import { apiSlice } from "../../api/apiSlice";

export const tuitionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyTuitions: builder.query({
      query: (userId) => `/chat/getTuitions/${userId}`,
    }),
    getStudentTuitions: builder.query({
      query: (userId) => `/chat/getStudentTuitions/${userId}`,
    }),
    getNewTuitions: builder.query({
      query: (type) => `/newTuition/getTuitions/${type}`,
    }),
    addNewTuition: builder.mutation({
      query: (data) => ({
        url: "/newTuition/saveTuition/",
        method: "POST",
        body: data.data,
      }),
    }),
    editTuition: builder.mutation({
      query: ({ tuitionId, data, sender }) => ({
        url: `/newTuition/updateNewTuition/${tuitionId}`,
        method: "PATCH",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const updatedTuition = await queryFulfilled;

          if (updatedTuition?.data?.tutorId) {
            dispatch(
              apiSlice.util.updateQueryData(
                "getMyTuitions",
                updatedTuition?.data?.tutorId,
                (draft) => {
                  const draftTuition = draft.find(
                    (c) => c.tuitionId === updatedTuition.data.tuitionId
                  );
                  draftTuition.status = updatedTuition.data.status;

                  //   draft.messages.push(res); //console.log(current(draft))
                }
              )
            );
            // update messages cache pessimistically end
          }
        } catch (err) {
          // pathResult.undo();
        }
      },
    }),

    // new
    getUserTuitions: builder.query({
      query: (userId) => `/tuition/timetable/${userId}`,
    }),
    updateTuitionStatus: builder.mutation({
      query: ({tuitionId, status, user}) => ({
        url: `/tuition/update-tuition-status`,
        method: 'PUT',
        body: { tuitionId, status },
      }),
      async onQueryStarted({ tuitionId, status, user}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTuition } = await queryFulfilled; //returns 
          console.log(updatedTuition);
          if (updatedTuition) {
            dispatch(
              apiSlice.util.updateQueryData(
                "getUserTuitions",
                user,
                (draft) => {
                  const draftTuition = draft.find(
                    (tuition) => tuition._id === updatedTuition._id
                  );
                  draftTuition.status = updatedTuition.status;
                  console.log(draftTuition)
                  //   draft.messages.push(res); //console.log(current(draft))
                }
              )
            );
            // update messages cache pessimistically end
          }
          // const result = dispatch(
          //   apiSlice.util.updateQueryData('getUserTuitions', user, (draft) => {
          //     Object.assign(draft, updateTuitionStatus)
          //   })
          // )
        } catch {}
      }
    }),

  }),
});

export const {
  useGetMyTuitionsQuery,
  useEditTuitionMutation,
  useGetStudentTuitionsQuery,
  useAddNewTuitionMutation,
  useGetNewTuitionsQuery,
  useGetUserTuitionsQuery,
  useUpdateTuitionStatusMutation
} = tuitionsApi;
