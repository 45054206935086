//worked till now
import React, { Fragment } from "react";
import { Card, CardBody, Col, ModalBody, ModalHeader } from "reactstrap";

const InterestViewContent = (props) => {
  return (
    <Fragment>
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody className={"" + props.bodyClass}>
        <Col sm="12" xl="12 box-col-12">
          <Card className="mb-0">
            <CardBody className="megaoptions-border-space-sm">
              <div className="table-responsive-sm table-bordered">
                <table className="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td className="p-1 py-1">Category</td>
                      <td className="p-1 py-1">
                        <span>
                          {props.data?.subjects[0]?.tag.includes("/") ? props.data?.subjects[0]?.tag.split("/")[0] : props.data?.subjects[0]?.tag}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Sub Category</td>
                      <td className="p-1 py-1">
                        <span>{props.data?.subjects[0]?.tag.includes("/") ? props.data?.subjects[0]?.tag.split("/")[1] : ""}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Subjects</td>
                      <td className="p-1 py-1">
                        {props.data?.subjects?.map((item, index) => (
                          <span className="badge badge-light-dark" style={{ margin: "0px 3px 2px 0px" }} key={index}>
                            {item?.name}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Class</td>
                      <td className="p-1 py-1">
                        <span>{props.data?.classes?.name}</span>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="p-1 py-1">Amount Plan</td>
                      <td className="p-1 py-1">
                        <span>{props.data.amountPlan}</span>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="p-1 py-1">Tution Type</td>
                      <td className="p-1 py-1">
                        {props.data.tutionTypes?.map((item, index) => (
                          <span className="badge badge-light-dark" style={{ margin: "0px 3px 2px 0px" }} key={index}>
                            {item}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Amount</td>
                      <td className="p-1 py-1">
                        <span>{props.data?.amount?.initialPrice}</span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td className="p-1 py-1">Currency</td> */}
                      {/* <td className="p-1 py-1">
                        <span>
                          {props.data.currency.name} -{" "}
                          {props.data.currency.code}
                        </span>
                      </td> */}
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Is Negotiable</td>
                      <td className="p-1 py-1">
                        {!props.data?.amount?.isNegotiable && <span>No</span>}
                        {props.data?.amount?.isNegotiable && <span>Yes</span>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </ModalBody>
    </Fragment>
  );
};

export default InterestViewContent;
