import React, { useState } from "react";
import SweetAlert from "sweetalert2";
import { ProfilePictureRootURL } from "../../api";
import { getEmailVerifiedOrNot } from "../../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import { axiosRequest } from "../../redux/utils/axios-utils";
import Context from "./index";

const BecomeStudentProvider = (props) => {
  const [stepStudent, setStepStudent] = useState(0);
  const [isMemberExists, setIsMemberExists] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [interestLearn, setInterestLearn] = useState({});

  const [studentBasic, setStudentBasic] = useState({
    gender: "",
    doB: "",
    contactNo: {
      code: "+880",
      number: "",
    },
    language: [],
    currency: null,
    about: {
      tutorBio: "",
      studentBio: "",
    },
  });

  const [profilePhoto, setProfilePhoto] = useState("");

  const [interestLearnList, setInterestLearnList] = useState([]);

  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  const getLoggedStudentInfo = async (userId) => {
    var res = await axiosRequest({
      url: "/public/singleStudent/" + userId,
      method: "get",
    });

    if (res.data) {
      var student = res.data;
      if (student.gender) {
        setStudentBasic({
          ...studentBasic,
          gender: student.gender ? student.gender : "",
          doB: student.doB ? new Date(student.doB) : "",
          contactNo: {
            code: student.contactNo?.code ? student.contactNo?.code : "",
            number: student?.contactNo?.number
              ? student?.contactNo?.number
              : "",
          },
          language: student?.languages ? student?.languages : [],
          currency: student?.currency ? student?.currency : null,

          about: {
            tutorBio: student.about?.tutorBio ? student.about?.tutorBio : "",
            studentBio: student.about?.studentBio
              ? student.about?.studentBio
              : "",
          },
        });
        // saveCurrentStepToSession(3);
      } else {
        console.warn("nothing found");
      }

      setIsEmailVerified(student?.emailVerified);
      if (student?.media?.picture) {
        const pro_pic_url = student?.media?.picture?.includes(
          "googleusercontent"
        )
          ? student?.media?.picture
          : ProfilePictureRootURL + student?.media?.picture;

        setProfilePhoto(pro_pic_url);
      }

      if (student?.interestToLearn?.length > 0) {
        setInterestLearnList(student?.interestToLearn);
        saveCurrentStepToSession(3);
      }

      if (student?.about?.studentBio) {
        saveCurrentStepToSession(2);
      }
      if (!student?.about?.studentBio) {
        saveCurrentStepToSession(1);
      }
    } else {
      clearBecomeStudentContext();
    }
  };

  const saveCurrentStepToSession = (value) => {
    setStepStudent(value);
    if (_tuteair) {
      localStorage.setItem(
        "_tuteair_newTutor",
        JSON.stringify({
          ..._tuteair,
          currentStepStudent: value,
        })
      );
    }
  };

  const handleSkipOrNext = (e) => {
    if (stepStudent > 3) setStepStudent(1);

    if (e === "next") setStepStudent(stepStudent + 1);
    if (e === "skip") setStepStudent(stepStudent + 1);
    if (e === "back") setStepStudent(stepStudent - 1);
  };

  const handleEmailVerified = async (e, step, navigate) => {
    e.preventDefault();
    var result = await getEmailVerifiedOrNot();

    if (result?.emailVerified) {
      if (profilePhoto !== "") {
        SweetAlert.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true,
          title: "Welcome to TuteAir",
          text: "Thank you for joining us. Visit TuteAir frequently to explore and interact. We'll be adding a lot more features daily.",
          icon: "success",
          confirmButtonText: "Go Dashboard",
        }).then((result) => {
          if (result.value) {
            setIsEmailVerified(true);
            localStorage.setItem(
              "_tuteair_newTutor",
              JSON.stringify({
                ..._tuteair,
                emailVerified: true,
              })
            );
            navigate(`${process.env.PUBLIC_URL}/dashboard`);
          }
        });
      } else {
        SweetAlert.fire({
          title: "Please upload your photo",
          icon: "warning",
        });
      }
    } else {
      SweetAlert.fire({
        title: "Email not verified yet!",
        icon: "error",
      });
      setIsEmailVerified(false);
    }
  };
  const clearBecomeStudentContext = () => {
    setStepStudent(0);
    setInterestLearn({});
    setStudentBasic({
      gender: "",
      doB: "",
      contactNo: {
        code: "+880",
        number: "",
      },
      language: [],
      currency: null,
      about: {
        tutorBio: "",
        studentBio: "",
      },
    });
    setInterestLearnList([]);
    setProfilePhoto("");
  };
  return (
    <Context.Provider
      value={{
        ...props,
        stepStudent,
        setStepStudent,
        getLoggedStudentInfo,
        interestLearn,
        setInterestLearn,
        interestLearnList,
        setInterestLearnList,
        handleSkipOrNext,
        saveCurrentStepToSession,
        clearBecomeStudentContext,
        isMemberExists,
        setIsMemberExists,
        handleEmailVerified,
        isEmailVerified,
        setIsEmailVerified,
        studentBasic,
        setStudentBasic,
        profilePhoto,
        setProfilePhoto,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default BecomeStudentProvider;
