import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { ExternalLink, MapPin } from "react-feather";
import { Link } from "react-router-dom";
import { CardImg, CardText, CardTitle, Col, Media, Row } from "reactstrap";
import { Image, LI, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";

const StudentCardContain = ({ showStudents }) => {
  const { moonlight } = useContext(FilterContext);

  return (
    <Fragment>
      <Col xl="12" className="">
        <Row className="search-page">
          <div
            className="search-links tab-pane fade show active"
            id="all-links"
            role="tabpanel"
            aria-labelledby="all-link"
          >
            <Row>
              <Col xl="12 box-col-12 search-banner">
                {showStudents &&
                  showStudents?.map((item, k) => {
                    return (
                      <div
                        className={`info-block p-3 mt-0 mb-2 ${
                          moonlight ? "single_tutor_dark" : "single_tutor"
                        }`}
                        key={k}
                      >
                        <Media>
                          <Link to={`/profile/${item.userId}`}>
                            <Image
                              attrImage={{
                                className: "img-40 img-fluid m-r-20",
                                src: `${item?.media?.profilePicture?.imageInfo
                                      ?.secure_url
                                      ? item?.media?.profilePicture?.imageInfo
                                          ?.secure_url
                                      :
                                  item.media.picture
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.media.picture
                                      }`
                                    : man
                                }`,
                                alt: "",
                              }}
                            />
                          </Link>
                          <Media body>
                            <h6 className="tutor_name">
                              <Link to={`/student-profile/${item.userId}`}>
                                {item.name.firstName + " " + item.name.lastName}
                              </Link>
                              <span
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Open in new tab"
                                className="new_tab_icon"
                              >
                                <Link
                                  to={`/student-profile/${item.userId}`}
                                  target="_blank"
                                >
                                  <ExternalLink height={13} />
                                </Link>
                              </span>
                            </h6>
                            {item.interestToLearn?.map((item, i) =>
                              [
                                ...new Set(
                                  item.subjects?.map((sub) => sub.tag)
                                ),
                              ]?.map((subject, k) => (
                                <span
                                  key={k}
                                  className="badge badge-light-primary py-1"
                                >
                                  {subject}
                                </span>
                              ))
                            )}
                            <a href="#javascript">{}</a>
                            <p className="" style={{ textAlign: "justify" }}>
                              {item.about?.studentBio?.length > 165 ? (
                                <span>
                                  {item.about?.studentBio
                                    .substring(0, 164)
                                    .trim()}
                                  ...
                                </span>
                              ) : (
                                <span>{item.about?.studentBio}</span>
                              )}
                            </p>
                            <div className="star-ratings">
                              <UL
                                attrUL={{
                                  className: "simple-list search-info flex-row",
                                }}
                              >
                                <LI>
                                  <span>
                                    <MapPin height={"12px"} />
                                  </span>
                                  <span>
                                    {item.address?.postalCode}{" "}
                                    {item.address?.city} {item.address?.country}
                                  </span>
                                </LI>
                                <LI>
                                  Joined {moment(item.createdAt).fromNow()}
                                </LI>
                                {/* <LI>{"item.vote"}</LI> */}
                                {/* <LI>
                                  {item.interestAndPricing?.map((item, i) =>
                                    [
                                      ...new Set(
                                        item.subjects?.map((sub) => sub.tag)
                                      ),
                                    ]?.map((subject, k) => (
                                      <span
                                        key={k}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {subject}
                                      </span>
                                    ))
                                  )}
                                </LI> */}
                              </UL>
                            </div>
                          </Media>
                        </Media>
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </div>
        </Row>
      </Col>
    </Fragment>
  );
};

export default StudentCardContain;
