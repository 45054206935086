import React, { Fragment } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import MyTuitionsContains from "../../Component/NewMyTuitions/MyTuitions";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const MyTuition = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="MyTuition" title="" />
      <MyTuitionsContains />
    </Fragment>
  );
};

export default MyTuition;
