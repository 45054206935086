// import LandingHomeContain from "../Component/Home/index";
import BecomeStudentContain from "../Component/LandingPage/BecomeStudent";
import BecomeTutorContain from "../Component/LandingPage/BecomeTutor";
import BecomeTutorLogin from "../Component/LandingPage/BecomeTutor/S0.Login";
import MinFaqs from "../Component/LandingPage/FrequnetlyAskQustions/MinFAQ";
import HelpContain from "../Component/LandingPage/HelpSection";
import LandingPageContain from "../Component/LandingPage/index";
import RegisterV2 from "../Component/LandingPage/RegisterV2";
import SearchTutorContain from "../Component/LandingPage/SearchTutor";
import CreatePwd from "../Component/Pages/Auth/CreatePwd";
import ForgetPwd from "../Component/Pages/Auth/ForgetPwd";
import SignIn from "../Component/Pages/Auth/SignIn";
import SignUp from "../Component/Pages/Auth/SignUp";
import VarifyEmail from "../Component/Pages/Auth/VarifyEmail";
import VarifyRegisterAgain from "../Component/Pages/Auth/VarifyRegisterAgain";
import ComingBgImg from "../Component/Pages/ComingSoon/ComingBgImg";
import PrivacyContain from "../Component/Privacy";
import TermsConditionContains from "../Component/TermsCondtion/TermsCondtion";
import DashboardRedirection from "../Pages/DashboardRedirection";

export const authRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/home`,
    Component: <LandingPageContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/authChecker/:cryptoToken`,
    Component: <DashboardRedirection />,
  },
  {
    path: `${process.env.PUBLIC_URL}/search-tutors`,
    Component: <SearchTutorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/get-help`,
    Component: <HelpContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/become-tutor`,
    Component: <BecomeTutorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/become-student`,
    Component: <BecomeStudentContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/register-v2`,
    Component: <RegisterV2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/login-account`,
    Component: <BecomeTutorLogin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/signin`,
    Component: <BecomeTutorLogin />,
    // Component: <SignIn />,
  },
  {
    path: `${process.env.PUBLIC_URL}/faqs`,
    Component: <MinFaqs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/signup`,
    // Component: <SignUp />,
    Component: <RegisterV2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/comming-soon`,
    Component: <ComingBgImg />, // bg-img
  },
  {
    path: `${process.env.PUBLIC_URL}/forgot-password`,
    Component: <ForgetPwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/create-password/:id/:token`,
    Component: <CreatePwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/privacy`,
    Component: <PrivacyContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/terms-condition`,
    Component: <TermsConditionContains />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email-verify/:id/verify/:token`,
    Component: <VarifyEmail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email-verify/:id/verifyAgain/:token`,
    Component: <VarifyRegisterAgain />,
  },
];
