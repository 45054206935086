import { useEffect, useState } from "react";
import { CornerUpLeft } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Media, Spinner } from "reactstrap";
import { ProfilePictureRootURL } from "../../../../api";
import man from "../../../../assets/images/avtar/man.png";
import nouser from "../../../../assets/images/avtar/nouser.png";
import { apiSlice } from "../../../../redux/features/api/apiSlice";
import { useGetConversationsQuery } from "../../../../redux/features/chat/conversations/conversationsApi";
import { chattingInfo } from "../../../../redux/features/chat/conversations/conversationsSlice";
import { useGetUserTuitionsQuery } from "../../../../redux/features/chat/tuitions/tuitionsApi";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import AddTuitionModal from "./AddTuitionModal";
import AllTuitionsModal from "./AllTuitionsModal";
import AllTuitionsModalStudent from "./AllTuitionsModalStudent";
import { useWindowWidth } from "./useWindowWidth";
import { useGetMemberInfoByIdQuery } from "../../../../redux/features/member/memberApi";
import { ToolTip } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import FreeClassModal from "./FreeClassModal";

export default function ChatHead({ conversationId }) {
  const width = useWindowWidth();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tuitionLoading, setTuitionLoading] = useState(true);
  const toggle = () => setModal(!modal);
  const [addModal, setAddModal] = useState(false);
  const toggleAdd = () => setAddModal(!addModal);
  const [freeClassModal, setFreeClassModal] = useState(false);
  const toggleFreeClassModal = () => setFreeClassModal(!freeClassModal);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth) || {};
  let { data: tuitionsInfo } = useGetUserTuitionsQuery(user);

  const { selectedUser } = useSelector((state) => state.conversations) || {};
  const { memberType } = useSelector((state) => state.menu) || {};
  const [InterestPricingData, setInterestPricingData] = useState([]);
  const [tutorCurrency, setTutorCurrency] = useState("");
  const [tutorName, setTutorName] = useState("");
  const [showUrlType, setShowUrlType] = useState("");

  // new states
  const [tutorAllTuitionList, setTutorAllTuitionList] = useState([]);
  const [tutorFreeTuitionList, setTutorFreeTuitionList] = useState([]);
  const [tutorOpenTuitionList, setTutorOpenTuitionList] = useState([]);
  const [studentAllTuitionList, setStudentAllTuitionList] = useState([]);
  const [studentOpenTuitionList, setStudentOpenTuitionList] = useState([]);

  const [toolTipMessage, setToolTipMessage] = useState("");
  const [tooltip, setTooltip] = useState(false);
  const toggleToolTip = () => setTooltip(!tooltip);
  const [tooltipFreeClass, setTooltipFreeClass] = useState(false);
  const toggleFreeClassToolTip = () => setTooltipFreeClass(!tooltipFreeClass);
  const [mediaTooltip, setMediaTooltip] = useState(false);
  const toggleMediaToolTip = () => {
    setMediaTooltip(!mediaTooltip);
    if (toolTipMessage) {
      // setToolTipMessage("")
    }
  }
  const [freeClass, setFreeClass] = useState(0);

  const { data: conversations, refetch: refetchReschedule } = useGetConversationsQuery(user);
  const selectedConversation = conversations?.find((c) => c.conversationId === conversationId);
  let { data: receiverInfo } = useGetMemberInfoByIdQuery(selectedConversation?.users?.find((x) => x !== user));
  let swMember = localStorage.getItem("swMember");

  // get tutors subjects
  useEffect(() => {
    const getTutorInfo = async () => {
      setIsLoading(true);
      var res = await axiosRequest({
        url: "/newMember/singleMemberInfo/" + user,
        method: "post",
        data: {
          type: "student",
        },
      });
      if (res) {
        if (res.data) {
          setInterestPricingData(res.data?.interestAndPricing);
          setTutorCurrency(res.data?.currency);
          setTutorName(`${res.data?.name.firstName} ${res.data?.name.lastName}`);
        }
      }
      setIsLoading(false);
    };
    getTutorInfo();
  }, []);

  useEffect(() => {
    refetchReschedule();
  }, [freeClass]);

  const getSingleUser = async () => {
    if (!selectedUser?.recevierId) return;
    var res = await axiosRequest({
      url: "/newMember/settings/singleUser/" + selectedUser?.recevierId,
      method: "get",
    });

    if (res) {
      dispatch(
        chattingInfo({
          type: "SELECTED",
          selectedUser: {
            conversationId: selectedUser.conversationId,
            recevierId: selectedUser.recevierId,
            isAccountDeactive: res.data?.isAccountDeactive,
            isAccountDelete: res.data?.isAccountDelete,
          },
        })
      );

      if (selectedUser.conversationId) {
        dispatch(
          apiSlice.util.updateQueryData("getConversations", user, (draft) => {
            const draftConversation = draft?.find((c) => c.conversationId === selectedUser?.conversationId);
            if (draftConversation) {
              draftConversation.isAccountDeactive = res.data?.isAccountDeactive;
              draftConversation.isAccountDelete = res.data?.isAccountDelete;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (selectedUser) getSingleUser();
    else {
      dispatch(
        chattingInfo({
          type: "TOP",
          topConversation: selectedConversation,
        })
      );
      dispatch(
        chattingInfo({
          type: "SELECTED",
          selectedUser: {
            conversationId: selectedConversation?.conversationId,
            recevierId: selectedConversation?.users?.find((x) => x !== user),
            isAccountDeactive: selectedConversation?.isAccountDeactive,
            isAccountDelete: selectedConversation?.isAccountDelete,
          },
        })
      );
    }
  }, []);

  const getTutorsTuitions = async () => {
    var res = await axiosRequest({
      url: "/tuition/timetable/" + user,
      method: "get",
    });
    if (res) {
      let tempList = [...res?.data];
      let tempFilter = tempList.filter(
        (tuition) => tuition.studentId === selectedConversation?.receiverId && tuition.status !== "CANCELED" && tuition.status !== "CLOSED" && tuition.status !== "FREE"
      );
      let tempFreeTuitionFilter = tempList.filter(
        (tuition) => tuition.studentId === selectedConversation?.receiverId && tuition.status === "FREE"
      );
      setTutorFreeTuitionList(tempFreeTuitionFilter)
      setTutorAllTuitionList(tempFilter);
      let tempOpenFilter = tempFilter.filter((tuition) => tuition.status === "OPEN");
      setTutorOpenTuitionList(tempOpenFilter);
      setTuitionLoading(false);
    }
  };

  const getStudentsTuitions = async () => {
    // new
    // console.log(tuitionsInfo);
    // let tempFilter = tuitionsInfo.filter(
    //   (tuition) => tuition.tutorId === selectedConversation?.receiverId
    // );
    // setStudentAllTuitionList(tempFilter);
    // let tempOpenFilter = tempFilter.filter(
    //   (tuition) => tuition.status === "OPEN"
    // );
    // setStudentOpenTuitionList(tempOpenFilter);
    // setTuitionLoading(false)
    // old
    var res = await axiosRequest({
      url: "/tuition/timetable/" + user,
      method: "get",
    });
    if (res) {
      let tempList = [...res?.data];
      let tempFilter = tempList.filter(
        (tuition) => tuition.tutorId === selectedConversation?.receiverId && tuition.status !== "CANCELED" && tuition.status !== "CLOSED" && tuition.status !== "FREE"
      );
      setStudentAllTuitionList(tempFilter);
      let tempOpenFilter = tempFilter.filter((tuition) => tuition.status === "OPEN");
      setStudentOpenTuitionList(tempOpenFilter);
      setTuitionLoading(false);
    }
  };

  useEffect(() => {
    if (swMember === "tutor") {
      getTutorsTuitions();
      selectedConversation && setFreeClass(selectedConversation?.freeClassCount);
      // } else if (swMember === "student" && tuitionsInfo) {
    } else if (swMember === "student") {
      getStudentsTuitions();
    }
  }, [memberType, selectedConversation]);
  // }, [memberType, selectedConversation, tuitionsInfo]);

  // Redirects to receiver profile conditionally
  useEffect(() => {
    if (memberType && receiverInfo) {
      const { memberType: receiverType } = receiverInfo;
      // if the logged user is in tutor mode && receiver is either a student or both, redirects to student account
      if (memberType === "tutor" && (receiverType === "student" || receiverType === "both")) {
        setShowUrlType("student");
      }
      // if the logged user is in student mode && receiver is either a tutor or both, redirects to tutor account
      else if (memberType === "student" && (receiverType === "tutor" || receiverType === "both")) {
        setShowUrlType("tutor");
      }
      else {
        setShowUrlType("");
        setToolTipMessage(
          memberType === "student" ? "In student mode, another student profile cannot be visited"
            : memberType === "tutor" ? "In tutor mode, another tutor profile cannot be visited" : "");
      }
    }
  }, [memberType, receiverInfo]);

  const picture =
    selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete
      ? nouser
      : selectedConversation?.thumb === "" || selectedConversation?.thumb === " "
        ? man
        : ProfilePictureRootURL + selectedConversation?.thumb;

  const handleTuitionModal = (e) => {
    e.preventDefault();
    toggle();
  };

  const handleBackButton = (e) => {
    dispatch(
      chattingInfo({
        type: "MOBILE",
        showConversation: "",
        showMessage: "d-none",
      })
    );
  };

  const handleAddTuitionModal = (e) => {
    e.preventDefault();
    toggleAdd();
  };

  const handleFreeClassModal = (e) => {
    e.preventDefault();
    toggleFreeClassModal();
  };

  return (
    <div className="row d-flex justify-items-center align-items-center chat_header_bg">
      <div className="col-md-6 col-9">
        <Media className="chat-header clearfix d-flex align-items-center">
          <Link to={
            showUrlType === "tutor"
              ? `/profile/${selectedConversation?.users?.find((x) => x !== user)}`
              : showUrlType === "student"
                ? `/student-profile/${selectedConversation?.users?.find((x) => x !== user)}`
                : ""}>
            <img style={{ cursor: "pointer" }} src={picture} className="rounded-circle" alt="" />
          </Link>
          <Media body>
            <Link to={
              showUrlType === "tutor"
                ? `/profile/${selectedConversation?.users?.find((x) => x !== user)}`
                : showUrlType === "student"
                  ? `/student-profile/${selectedConversation?.users?.find((x) => x !== user)}`
                  : ""}>
              <div className="about">
                <div className="name" style={{ cursor: "pointer" }} id="media-tooltip">
                  {selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete ? "TuteAir User" : selectedConversation?.name}
                </div>
              </div>
            </Link>
          </Media>
        </Media>
        {
          !showUrlType && (
            <>
              <ToolTip
                attrToolTip={{
                  placement: 'bottom',
                  isOpen: mediaTooltip,
                  target: 'media-tooltip',
                  toggle: toggleMediaToolTip
                }}
              >
                {toolTipMessage}
              </ToolTip>
            </>
          )
        }
      </div>
      {selectedUser && width <= 500 ? (
        <div className="col-md-6 col-3">
          <button className="btn btn-sm btn-default text-primary m-2" onClick={handleBackButton}>
            <CornerUpLeft />
          </button>
        </div>
      ) : (
        ""
      )}

      <div className="col-lg-6 col-12 d-flex flex-wrap">
        <div className="col-lg-4 col-4" style={{ textAlign: "left" }}>
          {swMember === "tutor" && !(selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete) && (
            <>
              <div id="Tooltip-freeclass" style={{ display: 'inline-block' }}>
                <button
                  className="btn btn-info align-items-center position-relative overflow-visible px-4"
                  disabled={
                    selectedUser?.isAccountDeactive
                    || selectedUser?.isAccountDelete
                    || isLoading
                    || receiverInfo?.memberType === "tutor"
                    || selectedConversation?.freeClassCount === 0
                  }
                  onClick={(e) => handleFreeClassModal(e)}
                >
                  <span className="text-white">Free Class</span>
                  {
                    receiverInfo?.memberType !== "tutor" && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                        {freeClass}
                      </span>
                    )
                  }
                </button>
              </div>
              {
                receiverInfo?.memberType === "tutor" && (
                  <ToolTip
                    attrToolTip={{
                      placement: 'bottom',
                      isOpen: tooltipFreeClass,
                      target: 'Tooltip-freeclass',
                      toggle: toggleFreeClassToolTip
                    }}
                  >
                    You cannot create free class with a user who is only tutor
                  </ToolTip>
                )
              }
              {freeClassModal && (
                <FreeClassModal
                  title={"Add a free class"}
                  isOpen={freeClassModal}
                  toggler={toggleFreeClassModal}
                  conversationId={conversationId}
                  selectedStudentName={selectedConversation?.name}
                  selectedStudentId={selectedConversation?.users?.find((x) => x !== user)}
                  freeClass={freeClass}
                  setFreeClass={setFreeClass}
                  selectedTutorName={tutorName}
                  freeTuition={tutorFreeTuitionList}
                />
              )}
            </>
          )}
        </div>
        <div className="col-lg-4 col-4" style={{ textAlign: "center" }}>
          {swMember === "tutor" && !(selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete) && (
            <>
              <div id="Tooltip" style={{ display: 'inline-block' }}>
                <button
                  className="btn btn-warning align-items-center px-4"
                  disabled={
                    selectedUser?.isAccountDeactive
                    || selectedUser?.isAccountDelete
                    || isLoading
                    || receiverInfo?.memberType === "tutor"
                  }
                  onClick={(e) => handleAddTuitionModal(e)}
                >
                  <span className="text-white">Add Tuition</span>
                </button>
              </div>
              {
                receiverInfo?.memberType === "tutor" && (
                  <ToolTip
                    attrToolTip={{
                      placement: 'bottom',
                      isOpen: tooltip,
                      target: 'Tooltip',
                      toggle: toggleToolTip
                    }}
                  >
                    You cannot add tuition with a user who is only tutor
                  </ToolTip>
                )
              }
              {addModal && (
                <AddTuitionModal
                  title={"Add New Tuition"}
                  isOpen={addModal}
                  toggler={toggleAdd}
                  subjectList={InterestPricingData}
                  conversationId={conversationId}
                  receiverId={selectedConversation?.users?.find((x) => x !== user)}
                  tutorCurrency={tutorCurrency}
                  selectedStudentName={selectedConversation?.name}
                  selectedTutorName={tutorName}
                  allTuitions={tutorAllTuitionList}
                />
              )}
            </>
          )}
        </div>
        <div className="col-lg-4 col-4" style={{ textAlign: "right" }}>
          {swMember === "tutor" && !(selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete) && (
            <>
              <button
                className="btn btn-primary align-items-center position-relative overflow-visible"
                disabled={selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete || tuitionLoading}
                onClick={(e) => handleTuitionModal(e)}
              >
                {tuitionLoading ? (
                  <div className="d-flex align-items-center">
                    <span className="m-r-10">Loading</span>
                    <Spinner animation="border" variant="light" />
                  </div>
                ) : (
                  <>
                    <span>Tuitions</span>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                      {tutorAllTuitionList?.length > 0 ? tutorAllTuitionList?.length : undefined}
                    </span>
                    {/* <label className="badge badge-light-dark text-white m-0 mx-1">
                          {tutorOpenTuitionList?.length === 0
                            ? 0
                            : tutorOpenTuitionList?.length}{" "}
                          / 
                          {tutorAllTuitionList.length}
                        </label> */}
                  </>
                )}
              </button>
              {modal && (
                <AllTuitionsModal
                  title={"Your Tuitions"}
                  isOpen={modal}
                  toggler={toggle}
                  allTuitions={tutorAllTuitionList}
                  conversationId={conversationId}
                  tutorCurrency={tutorCurrency}
                  receiverId={selectedConversation?.users?.find((x) => x !== user)}
                />
              )}
            </>
          )}
          {swMember === "student" && !(selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete) && (
            <div>
              <button
                className="btn btn-primary align-items-center position-relative overflow-visible me-1"
                onClick={(e) => handleTuitionModal(e)}
                disabled={selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete || tuitionLoading}
              >
                {tuitionLoading ? (
                  <div className="d-flex align-items-center">
                    <span className="m-r-10">Loading</span>
                    <Spinner animation="border" variant="light" />
                  </div>
                ) : (
                  <>
                    <span>Tuitions</span>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                      {studentAllTuitionList?.length > 0 ? studentAllTuitionList?.length : undefined}
                    </span>
                    {/* <label className="badge badge-light-dark text-white m-0 mx-1">
                          {studentOpenTuitionList?.length === 0
                            ? 0
                            : studentOpenTuitionList?.length}{" "}
                          / {studentAllTuitionList?.length}
                        </label> */}
                  </>
                )}
              </button>
              {modal && (
                <AllTuitionsModalStudent
                  title={"Your Tuitions"}
                  isOpen={modal}
                  toggler={toggle}
                  allTuitions={studentAllTuitionList}
                  conversationId={conversationId}
                  receiverId={selectedConversation?.users?.find((x) => x !== user)}
                  screenWidth={width}
                  user={user}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
