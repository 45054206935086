import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Label, Media } from "reactstrap";
import SweetAlert from "sweetalert2";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import FilterContext from "../../../_helper/Filter";
import { userLoggedOut } from "../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import NavbarContain from "../BecomeTutor/ChildComponent/Navbar";
import "../BecomeTutor/become-tutor.css";
import { getResendEmailResponse } from "../BecomeTutor/fnBecomeTutor";
import NewInterestToLearn from "./S1.NewInterestToLearn";
import StudentPersonalInfo from "./S2.Personal";
import StudentPhotoContain from "./S3.Photo";
import { fnResendEmail } from "../../../Services/Filter.Service";

const BecomeStudentContain = () => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  const [seconds, setSeconds] = useState(59);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const [minutes, setMinutes] = useState(0);

  const { accountType, isTutor, setIsTutor } = useContext(FilterContext);

  const {
    stepStudent,
    setStepStudent,
    getLoggedStudentInfo,
    profilePhoto,
    setProfilePhoto,
    clearBecomeStudentContext,
    handleEmailVerified,
    setIsEmailVerified,
    isEmailVerified,
    isMemberExists,
  } = useContext(BecomeStudentContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Become Student | TuteAir - Learn & Teach Globally";
    setIsTutor(true);
    if (_tuteair?.accessToken && _tuteair?._id) {
      getLoggedStudentInfo(_tuteair?._id);
      setStepStudent(_tuteair.currentStepStudent);
    } else {
      dispatch(userLoggedOut());
      dispatch(clearMemberMenus());
      clearBecomeStudentContext();
      localStorage.clear();
      navigate(`${process.env.PUBLIC_URL}/login-account`);
    }

    if (!isMemberExists) {
      // console.log(isMemberExists);
      // Logout();
    }
  }, []);

  const handleResendEmail = async () => {
    // var response = await getResendEmailResponse(_tuteair?._id);
    // setResendEnabled(false);
    // if (response?.message === "verified") {
    //   setIsEmailVerified(true);
    //   SweetAlert.fire({
    //     title: `Your email are already verified.`,
    //     text: "Thank you for being with TuteAir. Complete your profile to find your desired tuitions.",
    //   });
    // } else if (response?.message === "success") {
    //   SweetAlert.fire({
    //     title: `Email sent to <u>${_tuteair?.email}<u/>`,
    //     text: "N.B: If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder",
    //   });
    // }

    await fnResendEmail({ setResendEnabled, setIsEmailVerified });
  };

  const handleNotReceivedEmail = async (e) => {
    e.preventDefault();
    var htmlText = "";
    htmlText +=
      "<p>If you do not see the email in your inbox in a few minutes, please check your junk mail folder and add  <q>support@tuteair.com</q> to your White List or Safe Sender List.</p>";
    htmlText +=
      "<p>We make every effort to ensure that these emails are delivered.</p>";
    htmlText += `<p>If you still don’t receive an email, then please click on <b>Resend Email</b></p>`;

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Didn't receive an Email?",
      html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Resend Email",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        handleResendEmail();
      }
    });
  };

  const switchMemberToggle = (isTutor) => {
    setIsTutor(!isTutor);
    if (isTutor) {
      navigate(`${process.env.PUBLIC_URL}/become-tutor`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/become-student`);
    }
  };

  const accountType2 = JSON.parse(
    localStorage.getItem("_tuteair_newTutor")
  ).accountType;

  // routing to become member as this page is not being used 

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/become-member/about-me`
  }, []);

  return (
    <></>
    // <>
    //   <div className="" style={{ background: "#f0f8ffd9" }}>
    //     <NavbarContain />
    //     <Container
    //       className={`py-1 mt-5 py-md-5 ${
    //         stepStudent !== 0 ? "container_padding" : ""
    //       }`}
    //     >
    //       {stepStudent > 0 && (
    //         <Row className="mt-4" style={{ placeContent: "center" }}>
    //           <Col className="email_confirm_title text-center">
    //             {!isEmailVerified && (
    //               <div className="w-100 d-flex justify-content-center align-items-center flex-column flex-md-row">
    //                 <div className="d-flex">
    //                   <Mail width={17} height={17} className="mt-2" />
    //                   <h5 className="px-2 d-none d-md-block mt-2">
    //                     An Email has been sent to your email. Please confirm
    //                     your email.
    //                   </h5>
    //                   <small className="fw-bold fs-6 d-md-none px-1">
    //                     {" "}
    //                     An Email has been sent to your email. <br /> Please
    //                     confirm your email.
    //                   </small>
    //                 </div>

    //                 <a
    //                   href="#resentEmail"
    //                   onClick={(e) => handleNotReceivedEmail(e)}
    //                   className="text-underline px-3 mt-2"
    //                 >
    //                   Didn't receive an Email?
    //                 </a>
    //                 {/* <button
    //                  className="btn btn-sm py-1 text-dark btn-light-gradien mx-3"
    //                  // disabled={!resendEnabled}
    //                  disabled={false}
    //                  onClick={(e) => handleResendEmail(e)}
    //                >
    //                  Resend
    //                  {minutes === 0 && seconds === 0 ? null : (
    //                    <span>
    //                      {" "}
    //                      in {seconds < 10 ? `0${seconds}` : seconds}
    //                    </span>
    //                  )}
    //                </button> */}
    //               </div>
    //             )}
    //           </Col>
    //         </Row>
    //       )}

    //       {(accountType === "both" || accountType2 === "both") && (
    //         <hr className="my-1 d-md-none border border-primary" />
    //       )}

    //       {(accountType === "both" || accountType2 === "both") && (
    //         <div className="d-flex mt-2 mt-md-4 justify-content-center align-items-center flex-column flex-md-row">
    //           <h5 className="pe-3 d-none d-md-block">
    //             Switch to 'Tutor' type to give required information:
    //           </h5>
    //           <small className="fw-bold mb-1 d-md-none">
    //             Switch to 'Tutor' type to give required information:
    //           </small>
    //           <div className="d-flex align-items-center">
    //             <h5 className="px-2"> Tutor </h5>
    //             <Media body className={"text-end switch-outline media-body"}>
    //               <Label className="switch p-0 m-0">
    //                 <Input
    //                   type="checkbox"
    //                   checked={isTutor}
    //                   onClick={(e) => switchMemberToggle(isTutor)}
    //                   onChange={(e) => switchMemberToggle(isTutor)}
    //                 />
    //                 <span
    //                   className="switch-state bg-primary"
    //                   style={{ background: "#6b6b91" }}
    //                 ></span>
    //               </Label>
    //             </Media>
    //             <h5 className="px-2"> Student </h5>
    //           </div>
    //         </div>
    //       )}

    //       {stepStudent === 1 ? (
    //         <StudentPersonalInfo />
    //       ) : stepStudent === 2 ? (
    //         <NewInterestToLearn />
    //       ) : stepStudent === 3 ? (
    //         <StudentPhotoContain />
    //       ) : (
    //         <NewInterestToLearn />
    //       )}
    //     </Container>
    //   </div>
    // </>
  );
};

export default BecomeStudentContain;
