import React from "react";
import { Card, CardHeader, CardText, CardTitle } from "reactstrap";
import "../_profile.css";
import { Link } from "react-router-dom";

export default function Qualification({ params, data, accountType, title, type }) {
  return (
    <div>
      {/* <Card body> */}
      <Card body className="card-absolute b-t-light border-5 cardMobileView">
        <CardHeader
          style={{ padding: "5px 10px" }}
          className="bg-primary title"
        >
          {title}
        </CardHeader>
        {/* <CardTitle tag="h4" className="">
          {props.title}
        </CardTitle> */}
        {params === "me" &&
          <div className="d-flex justify-content-end">
            <Link to={'/settings?tab=3'}>
              <button
                //type="submit"
                className="btn btn-primary text-white py-1 px-2"
              //disabled={false}}
              >
                <i class="fa fa-edit me-2" aria-hidden="true"></i>
                Edit
              </button>
            </Link>
          </div>
        }
        {data?.map((item, index) => (
          <div key={index} className="MobileViewResponsive">
            <CardText className="text-primary mb-0">
              <i className="fa fa-graduation-cap"></i>{" "}
              <span className="ms-1 degreeCourseMobileView">
                {item.degree.label} in {item.course.label}
              </span>
            </CardText>
            <div style={{ paddingLeft: "26px" }}>
              <p className="mb-0 insMobileView"> {item.institute}</p>
              <p className="mb-0 resultMobileView">
                {" "}
                Result {item.marks}% (Score in percentage)
              </p>
              <p className="mb-0 yearMobileView"> Year of {item.passingYear}</p>
            </div>
          </div>
        ))}
        {data?.length <= 0 &&
          accountType.toLowerCase() !== type.toLowerCase() && (
            <CardText className="mb-0 m-t-20" style={{ fontSize: "13px" }}>
              {type} hasn't added qualification yet.
            </CardText>
          )}
        {data?.length <= 0 &&
          accountType.toLowerCase() === type.toLowerCase() && (
            <CardText className="mb-0 m-t-20" style={{ fontSize: "13px" }}>
              You haven't added qualification yet.
            </CardText>
          )}
      </Card>
    </div>
  );
}
