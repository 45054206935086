import moment from "moment";
import React from "react";
import { Card, CardHeader, CardText, CardTitle } from "reactstrap";
import "../_profile.css";
import { Link } from "react-router-dom";

export default function Experience({ params, data, accountType }) {
  return (
    <div>
      {/* <Card body> */}
      <Card body className="card-absolute b-t-light border-5 cardMobileView">
        <CardHeader
          style={{ padding: "5px 10px" }}
          className="bg-primary title"
        >
          Experience
        </CardHeader>
        {/* <CardTitle tag="h4">
          Experience
        </CardTitle> */}
        {params === "me" &&
          <div className="d-flex justify-content-end">
            <Link to={'/tutor-account?tab=2'}>
              <button
                //type="submit"
                className="btn btn-primary text-white py-1 px-2"
              //disabled={false}}
              >
                <i class="fa fa-edit me-2" aria-hidden="true"></i>
                Edit
              </button>
            </Link>
          </div>
        }
        {data?.map((item, index) => (
          <div key={index} className="MobileViewResponsive">
            <CardText
              className="text-primary mb-0"
              style={{ fontSize: "13px" }}
            >
              {" "}
              <i className="fa fa-briefcase"></i>
              <span className="ms-3 positionMblView">{item.position}</span>
            </CardText>
            <CardText
              className="mb-0 orgMobileView"
              style={{ fontSize: "13px", paddingLeft: "26px" }}
            >
              {item.organization}
            </CardText>
            <CardText
              style={{ fontSize: "13px", paddingLeft: "26px" }}
              className="dateMblView"
            >
              {moment(item.startDate).format("MMMM D, yyyy")} -{" "}
              {item.running ? (
                <span>Running</span>
              ) : (
                <span>{moment(item.endDate).format("MMMM D, yyyy")}</span>
              )}
            </CardText>
          </div>
        ))}
        {
          data?.length <= 0 && accountType !== "tutor" && (
            <CardText className="mb-0 m-t-20" style={{ fontSize: "13px" }}>
              Tutor hasn't added experience yet.
            </CardText>
          )
        }
        {
          data?.length <= 0 && accountType === "tutor" && (
            <CardText className="mb-0 m-t-20" style={{ fontSize: "13px" }}>
              You haven't added experience yet.
            </CardText>
          )
        }
      </Card>
    </div>
  );
}
