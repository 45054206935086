import React, { Fragment } from "react";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import TuitionFeesFilter from "../../FindTutors/ListView/TuitionFeesFilter";
import TutorLocation from "../../FindTutors/ListView/TutorLocationFilter";
// import BasicFilter from "./BasicFilter";

const FilterOptionsContain = ({ setFilters, isReset }) => {
  return (
    <Fragment>
      <SubjectsFilter setFilters={setFilters} isReset={isReset} />
      <BasicFilter setFilters={setFilters} isReset={isReset} />
      {/* <BasicFilter setFilters={setFilters} isReset={isReset} /> */}
      <TutorLocation setFilters={setFilters} isReset={isReset} />
      <TuitionFeesFilter setFilters={setFilters} isReset={isReset} />
    </Fragment>
  );
};

export default FilterOptionsContain;
