import React, { useEffect, useState } from "react";
import { Col, Row, Input, Media, Card } from "reactstrap";
import { useWindowWidth } from "../../../Chat/NewChat/inbox/useWindowWidth";
import RadioField from "./RadioField";
import Multiselect from "multiselect-react-dropdown";
import { H5 } from "../../../../AbstractElements";

const InputField = ({ label, selectedValue, edit, inputType, handleFunction, isValid, languageList, bgColors, accountTypeList, accountType }) => {
  let width = useWindowWidth();
  
  
  return (
    <div className={`${width < 500 ? "justify-content-between" : ""} d-flex align-items-center `}>
      <Col
        style={{ marginRight: `${width < 500 ? "m-r-30" : ""}` }}
        className={`${width < 500 ? "m-r-30" : ""}`}
        md="3" xs="3"
      >
        <Row>
          <span style={{color: "#6c757d"}}>{label}</span>
        </Row>
      </Col>
      <Col xs="9">
        <Row>
          {/* readonly value */}
          {
            !edit && inputType !== "checkbox" && inputType !=="multi" && inputType !== "radio" && <span>{selectedValue}</span> 
          }
          {
            !edit && inputType === "radio" && <span>{selectedValue?.charAt(0).toUpperCase() + selectedValue?.slice(1)}</span> 
          }
          {
            !edit && inputType === "multi" && (
              <div className="d-flex flex-wrap">
              {
                selectedValue.map((language, index) => (
                  <div key={index} style={{backgroundColor: "#bcb8b1"}} className="txt-dark m-r-10 px-3 m-b-5 rounded-5">
                    <span>{language}</span>
                  </div>
                ))
              }
              </div>
            )
          }
          {
            !edit && inputType === "checkbox" && (
              <div className="d-flex">
              {
                accountTypeList.map((account, index) => (
                  <div key={index} className="bg-success txt-dark m-r-10 px-3 rounded-5">
                    <span>{account}</span>
                  </div>
                ))
              }
              </div>
            )
          }
          {/* when only text input */}
          {inputType === "input" && edit && (
            <>
            <Col sm="5">
              <form autoComplete="off">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Phone No"
                  value={selectedValue}
                  onChange={(e) => handleFunction(e)}
                />
              </form>
              </Col>
              {!isValid && (
                <p className="txt-danger">Please enter valid phone number</p>
              )}
            </>
            )
          }
          {/* when radio input */}
          {
            inputType === "radio" && edit && (
              <RadioField handleFunction={handleFunction} value={selectedValue}></RadioField>
            )
          }
          {/* when multi selection input */}
          {
            inputType === "multi" && edit && (
              <form autoComplete="off">
                <Multiselect
                  className="darkMode"
                  isObject={false}
                  options={languageList}
                  showCheckbox
                  selectedValues={selectedValue}
                  onSelect={(e) => {
                    handleFunction(e);
                  }}
                  onRemove={(e) => {
                    handleFunction(e);
                  }}
                />
              </form>
            )
          }
          {/* when check box input */}
          {
            inputType === "checkbox" && edit && (
              <>
                <Col lg="4" md="5" sm="3" xs="6">
                  <Card className={`mb-0 ${bgColors?.bgColor1} border b-primary p-1 d-flex justify-content-center align-items-center`}>
                    <Media className="p-t-5 d-flex justify-content-center align-items-center">
                      <div className="checkbox checkbox-dark">
                        <input
                          id="inline-1"
                          type="checkbox"
                          checked={accountType.onlyTutor || (accountType.onlyTutor && accountType.onlyStudent)}
                          value="tutor"
                          onChange={handleFunction}
                        />
                        <label htmlFor="inline-1" className="mt-0">
                          Tutor
                        </label>
                      </div>
                    </Media>
                  </Card>
                </Col>
                <Col lg="4" md="5" sm="3" xs="6">
                  <Card className={`mb-0 ${bgColors?.bgColor2} p-1 b-primary d-flex justify-content-center align-items-center`}>
                    <Media className="p-t-5 d-flex justify-content-center align-items-center">
                      <div className="checkbox checkbox-dark">
                        <Input
                          id="inline-2"
                          type="checkbox"
                          checked={accountType.onlyStudent || (accountType.onlyTutor && accountType.onlyStudent)}
                          value="student"
                          onChange={handleFunction}
                        />
                        <label htmlFor="inline-2" className="mt-0">
                          Student
                        </label>
                      </div>
                    </Media>
                  </Card>
                </Col>
              </>
            )
          }
        </Row>
      </Col>
    </div>
  );
};

export default InputField;
