import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { convertToTitleCase } from "../../Services/Api.Service";
import Accordion from "./Accordion";

export default function SummeryList(props) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (props.summeryList?.transactions?.length > 0) {
      const sortedTransactions = props.summeryList.transactions
        .slice()
        .sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt));

      setTransactions(sortedTransactions);
    }
  }, [props.summeryList]);

  let prevTransactionNo = " ";
  let updatedTransactions = [...transactions];

  props?.summeryList?.tutorPayHistory?.forEach((item) => {
    updatedTransactions.push(item);
  });

  const sortedTransactions = updatedTransactions
    .slice()
    .sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt));

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table style={{backgroundColor:'white'}} className="table">
            <thead>
              <tr style={{ background: "#dbdbdb2b" }}>
                <th>Item</th>
                <th>Subject</th>
                <th className="d-inline-flex">
                  Pay <span className="ms-1">Time</span>
                </th>
                <th>Type</th>
                <th>Expenditure</th>
                <th>Amount</th>
                <th>Balance</th>
              </tr>
            </thead>

            <tbody>
              {sortedTransactions?.length === 0 && (
                <>
                  <tr className="px-0">
                    <td colSpan={8} className="text-center">
                      There is no payment info available!
                    </td>
                  </tr>
                </>
              )}
              {sortedTransactions?.map((item, index) => {
                const isSameTransaction =
                  item.transactionNo === prevTransactionNo;
                prevTransactionNo = item.transactionNo;
                const rowClassName = isSameTransaction ? "same-trx" : "";
                const accordionTitle =
                  item?.status === "cancellation_credit" ||
                    item?.status === "tuition_closed_credit"
                    ? "Received Credit of"
                    : "Paid";
                const classText =
                  item.classList?.length === 1 ? "class" : "classes";

                return (
                  <tr className={`px-0 ${rowClassName}`} key={index}>
                    {item?.tutorInfo?.firstName ? (
                      <td>
                        {"Tuition with " + item?.tutorInfo?.firstName}
                        {/* {props?.memberType === "student"
                      ? "Tuition with " +
                        item?.tutorInfo?.firstName +
                        " " +
                        item?.tutorInfo?.lastName
                      : item?.studentInfo?.firstName +
                        " " +
                        item?.studentInfo?.lastName} */}
                      </td>
                    ) : (
                      <span>Paid To Account</span>
                    )}
                    {item?.subject && (
                      <td>
                        {item.subject?.map((item, index) => (
                          <span
                            className="badge-light-primary"
                            style={{ margin: "0px 3px 2px 0px" }}
                            key={index}
                          >
                            {item?.name}
                          </span>
                        ))}

                        <tr>
                          {item.classList?.length > 0 && (
                            <>
                              <td>
                                <Accordion
                                  title={`${accordionTitle} ${item.classList?.length} ${classText}`}
                                  classes={item.classList}
                                  id={1}
                                />
                                {item?.status !== "cancellation_credit" &&
                                  item?.status !== "tuition_closed_credit" && (
                                    <div>
                                      <div>
                                        Transaction No:
                                        <span
                                          style={{
                                            backgroundColor: "#f1f1de",
                                            color: "#000",
                                            borderRadius: "3px",
                                            padding: "0 5px 0 5px",
                                          }}
                                        >
                                          {item.transactionNo}
                                        </span>
                                      </div>
                                      {item?.pay_details?.voucher && (
                                        <div>
                                          Voucher Code:
                                          <span
                                            style={{
                                              backgroundColor: "#f1f1de",
                                              color: "#000",
                                              borderRadius: "3px",
                                              padding: "0 5px 0 5px",
                                            }}
                                          >
                                            {
                                              item?.pay_details?.voucher
                                                ?.voucherCode
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                {/* {item.classList.map((cl, i) => (
                             <>
                               <p>
                                 {moment(new Date(cl.classDate)).format(
                                   "LL"
                                 )}{" "}
                                 {moment(new Date(cl.startTime)).format("LT")}{" "}
                                 -{moment(new Date(cl.endTime)).format("LT")}
                               </p>
                             </>
                           ))} */}
                              </td>
                            </>
                          )}
                        </tr>
                      </td>
                    )}
                    {item.status === "pay out" && <td></td>}
                    {item?.createdAt && (
                      <td>
                        <span>
                          {moment(new Date(item.createdAt)).format("lll")}
                        </span>
                      </td>
                    )}
                    {item?.status && (
                      <td>
                        {item?.status === "class_payment"
                          ? "Paid Tutor"
                          : item?.status === "cancellation_credit"
                            ? "Cancellation Credit"
                            : item?.status === "tuition_closed_credit"
                              ? "Tuition Closing Credit"
                              : item?.status === "pay out"
                                ? "Pay Out"
                                : item?.status === "class_complete"
                                  ? "Remuneration"
                                  : ""}
                      </td>
                    )}
                    {item?.status && (
                      <td>
                        {item?.status === "class_payment" ? (
                          <>
                            {item.pay_details && (
                              <>
                                {item.pay_details?.voucher?.amount > 0 && (
                                  <p>
                                    Voucher: $
                                    {item.pay_details?.voucher?.amount}
                                  </p>
                                )}
                                {item.pay_details?.credit?.amount > 0 && (
                                  <p>
                                    Credit: $ {item.pay_details?.credit?.amount}
                                  </p>
                                )}
                                {item.pay_details?.card?.amount > 0 && (
                                  <p>
                                    {convertToTitleCase(
                                      item.pay_details?.card?.name
                                    )}
                                    : ${item.pay_details?.card?.amount}
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          0
                        )}
                      </td>
                    )}
                    {/* <td>${item?.status === "class_payment" ? item?.pay_amount : 0}</td> */}
                    {item?.status && (
                      <td>
                        $
                        {item?.status === "cancellation_credit"
                          ? parseFloat(item?.pay_amount).toFixed(2)
                          : item?.status === "class_complete"
                            ? parseFloat(item?.pay_amount).toFixed(2)
                            : item?.status === "pay out"
                              ? parseFloat(item?.pay_amount).toFixed(2)
                              : item?.status === "tuition_closed_credit"
                                ? parseFloat(item?.pay_amount).toFixed(2)
                                : 0}
                      </td>
                    )}
                    {item?.balance !== undefined && (
                      <td>${parseFloat(item?.balance).toFixed(2)}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
//
