import React, { Fragment } from "react";
import AllUsersContain from "../../Component/Chat/NewChat/AllUsers";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const AllUsers = () => {
  useLogoutSession();
  return (
    <Fragment>
      <AllUsersContain />
    </Fragment>
  );
};

export default AllUsers;
