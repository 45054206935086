import React, { Fragment, useEffect } from "react";
import { Container, Row } from "reactstrap";
import RequestTuition from "./Student/RequestTuition";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RequestTuitionContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
  }, [memberType, navigate]);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          {/* <div>index</div> */}
          <RequestTuition />
        </Row>
      </Container>
    </Fragment>
  );
};

export default RequestTuitionContain;
