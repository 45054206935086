import axios from "axios";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import { Row } from "react-bootstrap";
import { Info, ThumbsUp } from "react-feather";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Input } from "reactstrap";
import { H2 } from "../../../AbstractElements";
import { API_URL_V1 } from "../../../api";
import { dataURLtoFile } from "../../../Services/Api.Service";
import { authHeader } from "../../../Services/AuthVerify";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import "../../LandingPage/BecomeTutor/become-tutor.css";
import { canvasPreview } from "../../LandingPage/BecomeTutor/canvasPreview";
import HelpModal from "../../LandingPage/BecomeTutor/ChildComponent/HelpModal";
import HelpModalBtn from "../../LandingPage/BecomeTutor/ChildComponent/HelpModalBtn";
import { useDebounceEffect } from "../../LandingPage/BecomeTutor/useDebounceEffect";
import MemberInformationContext from "../../../_helper/MemberInformation";
import { ProfilePictureRootURL } from "../../../api";
import Resizer from "react-image-file-resizer";
import { saveSingleImageToCloudinary } from "../../Common/CommonPhotoFn";
import { toast } from "react-toastify";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 30,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const ChangeProfilePicture = ({ header }) => {
  const {
    step,
    handleSkipOrNext,
    profilePhoto,
    setProfilePhoto,
    handleEmailVerified,
    isEmailVerified,
  } = useContext(BecomeTutorContext);
  const { profileUrl, setProfileUrl } = useContext(MemberInformationContext);

  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(4 / 4);
  const [img64, setImg64] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [proPhoto, setProPhoto] = useState();
  const [newImage, setNewImage] = useState("");
  const [imageName, setImageName] = useState("");
  const imageInputRef = useRef();

  const fileChangedHandler = (imgSrc) => {
    var fileInput = false;
    if (imgSrc) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          imgSrc,
          400,
          400,
          "JPEG",
          100,
          0,
          (uri) => {
            setNewImage(uri);
          },
          "base64",
          300,
          300
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      fileChangedHandler(e.target.files[0]);
      setImageName(e.target.files[0]?.name);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
      const base64Image = previewCanvasRef.current?.toDataURL("image/jpeg", 1);
      setImg64(base64Image);
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleSavePhoto = async () => {
    setIsLoading(true);
    const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

    if (_tuteair?._id && imgSrc) {
      var file = dataURLtoFile(img64, imageName + ".jpeg");

      // const formData = new FormData();
      // formData.append("document", file);
      // formData.append("prev_photo", _tuteair?.prev_photo);
      // formData.append("userId", _tuteair?._id);

      const { imageUrl, uploadedUrl } = await saveSingleImageToCloudinary(
        file,
        `profile-image/${_tuteair?._id}`
      );

      const value = {
        imageInfo: imageUrl?.imageInfo,
        storageProvider: imageUrl?.storageProvider,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL_DEV}/public/profile-photo-save/${_tuteair?._id}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${_tuteair?.token}`,
          },
        }
      );

      if (res?.data) {
        if (res.status === 200) {
          toast.success(res?.data?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          localStorage.setItem("_userPhoto", img64);
          localStorage.setItem("picture2", img64);
          setProfilePhoto(img64);
          setProfileUrl(img64);

          localStorage.setItem(
            "_tuteair_newTutor",
            JSON.stringify({
              ..._tuteair,
              prev_photo: res?.data?.prev_photo,
              currentStep: 7,
            })
          );

          setImgSrc("");
          imageInputRef.current.value = "";
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      alert("Select image again");
      setIsLoading(false);
    }
  };

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    // var picture2 = localStorage.getItem("picture2");
    // if (picture2 && picture2 !== " " && picture2 !== "") {
    //   const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    //   localStorage.setItem(
    //     "_tuteair_newTutor",
    //     JSON.stringify({
    //       ..._tuteair,
    //       currentStep: 7,
    //     })
    //   );
    //   setProfilePhoto(picture2);
    // } else {
    //   setProfilePhoto("");
    // }
    let pro_pic = localStorage.getItem("picture2");
    if (profileUrl) {
      let pro_pic = localStorage.getItem("picture2");

      if (pro_pic && pro_pic.includes("googleusercontent")) {
        setProfilePhoto(pro_pic);
      } else {
        setProfilePhoto(profileUrl);
      }
    } else if (pro_pic && pro_pic !== " " && pro_pic !== "") {
      setProfilePhoto(pro_pic);
    }
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H2 attrH2={{ className: "txt-primary" }}>{header}</H2>
        </CardHeader>
        <CardBody>
          <div className="input-cropper">
            <Input
              type="file"
              onChange={onSelectFile}
              className="form-control"
              ref={imageInputRef}
              accept="image/*"
              title="Choose a Profile Picture"
            />
            <div className="f-10 m-t-5">
              Please upload picture in only{" "}
              <span className="txt-warning">JPG, JPEG</span> or{" "}
              <span className="txt-warning">PNG</span> format
            </div>
          </div>

          {Boolean(imgSrc) && (
            <div>
              <Col className="d-flex justify-content-center">
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={newImage}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Col>
            </div>
          )}

          <div className="py-2 row">
            <Col sm={12}>Your Photo:</Col>
            <div>
              <Col sm={12} className="d-flex justify-content-center">
                {Boolean(completedCrop) && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: completedCrop.height,
                    }}
                  />
                )}

                {!completedCrop && profileUrl && (
                  <img
                    src={profileUrl}
                    alt=""
                    style={{ width: "200px", height: "200px" }}
                  />
                )}
              </Col>
            </div>
          </div>
          <Row
            style={{
              float: "right",
            }}
            className="py-1 m-t-30"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn  btn-lg btn-success  mx-2"
                disabled={imgSrc === "" || isLoading}
                onClick={(e) => handleSavePhoto()}
              >
                {isLoading ? "Uploading..." : "Change Picture"}
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* </Col> 
       </Row>*/}
      {/* help modal  */}
      {/* <HelpModal heading={helpModalHeading} body={helpModalBody} /> */}
    </Fragment>
  );
};

export default ChangeProfilePicture;
