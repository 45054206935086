import axios from 'axios';
import { API_URL_V1 } from '../../api';

const client = axios.create({ baseURL: API_URL_V1 })

const getToken = () => {
    var user = JSON.parse(localStorage.getItem("tuteAirUser"));
    const currentUserToken = user?.accessToken;
    return currentUserToken;
}

export const axiosRequest = ({ ...options }) => {

    client.defaults.headers.common.Authorization = `Bearer ${getToken()}`

    const onSuccess = response => response
    const onError = error => {
        // optionaly catch errors and add additional logging here
        //console.log("axiosRequest - error called")
        return Promise.reject(error);
    }

    return client(options).then(onSuccess).catch(onError)
}