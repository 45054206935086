import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Button,
  Input,
} from "reactstrap";
import { axiosRequest } from "../../redux/utils/axios-utils";
import { useDispatch, useSelector } from "react-redux";
import { H5, P, Image, Btn } from "../../AbstractElements";
import {
  useGetMemberInfoByIdQuery,
  useUpdateMemberBioMutation,
} from "../../redux/features/member/memberApi";
import { ProfilePictureRootURL } from "../../api";
import { Edit, Edit2, Edit3 } from "react-feather";
import { useWindowWidth } from "../Chat/NewChat/inbox/useWindowWidth";

export const AboutMe = () => {
  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo } = useGetMemberInfoByIdQuery(user);
  let [updateMemberBio] = useUpdateMemberBioMutation();
  const { memberType } = useSelector((state) => state.menu) || {};
  const [currentAbout, setCurrentAbout] = useState("");
  const [prevAbout, setPrevAbout] = useState("");
  const [about, setAbout] = useState({});
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [editError, setEditError] = useState(false);
  const [profileUrl, setProfileUrl] = useState("");
  const [countCharacter, setCountCharacter] = useState(0);
  const [pastTextLimit, setPastTextLimit] = useState("");
  const width = useWindowWidth();

  useEffect(() => {
    if (memberInfo) {
      setAbout(memberInfo?.about);
      if (memberType === "tutor") {
        setCurrentAbout(memberInfo?.about?.tutorBio);
        setPrevAbout(memberInfo?.about?.tutorBio);
      } else {
        setCurrentAbout(memberInfo?.about?.studentBio);
        setPrevAbout(memberInfo?.about?.studentBio);
      }
    }
    // changing membertype doesn't change width. Fix it
  }, [memberInfo, memberType]);

  useEffect(() => {
    if (
      memberInfo &&
      memberInfo?.media?.profilePicture?.imageInfo?.secure_url
    ) {
      setProfileUrl(memberInfo?.media?.profilePicture?.imageInfo?.secure_url);
    } else if (memberInfo && ProfilePictureRootURL) {
      if (memberInfo?.media?.picture) {
        const profUrl = ProfilePictureRootURL + memberInfo.media.picture;
        setProfileUrl(profUrl);
      }
    }
  }, [memberInfo, ProfilePictureRootURL]);

  const handleUpdateBio = () => {
    updateMemberBio({
      user: user,
      about: about,
    });
    setEdit(false);
    // useUpdateMemberBioMutation(user, about)
  };

  const handleText = (e) => {
    setSave(true);
    const text = e.target.value;
    setCountCharacter(text?.length);
    if (text?.length < 2000) {
      setEditError(false);
      setPastTextLimit("");
      setAbout((prevAboutData) => ({
        ...prevAboutData,
        [memberType === "tutor" ? "tutorBio" : "studentBio"]: text,
      }));
      setCurrentAbout(text);
    } else {
      setCountCharacter(text?.length);
      setEditError(true);
    }
  };

  const handleBack = () => {
    setEdit(false);
    setSave(false);
    setCurrentAbout(prevAbout);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const text = e.target.value;
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;

      if (selectionStart === selectionEnd) {
        if (selectionStart > 0) {
          const updatedText =
            text.slice(0, selectionStart - 1) + text.slice(selectionEnd);

          setCurrentAbout(updatedText);
          setCountCharacter(updatedText?.length);

          setAbout((prevAboutData) => ({
            ...prevAboutData,
            [memberType === "tutor" ? "tutorBio" : "studentBio"]: updatedText,
          }));

          e.target.value = updatedText;

          e.target.selectionStart = selectionStart - 1;
          e.target.selectionEnd = selectionStart - 1;

          if (updatedText.length < 2000) {
            setEditError(false);
            setPastTextLimit("");
          }
        }
      } else {
        const updatedText =
          text.slice(0, selectionStart) + text.slice(selectionEnd);

        setCurrentAbout(updatedText);
        setCountCharacter(updatedText?.length);

        setAbout((prevAboutData) => ({
          ...prevAboutData,
          [memberType === "tutor" ? "tutorBio" : "studentBio"]: updatedText,
        }));

        e.target.value = updatedText;

        e.target.selectionStart = selectionStart;
        e.target.selectionEnd = selectionStart;

        if (updatedText.length < 2000) {
          setEditError(false);
          setPastTextLimit("");
        }
      }
      // Prevent default behavior to stop the browser from removing the character twice
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    if (pastedText.length + currentAbout.length > 2000) {
      e.preventDefault();
      setPastTextLimit("Pasting text exceeds character limit.");
    }
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-center m-t-50">
        <Col sm="12" xl="8">
          <Card className="b-b-light border-5">
            <CardHeader className="b-l-primary border-3">
              <p className="f-22 text-center txt-primary">
                <strong>About Me</strong>
              </p>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  <Col
                    className={`${width < 500 ? "d-flex justify-content-center" : ""
                      }`}
                    md="3"
                  >
                    <Image
                      attrImage={{
                        className: "img-100 rounded-circle",
                        style: { height: "100px" },
                        alt: "img",
                        src: `${profileUrl}`,
                      }}
                    />
                  </Col>
                  <Col md="8">
                    {edit ? (
                      <Col className="d-flex justify-content-center align-items-center">
                        <Input
                          type="textarea"
                          className={`form-control ${editError || pastTextLimit
                              ? "border border-danger"
                              : ""
                            } `}
                          placeholder="Write something about you..."
                          rows="12"
                          value={currentAbout ?? ""}
                          onChange={(e) => handleText(e)}
                          onKeyDown={handleKeyDown}
                          onPaste={handlePaste}
                          style={{
                            backgroundColor: "#e3f2fd",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    ) : (
                      <p style={{ textAlign: "justify" }}>{prevAbout}</p>
                    )}
                    {edit && (
                      <>
                        <p
                          className={`${editError || pastTextLimit ? "font-danger" : ""
                            } f-12`}
                        >
                          Please write within 2000 characters.
                          {pastTextLimit ? (
                            pastTextLimit
                          ) : (
                            <span>
                              You have written
                              {countCharacter
                                ? countCharacter
                                : currentAbout?.length}{" "}
                              characters.
                            </span>
                          )}
                        </p>
                      </>
                    )}
                  </Col>
                  <Col
                    md="1"
                    className={`${width < 500 ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    {!edit && (
                      <Button
                        className="p-0 bg-none txt-primary border-0"
                        color="transparent"
                        onClick={() => setEdit(true)}
                      >
                        <Edit />
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              {edit && (
                <div className="m-t-15">
                  <Row>
                    <div
                      className={`${width < 500
                          ? "d-flex justify-content-between"
                          : "d-flex"
                        } m-t-15`}
                    >
                      <Col sm="3" hidden={width < 500}></Col>
                      <Col sm="4">
                        <Btn
                          attrBtn={{
                            className: "btn-block pull-left",
                            color: "dark",
                            type: "submit",
                            onClick: handleBack,
                          }}
                        >
                          {"Back"}
                          {/* {loading ? "LOADING..." : "Save changes"} */}
                        </Btn>
                      </Col>
                      <Col sm="4">
                        <Btn
                          attrBtn={{
                            className: "btn-block pull-right",
                            color: "success",
                            type: "submit",
                            // disabled: change ? (loading ? loading : loading) : true,
                            disabled: editError || !save || pastTextLimit,
                            onClick: (e) => handleUpdateBio(e),
                          }}
                        >
                          {"Save changes"}
                          {/* {loading ? "LOADING..." : "Save changes"} */}
                        </Btn>
                      </Col>
                    </div>
                  </Row>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
      {/* <div style={{ height: "300px", backgroundColor: "white" }}></div> */}
    </Fragment>
  );
};
