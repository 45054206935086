import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";

import { Row } from "reactstrap";
import { Image } from "../../AbstractElements";
import { ToggleIconss } from "../../Data/svgIcons";
import CheckContext from "../../_helper/customizer/index";
import imgg from "../../assets/images/logo/logo.png";
import HeaderContain from "./HeaderContain";
import SearchBar from "./SearchBar";

const Header = () => {
  const { toggleSidebar, setToggleIcon } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const width = useWindowSize();
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  useEffect(() => {
    if (window.innerWidth > 580) {
      openCloseSidebar();
    }

    // if (window.innerWidth <= 580) {
    //   console.log(window.innerWidth);
    //   // close_icon
    //   setToggleIcon(true);
    // }
  }, []);
  return (
    <Fragment>
      <Row className="header-wrapper m-0">
        <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
          <div className="logo-wrapper">
            <a href="#1">
              <Image
                attrImage={{
                  className: "img-fluid",
                  src: `${imgg}`,
                  alt: "12314211",
                }}
              />
            </a>
          </div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar()} id="sidebar-toggle">
            <i className="status_toggle sidebar-toggle d-flex">
              <ToggleIconss />
            </i>
          </div>
        </div>
        <SearchBar />
        <HeaderContain />
      </Row>
    </Fragment>
  );
};
export default Header;
