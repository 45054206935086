import Multiselect from "multiselect-react-dropdown";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Row, Col, Input, Label } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import { getTrackBackground, Range } from "react-range";

export default function BasicFilter({ isReset, isTutorName }) {
  const currentMemberType = localStorage.getItem("swMember");
  const {
    name,
    setName,
    age,
    setAge,
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    moonlight,
  } = useContext(FilterContext);
  const {
    getLanguages,
    languages: languageList,
    setLanguages: setLanguageList,
  } = useContext(TutorsContext);

  const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);
  const multiselectRef = useRef();

  const fnSetGrade = (value) => {
    setAge(value);
    // setEducationFilter((prev) => ({ ...prev, age: value }));
  };

  const MIN = 0;
  const MAX = 100;
  const STEP = (MAX - MIN) / 20;
  const [values, setValues] = useState([MIN, MAX]);

  const MINYEAR = 1940;
  const MAXYEAR = 2027;
  const STEPYEAR = 1;
  const [valueYear, setValueYear] = useState([MINYEAR + 10, MAXYEAR - 2]);

  useEffect(() => {
    if (values[0] == 0 && values[1] == 100) {
      // do nothing
    } else {
      fnSetGrade(values);
    }
  }, values);

  useEffect(() => {
    setName("");
  }, []);

  useEffect(() => {
    if (isReset) {
      setName("");
      setValues([MIN, MAX]);
      setGender("0");
      setLanguages([]);
      setLanguageSelectedOptions([]);
      setProfilePicExist(false);

      multiselectRef?.current?.resetSelectedValues();
    }
  }, [isReset]);

  useEffect(() => {
    if (languageList.length === 0) {
      getLanguages();
    }
    return () => {
      // setLanguageList(languageList);
    };
  }, []);

  return (
    <Fragment>
      <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
        <h4 className="f_title">
          <span>Basic</span>
        </h4>
        <Row>
          <Col sm="12" xl="12">
            <div className="row mb-1">
              {isTutorName ? (
                <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                  Tutor's Name
                </Label>
              ) : currentMemberType != "tutor" ? (
                <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                  Tutor's Name
                </Label>
              ) : (
                <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                  Student's Name
                </Label>
              )}

              <Col sm="9">
                <Input
                  type="text"
                  name="text"
                  className="form-control p-2 mb-1"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </div>
            <div className="row mb-1">
              <Label className="col-sm-3 col-form-label pt-0 mt-1" htmlFor="">
                Gender
              </Label>
              <Col sm="9" className="mt-2">
                <div
                  className="m-checkbox-inline"
                  style={{ paddingLeft: "6px" }}
                >
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline4"
                      type="radio"
                      name="radio1"
                      value="0"
                      onChange={(e) => setGender("0")}
                    />
                    <Label className="mb-0" for="radioinline4">
                      <span className="digits"> {"All"}</span>
                    </Label>
                  </div>
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline1"
                      type="radio"
                      name="radio1"
                      value="male"
                      onChange={(e) => setGender("male")}
                    />
                    <Label className="mb-0" for="radioinline1">
                      <span className="digits"> {"Male"}</span>
                    </Label>
                  </div>
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline2"
                      type="radio"
                      name="radio1"
                      value="female"
                      onChange={(e) => setGender("female")}
                    />
                    <Label className="mb-0" for="radioinline2">
                      <span className="digits"> {"Female"}</span>
                    </Label>
                  </div>
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline3"
                      type="radio"
                      name="radio1"
                      value="other"
                      onChange={(e) => setGender("other")}
                    />
                    <Label className="mb-0" for="radioinline3">
                      <span className="digits"> {"Other"}</span>
                    </Label>
                  </div>
                </div>
              </Col>
            </div>

            <div className="row mb-2">
              <Label className="col-sm-3 col-form-label " htmlFor="inputEmail3">
                Age
              </Label>
              <Col md="9" className="">
                <Range
                  values={values}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  onChange={(values) => {
                    setValues(values);
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "36px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <output style={{ marginTop: "10px" }}>{values[0]}</output>
                      <div
                        ref={props.ref}
                        style={{
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values,
                            colors: ["#ccc", "#4d8aff", "#ccc"],
                            min: MIN,
                            max: MAX,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                      <output style={{ marginTop: "10px" }}>{values[1]}</output>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "25px",
                        width: "25px",
                        borderRadius: "30px",
                        backgroundColor: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 2px 6px #AAA",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "5px",
                          backgroundColor: isDragged ? "#4d8aff" : "#CCC",
                        }}
                      />
                    </div>
                  )}
                />
              </Col>
            </div>

            <div className="row mb-1">
              <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                Language
              </Label>
              <Col sm="9">
                <Multiselect
                  className="darkMode bg-white"
                  isObject={false}
                  options={languageList}
                  showCheckbox
                  selectedValues={selectedLanguageOptions}
                  onSelect={(e) => {
                    setLanguages(e);
                  }}
                  onRemove={(e) => {
                    setLanguages(e);
                  }}
                  ref={multiselectRef}
                />
              </Col>
            </div>
            <div className="row mb-1 mt-2">
              <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                Profile Picture?
              </Label>
              <Col md={9}>
                <Input
                  checked={profilePicExist}
                  type="checkbox"
                  onChange={(e) => setProfilePicExist(e.target.checked)}
                />
              </Col>
            </div>
            {/* {currentMemberType != "tutor" && (
          <hr
            color="#337AB7"
            size="5"
            width="92%"
            style={{ position: "absolute", marginTop: "15px" }}
          />
        )} */}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
