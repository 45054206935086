import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";

export default function PaymentContain2(props) {
  const [classList, setClassList] = useState(props);

  return (
    <div className="d-flex justify-content-center">
      <Card
        style={{
          width: "100%",
          border: "1px solid #e4c5c5",
          // marginLeft: "8px",
        }}
      >
        <table className="py-3 summery_table table table-responsive-sm table-borderless">
          <tr>
            {/* <th></th> */}
            <th
              style={{ fontSize: "16px", color: "#6362E7" }}
              className="titleItem"
            >
              Tuition
            </th>
            <th></th>
            {/* <th></th> */}

            <th
              style={{ fontSize: "16px", color: "#6362E7" }}
              className="titleItem"
            >
              Amount
            </th>
            <th
              className="titleItem"
              style={{ fontSize: "16px", color: "#6362E7" }}
            >
              Commission{" "}
              <span style={{ fontSize: "12px", color: "#6362E7" }}>(10%)</span>
            </th>
            <th
              style={{ fontSize: "16px", color: "#6362E7" }}
              className="titleItem"
            >
              Sub Total
            </th>
          </tr>
          <tr>
            {/* <th></th> */}
            <th
              style={{ fontSize: "15px", color: "green" }}
              className="titleItem"
            >
              {props?.toPaidList?.map((item) => item?.toPayClassNo).length == 1
                ? "Subject"
                : "Subjects"}
            </th>
            <th
              style={{ fontSize: "15px", color: "green" }}
              className="titleItem"
            >
              Pay For{" "}
              {props?.toPaidList?.map((item) => item?.toPayClassNo) == 1
                ? "Class"
                : "Classes"}
            </th>
          </tr>
          {props?.toPaidList?.map((item, index) => (
            <tr key={index}>
              {/* <td></td> */}
              <td className="titleItem"> {item.subjectName}</td>
              <td className="titleItem">{item.toPayClassNo}</td>
              {/* <td></td> */}
              <td>{item.payableAmount}</td>
              <td className="titleItem">
                {" "}
                {(item.payableAmount * 1.1 - item.payableAmount).toFixed(2)}
              </td>
              <td>{(item.payableAmount * 1.1).toFixed(2)}</td>
            </tr>
          ))}
        </table>
      </Card>
    </div>
  );
}
