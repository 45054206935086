import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import { LI } from "../../../AbstractElements";
import { ServerURL } from "../../../api";
import { ChatSvg } from "../../../Data/svgIcons";
import { apiSlice } from "../../../redux/features/api/apiSlice";
import { useGetConversationsQuery } from "../../../redux/features/chat/conversations/conversationsApi";
import { getActiveUsers, setGlobalSocket, unseenConversations } from "../../../redux/features/chat/conversations/conversationsSlice";
import { getUpdatedSeenMessage } from "../../../redux/features/chat/messages/messagesSlice";
import { toastBottomRight } from "../../../redux/utils/tuteairCommon";
import "./Notif.css";

const ChatIcon = () => {
  const { unseensData } = useSelector((state) => state.conversations) || {};
  const dispatch = useDispatch();
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  let { data: conversations, isSuccess } = useGetConversationsQuery(_tuteair?._id);

  const { user } = useSelector((state) => state.auth) || {};
  const [myUnseenConversations, setUnseenConversations] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [connectionErrorDisplayed, setConnectionErrorDisplayed] = useState(false);

  useEffect(() => {
    const newSocket = io(ServerURL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 1,
      transports: ["websocket"],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    });

    setSocket(newSocket);
    dispatch(setGlobalSocket(newSocket));

    // emit addUser event when socket connects
    newSocket.on("connect", () => {
      if (user) {
        newSocket.emit("addUser", user);
        newSocket.on("getUsers", (users) => {
          const filterLoggedUser = users.filter((singleUser) => singleUser?.userId !== user);
          dispatch(getActiveUsers(filterLoggedUser));
        });
        newSocket.on("seenSuccess", (data) => {
          dispatch(
            getUpdatedSeenMessage({
              type: "SEEN_ALL",
              seen: true,
            })
          );

          try {
            if (data) {
              dispatch(
                apiSlice.util.updateQueryData("getMessages", data?.conversationId, (draft) => {
                  if (draft?.conversationId === data?.conversationId) {
                    const draftMessage = draft.messages.find((c) => c.messageId === data.messageId);
                    draftMessage.seenBy.push(data.receiverId);
                  }
                })
              );
            }
          } catch (error) {
            console.error(error);
          }
        });
      }
    });
    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      var unseens = conversations?.filter((x) => x.isSeen === false && x.updatedBy !== _tuteair?._id);
      dispatch(
        unseenConversations({
          type: "ALL",
          unseensData: unseens,
        })
      );
      setUnseenConversations(unseensData);
    }

    return () => {
      setUnseenConversations([]);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      setIsConnected(true);
      realtimeConversations();
      setConnectionErrorDisplayed(false); // reset the connection error state
    });

    socket.on("connect_error", (error) => {
      if (!connectionErrorDisplayed) {
        // toastNotifiy("WebSocket connection error", "warn");
        console.warn("WebSocket connection error");
        setConnectionErrorDisplayed(true);
      }
    });

    realtimeConversations();

    return () => {
      socket.off("connect");
      // socket.off("disconnect"); //commented to solve removing users from socket server after changing conversation list 
      socket.off("connect_error");
      socket.off("reconnect_attempt");
      socket.off("reconnect_error");
      socket.off("reconnect_failed");
      // socket.disconnect(); //commented to solve removing users from socket server after changing conversation list
    };
  }, [conversations?.length]);

  const realtimeConversations = async () => {
    // when new message comes- unseen conversaion list update
    try {
      socket.on("conversation", (data) => {
        if (data.data?.receiverId === _tuteair?._id) {
          var previous = [...conversations];
          // console.log(
          //   conversations,
          //   _tuteair?._id,
          //   data.data?.receiverId,
          //   "conv"
          // );
          if (data.data?.status === "updated") {
            const updatedData = previous.map((x) =>
              x.conversationId === data.data?._id
                ? {
                    ...x,
                    isSeen: false,
                    message: data.data?.message,
                    updatedBy: data.data?.updatedBy,
                    updatedTime: data.data?.updatedAt,
                    receiverId: data.data?.receiverId,
                  }
                : x
            );

            var updatedUnseens = updatedData?.filter((x) => x.isSeen === false && x.updatedBy !== _tuteair?._id);

            setUnseenConversations(updatedUnseens);
            dispatch(
              unseenConversations({
                type: "ALL",
                unseensData: updatedUnseens,
              })
            );

            toastBottomRight("You have one new message", "success");
          } else if (data.data?.status === "created") {
            toastBottomRight("You have one new message!", "success");
            var newConversations = [...conversations];
            newConversations.push(data.data);
            var unseensConv = newConversations?.filter((x) => x.isSeen === false && x.updatedBy !== _tuteair?._id);
            dispatch(
              unseenConversations({
                type: "ALL",
                unseensData: unseensConv,
              })
            );
            setUnseenConversations(unseensConv);
          }
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Fragment>
      <LI attrLI={{ className: " " }}>
        <Link to={process.env.PUBLIC_URL + "/inbox/"}>
          <div className="notification-box">
            <ChatSvg />
            <span className="badge rounded-pill badge-warning">{unseensData?.length}</span>
          </div>
        </Link>
      </LI>
    </Fragment>
  );
};
export default ChatIcon;
