import React, { Fragment } from "react";
import FindTutorsContain from "../../Component/FindTutors";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const FindTutors = () => {
  useLogoutSession();
  return (
    <Fragment>
      <FindTutorsContain />
    </Fragment>
  );
};
export default FindTutors;
