import React, { Fragment } from "react";
import Inbox from "../../../Component/Chat/NewChat/Inbox";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const InboxContain = () => {
  useLogoutSession();
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Chat" title=" Chat App" /> */}
      <div className="pt-2">
        <Inbox />
      </div>
    </Fragment>
  );
};
export default InboxContain;
