import React, { Fragment, useContext, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { Btn } from "../../../AbstractElements";
import { userLoggedOut } from "../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import MemberInformationContext from "../../../_helper/MemberInformation";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import "./AccountSettings.css";

const ChangePassword = () => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePasswordConfirm, setTogglePasswordConfirm] = useState(false);
  const { clearBecomeTutorContext } = useContext(BecomeTutorContext);
  const { clearBecomeStudentContext } = useContext(BecomeStudentContext);
  const { clearPersonalAddressProvider } = useContext(PersonalAddressContext);
  const { clearMemberInfoContext } = useContext(MemberInformationContext);

  const [prevPassword, setPrevPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState(
    "Minimum 8 characters, at least 1 letter, 1 number and 1 special character"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fnIsValidPassword = (string) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&',-])[A-Za-z\d@$!%*#?&',-]{8,20}$/.test(
      string
    );

  const fnChangedPassword = async (prevPassword, password, confirmPassword) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once changed, you need to login again!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        if (password === confirmPassword) {
          var response = await axiosRequest({
            url: "/newMember/settings/changed-password",
            method: "put",
            data: {
              prevPassword,
              newPassword: confirmPassword,
            },
          });
          if (response?.status === 200) {
            SweetAlert.fire({
              title: "Successfully changed password!",
              icon: "success",
            });
            dispatch(userLoggedOut());
            dispatch(clearMemberMenus());
            clearBecomeTutorContext();
            clearBecomeStudentContext();
            clearPersonalAddressProvider();
            clearMemberInfoContext();
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/login?logout=1`;
          } else {
            SweetAlert.fire({
              title: response.data,
              icon: "error",
            });
          }
        } else {
          SweetAlert.fire("Password didn't matched!");
        }
      } else {
        SweetAlert.fire("Nothing is changed!");
      }
    });
  };

  const handleAccountSetup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    await fnChangedPassword(prevPassword, password, confirmPassword);
    setButtonDisabled(false);
  };

  const handleConfirmPasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    setButtonDisabled(!isValidPwd);
    fnValidatePwd();
  };
  const handlePasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    setButtonDisabled(!isValidPwd);
    fnValidatePwd();
  };

  const fnValidatePwd = () => {
    if (password !== confirmPassword) {
      setButtonDisabled(true);
      setShowErrorMessage(true);
      setIsCPasswordDirty(true);
    } else {
      setIsCPasswordDirty(false);
      if (isValidPassword) {
        setButtonDisabled(false);
      }
    }

  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row className="">
            <h3 className="tab_title">Change Password</h3>
            <FormGroup>
              <label className="form-label">{"Current Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type="password"
                  required=""
                  autoComplete="off"
                  placeholder="Enter Current Password"
                  value={prevPassword}
                  onChange={(e) => setPrevPassword(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <label className="form-label">{"New Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type={togglePassword ? "text" : "password"}
                  required=""
                  placeholder="*********"
                  value={password}
                  onChange={handlePassword}
                  onKeyUp={handlePasswordKeyUp}
                />
              </InputGroup>
              {showErrorMessage && !isValidPassword ? (
                <div className="text-danger">{pwdErrorMessage}</div>
              ) : (
                ""
              )}
              {
                password !== '' &&
                <div
                  className="text-primary fw-bold mt-2" role="button"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  <span >{togglePassword ? "Hide password" : "Show password"}</span>
                </div>
              }
            </FormGroup>
            <FormGroup>
              <label className="form-label">{"Confirm New Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type={togglePasswordConfirm ? "text" : "password"}
                  required=""
                  placeholder="*********"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  onKeyUp={handleConfirmPasswordKeyUp}
                />
              </InputGroup>
              {showErrorMessage && isCPasswordDirty ? (
                <div className="text-danger"> Passwords did not match </div>
              ) : (
                ""
              )}
              {
                confirmPassword !== '' &&
                <div
                  className="text-primary fw-bold mt-2" role="button"
                  onClick={() => setTogglePasswordConfirm(!togglePasswordConfirm)}
                >
                  <span >{togglePasswordConfirm ? "Hide password" : "Show password"}</span>
                </div>
              }
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn
            attrBtn={{
              color: "warning",
              className: "m-r-15",
              disabled: isButtonDisabled,
              type: "submit",
              onClick: (e) => handleAccountSetup(e),
            }}
          >
            Update Changes
          </Btn>
          {/* <Btn attrBtn={{ color: "light", type: "submit" }}>{Cancel}</Btn> */}
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default ChangePassword;
