import React from "react";
import { Card, CardHeader, CardTitle } from "reactstrap";

export default function PreferredLanguage({data}) {
  return (
    <div>
      {/* <Card body> */}
      <Card body className="card-absolute b-t-light border-5">
        <CardHeader style={{padding: "5px 10px"}} className="bg-primary">
          Language
        </CardHeader>
        {/* <CardTitle tag="h4">Preferred Language</CardTitle> */}
        <div className="m-t-25">
          <CardTitle className="">
            <span>
              {data?.map((item, index) => (
                <span
                  className="badge badge-light-primary"
                  style={{ margin: "0px 3px 2px 0px" }}
                  key={index}
                >
                  {item}
                </span>
              ))}
            </span>
          </CardTitle>
        </div>
      </Card>
    </div>
  );
}
