import React, { Fragment, useContext, useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
} from "reactstrap";
import { H2 } from "../../../AbstractElements";
import { useSelector } from "react-redux";
import SetUsersLocation from "../../FindTutors/SetUsersLocation";
import AddressInput from "./AccountComponents/AddressInput";
import {
  useGetMemberInfoByIdQuery,
  useUpdateMemberAddressMutation,
} from "../../../redux/features/member/memberApi";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import { toastNotifiy } from "../../../Services/AuthVerify";

const Address = ({ header }) => {
  const [edit, setEdit] = useState(true);
  const [saveEnable, setSaveEnable] = useState(false);
  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo } = useGetMemberInfoByIdQuery(user);
  let [updateMemberAddress] = useUpdateMemberAddressMutation();
  const [renderComponent, setRenderComponent] = useState(false);

  const {
    address1,
    setAddress1,
    address2,
    setAddress2,
    area,
    setArea,
    city,
    setCity,
    state,
    setState,
    postalCode,
    setPostalCode,
    country,
    setCountry,
    latitude,
    longitude,
    getPreviousLocation,
    setAddressData,
  } = useContext(PersonalAddressContext);
  const userEmail = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  useEffect(() => {
    if (memberInfo) {
      let { address } = memberInfo;
      let { address1, address2, area, city, state, postalCode, country } =
        address;
      setAddress1(address1);
      setAddress2(address2);
      setArea(area);
      setCity(city);
      setState(state);
      setPostalCode(postalCode);
      setCountry(country);
    }
  }, [memberInfo]);

  const handleStreetAddress = (value) => {
    setAddress1(value);
    setAddress2("");
  };

  const validateFields = () => {
    if (!address1) {
      setSaveEnable(false);
      toastNotifiy("Street address can't be empty", "warn");
      return false;
    }
    if (!city) {
      setSaveEnable(false);
      toastNotifiy("City can't be empty", "warn");
      return false;
    }
    if (!postalCode || postalCode === null || postalCode === "") {
      setSaveEnable(false);
      toastNotifiy("Post code can't be empty", "warn");
      return false;
    }
    if (!country) {
      setSaveEnable(false);
      toastNotifiy("Country can't be empty", "warn");
      return false;
    }
    return true;
  };

  const handleSaveChanges = () => {
    const fieldsValid = validateFields();
    if (fieldsValid) {
      const address = {
        address1: address1,
        address2: address2,
        area: area,
        city: city,
        postalCode: postalCode,
        state: state,
        country: country,
        latitude: latitude,
        longitude: longitude,
      };
      updateMemberAddress({
        user: user,
        address: address,
      }).then(() => {
        setSaveEnable(false);
        toastNotifiy("Address saved successfully!", "success");
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderComponent(true);
    }, 3000);
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <H2 attrH2={{ className: "txt-primary" }}>{header}</H2>
          {!edit && (
            <Button
              className="p-0 bg-none txt-primary border-0 d-flex align-items-center"
              color="transparent"
              onClick={() => setEdit(true)}
            >
              <Edit size={15} />
              <span className="m-l-5">Edit</span>
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <div className="mb-1">
              <Label className="col-sm-12 col-form-label txt-danger">
                <b>
                  {" "}
                  {1 == 0 && 1 == 0 ? (
                    <i>
                      Please allow your location from browser settings to
                      visualize the map here and set your location on map.
                    </i>
                  ) : (
                    <i>
                      Search your location on map to auto-complete address
                      fields.
                    </i>
                  )}
                </b>
              </Label>
            </div>
          </Row>
          <Row>
            {renderComponent && (
              <SetUsersLocation
                updateAddress={true}
                setSaveEnable={setSaveEnable}
              />
            )}
          </Row>
          <Row>
            <AddressInput
              label="Street Address"
              value={
                address1 && address2
                  ? `${address1} ${address2}`
                  : !address2
                  ? `${address1}`
                  : !address1
                  ? `${address2}`
                  : ``
              }
              edit={edit}
              handleFunction={handleStreetAddress}
              setSaveEnable={setSaveEnable}
            ></AddressInput>
          </Row>
          <Row>
            <AddressInput
              label="Area"
              value={!area ? "" : area}
              edit={edit}
              optional={true}
              handleFunction={setArea}
              setSaveEnable={setSaveEnable}
            ></AddressInput>
          </Row>
          <Row>
            <Col md="6" xs="12">
              <AddressInput
                label="City"
                value={!city ? "" : city}
                edit={edit}
                handleFunction={setCity}
                setSaveEnable={setSaveEnable}
              ></AddressInput>
            </Col>
            <Col md="6" xs="12">
              <AddressInput
                label="Post Code"
                value={!postalCode ? "" : postalCode}
                edit={edit}
                handleFunction={setPostalCode}
                setSaveEnable={setSaveEnable}
              ></AddressInput>
            </Col>
          </Row>
          <Row>
            <Col md="6" xs="12">
              <AddressInput
                label="State"
                value={!state ? "" : state}
                edit={edit}
                optional={true}
                handleFunction={setState}
                setSaveEnable={setSaveEnable}
              ></AddressInput>
            </Col>
            <Col md="6" xs="12">
              <AddressInput
                label="Country"
                value={!country || country === userEmail.email ? "" : country}
                edit={edit}
                handleFunction={setCountry}
                setSaveEnable={setSaveEnable}
              ></AddressInput>
            </Col>
          </Row>
          <Row>
            <div className="mb-1">
              <Label className="col-sm-12 col-form-label txt-danger f-10">
                <i>
                  *Tuteair keeps your address private. Only your location
                  pointer will be shown publicly in search results.
                </i>
              </Label>
            </div>
          </Row>
          {edit && (
            <Row className="m-t-15">
              {/* <Col className="d-flex justify-content-start">
                  <Button
                    className="border-0 d-flex align-items-center"
                    color="dark"
                    onClick={() => setEdit(true)}
                    // onClick={() => setEdit(false)}
                    >
                      Back
                  </Button>
                </Col> */}
              <Col className="d-flex justify-content-end">
                <Button
                  className="border-0 d-flex align-items-center"
                  color="success"
                  // onClick={() => setEdit(false)}
                  onClick={handleSaveChanges}
                  disabled={!saveEnable}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Address;
