import moment from "moment";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { MoreHorizontal } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table, Row } from "reactstrap";
import { H5, LI, P, UL } from "../../../AbstractElements";
import { Today } from "../../../Constant";
import ClassSchedulerContext from "../../../_helper/ClassScheduler";
import ClassListView from "../../Schedule/Calendar/ClassListView/ClassListView";

const UpdateClassStatus = (props) => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const {
    classList,
    getAllScheduleEvents,
    tutorClassList,
    setTutorClassList,
    studentClassList,
    setStudentClassList,
    setIsCompleteClassList,
  } = useContext(ClassSchedulerContext);
  const [incompleteStatusList, setIncompleteStatusList] = useState([]);

  useEffect(() => {
    getAllScheduleEvents();

    // Clean up function to reset tutorClassList and studentClassList on unmount
    return () => {
      setTutorClassList([]);
      setStudentClassList([]);
    };
  }, []);

  useEffect(() => {
    getAllScheduleEvents();
  }, [memberType]);

  useEffect(() => {
    if (memberType === "student") {
      if (studentClassList && studentClassList?.length > 5) {
        setIncompleteStatusList(studentClassList?.slice(0, 6));
      } else {
        setIncompleteStatusList(studentClassList);
      }
    } else {
      if (tutorClassList && tutorClassList?.length > 5) {
        setIncompleteStatusList(tutorClassList?.slice(0, 6));
      } else {
        setIncompleteStatusList(tutorClassList);
      }
    }
  }, [memberType, studentClassList, tutorClassList]);

  return (
    <Fragment>
      <Row>
        <ClassListView
          classList={incompleteStatusList}
          memberType={memberType}
          dashboardTitle="Class completion status not updated"
          type="complete"
          dashboard="dashboard"
        />
        {incompleteStatusList?.length > 5 && (
          <span className="text-center mb-1" colSpan={2}>
            <Link
              to={`${process.env.PUBLIC_URL}/calendar?tab=2`}
              onClick={() => setIsCompleteClassList(true)}
            >
              More Classes
            </Link>
          </span>
        )}
      </Row>
    </Fragment>
  );
};
export default UpdateClassStatus;
