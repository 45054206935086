import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { WorkExpEditApi, WorkExpSaveApi } from "../../../../api/index";
import { useGetMemberInfoByIdQuery } from "../../../../redux/features/member/memberApi";
import { toastMessage } from "../../../../redux/utils/tuteairCommon";
import { authHeader } from "../../../../Services/AuthVerify";
import "../../../../style/custom-css/PersonalInfo.css";

const TutorExperienceModal = (props) => {
  const [position, setPosition] = useState("");
  const [organization, setOrganization] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [running, setIsRunning] = useState();

  const [endDate, setEndDate] = useState(new Date());

  const [aboutWorkExp, setAboutWorkExp] = useState("");
  const [loading, setLoading] = useState(false);
  const [dateOfBirth, setdateOfBirth] = useState(new Date("01/01/2000"));

  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo, refetch } = useGetMemberInfoByIdQuery(user);

  useEffect(() => {
    if (memberInfo) {
      setdateOfBirth(
        new Date(memberInfo?.doB === "" ? "01/01/2000" : memberInfo?.doB)
      );
    }
  }, [memberInfo]);

  useEffect(() => {
    setPosition(props.expSingleData.position);
    setOrganization(props.expSingleData.organization);
    setStartDate(new Date(props.expSingleData.startDate));
    setIsRunning(props.expSingleData.running);
    setEndDate(new Date(props.expSingleData.endDate));
    setAboutWorkExp(props.expSingleData.aboutWorkExp);
  }, [props.expSingleData]);

  const handleStartDateChange = (date) => {
    setStartDate(new Date(date));
  };
  const handleEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const checkboxHandler = async (isChk) => {
    setIsRunning(isChk);
    if (isChk === true) setEndDate("");
    else setEndDate(new Date());
  };

  const validatedField = () => {
    if (!position || !organization || !startDate) {
      toastMessage("Fillup required field!", "warn");
      return false;
    } else if (!isNaN(position)) {
      toastMessage("Enter valid position name!", "warn");
      return false;
    } else if (!isNaN(organization)) {
      toastMessage("Enter valid organization name!", "warn");
      return false;
    } else if (!isNaN(aboutWorkExp)) {
      toastMessage("Enter valid work experience!", "warn");
      return false;
    } else {
      return true;
    }
  };

  const addNewExperience = async (e) => {
    e.preventDefault();

    if (validatedField()) {
      setLoading(true);
      if (window.confirm("Are you sure to save this information?")) {
        var req = {
          position,
          organization,
          startDate,
          endDate,
          running,
          aboutWorkExp,
        };

        const response = await axios.post(WorkExpSaveApi, req, {
          headers: {
            Authorization: authHeader(),
          },
        });

        if (response.status === 201) {
          if (response.data && response.data) {
            refetch();
            toastMessage("Experience Info Added successfully", "success");
            props.toggler();
          } else {
            toastMessage("Failed to add", "warn");
          }
          setLoading(false);
        } else {
          setLoading(false);
          toastMessage("something went wrong", "warn");
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleUpdateExperience = async (e) => {
    e.preventDefault();
    var req = JSON.stringify({
      position,
      organization,
      startDate,
      endDate,
      running,
      aboutWorkExp,
    });

    if (validatedField()) {
      setLoading(true);
      if (window.confirm("Are you sure to update this information?")) {
        const res = await axios.put(
          WorkExpEditApi + "/" + props.expSingleData.id,
          req,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: authHeader(),
            },
          }
        );

        if (res.status === 201) {
          if (res.data && res.data) {
            refetch();
            toastMessage("Experiences Updated Successfully", "success");
            props.toggler();
          } else {
            toastMessage("Failed to add", "warn");
          }
          setLoading(false);
        } else {
          setLoading(false);
          toastMessage("something went wrong", "warn");
        }
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk text-nowrap"
                        htmlFor=""
                      >
                        Position
                      </Label>
                      <Col sm="10">
                        <Input
                          className="form-control"
                          type="text"
                          required
                          placeholder="Enter Position"
                          value={position}
                          onChange={(e) => setPosition(e.target.value)}
                        />
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor=""
                      >
                        Organization
                      </Label>
                      <Col sm="10">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Enter Organization"
                          value={organization}
                          onChange={(e) => setOrganization(e.target.value)}
                        />
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk text-nowrap"
                        htmlFor="inputEmail3"
                      >
                        Start Date
                      </Label>
                      <Col sm="4">
                        <DatePicker
                          className="form-control digits"
                          selected={startDate}
                          minDate={new Date(dateOfBirth)}
                          maxDate={endDate}
                          onChange={handleStartDateChange}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy"
                        />
                      </Col>
                      {endDate && !running && (
                        <Label
                          className="col-sm-2 col-form-label asterisk text-nowrap"
                          htmlFor="inputEmail3"
                        >
                          End Date
                        </Label>
                      )}
                      <Col sm="4">
                        {endDate && !running && (
                          <DatePicker
                            className="form-control digits"
                            selected={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            onChange={handleEndDateChange}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd MMMM yyyy"
                          />
                        )}
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label text-nowrap"
                        htmlFor="inputEmail3"
                      >
                        Is Running
                      </Label>
                      <Col sm="10">
                        <Input
                          id=""
                          type="checkbox"
                          checked={running === true}
                          onChange={(event) => {
                            checkboxHandler(event.target.checked);
                          }}
                        />
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        About Work Experience
                      </Label>
                      <Col sm="10">
                        <Input
                          type="textarea"
                          className="form-control"
                          rows="3"
                          value={aboutWorkExp}
                          onChange={(e) => setAboutWorkExp(e.target.value)}
                        />
                      </Col>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) =>
                props.edit ? handleUpdateExperience(e) : addNewExperience(e),
            }}
          >
            {loading ? "LOADING..." : "Save changes"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TutorExperienceModal;
