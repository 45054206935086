import React, { useEffect, useState } from "react";
import { Star } from "react-feather";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { axiosRequest } from "../../../redux/utils/axios-utils";

const ReviewViewModal = ({ isOpen, toggle, isEdit, item, isReviewedByMe }) => {
  const [rating, setRating] = useState(4);
  const [reviewText, setReviewText] = useState("");
  const [reviewTime, setReviewTime] = useState(null);
  const { memberType } = useSelector((state) => state.menu) || {};

  const [isLoading, setIsLoading] = useState(false);

  const fetchFeedback = async (item) => {
    const userId = memberType === "student" ? item?.studentId : item?.tutorId;
    const tuitionId = item.tuitionId;
    const classId = item.classId;
    try {
      setIsLoading(true);
      const res = await axiosRequest({
        url: `/review/single/${userId}/${tuitionId}/${classId}`,
        method: "get",
      });
      if (res.status === 200 && res.data) {
        var feedback = res.data.feedback;
        if (memberType === "student") {
          setRating(feedback.fromTutor?.rating);
          setReviewText(feedback.fromTutor?.text);
          setReviewTime(feedback.fromTutor?.time);
        } else {
          setRating(feedback.fromStudent?.rating);
          setReviewText(feedback.fromStudent?.text);
          setReviewTime(feedback.fromStudent?.time);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isReviewedByMe) {
      fetchFeedback(item);
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Modal isOpen={isOpen} toggle={toggle}>
          {/* <ModalHeader toggle={props.toggle}>Leave a Review</ModalHeader> */}
          <ModalBody>
            {!isReviewedByMe ? (
              <>
                <p>
                  <b>
                    {memberType === "student"
                      ? item.tutorName
                      : item.studentName}
                  </b>{" "}
                  has given feedback for this class, but you will not be able to
                  see{" "}
                  <b>
                    {memberType === "student"
                      ? item.tutorName
                      : item.studentName}
                  </b>
                  's review until you have submitted your own review.
                </p>
              </>
            ) : (
              <>
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center mb-3 justify-content-center"></div>

                    <div className="d-flex align-items-center mb-3">
                      <Star
                        size={24}
                        className={`mr-2 ${rating >= 1 ? "text-warning" : ""}`}
                      />
                      <Star
                        size={24}
                        className={`mr-2 ${rating >= 2 ? "text-warning" : ""}`}
                      />
                      <Star
                        size={24}
                        className={`mr-2 ${rating >= 3 ? "text-warning" : ""}`}
                      />
                      <Star
                        size={24}
                        className={`mr-2 ${rating >= 4 ? "text-warning" : ""}`}
                      />
                      <Star
                        size={24}
                        className={`mr-2 ${rating >= 5 ? "text-warning" : ""}`}
                      />
                      <span className="px-3">
                        {memberType === "student"
                          ? item.tutorName
                          : item.studentName}
                      </span>
                    </div>
                    <div className="d-flex align-items-center mb-3 ">
                      <label htmlFor="1" className="col-md-6">
                        Reviewed on{" "}
                        {reviewTime &&
                          new Date(reviewTime).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                      </label>
                    </div>
                    <label htmlFor="">{reviewText}</label>
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <div>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ReviewViewModal;
