import React, { useState } from 'react'
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import HelpContentTab from './HelpContentTab';
import { Container } from "reactstrap";

const HelpTab = () => {
  const [pillWithIconTab, setpillWithIconTab] = useState('1');
  return (
    // <Col sm="12" xl="6 xl-100">
    // <Col md={10} className="offset-1">
    <Col sm="12">
      <Container>
        {/* <Card className="height-equal"> */}
        {/* <CardHeader className='pb-0'>
            <H5>PillTabsWithIcon</H5>
            <H5>{PillTabsWithIcon}</H5>
          </CardHeader> */}
        {/* <CardBody> */}
        <Nav className="nav-pills justify-content-center mb-4">
          <NavItem  >
            <NavLink href="#javascript" className={pillWithIconTab === '1' ? 'active' : ''} onClick={() => setpillWithIconTab('1')}><i class="icofont icofont-teacher"></i>Tutor
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink href="#javascript" className={pillWithIconTab === '2' ? 'active' : ''} onClick={() => setpillWithIconTab('2')}><i class="icofont icofont-student"></i>Student</NavLink>
          </NavItem>
        </Nav>
        {/* <TabContent activeTab={pillWithIconTab}> */}
        <TabContent activeTab={pillWithIconTab}>
          <TabPane className="fade show" tabId="1">
            {/* <HelpAccordion data={tutorHelp}></HelpAccordion> */}
            {/* --- Help Tab for Tutor */}
            <HelpContentTab
              acType="Tutor"
              oppositeUser="Student"
            ></HelpContentTab>
          </TabPane>
          <TabPane tabId="2">
            {/* <HelpAccordion data={studentHelp}></HelpAccordion> */}
            {/* --- Help Tab for Student */}
            <HelpContentTab
              acType="Student"
              oppositeUser="Tutor"
            ></HelpContentTab>
          </TabPane>
        </TabContent>
        {/* </CardBody> */}
        {/* </Card> */}
      </Container>
    </Col>
  )
}

export default HelpTab;
