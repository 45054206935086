import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import TutorTabs from "../../../Component/AccountNew/Tutor/index";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const TutorAccount = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="TutorNewAccount" title="Tutor Account" />
      {/* <TutorAccountContain /> */}
      <TutorTabs />
    </Fragment>
  );
};

export default TutorAccount;
