//worked till now
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";

import { Info } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { GetConversationApi } from "../../../api";
import { useAddConversationMutation, useEditConversationMutation } from "../../../redux/features/chat/conversations/conversationsApi";
import { useAddNewTuitionMutation } from "../../../redux/features/chat/tuitions/tuitionsApi";
import { authHeader } from "../../../Services/AuthVerify";
import { AddNotification } from "../../../Services/Notification.Service";
import "../../../style/custom-css/PersonalInfo.css";

const ContactMemberModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [tuitions, setTuitions] = useState([]);
  const [tuitionsData, setTuitionsData] = useState([]);
  // post
  const [selectedTuition, setSelectedTuition] = useState("");
  const [tuitionPlace, setTuitionPlace] = useState("");
  const [budget, setBudget] = useState("");
  const [currency, setCurrency] = useState(null);
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [autoMessage, setAutoMessage] = useState("");
  const [probableStartingDate, setProbableStartingDate] = useState(new Date());
  const { userId, name } = props.photoIndex || "";
  const [conversation, setConversation] = useState(undefined);
  const [editable, setEditable] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth) || {};
  const navigate = useNavigate();
  const [sendDisable, setSendDisable] = useState(false);

  const [addConversation, { isSuccess: isAddConversationSuccess, error: err1 }] = useAddConversationMutation();
  const [editConversation, { isSuccess: isEditConversationSuccess, error: err2 }] = useEditConversationMutation();

  const [addNewTuition, { isSuccess: isSaveTuition, error: err3 }] = useAddNewTuitionMutation();

  //validate budget
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValidBudget, setIsValidBudget] = useState(false);
  const [budgetErrorMessage, setBudgetErrorMessage] = useState("budget cannot be negative");

  const fnIsValidBudget = (Number) => {
    if (Number >= 0) return true;
    else return false;
  };

  useEffect(() => {
    if (!fnIsValidBudget(budget)) {
      setIsValidBudget(false);
      setShowErrorMessage(true);
    } else {
      setIsValidBudget(false);
      setShowErrorMessage(false);
    }
  }, [budget]);

  useEffect(() => {
    setSelectedTuition("");
    setCurrency(null);
    fnSetInitialField();
    setUserMessage(`Hi ${name}, I am looking for a tutor. Please let me know if you are available to teach.`)
    return () => {
      setTuitions([]);
    };
  }, []);

  useEffect(() => {
    if (isAddConversationSuccess || isEditConversationSuccess || isSaveTuition) {
      setLoading(false);
      if (isEditConversationSuccess) {
        navigate(
          `${process.env.PUBLIC_URL}/inbox/` // {conversation[0].conversationId
        );
      } else if (isAddConversationSuccess) {
        navigate(`${process.env.PUBLIC_URL}/inbox/`);
      } else if (isSaveTuition) {
        navigate(`${process.env.PUBLIC_URL}/inbox/`);
      } else {
        setLoading(false);
        console.log(err1, err2, err3);
        alert("Something went wrong");
      }
    }
  }, [isAddConversationSuccess, isEditConversationSuccess, isSaveTuition, conversation, navigate]);

  // changes the default message to start conversation

  useEffect(() => {
    let selectedSubject = "";
    let selectedBudget = "";
    // create budget message
    if (budget) {
      selectedBudget = `[Budget: ${budget} USD/class]`;
    }
    else {
      selectedBudget = "";
    }

    // create subject message
    if (selectedTuition?.label) {
      selectedSubject = `[Subject: '${selectedTuition?.label?.toString().trim()}']`;
    }
    else if (selectedTuition.length === 0) {
      selectedBudget = "";
      selectedSubject = "";
      setBudget("");
    }
    setAutoMessage(`${selectedSubject} ${selectedBudget}`);
    return;
  }, [selectedTuition, budget]);

  useEffect(() => {
    setMessage(`${autoMessage} ${userMessage}`)
  }, [userMessage, autoMessage]);

  // useEffect(() => {
  //   if (
  //     isAddConversationSuccess ||
  //     isEditConversationSuccess ||
  //     isSaveTuition
  //   ) {
  //     setLoading(false);
  //     if (isEditConversationSuccess) {
  //       navigate(
  //         `${process.env.PUBLIC_URL}/inbox/` // {conversation[0].conversationId
  //       );
  //     } else if (isAddConversationSuccess) {
  //       navigate(`${process.env.PUBLIC_URL}/inbox/`);
  //     } else if (isSaveTuition) {
  //       navigate(`${process.env.PUBLIC_URL}/inbox/`);
  //     } else {
  //       setLoading(false);
  //       console.log(err1, err2, err3);
  //       alert("Something went wrong");
  //     }
  //   }
  // }, [
  //   isAddConversationSuccess,
  //   isEditConversationSuccess,
  //   isSaveTuition,
  //   conversation,
  //   navigate,
  // ]);
  // useEffect(() => {
  //   var tuition =
  //     selectedTuition.label === undefined
  //       ? "-"
  //       : selectedTuition.label?.toString().trim();
  //   let remuneration = "";
  //   if (currency && budget >= 0) {
  //     if (budget) {
  //       remuneration =
  //         " I am offering a remuneration of [" +
  //         budget +
  //         " " +
  //         currency?.name +
  //         "] per hour.";
  //     }
  //   }
  //   setMessage(
  //     "Hi, " +
  //       name +
  //       ", I am looking for a tutor to help me on '" +
  //       tuition +
  //       "'. Please let me know if you are available to teach this subject." +
  //       remuneration
  //   );
  // }, [selectedTuition, budget]);

  const fnSetInitialField = async () => {
    setLoading(true);
    // const res = await axiosRequest({
    //   url: "/member/interest-pricing/initialValue",
    //   method: "get",
    // });
    // var currList = [];
    // if (res.data) {
    //   res.data?.currencies?.map((item, index) => {
    //     currList.push({
    //       value: item.name,
    //       label: item.name,
    //       isDisabled: false,
    //     });
    //   });
    // }
    // setCurrencyList(currList);
    if (conversation === undefined || conversation?.length === 0) {
      const conv = await axios.get(GetConversationApi + userId, {
        headers: {
          Authorization: authHeader(),
        },
      });
      if (conv && conv.data) {
        setConversation(conv.data);
      } else {
        setConversation([]);
      }
    }

    var tuitionList = [];
    if (props.photoIndex.tuitions) {
      setTuitionsData(props.photoIndex.tuitions);
      props.photoIndex.tuitions?.map((item, index) => {
        var subjects = "";
        item.subjects.map((item2, index2) => {
          var comma = item.subjects.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
        });
        tuitionList.push({
          value: item.id,
          label: subjects + " ", //+ item.amount,
          item: item,
        });
      });
    }
    setTuitions(tuitionList);
    setLoading(false);
  };

  // const validateStartConversation = () => {
  //   if (selectedTuition === "" || selectedTuition.value === "") {
  //     toastNotifiy("Subjects is required", "warn");
  //     return false;
  //   } else if (tuitionPlace === "") {
  //     toastNotifiy("Tuition place is required ", "warn");
  //     return false;
  //   } else if (!currency) {
  //     toastNotifiy("Currency field is required ", "warn");
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const handleStartConversation = async (e) => {
    e.preventDefault();
    setLoading(true);

    // check conversation
    if (selectedTuition) {
      // if (!validateStartConversation()) {
      //   setLoading(false);
      //   return;
      // }
      if (conversation?.length > 0) {
        // edit conversation
        editConversation({
          sender: loggedInUser,
          id: conversation[0].conversationId,
          data: {
            message,
          },
        });
      } else if (conversation?.length === 0) {
        // add conversation
        addConversation({
          sender: loggedInUser,
          data: {
            receiverId: userId,
            message,
            timestamp: new Date().getTime(),
          },
        });
      }
      // addNewTuition({
      //   data: {
      //     studentId: loggedInUser,
      //     tutorId: userId,
      //     interestedSubjectsId: selectedTuition.value,
      //     interestedSubjects: selectedTuition.item,
      //     studentBudget: budget,
      //     probableStartDate: probableStartingDate,
      //     preferablePlace: tuitionPlace,
      //     currency: currency.name,
      //     sender: "Student"
      //     // calculateBy: calculateBy,
      //   },
      // });
      // const notifyObj = {
      //   receiver: userId,
      //   title: "You have a new tuition request",
      //   description: "Someone Wants to contact with you",
      //   context: process.env.PUBLIC_URL + "/inbox",
      //   isPublic: false,
      // };
      // AddNotification(notifyObj);
    } else {
      if (!conversation) await fnSetInitialField();
      if (conversation?.length > 0) {
        // edit conversation
        editConversation({
          sender: loggedInUser,
          id: conversation[0].conversationId,
          data: {
            message,
          },
        });
      } else if (conversation?.length === 0) {
        // add conversation
        addConversation({
          sender: loggedInUser,
          data: {
            receiverId: userId,
            message,
            timestamp: new Date().getTime(),
          },
        });
      }
    }
    const notifyObj = {
      receiver: userId,
      title: "You have a new message",
      description: "Someone Wants to contact with you",
      context: process.env.PUBLIC_URL + "/inbox",
      isPublic: false,
    };
    AddNotification(notifyObj);
  };

  const handleClose = () => {
    props.setPhotoIndex({ ...props.photoIndex, isOpen: false });
  };

  const handleSubjects = (item) => {
    if (!item) {
      setSelectedTuition([]);
      setCurrency([]);
      return;
    }
    setSelectedTuition(item);
    const subjectInfo = tuitionsData.filter((x) => x.id === item.value);
    // setChange(true)
    setCurrency(subjectInfo[0].currency);
  };

  // // disable send button update
  // useEffect(() => {
  //   if (!userMessage) {
  //     sendDisable(true);
  //   } else {
  //     sendDisable(false);
  //   }
  // }, [userMessage]);

  console.log('me', userMessage);

  return (
    <Fragment>
      <Modal
        isOpen={props.photoIndex.isOpen}
        toggle={props.photoIndex.toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader style={{ placeContent: "center" }} toggle={handleClose}>
          <div>Start Conversation with {name}</div>
        </ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm py-1">
                <Row>
                  <Col sm="12" xl="12">
                    <div>
                      <Row className="col-12">
                        <Col lg="8">
                          <Col>
                            <Label className="col-form-label f-w-500" style={{ color: "#6362E7" }} htmlFor="">
                              Tutors Subjects
                            </Label>
                          </Col>
                          <Col>
                            <Select
                              options={tuitions}
                              placeholder="Choose Subject"
                              className="js-example-basic-single col-sm-12 darkMode"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: "#c3e3ff", // replace red with your desired border color
                                  // '&:hover': { borderColor: 'blue' }, // replace blue with your desired hover border color
                                }),
                              }}
                              // onChange={(item) => setSelectedTuition(item)}
                              onChange={(item) => handleSubjects(item)}
                              value={selectedTuition}
                              isClearable={true}
                            />
                          </Col>
                        </Col>
                        <Col lg={4}>
                          <Col>
                            <Label className="col-form-label f-w-500" style={{ color: "#6362E7" }} htmlFor="inputEmail3">
                              Your Budget
                            </Label>
                          </Col>
                          <Col>
                            <div style={{ display: "flex", placeItems: "center" }}>
                              <Input
                                type="text"
                                className="form-control f-16"
                                placeholder="Enter Your Budget"
                                value={budget}
                                onChange={(e) => {
                                  e.target.value?.replace(/[^\d.]/g, "")?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] >= 0 &&
                                    e.target.value?.replace(/[^\d.]/g, "")?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] <= 1000000 &&
                                    setBudget(e.target.value?.replace(/[^\d.]/g, "")?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]);
                                }}
                                disabled={!selectedTuition || selectedTuition.length === 0}
                                style={{ borderColor: "#c3e3ff" }}
                              />
                              <div>
                                <span className="m-l-5">USD</span>
                                <span className="px-2">/class</span>
                              </div>
                            </div>
                            {showErrorMessage && !isValidBudget ? <div className="text-danger">{budgetErrorMessage}</div> : ""}
                          </Col>
                        </Col>
                      </Row>
                    </div>
                    {/* <Row className="align-items-center border-bottom">
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Preferred Place?
                        </Label>
                      </Col>
                      <Col sm="8">
                        <div
                          className="m-checkbox-inline"
                          style={{ paddingLeft: "6px", fontSize: "13px" }}
                        >
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline1"
                              type="radio"
                              name="radio2"
                              value="Online"
                              // checked={gender === "male"}
                              onChange={handlePreferredPlace}
                            />
                            <Label className="mb-0" for="radioinline1">
                              <span className="digits"> {"Online"}</span>
                            </Label>
                          </div>
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline2"
                              type="radio"
                              name="radio2"
                              value="TutorPlace"
                              // checked={gender === "female"}
                              onChange={handlePreferredPlace}
                            />
                            <Label className="mb-0" for="radioinline2">
                              <span className="digits"> {"Teacher Place"}</span>
                            </Label>
                          </div>
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline3"
                              type="radio"
                              name="radio2"
                              value="StudentPlace"
                              // checked={gender === "other"}
                              onChange={handlePreferredPlace}
                            />
                            <Label className="mb-0" for="radioinline3">
                              <span className="digits"> {"Student Place"}</span>
                            </Label>
                          </div>
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline4"
                              type="radio"
                              name="radio2"
                              value="Other"
                              // checked={gender === "other"}
                              onChange={handlePreferredPlace}
                            />
                            <Label className="mb-0" for="radioinline4">
                              <span className="digits"> {"Other Place"}</span>
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row> */}
                    {/* <Row className="py-1 align-items-center">
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Your Budget
                        </Label>
                      </Col>
                      <Col md={4} sm={6}>
                        <div style={{ display: "flex", placeItems: "center" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="Enter Your Budget"
                            style={{ fontSize: "13px" }}
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                          />
                          <div>
                            <span className="px-2">/Hourly</span>
                          </div>
                        </div>
                        {showErrorMessage && !isValidBudget ? (
                          <div className="text-danger">
                            {budgetErrorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col
                        md={4}
                        sm={6}
                        className={`py-1 ${currency !== null ? "" : "d-none"}`}
                      >
                        <div
                          className={`"border"`}
                          style={{
                            borderRadius: "5px",
                            background: "#e6e6e659",
                            lineHeight: "13px",
                            paddingTop: "3px",
                          }}
                        >
                          <span>{currency?.name} </span>
                          <p className="mb-0" style={{ fontSize: "10px" }}>
                            {currency?.code}
                          </p>
                        </div>
                      </Col>
                    </Row> */}
                    {/* <Row className="">
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="">
                          Probable Starting Date
                        </Label>
                      </Col>
                      <Col md={4}>
                        <ReactDatePicker
                          className="form-control digits"
                          selected={probableStartingDate}
                          onChange={handleProbableStartingDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy"
                        />
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md={4}>
                        <Label className="col-form-label f-w-500" style={{ color: "#6362E7" }} htmlFor="">
                          Message
                        </Label>
                      </Col>
                    </Row>
                    <Row className="py-1">
                      <Col md={12}>
                        <Input
                          type="textarea"
                          className="form-control"
                          rows="4"
                          style={{ fontSize: "13px" }}
                          // style={{ fontSize: "13px", backgroundColor: "#6362E7", backgroundColor: "rgba(99, 98, 231, 0.1)"}}
                          // style={{ fontSize: "13px", backgroundColor: "#e2fdff", backgroundColor: "rgb(226, 253, 255, 0.5)"}}
                          value={userMessage}
                          onChange={(e) => setUserMessage(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <div className="d-flex align-items-center txt-warning">
                        <Info size="14" />
                        <span className="f-12 m-l-5">Clearing subject clears your budget</span>
                      </div>
                    </Row>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={(e) => handleStartConversation(e)}
            disabled={loading || !userMessage}
          >
            {/* {loading ? "LOADING..." : "Save changes"} */}
            {"Start Conversation"}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment >
  );
};

export default ContactMemberModal;
