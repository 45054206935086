import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import Swal from "sweetalert2";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { AddNotification } from "../../../../Services/Notification.Service";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";

const CancelClass = ({
  setIsCancelled,
  setTitle,
  setReason,
  reason,
  setConfirm,
  confirm,
  event,
  toggle,
  setIsRescheduled,
  memberType,
}) => {
  const reasons = ["Sickness", "Family Emergency", "Disaster"];

  const {
    getAllSchedulesByRRule,
    scheduleEventList,
    setScheduleEventList,
    filterClassList,
    classList,
  } = useContext(ClassSchedulerContext);

  useEffect(() => {
    setTitle(
      "Are you sure you want to cancel (" +
        moment(event?.date).format("LL") +
        ") class?"
    );
  }, []);

  var newArray =
    classList.filter(function (el) {
      return (
        el.isPaid === false &&
        el.classStatus !== "cancelled" &&
        el.tuitionId === event.tuitionId &&
        moment(el?.start) > moment(event.start) &&
        el
      );
    }) || [];

  let notificationText =
    memberType === "tutor" && event.isPaid && newArray.length > 0
      ? `${event.title} on ${moment(event?.date).format(
          "LL"
        )} is cancelled by your tutor(${
          event.tutorName
        }). As this was a paid class, your next class on ${moment(
          newArray[0].start
        ).format("LL")} (${
          newArray[0].title
        }) with this tutor is marked as paid in our system.`
      : memberType === "tutor" && event.isPaid && newArray.length === 0 && event?.tuitionStatus !== "FREE"
      ? `${event.title} on ${moment(event?.date).format(
          "LL"
        )} is cancelled by your tutor(${
          event.tutorName
        }). As there is no unpaid class in this tution the paid amount(${
          event.confirmedTuitionFee
        }) is deposited as credit in your TuteAir account.`
      : `${event.title} on ${moment(event?.date).format("LL")} is cancelled.`;

  const handleCancelClass = async (e) => {
    e.preventDefault();
    // console.log(newArray[0]);
    // return;
    var warning =
      event.isPaid && newArray.length > 0 && memberType === "student"
        ? `As this is a paid class, if you cancel this class your next class on ${moment(
            newArray[0].start
          ).format("LL")} (${
            newArray[0].title
          }) will be marked as paid in our system.`
        : event.isPaid && newArray.length === 0 && memberType === "student" && event.tuitionStatus !== "FREE"
        ? `As this is a paid class and there is no unpaid class in this tuition, if you cancel this class and you do not have any unpaid class the credit(${event.confirmedTuitionFee}) will automatically deposit to your TuteAir account. You can pay for any other tution later with any tutor.`
        : "";
    var warningTitle =
      memberType === "student" ? event?.tutorName : event?.studentName;
    Swal.fire({
      title: `Cancel ${moment(event?.date).format(
        "LL"
      )} Class with ${warningTitle}`,
      html: warning,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      confirmButtonText: `YES, Cancel`,
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        var res = await axiosRequest({
          url: "/tuition/cancelled-class/",
          method: "put",
          data: {
            tuitionId: event.tuitionId,
            classId: event.classId,
            cancel: {
              reason: reason,
              cancelBy: event.userId,
              time: new Date(),
            },
          },
        });
        if (res.status === 201) {
          var prevList = [...scheduleEventList];
          var unpaidClassId = newArray[0] && newArray[0].classId;
          var resIsPaid = null;
          var creditRequest = null;
          if (event.isPaid && typeof unpaidClassId === "string") {
            resIsPaid = await axiosRequest({
              url: "/tuition/payment-class/",
              method: "put",
              data: {
                tuitionId: event.tuitionId,
                classIdList: [unpaidClassId],
                isPaid: true,
              },
            });
          } else if (event.isPaid && event?.tuitionStatus !== "FREE") {
            creditRequest = await axiosRequest({
              url: "/payment/cancel-class-payment/",
              method: "put",
              data: {
                tuitionId: event.tuitionId,
                studentId: event.studentId,
                class_info: {
                  classId: event.classId,
                  classDate: event.date,
                  startTime: event.start,
                  endTime: event.end,
                  tuitionFee: event.confirmedTuitionFee,
                  payDate: new Date(),
                },
              },
            });
          }

          for (let i = 0; i < prevList.length; i++) {
            const element = prevList[i];
            if (
              element.tuitionId === event.tuitionId &&
              element.classId === event.classId
            ) {
              element.classStatus = "cancelled";
              element.cancel = {
                reason: reason,
                cancelBy: event.userId,
                time: new Date(),
              };
            }
            if (
              element.tuitionId === event.tuitionId &&
              element.classId === unpaidClassId &&
              resIsPaid !== null &&
              resIsPaid.status === 201 &&
              event.isPaid
            ) {
              element.isPaid = true;
            }
          }

          filterClassList(event?.memberType, event?.userId, prevList);
          Swal.fire({
            icon: "success",
            text: `Your class on ${moment(event?.date).format(
              "LL"
            )} is cancelled.`,
          });

          const notifyObj = {
            receiver:
              event.memberType === "student" ? event.tutorId : event.studentId,
            title: notificationText,
            description: "Someone Wants to contact with you",
            context: process.env.PUBLIC_URL + "/calendar",
            isPublic: false,
          };

          AddNotification(notifyObj);
          toggle();
        }
      } else {
        // SweetAlert.fire({
        //   icon: "danger",
        //   text: "No Extend Agreement!",
        // });
      }
    });
  };

  return (
    <>
      <Form className="pt-3">
        <FormGroup row>
          <Label for="exampleEmail2" sm={2}>
            Reason
          </Label>
          <Col xl="9" sm="9" lg="9">
            <Form>
              <FormGroup>
                {reasons.map((i) => (
                  <>
                    <Input
                      id={i}
                      type="checkbox"
                      value={i}
                      checked={i === reason}
                      onChange={() => {
                        setReason(i);
                      }}
                      style={{
                        margin: "4px 8px",
                      }}
                    />
                    <Label
                      style={{
                        paddingTop: 1,
                      }}
                      htmlFor="radioinline2"
                    >
                      <span> {i}</span>
                    </Label>
                  </>
                ))}
                <Input
                  id="examplePassword"
                  name="reason"
                  placeholder="Any other reason"
                  type="text"
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </FormGroup>
            </Form>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={12} className="text-center">
            <button
              onClick={(e) => {
                setIsCancelled(false);
                setIsRescheduled(false);
                setTitle("Calendar");
                setReason("");
                setConfirm(false);
              }}
              className="btn btn-info mx-2"
            >
              Back
            </button>
            <button
              className="btn btn-danger"
              disabled={reason === ""}
              onClick={(e) => {
                handleCancelClass(e);
                setIsCancelled(false);
                setIsRescheduled(false);
                setTitle("Calendar");
                setReason("");
                setConfirm(false);
              }}
            >
              Cancel The Class
            </button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default CancelClass;
