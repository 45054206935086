//worked till now
import axios from "axios";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useContext,
} from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTrackBackground, Range } from "react-range";

import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { Btn } from "../../../AbstractElements";
import {
  TutorInterestAndPricingGetApi,
  TuitionRequestGetAllApi,
  TuitionRequestPostApi,
  TuitionRequestUpdateApi,
  TuitionRequestDeleteApi,
  TuitionRequestGetByIdApi,
  LanguagesApi,
  CountriesApi,
} from "../../../api/index";
import {
  getSubCategoryList1,
  getSubCategoryList2,
  getSubjectList,
} from "../../AccountNew/Tutor/InterestPricing/subjectInterestCommon";

import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import Multiselect from "multiselect-react-dropdown";
import SubjectsList from "../../../assets/json/subjects/subject.json";
import { FixedSizeList as List } from "react-window";
import subjects from "../../../api/subjects.json";
import "../../AccountNew/Student/InterestLearn/styles.css";
import Select, { createFilter } from "react-select";
import TutorsContext from "../../../_helper/Tutors";

let { options, optionsOld, height } = getSubjectList();

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const RequestTuitionAddEditContent = (props) => {
  const {
    getDegrees,
    degrees,
    selectedDegree,
    setDegrees,
    getCourses,
    courses,
    setCourses,
    selectedCourse,
    workExpYearList,
    workExpYear,
  } = useContext(TutorsContext);

  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);
  const [country, setCountry] = useState([""]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [tutionType, setTutionType] = useState(null);
  const [profilePicExist, setProfilePicExist] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [subject, setSubject] = useState(null);
  const [subject_category_filter, setSubject_category_filter] = useState(null);
  const [subject_subcategory_filter, setSubject_subcategory_filter] =
    useState(null);
  const [location, setLocation] = useState(null);
  const [price, setPrice] = useState(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [education, setEducation] = useState(null);
  const [degree, setDegree] = useState("");
  const [course, setCourse] = useState("");
  const [institute, setInstitute] = useState("");
  const [minGrade, setMinGrade] = useState("");
  const [maxGrade, setMaxGrade] = useState("");
  const [workExperience, setWorkExperience] = useState(null);
  const [exerienceYear, setExperienceYear] = useState("");
  const [organisation, setOrganisation] = useState("");
  const navigate = useNavigate();

  const fetchLanguages = async () => {
    const res = await axios.get(LanguagesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    var newList = [];
    var len = res.data.length;
    for (var i = 0; i < len; i++) {
      newList.push(res.data[i].name);
    }
    newList.sort();
    setLanguageList(newList);
  };

  const fetchCountries = async () => {
    const res = await axios.get(CountriesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });

    var countryList = [];
    if (res.data) {
      res.data.map((item, index) => {
        countryList.push({
          value: item._id + "_" + item.name,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    countryList = countryList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setCountries(countryList);
  };

  useEffect(() => {
    fetchLanguages();
    fetchCountries();
  }, []);

  const today = new Date();
  const nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tutionTypeList, setTutionTypeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(nextMonth);

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]); // new
  const [selectedOptions, setSubjectSelectedOptions] = useState([]);
  const [selectedTuitionTypeOptions, setTuitionTypeSelectedOptions] = useState(
    []
  );
  const [selectedClasses, setClassesSelectedOptions] = useState([]);

  // post request values
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subjects, setSubjects] = useState(null);
  const [gender, setGender] = useState("");
  const [requestMessage, setRequestMessage] = useState("");
  const [tuitionTypes, setTuitionTypes] = useState(null);
  const [classes, setClasses] = useState(null);
  const { subjectCategoryList, subjectSubCategoryList } =
    useSelector((state) => state.member) || {};
  const [status, setStatus] = useState("");
  const [statusHide, setStatusHide] = useState(false);

  const fnSetInitialValueAdd = () => {
    setSubCategoryList([]);
    setSubjectList([]);

    setCategory("");
    setSubCategory("");
    setSubjects([]);
    setClasses([]);
    setTuitionTypes([]);
  };

  // console.log("experience", props.data.workExperience.workExpYear);

  // get all inital values
  const fetchInitialValue = async () => {
    setGender(props.data.gender);
    setSelectCategory(props.data.subjectCategory);
    setSelectSubCategory(props.data.subjectSubCategory);
    setSubjectSelectedOptions(props.data.subject);
    setTuitionTypeSelectedOptions(props.data.tutionType);
    setTuitionTypes(props.data.tutionType);
    setLanguageSelectedOptions(props.data.language);
    setLocation(props.data.location);
    setCountry(props.data.location ? props.data.location.country : "");
    setCity(props.data.location ? props.data.location.city : "");
    setPostalCode(props.data.location ? props.data.location.postalCode : "");
    setProfilePicExist(props.data.profilePicture);
    setPrice(props.data.price);
    setMaxPrice(props.data.price ? props.data.price.max : "");
    setMinPrice(props.data.price ? props.data.price.min : "");
    setEducation(props.data.education);
    setDegree(selectedDegree ? selectedDegree : "");
    setCourse(selectedCourse ? selectedCourse : "");
    setInstitute(
      props.data.education?.institute ? props.data.education?.institute : ""
    );
    setWorkExperience(props.data.workExperience);
    setExperienceYear(workExpYear ? workExpYear : "");
    setOrganisation(
      props.data.workExperience?.org ? props.data.workExperience?.org : ""
    );

    const res = await axios.get(TutorInterestAndPricingGetApi, {
      headers: {
        Authorization: authHeader(),
      },
    });

    setTutionTypeList([
      "Online",
      "Teachers Place",
      "Students Place",
      "Public Place",
    ]);

    var catList = subjectCategoryList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));
    setCategoryList(catList);
  };
  useEffect(() => {
    fetchInitialValue();
    return () => {
      fnSetInitialValueAdd(); // when unmount the component
    };
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(new Date(date));
  };
  const handleEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const fnSetInitialEditData = () => {
    setStatusHide(true);
    setSelectCategory({
      value: props?.data?.subjects[0]?.tag?.includes("/")
        ? props.data.subjects[0]?.tag.split("/")[0]
        : props.data.subjects[0]?.tag,
      label: props.data.subjects[0]?.tag.includes("/")
        ? props.data.subjects[0]?.tag.split("/")[0]
        : props.data.subjects[0]?.tag,
    });
    fnSetEditedData(props.data.subjects[0]?.tag, props.data.subjects);
    setTuitionTypeSelectedOptions(props.data.tuitionTypes);
    setTuitionTypes(props.data.tuitionTypes);
    setGender(props.data.gender);
    setStartDate(new Date(props.data.startDate));
    setEndDate(new Date(props.data.endDate));
    setRequestMessage(props.data.requestMessage);
    setStatus(props.data.status);
    //setSelectCategory(props.data.subjectCategory);
    //setSelectSubCategory(props.data.subjectSubCategory);
    //setSubjectSelectedOptions(props.data.subject);
    setLanguageSelectedOptions(props.data.languages);
    setLocation(props.data.location);
    setCountry(props.data.location ? props.data.location.country : "");
    setCity(props.data.location ? props.data.location.city : "");
    setPostalCode(props.data.location ? props.data.location.postalCode : "");
    setProfilePicExist(props.data.profilePicExist);
    setPrice(props.data.price);
    setMaxPrice(props.data.price ? props.data.price.max : "");
    setMinPrice(props.data.price ? props.data.price.min : "");

    var degreeList = [];
    degreeList.push({
      value: props.data.education?.degree,
      label: props.data.education?.degreeName,
      isDisabled: false,
    });
    setDegree(degreeList ? degreeList : "");
    var courseList = [];
    courseList.push({
      value: props.data.education?.course,
      label: props.data.education?.courseName,
      isDisabled: false,
    });
    setCourse(courseList ? courseList : "");
    setInstitute(
      props.data.education?.institute ? props.data.education?.institute : ""
    );
    setWorkExperience(props.data.workExperience);
    var workExperienceList = [];
    workExperienceList.push({
      label: props.data.workExperience?.expYear,
      name: props.data.workExperience?.expYear,
      value: props.data.workExperience?.expYear,
    });
    setExperienceYear(workExperienceList ? workExperienceList : "");
    setOrganisation(
      props.data.workExperience?.org ? props.data.workExperience?.org : ""
    );
  };

  useEffect(() => {
    if (props.edit) {
      fnSetInitialEditData();
    } else {
      fnSetInitialValueAdd();
    }
  }, []);

  const fnSetEditedData = async (tag, subjects2) => {
    const subCatList = subjectSubCategoryList?.filter(
      (x) => x?.split("/")[0] === tag.split("/")[0]
    );
    if (subCatList) {
      let subCategoryObjList = [];
      subCatList?.map((item, index) => {
        subCategoryObjList.push({
          value: item.split("/")[0] + "/" + item.split("/")[1],
          label: item.split("/")[1],
          isDisabled: false,
        });
      });
      setSubCategoryList(subCategoryObjList);

      options = optionsOld?.filter((x) => x.tag === tag);
      var subj3 = [];

      subjects2?.map((item, index) => {
        subj3.push({
          value: item.name,
          //label: "[ " + item.tag + " ] " + item.name,
          label: item.name,
          tag: item.tag,
          id: item.id,
        });
      });
      setSubjectSelectedOptions(subj3);

      if (tag.includes("/")) {
        setSelectSubCategory({
          value: tag.includes("/") ? tag : "",
          label: tag.includes("/") ? tag.split("/")[1] : "",
        });
      }
    } else {
      alert(22);
    }
  };

  const handleSubjects = async (sub) => {
    if (!sub || sub.length === 0) {
      setSubject([]);
      setSubjectSelectedOptions([]);
      return;
    }
    setSubject(sub);
    setSubjectSelectedOptions(sub);
    options = options?.filter((x) => x.tag === sub[0]?.tag);
    if (sub[0]?.tag.includes("/")) {
      setCategory({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      setSubject_category_filter({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      var subCatList = await getSubCategoryList2(
        sub[0],
        subjectSubCategoryList
      );

      setSubCategoryList(subCatList);
      setSubCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
      setSubject_subcategory_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
    } else {
      if (!selectedOptions) {
        alert(1);
      }
      setCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
      setSubject_category_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
    }
  };

  const clearList = () => {
    setSubCategoryList([]);
    //setSubjectSelectedOptions([]);
    setSubjectList([]);
  };

  // set subjects & specializations for send backend
  useEffect(() => {
    let arr1 = [];
    if (
      selectedOptions !== null &&
      selectedOptions !== undefined &&
      selectedOptions.length > 0
    ) {
      selectedOptions?.map((item) => {
        arr1.push({
          id: item.id,
          tag: item.tag,
          name: item.value,
        });
      });
      setSubjects(arr1);
    }
  }, [selectedOptions]);

  const handleCategories = async (item) => {
    // setSubjectSelectedOptions([]);
    setSubject([]);
    setCategory(item);
    setSelectCategory(item);
    setSubCategory([]);
    setSubject_category_filter(item);
    setSubject_subcategory_filter({});
    if (!item) {
      setSubCategoryList([]);
      // setSubjectSelectedOptions([]);
      setSubject([]);
      setSubject_category_filter({});
      options = optionsOld;
      return;
    }
    options = optionsOld?.filter((x) => x.tag.split("/")[0] === item?.value);

    var subCatList = await getSubCategoryList1(item, subjectSubCategoryList);
    if (subCatList) setSubCategoryList(subCatList);
  };

  const handleSubCategory = async (item) => {
    //setSubjectSelectedOptions([]);
    setSubject([]);
    //setSubCategory(item);
    setSelectSubCategory(item);
    setSubject_subcategory_filter(item);
    if (!item) {
      setSubject_subcategory_filter({});
    }
    options = optionsOld.filter((x) => x.tag === item.value);
  };

  const validateInterestPricing = () => {
    //  if (!subjects || subjects.length === 0) {
    //     toastNotifiy("Subjects Field Required", "warn");
    //     return false;
    //   } else if (!classes) {
    //     toastNotifiy("Degree Field Required", "warn");
    //     return false;
    //   } else {
    //     return true;
    //   }
    return true;
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (validateInterestPricing()) {
      if (window.confirm("Are you sure to save this information?")) {
        setLoading(true);
        let request = {
          gender,
          profilePicExist,
          languages: selectedLanguageOptions,
          subjects,
          tuitionTypes: selectedTuitionTypeOptions,
          location,
          price,
          education,
          workExperience,
          startDate,
          endDate,
          status,
          requestMessage,
        };
        const res = await axios.post(TuitionRequestPostApi, request, {
          headers: {
            Authorization: authHeader(),
          },
        });
        if (res.status === 200) {
          const msg = "Successfully Added Request for Tuition Information!";
          toastNotifiy(msg, "success");
          props.toggler();
          setLoading(false);
          setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/request-tuition`);
          }, 3600);
        } else {
          toastNotifiy("Failed to insert!!", "warn");

          setLoading(false);
        }
        return;
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validateInterestPricing()) {
      if (window.confirm("Are you sure to update this information?")) {
        setLoading(true);
        let request = {
          gender,
          profilePicExist,
          languages: selectedLanguageOptions,
          subjects,
          tuitionTypes: selectedTuitionTypeOptions,
          location,
          price,
          education,
          workExperience,
          startDate,
          endDate,
          status,
          requestMessage,
        };
        const res = await axios.put(
          TuitionRequestUpdateApi + props.data.id,
          request,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        if (res.status === 200) {
          const msg = "Successfully Updated Request for Tuition Information!";
          toastNotifiy(msg, "success");
          props.toggler();
          setLoading(false);
        } else {
          toastNotifiy("Failed to insert!!", "warn");

          setLoading(false);
        }
        return;
      }
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col md={6}>
                    <div className="tuitionRequestCard">
                      <div className="row mb-1">
                        <h4 className="f_title">
                          <span>Basic</span>
                        </h4>
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Gender
                        </Label>
                        <Col sm="9">
                          <div
                            className="m-checkbox-inline"
                            style={{ paddingLeft: "6px" }}
                          >
                            <div className="radio radio-primary">
                              <Input
                                id="radioinline3"
                                type="radio"
                                name="radio1"
                                value={gender}
                                checked={gender == "0"}
                                onChange={(e) => setGender("0")}
                                disabled={true}
                              />

                              <Label className="mb-0" for="radioinline4">
                                <span className="digits"> {"All"}</span>
                              </Label>
                            </div>
                            <div className="radio radio-primary">
                              <Input
                                id="radioinline3"
                                type="radio"
                                name="radio1"
                                value={gender}
                                checked={gender == "male"}
                                onChange={(e) => setGender("male")}
                                disabled={true}
                              />
                              <Label className="mb-0" for="radioinline2">
                                <span className="digits"> {"Male"}</span>
                              </Label>
                            </div>
                            <div className="radio radio-primary">
                              <Input
                                id="radioinline3"
                                type="radio"
                                name="radio1"
                                value={gender}
                                checked={gender == "female"}
                                onChange={(e) => setGender("female")}
                                disabled={true}
                              />
                              <Label className="mb-0" for="radioinline3">
                                <span className="digits"> {"Female"}</span>
                              </Label>
                            </div>
                            <div className="radio radio-primary">
                              <Input
                                id="radioinline3"
                                type="radio"
                                name="radio1"
                                value={gender}
                                checked={gender == "other"}
                                onChange={(e) => setGender("other")}
                                disabled={true}
                              />
                              <Label className="mb-0" for="radioinline3">
                                <span className="digits"> {"Other"}</span>
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Profile Picture?
                        </Label>
                        <Col md={9}>
                          <Input
                            checked={profilePicExist}
                            type="checkbox"
                            onChange={(e) =>
                              setProfilePicExist(e.target.checked)
                            }
                            disabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Language
                        </Label>
                        <Col sm="9">
                          <Multiselect
                            className="darkMode bg-white"
                            isObject={false}
                            options={languageList}
                            showCheckbox
                            selectedValues={selectedLanguageOptions}
                            onSelect={(e) => {
                              setLanguages(e);
                            }}
                            onRemove={(e) => {
                              setLanguages(e);
                            }}
                            disable={true}
                          />
                        </Col>
                      </div>
                    </div>

                    <div className="tuitionRequestCard">
                      <h4 className="f_title">
                        <span>Subject</span>
                      </h4>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Category
                        </Label>
                        <Col sm="9">
                          <Select
                            className="darkMode"
                            options={subjectCategoryList}
                            placeholder="Select Category"
                            onChange={(item) => handleCategories(item)}
                            value={selectCategory}
                            isClearable={true}
                            isDisabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Sub-Category
                        </Label>
                        <Col sm="9">
                          <Select
                            className="darkMode"
                            options={subCategoryList}
                            placeholder="Select Sub Category"
                            onChange={(item) => handleSubCategory(item)}
                            value={selectSubCategory}
                            isDisabled={true}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Subjects
                        </Label>
                        <Col sm="9">
                          <Select
                            isMulti
                            components={{ MenuList }}
                            options={options}
                            className="darkMode"
                            // isClearable={true}
                            placeholder="Select Subjects"
                            isSearchable={true}
                            isDisabled={true}
                            isLoading={false}
                            closeMenuOnSelect={false}
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            onChange={(item) => handleSubjects(item)}
                            value={selectedOptions}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Tuition Place
                        </Label>
                        <Col sm="9">
                          <Multiselect
                            className="darkMode bg-white"
                            isObject={false}
                            options={tutionTypeList}
                            showCheckbox
                            onRemove={(e) => {
                              setTutionType(e);
                            }}
                            onSelect={(e) => {
                              setTutionType(e);
                            }}
                            selectedValues={selectedTuitionTypeOptions}
                            disable={true}
                          />
                        </Col>
                      </div>
                    </div>

                    <div className="tuitionRequestCard">
                      <h4 className="f_title">
                        <span>Location</span>
                      </h4>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Country
                        </Label>
                        <Col sm="9">
                          <Input
                            type="text"
                            name="country"
                            className="form-control darkMode"
                            placeholder="Country"
                            value={country}
                            disabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          City
                        </Label>
                        <Col sm="9">
                          <Input
                            type="text"
                            name="city"
                            className="form-control darkMode"
                            placeholder="City"
                            value={city}
                            disabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Post Code
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Post Code"
                            disabled={true}
                            value={postalCode}
                          />
                        </Col>
                      </div>
                    </div>

                    <div className="tuitionRequestCard">
                      <h4 className="f_title">
                        <span>Price</span>
                      </h4>

                      <Row className="d-flex align-items-bottom">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          USD
                        </Label>
                        <Col
                          sm="9"
                          className="d-flex justify-content-between align-items-bottom"
                        >
                          <Col md={5} className="mb-2">
                            <Input
                              type="number"
                              placeholder="Minimum"
                              value={minPrice}
                              // disabled={!currency}
                              onChange={(e) => setMinPrice(e.target.value)}
                              disabled={true}
                            />
                          </Col>
                          <Col md={5}>
                            <Input
                              type="number"
                              placeholder="Maximum"
                              value={maxPrice}
                              // min={minValue}
                              // disabled={!currency}
                              onChange={(e) => setMaxPrice(e.target.value)}
                              disabled={true}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="tuitionRequestCard mt-3">
                      <h4 className="f_title">
                        <span>Education</span>
                      </h4>

                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor="inputEmail3"
                        >
                          Level
                        </Label>
                        <Col sm="9">
                          <Select
                            options={degrees}
                            placeholder="Select Level"
                            className="js-example-basic-single col-sm-12 darkMode"
                            selecte
                            // onChange={(item) => setDegree(item)}
                            value={degree}
                            isDisabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor="inputEmail3"
                        >
                          Course
                        </Label>
                        <Col sm="9">
                          <Select
                            options={courses}
                            placeholder="Select Course"
                            className="js-example-basic-single col-sm-12 darkMode"
                            // onChange={(item) => setCourse(item)}
                            value={course}
                            isDisabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Prefered Institute
                        </Label>
                        <Col sm="9">
                          <Input
                            type="text"
                            name=""
                            placeholder="Enter Your Institute"
                            className="form-control"
                            value={institute}
                            onChange={(e) => setInstitute(e.target.value)}
                            disabled={true}
                          />
                        </Col>
                      </div>
                      {/* <div className="row mb-1 ">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor="inputEmail3"
                        >
                          Result (%)
                        </Label>
                        <Col md="9">
                          <Range
                            values={values}
                            // step={STEP}
                            min={MIN}
                            max={MAX}
                            // onChange={(values) => {
                            //   setValues(values);
                            // }}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <output style={{ marginTop: "10px" }}>
                                  {values[0]}
                                </output>
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                      values,
                                      colors: ["#ccc", "#4d8aff", "#ccc"],
                                      min: MIN,
                                      max: MAX,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                                <output style={{ marginTop: "10px" }}>
                                  {values[1]}
                                </output>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "25px",
                                  width: "25px",
                                  borderRadius: "30px",
                                  backgroundColor: "#FFF",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 6px #AAA",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "5px",
                                    backgroundColor: isDragged
                                      ? "#4d8aff"
                                      : "#CCC",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Col>
                      </div> */}
                    </div>

                    <div className="tuitionRequestCard">
                      <h4 className="f_title">
                        <span>Experience</span>
                      </h4>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Duration in years
                        </Label>
                        <Col sm="9">
                          <Select
                            options={workExpYearList}
                            placeholder="Select Years"
                            className="js-example-basic-single col-sm-12 darkMode"
                            // isClearable={true}
                            // onChange={(item) => setExperienceYear(item)}
                            value={exerienceYear}
                            isDisabled={true}
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Prefered Organisation
                        </Label>
                        <Col sm="9">
                          <Input
                            type="text"
                            name="text"
                            className="form-control px-2 py-7"
                            placeholder="Organisation Name"
                            value={organisation}
                            onChange={(e) => setOrganisation(e.target.value)}
                            disabled={true}
                          ></Input>
                        </Col>
                      </div>
                    </div>

                    <div className="tuitionRequestCard mt-3">
                      <h4 className="f_title ">
                        <span>Remarks</span>
                      </h4>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Intended Start Date
                        </Label>
                        <Col sm="9">
                          <DatePicker
                            className="form-control digits"
                            selected={startDate}
                            // maxDate={endDate}
                            minDate={startDate}
                            onChange={handleStartDateChange}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd MMMM yyyy"
                          />
                        </Col>
                      </div>
                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Post Expiry Date
                        </Label>
                        <Col sm="9">
                          <DatePicker
                            className="form-control digits"
                            selected={endDate}
                            minDate={startDate}
                            // maxDate={new Date()}
                            onChange={handleEndDateChange}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd MMMM yyyy"
                          />
                        </Col>
                      </div>

                      {statusHide && (
                        <div className="row mb-1">
                          <Label
                            className="col-sm-3 col-form-label pt-0"
                            htmlFor=""
                          >
                            Status
                          </Label>
                          <Col sm="9">
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="0">Select Status</option>
                              <option value={"OPEN"}>Open</option>
                              <option value={"PAUSE"}>Pause</option>
                              <option value={"CLOSED"}>Close</option>
                            </Input>
                          </Col>
                        </div>
                      )}

                      <div className="row mb-1">
                        <Label
                          className="col-sm-3 col-form-label pt-0"
                          htmlFor=""
                        >
                          Additional Info In Details
                        </Label>
                        <Col sm="9">
                          <Input
                            type="textarea"
                            className="form-control"
                            rows="3"
                            value={requestMessage}
                            maxLength={200}
                            onChange={(e) => setRequestMessage(e.target.value)}
                          />
                        </Col>
                      </div>
                    </div>
                  </Col>

                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) =>
                props.edit ? handleUpdate(e) : handleSaveChanges(e),
            }}
          >
            {/* {loading ? "LOADING..." : "Save changes"} */}
            {loading
              ? "LOADING..."
              : props.edit
              ? "Update Changes"
              : "Save Changes"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default RequestTuitionAddEditContent;
