import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Btn, H4, Image, P } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import Experience from "../../Profile/Tutor/Experience";
import Qualification from "../../Profile/Tutor/Qualification";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ShowMoreText from "react-show-more-text";
import "./hero-section.css";

const SingleTutorModal = (props) => {
  const { toggleCallback, modal, tutor, setTutor } = props;
  const [isShow, setIsShowless] = useState(true);
  function toggleIsShow() {
    setIsShowless(!isShow);
  }
  const modalToggle = () => {
    toggleCallback(false);
    setTutor(null);
  };
  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={modalToggle}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "100vh",
        }}
        className="modal-md"
        backdrop={"static"}
      >
        <ModalHeader style={{ placeContent: "center" }}>
          <div>Tutor details</div>
          <Btn
            attrBtn={{
              color: "transprant",
              className: "btn-close",
              onClick: modalToggle,
              type: "button",
            }}
          ></Btn>
        </ModalHeader>
        <ModalBody>
          <div className="profile-mail pt-0" id="DivIdToPrint">
            <div className="media align-items-center m-b-20">
              <img
                src={`${
                  tutor?.media?.picture
                    ? ` ${ProfilePictureRootURL}${tutor?.media?.picture}`
                    : man
                }`}
                alt=""
                style={{ width: 100, height: 100, borderRadius: "50%" }}
                className="m-r-20 profilePicMblView"
              />
              {/* Alternate but not rounded properly */}
              {/* <Image
                attrImage={{
                  className: "img-100 img-fluid m-r-20 rounded-circle",
                  src: `${
                    tutor.media.picture
                      ? ` ${ProfilePictureRootURL}${tutor.media.picture}`
                      : man
                  }`,
                  alt: "",
                }}
              /> */}
              <div className="media-body mt-0">
                <H4>
                  <span id="printname">{tutor?.name?.firstName} </span>
                  <span id="printlast">{tutor?.name?.lastName}</span>
                </H4>
                <ShowMoreText
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="content-css"
                  anchorClass="my-anchor-css-class"
                  onClick={toggleIsShow}
                  expanded={false}
                >
                  <P id="printmail">{tutor?.about?.tutorBio}</P>
                </ShowMoreText>
              </div>
            </div>
            <div className="p-t-5">
              {tutor?.educations?.length !== 0 ? (
                <Qualification
                  style={{ marginBottom: 0 }}
                  data={tutor.educations}
                  title="Qualifications"
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {tutor?.workExperiences?.length !== 0 ? (
                <Experience data={tutor.workExperiences} />
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="linkOrSignInMblView">
                Please
                <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                  <span> Sign in </span>
                </Link>
                or
                <Link to={`${process.env.PUBLIC_URL}/register-v2`}>
                  <span> Register </span>
                </Link>
                To Get Onboard With Tutors
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: modalToggle }}>Close</Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default SingleTutorModal;
