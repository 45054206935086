import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardText, Col, Row } from "reactstrap";

export default function Schedule({ params, data, accountType }) {
  const [freeSlots, setfreeSlots] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const tempFreeSlots = [];
      data.map((day) => {
        let freeTimeTable = [];
        day?.timeTable.map((time) => {
          if (!time.isBooked) {
            freeTimeTable.push(time);
          }
        });
        freeTimeTable.sort((a, b) => {
          const startTimeA = new Date(
            `1970/01/01 ${moment(a.start).format("HH:mm")}`
          );
          const startTimeB = new Date(
            `1970/01/01 ${moment(b.start).format("HH:mm")}`
          );
          return startTimeA - startTimeB;
        });
        if (freeTimeTable?.length > 0) {
          tempFreeSlots.push({
            dayName: day.dayName,
            timeTable: freeTimeTable,
          });
        }
      });
      setfreeSlots(tempFreeSlots);
    }
  }, [data]);
  return (
    <Card className="card-absolute b-t-light border-5">
      <CardHeader style={{ padding: "5px 10px" }} className="bg-primary">
        Schedule
      </CardHeader>
      {params === "me" &&
        <div className="d-flex justify-content-end me-3 mt-2">
          <Link to={'/tutor-account?tab=3'}>
            <button
              //type="submit"
              className="btn btn-primary text-white py-1 px-2"
            //disabled={false}}
            >
              <i class="fa fa-edit me-2" aria-hidden="true"></i>
              Edit
            </button>
          </Link>
        </div>
      }
      <CardBody className="scheduleMblView">
        <Row>
          {freeSlots.length > 0 &&
            freeSlots?.map((day, index) => (
              <Col className="m-r-5">
                <Row className="text-center text-primary border-bottom border-3 m-b-10">
                  <span>{day.dayName.toUpperCase()}</span>
                </Row>
                {day?.timeTable.map((time) => (
                  <Row
                    className={`f-12 m-b-5 p-t-5 p-b-5 rounded d-flex justify-content-center align-items-center`}
                    style={{ backgroundColor: "#c3e3ff" }}
                  >
                    <div className="w-auto">
                      <span>
                        {new Date(time.start).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        -
                      </span>
                      <br />
                      <span className="m-b-10 f-12">
                        {new Date(time.end).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  </Row>
                ))}
              </Col>
            ))}
        </Row>
        {freeSlots.length > 0 && (
          <Row className="m-t-30">
            <Col className="d-flex justify-content-end f-12">
              <span className="text-bold">
                {" "}
                <strong>N.B.</strong>{" "}
              </span>
              <span>This schedule is in your local time</span>
            </Col>
          </Row>
        )}
        {(freeSlots.length <= 0 || data?.length <= 0) &&
          accountType !== "tutor" && (
            <CardText className="mb-0 text-center" style={{ fontSize: "13px" }}>
              No available schedule found. You can contact the tutor to know the
              availability.
            </CardText>
          )}
        {(freeSlots.length <= 0 || data?.length <= 0) &&
          accountType === "tutor" && (
            <CardText className="mb-0 text-center" style={{ fontSize: "13px" }}>
              No available schedule found.
              <Link
                to={`${process.env.PUBLIC_URL}/tutor-account`}
                state={{ to: "schedule" }}
              >
                Click Here
              </Link>{" "}
              To Update Your Schedule
            </CardText>
          )}
        {/* {
          data?.length <=0 && (
            <CardText className="mb-0 text-center" style={{ fontSize: "13px"}}>
              Tutor hasn't added schedule yet.
            </CardText>
          )
        } */}
      </CardBody>
    </Card>
  );
}
