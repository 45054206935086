import { apiSlice } from "../api/apiSlice";

export const tuitionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRescheduledClassesByTutorId: builder.query({
      query: (tutorId) => `/tuition/rescheduled-class/${tutorId}`,
    }),
  }),
});

export const { useGetRescheduledClassesByTutorIdQuery } = tuitionApi;
