import React from "react";
import UpcomingClasses from "./UpcomingClasses/UpcomingClasses";

export default function ClassListContain() {
  return (
    <div>
      <UpcomingClasses />
    </div>
  );
}
