import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardHeader } from "reactstrap";

export default function InterestToLearn(props) {
  return (
    <Card body className="card-absolute b-t-light border-5">
      <CardHeader style={{ padding: "5px 10px" }} className="bg-primary">
        Interest To Learn
      </CardHeader>
      {props.params === 'me' &&
        <div className="d-flex justify-content-end">
          <Link to={'/student-account?tab=2'}>
            <button
              //type="submit"
              className="btn btn-primary text-white py-1 px-2"
            //disabled={false}}
            >
              <i class="fa fa-edit me-2" aria-hidden="true"></i>
              Edit
            </button>
          </Link>
        </div>
      }
      <div className="table-responsive-sm m-t-30">
        <table className="table table-border-horizontal">
          <thead>
            {/* <tr className="h4">
              <td colSpan={2}>Interest To Learn</td>
            </tr> */}
            <tr style={{ background: "#dbdbdb2b" }}>
              <th style={{ width: "35%" }}>Subjects</th>
              <th className="text-nowrap">Tuition Type</th>
              <th className="text-nowrap">
                Amount <sub>/class</sub>
              </th>
              <th style={{ width: "5%" }}>Negotiable</th>
            </tr>
          </thead>
          <tbody>
            {props?.data?.length === 0 && (
              <tr>
                <td colSpan={2} className="text-center">
                  No Info Found
                </td>
              </tr>
            )}
            {props?.data?.map((item, index) => (
              <tr key={index} className="px-0">
                <td>
                  <span>
                    {item.subjects?.map((item, index) => (
                      <span
                        className="badge badge-light-primary"
                        style={{ margin: "0px 3px 2px 0px" }}
                        key={index}
                      >
                        {item.name}
                      </span>
                    ))}
                  </span>
                  <span className="mb-0 px-1" style={{ color: "#488374" }}>
                    <small>{item.classes?.name}</small>
                  </span>
                </td>
                {/* <td>{item.classes?.name}</td> */}
                <td className="">
                  <span>
                    {item.tutionTypes?.map((item, index) => (
                      <span
                        className="badge badge-light-primary"
                        style={{ margin: "0px 3px 2px 0px" }}
                        key={index}
                      >
                        {item}
                      </span>
                    ))}
                  </span>
                </td>
                <td style={{ fontSize: "13px" }}>
                  USD {item.amount?.initialPrice}
                  {/* {item.amount?.initialPrice} {item.currency?.name} / Hourly */}
                </td>
                <td className="text-center" style={{ fontSize: "13px" }}>
                  {!item.amount?.isNegotiable && <span>No</span>}
                  {item.amount?.isNegotiable && <span>Yes</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
