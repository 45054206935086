import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import authSliceReducer from "../features/auth/authSlice";
import conversationsSliceReducer from "../features/chat/conversations/conversationsSlice";
import mesagesSliceReducer from "../features/chat/messages/messagesSlice";
import filterSliceReducer from "../features/filters/filterSlice";
import memberSliceReducer from "../features/member/memberSlice";
import menuSliceReducer from "../features/menus/menuSlice";
import confirmedTuitionSliceReducer from "../features/tuition/confirmedTuitionSlice";
import tuitionSliceReducer from "../features/tuition/tuitionSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSliceReducer,
        conversations: conversationsSliceReducer,
        messages: mesagesSliceReducer,
        menu: menuSliceReducer,
        tuition: tuitionSliceReducer,
        confirmedTuition: confirmedTuitionSliceReducer,
        member: memberSliceReducer,
        filter: filterSliceReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddlewares) =>
        getDefaultMiddlewares({
            serializableCheck: false
        }).concat(apiSlice.middleware),
        
});
