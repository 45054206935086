import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import ClassListContain from "../../Component/ClassList";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const ClassList = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs
        parent="Class List For Payment"
        title="Class List For Payment"
      />
      <ClassListContain />
    </Fragment>
  );
};

export default ClassList;
