import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardFooter, Col, Input, Row, Table, Button } from "reactstrap";
import SweetAlert from "sweetalert2";
import paypal from "../../../assets/images/other-images/paypal.png";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ClassSchedulerContext from "../../../_helper/ClassScheduler";
import PaymentContain from "../Payment/Payment";
import PaypalPaymentModal from "./PaypalPaymentModal";
// import paypalOld from "../../../assets/images/checkout/paypal.png";
// import paypal from "../../../assets/images/other-images/paypal.png";
import allCard from "../../../assets/images/checkout/allCard.png";
import payment from "../../../assets/images/checkout/payment.jpg";
import "./UpcomingClasses.css";

export default function UpcomingClasses() {
  const [isActive, setIsActive] = useState(false);
  const [classPaidList, setClassPaidListR] = useState([]);

  const { memberType } = useSelector((state) => state.menu) || {};
  const [processedPaid, setProcessedPaid] = useState(true);
  const { user } = useSelector((state) => state.auth) || {};

  const [modal, setModal] = useState(false);
  const [isPayModal, setIsPayModal] = useState(false);
  const [title, setTitle] = useState("");
  const [toPaidList, setToPayableList] = useState([]);
  const [totalToPay, setTotalToPay] = useState(0);
  const toggle = () => setModal(!modal);
  const [show, setShow] = useState(true);
  const [tuitionScheduleList, setTuitionScheduleList] = useState([]);
  // const paymentToggler = () => setIsPayModal(!isPayModal);
  const [isCardBtnDisable, setIsCardBtnDisable] = useState(false);
  const [isPaypalBtnDisable, setIsPaypalBtnDisable] = useState(false);

  const toggler = () => setModal(!modal);
  const [creditAmount, setCreditAmount] = useState(0);
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [isProceedPay, setIsProceedPay] = useState(false);
  const [summaryList, setSummaryList] = useState([]);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherAmount, setVoucherAmount] = useState(0);
  const navigate = useNavigate();
  const {
    allEventList,
    scheduleEventList,
    setScheduleEventList,
    getAllScheduleEvents,
  } = useContext(ClassSchedulerContext);

  const getTutiionsSchedule = async () => {
    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var _tuteair2 = JSON.parse(localStorage.getItem("tuteAirUser"));
    var res = await axiosRequest({
      url: "/tuition/all-classes/" + _tuteair?._id,
      method: "get",
    });

    if (res.data) {
      var list = [];
      var confirmedTuitionList = res.data
        .filter(
          (item) =>
            item.status === "CONFIRMED" && item?.tutor?._id !== _tuteair?._id
        )
        .reverse();

      confirmedTuitionList.map((x) => {
        var subjectName = "";
        x?.interestedSubjects?.subjects?.map((x) => {
          subjectName += " " + x.name;
        });

        list.push({
          ...x,
          isChecked: false,
          subjectName: subjectName,
          totalToPaid: x.scheduledClasses.filter(
            (i) =>
              new Date() < new Date(i.endTime) &&
              !i.isPaid &&
              i.classStatus !== "cancelled"
          ).length,

          totalAmount: x.scheduledClasses?.length * x.confirmedTuitionFee,
          totalUnPaidAmount:
            x.scheduledClasses.filter(
              (i) =>
                new Date() < new Date(i.endTime) &&
                !i.isPaid &&
                i.classStatus !== "cancelled"
            ).length * x.confirmedTuitionFee,
        });
      });

      setTuitionScheduleList(list);
    }
  };

  const handlePayForClasses = (e, item) => {
    // if (!item.isChecked) {
    //   alert("Please click on checkbox");
    //   return;
    // }
    // if (
    //   e.target.value >
    //     item?.scheduledClasses?.filter((x) => !x.isPaid).length -
    //       item.cancelClasses ||
    //   e.target.value <= 0
    // ) {
    //   return;
    // }
    // if(e.target.value === 0)

    var _tuitionList = [...tuitionScheduleList];
    var _toPaidList = [...toPaidList];
    var unPaidClasses = item.scheduledClasses.filter(
      (i) =>
        new Date() < new Date(i.endTime) &&
        !i.isPaid &&
        i.classStatus !== "cancelled"
    ).length;
    for (let i = 0; i < _tuitionList.length; i++) {
      const tuition = tuitionScheduleList[i];

      if (tuition._id === item._id) {
        var payForClass = e.target.value;

        if (payForClass <= unPaidClasses) {
          tuition.totalToPaid = payForClass;
        }

        if (payForClass <= unPaidClasses) {
          if (tuition.totalToPaid == 0) {
            item.isChecked = false;
          } else {
            item.isChecked = true;
          }
          tuition.totalUnPaidAmount = tuition.confirmedTuitionFee * payForClass;
          var payableAmount = tuition.confirmedTuitionFee * payForClass;
          setTotalToPay(payableAmount);
          fnSetToPayableList(_toPaidList, item, e.target.value);
        }

        // if (_toPaidList.filter((x) => x.tuitionId === item._id).length === 0) {
        //   _toPaidList.push({
        //     tuitionId: item._id,
        //     toPayClassNo: e.target.value,
        //     payableAmount: payableAmount,
        //   });
        // } else {
        //   for (let j = 0; j < _toPaidList.length; j++) {
        //     const toPaid = _toPaidList[j];

        //     if (item._id === toPaid.tuitionId) {
        //       toPaid.toPayClassNo = e.target.value;
        //       toPaid.payableAmount = payableAmount;
        //     }
        //   }
        // }

        // setToPayableList(_toPaidList);

        break;
      }
    }

    setTuitionScheduleList(_tuitionList);
  };

  const clickHandler = (e, item) => {
    var _tuitionList = [...tuitionScheduleList];
    var _toPaidList = [...toPaidList];
    for (let i = 0; i < _tuitionList.length; i++) {
      const tuition = tuitionScheduleList[i];
      if (tuition._id === item._id) {
        tuition.isChecked = e.target.checked;
        if (!tuition.isChecked) {
          var newList = _toPaidList.filter((x) => x.tuitionId !== item._id);
          setToPayableList(newList);
        } else {
          fnSetToPayableList(_toPaidList, item, item.totalToPaid);
          // if (
          //   _toPaidList.filter((x) => x.tuitionId === item._id).length === 0
          // ) {
          //   _toPaidList.push({
          //     tuitionId: item._id,
          //     toPayClassNo: item.totalToPaid,
          //     payableAmount: item.totalUnPaidAmount,
          //   });
          // } else {
          //   for (let j = 0; j < _toPaidList.length; j++) {
          //     const toPaid = _toPaidList[j];
          //     if (item._id === toPaid.tuitionId) {
          //       toPaid.toPayClassNo = item.totalToPaid;
          //       toPaid.payableAmount = item.totalUnPaidAmount;
          //     }
          //   }
          // }

          // setToPayableList(_toPaidList);
        }
        break;
      }
    }

    setTuitionScheduleList(_tuitionList);
  };

  const fnSetToPayableList = (_toPaidList, item, toPayClassNo) => {
    if (_toPaidList?.filter((x) => x.tuitionId === item._id).length === 0) {
      _toPaidList.push({
        tuitionId: item._id,
        toPayClassNo: toPayClassNo,
        payableAmount: item.totalUnPaidAmount,
        subjectName: item.subjectName,
        tutorName: item.tutor,
        totalClass: item.scheduledClasses.length,
        unpaidClass: item?.scheduledClasses?.filter((x) => !x.isPaid).length,
        paidClass: item?.scheduledClasses?.filter((x) => x.isPaid).length,
        amount: item.confirmedTuitionFee,
        tuitionPlace: item.tuitionPlace,
        scheduledClasses: item.scheduledClasses,
      });
    } else {
      for (let j = 0; j < _toPaidList?.length; j++) {
        const toPaid = _toPaidList[j];
        if (item._id === toPaid.tuitionId) {
          toPaid.toPayClassNo = toPayClassNo;
          toPaid.payableAmount = item.totalUnPaidAmount;
        }
      }
    }

    setToPayableList(_toPaidList);
  };

  var checkoutToPaidList = toPaidList?.filter(
    (item) => item?.payableAmount > 0
  );
  const checkVoucherValidity = async (voucherCode) => {
    //e.preventDefault();
    try {
      let totalClassesToPay = checkoutToPaidList.reduce(
        (total, item) => total + parseInt(item?.toPayClassNo, 10) || 0,
        0
      );

      var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
      var res = await axiosRequest({
        //url: "/payment/check-voucher-validity",
        url: "/payment/checkDynamicVoucherValidity",
        method: "post",
        data: {
          voucherCode: voucherCode,
          userId: _tuteair._id,
          //add this
          totalClassesToPay: totalClassesToPay,
        },
      });
      if (res.status === 200) {
        //setVoucherAmount(res?.data?.voucher?.amount);

        if (res?.data?.voucher?.discountType === "percentage") {
          const percentage = res?.data?.voucher?.discountAmount;
          const maxDiscountAmount = res?.data?.voucher?.maxDiscountAmount;
          const totalAmount = (
            checkoutToPaidList?.reduce((accumulator, object) => {
              return accumulator + object.payableAmount;
            }, 0) * 1.1
          ).toFixed(2);
          const offAmount = (totalAmount * 0.01 * percentage).toFixed(2);
          if (offAmount < maxDiscountAmount) {
            setVoucherAmount(offAmount);
          } else setVoucherAmount(maxDiscountAmount);
        } else setVoucherAmount(res?.data?.voucher?.discountAmount);
        toastNotifiy(res?.data?.message, "success");
      }
    } catch (error) {
      console.log(error);
      toastNotifiy(error?.response?.data?.error, "warn");
    }
  };

  var paymentSplitsForStripeAndPaypal = [];
  async function calculatePaymentSplit(totalCredit, totalVoucher) {
    for (let i = 0; i < checkoutToPaidList.length; i++) {
      const classAmount =
        checkoutToPaidList[i]?.amount * checkoutToPaidList[i]?.toPayClassNo;
      const CommissionAmount = classAmount * 0.1; // Calculate 10% commission
      let tuitionId = checkoutToPaidList[i]?.tuitionId;
      let payClassNo = parseInt(checkoutToPaidList[i]?.toPayClassNo);
      const name = checkoutToPaidList[i]?.subjectName;
      const desc = `Pay for ${checkoutToPaidList[i]?.toPayClassNo} classes`;

      let voucherAmount = 0;
      let creditAmount = 0;
      let cardAmount = 0;

      // Calculate voucher amount
      if (totalVoucher > 0) {
        if (classAmount + CommissionAmount >= totalVoucher) {
          voucherAmount = totalVoucher;
          totalVoucher = 0;
        } else {
          voucherAmount = classAmount + CommissionAmount;
          totalVoucher -= voucherAmount;
        }
      }
      if (totalCredit > 0) {
        if (classAmount + CommissionAmount - voucherAmount >= totalCredit) {
          creditAmount = totalCredit;
          totalCredit = 0;
        } else {
          creditAmount = classAmount + CommissionAmount - voucherAmount;
          totalCredit -= creditAmount;
        }
      }
      // Calculate card amount
      cardAmount =
        classAmount + CommissionAmount - voucherAmount - creditAmount;
      cardAmount = Number(cardAmount.toFixed(2)); // Round to two decimal places

      paymentSplitsForStripeAndPaypal.push({
        tuitionId,
        payClassNo,
        name,
        desc,
        voucherAmount,
        creditAmount,
        cardAmount,
      });
    }
    return paymentSplitsForStripeAndPaypal;
  }

  const handleProcessedPaidList = async (totalUnPaidAmount, totalToPaid) => {
    await calculatePaymentSplit(creditAmount, voucherAmount);

    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var total_stripe = 0;
    var p_details = [];
    var line_items = paymentSplitsForStripeAndPaypal?.map((item) => {
      total_stripe = total_stripe + item?.cardAmount;
      p_details.push({
        p: Math.round(item?.cardAmount * 100) / 100,
        c: Math.round(item?.creditAmount * 100) / 100,
        v: Math.round(item?.voucherAmount * 100) / 100,
      });
      return {
        tuitionId: item?.tuitionId,
        payClassNo: parseInt(item?.payClassNo),
        name: item?.name, //subject
        desc: `Pay for ${item?.payClassNo} classes`,
        price: Math.round(item?.cardAmount * 100),
        images: ["https://i.ibb.co/9T0CHzt/tute-logo-1.png"],
      };
    });

    var res = await axiosRequest({
      url: "/payment/checkout-session",
      method: "post",
      data: {
        classes: line_items,
        userId: _tuteair._id,
        p_details,
        voucherCode: voucherCode,
      },
    });

    if (res.status === 200) {
      window.open(res.data.url, "_blank");
      setIsCardBtnDisable(true);
    }
  };

  const handlePayByCredit = () => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Are you sure ? <br> <br> Please confirm.",
      // html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#27a75d",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#FF8819",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await handlePayByCreditFunc();
      }
    });
  };

  const handlePayByCreditFunc = async () => {
    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var totalPayAmount = (
      checkoutToPaidList?.reduce((accumulator, object) => {
        return accumulator + object.payableAmount;
      }, 0) * 1.1
    ).toFixed(2);
    const line_items = checkoutToPaidList?.map((item) => {
      return {
        tuitionId: item?.tuitionId,
        payClassNo: parseInt(item?.toPayClassNo),
        userId: _tuteair._id,
        transactionNo: "",
        gateway: "credit",
      };
    });

    var res = await axiosRequest({
      url: "/payment/pay-by-credit",
      method: "post",
      data: {
        classes: line_items,
        userId: _tuteair._id,
        total_credit: totalPayAmount,
        // noOfClasses: toPaidList[0].toPayClassNo,
      },
    });

    if (res?.data) {
      const msg = "Paid by credit successfully";
      toastNotifiy(msg, "success");

      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/payment-summary`);
      }, 3600);
    } else {
      const msg = "something went wrong, please try again!";
      toastNotifiy(msg, "warn");
    }
  };

  const handlePayByVoucher = () => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "You want to pay by voucher? <br> <br> Please confirm.",
      // html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#27a75d",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#FF8819",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await handlePayByVoucherFunc();
      }
    });
  };
  const handlePayByVoucherFunc = async () => {
    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var totalPayAmount = (
      checkoutToPaidList?.reduce((accumulator, object) => {
        return accumulator + object.payableAmount;
      }, 0) * 1.1
    ).toFixed(2);
    const line_items = checkoutToPaidList?.map((item) => {
      return {
        tuitionId: item?.tuitionId,
        payClassNo: parseInt(item?.toPayClassNo),
      };
    });

    var res = await axiosRequest({
      url: "/payment/pay-by-voucher",
      method: "post",
      data: {
        classes: line_items,
        userId: _tuteair._id,
        total_voucher: totalPayAmount,
        voucherCode: voucherCode,
        // gateway: "voucher",
        // total_voucher: voucherAmount,
      },
    });

    if (res?.data) {
      const msg = "Paid by voucher successfully";
      toastNotifiy(msg, "success");

      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/payment-summary`);
      }, 3600);
    } else {
      const msg = "something went wrong, please try again!";
      toastNotifiy(msg, "warn");
    }
  };

  const handlePayByCreditAndVoucher = () => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "You want to pay by credit & voucher? <br> <br> Please confirm.",
      // html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#27a75d",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#FF8819",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await handlePayByCreditAndVoucherFunc();
      }
    });
  };
  const handlePayByCreditAndVoucherFunc = async () => {
    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var totalPayAmount = checkoutToPaidList?.reduce((accumulator, object) => {
      return accumulator + object.payableAmount;
    }, 0);
    const line_items = checkoutToPaidList?.map((item) => {
      return {
        tuitionId: item?.tuitionId,
        payClassNo: parseInt(item?.toPayClassNo),
      };
    });

    var res = await axiosRequest({
      url: "/payment/pay-by-credit-voucher",
      method: "post",
      data: {
        classes: line_items,
        userId: _tuteair._id,
        total_credit: creditAmount,
        total_voucher: voucherAmount,
        voucherCode: voucherCode,
        //gateway = "credit-voucher",
        //total_voucher:totalPayAmount ,
        //totalPayAmount: totalPayAmount,
        // noOfClasses: toPaidList[0].toPayClassNo,
      },
    });

    if (res?.data) {
      const msg = "Paid by credit and voucher successfully";
      toastNotifiy(msg, "success");

      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/payment-summary`);
      }, 3600);
    } else {
      const msg = "something went wrong, please try again!";
      toastNotifiy(msg, "warn");
    }
  };
  const handleProcessedPaypalPaidList = async (
    totalUnPaidAmount,
    totalToPaid
  ) => {
    await calculatePaymentSplit(creditAmount, voucherAmount);

    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var total_paypal = 0;
    var pay_details = [];
    var line_items = paymentSplitsForStripeAndPaypal?.map((item) => {
      total_paypal = total_paypal + item?.cardAmount;
      pay_details.push({
        p: Math.round(item?.cardAmount * 100) / 100,
        c: Math.round(item?.creditAmount * 100) / 100,
        v: Math.round(item?.voucherAmount * 100) / 100,
      });

      return {
        id: item?.tuitionId,
        tuitionId: item?.tuitionId,
        payClassNo: parseInt(item?.payClassNo),
        name: item?.name, //subject
        desc: `Pay for ${item?.payClassNo} classes`,
        price: item?.cardAmount,
      };
    });

    var res = await axiosRequest({
      url: "/payment/paypal-checkout",
      method: "post",
      data: {
        classes: line_items,
        userId: _tuteair._id,
        pay_details,
        voucherCode: voucherCode,

        //total_paypal: Math.round(total_paypal * 100) / 100,
        // total_credit: creditAmount,
        // total_voucher: voucherAmount,
      },
    });

    if (res.status === 201) {
      // console.log("res data", res.data);
      window.open(res.data, "_blank");
      setIsPaypalBtnDisable(true);
    }
  };

  useEffect(() => {
    getPaymentSummery();
  }, []);

  const getPaymentSummery = async () => {
    var res = await axiosRequest({
      url: "/payment/payment-summary/",
      method: "get",
    });
    if (res) {
      setSummaryList(res.data);
    }
  };

  useEffect(() => {
    getTutiionsSchedule();
  }, [isPayModal]);

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/payment-summary`);
    }
  }, [memberType, navigate]);

  const handleProceedToPay = () => {
    setIsPayModal(true);
    setIsProceedPay(true);
  };

  // const handlePayByCreditAlert = () => {

  //     SweetAlert.fire({
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       backdrop: true,
  //       title: "Are you sure ? <br> <br> Please confirm.",
  //       // html: htmlText,
  //       icon: "info",
  //       showCancelButton: true,
  //       confirmButtonText: "Confirm",
  //       confirmButtonColor: "#27a75d",
  //       cancelButtonText: "Cancel",
  //       cancelButtonColor: "#FF8819",
  //       reverseButtons: true,
  //     });

  // };

  const paymentLimitation = () => {
    if (checkoutToPaidList.length <= 2) {
      setIsPayModal(true);
    } else {
      alert("You can't pay for more than 2 classes");
    }
  };

  const totalPayableAmount =
    checkoutToPaidList?.reduce((accumulator, object) => {
      return accumulator + object.payableAmount;
    }, 0) * 1.1;

  var roundTotalPayableAmount = Math.round(totalPayableAmount * 100) / 100;

  const canPayByVoucher = voucherAmount >= roundTotalPayableAmount;
  const canPayByCredit = Number(creditAmount) >= roundTotalPayableAmount;
  const canPayByCreditAndVoucher =
    voucherAmount + Number(creditAmount) >= roundTotalPayableAmount;

  return (
    <div>
      <span className={`${isPayModal ? "d-none" : ""}`}>
        <Table borderless responsive>
          <thead>
            <tr className="title">
              <th></th>
              <th>#</th>
              <th>Subject</th>
              <th>Tutor Name</th>
              <th>Total Classes</th>
              <th>Cancelled Classes</th>
              <th>Unpaid Classes</th>
              <th>Paid Classes</th>
              <th>Amount</th>
              <th>Pay For Classes</th>
              <th>Sub Amount</th>
            </tr>
          </thead>
          <tbody>
            {tuitionScheduleList?.map((item, i) => (
              <tr
                className={item.isChecked === true ? "checkedAfterEffect" : ""}
                key={i}
                style={{ background: "lightblue" }}
              >
                <td>
                  <Input
                    type="checkbox"
                    disabled={
                      item?.totalToPaid === 0 ||
                      item.scheduledClasses.filter(
                        (i) =>
                          new Date() < new Date(i.endTime) &&
                          !i.isPaid &&
                          i.classStatus !== "cancelled"
                      ).length === 0
                    }
                    checked={item.isChecked}
                    onChange={(e) => clickHandler(e, item)}
                  />
                </td>
                <th scope="row">{i + 1}</th>

                <td>{item?.subjectName}</td>

                <td>{item?.tutor?.firstName + " " + item?.tutor?.lastName}</td>

                <td>
                  {
                    item?.scheduledClasses?.length
                    // item?.scheduledClasses?.filter((x) => x.isPaid).length
                  }
                </td>
                <td>{item?.cancelClasses}</td>
                <td>
                  {item?.scheduledClasses?.filter((x) => !x.isPaid).length === 0
                    ? 0
                    : item.scheduledClasses.filter(
                      (i) =>
                        new Date() < new Date(i.endTime) &&
                        !i.isPaid &&
                        i.classStatus !== "cancelled"
                    ).length}
                </td>
                <td>
                  {item?.scheduledClasses?.filter((x) => x.isPaid).length}
                </td>
                <td>{item?.confirmedTuitionFee} / USD</td>

                <td>
                  <Input
                    style={{ width: "50px", textAlign: "center" }}
                    type="number"
                    className={
                      item?.totalToPaid === 0 ||
                        item.scheduledClasses.filter(
                          (i) =>
                            new Date() < new Date(i.endTime) &&
                            !i.isPaid &&
                            i.classStatus !== "cancelled"
                        ).length === 0
                        ? "payForClasses"
                        : ""
                    }
                    disabled={
                      item?.totalToPaid === 0 ||
                      item.scheduledClasses.filter(
                        (i) =>
                          new Date() < new Date(i.endTime) &&
                          !i.isPaid &&
                          i.classStatus !== "cancelled"
                      ).length === 0
                    }
                    value={
                      Math.max(
                        0,
                        item?.totalToPaid === 0 ? 0 : item?.totalToPaid
                      )

                      // item?.scheduledClasses?.filter((x) => x.isPaid).length
                    }
                    // value={
                    //   item?.totalToPaid === 0
                    //     ? 0
                    //     : item?.totalToPaid - item?.cancelClasses
                    //   // item?.scheduledClasses?.filter((x) => x.isPaid).length
                    // }
                    max={
                      Math.max(
                        0,
                        item.scheduledClasses.filter(
                          (i) =>
                            new Date() < new Date(i.endTime) &&
                            !i.isPaid &&
                            i.classStatus !== "cancelled"
                        ).length
                      )
                      // item?.scheduledClasses?.filter((x) => !x.isPaid).length
                    }
                    min={1}
                    // onChange={(e) => setPayForClasses(e.target.value)}
                    onChange={(e) => handlePayForClasses(e, item)}
                  />
                </td>
                <td>
                  <Input
                    readOnly
                    className="subAmount"
                    style={{ width: "70px", textAlign: "center" }}
                    type="number"
                    value={
                      item.isChecked === true
                        ? item.totalToPaid * item.confirmedTuitionFee
                        : 0
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {checkoutToPaidList?.length > 0 && (
          <span>
            <CardFooter className="text-end mt-2 ">
              <div
                style={{
                  color: "blue",
                  alignItems: "center",
                  marginRight: "2px",
                }}
              >
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Total Amount
                </span>
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  :{" "}
                </span>
                <input
                  className=""
                  style={{
                    width: "60px",
                    textAlign: "center",
                    border: "1px solid #bababa",
                    borderRadius: "3px",
                    fontSize: "16px",
                    padding: "2px 0",
                  }}
                  type="text"
                  disabled
                  value={checkoutToPaidList?.reduce((accumulator, object) => {
                    return accumulator + object.payableAmount;
                  }, 0)}
                />
              </div>
            </CardFooter>

            <CardFooter className="text-end mt-2">
              <button
                className="payByCard"
                onClick={(e) => paymentLimitation()}
              >
                Checkout
              </button>
            </CardFooter>
          </span>
        )}
      </span>

      <span className={`${!isPayModal ? "d-none" : ""}`}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={6} sm={12} xs={12} className="mt-2">
            <img className="paymentBanner" src={payment} alt="payment" />
          </Col>
          <Col md={6}>
            <PaymentContain toPaidList={checkoutToPaidList} />
            <Card
              className="text-end my-3"
              style={{
                border: "1px solid #e4c5c5",
              }}
            >
              {/* sm={ } xs={ } */}
              <Row className="mt-4">
                <Row style={{ marginBottom: "10px" }}>
                  <Col
                    md={`${isProceedPay ? 8 : 5}`} sm={5} xs={5}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Total Amount
                  </Col>
                  <Col
                    md={3} sm={3} xs={3}
                    className={`${isProceedPay ? "d-none" : ""}`}
                  ></Col>
                  <Col md={1} sm={1} xs={2}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      type="text"
                      className="form-control"
                      disabled
                      value={(
                        checkoutToPaidList?.reduce((accumulator, object) => {
                          return accumulator + object.payableAmount;
                        }, 0) * 1.1
                      ).toFixed(2)}
                    />
                  </Col>
                </Row>
                <Row
                  style={{ marginBottom: "10px" }}
                  className={` ${isProceedPay ? "d-none" : ""}`}
                >
                  <Col
                    md={5} sm={3} xs={3}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Apply a voucher
                  </Col>
                  <Col md={3} sm={4} xs={4}                  >
                    <input
                      type="text"
                      placeholder="Enter Voucher Code"
                      className="form-control inputBox"
                      value={voucherCode}
                      onChange={(e) => setVoucherCode(e.target.value)}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1}                  >:</Col>
                  <Col md={2} sm={2} xs={2}                  >
                    <input
                      style={{ textAlign: "center" }}
                      disabled
                      type="text"
                      className="form-control inputBox"
                      value={voucherAmount ? voucherAmount : 0}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1}                  >
                    <button
                      style={{
                        background: "#6666e3",
                        color: "#ffffff",
                        outline: "none",
                        border: "none",
                        padding: "4px 6px",
                        borderRadius: "4px",
                      }}
                      onClick={() => checkVoucherValidity(voucherCode)}
                    >
                      Apply
                    </button>
                  </Col>
                </Row>
                <Row
                  style={{ marginBottom: "10px" }}
                  className={`${isProceedPay ? "d-none" : ""}`}
                >
                  <Col
                    md={5} sm={5} xs={5}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Available Credit
                  </Col>
                  <Col md={3} sm={3} xs={3}                  >
                    <input
                      type="text"
                      className="form-control inputBox"
                      disabled
                      //value={(summaryList?.credit).toFixed(2)}
                      value={parseFloat(summaryList?.credit).toFixed(2)}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      disabled={summaryList?.credit > 0 ? false : true}
                      type="text"
                      pattern="/[0-9]+/"
                      className="form-control"
                      // onChange={(e) =>
                      //   setCreditAmount(
                      //     e.target.value <=
                      //       toPaidList?.reduce((accumulator, object) => {
                      //         return accumulator + object.payableAmount;
                      //       }, 0) && e.target.value <= updatedCredit
                      //       ? e.target.value
                      //       : ""
                      //   )
                      // }
                      // onChange={(e) => {
                      //   e.target.value
                      //     ?.replace(/[^\d.]/g, "")
                      //     ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] >= 0 &&
                      //     e.target.value
                      //       ?.replace(/[^\d.]/g, "")
                      //       ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] <=
                      //       1000000 &&
                      //     setCreditAmount(
                      //       e.target.value <=
                      //         checkoutToPaidList?.reduce(
                      //           (accumulator, object) => {
                      //             return accumulator + object.payableAmount;
                      //           },
                      //           0
                      //         ) && e.target.value <= summaryList?.credit
                      //         ? e.target.value
                      //         : "" &&
                      //             e.target.value
                      //               ?.replace(/[^\d.]/g, "")
                      //               ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
                      //     );
                      // }}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1");

                        //   ?.replace(/[^\d.]/g, "")
                        //   ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0];
                        // const parsedValue = parseFloat(cleanedValue);

                        if (
                          // parsedValue >= 0 &&
                          // parsedValue <= 1000000 &&
                          inputValue <=
                          checkoutToPaidList?.reduce(
                            (accumulator, object) => {
                              return accumulator + object.payableAmount;
                            },
                            0
                          ) *
                          1.1 -
                          voucherAmount &&
                          inputValue <= summaryList?.credit
                        ) {
                          setCreditAmount(inputValue);
                        } else {
                          setCreditAmount("");
                        }
                      }}
                      value={creditAmount}
                      max={summaryList?.credit}
                      min={0}
                    />
                  </Col>
                </Row>
                <Row
                  style={{ marginBottom: "10px" }}
                  className={`${isProceedPay ? "d-none" : ""}`}
                >
                  <Col
                    md={5} sm={5} xs={5}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Payable Amount
                  </Col>
                  <Col md={3} sm={3} xs={3}>
                    {" "}
                    <input
                      // style={{ textAlign: "center" }}
                      type="text"
                      className="form-control inputBox"
                      disabled
                      value={summaryList?.payableAmount}
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      disabled
                      type="text"
                      className="form-control"
                      onChange={(e) => setIncomeAmount(e.target.value)}
                      value={incomeAmount}
                    />
                  </Col>
                </Row>
                <Row
                  style={{ marginBottom: "10px" }}
                  className={`${!isProceedPay ? "d-none" : ""}`}
                >
                  <Col
                    md={8} sm={8} xs={8}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Credit Used
                  </Col>

                  <Col md={1} sm={1} xs={1}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      type="text"
                      disabled
                      className="form-control"
                      value={creditAmount}
                    />
                  </Col>
                </Row>
                <Row
                  style={{ marginBottom: "10px" }}
                  className={`${!isProceedPay ? "d-none" : ""}`}
                >
                  <Col
                    md={8} sm={8} xs={8}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Income Used
                  </Col>

                  <Col md={1} sm={1} xs={1}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      type="text"
                      disabled
                      className="form-control"
                      value={incomeAmount}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col
                    md={`${isProceedPay ? 8 : 5}`} sm={5} xs={5}
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "600",
                    }}
                  >
                    Total Payable
                  </Col>
                  <Col
                    md={3} sm={3} xs={3}
                    className={`${isProceedPay ? "d-none" : ""}`}
                  ></Col>
                  <Col md={1} sm={1} xs={1}>:</Col>
                  <Col md={2} sm={2} xs={2}>
                    <input
                      style={{ textAlign: "center" }}
                      type="text"
                      disabled
                      className="form-control"
                      value={Math.max(
                        0,
                        checkoutToPaidList?.reduce((accumulator, object) => {
                          return accumulator + object.payableAmount;
                        }, 0) *
                        1.1 -
                        creditAmount -
                        voucherAmount
                      ).toFixed(2)}
                    // value={totalPayableAmount}
                    />
                  </Col>
                </Row>
              </Row>
              <Row
                // className="mt-3 ps-2 d-flex align-items-start justify-content-end"
                className={`  payment  ${!isProceedPay ? "d-none" : ""}`}
              >
                <Col md={5} sm={5} xs={5}></Col>
                <Col md={6} sm={6} xs={6}>
                  <Button
                    disabled={isCardBtnDisable ? true : false}
                    className="payByCard"
                    style={{
                      opacity: `${isCardBtnDisable ? 0.5 : 1}`,
                    }}
                    color="primary"
                    onClick={
                      (e) => handleProcessedPaidList()
                      // item?.totalUnPaidAmount,
                      // item?.totalToPaid
                    }
                  >
                    Pay by Card
                  </Button>

                  <div>
                    <img className="payAllCard" src={allCard} alt="visa card" />
                  </div>

                  <button
                    disabled={isPaypalBtnDisable ? true : false}
                    style={{
                      marginRight: 0,
                      paddingRight: 0,
                      outline: "none",
                      border: "none",
                      background: "none",
                      opacity: `${isPaypalBtnDisable ? 0.5 : 1}`,
                    }}
                    onClick={(e) => handleProcessedPaypalPaidList()}
                  >
                    <img src={paypal} alt="paypal" />
                  </button>
                </Col>
              </Row>
              <p style={{ textAlign: "center", margin: "8px 0 6px 0" }}>
                *We ensure the data safety
              </p>
              {modal && (
                <PaypalPaymentModal
                  title={title}
                  isOpen={modal}
                  toggler={toggler}
                  classPaidList={toPaidList}
                // amount={item?.totalUnPaidAmount}
                />
              )}
            </Card>

            <button
              className={`btn btn-sm btn-warning mx-2 w-30 ${!isProceedPay ? "d-none" : ""
                }`}
              onClick={(e) => {
                setIsPayModal(!isPayModal);
                setIsProceedPay(!isProceedPay);
                setToPayableList([]);
                setCreditAmount(0);
                setVoucherAmount(0);
                setVoucherCode("");
                setIsCardBtnDisable(false);
                setIsPaypalBtnDisable(false);
              }}
            >
              Cancel
            </button>

            <CardFooter className={`text-end ${isProceedPay ? "d-none" : ""}`}>
              <button
                className="btn btn-sm btn-warning mx-2 w-30"
                onClick={(e) => {
                  setIsPayModal(!isPayModal);
                  setToPayableList([]);
                  setCreditAmount(0);
                  setVoucherAmount(0);
                  setVoucherCode("");
                }}
              >
                Cancel
              </button>

              {canPayByVoucher ? (
                <button
                  className="payByCard"
                  onClick={() => handlePayByVoucher()}
                >
                  Pay By Voucher
                </button>
              ) : canPayByCredit ? (
                <button
                  className="payByCard"
                  onClick={(e) => handlePayByCredit()}
                >
                  Pay By Credit
                </button>
              ) : canPayByCreditAndVoucher ? (
                <button
                  className="payByCard"
                  onClick={(e) => handlePayByCreditAndVoucher()}
                >
                  Pay By Credit & Voucher
                </button>
              ) : (
                <button
                  className="payByCard"
                  onClick={(e) => handleProceedToPay()}
                  style={{
                    opacity: `${isPaypalBtnDisable ||
                      Number(
                        (
                          checkoutToPaidList?.reduce((accumulator, object) => {
                            return accumulator + object.payableAmount;
                          }, 0) *
                          1.1 -
                          creditAmount -
                          voucherAmount
                        ).toFixed(2)
                      ) < 0.5
                      ? 0.5
                      : 1
                      }`,
                  }}
                >
                  Proceed to Pay
                </button>
              )}

              {/* {checkoutToPaidList?.reduce((accumulator, object) => {
                return accumulator + object.payableAmount;
              }, 0) -
                creditAmount !=
              0 ? (
                <button
                  className="payByCard"
                  // onClick={(e) => setIsPayModal(true)}
                  onClick={() => handleProceedToPay()}
                >
                  Proceed To Pay
                </button>
              ) : (
                <button
                  className="payByCard"
                  // onClick={(e) => setIsPayModal(true)}
                  onClick={(e) => handlePayByCredit()}
                >
                  Pay By Credit
                </button>
              )} */}
            </CardFooter>
          </Col>
        </Row>
      </span>
      {checkoutToPaidList?.length > 0 && (
        <CardFooter className="text-end txt-warning mt-2">
          <div>
            <p style={{ fontWeight: "500" }}>
              N.B: PayPal and Stripe, you cann't pay less than $0.5.
            </p>
          </div>
        </CardFooter>
      )}
    </div>
  );
}
