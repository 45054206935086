import React, { Fragment, useContext, useEffect } from "react";
import CompanySection from "./Company-section/Company";
import Header from "./Header/Header";
import HeroSection from "./Hero-Section/HeroSection";

import AboutUs from "./About-us/AboutUs";
import Features from "./Feature-section/Features";

import FilterContext from "../../_helper/Filter";
import Footer from "./Footer/Footer";
import MinFaqs from "./FrequnetlyAskQustions/MinFAQ";
import Newsletter from "./Newsletter/Newsletter";

const LandingPageContain = () => {
  const { moonlight, setMoonlight } = useContext(FilterContext);

  useEffect(() => {
    document.title = "Home | TuteAir - Learn & Teach Globally";
  }, []);

  // --- setting page theme to light mode ---
  useEffect(() => {
    // console.log(moonlight);
    // console.log(localStorage.getItem('layout_version'));
    setMoonlight(false);
    document.body.className = "light";
    localStorage.setItem("layout_version", "light");
  }, []);

  return (
    <Fragment>
      <div className="" style={{ background: "#f0f8ffd9" }}>
        {/* #f174171f  #f0f8ffd9*/}
        <Header />
        {/* <HeroSection /> */}
        {/* <CompanySection /> */}
        {/* <AboutUs /> */}
        {/* <AboutUs /> */}
        {/* <Features /> */}
        {/* <MinFaqs />/ */}
        {/* <Prices /> */}
        {/* <Testimonials /> */}
        {/* <Newsletter /> */}
        {/* <Footer /> */}
      </div>
    </Fragment>
  );
};

export default LandingPageContain;
