import { apiSlice } from "../api/apiSlice";
import { stUpdatedReplys } from "./reviewsSlice";

export const reviewsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReviews: builder.query({
            query: (tuitionId, sender) =>
                `/review/getReview/${tuitionId}`,
        }),
        addReview: builder.mutation({
            query: (data) => ({
                url: "/newTuition/saveReview",
                method: "POST",
                body: data.data,
            }),
        }),
        addReply: builder.mutation({
            query: (data) => ({
                url: "/newTuition/saveReply",
                method: "POST",
                body: data.data,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const result = await queryFulfilled;
                    dispatch(
                        stUpdatedReplys({
                            updatedReplys: result.data,
                        })
                    );
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useGetReviewsQuery, useAddReviewMutation, useAddReplyMutation } = reviewsApi;
