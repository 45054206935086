import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { RRule } from "rrule";
import { useWindowWidth } from "./useWindowWidth";
import {
  getTutorSchedule,
  handleTuitionDateString,
} from "./tuitionCommonFns";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import { ArrowRight, X } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useEditConversationMutation } from "../../../../redux/features/chat/conversations/conversationsApi";
import { useAddNewTuitionMutation } from "../../../../redux/features/chat/tuitions/tuitionsApi";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { toastNotifiy } from "../../../../Services/AuthVerify";
import { AddNotification } from "../../../../Services/Notification.Service";
import "../../../../style/custom-css/PersonalInfo.css";
import TuitionModalHeader from "./TuitionComponents/TuitionModalHeader";
import { TutorScheduleDisplay } from "./TuitionComponents/TutorScheduleDisplay";
import { useGetRescheduledClassesByTutorIdQuery } from "../../../../redux/features/tuition/tuitionApi";
import {
  findFreeSlots,
  formatStartEndTime,
  timeSlotByWeekDay,
  validateTimeSlot,
  validateTimeSlot2,
  validateTimeSlotFromScheduleList,
  getAllSchedulesByRRule as getRRule
} from "../../../Schedule/Calendar/Reschedule/fnRescheduling";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import { validateTimeFromStudentScheduleList } from "../../../Schedule/Calendar/FreeClass/fnsFreeClass";

const FreeClassModal = ({
  title,
  isOpen,
  toggler,
  conversationId,
  selectedStudentName,
  selectedStudentId,
  freeTuition,
  freeClass,
  setFreeClass
}) => {
  const today = new Date();
  let tomorrow = new Date();
  // console.log("conversationId", conversationId);
  tomorrow.setDate(today.getDate() + 1);
  const { user: loggedInUser } = useSelector((state) => state.auth) || {};
  const { selectedUser, hideMessage, hideConversation } = useSelector((state) => state.conversations) || {};

  const [freeClassTitle, setFreeClassTitle] = useState(`Introductory class with ${selectedStudentName}`);
  const [freeClassDate, setFreeClassDate] = useState(today);
  const [timeSlot, setTimeSlot] = useState({});

  const [modalOpen, setmodalOpen] = useState(isOpen);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [weekdayList, setWeekdayList] = useState([]);
  const [tutorOldSchedule, setTutorOldSchedule] = useState([]);

  const [editConversation, { isSuccess: isEditConversationSuccess, error: err2 }] = useEditConversationMutation();
  const [addNewTuition, { isSuccess: isSaveTuition, data, error: err3 }] = useAddNewTuitionMutation();
  const { data: rescheduledClasses, isLoading, refetch } = useGetRescheduledClassesByTutorIdQuery(loggedInUser);
  const navigate = useNavigate();
  const width = useWindowWidth();

  const {
    getAllSchedulesByRRule,
    scheduleEventList,
    setScheduleEventList,
    filterClassList,
    getRescheduleFromTutor
  } = useContext(ClassSchedulerContext);

  useEffect(() => {
    // console.log("sId", selectedStudentId);
    getTutorSchedule(setTutorOldSchedule, setWeekdayList);
    getRescheduleFromTutor(selectedStudentId);
  }, []);

  useEffect(() => {
    fnValidationTimeSlot();
  }, [tutorOldSchedule, rescheduledClasses, scheduleEventList]);

  const fnValidationTimeSlot = async () => {
    let timeTable = [];
    tutorOldSchedule?.map((c) => {
      timeTable.push({
        dayName: c.dayName,
      });
    });
    const sixMonthAfter = new Date(
      today.getFullYear(),
      today.getMonth() + 6,
      today.getDate()
    );
    let rescheduleUntil = new Date(sixMonthAfter);

    // getting all dates of tutor are available // only days
    let allSchedulesOfTutor = getAllSchedulesByRRule({
      startDate: tomorrow,
      endDate: rescheduleUntil,
      repeat: {
        type: "weekly",
        interval: 1,
      },
      timeTable: timeTable,
    });

    const newTimeTable = allSchedulesOfTutor.filter((date) => {
      const timeSlots = timeSlotByWeekDay(date, tutorOldSchedule);
      return timeSlots?.timeTable.some((element) => {
        if (!element.isBooked) {
          return !isAvailableOnSpecificDate(element.start, element.end, date);
        }
        return false;
      });
    });

    // console.log("newTimeTable", newTimeTable);
    let _newFilteredAvailableDates = newTimeTable.filter(
      (date, i, self) =>
        self.findIndex((d) => d.getTime() === date.getTime()) === i
    );

    setAvailableDates(_newFilteredAvailableDates);
    // setFreeClassDate(new Date(_newFilteredAvailableDates[0]));
    // console.log("_newFilteredAvailableDates[0]", _newFilteredAvailableDates[0]);

    // next day is selected when enter first time to the component
    let nextDaySelected = allSchedulesOfTutor?.filter(
      (x) => moment(x).format("L") > moment(today).format("L")
    )[0];
    setFreeClassDate(nextDaySelected);
    if (tutorOldSchedule) handleRescheduleDate(nextDaySelected);
  };

  const isAvailableOnSpecificDate = (start, end, date) => {
    var startEnd = formatStartEndTime(date, { start, end });
    var _start = new Date(startEnd?.start);
    var _end = new Date(startEnd?.end);
    var dateExist = scheduleEventList.filter(
      (x) =>
        x.start.getTime() === _start.getTime() &&
        x.end.getTime() === _end.getTime()
      //   || x.classStatus === "cancelled"
    );
    return dateExist.length > 0;
  };

  const handleRescheduleDate = (date) => {
    // get all rescheduled dates of selected date from datepicker
    const filteredSelectedDateList = rescheduledClasses?.rescheduleDates?.filter(
      (item) =>
        !item.hasChanged &&
        new Date(item.date).toDateString() === new Date(date).toDateString()
    );
    let timeSlots = timeSlotByWeekDay(date, tutorOldSchedule);
    setFreeClassDate(date ?? new Date());
    let newTimeTable = [];
    for (let i = 0; i < timeSlots?.timeTable?.length; i++) {
      const element = timeSlots?.timeTable[i];
      // checking student calendar he is booked or not with someone on this day.
      let isExist = isAvailableOnSpecificDate(element.start, element.end, date);
      if (!isExist) {
        newTimeTable.push(element);
      }
    }
    // console.log("newTimeTable", newTimeTable);

    let availableTime = newTimeTable?.filter((x) => !x.isBooked);
    let _availableFreeSlots =
    filteredSelectedDateList?.length > 0
        ? checkSchedule(availableTime, filteredSelectedDateList, date)
        : availableTime;
    setAvailableTimeSlots(_availableFreeSlots);
    // console.log("_availableFreeSlots", _availableFreeSlots);

    const firstAvailableTime = _availableFreeSlots?.[0];
    const isValid = validateTimeSlot2(
      new Date(firstAvailableTime?.start),
      new Date(firstAvailableTime?.end),
      _availableFreeSlots
    );

    setTimeSlot({
      oldDate: new Date(),
      // oldDate: event.start,
      start: new Date(firstAvailableTime?.start ?? Date.now()),
      end: new Date(firstAvailableTime?.end ?? Date.now()),
      isValid: !!isValid,
    });
  };

  const getEndTime = (time) => {
    return new Date(time?.getTime() + 1 * 60 * 60 * 1000);;
  };

  function modifyAvailableTime(availableSchedule, date) {
    const givenDate = new Date(date);
    const updatedSchedules = availableSchedule?.map((schedule) => {
      const startDate = new Date(schedule.start);
      startDate.setFullYear(
        givenDate.getFullYear(),
        givenDate.getMonth(),
        givenDate.getDate()
      );
      const updatedStart = startDate.toISOString();

      const endDate = new Date(schedule.end);
      endDate.setFullYear(
        givenDate.getFullYear(),
        givenDate.getMonth(),
        givenDate.getDate()
      );
      const updatedEnd = endDate.toISOString();

      return { start: updatedStart, end: updatedEnd };
    });
    return updatedSchedules;
  }

  function checkSchedule(schedule, bookedSchedule, date) {
    var freeList = [];
    schedule = modifyAvailableTime(schedule, date);
    for (let i = 0; i < schedule?.length; i++) {
      const sch = schedule[i];
      var freeSlots = findFreeSlots(sch, bookedSchedule);
      var concatenateList = freeList.concat(freeSlots);
      freeList = concatenateList;
    }
    return freeList;
  }

  const handleSelectedTimeSlot = (time, type, _ts, oppositeTime, hideToast) => {
    if (_ts) {
      // setTimeSlot({
      //   start: time,
      //   end: endTime,
      // });
      let isValid;
      let bookedEvent;
      let isStudentAvailable = true;
      if (type === "start") {
        let endTime = oppositeTime ? oppositeTime : getEndTime(time);
        isValid = validateTimeSlot(time, endTime, tutorOldSchedule, freeClassDate);
        // if valid then check student is booked with other tutor on this time
        if (isValid) {
          bookedEvent = validateTimeFromStudentScheduleList(scheduleEventList, freeClassDate, time, endTime);
          if (bookedEvent && bookedEvent?.length > 0) {
            !hideToast && toastNotifiy(`${selectedStudentName} is not available in this time`, "warn");
            isValid = false;
            isStudentAvailable = false;
          }
        }

        setTimeSlot({
          oldDate: new Date(),
          start: time,
          end: endTime,
          isValid: isValid,
        });
      }
      else {
        let startTime = oppositeTime ? oppositeTime : timeSlot?.start;
        isValid = validateTimeSlot(startTime, time, tutorOldSchedule, freeClassDate);
        // if valid then check student is booked with other tutor on this time
        if (isValid) {
          bookedEvent = validateTimeFromStudentScheduleList(scheduleEventList, freeClassDate, startTime, time);
          if (bookedEvent && bookedEvent?.length > 0) {
            !hideToast && toastNotifiy(`${selectedStudentName} is not available in this time`, "warn");
            isValid = false;
            isStudentAvailable = false;
          }
        }
        setTimeSlot({
          oldDate: new Date(),
          start: startTime,
          end: time,
          isValid: isValid,
        });
      }
      return isStudentAvailable;
    }
  };

  function getWeeklySchedules(date, timeTable) {
    var weekDay = moment(date).format("dddd");
    var startEnd = null;
    timeTable?.map((x) => {
      if (x.dayName.toUpperCase() === weekDay.substring(0, 3).toUpperCase()) {
        startEnd = formatStartEndTime(date, x);
      }
      return startEnd;
    });
    return startEnd;
  }

  // --- validates input fields before storing in DB ---
  const validateFreeClassData = () => {
    if (!freeClassTitle) {
      toastNotifiy("Title is required", "warn");
      return false;
    } 
    else {
      return true;
    }
  };

  // executes on click create free class button
  const handleFreeClassConfirmation = async (e) => {
    e.preventDefault();
    // validating to check no field is empty
    const isValidData = validateFreeClassData();
    // executes if data is validated
    if (isValidData) {
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
        title: "Are you sure to create a free class?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
        html: "You can join the class from calendar",
        width: "500px",
      }).then(async (result) => {
        if (result.value) {
          // create class when clicked on yes
          await createFreeClass();
          SweetAlert.fire("Free class created successfully!", "", "success");
        } else {
        }
      });
    }
  };

  const createFreeClass = async () => {
    // console.log("new Date(freeClassDate)", new Date(freeClassDate));
    const startMoment = moment(freeClassDate);
    const startTimeMoment = moment(timeSlot?.start).utcOffset(startMoment.utcOffset());
    startMoment.set({
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: startTimeMoment.second(),
      millisecond: startTimeMoment.millisecond()
    });
    const endMoment = moment(freeClassDate);
    const endTimeMoment = moment(timeSlot?.end).utcOffset(endMoment.utcOffset());
    endMoment.set({
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond()
    });

    const updatedStartDate = startMoment.toISOString();
    const updatedEndDate = endMoment.toISOString();
    // console.log("updatedStartDate", updatedStartDate);
    // console.log("updatedEndDate", updatedEndDate);
    const timeTable = [{
      dayName: new Date(freeClassDate).toLocaleDateString("en-US", { weekday: "short" }).toLowerCase(),
      end: updatedEndDate,
      start: updatedStartDate,
    }]
    const obj = {
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      repeat: { type: "weekly", interval: 1 },
      timeTable: timeTable,
    };
    const classSchedule = getAllSchedulesByRRule(obj);
    // console.log("classSchedule", classSchedule);
    const finalClassSchedule = getWeeklySchedules(
      new Date(classSchedule[0]),
      timeTable
    );
    console.warn("finalClassSchedule", finalClassSchedule);
    let eventList = [];
    eventList.push({
      date: new Date(finalClassSchedule?.start),
      startTime: new Date(finalClassSchedule?.start),
      endTime: new Date(finalClassSchedule?.end),
      isPaid: true,
      classStatus: "FREE",
      title: freeClassTitle,
      isFreeClass: true
    });
    // console.log("eventList", eventList);
    // save the tuition details with created class
    saveTuitionSchedule(eventList);
  }

  const tuitionSaveAPI = async (eventList) => {
    // save tuition data
    let res = await axiosRequest({
      url: "/tuition/tuition-schedule",
      method: "post",
      data: {
        tutorId: loggedInUser,
        studentId: selectedStudentId,
        status: "FREE",
        scheduledClasses: eventList,
        tuitionPlace: "Online",
        classStart: freeClassDate,
        classEnd: freeClassDate,
      },
    });
    return res;
  }

  const saveTuitionSchedule = async (eventList) => {
    let response = freeClass === 3 ? await tuitionSaveAPI(eventList) : await updateFreeClassSchedule(eventList[0]);
    // check success > decrement freeClassCount > update reschedule list > notify student > navigate
    if (response?.status === 201) {
      const tuitionId = response?.data?._id ? response?.data?._id : freeTuition[0]?._id;
      const classId = response?.data?.scheduledClasses[response?.data?.scheduledClasses.length - 1]?._id;
      await decrementFreeClass();
      await updateRescheduleDates(tuitionId, classId);
      const notifyObj = {
        receiver: selectedStudentId,
        title: "A free class is created for you",
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/calendar",
        isPublic: false,
      };
      AddNotification(notifyObj);
      const tuitionStartDateString = handleTuitionDateString(new Date(freeClassDate));
      const tuitionScheduleString = `${new Date(freeClassDate).toLocaleDateString("en-US", { weekday: "long" })} from ${new Date(timeSlot?.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} to ${new Date(timeSlot?.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
      editConversation({
        sender: loggedInUser,
        id: conversationId,
        data: {
          message: `A free class has been created. Class details are as follows- [Class Date]: ${tuitionStartDateString}, ${tuitionScheduleString}. You can join the class from calendar.`,
        },
      });
      toastNotifiy("Free class is created", "success");
      navigate(`${process.env.PUBLIC_URL}/inbox/`);
      toggler();
    }
  };

  const updateFreeClassSchedule = async (newFreeClass) => {
    // add new schedule in free tuition
    const res = await axiosRequest({
      url: "/tuition/append-free-class",
      method: "put",
      data: {
        tuitionId: freeTuition[0]?._id,
        newFreeClass: newFreeClass,
        updateCount: false
      },
    });
    return res;
  };

  const decrementFreeClass = async () => {
    const decrementClass = freeClass - 1;
    setFreeClass(decrementClass);
    // decrement free class count
    let res = await axiosRequest({
      url: "/chat/newConversation/updateFreeClassCount",
      method: "put",
      data: {
        conversationId: conversationId,
        remainingClassCount: decrementClass
      },
    });
  }

  const updateRescheduleDates = async (tuitionId, classId) => {
    var dt1 = moment(freeClassDate).format("YYYY-MM-DD");
    var _start = moment(timeSlot?.start).format("LT");
    var _end = moment(timeSlot?.end).format("LT");
    var start = moment(`${dt1} ${_start}`, "YYYY-MM-DD HH:mm aa").format();
    var end = moment(`${dt1} ${_end}`, "YYYY-MM-DD HH:mm aa").format();
    const data = {
      tuitionId: tuitionId,
      tutorId: loggedInUser,
      classId: classId,
      startTime: start,
      endTime: end,
      date: start,
      isPaid: true,
      reschedule_infos: [],
      rescheduleBy: loggedInUser,
      hasChanged: false,
      isFreeClass: true,
      classInfo: {},
    };
    let res = await axiosRequest({
      url: "/tuition/rescheduled-class/",
      method: "put",
      data: data,
    });
    refetch();
  };

  const handleAvailableTime = (item, start, end) => {
    const isStartValid = handleSelectedTimeSlot(new Date(start), "start", timeSlot, new Date(end), true);
    const isEndValid = handleSelectedTimeSlot(new Date(end), "end", timeSlot, new Date(start), true);
    if(!isStartValid || !isEndValid) {
      toastNotifiy(`${selectedStudentName} is not available in this time`, "warn");
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={modalOpen}
        toggle={toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-lg"
        backdrop={"static"}
      >
        <TuitionModalHeader
          title={`${title} with ${selectedStudentName}`}
          toggler={toggler}
        ></TuitionModalHeader>
        <ModalBody className={"pb-0 "}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm py-1">
                <Row>
                  <Col sm="12" xl="12">
                    <Row className="mb-3">
                      <Col lg={2}>
                        <Label className="col-form-label asterisk" htmlFor="">
                          Title
                        </Label>
                      </Col>
                      <Col lg={8}>
                        <Input
                          className="form-control inputFieldStyle"
                          type="text"
                          placeholder="Enter a title for the free class"
                          value={freeClassTitle}
                          onChange={(e) => {
                            setFreeClassTitle(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Label className="asterisk" for="exampleEmail" size="lg" sm={2}>
                        Date
                      </Label>
                      <Col xl="4" sm="4" lg="4">
                        <DatePicker
                          className="form-control datetimepicker-input digits"
                          selected={freeClassDate}
                          onChange={(date) => handleRescheduleDate(date)}
                          dateFormat="MMM dd, yyyy"
                          includeDates={availableDates}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="2"></Col>
                      <Col sm="10">
                        <div>
                          <Link
                            to={`${process.env.PUBLIC_URL}/tutor-account`}
                            state={{ to: "3" }}
                          >
                            Click Here To Update Your Schedule
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Row>
                        <Col sm={2}></Col>
                        <Col lg={8} className="px-4">
                          {availableTimeSlots?.filter((x) => !x.isBooked)
                            ?.length > 0 ? (
                            <Row
                              className={`align-items-center weekly_schdule_box
                              ${
                                !timeSlot?.hasOwnProperty("isValid")
                                  ? ""
                                  : timeSlot?.isValid
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              <div className="align-items-center d-flex">
                                <Label
                                  className="m-r-5"
                                  for="exampleEmail2"
                                  sm={2}
                                >
                                  {new Date(freeClassDate).toLocaleDateString("en-US", { weekday: "long" }).slice(0, 3)}
                                </Label>
                                <Col xl="3" sm="3" lg="3">
                                  <DatePicker
                                    className="form-control datetimepicker-input digits"
                                    selected={new Date(timeSlot?.start)}
                                    onChange={(sTime) => {
                                      handleSelectedTimeSlot(new Date(sTime), "start", timeSlot);
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Start"
                                    timeFormat="h:mm aa"
                                    dateFormat="h:mm aa"
                                  />
                                </Col>
                                <Label
                                  for="exampleEmail2"
                                  className="text-center"
                                  sm={1}
                                >
                                  <ArrowRight />
                                </Label>
                                <Col xl="3" sm="3" lg="3">
                                  <DatePicker
                                    className="form-control datetimepicker-input digits"
                                    selected={timeSlot?.end}
                                    onChange={(eTime) => {
                                      handleSelectedTimeSlot(new Date(eTime), "end", timeSlot);
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Start"
                                    timeFormat="h:mm aa"
                                    dateFormat="h:mm aa"
                                    minTime={new Date(timeSlot?.start)}
                                    maxTime={new Date("2022-11-01T17:59:00.000Z")}
                                  />
                                </Col>
                                <Label
                                  className="text-center m-l-5"
                                  for="exampleEmail2"
                                  sm={3}
                                >
                                  {/* <b> {`(${item.timeDiff})`}</b> */}
                                </Label>
                              </div>
                              <Col md={12}>
                                <Row className="py-2">
                                  <Col md={2}></Col>
                                  <Col md={9}>
                                    <TutorScheduleDisplay
                                      handleAvailableTime={handleAvailableTime}
                                      availableSlots={availableTimeSlots
                                        .sort((a, b) => {
                                          const startTimeA = new Date(`1970/01/01 ${moment(a.start).format("HH:mm")}`);
                                          const startTimeB = new Date(`1970/01/01 ${moment(b.start).format("HH:mm")}`);
                                          return startTimeA - startTimeB;
                                        })}
                                      bookedSlots={availableTimeSlots
                                        .filter((time) => time.isBooked)
                                        .sort((a, b) => {
                                          const startTimeA = new Date(`1970/01/01 ${moment(a.start).format("HH:mm")}`);
                                          const startTimeB = new Date(`1970/01/01 ${moment(b.start).format("HH:mm")}`);
                                          return startTimeA - startTimeB;
                                        })}
                                    ></TutorScheduleDisplay>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col md={12}>
                                <h4 className="text_red text-center">
                                  There is no available time slot on this day
                                </h4>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn
            attrBtn={{
              color: "success",
              disabled: timeSlot.hasOwnProperty("isValid") && !timeSlot?.isValid || !freeClassTitle,
              onClick: (e) => handleFreeClassConfirmation(e),
            }}
          >
            {"Create a Free Class"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default FreeClassModal;
