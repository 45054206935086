import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Message from "./Message";

function convertIsoToLocalInMessages(messageObjects) {
  return messageObjects.map(messageObject => ({
    ...messageObject,
    text: convertIsoToLocalInText(messageObject.text),
  }));
}

function convertIsoToLocalInText(text) {
  const regex = /([^\s]+)Z/g;

  return text.replace(regex, (match, isoDateTime) => {
    try {
      const localDate = (new Date(isoDateTime + "Z")).toLocaleTimeString(
        [], {
        hour: "2-digit",
        minute: "2-digit",
      }
      );
      return localDate;
    } catch (error) {
      console.warn(`Error parsing '${isoDateTime}' as ISO date:`, error);
      return match;
    }
  });
}

export default function Messages({ messages = [] }) {
  const [formattedMessages, setFormattedMessages] = useState();
  const { user } = useSelector((state) => state.auth) || {};
  const [messageStatus, setMessageStatus] = useState("")
  useEffect(() => {
    scroolToBottom();
  }, [messages]);

  const scroolToBottom = () => {
    var container = document.querySelector(".chat-history");
    if (messages?.length > 0) {
      if (container?.scrollBy) {
        setTimeout(function () {
          container.scrollBy({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }, 500);
      }
      const { sender, seenBy } = messages[messages.length - 1];
      if (sender === user && seenBy?.length === 0) {
        setMessageStatus("sent");
      }
      else if (sender === user && seenBy?.length > 0) {
        setMessageStatus("read");
      }
      else {
        setMessageStatus("");
      }
    }
  };

  useEffect(() => {
    const convertedMessages = convertIsoToLocalInMessages(messages);
    setFormattedMessages(convertedMessages);
  }, [messages]);

  // const { email } = user || {};
  return (
    <div className="chat-history chat-msg-box custom-scrollbar">
      <ul className="simple-list">
        {
          // messages
          formattedMessages && formattedMessages
            .slice()
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((message, i) => {
              const { text: lastMessage, sender, time } = message || {};

              const justify = sender !== user ? "" : "pull-right other-message";
              const justify2 = sender !== user ? "float-start" : "float-end";

              return (
                <Message
                  key={i}
                  justify={justify}
                  imgClass={justify2}
                  message={lastMessage}
                  time={time}
                />
              );
            })}
      </ul>
      <p className={`text-end`} style={{ color: "#a6a2a2" }}>
        {
          messageStatus === "read" ?
            <span>
              Read <i className="fa fa-check-circle m-l-5"></i>
            </span>
            : messageStatus === "sent" ?
              <span>
                Sent <i className="icofont icofont-check-circled m-l-5"></i>
              </span>
              : ""
        }
      </p>
    </div>
  );
}
