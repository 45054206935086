import React, { Fragment } from "react";
import DefaultContain from "../../../Component/DashBoard/Default/index";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const Default = (e) => {
  useLogoutSession();
  return (
    <Fragment>
      {/* <Breadcrumbs
        parent="Dashboards"
        title={`${swMember.toUpperCase()} Dashboard`}
      /> */}
      <DefaultContain />
    </Fragment>
  );
};
export default Default;
