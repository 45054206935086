import React, { useState } from "react";
import "../../../assets/images/help-page/createAccount.png";
import Slider from "react-slick";
import { Card, CardBody, CardHeader } from "reactstrap";
import Image from "../../../CommonElements/Media";
import { Link } from "react-router-dom";
const HelpSlider = ({
  slides,
  rtl,
  lazyLoad,
  autoplay,
  focusOnSelect,
  helpList,
}) => {
  var settings = {
    customPaging: function (i) {
      return (
        // multiple text
        <btn
          className="txt-info d-flex justify-content-between px-1"
          style={{
            borderBottom: `2px solid #bcc1d1`,
          }}
        >
          {helpList[i]?.indicator}
        </btn>

        // single text
        //  <btn style={{ borderBottom:`2px solid #bcc1d1`}} className='txt-warning d-flex justify-content-between px-1'>{`${i + 1}`}
        //   </btn>
      );
    },
    dots: true,
    fade: true,
    dotClass: "slick-dots slick-thumb",
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    infinite: true,
    // autoplay: true,
    speed: 500,
    arrows: false,
    slidesToShow: slides,
    slidesToScroll: 1,
    adaptiveHeight: false,
    // autoplay: true,
    autoplaySpeed: 90000,
    centerPadding: "0px",
    centerMode: false,
    rtl: rtl,
    focusOnSelect: focusOnSelect,
    lazyLoad: lazyLoad,
    className: "center",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Card>
      <CardBody>
        <Slider {...settings}>
          {helpList?.map((item) => (
            <div className="item" key={item.id}>
              {/* <Image
                attrImage={{
                  src: `${item.img}`,
                  alt: "",
                  className: "img-fluid",
                }}
              /> */}
              <img
                width={"100%"}
                className="b-r-10"
                src={`${item.img}`}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </CardBody>
    </Card>
  );
};

export default HelpSlider;
