import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  Button,
  Col,
  Container,
  Row,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";

import { useSelector } from "react-redux";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import FilterOptionsContain from "../../FindTutors/ListView/FilterOptions";
// import MapViewContain from "../../FindTutors/MapView";
// import EducationFilter from "./EducationFilter";
import { Filter, List, MapPin, RefreshCcw, X } from "react-feather";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import {
  filterByTutorName,
  filterByAge,
  filterByRating,
  filterByEducation,
  filterByLanguage,
  filterByPrice,
  filterBySchedule,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
  filterByWorkExperience,
} from "../../../Services/Filter.Service";
import QuickSearchPublic from "../Hero-Section/QuickSearch";
import TutorListView from "./TutorListView";
import MapView from "./MapView";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import TuitionFeesFilter from "../../FindTutors/ListView/TuitionFeesFilter";
import EducationFilter from "../../FindTutors/ListView/EducationFilter";
import WorkExperienceFilter from "../../FindTutors/ListView/WorkExperienceFilter";
import TutorLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";
import RatingFilter from "../../FindTutors/ListView/RatingFilter";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";
import { useWindowWidth } from "../../Chat/NewChat/inbox/useWindowWidth";
export default function AllTutorList() {
  const {
    name,
    setName,
    age,
    rating,
    isRatingChange,
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    scheduleFilter,
    setScheduleFilter,
    educationFilter,
    setEducationFilter,
    workExperiencesFilter,
    setWorkExperiencesFilter,
    priceFilter,
    setPriceFilter,
    // myFilter: filters,
    // setMyFilter: setFilters,
  } = useContext(FilterContext);

  const {
    tutorList: tutors2,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
    tutorWithRatings,
    ratingList,
    getallReviewsAndRatings,
    countries,
    publicTutors: tutors,
    setPublicTutors,
    showPublicTutors,
    setShowPublicTutors,
    getPublicTutors,
  } = useContext(TutorsContext);

  const width = useWindowWidth();
  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSearched, setIsSearched] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [isTutorName, setIsTutorName] = useState(true);
  const [tutorWithRatingsList, setTutorWithRatings] = useState([]);

  useEffect(() => {
    if (tutors.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  });

  const memoizedGetallReviewsAndRatings = useMemo(
    () => getallReviewsAndRatings,
    []
  );

  useEffect(() => {
    memoizedGetallReviewsAndRatings();
  }, [memoizedGetallReviewsAndRatings]);

  const ratigsnWithTutorList = useMemo(() => {
    const updatedRatings = [];
    tutors?.forEach((item) => {
      let totalRating = 0;
      let noOfClasses = 0;

      ratingList?.forEach((item2) => {
        if (item.userId === item2.tutorId) {
          if (item2?.feedback?.fromStudent?.rating !== undefined) {
            totalRating += item2?.feedback?.fromStudent?.rating;
            noOfClasses += 1;
          }
        }
      });

      if (totalRating > 0) {
        updatedRatings.push({
          noOfClasses,
          rating: totalRating / noOfClasses,
          tutorId: item.userId,
        });
      }
    });
    setTutorWithRatings(updatedRatings);

    return updatedRatings;
  }, [tutors, ratingList]);

  //tutor list show based on user's data
  // function getUserData(showPublicTutors, userId) {
  //   const userData = Array.from(
  //     new Set(
  //       showPublicTutors
  //         ?.filter((item) => item.userId === userId)
  //         .flatMap((item) =>
  //           item.interestToLearn?.flatMap((item2) => [
  //             ...(item?.address?.city ? [item?.address?.city] : []),
  //             ...(item?.address?.state ? [item?.address?.state] : []),
  //             ...(item?.address?.country ? [item?.address?.country] : []),
  //             ...(item2.subjects?.map((item3) => item3.tag) || []),
  //             // ...(item.languages?.map((item4) => item4) || []),
  //             ...(item2?.amount?.initialPrice
  //               ? [item2.amount.initialPrice]
  //               : []),
  //           ])
  //         )
  //     )
  //   );

  //   return userData || [];
  // }

  // function filterTutorsByData(showPublicTutors, userSubject) {
  //   const matchedTutors = [];
  //   const unmatchedTutors = [];

  //   showPublicTutors?.forEach((item) => {
  //     const tutorDataList = item?.interestAndPricing?.map((item2) => [
  //       ...(item?.address?.city ? [item?.address?.city] : []),
  //       ...(item?.address?.state ? [item?.address?.state] : []),
  //       ...(item?.address?.country ? [item?.address?.country] : []),
  //       ...(item2?.subjects?.map((item3) => item3.tag) || []),
  //       // ...(item4.languages?.map((item6) => item6) || []),
  //       ...(item2?.amount?.initialPrice ? [item2.amount.initialPrice] : []),
  //     ]);

  //     if (
  //       tutorDataList &&
  //       tutorDataList.some((subject) => userSubject.includes(subject))
  //     ) {
  //       matchedTutors.push(item);
  //     } else {
  //       unmatchedTutors.push(item);
  //     }
  //   });

  //   return { matchedTutors, unmatchedTutors };
  // }

  // function sortTutorsByData(showPublicTutors, userSubject) {
  //   const sortedTutors = showPublicTutors.sort((a, b) => {
  //     const matchedSubjectsA = userSubject.filter((subject) =>
  //       hasMatchingData(a, subject)
  //     );

  //     const matchedSubjectsB = userSubject.filter((subject) =>
  //       hasMatchingData(b, subject)
  //     );

  //     if (matchedSubjectsA.length === matchedSubjectsB.length) {
  //       return (
  //         userSubject.findIndex((subject) => subject === matchedSubjectsA[0]) -
  //         userSubject.findIndex((subject) => subject === matchedSubjectsB[0])
  //       );
  //     } else {
  //       return matchedSubjectsB.length - matchedSubjectsA.length;
  //     }
  //   });

  //   return sortedTutors;
  // }

  // function combineTutors(matchedTutors, unmatchedTutors) {
  //   const combinedTutors = [...matchedTutors, ...unmatchedTutors];
  //   return combinedTutors;
  // }

  // function hasMatchingData(tutor, subject) {
  //   return (
  //     tutor.interestAndPricing?.some((item) =>
  //       item.subjects?.some((subjectItem) => subjectItem.tag === subject)
  //     ) ||
  //     tutor.address?.city === subject ||
  //     tutor.address?.state === subject ||
  //     tutor.address?.country === subject ||
  //     // tutor.languages?.some((languageItem) => languageItem === subject) ||
  //     (tutor.amount?.initialPrice && tutor.amount.initialPrice === subject)
  //   );
  // }

  // const userSubject = getUserData(showPublicTutors, userId);

  // const { matchedTutors, unmatchedTutors } = filterTutorsByData(
  //   showPublicTutors,
  //   userSubject
  // );
  // const sortedTutors = sortTutorsByData(matchedTutors, userSubject);
  // const combinedTutors = combineTutors(sortedTutors, unmatchedTutors);

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredTutors = showPublicTutors?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "student" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );

  const showTutors = allFilteredTutors.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredTutors?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const fetchFilteredTutors = async () => {
    setIsLoading(true);

    var filteredTutors = [...tutors];
    var ratingsTutors = [...ratigsnWithTutorList];
    // filtering starts here

    if (filters.name) {
      filteredTutors = await filterByTutorName(filteredTutors, filters.name);
    }

    if (filters.age) {
      filteredTutors = await filterByAge(filteredTutors, filters.age);
    }
    if (isRatingChange && filters.rating) {
      filteredTutors = await filterByRating(
        filteredTutors,
        ratingsTutors,
        filters.rating
      );
    }

    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.media?.picture?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }

        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    if (filters.scheduleFilter) {
      filteredTutors = await filterBySchedule(
        filteredTutors,
        filters.scheduleFilter
      );
    }
    if (filters.educationFilter) {
      filteredTutors = await filterByEducation(
        filteredTutors,
        filters.educationFilter
      );
    }
    if (filters.workExperiencesFilter) {
      filteredTutors = await filterByWorkExperience(
        filteredTutors,
        filters.workExperiencesFilter
      );
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }
    // filtering ends here
    setShowPublicTutors(filteredTutors);
    // setTutorsToShow(filteredTutors);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   fetchFilteredTutors();
  // }, [filters]);

  useEffect(() => {
    setIsLoading(true);

    if (tutors?.length === 0) {
      getPublicTutors();
    }

    if (location?.country) filters.location = location;

    if (subject?.length > 0) filters.subject = subject;

    fetchFilteredTutors();

    // if (tutors.length === 0 && !filters) GetAllTutors();
    setIsLoading(false);

    return () => {
      //setTutorList(tutors);
    };
  }, [filters]);

  const updateFilter = (a) => {
    if (a === 1) setIsSearched(!isSearched);
    setIsReset(false);

    const addedFilter = {};

    if (name != " ") {
      addedFilter.name = name;
    }

    if (gender != "0") {
      addedFilter.gender = gender;
    }

    if (age?.length != 0) {
      addedFilter.age = age;
    }
    if (isRatingChange && rating.length != 0) {
      addedFilter.rating = rating;
    }

    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages?.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location)?.length != 0) {
      addedFilter.location = location;
    }
    if (tutionType?.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject?.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter)?.length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter)?.length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(scheduleFilter)?.length) {
      addedFilter.scheduleFilter = scheduleFilter;
    }
    if (Object.keys(educationFilter)?.length) {
      addedFilter.educationFilter = educationFilter;
    }
    if (Object.keys(workExperiencesFilter)?.length) {
      addedFilter.workExperiencesFilter = workExperiencesFilter;
    }
    if (Object.keys(priceFilter)?.length) {
      addedFilter.priceFilter = priceFilter;
    }
    setFilters(addedFilter);
  };

  const handleSearch = () => {
    setIsSearched(!isSearched);
  };

  const handleRefresh = () => {
    setIsReset(true);
    getPublicTutors();
  };

  const [isListView, setIsListView] = useState(true);
  const [isMapViewView, setIsMapView] = useState(false);

  const handleListView = (e) => {
    e.preventDefault();
    setIsListView(true);
    setIsMapView(false);
  };
  const handleMapView = (e) => {
    e.preventDefault();
    setIsMapView(true);
    setIsListView(false);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    getPublicTutors();
  };

  return (
    <Fragment>
      <Container fluid={true} className="pt-2">
        <Row
          className={` form-inline mx-0 search_tutor pt-4 
          `}
          style={{ marginTop: "0.4rem", placeContent: "center" }}
        >
          <QuickSearchPublic isReset={isReset} countries={countries} />

          <div className="col-md-1 d-flex mb-3 search_btn_public">
            <button
              className="btn btn-outline-info"
              style={{ minHeight: "38px" }}
              onClick={(e) => updateFilter(1)}
            >
              Search
            </button>
          </div>

          <button
            className="btn btn-sm btn-outline-warning px-2 d-none"
            style={{
              marginLeft: "50px",
              width: "120px",
              textAlign: "-webkit-match-parent",
              fontSize: "14px",
            }}
          >
            <Filter height={17} />
            <span style={{ position: "absolute", marginTop: "0px" }}>
              Filter More
            </span>
          </button>
        </Row>
        {/* 
        <Row className="mb-0 py-2 view_list">
          <Col sm="6" className="quick_search ">
            <div
              className={`square-product-setting d-inline-block ${
                !isSearched ? "d-none" : ""
              }`}
              style={{ paddingRight: "20px" }}
            >
              <button
                className="btn btn-sm btn-outline-warning"
                onClick={handleSearch}
              >
                Show Quick Search
              </button>
            </div>
          </Col>
        </Row> */}
        <Row className="d-none">
          <Col sm="12">
            Filtered By:{" "}
            <span className="px-2">
              <span className="text-primary">
                Bangladesh
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Dhaka
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Physics
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">Clear All</span>
            </span>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={8} className={`${width < 768 ? "d-none" : "d-block"}`}>
            <Col md="12 search_title" className="mt-2">
              <h4>Your Search Results</h4>
            </Col>
            <Col sm="8">
              {isLoading ? null : (
                <p className="pb-1">
                  About {allFilteredTutors?.length} tutors found (0.60 seconds)
                </p>
              )}
            </Col>
          </Col>
          <Col
            md={4}
            className={`px-3 text-nowrap ${
              width < 768
                ? "d-flex justify-content-between align-items-center"
                : ""
            }`}
            style={{ textAlign: "-webkit-right" }}
          >
            <div>
              <div className={`square-product-setting d-inline-block`}>
                <a
                  className={`icon-grid m-0 list-layout-view`}
                  onClick={(e) => handleRefresh(e)}
                  href="#javascript"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Refresh List"
                >
                  <RefreshCcw />
                </a>
              </div>
              <div className={`mx-2 square-product-setting d-inline-block`}>
                <a
                  className={`icon-grid m-0 list-layout-view  ${
                    isListView ? "icon_visited" : ""
                  }`}
                  onClick={(e) => handleListView(e)}
                  href="#javascript"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="List View"
                >
                  <List />
                </a>
              </div>
              <div className={`square-product-setting d-inline-block`}>
                <a
                  className={`icon-grid grid-layout-view  ${
                    isMapViewView ? "icon_visited" : ""
                  }`}
                  onClick={(e) => handleMapView(e)}
                  href="#javascript"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Map View"
                >
                  <MapPin />
                </a>
              </div>
            </div>
            <div
              className={`${width < 768 ? "d-block" : "d-none"}`}
              style={{ textAlign: "end", MarginRight: "2px" }}
            >
              More Filter Below
            </div>
          </Col>
        </Row>
        <Row className="mb-5 tutorViewResponsive">
          <Col className="mb-5 tutorViewResponsive">
            <Col>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <PulseLoader
                    color="blue"
                    isLoading={isLoading}
                    size={25}
                    aria-label="isLoading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <span>
                  {isListView && (
                    <TutorListView
                      tutors={showTutors}
                      allFilteredTutors={allFilteredTutors}
                      tutorWithRatings={ratigsnWithTutorList}
                    />
                  )}
                  {isMapViewView && (
                    <MapView
                      showTutors={showPublicTutors}
                      tutorWithRatings={ratigsnWithTutorList}
                    />
                  )}
                </span>
              )}
            </Col>
            <Col md={8} className={`${width > 768 ? "d-none" : "d-block"}`}>
              <Col md="12 search_title" className="mt-2">
                <h4>Your Search Results</h4>
              </Col>
              <Col sm="8">
                <p className="pb-1">
                  About {allFilteredTutors?.length} tutors found (0.60 seconds)
                </p>
              </Col>
            </Col>
          </Col>
          <Col xl="4" className="filterListResponsive mt-0 mb-2">
            {/* <div className="d-none">
              <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
            </div> */}

            <div>
              <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                <AccordionItem>
                  <AccordionHeader targetId="1">Basic</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <BasicFilter
                      setFilters={setFilters}
                      isReset={isReset}
                      isTutorName
                    />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Subjects</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <SubjectsFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Tutor's Location
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <TutorLocationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>
                {/* <AccordionItem>
                <AccordionHeader targetId="7">Schedule</AccordionHeader>
                <AccordionBody accordionId="7">
                  <ScheduleFilter setFilters={setFilters} isReset={isReset} />
                </AccordionBody>
              </AccordionItem> */}

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Tutor Ratings</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <RatingFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              <Button
                color="primary"
                className="mb-2 "
                onClick={(e) => updateFilter(0)}
              >
                Apply Filter
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {isListView && allFilteredTutors?.length !== 0 && (
              <div className="mt-5 mb-5 pagination" style={{ float: "left" }}>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  //disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
