import { toast } from "react-toastify";

export function toastMessage(message, type) {
  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
}
export function toastBottomRight(message, type) {
  const customId = "custom-id-yes";

  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      toastId: customId
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.warn(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  }
}



export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
export function isDifferenceGivenDays(endDateStr, day) {
  var today = new Date(); // create a new Date object for today's date
  var endDate = new Date(endDateStr); // create a new Date object for the end date
  var diffTime = Math.abs(today - endDate); // get the difference in milliseconds
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // convert the difference to days and round up to the nearest whole number
  return diffDays >= day;
}
export function addCustomDays(givenDate, days) {
  const date = new Date(givenDate);
  date.setDate(date.getDate() + days);
  return date.toISOString();
}


