import React, { Fragment, useEffect, useState } from "react";
import { LogIn, Mail, Settings, User, Info } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input, Label, Media } from "reactstrap";
import { H6, Image, LI, UL } from "../../AbstractElements";
import man from "../../assets/images/avtar/man.png";
// import man from "../../assets/images/avtar/man.png";
import { Inbox, LogOut } from "../../Constant";
import { FAQSvg } from "../../Data/svgIcons";
import { memberMenus } from "../../redux/features/menus/menuSlice";
import Language from "./Langauge";
import MaxMiniSize from "./MaxMiniSize";
import Notification from "./Notification";

import { useContext } from "react";
import { clearAllLoginStore } from "../../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import { axiosRequest } from "../../redux/utils/axios-utils";
import "../../style/custom-css/PersonalInfo.css";
import BecomeTutorContext from "../../_helper/BecomeTutor";
import FilterContext from "../../_helper/Filter";
import MemberInformationContext from "../../_helper/MemberInformation";
import PersonalAddressContext from "../../_helper/PersonalAddress";
import useNetworkStatus from "../../_helper/NetworkStatus/useNetworkStatus";
// import BecomeTutorContext from "../../../_helper/BecomeTutor";

const HeaderContain = () => {
  const [profile, setProfile] = useState(man);
  const [name, setName] = useState("");
  const [memberTypeText, setMemberType] = useState("Tutor");
  const [isTutor, setIsTutor] = useState(false);
  const [isAccountHide, setIsAccountHide] = useState("");
  const networkStatus = useNetworkStatus();
  const [isVisible, setIsVisible] = useState("d-blcok");
  const [proPhoto, setProPhoto] = useState();
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  // const {
  //   step,
  //   handleSkipOrNext,
  //   profilePhoto,
  //   setProfilePhoto,
  //   handleEmailVerified,
  //   isEmailVerified,
  // } = useContext(BecomeTutorContext);

  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
  let picture2 = localStorage.getItem("picture2");

  const { themeReminder, setMoonlight, setThemeReminder } =
    useContext(FilterContext);

  const { clearBecomeTutorContext, profilePhoto } =
    useContext(BecomeTutorContext);
  const {
    setAddress1,
    setAddress2,
    setArea,
    setCity,
    setState,
    setPostalCode,
    setCountry,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);

  const {
    setAbout,
    setmedia,
    setdateOfBirth,
    setFullName,
    setGender,
    setEmail,
    setPhone,
    setLanguages,
    setMemberType: setMemberType2,
    setAddress,
    setEducations,
    setWorkExperiences,
    setSchedules,
    setInterestAndPricing,
    setInterestToLearn,
    setLanguageSelectedOptions,
    profileUrl,
    setProfileUrl,
    setDocumentID,
  } = useContext(MemberInformationContext);

  const clearProvider = () => {
    setAddress1("");
    setAddress2("");
    setArea("");
    setCity("");
    setState("");
    setPostalCode("");
    setCountry("");
    setLatitude("");
    setLongitude("");
    clearMemberInfoContext();
  };

  const clearMemberInfoContext = () => {
    setAbout({});
    setmedia({});
    setdateOfBirth(new Date("01/01/2000"));
    setFullName("");
    setGender("");
    setEmail("");
    setPhone("");
    setLanguages([]);
    setMemberType2("");
    setAddress({});
    setEducations([]);
    setWorkExperiences([]);
    setSchedules([]);
    setInterestAndPricing([]);
    setInterestToLearn([]);
    setLanguageSelectedOptions([]);
    setProfileUrl("");
    setDocumentID("");
  };

  const navigate = useNavigate();
  const Logout = () => {
    clearAllLoginStore(dispatch);
    clearProvider();
    const themeReminder = localStorage.getItem("theme_reminder");
    localStorage.clear();
    localStorage.setItem("theme_reminder", themeReminder);
    clearBecomeTutorContext();
    // navigate(`${process.env.PUBLIC_URL}/login-account`);
    window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/?logout=1`;
  };

  const authenticated = auth0_profile?.authenticated;
  const dispatch = useDispatch();
  var swMember = localStorage.getItem("swMember");

  if (swMember === "undefined") Logout();

  useEffect(() => {
    setMemberType(swMember);
    if (swMember === "turor") setIsTutor(false);
    else if (swMember === "student") setIsTutor(true);
  }, [swMember]);

  useEffect(() => {
    setProfile(picture2);

    setName(auth0_profile?.name);
  }, [picture2, auth0_profile]);

  const switchMemberToggle = (isTutor) => {
    setIsTutor(!isTutor);
    if (isTutor) {
      setMemberType("Tutor");
      // dispatch(userLoggedIn({ accountType: "tutor"}));
      localStorage.setItem("swMember", "tutor");
      dispatch(memberMenus({ memberType: "tutor" }));
    } else {
      localStorage.setItem("swMember", "student");
      // dispatch(userLoggedIn({ accountType: "student"}));
      setMemberType("Student");
      dispatch(memberMenus({ memberType: "student" }));
    }
  };
  useEffect(() => {
    const getMemberInfo = async () => {
      var res = await axiosRequest({
        url: "/newMember/membersInfo",

        method: "get",
      });

      if (res) {
        setIsAccountHide(res?.data?.isAccountHide);
      }
    };

    getMemberInfo();
  }, [isAccountHide]);

  //--- setting theme to previous preference
  useEffect(() => {
    const theme = localStorage.getItem("theme_reminder");
    if (themeReminder) {
      setMoonlight(true);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    } else if (theme === "dark-only") {
      setMoonlight(true);
      setThemeReminder(true);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  }, []);

  // const handleChange = () => {
  //   if (isVisible === "d-block") {
  //     setIsVisible("d-none");
  //   } else {
  //     setIsVisible("d-block");
  //   }
  // };

  // const handleMouseOver = () => {
  //   setIsVisible("d-block");
  // };

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL
          attrUL={{ className: `simple-list flex-row nav-menus` }}
          className="d-flex justify-content-left align-items-center"
        >
          <LI attrLI={{ className: "onhover-dropdown d-none" }}>
            <H6 attrH6={{ className: "txt-dark mb-0 mt-1" }}>EN</H6>
            <Language />
          </LI>

          {/* It's not important in the current phase. It will be completed in the
          next phase. */}

          {/* {isAccountHide && (
            <span className="hideAccount">
              <Link to={`${process.env.PUBLIC_URL}/settings`}>
                <li>
                  <i class="fa fa-eye-slash"></i>
                </li>
              </Link>
            </span>
          )}

          {!isAccountHide && (
            <span className="visibleAccount">
              <Link to={`${process.env.PUBLIC_URL}/settings`}>
                <li>
                  <i class="fa fa-eye"></i>
                </li>
              </Link>
            </span>
          )} */}
          {(accountType === "tutor" || accountType === "both") && (
            <li className="list-group-item">
              <H6 attrH6={{ className: "txt-dark mb-0 mt-1" }}>
                <span>TUTOR</span>
              </H6>
            </li>
          )}

          {accountType === "both" && (
            <li className="mt-1">
              <Media body className={"text-end switch-outline media-body"}>
                <Label className="switch p-0 m-0">
                  <Input
                    type="checkbox"
                    checked={isTutor}
                    onClick={(e) => switchMemberToggle(isTutor)}
                    onChange={(e) => switchMemberToggle(isTutor)}
                  />
                  <span
                    className="switch-state bg-primary"
                    style={{ background: "#6b6b91" }}
                  ></span>
                </Label>
              </Media>
            </li>
          )}

          {(accountType === "student" || accountType === "both") && (
            <li className="list-group-item">
              <H6 attrH6={{ className: "txt-dark mb-0 mt-2" }}>
                <span>STUDENT</span>
              </H6>
            </li>
          )}

          {/* <MoonLight /> */}
          <Notification />
          <MaxMiniSize />
          {/* <span onMouseOver={() => handleMouseOver()}> */}
          <LI
            attrLI={{
              className: "profile-nav onhover-dropdown pe-0 pt-0 me-0",
            }}
          >
            <Media className="profile-media">
              <Image
                attrImage={{
                  className: "rounded-circle",
                  src: `${profileUrl ? profileUrl : profile}`,
                  alt: "",
                }}
              />
              <Media body>
                <span>{fName + " " + lName}</span>
              </Media>
            </Media>
            <UL
              attrUL={{
                className: `simple-list profile-dropdown onhover-show-div p-0`,
              }}
            >
              {/* <span className={isVisible}> */}
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/profile/me`}>
                  <div className="p-10">
                    <i>
                      <User />
                    </i>
                    <span>{"My Profile"} </span>
                  </div>
                </Link>
              </LI>
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/settings#javascript`}>
                  <div className="p-10">
                    <i>
                      <Settings />
                    </i>
                    <span>{"Account Settings"}</span>
                  </div>
                </Link>
              </LI>
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/get-help`}>
                  <div className="p-10">
                    <i>
                      <Info />
                    </i>
                    <span>{"Help"}</span>
                  </div>
                </Link>
              </LI>
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/inbox`}>
                  <div className="p-10">
                    <i>
                      <Mail />
                    </i>
                    <span>{Inbox}</span>
                  </div>
                </Link>
              </LI>
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/FAQ`}>
                  <div className="p-10">
                    <FAQSvg />
                    <span>{"FAQs"}</span>
                  </div>
                </Link>
              </LI>
              <LI attrLI={{ onClick: Logout, className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                  <div className="p-10">
                    <LogIn />
                    <span>{LogOut}</span>
                  </div>
                </Link>
              </LI>
              {/* </span> */}
            </UL>
          </LI>
          {/* </span> */}
        </UL>
      </div>
      {networkStatus && (
        <div className="bg-danger text-center w-100">
          You are currently offline. Please check your Internet connection!
        </div>
      )}
    </Fragment>
  );
};
export default HeaderContain;
