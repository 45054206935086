import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tutorList: undefined
};

const filterSlice = createSlice({
    name: "tuition",
    initialState,
    reducers: {
        tutorTuitions: (state, action) => {
            state.tutorList = action.payload.tutorTuitionList;
        },
    },
});

export const {tutorTuitions} = filterSlice.actions;
export default filterSlice.reducer;
