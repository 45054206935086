import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { toastNotifiy as toastNotify } from "../../Services/AuthVerify";
import { AddNotification } from "../../Services/Notification.Service";
import ClassSchedulerContext from "../../_helper/ClassScheduler";
import notFound from "../../assets/images/my-tuition/not_found-3.jpg";
import {
  useEditConversationMutation,
  useGetConversationsQuery,
} from "../../redux/features/chat/conversations/conversationsApi";
import { axiosRequest } from "../../redux/utils/axios-utils";
import {
  extendMessage,
  sendNotificationWithMessage,
} from "../Schedule/Calendar/Extend/extendTuitionFns";
import {
  getAllSchedulesByRRule,
  getWeeklySchedules,
} from "../Schedule/Calendar/Reschedule/fnRescheduling";
import ExtendTuitionModal from "./ExtendTuitionModal";
import Pagination from "./Pagination";
import { SingleMyTuition } from "./SingleMyTuition";
import { updateTutorNewSchedule } from "../Common/scheduleUpdate/UpdateTutorNewSchedule";

const MyTuitionsContains = () => {
  const {
    getTuitionList,
    tuitionList,
    myTuitionList,
    filterMyTuitionList,
    getAllScheduleEvents,
  } = useContext(ClassSchedulerContext);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [singleTuition, setSingleTuition] = useState({});
  const [title, setTitle] = useState("");

  const [showTuitionsList, setShowTuitionsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const { user } = useSelector((state) => state.auth) || {};
  // const { selectedUser } = useSelector((state) => state.conversations) || {};
  let { data: conversations, isError, error } = useGetConversationsQuery(user);
  const { memberType } = useSelector((state) => state.menu) || {};
  const [activeTab, setActiveTab] = useState("1");
  const [showPagination, setShowPagination] = useState(false);
  const [tabList, setTabList] = useState([]);

  const [
    editConversation,
    { isSuccess: isEditConversationSuccess, error: err2 },
  ] = useEditConversationMutation();

  let tabList2 = [
    { id: "1", route: "all", name: "All", icon: "fa fa-tasks", total: 0 },
    {
      id: "2",
      route: "pending",
      name: "Pending",
      icon: "icofont icofont-spinner",
      total: 0,
    },
    {
      id: "3",
      route: "confirmed",
      name: "Confirmed",
      icon: "icofont icofont-tick-boxed",
      total: 0,
    },
    {
      id: "4",
      route: "Close Requests",
      name: "Close Requests",
      icon: "icofont icofont-tick-boxed",
      total: 0,
    },
    {
      id: "5",
      route: "Closed",
      name: "Closed",
      icon: "icofont icofont-ui-block",
      total: 0,
    },
    {
      id: "6",
      route: "extend",
      name: "Extend Requests",
      icon: "icofont icofont-meeting-add",
      total: 0,
    },
  ];

  const tuitionsPerPage = 9;
  const pagesVisited = pageNumber * tuitionsPerPage;
  // changes pagination page number
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // changing myTuitionList, pageNumber, activeTab changes displayed tuitions
  const handlePagination = () => {
    let filteredList = handleTabChange();
    const showTuitionList = filteredList?.slice(
      pagesVisited,
      pagesVisited + tuitionsPerPage
    );
    setShowTuitionsList(showTuitionList);
    const page = Math.ceil(filteredList?.length / tuitionsPerPage);
    setPageCount(page);
  };

  useEffect(() => {
    getTuitionList();
  }, []);

  const modifyTabList = (_myTuitionList) => {
    if (_myTuitionList.length > 0) {
      for (let i = 0; i < tabList.length; i++) {
        if (tabList2[i].id === "1") {
          tabList2[i].total = _myTuitionList.filter(
            (tuition) => tuition.status !== "FREE"
          )?.length;
        } else if (tabList[i].id === "2") {
          tabList2[i].total = _myTuitionList.filter(
            (tuition) =>
              tuition.status === "OPEN" || tuition.status === "DECLINED"
          )?.length;
        } else if (tabList[i].id === "3") {
          tabList2[i].total = _myTuitionList.filter(
            (tuition) => tuition.status === "CONFIRMED"
          )?.length;
        } else if (tabList[i].id === "4") {
          tabList2[i].total = _myTuitionList.filter(
            (tuition) => tuition.status === "CLOSE_REQUEST"
          )?.length;
        } else if (tabList[i].id === "5") {
          tabList2[i].total = _myTuitionList.filter(
            (tuition) =>
              tuition.status === "CANCELED" || tuition.status === "CLOSED"
          )?.length;
        } else if (tabList[i].id === "6") {
          tabList2[i].total = _myTuitionList.filter(
            (t) => t.status === "CONFIRMED" && t.isExtendRequest
          )?.length;
        }
      }
    } else {
      setTabList(tabList);
    }
    setTabList(tabList2);
  };

  useEffect(() => {
    changePage({ selected: 0 });
    filterMyTuitionList(memberType, user, tuitionList);
  }, [memberType, activeTab]);

  useEffect(() => {
    modifyTabList(myTuitionList);
    if (myTuitionList.length > 0) {
      handlePagination();
    } else {
      setShowTuitionsList([]);
    }
  }, [myTuitionList, pageNumber, activeTab]);

  const handleExtendButton = (tuitionId) => {
    var _selectedTuition = myTuitionList?.find((x) => x._id === tuitionId);
    setSingleTuition(_selectedTuition);
    setTitle("Extend Tuition");
    setModal(true);

    var classStart = _selectedTuition?.timeTableHistory[0].classStart;
    var topActiveTimeTableHistory = _selectedTuition?.timeTableHistory?.filter(
      (x) => x.isActive
    );
    var classEnd =
      topActiveTimeTableHistory[topActiveTimeTableHistory.length - 1].classEnd;

    var today = moment(new Date());
    var endDate = moment(new Date(classEnd));
    const diffTime = endDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setSingleTuition({
      ..._selectedTuition,
      memberType: memberType,
      userId: user,
      tuitionStartEnd: { classStart, classEnd },
      diffDays: diffDays,
    });
  };

  const getDetails = (tuition, memberType) => {
    const {
      _id: tuitionId,
      student: { _id: studentId },
      tutor: { _id: tutorId },
      interestedSubjects,
      timeTableHistory,
    } = tuition;
    const subjectName = interestedSubjects?.subjects
      .map((subject) => subject.name)
      .join(" - ");
    const selectedConversation =
      memberType === "tutor"
        ? conversations?.find((c) => c.receiverId === studentId)
        : conversations?.find((c) => c.receiverId === tutorId);
    const receiverId = memberType === "tutor" ? studentId : tutorId;
    const tuitionSchedule =
      timeTableHistory[timeTableHistory.length - 1]?.timeTable;
    const details = {
      tuitionId: tuitionId,
      conversationId: selectedConversation?.conversationId,
      subjectName: subjectName,
      receiverId: receiverId,
      tutorId: tutorId,
      tuitionSchedule: tuitionSchedule,
    };
    return details;
  };

  const handleCancel = (tuition, memberType) => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Are you sure to cancel the tuition?",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleTuition(tuition, memberType, "CANCELED");
        SweetAlert.fire("Your tuition has been canceled!", "", "");
      } else {
      }
    });
  };

  const handleCancelCloseTuition = (tuition, memberType) => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Are you sure to cancel the close tuition request?",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleTuition(tuition, memberType, "CONFIRMED", null, "cancelClosed");
        SweetAlert.fire(
          "Your close tuition request has been canceled!",
          "",
          "success"
        );
      } else {
      }
    });
  };

  const handleTuition = async (
    tuition,
    memberType,
    status,
    closers,
    cancelClosed
  ) => {
    const {
      tuitionId,
      conversationId,
      subjectName,
      receiverId,
      tutorId,
      tuitionSchedule,
    } = getDetails(tuition, memberType);

    let res = await axiosRequest({
      url: "/tuition/update-tuition-status",
      method: "put",
      data: {
        tuitionId: tuitionId,
        status: closers ? "CLOSE_REQUEST" : status,
        user: user,
        isCloseByStudent: closers?.isCloseByStudent,
        isCloseByTutor: closers?.isCloseByTutor,
      },
    });

    if (res.status === 200 || res.status === 201) {
      const status = res.data.status;

      getTuitionList();
      removeTuitionFromSchedule(
        tuitionId,
        tutorId,
        tuitionSchedule,
        status,
        cancelClosed
      );
      editConversation({
        sender: user,
        id: conversationId,
        data: {
          message: `[${subjectName}] This tuition has been ${status.toLowerCase()}. Thank you.`,
        },
      });
      const notifyObj = {
        receiver: receiverId,
        title: `Your tuition for [${subjectName}] has been ${status.toLowerCase()}`,
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/inbox",
        isPublic: false,
      };
      AddNotification(notifyObj);
    } else if (res.status === 201) {
      getTuitionList();
      editConversation({
        sender: user,
        id: conversationId,
        data: {
          message: `[${subjectName}] I have closed this tuition. Please check and update your class completion status properly. Then kindly close this tuition.Thank You.`,
        },
      });
      const notifyObj = {
        receiver: receiverId,
        title: `Your tuition for [${subjectName}] has been ${status.toLowerCase()}`,
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/inbox",
        isPublic: false,
      };
      AddNotification(notifyObj);
    }
  };

  const removeTuitionFromSchedule = async (
    tuitionId,
    tutorId,
    tuitionSchedule,
    status,
    cancelClosed
  ) => {
    let res = await axiosRequest({
      url: "/newMember/getMemberSchedule/" + tutorId,
      method: "get",
    });
    if (res?.data) {
      const { tutorSchedules } = res?.data;
      let tutorOldSchedule = [...tutorSchedules];
      tutorOldSchedule?.map((tutor) => {
        tuitionSchedule?.map((tuition) => {
          if (tutor.dayName === tuition.dayName) {
            tutor.timeTable.map((time) => {
              if (
                time.tuitionId === tuitionId &&
                !cancelClosed &&
                status === "CLOSED"
              ) {
                time.isBooked = false;
                time.tuitionId = "";
              } else if (
                time.tuitionId === tuitionId &&
                cancelClosed === "cancelClosed"
              ) {
                time.isBooked = true;
                time.tuitionId = tuitionId;
              } else if (
                time.tuitionId === tuitionId &&
                status !== "CLOSE_REQUEST"
              ) {
                time.isBooked = false;
                time.tuitionId = "";
              }
            });
          }
        });
      });
      updateTutorNewSchedule(tutorId, tutorOldSchedule);
    }
  };

  const handleClose = (tuition, memberType) => {
    if (tuition?.scheduledClasses?.length > 0) {
      const paidClass = tuition?.scheduledClasses?.filter(
        (item) =>
          // item.isPaid &&
          // new Date(item.date) < new Date() &&
          item.classStatus !== "cancelled" && item.classStatus !== "complete"
      );

      if (paidClass?.length > 0) {
        var closers = createDataObject(memberType, tuition);

        function createDataObject(memberType, item) {
          const isStudent = memberType === "student";
          const isTutor = memberType === "tutor";

          const isCloseByStudent =
            (isStudent && item?.tuitionCloseStatus === undefined && true) ||
            (typeof item?.tuitionCloseStatus === "object" &&
              isStudent &&
              true) ||
            item?.tuitionCloseStatus?.isCloseByStudent;

          const isCloseByTutor =
            (isTutor && item?.tuitionCloseStatus === undefined && true) ||
            (typeof item?.tuitionCloseStatus === "object" && isTutor && true) ||
            item?.tuitionCloseStatus?.isCloseByTutor;

          var data = {
            isCloseByStudent,
            isCloseByTutor,
          };

          return data;
        }
      }
    }
    else {
      var closers = createDataObject(memberType, tuition);

      function createDataObject(memberType, item) {
        const isStudent = memberType === "student";
        const isTutor = memberType === "tutor";

        const isCloseByStudent =
          (isStudent && item?.tuitionCloseStatus === undefined && true) ||
          (typeof item?.tuitionCloseStatus === "object" &&
            isStudent &&
            true) ||
          item?.tuitionCloseStatus?.isCloseByStudent || undefined;

        const isCloseByTutor =
          (isTutor && item?.tuitionCloseStatus === undefined && true) ||
          (typeof item?.tuitionCloseStatus === "object" && isTutor && true) ||
          item?.tuitionCloseStatus?.isCloseByTutor || undefined;

        var data = {
          isCloseByStudent,
          isCloseByTutor,
        };

        return data;
      }
    }

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Are you sure to close the tuition?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleTuition(tuition, memberType, "CLOSED", closers);
        SweetAlert.fire(
          ` Your tuition has been closed successfully`
        );
      } else {
      }
    });
  };

  const handleTutorExtend = (tuition, confirmation) => {
    var extendDate = moment(
      new Date(
        tuition?.timeTableHistory.filter((x) => !x.isActive)[
          tuition?.timeTableHistory.filter((x) => !x.isActive).length - 1
        ].classEnd
      )
    ).format("LL");

    var htmlContent = `Your current tuition agreement extended to <strong> ${extendDate} </strong>`;

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      html: confirmation ? htmlContent : "",
      title: `Are you sure to ${confirmation ? "extend the tuition" : "cancel the extend request"
        } ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleTuitionExtension(tuition, confirmation);
        SweetAlert.fire(
          `Extension request is ${confirmation ? "approved" : "canceled"}!`,
          "",
          "success"
        );
      } else {
      }
    });
  };

  // API call to change data in db
  const handleTuitionExtension = async (tuition, confirmation) => {
    // tuitionId, scheduledClasses, isConfirmed, timeTableHistoryId

    const selectedConversation = conversations?.find(
      (c) => c.receiverId === tuition?.student._id
    );

    var timeTableHistory = tuition.timeTableHistory?.filter((x) => !x.isActive);
    if (timeTableHistory?.length > 0)
      timeTableHistory = timeTableHistory[timeTableHistory.length - 1];

    let obj = {
      startDate: timeTableHistory?.classStart,
      endDate: timeTableHistory?.classEnd,
      repeat: timeTableHistory?.repeat,
      timeTable: timeTableHistory?.timeTable,
    };
    let allSchedules = getAllSchedulesByRRule(obj);

    let extEventList = [];

    allSchedules?.map((date) => {
      var weekDays = getWeeklySchedules(
        new Date(date),
        timeTableHistory?.timeTable
      );
      if (weekDays) {
        extEventList.push({
          date: new Date(weekDays.start),
          startTime: new Date(weekDays.start),
          endTime: new Date(weekDays.end),
          isPaid: false,
        });
      }
    });
    var data = {
      tuitionId: tuition._id,
      scheduledClasses: extEventList,
      isConfirmed: confirmation,
      timeTableHistoryId: timeTableHistory?._id,
    };

    //return;
    let res = await axiosRequest({
      url: "/tuition/confirm-extend-timetable",
      method: "put",
      data: data,
    });
    if (res && res.status === 200) {
      if (res.data.success) {
        getTuitionList();
        const extendedMessage = extendMessage(
          new Date(timeTableHistory?.classEnd)
        );
        var message = `Tuition extension request has been confirmed until ${extendedMessage}. Please confirm extended tuition request.`;
        var notify_title = "Tuition extension request has been confirmed";
        var sender = tuition.tutor._id;
        var receiver = tuition.student._id;
        sendNotificationWithMessage(
          notify_title,
          sender,
          receiver,
          selectedConversation,
          editConversation,
          message
        );
        toastNotify("Tuition extension request has been confirmed", "success");
      } else {
        console.warn(res);
      }
    }
  };

  // filters tuitions according to current tab
  const handleTabChange = () => {
    let myTuition = [...myTuitionList];
    let filteredTuition = [];
    if (activeTab === "1") {
      filteredTuition = myTuition.filter(
        (tuition) => tuition.status !== "FREE"
      );
    } else if (activeTab === "2") {
      filteredTuition = myTuition.filter(
        (tuition) => tuition.status === "OPEN" || tuition.status === "DECLINED"
      );
    } else if (activeTab === "3") {
      filteredTuition = myTuition.filter(
        (tuition) => tuition.status === "CONFIRMED"
      );
    } else if (activeTab === "4") {
      filteredTuition = myTuition.filter(
        (tuition) => tuition.status === "CLOSE_REQUEST"
      );
    } else if (activeTab === "5") {
      filteredTuition = myTuition.filter(
        (tuition) =>
          tuition.status === "CANCELED" || tuition.status === "CLOSED"
      );
    } else if (activeTab === "6") {
      let extendedTuitions = myTuition.filter(
        (tuition) => tuition.status === "CONFIRMED" && tuition.isExtendRequest
      );
      filteredTuition = [...extendedTuitions];
    }

    if (filteredTuition.length >= 9) {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
    return filteredTuition;
  };

  return (
    <Fragment>
      {modal && (
        <ExtendTuitionModal
          title={"Modal"}
          isOpen={modal}
          toggle={toggle}
          data={singleTuition}
          memberType={memberType}
        />
      )}
      <Row className="project-list col-12 py-1 px-4">
        <Card className="py-3">
          <Nav tabs className="border-tab">
            {tabList.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  className={activeTab === tab.id ? "active" : ""}
                  onClick={() => setActiveTab(tab.id)}
                  style={{ cursor: "pointer" }}
                >
                  <i className={tab.icon}></i>
                  {tab.name} ({tab.total})
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Card>
      </Row>
      <Col sm="12">
        <TabContent activeTab={activeTab} id="top-tabContent">
          {tabList.map((tab) => (
            <TabPane key={tab.id} tabId={tab.id}>
              <Row className="py-1 px-4 col-12">
                {/* when tuitions exist for current tab */}
                {showTuitionsList.length > 0 &&
                  showTuitionsList?.map((tuition) => (
                    <SingleMyTuition
                      key={tuition?._id}
                      tuition={tuition}
                      schedules={
                        tuition?.timeTableHistory[
                          tuition?.timeTableHistory.length - 1
                        ]?.timeTable
                      }
                      handleExtendButton={handleExtendButton}
                      handleCancel={handleCancel}
                      handleCancelCloseTuition={handleCancelCloseTuition}
                      handleClose={handleClose}
                      handleTutorExtend={handleTutorExtend}
                    ></SingleMyTuition>
                  ))}
                {/* when tuitions don't exist for current tab */}
                {showTuitionsList.length === 0 && (
                  <div className="d-flex justify-content-center align-items-center text-center f-s-48">
                    <Col xs="12" sm="10" lg="8">
                      <h1
                        className="m-0"
                        style={{
                          fontFamily: "Fredoka One, cursive",
                          fontSize: "42px",
                          fontWeight: "bolder",
                          letterSpacing: "1px",
                          color: "#ff686b",
                        }}
                      >
                        No Tuition Found
                      </h1>
                      <img
                        src={notFound}
                        alt="not found"
                        className="img-fluid"
                      />
                    </Col>
                  </div>
                )}
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </Col>
      <Row className="py-1 px-4 col-12">
        {/* pagination will be displayed if tuition  number is greater than 9 */}
        {showPagination && (
          <Pagination
            pageCount={pageCount}
            changePage={changePage}
            pageNumber={pageNumber}
          ></Pagination>
        )}
      </Row>
    </Fragment>
  );
};

export default MyTuitionsContains;
