import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Bell, X } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Media } from "reactstrap";
import io from "socket.io-client";
import { H3, LI, UL } from "../../../AbstractElements";
import { ServerURL } from "../../../api";
import { NotificationUrl, ProfilePictureRootURL } from "../../../api/index";
import man from "../../../assets/images/avtar/man.png";
import { HeaderNotificationSvg } from "../../../Data/svgIcons";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { toastBottomRight } from "../../../redux/utils/tuteairCommon";
import { authHeader } from "../../../Services/AuthVerify";
import ChatIcon from "../ChatIcon";
import "./Notif.css";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const { user: userId } = useSelector((state) => state.auth) || {};
  const navigate = useNavigate();

  const fetchAllNotifications = async () => {
    const res = await axios.get(NotificationUrl, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (!res.data?.message?.includes("null")) {
      setNotifications(res.data);
    } else {
      console.log(res.data);
    }
  };

  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [connectionErrorDisplayed, setConnectionErrorDisplayed] =
    useState(false);

  useEffect(() => {
    fetchAllNotifications();

    const newSocket = io(ServerURL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 1,
      transports: ["websocket"],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const unSeenNotif = notifications.filter((item) => item.isSeen != "true");
    setNotifCount(unSeenNotif.length);
  }, [notifications]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      setIsConnected(true);
      realtimeNotification();

      setConnectionErrorDisplayed(false); // reset the connection error state
    });

    socket.on("connect_error", (error) => {
      if (!connectionErrorDisplayed) {
        // toastNotifiy("WebSocket connection error", "warn");
        console.warn("WebSocket connection error");
        setConnectionErrorDisplayed(true);
      }

      // console.error("WebSocket connection error:", error);
    });

    // socket.on("reconnect_attempt", (attempt) => {
    //   toastNotifiy(`WebSocket reconnect attempt ${attempt}`, "warn");
    //   // console.log(`WebSocket reconnect attempt ${attempt}`);
    // });

    // socket.on("reconnect_error", (error) => {
    //   toastNotifiy("WebSocket reconnection error", "warn");
    //   // console.error("WebSocket reconnection error:", error);
    // });

    // socket.on("reconnect_failed", () => {
    //   toastNotifiy("WebSocket reconnection failed", "warn");
    //   // console.error("WebSocket reconnection failed");
    // });

    if (isConnected) {
      realtimeNotification();
    }
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.off("reconnect_attempt");
      socket.off("reconnect_error");
      socket.off("reconnect_failed");
      socket.disconnect();
    };
  }, [socket]);

  const realtimeNotification = async () => {
    socket.on("notification", (data) => {
      if (data.data?.receiver === userId) {
        var prevNotifications = [...notifications];
        prevNotifications?.push({
          context: data?.data.context,
          createdAt: data?.data.createdAt,
          description: data?.data.description,
          id: data?.data._id,
          isSeen: data?.data.isSeen,
          sender: data?.data.sender,
          senderName: data?.data.senderName,
          senderPhoto: data?.data.senderPhoto,
          senderNewProfilePhoto:
            data?.data?.senderNewProfilePhoto?.imageInfo?.secure_url,
          title: data?.data.title,
          isFullTitle: data?.data?.isFullTitle,
        });
        // prevNotifications?.push(data?.data);
        prevNotifications.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setNotifications(prevNotifications);

        toastBottomRight(
          typeof data?.data?.isFullTitle && data?.data?.isFullTitle
            ? data?.data?.title
            : data?.data?.title + " from " + data?.data?.senderName,
          "success"
        );
      }
    });
  };

  const isSeenNotification = async (e, id, url) => {
    e.preventDefault();
    const res = await axiosRequest({
      url: "/notification/updateOne/" + id,
      method: "get",
    });
    if (res?.data?.message) {
      fetchAllNotifications();
    }
    navigate(`${process.env.PUBLIC_URL}${url}`);
  };

  const deleteNotification = async (e, id) => {
    e.preventDefault();
    const res = await axios.delete(NotificationUrl + "/delete/" + id, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res.data == "Deleted") {
      setNotifications(notifications?.filter((item) => item.id !== id));
    }
  };

  const markAllAsRead = async () => {
    const res = await axios.get(NotificationUrl + "/update", {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res.data) {
      fetchAllNotifications();
    }
  };

  return (
    <Fragment>
      <ChatIcon />
      <LI attrLI={{ className: "onhover-dropdown " }}>
        <div className="notification-box">
          <HeaderNotificationSvg />
          <span className="badge rounded-pill badge-warning">{notifCount}</span>
        </div>
        <div className={`onhover-show-div notification-dropdown`}>
          <div className="dropdown-title">
            <H3>Notification</H3>
            <a className="f-right" href="#javascript">
              <Bell />
            </a>
          </div>
          <UL attrUL={{ className: "simple-list custom-scrollbar" }}>
            {notifications?.map((item, k) => (
              <li
                key={k}
                className={`list-group-item ${
                  item.isSeen == "true" ? "" : "bgUnseen"
                }`}
              >
                <div
                  className={`header_notification ${
                    item.isSeen == "true" ? "" : "unseen-chat"
                  }`}
                  style={{ display: "flex" }}
                >
                  <div
                    onClick={(e) =>
                      isSeenNotification(e, item.id, item.context)
                    }
                    // to={item.context}
                    className="f-14 m-0"
                  >
                    <Media>
                      <div className={`notification-img bg-light-default`}>
                        <img
                          src={
                            // item.senderPhoto === null ||
                            // item.senderPhoto === "undefined" ||
                            // item.senderPhoto === "" ||
                            // item.senderPhoto === " "
                            //   ? man
                            //   :

                            item?.senderNewProfilePhoto?.imageInfo?.secure_url
                              ? item?.senderNewProfilePhoto?.imageInfo
                                  ?.secure_url
                              : item.senderPhoto
                              ? `${ProfilePictureRootURL + item.senderPhoto}`
                              : man
                          }
                          className=" img-fluid rounded-circle"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      </div>
                      <Media body>
                        <h6>
                          {typeof item?.isFullTitle && item?.isFullTitle
                            ? item.title
                            : item.title + " from " + item.senderName}
                        </h6>
                        {/* <P>{item.description}</P> */}
                        <span>{moment(item.createdAt).fromNow()} </span>
                      </Media>
                    </Media>
                  </div>
                  <div
                    className="notification-right"
                    onClick={(e) => deleteNotification(e, item.id)}
                  >
                    <X style={{ height: "17px" }} />
                  </div>
                </div>
              </li>
            ))}
            <LI attrLI={{ className: "p-0" }}>
              <a className="btn btn-primary" onClick={() => markAllAsRead()}>
                Mark all as read
              </a>
            </LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  );
};
export default Notification;
