import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardSubtitle, CardText, CardTitle, Spinner } from "reactstrap";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { useGetAllReviewRatingQuery } from "../../../redux/features/calendar/calendarApi";
import { isDifferenceGivenDays } from "../../../redux/utils/tuteairCommon";
import ClassRating from "../../Schedule/ClassReview/ClassRating";

export default function Feedback(props) {
  const { user: userId } = useSelector((state) => state?.auth);
  const params = useParams();
  const id = params.id === "me" ? userId : params.id;

  let { data, isLoading, isError } = useGetAllReviewRatingQuery(id);

  const [showMoreMap, setShowMoreMap] = useState({});
  const toggleShowMore = (tuitionId) => {
    setShowMoreMap({
      ...showMoreMap,
      [tuitionId]: !showMoreMap[tuitionId],
    });
  };

  const filterData = data?.filter((item) => {
    if (item?.reviews[0]?.studentId !== null) {
      return params.id === "me"
        ? item?.reviews[0]?.studentId._id !== userId
        : item?.reviews[0]?.studentId._id !== params.id;
    }
    return false;
  });

  data = filterData?.filter((item) => {
    var reviews = item?.reviews || [];
    var hasFeedback = reviews?.some(
      (x) => x.feedback?.fromStudent && x.feedback?.fromTutor
    );
    var isClassEndOver14Days = reviews?.some((x) =>
      isDifferenceGivenDays(x.classInfo?.classEnd, 14)
    );
    return hasFeedback || isClassEndOver14Days;
  });

  const showMore = (tuitionId) => showMoreMap[tuitionId] || false;
  return (
    <div>
      <Card body>
        <div>
          <CardTitle tag="h4">
            Student Response for {props.fullname} (
            {
              data?.filter(
                (item) =>
                  item?.reviews.filter((x) => x.feedback?.fromStudent).length >
                  0
              )?.length
            }
            )
          </CardTitle>
          <CardSubtitle className="py-1">
            We have total{" "}
            {
              data?.filter(
                (item) =>
                  item?.reviews.filter((x) => x.feedback?.fromStudent).length >
                  0
              )?.length
            }{" "}
            feedback from students for {props.fullname}
          </CardSubtitle>

          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <>
              <>
                {data?.map((item, i) => (
                  <div className="" key={i}>
                    <div>
                      {item.reviews
                        ?.filter((x) => x.feedback && x.feedback.fromStudent)
                        ?.map((rv, k) => (
                          <>
                            <div
                              className={`row border-top py-2 ${
                                k === 0 || showMore(item.tuition._id)
                                  ? ""
                                  : "d-none"
                              }`}
                              key={k}
                            >
                              <div className="col-md-1">
                                {k === 0 && (
                                  <img
                                    src={
                                      rv?.studentId?.newProfilePicture
                                        ?.imageInfo?.secure_url
                                        ? rv?.studentId?.newProfilePicture
                                            ?.imageInfo?.secure_url
                                        : rv.studentId?.profilePicture
                                        ? ProfilePictureRootURL +
                                          rv.studentId?.profilePicture
                                        : man
                                    }
                                    className={`img-fluid rounded-circle img-60`}
                                    alt=""
                                  />
                                )}
                              </div>

                              <div className="col-md-11">
                                <CardText className="text-primary mb-0">
                                  {k === 0 && (
                                    <div
                                      className="d-flex"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span className="float-left">
                                        {rv.studentId?.firstName}{" "}
                                        {rv.studentId?.lastName}{" "}
                                      </span>

                                      <span className="float-right">
                                        Tuition :
                                        {item.tuition?.interestedSubjects?.subjects
                                          ?.map((x) => x.name)
                                          ?.join(" ")}
                                      </span>
                                    </div>
                                  )}
                                  <ClassRating
                                    rating={rv.feedback?.fromStudent?.rating}
                                  />
                                </CardText>

                                <CardText className="mb-0">
                                  <small className="px-0">
                                    {moment(
                                      rv.feedback?.fromStudent?.time
                                    ).format("Do MMMM YYYY, h:mm a")}
                                  </small>
                                </CardText>
                                <CardText>
                                  {rv.feedback?.fromStudent?.text}
                                </CardText>
                                {k === 0 && item.reviews?.length > 1 && (
                                  <>
                                    <hr />
                                    <CardText>
                                      <span>
                                        <span className="float-right">
                                          Click the More button to see (
                                          {item.reviews?.length - 1})
                                        </span>{" "}
                                        more reviews of this tuition.
                                        {item.reviews?.length > 1 && (
                                          <button
                                            className="btn btn-link px-0"
                                            onClick={() =>
                                              toggleShowMore(item.tuition._id)
                                            }
                                          >
                                            {showMore(item.tuition._id)
                                              ? "Less"
                                              : "More"}
                                          </button>
                                        )}
                                      </span>
                                    </CardText>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
