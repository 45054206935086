import React, { Fragment, useState, useEffect, useContext } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H2 } from "../../../AbstractElements";
import CommonMethods from "./PaymentComponents/CommonMethods";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { useGetMemberInfoByIdQuery } from "../../../redux/features/member/memberApi";
import { useSelector } from "react-redux";
import PersonalAddressContext from "../../../_helper/PersonalAddress";

const PaymentInformation = ({ header }) => {
  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo, refetch } = useGetMemberInfoByIdQuery(user);
  const [method, setMethod] = useState("");
  const [currentMethod, setCurrentMethod] = useState("");
  const [saved, setSaved] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  // stripe && paypal
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  // bank
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [otherPaymentMethod, setOtherPaymentMethod] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberOrId, setMobileNumberOrId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [countryValidity, setCountryValidity] = useState(false);
  const [countryOptions, setCountryOptions] = useState("");

  const { country } = useContext(PersonalAddressContext);


  // regex validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[+\d-]+$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const numberRegex = /^\d+$/;
  const bankRegex = /^[a-zA-Z\s\d]+$/;
  const routingRegex = /^[0-9A-Za-z]+$/;
  const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

  useEffect(() => {
    // console.log(memberInfo);
    if (memberInfo?.payoutMethod) {
      loadData();
    }
    else {
      setSaved(false);
    }
  }, [memberInfo]);

  const loadData = () => {
    if ((memberInfo?.payoutMethod).includes('/')) {
      const parentPayoutMethod = memberInfo?.payoutMethod.split('/')[0];
      const childPayoutMethod = memberInfo?.payoutMethod.split('/')[1];
      setCurrentMethod(parentPayoutMethod);
      setMethod(parentPayoutMethod);
      setOtherPaymentMethod(childPayoutMethod);
    }
    else {
      setCurrentMethod(memberInfo?.payoutMethod);
      setMethod(memberInfo?.payoutMethod);
    }
    setSaved(true);
  }

  const handleMethodChange = (e) => {
    setMethod(e.target.value);
  };

  const paypalValidation = () => {
    let isValid = true;

    if (!email || !confirmEmail || !phone || !accountName) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }

    if (!nameRegex.test(accountName)) {
      toastNotifiy("Invalid account name", "danger");
      isValid = false;
      setError(true);
    }

    if (!emailRegex.test(email)) {
      toastNotifiy("Please enter valid email", "danger");
      isValid = false;
      setError(true);
    }

    if (emailRegex.test(email) && email !== confirmEmail) {
      toastNotifiy("Email doesn't match", "danger");
      isValid = false;
      setError(true);
    }

    if (!phoneRegex.test(phone)) {
      toastNotifiy("Please enter valid phone number", "danger");
      isValid = false;
      setError(true);
    }

    setError(false);
    return isValid;
  }

  const bankValidation = () => {
    if (!accountNumber || !bankName || !branchName || !accountName || !routingNumber || !swiftCode) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }

    let isValid = true;

    if (!nameRegex.test(accountName)) {
      toastNotifiy("Invalid account name", "danger");
      isValid = false;
      setError(true);
    }

    if (!numberRegex.test(accountNumber)) {
      toastNotifiy("Invalid account number", "danger");
      isValid = false;
      setError(true);
    }

    if (!bankRegex.test(bankName)) {
      toastNotifiy("Invalid bank name", "danger");
      isValid = false;
      setError(true);
    }

    if (!bankRegex.test(branchName)) {
      toastNotifiy("Invalid branch name", "danger");
      isValid = false;
      setError(true);
    }

    if (!routingRegex.test(routingNumber)) {
      toastNotifiy("Invalid routing number", "danger");
      isValid = false;
      setError(true);
    }

    if (!swiftRegex.test(swiftCode)) {
      toastNotifiy("Invalid swift code", "danger");
      isValid = false;
      setError(true);
    }
    setError(false);
    return isValid;
  }

  const otherPaymentValidation = () => {
    if (!accountName) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }
    if (otherPaymentMethod !== "Khalti" && otherPaymentMethod !== "eSewa" && !mobileNumber) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }
    if ((otherPaymentMethod === "Bkash" || otherPaymentMethod === "Nagad" || otherPaymentMethod === "Rocket") && !referenceNumber) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }
    if ((otherPaymentMethod === "Khalti" || otherPaymentMethod === "eSewa") && !mobileNumberOrId) {
      toastNotifiy("Fields can't be empty", "warn");
      return false;
    }
    let isValid = true;

    if (!nameRegex.test(accountName)) {
      toastNotifiy("Invalid account name", "danger");
      isValid = false;
      setError(true);
    }

    if (otherPaymentMethod !== "Khalti" && otherPaymentMethod !== "eSewa" && !phoneRegex.test(mobileNumber)) {
      toastNotifiy("Please enter valid mobile number", "danger");
      isValid = false;
      setError(true);
    }

    setError(false);
    return isValid;
  }

  const handleSaveInformation = async () => {
    let paymentData = {}
    if (method === "paypal" && paypalValidation()) {
      paymentData = {
        accountName: accountName,
        email: email,
        phone: phone
      }
    }
    if (method === "bank" && bankValidation()) {
      paymentData = {
        accountName: accountName,
        accountNumber: accountNumber,
        bankName: bankName,
        branchName: branchName,
        routingNumber: routingNumber,
        swiftCode: swiftCode
      }
    }
    if (method === "others" && otherPaymentValidation()) {
      if (otherPaymentMethod === "Bkash" || otherPaymentMethod === "Nagad" || otherPaymentMethod === "Rocket") {
        paymentData = {
          paymentMethod: otherPaymentMethod,
          accountName: accountName,
          mobileNumber: mobileNumber,
          referenceNumber: referenceNumber,
        }
      }
      if (otherPaymentMethod === "Paytm" || otherPaymentMethod === "PhonePe" || otherPaymentMethod === "JazzCash" || otherPaymentMethod === "Easypaisa" || otherPaymentMethod === "GCash" || otherPaymentMethod === "PayMaya" || otherPaymentMethod === "GrabPay" || otherPaymentMethod === "Touch 'n Go" || otherPaymentMethod === "GoPay" || otherPaymentMethod === "OVO") {
        paymentData = {
          paymentMethod: otherPaymentMethod,
          accountName: accountName,
          mobileNumber: mobileNumber,
        }
      }
      if (otherPaymentMethod === "Khalti" || otherPaymentMethod === "eSewa") {
        paymentData = {
          paymentMethod: otherPaymentMethod,
          accountName: accountName,
          mobileNumberOrId: mobileNumberOrId,
        }
      }
    }
    if (Object.keys(paymentData).length !== 0) {
      if (method === "others") {
        var res = await axiosRequest({
          url: "/payout/savePayoutInfo",
          method: "post",
          data: {
            userId: user,
            method: method + "/" + otherPaymentMethod,
            info: paymentData
          },
        });
      }
      else {
        var res = await axiosRequest({
          url: "/payout/savePayoutInfo",
          method: "post",
          data: {
            userId: user,
            method: method,
            info: paymentData
          },
        });
      }
      if (res?.status === 201) {
        refetch();
        toastNotifiy("Information saved successfully", "success");
        setSaved(true)
        setCurrentMethod(method);
        setEmail("");
        setConfirmEmail("");
        setAccountName("");
        setPhone("");
        setAccountNumber("");
        setBankName("");
        setBranchName("");
        setRoutingNumber("");
        setSwiftCode("");
        setFetchData(true);
        setOtherPaymentMethod("");
        setMobileNumber("");
        setMobileNumberOrId("");
        setReferenceNumber("");
      }

    }
  }

  const handleBackButton = () => {
    loadData();
  }

  useEffect(() => {
    const c = country.toLowerCase();
    // console.log(c);

    if (c === "bangladesh" || c === "india" || c === "pakistan" || c === "nepal" || c === "philippines" || c === "malaysia" || c === "indonesia") {
      setCountryValidity(true);
      setCountryOptions("");
    } else {
      setCountryValidity(false);
      setOtherPaymentMethod("");
      setMobileNumber("");
      setMobileNumberOrId("");
      setReferenceNumber("");
      setCountryOptions("");
    }

    if (c === "bangladesh") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="Bkash">Bkash</option>
        <option value="Nagad">Nagad</option>
        <option value="Rocket">Rocket</option>
      </>)
    }
    else if (c === "india") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="Paytm">Paytm</option>
        <option value="PhonePe">PhonePe</option>
      </>)
    }
    else if (c === "pakistan") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="JazzCash">JazzCash</option>
        <option value="Easypaisa">Easypaisa</option>
      </>)
    }
    else if (c === "nepal") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="Khalti">Khalti</option>
        <option value="eSewa">eSewa</option>
      </>)
    }
    else if (c === "philippines") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="GCash">GCash</option>
        <option value="PayMaya">PayMaya</option>
      </>)
    }
    else if (c === "malaysia") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="GrabPay">GrabPay</option>
        <option value="Touch 'n Go">Touch 'n Go</option>
      </>)
    }
    else if (c === "indonesia") {
      setCountryOptions(<>
        <option disabled selected>Select an option</option>
        <option value="GoPay">GoPay</option>
        <option value="OVO">OVO</option>
      </>)
    }
    else {
      setCountryOptions("");
    }

  }, [country]);

  // console.log(method);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H2 attrH2={{ className: "txt-primary" }}>{header}</H2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="text-center">
              <h3>Choose Your Payout Method</h3>
              <h5>You can select one method to provide your details</h5>
            </Col>
          </Row>
          <Row>
            <CommonMethods
              method={method}
              handleFunction={handleMethodChange}
              email={email}
              setEmail={setEmail}
              confirmEmail={confirmEmail}
              setConfirmEmail={setConfirmEmail}
              phone={phone}
              setPhone={setPhone}
              error={error}
              accountName={accountName}
              setAccountName={setAccountName}
              accountNumber={accountNumber}
              setAccountNumber={setAccountNumber}
              bankName={bankName}
              setBankName={setBankName}
              branchName={branchName}
              setBranchName={setBranchName}
              routingNumber={routingNumber}
              setRoutingNumber={setRoutingNumber}
              swiftCode={swiftCode}
              setSwiftCode={setSwiftCode}
              currentMethod={currentMethod}
              otherPaymentMethod={otherPaymentMethod}
              setOtherPaymentMethod={setOtherPaymentMethod}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              referenceNumber={referenceNumber}
              setReferenceNumber={setReferenceNumber}
              mobileNumberOrId={mobileNumberOrId}
              setMobileNumberOrId={setMobileNumberOrId}
              countryValidity={countryValidity}
              setCountryValidity={setCountryValidity}
              countryOptions={countryOptions}
              saved={saved}
              setSaved={setSaved}
            >
            </CommonMethods>
          </Row>
          {
            (method && !saved) && (
              <Row className="m-t-15">
                <Col className="d-flex justify-content-start">
                  <Button
                    className="border-0 d-flex align-items-center"
                    color="dark"
                    onClick={handleBackButton}
                  >
                    Back
                  </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    className="border-0 d-flex align-items-center"
                    color="success"
                    onClick={handleSaveInformation}
                  >
                    Save Information
                  </Button>
                </Col>
              </Row>
            )
          }
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default PaymentInformation;