import React, { Fragment } from "react";
// import CalenderContain from "../../../Component/Scheduler/Calender/Index";
import CalenderContain from "../../../Component/Schedule/Calendar/index";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const Calendar = () => {
  useLogoutSession();
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Calendar" title="Calander" /> */}
      <CalenderContain />
    </Fragment>
  );
};

export default Calendar;
