import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import "../../../style/custom-css/FindTutor.css";

export default function TuitionFeesFilter({ isReset }) {
  const [currency, setCurrency] = useState("");
  const [minValue, set_minValue] = useState("");
  const [maxValue, set_maxValue] = useState("");
  const { priceFilter, setPriceFilter, moonlight } = useContext(FilterContext);
  const { getCurrencies, currencies, setCurrencies } =
    useContext(TutorsContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const priceErrorMessage = "max price must be greater than or equal min price";

  // useEffect(() => {
  //   if (currencies.length === 0) {
  //     getCurrencies();
  //   }
  //   return () => {
  //     // setCurrencies(currencies);
  //   };
  // }, []);

  // const updateCurrencyFilter = (item) => {
  //   if (!item || item.length == 0) {
  //     setPriceFilter({});
  //     set_maxValue("");
  //     set_minValue("");
  //     return;
  //   }
  //   setPriceFilter((prev) => ({ ...prev, currency: item }));
  // };

  useEffect(() => {
    if (maxValue == "" && minValue == "") {
      setPriceFilter({});
    } else if (minValue && !maxValue) {
      setShowErrorMessage(false);
      setPriceFilter({ min: parseFloat(minValue) });
    } else if (!minValue && maxValue) {
      setPriceFilter({ max: parseFloat(maxValue) });
    } else if (maxValue == "") {
      setShowErrorMessage(false);
    } else if (parseFloat(maxValue) >= parseFloat(minValue)) {
      setShowErrorMessage(false);
      setPriceFilter((prev) => ({
        ...prev,
        min: parseFloat(minValue),
        max: parseFloat(maxValue),
      }));
    } else if (parseFloat(maxValue) < parseFloat(minValue)) {
      setShowErrorMessage(true);
      setPriceFilter((prev) => ({
        ...prev,
        min: parseFloat(minValue),
        max: parseFloat(maxValue),
      }));
    }
  }, [minValue, maxValue]);

  const initializeFeesFilter = () => {
    // setCurrency("");
    setPriceFilter({});
    set_maxValue("");
    set_minValue("");
  };

  useEffect(() => {
    if (isReset) {
      setShowErrorMessage(false);
      initializeFeesFilter();
    }
  }, [isReset]);

  return (
    <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
      <h4 className="f_title mb-3">
        <span>Price/class</span>
      </h4>

      <Row className="d-flex align-items-bottom">
        <Col className="usdAlign" md={2}>
          <span>USD</span>
          {/* <Select
            options={currencies}
            placeholder="Select Currency"
            className="js-example-basic-single col-sm-12 darkMode"
            isClearable={true}
            onChange={(item) => {
              setCurrency(item);
              updateCurrencyFilter(item);
            }}
            value={currency}
          /> */}
        </Col>
        <Col md={5} className="mb-2">
          <Input
            type="text"
            placeholder="Minimum"
            value={minValue}
            // disabled={!currency}
            onChange={(e) =>
              set_minValue(
                e.target.value
                  ?.replace(/[^\d.]/g, "")
                  ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
              )
            }
          />
        </Col>
        <Col md={5}>
          <Input
            type="text"
            placeholder="Maximum"
            value={maxValue}
            min={minValue}
            // disabled={!currency}
            onChange={(e) =>
              set_maxValue(
                e.target.value
                  ?.replace(/[^\d.]/g, "")
                  ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
              )
            }
          />
          <span>
            {showErrorMessage ? (
              <div className="text-danger">{priceErrorMessage}</div>
            ) : (
              ""
            )}
          </span>
        </Col>
      </Row>
    </div>
  );
}
