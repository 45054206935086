import moment from "moment";
import { RRule } from "rrule";

export const timeSlotByWeekDay = (date, tutorTimeTable) => {
  var weekDay = moment(date).format("dddd");
  var timeSlots = tutorTimeTable.find((x) => !x.isBooked && x.dayName.toUpperCase() === weekDay.substring(0, 3).toUpperCase());
  return timeSlots;
};

export const validateTimeSlot = (selectedStart, selectedEnd, tutorTimeTable, startDate) => {
  var isValidTime = false;
  var list = timeSlotByWeekDay(startDate, tutorTimeTable);
  var freeSlots = list.timeTable;

  for (let i = 0; i < freeSlots?.length; i++) {
    var start1 = moment(new Date(freeSlots[i].start.toLocaleString())).format("HH:mm");

    var end1 = moment(new Date(freeSlots[i].end.toLocaleString())).format("HH:mm");
    var selected1 = moment(selectedStart).format("HH:mm");
    var selected2 = moment(selectedEnd).format("HH:mm");

    var isStartTimeValid = isTimeBetween(start1, end1, selected1, "start");

    var isEndTimeValid = isTimeBetween(start1, end1, selected2, "end");

    if (isStartTimeValid && isEndTimeValid && !freeSlots[i].isBooked) {
      isValidTime = true;
      break;
    } else {
      isValidTime = false;
    }
  }
  return isValidTime;
};
export const validateTimeSlot2 = (selectedStart, selectedEnd, freeSlots) => {
  var isValidTime = false;

  for (let i = 0; i < freeSlots?.length; i++) {
    var start1 = moment(new Date(freeSlots[i].start.toLocaleString())).format("HH:mm");
    var end1 = moment(new Date(freeSlots[i].end.toLocaleString())).format("HH:mm");
    var selected1 = moment(selectedStart).format("HH:mm");
    var selected2 = moment(selectedEnd).format("HH:mm");
    var isStartTimeValid = isTimeBetween(start1, end1, selected1, "start");
    var isEndTimeValid = isTimeBetween(start1, end1, selected2, "end");
    if (isStartTimeValid && isEndTimeValid && !freeSlots[i].isBooked) {
      isValidTime = true;
      break;
    } else {
      isValidTime = false;
    }
  }
  return isValidTime;
};

export const validateTimeSlotFromScheduleList = (scheduleEventList, startDate, selectedStart, selectedEnd) => {
  const dt1 = moment(startDate).format("YYYY-MM-DD");
  const selected1 = moment(selectedStart).format("HH:mm");
  const selected2 = moment(selectedEnd).format("HH:mm");

  const filteredList = scheduleEventList.filter((item) => {
    const dt2 = moment(item.date).format("YYYY-MM-DD");

    if (dt1 !== dt2) {
      return false;
    }

    const start1 = moment(item.start).format("HH:mm");
    const end1 = moment(item.end).format("HH:mm");

    const isStartTime = isTimeBetween(start1, end1, selected1, "start");
    const isEndTime = isTimeBetween(start1, end1, selected2, "end");
    // have issues on equal endTime
    return isStartTime || isEndTime;
  });

  return filteredList;
};

export const formatStartEndTime = (date, time) => {
  // later startTime 11:45PM  and endTime 03:AM, then endDate will be next date and startDate remain same
  var dt1 = moment(date).format("YYYY-MM-DD");
  var _start = moment(time?.start).format("LT");
  var _end = moment(time?.end).format("LT");

  var start = moment(`${dt1} ${_start}`, "YYYY-MM-DD HH:mm aa").format();
  var end = moment(`${dt1} ${_end}`, "YYYY-MM-DD HH:mm aa").format();

  return { start, end };
};

const isTimeBetween = function (startTime, endTime, selectedTime, type) {
  let start = moment(startTime, "H:mm");
  let end = moment(endTime, "H:mm");
  let selected = moment(selectedTime, "H:mm");

  if (end < start) {
    return (selected >= start && selected <= moment("23:59:59", "h:mm:ss")) || (selected >= moment("0:00:00", "h:mm:ss") && selected < end);
  }

  if (type === "start") return selected >= start && selected < end;
  if (type === "end") return selected <= end && selected >= start;
};
function removeZFromISODateString(dateString) {
  if (dateString.endsWith("Z")) {
    return dateString.slice(0, -1);
  } else {
    return dateString;
  }
}
export const getAllSchedulesByRRule = ({ startDate, endDate, repeat, timeTable }) => {
  var byWeeks = getByWeekDays(timeTable);
  //   {
  //     "startDate": "2023-06-11T18:00:00.000Z",
  //     "endDate": "2023-10-02T18:00:00.000Z"
  // }
  const rule = new RRule({
    freq:
      repeat?.type === "weekly" ? RRule.WEEKLY : repeat?.type === "daily" ? RRule.DAILY : repeat?.type === "monthly" ? RRule.MONTHLY : RRule.YEARLY,
    interval: repeat?.interval,
    byweekday: repeat?.type === "weekly" ? byWeeks : [],
    dtstart: new Date(removeZFromISODateString(startDate)),
    until: new Date(removeZFromISODateString(endDate)),
  });

  var dates = rule.all();
  return dates;
};

const getByWeekDays = (timeTable) => {
  var weekDays = [];
  timeTable?.map((item) => {
    console.log(item.dayName);
    if (item.dayName === "sat") {
      weekDays.push(RRule.SA);
    } else if (item.dayName === "sun") {
      weekDays.push(RRule.SU);
    } else if (item.dayName === "mon") {
      weekDays.push(RRule.MO);
    } else if (item.dayName === "tue") {
      weekDays.push(RRule.TU);
    } else if (item.dayName === "wed") {
      weekDays.push(RRule.WE);
    } else if (item.dayName === "thu") {
      weekDays.push(RRule.TH);
    } else if (item.dayName === "fri") {
      weekDays.push(RRule.FR);
    }
  }); // if (timeTable.hasOwnProperty("sat")) weekDays.push(RRule.SA);
  return weekDays;
};

export function getWeeklySchedules(date, timeTable) {
  var weekDay = moment(date).format("dddd");
  var startEnd = null;
  timeTable?.map((x) => {
    if (x.dayName.toUpperCase() === weekDay.substring(0, 3).toUpperCase()) {
      startEnd = formatStartEndTime(date, x);
    }
    return startEnd;
  });
  return startEnd;
}

export function findFreeSlots(schedule, bookedList) {
  const output = [];
  // let remainingTimeRange = { start: schedule.start, end: schedule.end };
  let remainingTimeRange = schedule;

  // Iterate over the elements in array2
  for (const element of bookedList) {
    // If the current element overlaps with the remaining time range, exclude it
    if (element.startTime < remainingTimeRange.end && element.endTime > remainingTimeRange.start) {
      if (element.startTime > remainingTimeRange.start) {
        // Add the time range before the current element to the output
        output.push({
          ...element,
          start: remainingTimeRange.start,
          end: element.startTime,
        });
      }
      remainingTimeRange.start = element.endTime;
    }
  }

  // Add the time range after the last element in array2 to the output
  if (remainingTimeRange.start < remainingTimeRange.end) {
    output.push(remainingTimeRange);
  }

  console.log({ output });
  return output;
}
