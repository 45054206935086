import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearAllLoginStore } from "../../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import { parseJwt } from "../../redux/utils/tuteairCommon";
import MemberInformationContext from "../MemberInformation";
import PersonalAddressContext from "../PersonalAddress";
import BecomeTutorContext from "../BecomeTutor";


const useLogoutSession = () => {
    let userInfo = JSON.parse(localStorage.getItem("tuteAirUser"));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { clearBecomeTutorContext } = useContext(BecomeTutorContext);


    const {
        setAddress1,
        setAddress2,
        setArea,
        setCity,
        setState,
        setPostalCode,
        setCountry,
        setLatitude,
        setLongitude,
    } = useContext(PersonalAddressContext);

    const {
        setAbout,
        setmedia,
        setdateOfBirth,
        setFullName,
        setGender,
        setEmail,
        setPhone,
        setLanguages,
        setMemberType: setMemberType2,
        setAddress,
        setEducations,
        setWorkExperiences,
        setSchedules,
        setInterestAndPricing,
        setInterestToLearn,
        setLanguageSelectedOptions,
        setProfileUrl,
        setDocumentID,
    } = useContext(MemberInformationContext);

    const clearProvider = () => {
        setAddress1("");
        setAddress2("");
        setArea("");
        setCity("");
        setState("");
        setPostalCode("");
        setCountry("");
        setLatitude("");
        setLongitude("");
        clearMemberInfoContext();
    };

    const clearMemberInfoContext = () => {
        setAbout({});
        setmedia({});
        setdateOfBirth(new Date("01/01/2000"));
        setFullName("");
        setGender("");
        setEmail("");
        setPhone("");
        setLanguages([]);
        setMemberType2("");
        setAddress({});
        setEducations([]);
        setWorkExperiences([]);
        setSchedules([]);
        setInterestAndPricing([]);
        setInterestToLearn([]);
        setLanguageSelectedOptions([]);
        setProfileUrl("");
        setDocumentID("");
    };

    useEffect(() => {
        const Logout = () => {
            clearAllLoginStore(dispatch);
            clearProvider();
            localStorage.clear();
            clearBecomeTutorContext();
            // navigate(`${process.env.PUBLIC_URL}/login-account`);
            window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/?logout=1`;
        };

        if (userInfo) {
            if (userInfo.accessToken) {
                const jwtRes = parseJwt(userInfo.accessToken);
                const date = Date.now();
                const seconds = Math.floor(date / 1000);

                if (seconds >= jwtRes.exp) {
                    Logout();
                }
            }
        }

    }, [userInfo]);
}

export default useLogoutSession;