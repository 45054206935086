import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Star } from "react-feather";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toastNotifiy } from "../../../Services/AuthVerify";
import { AddNotification } from "../../../Services/Notification.Service";
import ClassSchedulerContext from "../../../_helper/ClassScheduler";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";

import {
  useEditCompletedClassMutation,
  useGetReviewRatingByTuitionIdQuery,
  useSaveReviewRatingMutation,
} from "../../../redux/features/calendar/calendarApi";
import {
  useEditConversationMutation,
  useGetConversationsQuery,
} from "../../../redux/features/chat/conversations/conversationsApi";
import {
  addCustomDays,
  isDifferenceGivenDays,
} from "../../../redux/utils/tuteairCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ClassReviewModal = ({ isOpen, toggle, isEdit, item, memberType }) => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [reviewTime, setReviewTime] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const { getAllScheduleEvents, classList, filterClassList } = useContext(
    ClassSchedulerContext
  );
  const navigate = useNavigate();

  const [
    editConversation,
    { isSuccess: isEditConversationSuccess, error: err2 },
  ] = useEditConversationMutation();
  const [
    editCompletedClass,
    {
      isSuccess: isClassEdited,
      isLoading: isClassCompleteLoading,
      error: errorEditClass,
      data: editCompletedClassResponse,
    },
  ] = useEditCompletedClassMutation();

  const [
    saveReviewRating,
    {
      isSuccess: isReviewSaved,
      isLoading: isReviewSaveLoading,
      error: errorSavingReview,
      data: reviewSaveResponse,
    },
  ] = useSaveReviewRatingMutation();

  const {
    data: ratingData,
    isLoading,
    isError,
    refetch,
  } = useGetReviewRatingByTuitionIdQuery({
    userId: memberType === "student" ? item.studentId : item.tutorId,
    tuitionId: item.tuitionId,
    classId: item.classId,
    skip: !isEdit,
  });

  useEffect(() => {
    if (isEdit) {
      refetch();
    }
  }, [isEdit]);

  useEffect(() => {
    if (ratingData) {
      const feedback =
        memberType === "student"
          ? ratingData.feedback?.fromStudent
          : ratingData.feedback?.fromTutor;
      setRating(feedback?.rating ?? 0);
      setReviewText(feedback?.text ?? "");
      setReviewTime(feedback?.time ?? new Date());
      setIsUpdated(!!feedback);
    }
  }, [ratingData]);

  const handleCompletedClass = (event) => {
    event.preventDefault();

    if (reviewText !== "" && rating === 0) {
      toastNotifiy("No rating has been selected!", "warn");
      return;
    }

    // Create data object for the completed class
    const data = createDataObject(memberType, item);

    // save to database
    editCompletedClass({ data: data });
  };

  function createDataObject(memberType, item) {
    const isStudent = memberType === "student";
    const isTutor = memberType === "tutor";

    const isCompleteByStudent =
      (isStudent && item?.completionStatus === undefined && true) ||
      (typeof item?.completionStatus === "object" && isStudent && true) ||
      item?.completionStatus?.isCompleteByStudent;

    const isCompleteByTutor =
      (isTutor && item?.completionStatus === undefined && true) ||
      (typeof item?.completionStatus === "object" && isTutor && true) ||
      item?.completionStatus?.isCompleteByTutor;

    const class_info = {
      classId: item.classId,
      classDate: item.date,
      startTime: item.start,
      endTime: item.end,
      tuitionFee: item.confirmedTuitionFee,
      payDate: new Date(),
    };

    const data = {
      tuitionId: item.tuitionId,
      classId: item.classId,
      tutorId: item.tutorId,
      isCompleteByStudent,
      isCompleteByTutor,
      class_info,
    };

    return data;
  }

  useEffect(() => {
    async function handleEditCompletedClass() {
      if (!isClassEdited) return;
      if (!editCompletedClassResponse.success) return;
      const notifyObj = createNotifyObj();
      if (rating === 0) {
        toastNotifiy(
          "Successfully completed a class without providing review or rating!",
          "success"
        );
        toggle();
      } else {
        await saveReviewIfRatingGiven();
      }
      getAllScheduleEvents();
      AddNotification(notifyObj);
    }

    handleEditCompletedClass();
  }, [isClassEdited]);

  useEffect(() => {
    if (isReviewSaved) {
      toastNotifiy("You have successfully provide a review!", "success");
      updateScheduleEventList();
      toggle();
    }
  }, [isReviewSaved]);

  const updateScheduleEventList = () => {
    const updatedList = classList?.map((event) => {
      if (
        event.tuitionId === item.tuitionId &&
        event.classId === item.classId
      ) {
        const feedback = {
          bytutor: memberType === "tutor" ? true : !!event.feedback?.bytutor,
          bystudent:
            memberType === "student" ? true : !!event.feedback?.bystudent,
        };

        return { ...event, feedback };
      } else {
        return event;
      }
    });

    var userId = memberType === "student" ? item.studentId : item.tutorId;

    filterClassList(memberType, userId, updatedList);
  };

  function createNotifyObj() {
    const title =
      memberType === "tutor"
        ? `Your class on ${moment(item?.start).format(
          "LL"
        )} is completed with ${item?.tutorName}`
        : `Your class on ${moment(item?.start).format(
          "LL"
        )} is completed with ${item?.studentName}. ${item?.completionStatus?.isCompleteByTutor
          ? "Your fee for this class is now added to your TuteAir balance."
          : "Your fee for this class will be added to your TuteAir balance after your confirmation."
        } `;
    return {
      receiver: memberType === "student" ? item.tutorId : item.studentId,
      title,
      description: "Someone Wants to contact with you",
      context: process.env.PUBLIC_URL + "/calendar",
      isPublic: false,
      isFullTitle: true,
    };
  }

  async function saveReviewIfRatingGiven() {
    if (rating === 0) {
      toastNotifiy("No rating has been selected!", "warn");
      return;
    }

    // alert("No rating has been selected");
    // return;
    const reviewData = createReviewData();
    await saveReviewRating({ data: reviewData });
  }

  function createReviewData() {
    if (!item) {
      alert(1);
      return;
    }
    const reviewReceiverId =
      memberType === "student" ? item.tutorId : item.studentId;
    return {
      tuitionId: item.tuitionId,
      tutorId: item.tutorId,
      studentId: item.studentId,
      classId: item.classId,
      text: reviewText,
      rating: rating,
      time: isUpdated ? new Date(reviewTime) : new Date(),
      from: memberType,
      reviewReceiverId: reviewReceiverId,
      classInfo: {
        classStart: item.start,
        classEnd: item.end,
      },
    };
  }

  function isWithin7Days(dateString) {
    if (!dateString) return true;
    const now = new Date();
    const date = new Date(dateString);
    const diff = now.getTime() - date.getTime();
    const daysDiff = diff / (1000 * 60 * 60 * 24);
    return daysDiff >= 0 && daysDiff <= 7;
  }

  function remainingTime(targetTime) {
    const now = new Date();
    const target = new Date(targetTime);

    if (target < now) {
      return "Time has already passed";
    }

    const diff = target.getTime() - now.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

    const days = Math.floor(diff / oneDay);
    const hours = Math.floor((diff % oneDay) / (60 * 60 * 1000));
    const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((diff % (60 * 1000)) / 1000);

    if (days > 7) {
      return "Greater than 7 days";
    }

    return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }

  const handleIncomplete = async () => {
    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Do you want to send a reschedule message for this class?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleRescheduleRequest();
        SweetAlert.fire("Your text has been sent!", "", "success");
      } else {
      }
    });

    const handleRescheduleRequest = async () => {
      const chatReceiverId =
        memberType === "student" ? item.tutorId : item.studentId;
      const chatSenderId =
        memberType === "student" ? item.studentId : item.tutorId;

      let res = await axiosRequest({
        url: "chat/newConversation/getConversation/" + chatReceiverId,
        method: "get",
      });
      if (res) {
        var conversationId = res.data[0].conversationId;
      }
      //post req to change status to incomplete.
      //tar response == 200{
      editConversation({
        sender: chatSenderId,
        id: conversationId,
        data: {
          message:
            memberType === "student"
              ? `[${item.title}] ${moment(item?.date).format(
                "LLLL"
              )}, This class was not completed. Please reschedule it. Thank you.`
              : `[${item.title}] ${moment(item?.date).format(
                "LLLL"
              )}, This class was not completed. Do want me to reschedule it?`,
        },
      });
      const notifyObj = {
        receiver: chatReceiverId,
        title: `Your tuition for [${item.title}] of ${moment(item?.date).format(
          "LLLL"
        )} is incomplete.`,
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/inbox",
        isPublic: false,
      };
      AddNotification(notifyObj);
      navigate(`${process.env.PUBLIC_URL}/inbox/${conversationId}`);
    };
  };
  //   };
  const handleSendReview = () => {
    if (isUpdated && !isWithin7Days(reviewTime)) {
      alert("Edit time has already passed!!");
      return;
    }

    if (!isEdit && isDifferenceGivenDays(item.end, 14)) {
      var endDate =
        item.start &&
        new Date(addCustomDays(item.start, 14)).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

      alert(`Review time already passed at ${endDate}`);
      return;
    }

    saveReviewIfRatingGiven();
  };

  const handleRating = (value) => {
    if (
      !(
        !isWithin7Days(reviewTime) ||
        (!isEdit && isDifferenceGivenDays(item.end, 14))
      )
    ) {
      setRating(value);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-3 justify-content-center">
              Please leave a feedback for this class
            </div>
          </div>
          <div className="d-flex align-items-center mb-3 justify-content-center">
            <FontAwesomeIcon icon={faStar}
              className={`mr-2 fs-3 ${rating >= 1 ? "text-warning" : "text-secondary"}`}
              onClick={() => handleRating(1)}
            />
            <FontAwesomeIcon icon={faStar}
              className={`mr-2 fs-3 ${rating >= 2 ? "text-warning" : "text-secondary"}`}
              onClick={() => handleRating(2)}
            />
            <FontAwesomeIcon icon={faStar}
              className={`mr-2 fs-3 ${rating >= 3 ? "text-warning" : "text-secondary"}`}
              onClick={() => handleRating(3)}
            />
            <FontAwesomeIcon icon={faStar}
              className={`mr-2 fs-3 ${rating >= 4 ? "text-warning" : "text-secondary"}`}
              onClick={() => handleRating(4)}
            />
            <FontAwesomeIcon icon={faStar}
              className={`mr-2 fs-3 ${rating >= 5 ? "text-warning" : "text-secondary"}`}
              onClick={() => handleRating(5)}
            />
            {/* <Star
              size={24}
              className={`mr-2 ${rating >= 1 ? "bg-warning overflow-hidden" : ""}`}
              onClick={() => handleRating(1)}
            />
            <Star
              size={24}
              className={`mr-2 ${rating >= 2 ? "text-warning" : ""}`}
              onClick={() => handleRating(2)}
            />
            <Star
              size={24}
              className={`mr-2 ${rating >= 3 ? "text-warning" : ""}`}
              onClick={() => handleRating(3)}
            />
            <Star
              size={24}
              className={`mr-2 ${rating >= 4 ? "text-warning" : ""}`}
              onClick={() => handleRating(4)}
            />
            <Star
              size={24}
              className={`mr-2 ${rating >= 5 ? "text-warning" : ""}`}
              onClick={() => handleRating(5)}
            /> */}
          </div>
          {/* <div className="d-flex align-items-center mb-3 justify-content-center">
            {rating > 0 ? (
              <>
                <span>{rating}</span>{" "}
                <Star size={24} className={`mr-2 text-warning`} />
              </>
            ) : (
              ""
            )}
          </div> */}
          <textarea
            className="form-control mb-3"
            value={reviewText}
            onChange={(event) => setReviewText(event.target.value)}
            maxLength={200}
            required
            rows={5}
            disabled={
              !isWithin7Days(reviewTime) ||
              (!isEdit && isDifferenceGivenDays(item.end, 14))
            }
            placeholder="Write your review here within 200 characters..."
          />
          {isEdit ? (
            ""
          ) : (
            <>
              <div className="d-flex align-items-center mb-3 justify-content-center">
                <h3 className="text-center px-3">
                  Are you sure your class on{" "}
                  <u>
                    {item.start &&
                      new Date(item.start).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                  </u>{" "}
                  with{" "}
                  <u>
                    {memberType === "student"
                      ? item.tutorName
                      : item.studentName}
                  </u>{" "}
                  is Completed?
                </h3>
              </div>
              <div className="d-flex">
                {/* <small>
                  <b> N.B:</b> After 2 weeks of class end [
                  {item.start &&
                    new Date(addCustomDays(item.start, 14)).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  ], if a you miss to provide reviews/rating, there will be no
                  opportunity to provide any
                </small> */}
                <small className="text-center">
                  <b> N.B: </b>If you don't provide reviews/ratings within 2 weeks after class ends, you will not have another opportunity to do so.
                </small>
              </div>
            </>
          )}
          <></>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {/* <div>
            <Button color="secondary" onClick={toggle}>
              {isEdit ? "No" : "Cancel"}
            </Button>
          </div> */}
          <div>
            <Button color="warning" onClick={handleIncomplete}>
              {isEdit ? "No" : "Reschedule Request"}
            </Button>
          </div>
          <div>
            <Button
              color="primary"
              disabled={
                isClassCompleteLoading || isReviewSaveLoading || isLoading
              }
              onClick={isEdit ? handleSendReview : handleCompletedClass}
            >
              {isEdit
                ? isUpdated
                  ? "Update Review"
                  : "Send Review"
                : "Yes, I'm Sure"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ClassReviewModal;
