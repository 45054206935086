import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { authHeader } from "../../../../Services/AuthVerify";
import axios from "axios";
import { toast } from "react-toastify";
import { Btn } from "../../../../AbstractElements";
import { RequestedSubjectUrl } from "../../../../api";

const RequestedSubjectModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [isGetback, setIsGetback] = useState(true);
  const [subjectCategory, setSubjectCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subject, setSubject] = useState("");

  const submitReqToAddSubject = async (e) => {
    if (subjectCategory.trim() === "" || subject.trim() === "") {
      toast.warn("Please add required fields", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    setLoading(true);
    const res = await axios.post(
      RequestedSubjectUrl,
      {
        message: message,
        isGetback: isGetback,
      },
      {
        headers: {
          Authorization: authHeader(),
        },
      }
    );
    if (res.status == 201) {
      toast.success(
        "Subject Request submitted successfully. Thanks for your time.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
      setTimeout(() => {
        props.toggler();
      }, 4000);
    } else {
      toast.warn("Something went wrong! Please try again after sometimes. ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (subjectCategory.trim() !== "" && subject.trim() !== "") {
      handleSubmit();
    }
  }, [subjectCategory, subCategory, subject]);
  const handleSubmit = () => {
    const messageObj = {
      subjectCategory,
      subCategory,
      subject,
    };
    setMessage(messageObj);
    //console.log(messageObj);
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <div className="row mb-1">
                      <Label className="col-sm-3 col-form-label"></Label>
                      <Col sm="9">
                        <p style={{ fontSize: "14px" }}>
                          If your request is valid, We will add your subject to
                          the subject list.
                        </p>
                        <Label className="col-sm-6 col-form-label asterisk">
                          Subject Category:
                        </Label>
                        <Col sm="6" className="mb-2">
                          <Input
                            type="text"
                            className="col-sm-3 form-control"
                            value={subjectCategory}
                            onChange={(e) => setSubjectCategory(e.target.value)}
                            maxLength={100}
                          />
                        </Col>
                        <Label className="col-sm-6 col-form-label">
                          Subcategory:
                        </Label>
                        <Col sm="6" className="mb-2">
                          <Input
                            type="text"
                            className="form-control"
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                            maxLength={100}
                          />
                        </Col>

                        <Label className="col-sm-6 col-form-label asterisk">
                          Subject:
                        </Label>
                        <Col sm="6" className="mb-3">
                          <Input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            maxLength={100}
                          />
                        </Col>
                      </Col>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-1">
                      <Label className="col-sm-3 col-form-label"></Label>
                      <Col sm="9">
                        <Input
                          id="inline-1"
                          type="checkbox"
                          checked={isGetback}
                          onChange={(event) => {
                            setIsGetback(event.target.checked);
                          }}
                        />
                        <label
                          htmlFor="inline-1"
                          style={{ position: "absolute", FontSize: "13px" }}
                          className="mb-0 px-2"
                        >
                          {" "}
                          I won't mind if TuteAir get back to me!
                        </label>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) => submitReqToAddSubject(e),
            }}
          >
            {loading ? "Submitting..." : "Submit"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default RequestedSubjectModal;
