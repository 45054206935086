import axios from "axios";
import React, { useState } from "react";
import { GetAllTutorsNew } from "../../api";
import categories from "../../api/categories.json";
import subjects from "../../api/subjects.json";
import { axiosRequest } from "../../redux/utils/axios-utils";
import {
  getCoursesResponse,
  getCurrenciesResponse,
  getDegreesResponse,
  getLangugaesResponse,
  getPublicTutorsResponse,
} from "../../Services/Api.Service";
import { authHeader } from "../../Services/AuthVerify";
import Context from "./index";

const TutorsProvider = (props) => {
  const [tutorList, setTutorList] = useState([]);
  const [tutorsToShow, setTutorsToShow] = useState([]);
  const [topTutors, setTopTutors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [languages, setLanguages] = useState([]);
  const [courses, setCourses] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [tutorWithRatings, setTutorWithRatings] = useState([]);
  const [publicTutors, setPublicTutors] = useState([]);
  const [showPublicTutors, setShowPublicTutors] = useState([]);

  const [workExpYear, setWorkExpYear] = useState(0);
  const workExpYearList = [
    {
      value: "<1",
      label: "<1 years",
      name: "<1",
    },
    {
      value: "1-2",
      label: "1-2 years",
      name: "1-2",
    },
    {
      value: "3-4",
      label: "3-4 years",
      name: "3-4",
    },
    {
      value: "4+",
      label: "4+ years",
      name: "4+",
    },
  ];

  const GetAllTutors = async () => {
    await axios
      .get(GetAllTutorsNew, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        setTutorList(res.data);
        setTutorsToShow(res.data);
      });
  };

  const getTopTutors = async () => {
    var res = await axiosRequest({
      url: "/users/public/topTutors",
      method: "get",
    });
    if (res.data) {
      setTopTutors(res.data);
    }
  };

  const getPublicTutors = async () => {
    var result = await getPublicTutorsResponse();
    setPublicTutors(result);
    setShowPublicTutors(result);
  };

  const getCurrencies = async () => {
    var result = await getCurrenciesResponse();
    setCurrencies(result);
  };

  const getDegrees = async () => {
    var result = await getDegreesResponse();
    setDegrees(result);
  };

  const getCourses = async () => {
    var result = await getCoursesResponse();
    setCourses(result);
  };

  const getLanguages = async () => {
    var result = await getLangugaesResponse();
    setLanguages(result);
  };

  const getCountries = async () => {
    var res = await axiosRequest({
      url: "/users/public/countries",
      method: "get",
    });
    if (res.data) {
      var list = [];
      res?.data?.map((item, index) => {
        list.push({
          value: item._id + "_" + item.name,
          label: item.name,
          isDisabled: false,
        });
      });
      list = list.slice().sort((a, b) => a.label.localeCompare(b.label));
      setCountries(list);
    }
  };

  const getallReviewsAndRatings = async () => {
    var res = await axiosRequest({
      url: "/review/all",
      method: "get",
    });

    if (res?.data) {
      setRatingList(res?.data);
    }
    var ratigsnWithTutorList = [];

    tutorsToShow?.map((item) => {
      let totalRating = 0;
      var noOfClasses = 0;

      ratingList?.map((item2) => {
        if (item.userId === item2.tutorId) {
          if (item2?.feedback?.fromStudent?.rating !== undefined) {
            totalRating += item2?.feedback?.fromStudent?.rating;
            noOfClasses = noOfClasses + 1;
          }
        }
      });

      if (totalRating > 0) {
        ratigsnWithTutorList.push({
          noOfClasses: noOfClasses,
          rating: totalRating / noOfClasses,
          tutorId: item.userId,
        });
      }
    });
    setTutorWithRatings(ratigsnWithTutorList);
  };

  const getSubjects = () => {
    let subjectList1 = [];
    subjects?.map((item, i) => {
      subjectList1.push({
        value: item.tag,
        label: item.name,
        isDisabled: false,
      });
    });
    setAllSubjects(subjectList1);
  };

  const getCategories = () => {
    let categoryArrList = [];
    let subCategoryArrList = [];
    categories?.map((item, i) => {
      if (item.split("/").length > 0) {
        categoryArrList.push(item.split("/")[0]);
      }
      if (item.split("/").length > 1) {
        subCategoryArrList.push(item);
      }
    });
    var uniqueCategory = [...new Set(categoryArrList?.map((item) => item))];
    var categoryObjList = [];
    if (uniqueCategory) {
      uniqueCategory?.map((item, index) => {
        if (item.split("/").length > 0) {
          categoryObjList.push({
            value: item.split("/")[0],
            label: item.split("/")[0],
            isDisabled: false,
          });
        }
      });
    }

    var catList = categoryObjList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setAllCategories(catList);
    setAllSubCategories(subCategoryArrList);
    // state.subjectCategoryList = categoryObjList;
    // state.subjectSubCategoryList = subCategoryArrList;
  };

  return (
    <Context.Provider
      value={{
        ...props,
        tutorList,
        setTutorList,
        GetAllTutors,
        tutorsToShow,
        setTutorsToShow,
        getTopTutors,
        topTutors,
        setTopTutors,
        getCountries,
        countries,
        setCountries,
        getCurrencies,
        currencies,
        setCurrencies,
        getDegrees,
        degrees,
        setDegrees,
        selectedDegree,
        setSelectedDegree,
        getLanguages,
        languages,
        setLanguages,
        getCourses,
        courses,
        setCourses,
        selectedCourse,
        setSelectedCourse,
        workExpYearList,
        workExpYear,
        setWorkExpYear,
        tutorWithRatings,
        ratingList,
        getallReviewsAndRatings,
        publicTutors,
        setPublicTutors,
        showPublicTutors,
        setShowPublicTutors,
        getPublicTutors,
        getSubjects,
        allSubjects,
        setAllSubjects,
        getCategories,
        allCategories,
        setAllCategories,
        allSubCategories,
        setAllSubCategories,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default TutorsProvider;
