import axios from "axios";
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import { Input } from "reactstrap";
import { CountriesApi } from "../../../api";
import { authHeader } from "../../../Services/AuthVerify";
import FilterContext from "../../../_helper/Filter";
import {
  getSubCategoryList2,
  getSubjectList,
} from "../../AccountNew/Tutor/InterestPricing/subjectInterestCommon";

let { options, optionsOld, height } = getSubjectList();

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options?.indexOf(value) * height;
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const QuickSearch = ({ isReset }) => {
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currencyList, setCurrencyList] = useState(null);
  const [tutionTypeList, setTutionTypeList] = useState([]);

  const [selectedOptions, setSubjectSelectedOptions] = useState([]);

  const [selectedTutionTypeOptions, setTutionTypeSelectedOptions] = useState(
    []
  );

  const { subjectCategoryList, subjectSubCategoryList } =
    useSelector((state) => state.member) || {};

  //subjects post request values
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const {
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    setSubject_category_filter,
    setSubject_subcategory_filter,
  } = useContext(FilterContext);

  const handleSubjects = async (sub) => {
    if (!sub || sub.length === 0) {
      setSubject([]);
      setSubjectSelectedOptions([]);
      setSubject_category_filter({});
      setSubject_subcategory_filter({});
      return;
    }
    setSubject(sub);
    setSubjectSelectedOptions(sub);
    options = options?.filter((x) => x.tag === sub[0]?.tag);
    if (sub[0]?.tag.includes("/")) {
      setCategory({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      setSubject_category_filter({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      var subCatList = await getSubCategoryList2(
        sub[0],
        subjectSubCategoryList
      );

      setSubCategoryList(subCatList);
      setSubCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
      setSubject_subcategory_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
    } else {
      if (!selectedOptions) {
        alert(1);
      }
      setCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
      setSubject_category_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
    }
  };

  const handleCountryChange = async (item) => {
    if (!item) {
      setCountry();
      setLocation({});
      setPostalCode("");
      setIsPostCodeDisabled(true);
      return;
    }
    const [value, name] = item.value.split("_");
    if (!value) {
      setLocation({});
      return;
    }
    if (value) {
      setCountry(item);
      setLocation({ country: name });
      setCity("");
      setState("");
      setPostalCode("");
      setIsStateDisabled(true);
      setIsCityDisabled(false);
      setIsPostCodeDisabled(false);
      return;
    }
  };

  useEffect(() => {
    if (isReset) {
      setLocation({});
      setCountry("");
      setCity("");
      setPostalCode("");
      setIsCityDisabled(true);
      setIsPostCodeDisabled(true);
      setSubject("");
      setSubject_category_filter("");
      setSubject_subcategory_filter("");
      setSubjectSelectedOptions([]);
      setTutionTypeSelectedOptions([]);
    }
  }, [isReset]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countries, setCountries] = useState([]);
  // const [countryStates, setCountryStates] = useState([]);
  // const [cities, setCities] = useState([]);

  const { location, setLocation } = useContext(FilterContext);

  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isPostCodeDisabled, setIsPostCodeDisabled] = useState(true);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const res = await axios.get(CountriesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });

    var countryList = [];
    if (res.data) {
      res.data.map((item, index) => {
        countryList.push({
          value: item._id + "_" + item.name,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    countryList = countryList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setCountries(countryList);
  };
  const updatePostalCode = (e) => {
    setPostalCode(e.target.value);
    setLocation((prev) => ({ ...prev, postalCode: e.target.value }));
  };
  return (
    <Fragment>
      <div className="col-xxl-3 mb-3">
        <Select
          options={countries}
          placeholder="Select Country"
          className="js-example-basic-single col-sm-12 darkMode"
          onChange={(item) => handleCountryChange(item)}
          value={country}
          isClearable={true}
        />
      </div>
      <div className="col-xxl-2 mb-3">
        <Input
          className="form-control"
          type="text"
          placeholder="Post Code"
          disabled={isPostCodeDisabled}
          value={postalCode}
          onChange={(e) => updatePostalCode(e)}
        />
      </div>
      <div className="col-xxl-4 mb-3">
        <Select
          isMulti
          components={{ MenuList }}
          options={options}
          className="darkMode"
          isClearable={true}
          placeholder="Select Subjects"
          isSearchable={true}
          isDisabled={false}
          isLoading={false}
          closeMenuOnSelect={false}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={(item) => handleSubjects(item)}
          value={selectedOptions}
        />
      </div>
    </Fragment>
  );
};

export default QuickSearch;
