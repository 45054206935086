import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { H2 } from '../../../AbstractElements';
import { useSelector } from "react-redux";
import TutorEduInfo from '../../AccountNew/Tutor/Education/TutorEduInfo';
import MemberInformationContext from '../../../_helper/MemberInformation';

const Education = ({header}) => {
  const { educations, setEducations, getMemberInformation } = useContext(
    MemberInformationContext
  );
  useEffect(() => {
    if (educations.length === 0) {
      getMemberInformation();
    }
  }, []);
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H2 attrH2={{className: "txt-primary"}}>{header}</H2>
        </CardHeader>
        <CardBody>
          <TutorEduInfo></TutorEduInfo>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default Education