import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ROOT } from "../../api";

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [firstNetworkCheck, setFirstNetworkCheck] = useState(true);
    const [networkStatus, setNetworkStatus] = useState(false);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            setFirstNetworkCheck(false);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvement
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    useEffect(() => {
        if (isOnline) {
            if (firstNetworkCheck) {
                return;
            } else {
                // toast.success("You are online", {
                //     autoClose: 10000,
                // });
                setNetworkStatus(false);
            }
        }
        else {
            // toast.error("You are offline", {
            //     autoClose: 10000,
            // })
            setNetworkStatus(true);
        }
    }, [isOnline]);

    useEffect(() => {
        axios.get(`${ROOT}/`)
            .then(res => {
                if (res.status === 200) {
                    return;
                }
                else {
                    if (networkStatus) {
                        return;
                    }
                    toast.error("Server is currently down", {
                        autoClose: 15000,
                    })
                }
            })
            .catch(error => {
                toast.error("Server is currently down", {
                    autoClose: 15000,
                })
            })
    }, []);


    return networkStatus;
}

export default useNetworkStatus;