import React, { useEffect, useState } from "react";
import { Col, Row, Input } from "reactstrap";
import { useWindowWidth } from "../../../Chat/NewChat/inbox/useWindowWidth";

const AddressInput = ({ label, value, edit, inputType, handleFunction, optional, setSaveEnable }) => {
  let width = useWindowWidth();
  
  return (
    <div className="d-flex align-items-center justify-content-between m-b-10">
      <Col md="3">
          <span className={`col-form-label ${optional ? "": "asterisk"}`} style={{color: "#6c757d"}}>{label}</span>
          {/* <span className="col-form-label asterisk bg-primary" style={{color: "#6c757d"}}>{label}</span> */}
      </Col>
      <Col md="9" xs="9">
        <Row >
          {/* readonly value */}
          {
            !edit && <span>{value}</span> 
            // !edit && <span className="bg-warning">{value}</span> 
          }
          {/* when only text input */}
          { edit && (
            <>
            <Col>
            {/* <Col className="bg-danger"> */}
              <Input
                className="form-control"
                type="text"
                placeholder={label}
                value={value}
                onChange={(e) => {
                  handleFunction(e.target.value)
                  setSaveEnable(true)
                }}
              />
              </Col>
              {/* {!isValid && (
                <p className="txt-danger">Please enter valid phone number</p>
              )} */}
            </>
            )
          }
        </Row>
      </Col>
    </div>
  );
};

export default AddressInput;
