import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

const HelpContact = ({ oppositeUser }) => {
  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  return (
    <div>
      <p className="f-16">
        In your Dashboard
        <ul className="f-16">
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              1
            </span>{" "}
            Click on{" "}
            <Button
              style={{ padding: "4px 6px", border: "none", outline: "none" }}
              color=" "
              disabled={_tuteair ? false : true}
            >
              {_tuteair && oppositeUser === "Student" ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/find-students`}
                  target="_blank"
                >
                  <span className="txt-primary">Find Students</span>
                </Link>
              ) : _tuteair && oppositeUser === "Tutor" ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/find-tutors`}
                  target="_blank"
                >
                  <span className="txt-primary">Find Tutors</span>
                </Link>
              ) : (
                <span className="txt-primary">Find {`${oppositeUser}`}s</span>
              )}
            </Button>
            {/* <span className="txt-primary">Find {`${oppositeUser}`}s</span> */}
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              2
            </span>{" "}
            Click on the name of a {`${oppositeUser.toLowerCase()}`}
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              3
            </span>{" "}
            Click on <span className="">Contact {`${oppositeUser}`}</span>
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              4
            </span>{" "}
            You can select subject and state your budget for the tuition
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              5
            </span>{" "}
            You can also write a message
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              6
            </span>{" "}
            Click on <span className="">Start Conversation</span>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default HelpContact;
