import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Form, Media, Row } from "reactstrap";
import { H4, Image, P } from "../../../AbstractElements";
import man from "../../../assets/images/avtar/man.png";

const MyProfile = () => {
  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
  const [profile2, setProfile2] = useState(man);

  useEffect(() => {
    const picture2 = localStorage.getItem("picture2");
    if (picture2 === "" || picture2 === " ") {
      setProfile2(man);
    } else {
      setProfile2(picture2);
    }
  }, []);

  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: "card-title mb-0" }}>{ }</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-2">
              <div className="profile-title">
                <Media>
                  <Image
                    attrImage={{
                      className: "img-70 rounded-circle",
                      alt: "",
                      src: `${profile2}`,
                    }}
                  />
                  <Media body style={{ paddingLeft: "15px" }}>
                    <h3 className="mb-1 f-20 txt-primary">
                      <Link to={`${process.env.PUBLIC_URL}/profile/me`}>
                        {auth0_profile.name}
                      </Link>
                    </h3>
                    <P>
                      {auth0_profile.accountType === "both"
                        ? "TUTOR & STUDENT"
                        : auth0_profile.accountType?.toUpperCase()}
                      <p className="pt-0 mt-0 border-top">
                        {auth0_profile.email}
                      </p>
                    </P>
                  </Media>
                </Media>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default MyProfile;
