const faqList2 = [
  {
    id: 1,
    subTitle: "Cost",
    titless: [
      {
        id: 1,
        title: "How much does it cost?",
        answer:
          "Registration is totally free. The fees of the classes is set by the tutor and paid by the students.",
      },
      {
        id: 10,
        title: "Can I cancel a class at any time?",
        answer:
          "You can cancel a class up to 24 hours before the start time. If you paid for your classes, your fees for the cancelled classes will be credited to you, which you can use to pay other tutors. You can also withdraw the credits and get a refund.",
      },
      {
        id: 11,
        title:
          "Do I need to pay for all classes in advance?",
        answer:
          "No, you don't need to pay for all classes in advance. You can pay for one, two or any number of classes. But every individual class you will conduct with a tutor needs to be paid for in advance.",
      },
      {
        id: 12,
        title:
          "Do I need to pay extra to open a student account after registered as tutor?",
        answer:
          "No, there is no additional cost to create your student account once you registered. You can simply create your student profile and start learning after finding a tutor.",
      },
      {
        id: 13,
        title:
          "Do I need to pay extra to open a tutor account after registered as student?",
        answer:
          "No, there is no additional cost to create your tutor account once you registered. You can simply create your tutor profile and start teaching.",
      },
    ],
    sort: 1,
    abc: true,
  },
  {
    id: 2,
    subTitle: "Search",
    titless: [
      {
        id: 20,
        title: "How will I find tutors?",
        answer:
          "You can use TuteAir smart filtering options to find your preferred tutors. You contact them using TuteAir chat system, set suitable time and commitment. Then you are ready to learn.",
      },
      {
        id: 21,
        title: "How many tutors can I search?",
        answer: "Once you register, you can search unlimited number of tutors.",
      },
      {
        id: 22,
        title: "How many subjects can I search?",
        answer:
          "Once you register, you can search unlimited number of subjects.",
      },
    ],
    sort: 5,
  },
  {
    id: 3,
    subTitle: "Membership",
    titless: [
      {
        id: 30,
        title: "Can I be a student when I am a tutor?",
        answer:
          "Yes, you certainly can. Your membership allows you to be both or either.",
      },
      {
        id: 31,
        title: "Can I be a tutor when I am a student?",
        answer:
          "Yes, you certainly can. Your membership allows you to be both or either.",
      },
    ],
    sort: 3,
  },
  {
    id: 4,
    subTitle: "Location",
    titless: [
      {
        id: 40,
        title: "What is the location of tutors?",
        answer:
          "Our tutors are from all over the world. You can find tutors in your local area using our smart filtering options via map. Then you can start learning face to face or online. You can also find tutors from other part of the world and start learning online.",
      },
    ],
    sort: 4,
  },
  {
    id: 5,
    subTitle: "Tuition Payment",
    titless: [
      {
        id: 50,
        title: "How will I pay my tutor?",
        answer:
          "You will pay TuteAir for your classes with preferred tutors. TuteAir will pay tutors after you complete your classes and confirm the class completion.",
      },
      {
        id: 51,
        title: "What payment method TuteAir accepts?",
        answer:
          "TuteAir accepts payment by any popular card, i.e., Master Card, Visa, American Express, etc. You can also use PayPal to pay.",
      },
      {
        id: 52,
        title: "How will the tutor get payment?",
        answer:
          "TuteAir will pay tutors using their preferred payment method after the end of the calendar month. This will be the total number of completed classes in that month.",
      },
    ],
    sort: 6,
  },
  {
    id: 6,
    subTitle: "Tutoring",
    titless: [
      {
        id: 60,
        title: "Can I teach multiple subjects?",
        answer:
          "Yes, you can teach as many subjects as you want. There is no additional cost to teach multiple subjects.",
      },
      {
        id: 61,
        title: "Can I cancel a tutor?",
        answer:
          "Yes, you can cancel with a tutor at anytime you prefer based on your negotiation with tutors. You can start searching for new tutors at any time.",
      },
      {
        id: 62,
        title: "Can I cancel teaching a student?",
        answer:
          "Yes, you can cancel at anytime based on your negotiation with students.",
      },
      {
        id: 63,
        title: "How will students find tutor?",
        answer:
          "Students can find tutors using “Find Tutors” option. Then students can send message to tutor and start discussion.",
      },
      {
        id: 64,
        title: "How will tutor take online classes?",
        answer:
          "Tutors will take online classes in TuteAir's video call conferencing with screen sharing options and other functionalities.",
      }
    ],
    sort: 2,
  },
];

export const faqList = faqList2;
