import React, { useEffect, useState } from "react";
import { useWindowWidth } from "../useWindowWidth";
import ReactDatePicker from "react-datepicker";
import { Col, Label } from "reactstrap";

export const TuitionDate = ({
  tuitionStartDate,
  handleStartDate,
  tuitionEndDate,
  handleEndDate,
  today,
  tomorrow,
  disabled
}) => {
  const width = useWindowWidth();

  return (
    <>
      <Col lg={2}>
        <Label className="col-form-label asterisk" htmlFor="">
          Tuition Start
        </Label>
      </Col>
      <Col lg={3}>
        <ReactDatePicker
          className="form-control digits"
          selected={tuitionStartDate}
          onChange={handleStartDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          minDate={today}
          dropdownMode="select"
          dateFormat="dd MMMM yyyy"
          disabled={disabled}
        />
      </Col>
      <Col lg={2} className={`${width <= 800 ? "text-start" : "text-end"}`}>
        <Label className="col-form-label asterisk" htmlFor="">
          Tuition End
        </Label>
      </Col>
      <Col lg={3}>
        <ReactDatePicker
          className="form-control digits"
          selected={tuitionEndDate}
          onChange={handleEndDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          minDate={tuitionStartDate}
          dropdownMode="select"
          dateFormat="dd MMMM yyyy"
          disabled={disabled}
        />
      </Col>
    </>
  );
};