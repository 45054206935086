import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Rating from "react-rating";
import { Col, Label, Row } from "reactstrap";
import { AddNotification } from "../../../src/Services/Notification.Service";
import { ProfilePictureRootURL } from "../../api";
import man from "../../assets/images/avtar/man.png";
import { useAddReviewMutation } from "../../redux/features/review/reviewsApi";
import { toastMessage } from "../../redux/utils/tuteairCommon";

const AddReviewContain = (props) => {
  const [addReview, { isSuccess, isLoading }] = useAddReviewMutation();

  const [behaviour, setBehaviour] = useState(5);
  const [intelligence, setIntelligence] = useState(5);
  const [iq, setIQ] = useState(5);
  const [readOnly1, setReadOnly1] = useState(false);
  const [readOnly2, setReadOnly2] = useState(false);
  const [tutorBehaviour, setTutorBehaviour] = useState(5);
  const [capability, setCapability] = useState(5);
  const [proffesionalism, setProffesionalism] = useState(5);

  const [myReview, setMyReview] = useState({});
  const [reviewText, setReviewText] = useState("");
  const [tutorPhoto, setTutorPhoto] = useState("");
  const [studentPhoto, setStudentPhoto] = useState("");
  const [studentReply, setStudentReply] = useState({});
  useEffect(() => {
    if (props.data) {
      fnMemberRatings();
      var myReview = props.data?.filter(
        (r) => r.fromUserId === props.fromUserId && r.parentReviewId === null
      )[0];

      setMyReview(myReview);

      var stReply = props.data?.filter(
        (r) => r.toUserId === props.fromUserId && r.parentReviewId !== null
      )[0];

      setStudentReply(stReply);

      setTutorPhoto(
        props.trThumb === ""
          ? man
          : props.trThumb.includes("googleusercontent")
          ? props.trThumb
          : ProfilePictureRootURL + props.trThumb
      );

      setStudentPhoto(
        props.stThumb === ""
          ? man
          : props.stThumb.includes("googleusercontent")
          ? props.stThumb
          : ProfilePictureRootURL + props.stThumb
      );
    }
  }, [props.data]);

  const fnMemberRatings = () => {
    if (props.ratings?.studentRating) {
      console.log(props.ratings?.studentRating);
      setBehaviour(props.ratings.studentRating?.behaviour);
      setIntelligence(props.ratings.studentRating?.intelligence);
      setIQ(props.ratings.studentRating?.iq);
      setReadOnly2(true);
    }
    if (props.ratings?.tutorRating) {
      console.log(props.ratings?.tutorRating);

      setTutorBehaviour(props.ratings.tutorRating?.behaviour);
      setCapability(props.ratings.tutorRating?.capability);
      setProffesionalism(props.ratings.tutorRating?.proffesionalism);
      setReadOnly1(true);
    }
  };

  const handleReview = (e) => {
    e.preventDefault();

    var toUserId = "";
    if (props.isStudent) toUserId = props.tutorId;
    else if (props.isTutor) toUserId = props.studentId;

    if (reviewText === "") {
      toastMessage("Review Field Empty!!", "warn");
      return;
    }

    var tutorRating = {
      behaviour: tutorBehaviour,
      proffesionalism: proffesionalism,
      capability: capability,
      ratedAt: new Date(),
      updatedAt: new Date(),
    };

    var studentRating = {
      behaviour: behaviour,
      intelligence: intelligence,
      iq: iq,
      ratedAt: new Date(),
      updatedAt: new Date(),
    };

    addReview({
      data: {
        fromUserId: props.fromUserId,
        toUserId: toUserId,
        tuitionId: props.tuitionId,
        reviewText,
        memberType: props.isTutor ? "tutor" : "student",
        tutorRating,
        studentRating,
      },
    });

    const notifyObj = {
      receiver: toUserId,
      title: "You have got a new review",
      description: "Someone reviewed your tuition",
      context: process.env.PUBLIC_URL + "/student-tuitions",
      isPublic: false,
    };

    AddNotification(notifyObj);
  };

  useEffect(() => {
    if (isSuccess) {
      setMyReview({
        reviewId: "Ok",
        createdAt: new Date(),
        reviewText,
      });
      setReadOnly1(true);
      setReadOnly2(true);
      toastMessage("Successfully given review", "success");

      setReviewText("");
    }
  }, [isSuccess]);

  return (
    <Fragment>
      <Row
        style={{
          border: "1px solid #8080804d",
          borderRadius: "5px",
          padding: "15px",
          background: "#cdccca1a",
        }}
        className="my-1"
      >
        <Col md="12" className="py-2 row">
          <Col md="1" className="text-start">
            <img
              src={props.isStudent ? studentPhoto : tutorPhoto}
              alt=""
              className="rounded-circle media"
              style={{ borderRadius: "50px", height: "60px" }}
            />
          </Col>
          <Col md="11">
            <div>
              <div className="row">
                <div className="col-md-8 row">
                  <Label className="text-bold mb-0">
                    <strong>Your Review & Rating</strong>
                    <label className="py-0 px-2">
                      {myReview?.reviewId && (
                        <small className="px-0">
                          {moment(myReview.createdAt).format(
                            "Do MMMM YYYY, h:mm a"
                          )}
                        </small>
                      )}{" "}
                    </label>
                  </Label>
                  {props.isStudent && (
                    <table
                      className="table table-borderless"
                      style={{ width: "280px" }}
                    >
                      <tr>
                        <td className="py-0">
                          <span>Behaviour </span>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly1}
                            initialRating={tutorBehaviour}
                            emptySymbol="fa fa-star-o fa-1x"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setTutorBehaviour(rate)}
                          ></Rating>
                          <span>{tutorBehaviour}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-0">
                          <span>Proffesionalism </span>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly1}
                            initialRating={proffesionalism}
                            emptySymbol="fa fa-star-o fa-1x text-default"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setProffesionalism(rate)}
                          ></Rating>
                          <span>{proffesionalism}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-0">
                          <span>Capability </span>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly1}
                            initialRating={capability}
                            emptySymbol="fa fa-star-o fa-1x text-default"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setCapability(rate)}
                          ></Rating>
                          <span>{capability}</span>
                        </td>
                      </tr>
                    </table>
                  )}
                  {!props.isStudent && (
                    <table
                      className="table table-borderless"
                      style={{ width: "280px" }}
                    >
                      <tr>
                        <td className="py-0">
                          <span>Behaviour </span>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly2}
                            initialRating={behaviour}
                            emptySymbol="fa fa-star-o fa-1x"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setBehaviour(rate)}
                          ></Rating>
                          <span>{behaviour}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-0">
                          <span>Intelligence </span>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly2}
                            initialRating={intelligence}
                            emptySymbol="fa fa-star-o fa-1x text-default"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setIntelligence(rate)}
                          ></Rating>
                          <span>{intelligence}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-0">
                          <label>IQ </label>
                        </td>
                        <td className="py-0">
                          <Rating
                            readonly={readOnly2}
                            initialRating={iq}
                            emptySymbol="fa fa-star-o fa-1x"
                            fullSymbol="fa fa-star fa-1x text-warning"
                            onChange={(rate) => setIQ(rate)}
                          ></Rating>
                          <span className="">{iq}</span>
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div className="col-md-4 text-end">
                  <button
                    disabled={myReview?.reviewId || isLoading ? true : false}
                    className={
                      myReview?.reviewId
                        ? "btn px-2 py-1 mb-1 btn-primary d-none"
                        : "btn px-2 py-1 mb-1 btn-success d-none"
                    }
                    // onClick={(e) => !myReview?.reviewId ? handleReview(e) : "" }
                  >
                    <span>
                      <i className="fa fa-reply"></i>
                      {myReview?.reviewId ? " Reviewed" : " Review"}
                    </span>
                  </button>
                </div>
              </div>
              {!myReview?.reviewId && (
                <div>
                  <textarea
                    name=""
                    id=""
                    className="form-control"
                    rows="3"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                  ></textarea>
                </div>
              )}
              <div className={"pt-1 "} style={{ textAlign: "-webkit-right" }}>
                <div className="col-md-4 text-end">
                  <button
                    disabled={myReview?.reviewId || isLoading ? true : false}
                    className={
                      myReview?.reviewId
                        ? "btn px-2 py-1 mb-1 btn-primary d-none"
                        : "btn px-2 py-1 mb-1 btn-success"
                    }
                    onClick={(e) =>
                      !myReview?.reviewId ? handleReview(e) : ""
                    }
                  >
                    <span>
                      <i className="fa fa-reply"></i>
                      {myReview?.reviewId ? " Reviewed" : " Review"}
                    </span>
                  </button>
                </div>
              </div>
              <p className="mb-0">
                {myReview?.reviewId && <span>{myReview.reviewText}</span>}
              </p>
            </div>
          </Col>
        </Col>
        {myReview?.reviewId && (
          <Col className="offset-md-1 col-md-11">
            {studentReply?.reviewId && (
              <div
                className="border-top py-2"
                // style={{
                //   border: "1px solid gray",
                //   borderRadius: "5px",
                //   padding: "15px",
                //   background: "#fafcfa",
                // }}
              >
                <div className="row">
                  <div className="px-5 col-md-11">
                    <Label className="border-bottom">
                      <span>{props.name} Response </span>
                    </Label>
                    <Label className="px-2">
                      {studentReply?.reviewId && (
                        <small>
                          {moment(studentReply.createdAt).format(
                            "Do MMMM YYYY, h:mm a"
                          )}
                        </small>
                      )}{" "}
                    </Label>
                    <p>
                      {studentReply?.reviewId && (
                        <span>{studentReply.reviewText}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-4 text-end d-none">
                    <button className="btn px-1 py-0 btn-primary">
                      <span>
                        <i className="fa fa-reply"></i> Reply
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default AddReviewContain;
