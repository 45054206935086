import React, { useEffect, useState } from "react";
import { useWindowWidth } from "./useWindowWidth";
import { useSelector } from "react-redux";

import { Col, Input, InputGroup, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useEditConversationMutation } from "../../../../redux/features/chat/conversations/conversationsApi";
export default function SendChat(props) {
  //console.log(props);
  const width = useWindowWidth();
  const [editConversation, { isSuccess }] = useEditConversationMutation();
  const [messageInput, setMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { selectedUser } = useSelector((state) => state.conversations) || {};
  const { user: loggedInUser } = useSelector((state) => state.auth);

  // console.log(isDeactive);

  useEffect(() => {
    if (isSuccess) {
      setMessageInput("");
      setIsLoading(false);
    }
  }, [isSuccess]);

  const handleMessagePress = (e) => {
    if (e.key === "Enter" || e === "send") {
      if (messageInput.length > 0 && props.conversationId) {
        setIsLoading(true);
        if (!isLoading) {
          editConversation({
            sender: loggedInUser,
            id: props.conversationId,
            data: {
              message: messageInput,
            },
          });
        } else {
          console.warn("something went wrong!!");
        }

        var container = document.querySelector(".chat-history");
        setTimeout(function () {
          container.scrollBy({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }, 150);
      }
    }
  };


  return (
    <div className="chat-message clearfix">
      <Row className={`${width <= 500 ? "fixed-bottom" : ""}`}>
        <Col xl="12" className="d-flex">
          {!selectedUser?.isAccountDeactive &&
            !selectedUser?.isAccountDelete ? (
            <>
              <InputGroup className="text-box">
                <Input
                  style={{ height: '70px' }}
                  type="text"
                  className="form-control input-txt-bx"
                  placeholder="Type a message......"
                  value={messageInput}
                  onKeyPress={(e) => handleMessagePress(e)}
                  onChange={(e) => setMessageInput(e.target.value)}
                />
                <Btn
                  style={{ height: '70px' }}
                  attrBtn={{
                    disabled: isLoading,
                    color: "primary",
                    onClick: (e) => handleMessagePress("send"),
                  }}
                >
                  {isLoading === true ? "Sending" : "Send"}
                </Btn>
              </InputGroup>
            </>
          ) : (
            <>
              <InputGroup className="text-box">
                <div
                  className="form-control f-16 text-center"
                  style={{ placeSelf: "center" }}
                >
                  The account is currently unavailable
                </div>
              </InputGroup>
            </>
          )}

          {/*  */}
        </Col>
      </Row>
    </div>
  );
}
