import React, { useState } from 'react'
import AddFreeClassModal from './AddFreeClassModal';

const AddFreeClassButton = () => {
  const [freeClassModal, setFreeClassModal] = useState(false);
  const toggleFreeClassModal = () => setFreeClassModal(!freeClassModal);
  const handleFreeClassModal = (e) => {
    e.preventDefault();
    toggleFreeClassModal();
  };
  
  return (
    <>
      <button
        className="btn align-items-center position-relative overflow-visible px-4"
        onClick={(e) => handleFreeClassModal(e)}
        style={{backgroundColor: "#90e0ef", color: "#0077b6"}}
      >
        <span>Add Free Class</span>
      </button>
      {freeClassModal && (
        <AddFreeClassModal
          isOpen={freeClassModal}
          toggler={toggleFreeClassModal}
        />
      )}
    </>
  )
}

export default AddFreeClassButton