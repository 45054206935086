import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, Col, Form, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";
import { authHeader } from "../../../../Services/AuthVerify";
import EducationModal from "./EducationModal";

import axios from "axios";
import { DocumentRootURL, EducationInfoDeleteApi } from "../../../../api";
import { useGetMemberInfoByIdQuery } from "../../../../redux/features/member/memberApi";
import { toastMessage } from "../../../../redux/utils/tuteairCommon";

const TutorEduInfo = () => {
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [singleEduData, setSingleEduData] = useState({});
  const [educations, setEducations] = useState([]);

  const toggle = () => setModal(!modal);

  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo, refetch } = useGetMemberInfoByIdQuery(user);
  useEffect(() => {
    if (memberInfo) {
      setEducations(memberInfo?.educations);
    }
    return () => {
      setEducations([]);
    };
  }, [memberInfo]);

  const handleAddNewButton = () => {
    setEdit(false);
    setTitle("Add New Education Info");
    setSingleEduData({
      course: "",
      degree: "",
      institute: "",
      grade: "",
      passingYear: "",
      inProgress: false,
      attachment: [],
    });
    toggle();
  };
  const handleEduInfoEdit = async (item) => {
    setEdit(true);
    const attachURL = DocumentRootURL + item.attachments.id;
    setTitle("Update New Education Info");
    var singleData = {
      id: item.id,
      course: item.course,
      degree: item.degree,
      institute: item.institute,
      grade: item.marks,
      passingYear: item.passingYear,
      attachment: item.attachments.id,
      attachmentURL: attachURL,
      attachmentOriginalName: item.attachments.name,
      newAttachmentInfo:
        item?.attachments?.attachmentInfo?.attachmentInfo?.secure_url,
      inProgress: JSON.parse(item.isRunning),
    };

    setSingleEduData(singleData);
    setModal(true);
  };

  const handleEduInfoDelete = async (id) => {
    if (window.confirm("Are you sure you wish to delete this info?")) {
      const res = await axios.delete(EducationInfoDeleteApi + id, {
        headers: {
          Authorization: authHeader(),
        },
      });
      if (res.status === 201) {
        toastMessage("Education Info Deleted successully", "success");
        refetch();
      } else {
        alert("something went wrong!!");
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader style={{ height: "1px" }}>
              <Row className="align-items-center">
                <Col md="8"></Col>
                <Col md="4" style={{ position: "relative", bottom: "18px" }}>
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: handleAddNewButton,
                    }}
                  >
                    Add New
                  </Btn>

                  {modal && (
                    <EducationModal
                      title={title}
                      isOpen={modal}
                      toggler={toggle}
                      singleEduData={singleEduData}
                      edit={edit}
                    />
                  )}
                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>
            <div className="table-responsive-sm">
              <Table className="table-border-horizontal" responsive>
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th scope="col" style={{ width: "25%" }}>
                      {"Institute"}
                    </th>
                    <th
                      scope="col"
                      style={{ width: "25%" }}
                      className="text-nowrap"
                    >
                      {"Degree / Courses"}
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                      {"Attachments"}
                    </th>
                    <th scope="col" style={{ width: "10%" }}>
                      {"Result"}
                    </th>
                    <th
                      scope="col"
                      style={{ width: "15%" }}
                      className="text-nowrap"
                    >
                      {"Completion Year"}
                    </th>
                    <th scope="col" style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {educations?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={7}>No Education Info Found</td>
                    </tr>
                  )}
                  {educations?.length > 0 &&
                    educations
                      ?.slice()
                      ?.sort((x, y) => {
                        return y.passingYear - x.passingYear;
                      })
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td className="">
                            <span className="text-left">{item.institute}</span>
                          </td>
                          <td>
                            <p className="mb-0 px-1">{item?.degree?.label}</p>
                            <p
                              className="mb-0 px-1"
                              style={{ color: "#488374" }}
                            >
                              <small>{item?.course?.label}</small>
                            </p>
                          </td>
                          <td>
                            {item.attachments && (
                              <a
                                style={{ cursor: "pointer" }}
                                className="text-left text-primary"
                                // href={DocumentRootURL + item.attachments.id}
                                href={
                                  item.attachments?.attachmentInfo
                                    ?.attachmentInfo?.secure_url ||
                                  DocumentRootURL + item.attachments.id
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.attachments?.attachmentInfo
                                  ?.attachmentInfo?.secure_url
                                  ? item?.attachments?.attachmentInfo?.attachmentInfo?.secure_url
                                    ?.split("/")
                                    .pop()
                                  : item.attachments.name &&
                                  item.attachments.name.substring(
                                    item.attachments.name.indexOf("_") + 1
                                  )}

                                {/* {item.attachments.name &&
                                  item.attachments.name.substring(
                                    item.attachments.name.indexOf("_") + 1
                                  )} */}
                              </a>
                            )}
                          </td>
                          <td>{item.marks}%</td>
                          <td>
                            {item.passingYear}
                            {item.isRunning &&
                              item.isRunning === "true" &&
                              new Date().getFullYear() <=
                              parseInt(item.passingYear)
                              ? " (In Progress)"
                              : ""}
                          </td>
                          <td className="px-1 py-0 d-flex gap-1 align-items-center">
                            <button
                              className="btn px-1 py-0 btn-link  fs-3 mt-1"
                              onClick={() => handleEduInfoEdit(item)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                            <button
                              className="btn px-1 py-0 btn-link text-danger fs-3"
                              onClick={() => handleEduInfoDelete(item.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          </Card>

          <Form className="needs-validation d-none" noValidate>
            <H5>Tutor Education Will be there</H5>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TutorEduInfo;
