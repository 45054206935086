import React from "react";
import { X } from "react-feather";
import { ModalHeader } from "reactstrap";

const TuitionModalHeader = ({
  title,
  toggler
}) => {
  return (
    <ModalHeader style={{ placeContent: "center" }}>
      <div>{title}</div>
      <button
        onClick={toggler}
        style={{ backgroundColor: "white" }}
        className="rounded border-0 text-danger"
      >
        <X></X>
      </button>
    </ModalHeader>
  );
}

export default TuitionModalHeader;
