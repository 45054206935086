import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Clock } from "react-feather";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";

import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import TutorLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";
import StudentCardContain from "./StudentCard";
import TuitionFeesFilter from "../../FindTutors/ListView/TuitionFeesFilter";
import {
  filterByTutorName,
  filterByAge,
  filterByLanguage,
  filterByPrice,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
} from "../CommonFilter.jsx";

export default function ListView() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");
  const {
    name,
    setName,
    age,
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    priceFilter,
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const { accountType } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (tutors.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  });

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredStudents = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "tutor" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );
  const showStudents = allFilteredStudents.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredStudents.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const fetchFilteredTutors = async () => {
    setIsLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.name) {
      filteredTutors = await filterByTutorName(filteredTutors, filters.name);
    }

    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor?.gender?.toLowerCase() == filters.gender.toLowerCase()
      );
    }
    if (filters.age) {
      filteredTutors = await filterByAge(filteredTutors, filters.age);
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) =>
          tutor.media.picture.length > 0 ||
          tutor?.media?.profilePicture?.imageInfo?.secure_url?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }

    // filtering ends here
    setTutorsToShow(filteredTutors);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  useEffect(() => {
    setIsLoading(true);
    GetAllTutors();
    setIsLoading(false);
  }, []);

  const updateFilter = () => {
    // setIsLoading(true);
    const addedFilter = {};

    setIsReset(false);

    if (name != " ") {
      addedFilter.name = name;
    }

    if (age.length != 0) {
      addedFilter.age = age;
    }

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(priceFilter).length) {
      addedFilter.priceFilter = priceFilter;
    }

    //console.log(addedFilter);

    setFilters(addedFilter);
  };
  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTutors();
  };

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8} className="tabResponsive">
            {isLoading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {allFilteredStudents?.length} matched student's based on your
                search criteria
              </CardTitle>
            )}

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  isLoading={isLoading}
                  size={25}
                  aria-label="isLoading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>{<StudentCardContain showStudents={showStudents} />}</span>
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">Basic</AccordionHeader>
                <AccordionBody accordionId="1">
                  <BasicFilter setFilters={setFilters} isReset={isReset} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">Subjects</AccordionHeader>
                <AccordionBody accordionId="2">
                  <SubjectsFilter setFilters={setFilters} isReset={isReset} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">
                  Student's Location
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  <TutorLocationFilter
                    setFilters={setFilters}
                    isReset={isReset}
                  />
                </AccordionBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionHeader targetId="4">Student's Price</AccordionHeader>
                <AccordionBody accordionId="4">
                  <TuitionFeesFilter
                    setFilters={setFilters}
                    isReset={isReset}
                  />
                </AccordionBody>
              </AccordionItem>
              {/* 
                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Schedule</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <ScheduleFilter />
                  </AccordionBody>
                </AccordionItem>  */}
            </UncontrolledAccordion>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              {parseFloat(priceFilter.min) > parseFloat(priceFilter.max) ? (
                <Button
                  disabled
                  color="primary"
                  className="mb-3 "
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {allFilteredStudents?.length !== 0 && (
          <div className="mt-5 mb-5 pagination">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              //disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </Col>
    </Fragment>
  );
}
