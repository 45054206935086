import moment from "moment";
import React, { useState } from "react";
import { RRule } from "rrule";
import { axiosRequest } from "../../redux/utils/axios-utils";
import Context from "./index";

const ClassSchedulerProvider = (props) => {
  const [scheduleEventList, setScheduleEventList] = useState([]);
  const [myTuitionList, setMyTuitionList] = useState([]);
  const [tuitionList, setTuitionList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [tutorTimeTable, setTutorTimeTable] = useState([]);
  const [tutorClassList, setTutorClassList] = useState([]);
  const [studentClassList, setStudentClassList] = useState([]);
  const [isCompleteClassList, setIsCompleteClassList] = useState(false);

  const getTuitionList = async () => {
    var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    var swMember = localStorage.getItem("swMember");
    var res = await axiosRequest({
      url: "/tuition/all-classes/" + _tuteair?._id,
      method: "get",
    });
    if (res.data?.length > 0) {
      setTuitionList(res.data); // for my tuition page
      filterMyTuitionList(swMember, _tuteair._id, res.data);
    }
  };

  const filterMyTuitionList = (memberType, userId, eventList = []) => {
    if (memberType === "student") {
      setMyTuitionList(eventList.filter((x) => x.student?._id === userId));
    } else {
      setMyTuitionList(eventList.filter((x) => x.tutor?._id === userId));
    }
  };

  const getAllScheduleEvents = async () => {
    try {
      const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
      const swMember = localStorage.getItem("swMember");
      const { data } = await axiosRequest({
        url: `/tuition/all-classes/${_tuteair?._id}`,
        method: "get",
      });

      if (!data?.length) return;

      const eventList = data?.flatMap((item) =>
        item?.scheduledClasses?.map((s) => {
          const subjectName = item.interestedSubjects?.subjects
            ?.map((x) => x.name)
            ?.join(" ");
          return {
            tuitionId: item._id,
            classId: s._id,
            title: subjectName ? subjectName : s?.title,
            studentId: item.student?._id,
            tutorId: item.tutor?._id,
            tutorName: `${item.tutor?.firstName} ${item.tutor?.lastName}`,
            studentName: `${item.student?.firstName} ${item.student?.lastName}`,
            date: new Date(s.date),
            start: new Date(s.startTime),
            end: new Date(s.endTime),
            classStatus: s.classStatus,
            tuitionStatus: item.status,
            isPaid: s.isPaid,
            isFreeClass: s?.isFreeClass === true || s?.title ? true : false,
            completionStatus: s.completionStatus ?? {
              isCompleteByStudent: false,
              isCompleteByTutor: false,
            },
            tuitionCloseStatus: item.tuitionCloseStatus ?? {
              isCloseByStudent: false,
              isCloseByTutor: false,
            },
            // reschedule_reason: s.reschedule_reason,
            isRescheduled: s.isRescheduled,
            // oldRescheduled: s.oldRescheduled,
            confirmedTuitionFee: item.confirmedTuitionFee,
            currency: item?.currency?.name,
            timeTableHistory: item?.timeTableHistory,
            tuitionPlace: item?.tuitionPlace,
            cancel: s.cancel,
            feedback: s.feedback,
            reschedule_infos: s.reschedule_infos,
          };
        })
      );

      setMyTuitionList(data); // for my tuition page
      setScheduleEventList(eventList);
      filterClassList(swMember, _tuteair._id, eventList);
    } catch (error) {
      console.error(error);
    }
  };

  const getRescheduleFromTutor = async (selectedId) => {
    try {
      const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
      const swMember = localStorage.getItem("swMember");
      const { data } = await axiosRequest({
        // url: `/tuition/all-classes/${uId}`,
        url: `/tuition/all-classes/${selectedId}`,
        method: "get",
      });

      if (!data?.length) return;

      const eventList = data?.flatMap((item) =>
        item?.scheduledClasses?.map((s) => {
          const subjectName = item.interestedSubjects?.subjects
            ?.map((x) => x.name)
            ?.join(" ");
          return {
            tuitionId: item._id,
            classId: s._id,
            title: subjectName,
            studentId: item.student?._id,
            tutorId: item.tutor?._id,
            tutorName: `${item.tutor?.firstName} ${item.tutor?.lastName}`,
            studentName: `${item.student?.firstName} ${item.student?.lastName}`,
            date: new Date(s.date),
            start: new Date(s.startTime),
            end: new Date(s.endTime),
            classStatus: s.classStatus,
            isPaid: s.isPaid,
            completionStatus: s.completionStatus ?? {
              isCompleteByStudent: false,
              isCompleteByTutor: false,
            },
            // reschedule_reason: s.reschedule_reason,
            isRescheduled: s.isRescheduled,
            // oldRescheduled: s.oldRescheduled,
            confirmedTuitionFee: item.confirmedTuitionFee,
            currency: item?.currency?.name,
            timeTableHistory: item?.timeTableHistory,
            tuitionPlace: item?.tuitionPlace,
            cancel: s.cancel,
            feedback: s.feedback,
            reschedule_infos: s.reschedule_infos,
          };
        })
      );

      var allSelectedStudentEventList = eventList?.filter(
        (item2) => item2?.studentId === selectedId
      );
      setScheduleEventList(allSelectedStudentEventList);
      // filterClassList(swMember, _tuteair._id, eventList);
      // filterClassList(swMember, selectedId, allSelectedStudentEventList);
    } catch (error) {
      console.error(error);
    }
  };

  const getMemberSchedule = async (tutorId) => {
    var timeTableList = [];
    var res = await axiosRequest({
      url: "/newMember/getMemberSchedule/" + tutorId,
      method: "get",
    });
    var data = res?.data.tutorSchedules;
    if (data.length > 0) {
      let tutorScheduleList = [];
      data.map((day) => {
        tutorScheduleList.push({
          ...day,
          isChecked: false,
        });
      });
      timeTableList = tutorScheduleList;
    }
    return timeTableList;
  };

  function getWeeklySchedules(date, timeTable) {
    var weekDay = moment(date).format("dddd");
    var startEnd = null;

    timeTable?.map((x) => {
      if (x.dayName.toUpperCase() === weekDay.substring(0, 3).toUpperCase()) {
        startEnd = formatStartEndTime(date, x);
      }
      return startEnd;
    });
    return startEnd;
  }

  function formatStartEndTime(date, time) {
    // later startTime 11:45PM  and endTime 03:AM, then endDate will be next date and startDate remain same
    var dt1 = moment(date).format("YYYY-MM-DD");
    var _start = moment(time?.start).format("LT");
    var _end = moment(time?.end).format("LT");

    var start = moment(`${dt1} ${_start}`, "YYYY-MM-DD HH:mm aa").format();
    var end = moment(`${dt1} ${_end}`, "YYYY-MM-DD HH:mm aa").format();

    return { start, end };
  }

  const getByWeekDays = (timeTable) => {
    let weekDays = [];
    timeTable?.map((item) => {
      if (item.dayName === "sat") weekDays.push(RRule.SA);
      else if (item.dayName === "sun") weekDays.push(RRule.SU);
      else if (item.dayName === "mon") weekDays.push(RRule.MO);
      else if (item.dayName === "tue") weekDays.push(RRule.TU);
      else if (item.dayName === "wed") weekDays.push(RRule.WE);
      else if (item.dayName === "thu") weekDays.push(RRule.TH);
      else if (item.dayName === "fri") weekDays.push(RRule.FR);
    });

    // if (timeTable.hasOwnProperty("sat")) weekDays.push(RRule.SA);
    return weekDays;
  };

  const getAllSchedulesByRRule = ({
    startDate,
    endDate,
    repeat,
    timeTable,
  }) => {
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    // console.log("repeat", repeat);
    // console.log("timeTable", timeTable);
    let byWeeks = getByWeekDays(timeTable);
    // console.log("byWeeks", byWeeks);
    const rule = new RRule({
      freq:
        repeat?.type === "weekly"
          ? RRule.WEEKLY
          : repeat?.type === "daily"
            ? RRule.DAILY
            : repeat?.type === "monthly"
              ? RRule.MONTHLY
              : RRule.YEARLY,
      interval: repeat?.interval,
      byweekday: repeat?.type === "weekly" ? byWeeks : [],
      dtstart: new Date(startDate),
      until: new Date(endDate),
    });
    // console.log("rule", rule);

    var dates = rule.all();
    // console.log("dates", dates);
    return dates;
  };

  const filterClassList = (memberType, userId, eventList = []) => {
    if (memberType === "student") {
      setClassList(eventList.filter((x) => x.studentId === userId));
      setStudentClassList(
        eventList.filter(
          (x) =>
            x.studentId === userId &&
            x.isPaid &&
            !x.completionStatus.isCompleteByStudent &&
            // new Date(x.start) <= new Date() &&
            new Date(x.end) <= new Date() && //from now on class complete status will be available after class
            typeof x.cancel !== "object"
        )
      );
    } else {
      setClassList(eventList.filter((x) => x.tutorId === userId));
      setTutorClassList(
        eventList.filter(
          (x) =>
            x.tutorId === userId &&
            x.isPaid &&
            !x.completionStatus.isCompleteByTutor &&
            // new Date(x.start) <= new Date() &&
            new Date(x.end) <= new Date() && //from now on class complete status will be available after class
            typeof x.cancel !== "object"
        )
      );
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        scheduleEventList,
        setScheduleEventList,
        getMemberSchedule,
        getAllScheduleEvents,
        getRescheduleFromTutor,
        tutorTimeTable,
        setTutorTimeTable,
        getAllSchedulesByRRule,
        filterClassList,
        classList,
        getTuitionList,
        myTuitionList,
        filterMyTuitionList,
        tuitionList,
        setTuitionList,
        tutorClassList,
        studentClassList,
        isCompleteClassList,
        setIsCompleteClassList,
        setStudentClassList,
        setTutorClassList,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default ClassSchedulerProvider;

// res.data?.map((item) => {
//   item?.schedules?.map((item2) => {
//     var start1 = item2.start.toLocaleString();
//     var end1 = item2.start.toLocaleString();
//     var date1 = item2.date.toLocaleString();
//     eventList.push({
//       _id: item._id,
//       title: item2.title,
//       participants: item.participants,
//       schedules: item.schedules,
//       tuition: item.tuition,
//       date: new Date(date1),
//       start: new Date(start1),
//       end: new Date(end1),
//       eventCreator: item.eventCreator,
//     });
//   });
// });
