import axios from "axios";

export const saveSingleImageToCloudinary = async (photo, publicId) => {
  try {
    let imageUrl = {
      imageInfo: {},
      storageProvider: "",
    };
    let uploadedUrl = null;
    // const { originalWidth, originalHeight } = await getOriginalHeightWidth(
    //   photo
    // );

    // const resizedImage = await minimizeSize(photo);

    // if (!(resizedImage instanceof Blob || resizedImage instanceof File)) {
    //   throw new Error("Resized image is not valid.");
    // }

    const formData = new FormData();

    formData.append("file", photo);
    formData.append(
      "upload_preset",
      `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
    );
    formData.append(
      "public_id",
      `${publicId}/${photo.name.replace(/\.[^/.]+$/, "")}`
    );

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
      formData
    );

    if (response?.status === 200) {
      imageUrl = {
        imageInfo: {
          public_id: response?.data?.public_id,
          secure_url: response?.data?.secure_url,
          format: response?.data?.format,
          bytes: response?.data?.bytes,
          // originalWidth,
          // originalHeight,
        },
        storageProvider: "cloudinary",
      };
    }

    uploadedUrl = response?.data?.secure_url;
    return { imageUrl, uploadedUrl };
  } catch (error) {
    console.error("Error saving image to Cloudinary:", error);
    throw error;
  }
};

export const saveSingleAttachmentToCloudinary = async (
  attachment,
  publicId
) => {
  try {
    let attachmentUrl = {
      attachmentInfo: {},
      storageProvider: "",
    };
    let uploadedUrl = null;

    const formData = new FormData();

    formData.append("file", attachment);
    formData.append(
      "upload_preset",
      `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
    );
    formData.append(
      "public_id",
      `${publicId}/${attachment.name?.replace(/\s+/g, "_")}`
    );

    const isPDF = attachment.type === "application/pdf";

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${
        process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
      }/${isPDF ? "raw" : "image"}/upload`,
      formData
    );

    if (response?.status === 200) {
      attachmentUrl = {
        attachmentInfo: {
          public_id: response?.data?.public_id,
          secure_url: response?.data?.secure_url,
          format: response?.data?.format,
          bytes: response?.data?.bytes,
        },
        storageProvider: "cloudinary",
      };
    }

    uploadedUrl = response?.data?.secure_url;
    return { attachmentUrl, uploadedUrl };
  } catch (error) {
    console.error("Error saving PDF file to Cloudinary:", error);
    throw error;
  }
};
