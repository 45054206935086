import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useGetConversationsQuery } from "../../../redux/features/chat/conversations/conversationsApi";
import { useGetRescheduledClassesByTutorIdQuery } from "../../../redux/features/tuition/tuitionApi";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import CancelClass from "./CancelClass";
import ExtendTuition from "./Extend/ExtendTuition";
import SingleOccurence from "./Reschedule/SingleOccurence";

const ScheduleModal = (props) => {
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [title, setTitle] = useState("Calendar");
  const [reason, setReason] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [joinButtonEnabled, setJoinButtonEnabled] = useState(false);
  const [lessThan24, setLessThan24] = useState(false);
  const [lessThan30D, setLessThan30D] = useState(false);
  var paid = props?.data?.isPaid;
  const [tuitionEndIn, setTuitionEndIn] = useState(0);
  const { memberType } = useSelector((state) => state.menu) || {};

  // data for sending message
  const { user, photo } = useSelector((state) => state.auth) || {};
  let { data: conversations, isError, error } = useGetConversationsQuery(user);

  const { data, isLoading, refetch } = useGetRescheduledClassesByTutorIdQuery(
    props?.data?.tutorId,
    { skipToken: true }
  );

  const selectedConversation = conversations?.find(
    (c) => c.receiverId === props?.data?.tutorId
  );

  const handleReschedule = async (e, date) => {
    e.preventDefault();
    // if (
    //   moment(props?.data?.date).format("L") >= moment(new Date()).format("L")
    // ) {
    refetch();
    setIsRescheduled(true);
    setIsCancelled(false);
    setTitle(
      "Reschedule your (" +
        moment(props?.data?.date).format("LL") +
        ") class time"
    );
    // }
  };
  const handleExtended = (e, date) => {
    e.preventDefault();
    if (
      moment(props?.data?.date).format("L") >= moment(new Date()).format("L")
    ) {
      setIsExtended(true);
      setIsRescheduled(false);
      setIsCancelled(false);
      setTitle("Extend Tuition Time");
    }
  };
  // var user = JSON.parse(localStorage.getItem("tuteAirUser"));
  const getMeetingToken = async () => {
    var response = await axiosRequest({
      url: "/users/createMeetingJwt",
      method: "post",
      data: {
        room: props?.data?.tuitionId,
        user:
          props?.memberType === "student"
            ? props?.data?.studentId
            : props?.data?.tutorId,
      },
    });
    //console.log(response);
    if (response?.status === 201) {
      window.open(
        `https://meet.tuteair.com/${props?.data?.tuitionId}?jwt=${response?.data?.jwt}`,
        "_self"
      );
    }
  };

  useEffect(() => {
    if (props?.data) {
      const startTime = new Date(props?.data?.start); // June is represented by 5 as it is zero-based
      const endTime = new Date(props?.data?.end);

      const currentTime = new Date();

      // Calculate the time 1 hour and 30 minutes before the class starts
      const beforeClassJoinTime = new Date(
        startTime.getTime() - (1 * 60 * 60 * 1000 + 30 * 60 * 1000)
      );

      // Calculate the time 1 hour after the class ends
      const afterClassJoinTime = new Date(
        endTime.getTime() + 1 * 60 * 60 * 1000
      );

      if (
        currentTime >= beforeClassJoinTime &&
        currentTime <= afterClassJoinTime
      ) {
        setJoinButtonEnabled(true);
      } else {
        setJoinButtonEnabled(false);
      }
    }
  }, [props]);

  let closedTuitionText =
    props.data?.tuitionStatus &&
    props.data?.tuitionStatus === "CLOSED" &&
    props.data?.cancel &&
    props.data?.classStatus === "cancelled"
      ? "tuition is also closed"
      : props.data?.tuitionStatus === "CLOSED" &&
        props.data?.classStatus === "complete"
      ? "This class is completed, and tuition is also closed"
      : "This tuition is closed";

  let cancelText =
    props.data?.cancel && props.data?.classStatus === "cancelled"
      ? `This class is cancelled by ${
          props.data?.cancel.cancelBy === props.data?.userId
            ? "You"
            : props.data?.cancel.cancelBy !== props.data?.userId &&
              props.data?.memberType === "tutor"
            ? "Your Student"
            : "Your Tutor"
        } 
  on 
  ${moment(props.data?.cancel.time).format("LL")} for ${
          props.data?.cancel.reason
        }`
      : "";

  return (
    <>
      <Modal
        isOpen={true}
        toggle={props.toggle}
        className="schedule_modal"
        backdrop={"static"}
        animation={false}
      >
        <ModalHeader
          className="calender_title"
          toggle={props.toggle}
          style={{ color: (isCancelled || confirm) && "red" }}
        >
          {title}
        </ModalHeader>
        <ModalBody className={"py-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                {typeof props.data?.title === "undefined" ||
                props.data?.title === "" ? (
                  <>
                    <div className="table-responsive-sm">
                      <table className="table">
                        <tr>
                          <td className="text-center" colSpan={"3"}>
                            There was an error with this class! Please retry
                            later.
                          </td>
                        </tr>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <Row>
                      {isRescheduled && !isLoading ? (
                        // <>

                        <SingleOccurence
                          setIsCancelled={setIsCancelled}
                          setIsRescheduled={setIsRescheduled}
                          setTitle={setTitle}
                          event={props.data}
                          toggle={props.toggle}
                          selectedConversation={selectedConversation}
                          rescheduleDates={data?.rescheduleDates} // ?.filter((x) => x.tuitionId === props.data.tuitionId)
                        />
                      ) : // </>
                      isCancelled ? (
                        <>
                          <CancelClass
                            setIsCancelled={setIsCancelled}
                            setIsRescheduled={setIsRescheduled}
                            setTitle={setTitle}
                            event={props.data}
                            memberType={props.memberType}
                            toggle={props.toggle}
                            setReason={setReason}
                            reason={reason}
                            setConfirm={setConfirm}
                            confirm={confirm}
                          />
                        </>
                      ) : isExtended ? (
                        <>
                          <ExtendTuition
                            setIsCancelled={setIsCancelled}
                            setIsRescheduled={setIsRescheduled}
                            setIsExtended={setIsExtended}
                            setTitle={setTitle}
                            event={props.data}
                            toggle={props.toggle}
                            selectedConversation={selectedConversation}
                          />
                        </>
                      ) : (
                        <>
                          <Col sm="12" xl="12">
                            <div className="table-responsive-sm">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Class</th>
                                    <td>{props.data?.title}</td>
                                    <td className="text_right"></td>
                                  </tr>
                                  <tr>
                                    <th>
                                      {props.data?.memberType === "student"
                                        ? "Tutor"
                                        : "Student"}
                                    </th>
                                    <td>
                                      {props.data?.memberType === "student"
                                        ? props.data?.tutorName
                                        : props.data?.studentName}
                                    </td>
                                    <td className="text_right"></td>
                                  </tr>
                                  <tr>
                                    <th>Date</th>
                                    <td>
                                      {moment(props.data?.date).format("LL")}
                                    </td>
                                    <td className="text_right"></td>
                                  </tr>
                                  <tr>
                                    <th>Time</th>
                                    <td>
                                      {moment(props.data?.start).format("LT") +
                                        " - " +
                                        moment(props.data?.end).format("LT")}
                                    </td>
                                    <td className="text_right"></td>
                                  </tr>
                                  {props?.data?.tuitionStatus !== "FREE" && (
                                    <tr>
                                      <th>Tuition Expire on</th>
                                      <td>
                                        {props?.data?.diffDays > 0 ? (
                                          <>
                                            {props?.data?.diffDays} days (
                                            {moment(
                                              new Date(
                                                props.data?.tuitionStartEnd.classEnd
                                              )
                                            ).format("LL")}
                                            )
                                          </>
                                        ) : (
                                          <>
                                            {moment(
                                              new Date(
                                                props.data?.tuitionStartEnd.classEnd
                                              )
                                            ).format("LL")}
                                          </>
                                        )}
                                      </td>
                                      <td className="text_right"></td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          {props.data?.tuitionStatus === "CLOSED" &&
                          props.data?.classStatus === "cancelled" ? (
                            <Row>
                              <Col
                                md={12}
                                className="text-center py-2"
                                style={{ color: "red", fontWeight: 900 }}
                              >
                                {cancelText} and {closedTuitionText}
                              </Col>
                            </Row>
                          ) : props.data?.tuitionStatus === "CLOSED" &&
                            props.data?.classStatus === "complete" ? (
                            <Row>
                              <Col
                                md={12}
                                className="text-center py-2"
                                style={{ color: "red", fontWeight: 900 }}
                              >
                                {closedTuitionText}
                              </Col>
                            </Row>
                          ) : props.data?.tuitionStatus === "CLOSED" &&
                            props.data?.classStatus !== "complete" &&
                            props.data?.classStatus !== "cancelled" ? (
                            <Row>
                              <Col
                                md={12}
                                className="text-center py-2"
                                style={{ color: "red", fontWeight: 900 }}
                              >
                                {closedTuitionText}
                              </Col>
                            </Row>
                          ) : props.data?.classStatus === "cancelled" ? (
                            <Row>
                              <Col
                                md={12}
                                className="text-center py-2"
                                style={{ color: "red", fontWeight: 900 }}
                              >
                                {cancelText}
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Col md={12} className="text-center py-2">
                                <small>
                                  <b>N.B: </b>
                                </small>
                                <small>
                                  The <code>join</code> button will become
                                  active 1 hour and 30 minutes prior to the
                                  scheduled class start time.
                                </small>
                              </Col>
                              <Row style={{ display: !lessThan24 && "none" }}>
                                <Col
                                  md={12}
                                  className="text-center py-2"
                                  style={{ color: "red", fontWeight: 900 }}
                                >
                                  Sorry, You can not cancel a class before less
                                  than a day of starting.
                                </Col>
                              </Row>
                              <Row style={{ display: !lessThan30D && "none" }}>
                                <Col
                                  md={12}
                                  className="text-center py-2"
                                  style={{ color: "red", fontWeight: 900 }}
                                >
                                  Sorry, Tuition can only be extended if it
                                  expires in less than 30 days!
                                </Col>
                              </Row>
                              <Col md={12} className="cal_footer_center">
                                <Row>
                                  <Col md={12}>
                                    {props?.data?.tuitionPlace === "Online" && (
                                      <button
                                        className={
                                          props?.data?.isPaid &&
                                          props?.data?.tuitionStatus !==
                                            "CLOSED" &&
                                          joinButtonEnabled
                                            ? "btn btn bg-paid cal_btn"
                                            : "btn btn bgUnpaid cal_btn"
                                        }
                                        disabled={
                                          !joinButtonEnabled ||
                                          props?.data?.tuitionStatus ===
                                            "CLOSED" ||
                                          !props?.data?.isPaid
                                        }
                                        onClick={(e) => {
                                          getMeetingToken(e);
                                        }}
                                      >
                                        Join
                                      </button>
                                    )}
                                    {/* {props?.memberType === "student" && ( */}
                                    <button
                                      className="btn btn-sm bg-rescheduled cal_btn"
                                      onClick={(e) =>
                                        handleReschedule(e, props?.data?.date)
                                      }
                                      disabled={
                                        memberType === "student"
                                          ? isLoading ||
                                            moment(props?.data?.date).format(
                                              "L"
                                            ) <= moment(new Date()).format("L")
                                          : isLoading ||
                                            (moment(props?.data?.date).format(
                                              "L"
                                            ) <=
                                              moment(new Date()).format("L") &&
                                              props.data.isPaid == false) ||
                                            (props.data.isPaid == true &&
                                              (props?.data?.completionStatus
                                                ?.isCompleteByTutor === true ||
                                                props?.data?.completionStatus
                                                  ?.isCompleteByStudent ===
                                                  true))
                                      }
                                    >
                                      Reschedule
                                    </button>
                                    {/* )} */}
                                    <button
                                      className="btn btn-sm btn-danger cal_btn"
                                      onClick={(e) => {
                                        if (
                                          moment(props?.data?.start) -
                                            moment(new Date()) >
                                          86391214
                                        ) {
                                          return setIsCancelled(true);
                                        }
                                        setLessThan30D(false);
                                        return setLessThan24(true);
                                      }}
                                    >
                                      Cancel Class
                                    </button>
                                    {props.memberType === "student"
                                      ? props?.data?.tuitionStatus !==
                                          "FREE" && (
                                          <>
                                            <button
                                              className="btn btn-sm btn-secondary cal_btn"
                                              onClick={(e) => {
                                                if (
                                                  props?.data?.diffDays >= 30
                                                ) {
                                                  setLessThan24(false);
                                                  return setLessThan30D(true);
                                                } else {
                                                  return handleExtended(
                                                    e,
                                                    props?.data?.date
                                                  );
                                                }
                                              }}
                                              disabled={false}
                                            >
                                              Extend
                                            </button>
                                          </>
                                        )
                                      : ""}
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          )}
                        </>
                        // 86391214
                      )}
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter className=""></ModalFooter>
      </Modal>
    </>
  );
};

export default ScheduleModal;
