import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ContactMemberModal from "../../Chat/NewChat/ContactMemberModal";
import { sortedWorkExperiences } from "../../Common/helpers";
import "../_profile.css";
import About from "./About";
import Experience from "./Experience";
import Feedback from "./Feedback";
import PreferredLanguage from "./PreferredLanguage";
import Qualification from "./Qualification";
import Rating from "./Rating";
import Schedule from "./Schedule";
import SubjectAndPricing from "./SubjectAndPricing";
import ShareProfileModal from "./ShareProfileModal";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import HelpModalBtn from "../../LandingPage/BecomeTutor/ChildComponent/HelpModalBtn";
// import HelpModal from "../../LandingPage/BecomeTutor/ChildComponent/HelpModal";
// import FindStudentsGuide from "../../UserGuidelines/FindStudentsGuide";

const NewTutorProfileContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const [basicInfo, setBasicInfo] = useState({});
  const [educationInfo, setEducationInfo] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [interestPricingData, setInterestPricingData] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const [fullname, setFullName] = useState("");
  const [profile, setProfile] = useState(man);
  const [profile2, setProfile2] = useState("");
  const [isModal, setIsModal] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const initilindex = { index: 0, isOpen: false, toggler: toggle };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const navigate = useNavigate();

  const params = useParams();
  const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
  const id = params.id === "me" ? userData.id : params.id;
  const isFirstRender = useRef(true);
  const swMember = localStorage.getItem("swMember");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [allowSwitch, setAllowSwitch] = useState(true);

  if (allowSwitch) {
    const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    if (_tuteair?.accountType !== "tutor") {
      localStorage.setItem("swMember", "student");
    }
    setAllowSwitch(false);
  }

  // Function to open the Share Profile Modal
  const handleShareProfileModal = (e) => {
    e.preventDefault();
    setShareModalOpen(true);
  };

  //tutorInterestPricing
  useEffect(() => {
    const getMemberInfo = async () => {
      var res = await axiosRequest({
        url: "/newMember/singleMemberInfo/" + id,
        method: "post",
        data: {
          type: "student",
        },
      });
      if (res) {
        if (res?.data) {
          setEducationInfo(res?.data?.educations);
          const sortedExp = sortedWorkExperiences(res?.data?.workExperiences);
          setWorkExperience(sortedExp);
          // setSchedules(res.data.schedules);
          setSchedules(res?.data?.tutorSchedules);
          setInterestPricingData(res?.data?.interestAndPricing);
          var obj = {
            name: res.data.name,
            gender: res.data.gender,
            address: res.data.address,
            email: res.data.email,
            media: res.data.media,
            about: res.data.about,
            languages: res.data.languages,
            currency: res.data.currency,
            contactNo: res.data.contactNo,
            createdAt: res.data.createdAt,
          };
          setBasicInfo(obj);

          if (res?.data?.name) {
            setFullName(
              res?.data?.name?.firstName + " " + res?.data?.name?.lastName
            );
          }

          const pro_pic = localStorage.getItem("picture2");

          var profUrl = res?.data?.media?.profilePicture?.imageInfo?.secure_url
            ? res?.data?.media?.profilePicture?.imageInfo?.secure_url
            : res?.data?.media?.picture === "" ||
              res?.data?.media?.picture === null
            ? pro_pic?.includes("googleusercontent")
              ? pro_pic
              : ""
            : res?.data?.media?.picture?.includes("googleusercontent")
            ? res?.data?.media?.picture
            : ProfilePictureRootURL + res?.data?.media?.picture;
          setProfile2(profUrl);
        } else {
          const pro_pic = localStorage.getItem("picture2");
          if (pro_pic?.includes("googleusercontent")) {
            setProfile2(pro_pic);
          } else {
            setProfile2("");
          }
        }
      }
    };
    if (isFirstRender.current) {
      //console.count("called");
      isFirstRender.current = false;
      getMemberInfo();
    }
  }, []);

  const handleStartConversationModal = (e) => {
    e.preventDefault();
    setPhotoIndex({
      ...photoIndex,
      isOpen: true,
      userId: id,
      name: fullname,
      tuitions: interestPricingData,
    });
    setIsModal(true);
  };
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });

  useEffect(() => {
    if (memberType === "student" && params.id === "me") {
      navigate(`${process.env.PUBLIC_URL}/student-profile/me`);
    }
  }, [memberType, id]);
  const tutorInstitueName = educationInfo;
  const tutorlastInstitueName = tutorInstitueName?.slice(-1);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <div>
            <Card>
              <CardBody className="d-flex align-items-center">
                <Row className="col-12">
                  <Col xs="5" sm="3" lg="2">
                    <img
                      style={{ width: "150px", height: "150px" }}
                      className="rounded-circle me-2 profilePicMblView"
                      alt="tutor profile"
                      src={profile2 !== "" ? profile2 : profile}
                    />
                  </Col>

                  <Col
                    xs="7"
                    sm="3"
                    lg="8"
                    className="d-flex align-items-center"
                  >
                    <div style={{ flex: "auto" }}>
                      <CardTitle tag="h3" className="cardBox-2">
                        {fullname}
                      </CardTitle>
                      {tutorlastInstitueName?.map((item, index) => (
                        <CardText className="mb-0" key={index}>
                          Studied at {item.institute}
                        </CardText>
                      ))}

                      {basicInfo?.address?.city && (
                        <CardText>
                          <span className="mb-0">
                            Lives in {basicInfo?.address?.city}
                            {","} {basicInfo?.address?.country}
                          </span>
                        </CardText>
                      )}
                    </div>
                  </Col>
                  {/* <Col
                    sm="3"
                    lg="2"
                    className="d-flex justify-content-end mt-auto"
                  >
                    { <HelpModalBtn showIconAlways={true} /> }

                    <div>
                      <button
                        //type="submit"
                        className="btn btn-warning text-white ms-1 p-2"
                        //disabled={false}
                        onClick={(e) => handleShareProfileModal(e)}
                      >
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        Share
                      </button>
                    </div>
                  </Col> */}
                  <Col
                    sm="3"
                    lg="2"
                    className="d-flex justify-content-end mt-auto"
                  >
                    <div className="me-1 d-flex flex-column gap-3 justify-content-end">
                      {params.id === "me" && (
                        <Link to={`/settings?tab=1&edit=${true}`}>
                          <button
                            //type="submit"
                            className="btn btn-warning text-white ms-1 p-2"
                            //disabled={false}}
                          >
                            <i class="fa fa-edit me-2" aria-hidden="true"></i>
                            Edit profile
                          </button>
                        </Link>
                      )}
                      <button
                        //type="submit"
                        className="btn btn-warning text-white ms-1 p-2"
                        //disabled={false}
                        onClick={(e) => handleShareProfileModal(e)}
                      >
                        <i class="fa fa-share-alt me-2" aria-hidden="true"></i>
                        Share
                      </button>
                    </div>
                    {params.id !== "me" &&
                      (memberType === "student" || swMember === "student") && (
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success text-white ms-1 p-2"
                            disabled={false}
                            onClick={(e) => handleStartConversationModal(e)}
                          >
                            Contact Tutor
                          </button>
                        </div>
                      )}
                  </Col>
                </Row>
              </CardBody>
              {isModal && (
                <ContactMemberModal
                  photoIndex={photoIndex}
                  setPhotoIndex={callback}
                />
              )}
            </Card>
            <Row className="d-flex">
              <Col sm="4">
                <About data={basicInfo} fullname={fullname} />
                <PreferredLanguage data={basicInfo.languages} />
                {/* <PreferredCurrency data={basicInfo.currency} /> */}
                <Qualification
                  params={params.id}
                  data={educationInfo}
                  accountType={memberType}
                  type="Tutor"
                  title="Qualifications"
                />
                <Experience
                  params={params.id}
                  data={workExperience}
                  accountType={memberType}
                />
                <Rating />
              </Col>
              <Col sm="8">
                <SubjectAndPricing
                  params={params.id}
                  data={interestPricingData}
                />
                <Schedule
                  params={params.id}
                  data={schedules}
                  accountType={memberType}
                />
                <Feedback fullname={fullname} tutorPhoto={profile2} />
              </Col>
            </Row>
            {/* <HelpModal heading="Contact Tutor" body={<FindStudentsGuide />} /> */}
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={shareModalOpen}
        toggle={() => setShareModalOpen(!shareModalOpen)}
        style={{
          width: "fit-content",
        }}
      >
        {/* <ModalHeader toggle={() => setShareModalOpen(!shareModalOpen)}>
          Share Profile
        </ModalHeader> */}
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ShareProfileModal userId={id} open={shareModalOpen} />
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            onClick={() => setShareModalOpen(!shareModalOpen)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default NewTutorProfileContain;
