import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "reactstrap";
import { H4, Image, P } from "../../../AbstractElements";
import { RegisterApi } from "../../../api";
import { SignIn, Home } from "../../../Constant";
import { checkEmailVerifiedOrNot } from "../../LandingPage/BecomeTutor/fnBecomeTutor";

const VarifyEmail = () => {
  const [validURL, setValidURL] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [message, setMessage] = useState("Verifying your email...");
  const params = useParams();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      var result = await checkEmailVerifiedOrNot(params.id);

      if (result?.emailVerified) {
        setIsEmailVerified(true);
      } else {
        try {
          const url = `${RegisterApi}/email-verify/${params.id}/verify/${params.token}`;

          const response = await axios.get(url);
          if (response.data == "updated") {
            setValidURL(true);
          }
        } catch (error) {
          setValidURL(false);
          setMessage("404 NOT FOUND!");
        }
        setIsEmailVerified(false);
      }
    };

    verifyEmailUrl();
  }, []);

  return (
    <Fragment>
      <section>
        <Container fluid={true} className="p-0">
          <Row className="m-0">
            <Col xl="12 p-0">
              <div className="login-card1">
                <Form className="theme-form login-form">
                  <div style={{ "text-align": "center" }}>
                    <Image
                      attrImage={{
                        // className: "w-50",
                        alt: "img",
                        src: "https://i.ibb.co/9T0CHzt/tute-logo-1.png",
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  {isEmailVerified ? (
                    <div style={{ "text-align": "center" }}>
                      <H4 attrH4={{ className: "mb-3" }}>
                        EMAIL ALREADY VERIFIED!
                      </H4>

                      <P>
                        Click here to
                        <a
                          className="ms-2"
                          href={`${process.env.REACT_APP_NEXT_ROUTE}`}
                        >
                          {SignIn}
                        </a>
                      </P>
                    </div>
                  ) : validURL ? (
                    <div style={{ "text-align": "center" }}>
                      <H4 attrH4={{ className: "mb-3" }}>
                        EMAIL VERIFIED SUCCESSFULLY!
                      </H4>

                      <P>
                        Click here to
                        <Link to={`${process.env.REACT_APP_NEXT_ROUTE}/login`}>
                          <a className="ms-2" href="#">
                            {SignIn}
                          </a>
                        </Link>
                      </P>
                    </div>
                  ) : (
                    <div style={{ "text-align": "center" }}>
                      <H4 attrH4={{ className: "mb-3" }}>{message}</H4>
                      <P>
                        Click here to
                        <a
                          className="ms-2"
                          href={`${process.env.REACT_APP_NEXT_ROUTE}`}
                        >
                          {Home}
                        </a>
                      </P>
                    </div>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default VarifyEmail;
