import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import StudentFormWizardContain from "../../../Component/AccountNew/Student/index";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const StudentAccount = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="StudentNewAccount" title="Student Account" />
      {/* <TutorAccountContain /> */}
      <StudentFormWizardContain />
    </Fragment>
  );
};

export default StudentAccount;
