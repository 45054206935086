import io from "socket.io-client";
import { ServerURL } from "../../../../api";
import { apiSlice } from "../../api/apiSlice";

export const messagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: (id, sender) => `/chat/newMessage/${id}`, // sender param not used
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        
        // create socket
        const socket = io(ServerURL, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttempts: 1,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });

        try {
          await cacheDataLoaded;
          socket.on("message", (data) => {
            updateCachedData((draft) => {
              // console.log(current(draft))
              if (
                draft.messages.some(
                  (message) => message?.messageId === data?.data?.messageId
                )
              ) {
                //console.warn("duplicate message socket response");
                return;
              }

              if (draft?.conversationId === data?.data.conversationId) {
                draft.messages.push(data?.data);
              }
            });
          });
        } catch (err) {}

        await cacheEntryRemoved;
        // socket.close();
      },
    }),
    addMessage: builder.mutation({
      query: (data) => ({
        url: "/chat/newMessage/save",
        method: "POST",
        body: data,
      }),
    }),
    updateMessageStatus: builder.mutation({
      query: (data) => ({
        url: "/chat/newMessage/message-seen",
        method: "PATCH",
        body: data
      }),
      // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
      //   console.log(arg);
      // }
    })
  }),
});

export const { useGetMessagesQuery, useAddMessageMutation, useUpdateMessageStatusMutation } = messagesApi;
