import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from "axios";
import { allLoginProcess } from "../../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function useAuthFromNext() {
    const { cryptoToken } = useParams();
    const secret = process.env.REACT_APP_CRYPTO_JS_SECRET;
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);

    useEffect(async () => {
        const uriDecodedToken = decodeURIComponent(cryptoToken);

        if (cryptoToken && secret) {

            const bytes = CryptoJS.AES.decrypt(uriDecodedToken, secret);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);

            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL_DEV}/public/authorizedLogin`, {
                    headers: {
                        Authorization: `Bearer ${decryptedToken}`
                    }
                })

                if (res?.status === 200 && res?.data?._id) {
                    await allLoginProcess(res.data, dispatch);
                    setSuccess(true);
                }
            }
            catch (error) {
                console.error(error);
                toast.error("Authentication failed", {
                    autoClose: 5000,
                })
                window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/login?logout=1`;
            }
        } else {
            window.location.href = `${process.env.REACT_APP_NEXT_ROUTE}/login?logout=1`;
        }
        ;

    }, [cryptoToken, secret]);

    return success;
}
