import React, { Fragment, useEffect, useState } from "react";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { Col, Container, Row } from "reactstrap";
import { P } from "../../AbstractElements";
import "./Footer.css";

const Footer = () => {
  const [userId, setUserId] = useState("");
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
    setUserId(userData?.id);
  }, []);

  return (
    <Fragment>
      <footer>
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <div>
                <div> Share your profile on</div>
                <FacebookShareButton
                  url={`https://www.tuteair.com/profile/${userId}`}
                  quote={"TuteAir - Explore your tuition Experience"}
                  hashtag="#Tuteair"
                  className="me-1"
                >
                  <FacebookIcon className="link" size={25} round={true}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton
                  url={`https://www.tuteair.com/profile/${userId}`}
                  title={"TuteAir - Explore your tuition Experience"}
                  hashtag="#Tuteair"
                  className="me-1"
                >
                  <TwitterIcon className="link" size={25} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`https://www.tuteair.com/profile/${userId}`}
                  title={"TuteAir - Explore your tuition Experience"}
                  separator="::  "
                  className="me-1"
                >
                  <WhatsappIcon className="link" size={25} round={true} />
                </WhatsappShareButton>
              </div>
              <P attrPara={{ className: "mb-0" }}>Copyright 2023 © siara solutions pty ltd </P>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
export default Footer;
