import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Row, Col, Input, Label } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import { getTrackBackground, Range } from "react-range";

export default function RatingFilter({ isReset }) {
  const { setRating, setIsRatingChange, moonlight } = useContext(FilterContext);

  const multiselectRef = useRef();

  const fnSetRating = (value) => {
    setRating(value);
    // setEducationFilter((prev) => ({ ...prev, age: value }));
  };

  const MIN = 0;
  const MAX = 6;
  const STEP = (MAX - MIN) / 12;
  const [values, setValues] = useState([MIN + 1, MAX - 1]);

  useEffect(() => {
    if (values[0] == 1 && values[1] == 7) {
      // do nothing
    } else {
      fnSetRating(values);
    }
  }, values);

  useEffect(() => {
    if (isReset) {
      setValues([MIN + 1, MAX - 1]);

      setIsRatingChange(false);
      multiselectRef?.current?.resetSelectedValues();
    }
  }, [isReset]);

  return (
    <Fragment>
      <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
        <h4 className="f_title">
          <span>Ratings</span>
        </h4>
        <Row>
          <Col sm="12" xl="12">
            <div className="row mb-2">
              <Label className="col-sm-3 col-form-label " htmlFor="inputEmail3">
                Rating
              </Label>
              <Col md="9" className="">
                <Range
                  values={values}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  onChange={(values) => {
                    // Ensure the second value (maximum) does not exceed 5
                    const restrictedValues = [
                      values[0],
                      Math.min(values[1], 5),
                    ];
                    setValues(restrictedValues);
                    setIsRatingChange(true);
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "36px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <output style={{ marginTop: "10px" }}>{values[0]}</output>
                      <div
                        ref={props.ref}
                        style={{
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values,
                            colors: ["#ccc", "#4d8aff", "#ccc"],
                            min: MIN,
                            max: MAX,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                      <output style={{ marginTop: "10px" }}>{values[1]}</output>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "25px",
                        width: "25px",
                        borderRadius: "30px",
                        backgroundColor: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 2px 6px #AAA",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "5px",
                          backgroundColor: isDragged ? "#4d8aff" : "#CCC",
                        }}
                      />
                    </div>
                  )}
                />
              </Col>
            </div>

            {/* {currentMemberType != "tutor" && (
          <hr
            color="#337AB7"
            size="5"
            width="92%"
            style={{ position: "absolute", marginTop: "15px" }}
          />
        )} */}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
