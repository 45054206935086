import React from "react";
import { Card, CardHeader } from "reactstrap";
import "../../../style/custom-css/FindTutor.css";
import { Link } from "react-router-dom";

export default function SubjectAndPricing(props) {
  return (
    <Card body className="card-absolute b-t-light border-5">
      <CardHeader style={{ padding: "5px 10px" }} className="bg-primary">
        Subjects
      </CardHeader>
      {props.params === "me" &&
        <div className="d-flex justify-content-end ">
          <Link to={'/tutor-account?tab=4'}>
            <button
              //type="submit"
              className="btn btn-primary text-white py-1 px-2"
            //disabled={false}}
            >
              <i class="fa fa-edit me-2" aria-hidden="true"></i>
              Edit
            </button>
          </Link>
        </div>
      }
      <div className="table-responsive-sm tableMobileView">
        <table className="table">
          <thead>
            {/* <tr>
              <td className="h4" colSpan={6}>
                Tuition Details
              </td>
            </tr> */}
            <tr style={{ background: "#dbdbdb2b" }}>
              <th style={{ width: "35%" }} className="mblViewRes">
                Subjects
              </th>
              {/* <th>Class</th> */}
              <th className="mblViewRes">Tuition Type</th>
              <th className="text-nowrap mblViewRes">
                Amount <sub>/class</sub>
              </th>
              <th className="mblViewRes">Negotiable</th>
            </tr>
          </thead>
          <tbody>
            {props?.data?.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  No Info Found
                </td>
              </tr>
            )}
            {props?.data?.map((item, index) => (
              <tr key={index} className="px-0">
                <td>
                  <span>
                    {item.subjects?.map((item, index) => (
                      <span className="badge badge-light-primary" style={{ margin: "0px 3px 2px 0px" }} key={index}>
                        {item.name}
                      </span>
                    ))}
                  </span>
                  <span className="mb-0 px-1" style={{ color: "#488374" }}>
                    <small>{item.classes?.name}</small>
                  </span>
                </td>
                {/* <td>{item.classes?.name}</td> */}
                <td className="">
                  <span>
                    {item.tutionTypes?.map((item, index) => (
                      <span className="badge badge-light-primary" style={{ margin: "0px 3px 2px 0px" }} key={index}>
                        {item}
                      </span>
                    ))}
                  </span>
                </td>
                <td style={{ fontSize: "13px" }}>
                  USD {item.amount?.initialPrice}
                  {/* {item.amount?.initialPrice} {item.currency?.name} / Hourly */}
                </td>
                <td className="text-center" style={{ fontSize: "13px" }}>
                  {!item.amount?.isNegotiable && <span>No</span>}
                  {item.amount?.isNegotiable && <span>Yes</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
