import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Lock, Mail, Type, User } from "react-feather";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormGroup, Input, InputGroup, Label } from "reactstrap";
// import HelpModalBtn from "../../LandingPage/BecomeTutor/ChildComponent/HelpModalBtn";
// import HelpModal from "../../LandingPage/BecomeTutor/ChildComponent/HelpModal";
// import CreateAccountGuide from "../../../Component/UserGuidelines/CreateAcountGuide";

import { Btn, H4, H5, LI, P, UL } from "../../../AbstractElements";
import man from "../../../assets/images/dashboard/1.png";
import {
  CreateAccount,
  EmailAddress,
  Password,
  PrivacyPolicy,
  SignIn,
  YourName,
} from "../../../Constant";
import { toastNotifiy } from "../../../Services/AuthVerify";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import FilterContext from "../../../_helper/Filter";
import { useWindowWidth } from "../../Chat/NewChat/inbox/useWindowWidth";
import tuteair from "../assets/img/tuteair-v2.png";
import { SignInGoogle } from "../BecomeTutor/ChildComponent/SignInGoogle";
import SlickSlider from "../BecomeTutor/ChildComponent/SlickSlider";
import { createNewAccount2 } from "../BecomeTutor/fnBecomeTutor";

const RegisterV2 = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [accountType, setAccountType] = useState("tutor");
  const [loading, setLoading] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [emailVerify, setEmailVerify] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState("Minimum 8 characters, at least 1 letter, 1 number and 1 special character");
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const { step, setStep, setIsEmailVerified } = useContext(BecomeTutorContext);
  const { setStepStudent } = useContext(BecomeStudentContext);
  const { moonlight, setMoonlight, accountType, setAccountType } = useContext(FilterContext);

  const [isTutorChecked, setIsTutorChecked] = useState(true);
  const [isStudentChecked, setIsStudentChecked] = useState(true);

  const width = useWindowWidth();

  const fnIsValidPassword = (string) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&',-])[A-Za-z\d@$!%*#?&',-]{8,20}$/.test(string);
  //^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/.test(string);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  useEffect(() => {
    if (!fnIsValidPassword(password)) {
      setIsValidPassword(false);
      if (agreeCheckbox) {
        setButtonDisabled(true);
      }
    } else {
      setShowErrorMessage(true);
      if (agreeCheckbox) {
        setButtonDisabled(false);
        setShowErrorMessage(fnIsValidPassword(password));
      }
    }

    // if (isCPasswordDirty) {
    //   if (!fnIsValidPassword(password)) {
    //     setIsValidPassword(false);
    //   } else {
    //     setShowErrorMessage(true);
    //     if (agreeCheckbox) {
    //       setButtonDisabled(true);
    //       setShowErrorMessage(fnIsValidPassword(password));
    //     }
    //   }
    // }
  }, [password]);

  // const handlePasswordKeyUp = (e) => {
  //   var isValidPwd = fnIsValidPassword(password);
  //   setIsValidPassword(isValidPwd);
  //   setShowErrorMessage(!isValidPwd);
  // };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    var isValidPwd = fnIsValidPassword(e.target.value);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
  };

  const checkboxHandler = (isChk) => {
    setAgreeCheckbox(isChk);
    if (isCPasswordDirty === false && isChk === true && isValidPassword === true) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    setStep(0);
  }, []);

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (accountType === "") return;

    try {
      let result = await createNewAccount2(
        {
          firstName,
          lastName,
          email,
          password,
          accountType: accountType,
        },
        dispatch
      );
      if (result?.isRegistered) {
        if (accountType === "student") {
          setStepStudent(1);
          navigate(`${process.env.PUBLIC_URL}/become-student`);
        } else {
          setStep(1);
          if (accountType === "both") {
            setStepStudent(1);
          }
          navigate(`${process.env.PUBLIC_URL}/become-tutor`);
        }
      } else {
        toastNotifiy(result?.message, "warn");
      }
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        toast.error("Oppss.. Something went wrong!!.");
      }, 200);
    }
    setLoading(false);
  };

  //--- setting page theme to light mode ---
  useEffect(() => {
    // console.log(moonlight);
    // console.log(localStorage.getItem('layout_version'));
    setMoonlight(false);
    document.body.className = "light";
    localStorage.setItem("layout_version", "light");
  }, []);

  return (
    <Fragment>
      <section className={`py-md-5 ${width < 576 ? "" : "container"} ${step !== 0 ? "container_padding" : ""}`}>
        {/* <NavbarContain /> */}
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg_navbar mb-5"
          variant="light"
          fixed="top"
          // style={{ background: "#faebd7" }}
        >
          <Container>
            <Link to={`${process.env.PUBLIC_URL}/home`}>
              <Navbar.Brand className="tuteairBrand px-3">
                <img src={tuteair} height="35" className="" alt="TuteAir" />
              </Navbar.Brand>
            </Link>
            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
            <Navbar.Collapse id="responsive-navbar-nav " className="tuteair_navbar">
              <Nav className="me-auto"></Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Row xs="1" md="2" className="m-0">
          <Col xs={{ order: 2 }} md={{ order: 1 }} className="p-0 carousal-page mt-5 mt-md-0" style={{ placeSelf: "center" }}>
            <SlickSlider slides={1} title="Responsive Example" rtl={false} lazyLoad={true} />
          </Col>
          <Col xs={{ order: 1 }} md={{ order: 2 }} className="p-0">
            <div className="login-card1 become_tutor_card1">
              <Form className="theme-form login-form" autoComplete="off">
                <ToastContainer />
                <div className="login-header  text-center">
                  <H4>Create your account</H4>
                  {/* <HelpModalBtn showIconAlways={true} /> */}

                  {/* <H6>Enter your personal details to create account</H6> */}
                </div>

                <FormGroup>
                  <UL
                    attrUL={{ className: "simple-list login-social flex-row" }}
                  >
                    <LI>
                      <SignInGoogle />
                    </LI>
                  </UL>
                  <div className="mt-3">
                    {Option}
                    <div
                      style={{ fontSize: "13px" }}
                      className="digits text-center"
                    >
                      By signing in with Google I agree that I have read{" "}
                      <Link
                        to={`${process.env.PUBLIC_URL}/privacy`}
                        target="_blank"
                      >
                        {PrivacyPolicy}
                      </Link>
                      <span className="px-1">and</span>
                      <Link
                        to={`${process.env.PUBLIC_URL}/terms-condition`}
                        target="_blank"
                      >
                        Terms and Condition
                      </Link>
                    </div>
                  </div>
                </FormGroup>
                <div className="login-social-title">
                  <H5>Sign in with Email</H5>
                </div>
                <FormGroup className="form-group">
                  <label className="form-label">{YourName}</label>
                  <div className="small-group">
                    <InputGroup>
                      <span className="input-group-text">
                        <User />
                      </span>
                      <Input
                        style={{ color: "black" }}
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <span className="input-group-text">
                        <User />
                      </span>
                      <Input
                        style={{ color: "black" }}
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-label">{EmailAddress}</label>
                  <InputGroup>
                    <span className="input-group-text">
                      <Mail />
                    </span>
                    <Input
                      style={{ color: "black" }}
                      className="form-control"
                      type="email"
                      required=""
                      placeholder="user@gmail.com"
                      value={email}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label className="form-label">{Password}</label>
                  <InputGroup>
                    <span className="input-group-text">
                      <Lock />
                    </span>
                    <Input
                      style={{ color: "black" }}
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      required=""
                      placeholder="*********"
                      value={password}
                      autoComplete="off"
                      onChange={handlePassword}
                      // onKeyUp={handlePasswordKeyUp}
                    />
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </InputGroup>
                  {showErrorMessage && !isValidPassword ? <div className="text-danger">{pwdErrorMessage}</div> : ""}
                </FormGroup>
                <FormGroup className="">
                  <label className="form-label">
                    {"Account Type"}{" "}
                    <small className="px-1" style={{ fontWeight: "300", color: "#9a6b4c" }}>
                      You can change the type later!
                    </small>
                  </label>
                  <InputGroup className="form-control p-0 bg-light-info text-dark">
                    <span className="input-group-text">
                      <Type />
                    </span>
                    <div
                      style={{
                        paddingLeft: "35px",
                        paddingBottom: "5px",
                        FontSize: "13px",
                      }}
                    >
                      <div className="m-checkbox-inline custom-radio-ml pt-2" style={{ fontSize: "14px", fontWeight: "400" }}>
                        {/* <div className="checkbox checkbox-dark">
                          <Input
                            id="inline-student"
                            type="checkbox"
                            value="student"
                            checked={isStudentChecked}
                            onChange={(e) => {
                              setIsStudentChecked(e.target.checked);
                            }}
                          />
                          <label
                            htmlFor="inline-student"
                            className="mb-0"
                            style={{ fontWeight: "400" }}
                          >
                            {Option}
                            <span className="digits"> {"Student"}</span>
                          </label>
                        </div>
                        <div className="checkbox checkbox-dark">
                          <Input
                            id="inline-tutor"
                            type="checkbox"
                            value="tutor"
                            checked={isTutorChecked}
                            onChange={(e) => {
                              setIsTutorChecked(e.target.checked);
                            }}
                          />
                          <label
                            htmlFor="inline-tutor"
                            className="mb-0"
                            style={{ fontWeight: "400" }}
                          >
                            {Option}
                            <span className="digits"> {"Tutor"}</span>
                          </label>
                        </div> */}

                        <div className="radio radio-primary">
                          <Input
                            id="radioinline1"
                            type="radio"
                            name="radio1"
                            value="tutor"
                            checked={accountType === "tutor"}
                            onChange={() => {
                              setAccountType("tutor");
                            }}
                          />
                          <Label className="mb-0" htmlFor="radioinline1">
                            <span className="digits"> {"Tutor"}</span>
                          </Label>
                        </div>
                        <div className="radio radio-primary">
                          <Input
                            id="radioinline2"
                            type="radio"
                            name="radio1"
                            value="student"
                            checked={accountType === "student"}
                            onChange={() => {
                              setAccountType("student");
                            }}
                          />
                          <Label className="mb-0" htmlFor="radioinline2">
                            <span className="digits"> {"Student"}</span>
                          </Label>
                        </div>
                        <div className="radio radio-primary">
                          <Input
                            id="radioinline3"
                            type="radio"
                            name="radio1"
                            value="both"
                            checked={accountType === "both"}
                            onChange={(e) => {
                              setAccountType("both");
                            }}
                          />
                          <Label className="mb-0" htmlFor="radioinline3">
                            <span className="digits"> {"Both"}</span>
                          </Label>
                        </div>
                      </div>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="form-group">
                  <div className="m-t-15 m-checkbox-inline custom-radio-ml" style={{ fontSize: "13px" }}>
                    <div className="checkbox checkbox-dark">
                      <Input
                        id="inline-1"
                        type="checkbox"
                        onChange={(event) => {
                          checkboxHandler(event.target.checked);
                        }}
                      />
                      <label htmlFor="inline-1" style={{ position: "absolute", FontSize: "13px" }} className="mb-0">
                        <div>
                          {Option}
                          <span className="digits">
                            Agree with{" "}
                            <Link to={`${process.env.PUBLIC_URL}/privacy`} target="_blank">
                              {PrivacyPolicy}
                            </Link>
                            <span className="px-1">and</span>
                            <Link to={`${process.env.PUBLIC_URL}/terms-condition`} target="_blank">
                              Terms and Condition
                            </Link>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="topSpace">
                  <Btn
                    attrBtn={{
                      className: "btn-block",
                      color: "primary",
                      type: "submit",
                      disabled: isButtonDisabled, //loading ? loading : loading,
                      onClick: (e) => handleRegisterUser(e),
                    }}
                  >
                    {loading
                      ? "LOADING..."
                      : resendEnabled
                      ? "Resend Email"
                      : emailVerify
                      ? "An Email has been sent to your account. Please verify"
                      : CreateAccount}
                  </Btn>
                </FormGroup>
                <P>
                  Already have an account?
                  <Link to={`${process.env.PUBLIC_URL}/login-account`} className="ms-2">
                    {SignIn}
                  </Link>
                </P>
              </Form>
            </div>
          </Col>
        </Row>
      </section>
      {/* <HelpModal heading="Create Account" body={<CreateAccountGuide />} /> */}
    </Fragment>
  );
};

export default RegisterV2;
