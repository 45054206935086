import axios from "axios";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { Card, CardHeader, Col, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import moment from "moment";
import {
  TuitionRequestDeleteApi,
  TuitionRequestGetAllApi,
  TuitionRequestGetByIdApi,
} from "../../../api";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import RequestTuitionModal from "./RequestTuitionModal";
import { interestSubjects } from "../../../redux/features/member/memberSlice";
import { useDispatch, useSelector } from "react-redux";
import TutorsContext from "../../../_helper/Tutors";

const RequestTuition = () => {
  const {
    getDegrees,
    degrees,
    selectedDegree,
    setDegrees,
    getCourses,
    courses,
    setCourses,
    selectedCourse,
    workExpYearList,
    workExpYear,
  } = useContext(TutorsContext);
  const [modal, setModal] = useState(false);
  const [interestToLearnData, setinterestPricingData] = useState([]);
  const [interestedSingleData, setInterestedSingleData] = useState({});
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [title, setTitle] = useState("");
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  const { subjectCategoryList } = useSelector((state) => state.member) || {};

  const fetchInterestedSubjectData = async () => {
    const res = await axios.get(TuitionRequestGetAllApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.data) {
      setinterestPricingData(res.data);
    }
  };

  useEffect(() => {
    getDegrees();
  }, []);

  useEffect(() => {
    fetchInterestedSubjectData();
  }, [modal]);

  useEffect(() => {
    if (!subjectCategoryList) {
      dispatch(
        interestSubjects({
          type: "CATEGORY",
        })
      );
    }
  }, [subjectCategoryList, dispatch]);

  const handleAddNewButton = () => {
    setTitle("Post Tuition Request");
    setView(false);
    setEdit(false);
    setInterestedSingleData({});
    toggle();
  };

  const handleView = async (id) => {
    setTitle("View Tuition Request");

    const res = await axios.get(TuitionRequestGetByIdApi + id, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res) {
      setInterestedSingleData(res.data);
      setView(true);
      setEdit(false);
      toggle();
    }
  };

  const handleInterestToLearnEdit = async (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure to edit this information?")) {
      setTitle("Edit Tuition Request");
      var data = interestToLearnData.filter((x) => x._id === id);
      if (data) {
        data = data[0];
        setEdit(true);
        setView(false);
        let selectedTuitionTypes = [];

        for (var i = 0; i < data.tuitionTypes.length; i++) {
          selectedTuitionTypes.push({
            value: data.tuitionTypes[i], // backend api changed name for understand
            label: data.tuitionTypes[i], // backend api changed name for understand
            isDisabled: false,
          });
        }

        setInterestedSingleData({
          id: data._id,
          gender: data.gender,
          languages: data.languages,
          subjects: data.subjects,
          tuitionTypes: data.tuitionTypes, //selectedTuitionTypes?.map(({ value }) => value),
          location: data.location,
          education: data.education,
          selectedDegree: selectedDegree,
          price: data.price,
          workExperience: data.workExperience,
          profilePicExist: data.profilePicExist,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          requestMessage: data.requestMessage,
          status: data.status,
        });
        setModal(true);
        return;
      }
    }
  };

  const handleInterestToLearnDelete = async (e, id) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure to delete these request for tuition information?"
      )
    ) {
      const res = await axios.delete(TuitionRequestDeleteApi + id, {
        headers: {
          Authorization: authHeader(),
        },
      });

      if (res.status === 200) {
        toastNotifiy("Deleted successully", "warn");
        fetchInterestedSubjectData();
      } else {
      }
    }
  };
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="8"></Col>
                <Col md="4">
                  {/* <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: handleAddNewButton,
                    }}
                  >
                    Add New
                  </Btn> */}
                  {modal && (
                    <RequestTuitionModal
                      title={title}
                      isOpen={modal}
                      toggler={toggle}
                      edit={edit}
                      view={view}
                      data={interestedSingleData}
                    />
                  )}
                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>
            <div className="table-responsive-sm">
              <Table className="table-border-horizontal">
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th style={{ width: "5%" }} scope="col">
                      {"#"}
                    </th>
                    <th style={{ width: "30%" }} scope="col">
                      {"Subjects"}
                    </th>
                    <th style={{ width: "25%" }} scope="col">
                      {"Languages"}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      scope="col"
                      className="text-nowrap"
                    >
                      {"Tuition Place"}
                    </th>
                    <th
                      style={{ width: "15%" }}
                      scope="col"
                      className="text-nowrap"
                    >
                      {"Start Date"}
                    </th>
                    <th
                      style={{ width: "15%" }}
                      scope="col"
                      className="text-nowrap"
                    >
                      {"Expiry Date"}
                    </th>
                    <th style={{ width: "5%" }} scope="col">
                      {"Status"}
                    </th>{" "}
                    <th style={{ width: "5%" }} scope="col">
                      {""}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {interestToLearnData?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={4}>No Request for Tuitions Found!</td>
                    </tr>
                  )}
                  {interestToLearnData?.map((item, index) => (
                    <tr key={item._id}>
                      <th scope="row">{index + 1}</th>
                      <td className="">
                        <span
                          className="text-left h6"
                          style={{ cursor: "pointer" }}
                          title="For Details Click here"
                          onClick={() => handleView(item._id)}
                        >
                          {item?.subjects?.map((item, index) => (
                            <span
                              className="badge badge-light-primary"
                              style={{
                                margin: "0px 3px 2px 0px",
                                fontSize: "12.5px",
                              }}
                              key={index}
                            >
                              {item?.name}
                            </span>
                          ))}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-left h6"
                          style={{ cursor: "pointer" }}
                          title="For Details Click here"
                          onClick={() => handleView(item._id)}
                        >
                          {item?.languages?.map((item, index) => (
                            <span
                              className="badge badge-light-primary"
                              style={{
                                margin: "0px 3px 2px 0px",
                                fontSize: "12.5px",
                              }}
                              key={index}
                            >
                              {item}
                            </span>
                          ))}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-left h6"
                          style={{ cursor: "pointer" }}
                          title="For Details Click here"
                          onClick={() => handleView(item._id)}
                        >
                          {item?.tuitionTypes?.map((item, index) => (
                            <span
                              className="badge badge-light-primary"
                              style={{
                                margin: "0px 3px 2px 0px",
                                fontSize: "12.5px",
                              }}
                              key={index}
                            >
                              {item}
                            </span>
                          ))}
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge badge-light-dark"
                          style={{
                            margin: "0px 3px 2px 0px",
                            fontSize: "12.5px",
                          }}
                        >
                          {moment(item?.startDate).format("MMM D, yyyy")}
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge badge-light-dark"
                          style={{
                            margin: "0px 3px 2px 0px",
                            fontSize: "12.5px",
                          }}
                        >
                          {moment(item?.endDate).format("MMM D, yyyy")}
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge badge-light-dark"
                          style={{
                            margin: "0px 3px 2px 0px",
                            fontSize: "12.5px",
                          }}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td className="px-1">
                        {" "}
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={(e) =>
                            handleInterestToLearnEdit(e, item._id)
                          }
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        <button
                          className="btn px-1 py-0 btn-link text-danger"
                          onClick={(e) =>
                            handleInterestToLearnDelete(e, item._id)
                          }
                        >
                          <i className="fa fa-trash"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default RequestTuition;
