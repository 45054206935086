// common list
export const createAccountSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/createAccount.png"),
  },
];

export const accountSettingsTutorSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/accountSetting1.png"),
    indicator: "1 2",
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/accountSettingTutor2.png"),
    indicator: "3",
  },
  {
    id: 3,
    img: require("../../../assets/images/help-page/accountSettingTutor3.png"),
    indicator: "4 5 6 7 8",
  },
  // {
  //   id: 4,
  //   img: require("../../../assets/images/help-page/accountSetting4.png"),
  //   indicator: "7",
  // },
];

export const accountSettingsStudentSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/accountSetting1.png"),
    indicator: "1 2",
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/accountSettingStudent2.png"),
    indicator: "3",
  },
  {
    id: 3,
    img: require("../../../assets/images/help-page/accountSettingStudent3.png"),
    indicator: "4 5 6 7 8",
  },
  // {
  //   id: 4,
  //   img: require("../../../assets/images/help-page/accountSetting4.png"),
  //   indicator: "7",
  // },
];

// tutor account list
export const searchStudentsSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/searchStudent1.png"),
  },
]

export const contactStudentsSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/contactStudent1.png"),
    indicator: "1 2"
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/contactStudent2.png"),
    indicator: "3"
  },
  {
    id: 3,
    img: require("../../../assets/images/help-page/contactStudent3.png"),
    indicator: "4 5 6"
  },
]

export const createTuitionSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/createTuition1.png"),
    indicator: "1 2 3"
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/createTuition2.png"),
    indicator: "4 5"
  },
]

// student account list
export const searchTutorSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/searchTutor1.png"),
    indicator: "1 2"
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/searchTutor2.png"),
    indicator: "3"
  },
]

export const contactTutorSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/contactTutor1.png"),
    indicator: "1 2"
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/contactTutor2.png"),
    indicator: "3"
  },
  {
    id: 3,
    img: require("../../../assets/images/help-page/contactTutor3.png"),
    indicator: "4 5 6"
  },
]
  
export const confirmTuitionSnaps = [
  {
    id: 1,
    img: require("../../../assets/images/help-page/confirmTuition1.png"),
    indicator: "1 2"
  },
  {
    id: 2,
    img: require("../../../assets/images/help-page/confirmTuition2.png"),
    indicator: "3"
  },
  {
    id: 3,
    img: require("../../../assets/images/help-page/confirmTuition3.png"),
    indicator: "4"
  },
  {
    id: 4,
    img: require("../../../assets/images/help-page/confirmTuition4.png"),
    indicator: "5"
  },
]