import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import PaymentSummaryContain from "../../Component/PaymentSummary";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const PaymentSummery = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="PaymentSummery" title="Payment Info" />
      <PaymentSummaryContain />
    </Fragment>
  );
};

export default PaymentSummery;
