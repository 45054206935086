import React from "react";
import { Row, Col, Input, Label } from "reactstrap";

const TuitionPlace = ({ handlePreferredPlace, tuitionPlace, disabled }) => {
  const radioButtonList = [
    { id: 1, value: "Online", label: "Online" },
    { id: 2, value: "TutorPlace", label: "Tutor's Place" },
    { id: 3, value: "StudentPlace", label: "Student's Place" },
    { id: 4, value: "Other", label: "Other Place" },
  ];

  return (
    <Row
      className="justify-content-between"
      style={{ paddingLeft: "6px", fontSize: "13px" }}
    >
      {radioButtonList.map((button, index) => (
        <Col key={index} sm="6" lg="auto" className="m-b-5">
          <div className="radio radio-primary">
            <Input
              id={`radioinline1${button.id}`}
              type="radio"
              name={`radio2${button.id}`}
              value={button.value}
              checked={button.value === tuitionPlace}
              onChange={handlePreferredPlace}
              disabled={disabled}
            />
            <Label className="mb-0" for={`radioinline1${button.id}`}>
              <span className="digits"> {button.label}</span>
            </Label>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default TuitionPlace;
