import axios from "axios";
import moment from "moment";
import { ScheduleSaveNewApi } from "../../../../api/index";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import { axiosRequest } from "../../../../redux/utils/axios-utils";

export function updateDateInTimeTable(timeTable, newDateStr) {
  // Check if newDateStr is a valid ISO 8601 string
  // if (!/^\d{4}-\d{2}-\d{2}$/.test(newDateStr)) {
  //   throw new Error("newDateStr must be a valid ISO 8601 date string (YYYY-MM-DD)");
  // }

  const newDate = new Date(newDateStr); // Parse ISO string into Date object

  const newDateString = newDate.toISOString().slice(0, 10); // Extract YYYY-MM-DD part

  return timeTable.map(item => ({
    ...item,
    start: newDateString + 'T' + item.start.slice(11),
    end: newDateString + 'T' + item.end.slice(11),
  }));
}

export const getTutorSchedule = async (setTutorOldSchedule, setWeekdayList) => {
  let res = await axiosRequest({
    url: "/newMember/getTutorSchedule",
    method: "get",
  });

  let data = res?.data.tutorSchedules;
  let currentDate = new Date();
  currentDate = currentDate.toISOString();
  data = data.map((weekItems) => {
    const updatedTimeTable = updateDateInTimeTable(weekItems?.timeTable, currentDate);
    return { dayName: weekItems?.dayName, timeTable: updatedTimeTable };
  });
  setTutorOldSchedule(data);
  if (data.length > 0) {
    let tutorScheduleList = [];
    data.map((day) => {
      tutorScheduleList.push({
        ...day,
        isChecked: false,
      });
    });
    setWeekdayList(tutorScheduleList);
  }
};

export const updateTutorProfile = (
  tuitionId,
  selectedDayList,
  tutorOldSchedule
) => {
  const currentSelectedDayList = [...selectedDayList];
  const currentOldSchedule = [...tutorOldSchedule];

  currentSelectedDayList.map((sDay) => {
    currentOldSchedule.map((oDay, oDayIndex) => {
      if (sDay.dayName === oDay.dayName) {
        const { matchedSlot, index } = findMatchedSlot(
          sDay.start,
          sDay.end,
          oDay.timeTable
        );
        oDay.timeTable.splice(index, 1);
        const intervals = splitInterval(
          matchedSlot,
          sDay.start,
          sDay.end,
          tuitionId
        );
        intervals.map((interval) => {
          oDay.timeTable.push(interval);
        });
      }
    });
  });
  updateTutorProfileSchedule(currentOldSchedule);
};

const splitInterval = (interval, excludeStart, excludeEnd, tuitionId) => {
  let start1 = moment(new Date(interval.start)).format("HH:mm");
  let end1 = moment(new Date(interval.end)).format("HH:mm");
  let selected1 = moment(excludeStart).format("HH:mm");
  let selected2 = moment(excludeEnd).format("HH:mm");
  const intervals = [];

  // If the interval is completely within the exclusion range, return an empty array
  if (start1 === selected1 && end1 === selected2) {
    intervals.push({
      start: excludeStart,
      end: excludeEnd,
      isBooked: true,
      tuitionId: tuitionId,
    });
    return intervals;
  }

  if (start1 === selected1 && end1 !== selected2) {
    intervals.push(
      {
        start: interval.start,
        end: excludeEnd.toISOString(),
        isBooked: true,
        tuitionId: tuitionId,
      },
      {
        start: excludeEnd.toISOString(),
        end: interval.end,
        isBooked: false,
      }
    );
    return intervals;
  }

  if (start1 !== selected1 && end1 === selected2) {
    intervals.push(
      {
        start: interval.start,
        end: excludeStart.toISOString(),
        isBooked: false,
      },
      {
        start: excludeStart.toISOString(),
        end: interval.end,
        isBooked: true,
        tuitionId: tuitionId,
      }
    );
    return intervals;
  }

  intervals.push(
    {
      start: interval.start,
      end: excludeStart.toISOString(),
      isBooked: false,
    },
    {
      start: excludeStart.toISOString(),
      end: excludeEnd.toISOString(),
      isBooked: true,
      tuitionId: tuitionId,
    },
    {
      start: excludeEnd.toISOString(),
      end: interval.end,
      isBooked: false,
    }
  );

  return intervals;
};

const findMatchedSlot = (selectedStart, selectedEnd, freeSlots) => {
  let isValidTime = false;
  for (let i = 0; i < freeSlots.length; i++) {
    if (!freeSlots[i].isBooked) {
      let start1 = moment(new Date(freeSlots[i].start)).format("HH:mm");
      let end1 = moment(new Date(freeSlots[i].end)).format("HH:mm");
      let selected1 = moment(selectedStart).format("HH:mm");
      let selected2 = moment(selectedEnd).format("HH:mm");
      let isStartTimeValid = isTimeBetween(start1, end1, selected1, "start");
      let isEndTimeValid = isTimeBetween(start1, end1, selected2, "end");
      if (isStartTimeValid && isEndTimeValid) {
        isValidTime = true;
        const returnObject = {
          index: i,
          matchedSlot: freeSlots[i],
        };
        return returnObject;
      } else {
        isValidTime = false;
      }
    }
  }
  return isValidTime;
};

export const validateTimeSlot = (selectedStart, selectedEnd, freeSlots) => {
  let isValidTime = false;
  if (
    moment(selectedStart).format("HH:mm") ===
    moment(selectedEnd).format("HH:mm")
  ) {
    // toastNotifiy("Start and end time can't be same", "warn");
    return isValidTime;
  }
  for (let i = 0; i < freeSlots.length; i++) {
    let start1 = moment(new Date(freeSlots[i].start)).format("HH:mm");
    let end1 = moment(new Date(freeSlots[i].end)).format("HH:mm");
    let selected1 = moment(selectedStart).format("HH:mm");
    let selected2 = moment(selectedEnd).format("HH:mm");
    let isStartTimeValid = isTimeBetween(start1, end1, selected1, "start");
    let isEndTimeValid = isTimeBetween(start1, end1, selected2, "end");
    if (isStartTimeValid && isEndTimeValid) {
      isValidTime = true;
      break;
    } else {
      isValidTime = false;
    }
  }
  return isValidTime;
};

export const isTimeBetweenUpdated = function (
  startTime,
  endTime,
  selectedTime,
  type
) {
  let start = moment(startTime, "H:mm");
  let end = moment(endTime, "H:mm");
  let selected = moment(selectedTime, "H:mm");
  // console.log(start, end, selected);

  if (end < start) {
    return selected >= start || selected <= end;
  }

  if (type === "start") {
    return (selected > start || selectedTime === startTime) && selected < end;
  }
  if (type === "end") return selected > start && selected <= end;
};

export const isTimeBetween = function (startTime, endTime, selectedTime, type) {
  let start = moment(startTime, "H:mm");
  let end = moment(endTime, "H:mm");
  let selected = moment(selectedTime, "H:mm");

  if (end < start) {
    return selected >= start || selected <= end;
  }

  if (type === "start") {
    return (selected > start || selectedTime === startTime) && selected < end;
  }
  if (type === "end") return selected <= end && selected >= start;
};

const updateTutorProfileSchedule = async (newSchedule) => {
  const res = await axios.post(
    ScheduleSaveNewApi,
    {
      schedules: newSchedule,
    },
    {
      headers: {
        Authorization: authHeader(),
      },
    }
  );
};

// --- validates input fields before storing in DB ---
export const validateTuitionData = (
  selectedTuition,
  budget,
  tuitionPlace,
  selectedDayList,
  timeValidation
) => {
  if (
    selectedTuition === "" ||
    selectedTuition.value === "" ||
    selectedTuition?.length === 0
  ) {
    toastNotifiy("Subject is required", "warn");
    return false;
  } else if (budget === "") {
    toastNotifiy("Tuition fee is required ", "warn");
    return false;
  } else if (parseFloat(budget) < 0.5) {
    toastNotifiy("Please add valid tuition fee", "warn");
    return false;
  } else if (tuitionPlace === "") {
    toastNotifiy("Tuition place is required ", "warn");
    return false;
  } else if (selectedDayList.length < 1) {
    toastNotifiy("Please select your schedule", "warn");
    return false;
  } else if (!timeValidation) {
    toastNotifiy("Please choose valid timeslot", "warn");
    return false;
  }
  // else if (moment(tuitionStartDate).format('MMMM Do YYYY') === moment(tuitionEndDate).format('MMMM Do YYYY')) {
  //   toastNotifiy("Please select a different end date", "warn");
  //   return false;
  // }
  else {
    return true;
  }
};

// it calculates duration between selected start & end time + stores the duration in the day object
export const getDuration = (start, end, day) => {
  let timeDiff = end - start;
  let minutes = moment.duration(timeDiff).minutes();
  let hours = moment.duration(timeDiff).hours();
  day.timeDiff = `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
};

// returns generated string with the rescheduled dates
export const handleExcludeRescheduleString = (excludedClassList) => {
  let excludeRescheduleString = `${excludedClassList
    .map(
      (day, index) =>
        `${day} ${index === excludedClassList.length - 1
          ? ""
          : index === excludedClassList.length - 2
            ? "& "
            : ", "
        }`
    )
    .join("")}`;
  return excludeRescheduleString;
};

export const handleTuitionScheduleString = (selectedDayList) => {
  const dayNameMap = {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  };

  let tuitionScheduleString = `${selectedDayList
    .map(
      (day, index) =>
        `${dayNameMap[day.dayName]}s
        from ${
        // new Date(day.start).toLocaleTimeString([], {
        //   hour: "2-digit",
        //   minute: "2-digit",
        // })
        day.start
        }
        to ${
        // new Date(day.end).toLocaleTimeString([], {
        //   hour: "2-digit",
        //   minute: "2-digit",
        // })
        day.end
        }
        ${index === selectedDayList.length - 1
          ? ""
          : index === selectedDayList.length - 2
            ? " and "
            : ", "
        }`
    )
    .join("")}.`;

  return tuitionScheduleString;
};

export const handleTuitionDateString = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const tuitionDateString = date.toLocaleDateString("en-US", options);
  return tuitionDateString;
};

// returns tuition place, conditionally
export const handleTuitionPlaceString = (tuitionPlace) => {
  if (tuitionPlace === "Online") return "online";
  if (tuitionPlace === "TutorPlace") return "tutor's place";
  if (tuitionPlace === "StudentPlace") return "student's place";
  if (tuitionPlace === "Other") return "other place";
};

// returns date with date, month & year
export const getDateWithoutTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

// returns rescheduledDates list and generated string
export const getRescheduleContent = async (
  rescheduledClasses,
  tuitionStartDate,
  tuitionEndDate,
  selectedDayList
) => {
  const { rescheduleDates } = rescheduledClasses;
  const today = new Date();
  // remove dates before today's date from reschedule date list
  const filteredRescheduledates = rescheduleDates?.filter(
    (reschedule) =>
      getDateWithoutTime(new Date(reschedule?.date)) >=
      getDateWithoutTime(today)
  );
  let excludedClasses = [];
  let excludedClassDatesList = [];
  filteredRescheduledates?.map((reschedule) => {
    const { date, startTime, endTime } = reschedule;
    const rescheduleDate = new Date(date);
    // check if the rescheduled date falls between selected start & end date
    const isDateBetweenTuition =
      getDateWithoutTime(rescheduleDate) >=
      getDateWithoutTime(tuitionStartDate) &&
      getDateWithoutTime(rescheduleDate) <= getDateWithoutTime(tuitionEndDate);
    // get the reschedule day name
    const rescheduleDayName = rescheduleDate
      .toLocaleString("en-US", { weekday: "short" })
      .toLowerCase();
    // check if the rescheduled day name matches with the selected day names
    const isDayBetweenTuition = selectedDayList.find(
      (selected) => selected?.dayName === rescheduleDayName
    );
    // when rescheduled date falls between selected start & end date and the rescheduled day name matches with any of the selected day names
    if (isDateBetweenTuition && isDayBetweenTuition !== undefined) {
      // utcOffset method converts the time to the timezone offset in minutes and then get the hours and minutes
      const rescheduleStart = moment(new Date(startTime))
        .utcOffset(0)
        .format("HH:mm");
      const rescheduleEnd = moment(new Date(endTime))
        .utcOffset(0)
        .format("HH:mm");
      const selectedStart = moment(new Date(isDayBetweenTuition?.start))
        .utcOffset(0)
        .format("HH:mm");
      const selectedEnd = moment(new Date(isDayBetweenTuition?.end))
        .utcOffset(0)
        .format("HH:mm");
      // check if the selected start & end time falls between rescheduled start & end
      const startExist = isTimeBetweenUpdated(
        rescheduleStart,
        rescheduleEnd,
        selectedStart,
        "start"
      );
      const endExist = isTimeBetweenUpdated(
        rescheduleStart,
        rescheduleEnd,
        selectedEnd,
        "end"
      );
      // if the selected time overlaps with the rescheduled time, insert the date string into an array
      if (startExist || endExist) {
        const classDay =
          new Date(rescheduleDate).toLocaleDateString("en-US", {
            weekday: "long",
          }) +
          " " +
          new Date(rescheduleDate).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
        excludedClasses.push(classDay);
        excludedClassDatesList.push(new Date(rescheduleDate));
      }
    }
  });
  // get desired reschedule dates string to display on sweet alert
  let excludeText = handleExcludeRescheduleString(excludedClasses);
  const rescheduleContent = {
    text: excludeText,
    stringList: excludedClasses,
    dateList: excludedClassDatesList,
  };
  return rescheduleContent;
};
