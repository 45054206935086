import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { LI, UL } from "../../../AbstractElements";
import ClassSchedulerContext from "../../../_helper/ClassScheduler";
import ScheduleModal from "./ScheduleModal";

import "./_calendar.css";
import AddFreeClassButton from "./FreeClass/AddFreeClassButton";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);
// Events == Classes
const CalenderViewContain = () => {
  const {
    scheduleEventList,
    getMemberSchedule,
    setScheduleEventList,
    getAllScheduleEvents,
    filterClassList,
    classList,
    getRescheduleFromTutor,
  } = useContext(ClassSchedulerContext);
  const [modal, setModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [newselectedSchedule, setNewSelectedSchedule] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [isTutorReschedule, setIsTutorReschedule] = useState(false);
  const toggle = () => setModal(!modal);
  const { memberType } = useSelector((state) => state.menu) || {};

  const handleSelectEvent = async (item) => {
    var tutorTimeList = await getMemberSchedule(item.tutorId);
    if (memberType === "tutor") {
      setIsTutorReschedule(true);
      setSelectedStudentId(item.studentId);
      setSelectedSchedule({
        ...item,
        memberType: memberType,
        userId: _tuteair?._id,
        tutorTimeTable: tutorTimeList,
        tuitionStartEnd: { classEnd, classStart },
        diffDays: diffDays,
      });
    }

    // var tuitionStartEnd = item?.timeTableHistory?.filter(
    //   (x) =>
    //     x?.studentConfirmation?.status.toUpperCase() === "CONFIRMED" &&
    //     x.tutorConfirmation?.status.toUpperCase() === "CONFIRMED"
    // );

    var classStart = item?.timeTableHistory[0].classStart;
    var topActiveTimeTableHistory = item?.timeTableHistory?.filter(
      (x) => x.isActive
    );
    var classEnd =
      topActiveTimeTableHistory[topActiveTimeTableHistory.length - 1].classEnd;

    // var last_element = item?.timeTableHistory[item?.timeTableHistory.length - 1];

    var today = moment(new Date());
    var endDate = moment(new Date(classEnd));
    const diffTime = endDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setSelectedSchedule({
      ...item,
      memberType: memberType,
      userId: _tuteair?._id,
      tutorTimeTable: tutorTimeList,
      tuitionStartEnd: { classEnd, classStart },
      diffDays: diffDays,
    });
    setModal(true);
  };

  const handleSelectSlot = (item) => {
    // if (item.start > new Date()) {
    //   toggle();
    // }
  };
  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  useEffect(() => {
    getAllScheduleEvents();
    if (isTutorReschedule && modal) {
      getRescheduleFromTutor(selectedStudentId);
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    filterClassList(memberType, _tuteair?._id, scheduleEventList);
  }, [memberType]);
  //
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event?.classStatus?.includes("created") && {
        className: "default_class",
      }),

      ...(event?.classStatus?.includes("rescheduled") && {
        className: "bg-rescheduled",
      }),
      ...(event?.isPaid &&
        event?.tuitionStatus !== "FREE" && {
          className: "bg-paid",
        }),
      ...(!event?.isPaid && {
        className: "bg-unpaid",
      }),
      ...((event?.classStatus?.includes("FREE") || event?.isFreeClass) && {
        className: "bg-free",
      }),
      ...(event?.classStatus?.includes("cancelled") && {
        className: "bg-cancelled",
      }),
      ...(event?.classStatus?.includes("complete") && {
        className: "bg-completed",
      }),
      ...(event?.tuitionStatus?.includes("CLOSED") && {
        style: { textDecoration: "line-through" },
        disabled: event.disabled,
      }),
    }),

    []
  );

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="px-0">
            {modal && (
              <ScheduleModal
                title={"Modal"}
                isOpen={modal}
                toggle={toggle}
                data={selectedSchedule}
                memberType={memberType}
                // tutorTuitions={tutorTuitions}
              />
            )}
            <Card>
              <CardHeader className="pb-0">
                <Row style={{ alignItems: "center" }} className="py-3">
                  <Col md={4}>
                    {memberType === "tutor" && (
                      <AddFreeClassButton></AddFreeClassButton>
                    )}
                  </Col>

                  <Col md={8} style={{ textAlignLast: "right" }}>
                    <div className="simple-list product-color btn_color flex-row"></div>
                    <UL
                      attrUL={{
                        className:
                          "simple-list product-color btn_color flex-row",
                      }}
                    >
                      <LI attrLI={{ className: "bg-info" }}></LI>&nbsp;{" "}
                      <span>Free</span>
                      <LI attrLI={{ className: "bg-success" }}></LI>&nbsp;{" "}
                      <span>Paid</span>
                      <LI attrLI={{ className: "bg-warning" }}></LI>&nbsp;{" "}
                      <span>Unpaid</span>
                      <LI attrLI={{ className: "bg-primary" }}></LI>&nbsp;{" "}
                      <span>Completed</span>
                      {/* <LI attrLI={{ className: "bg-info" }}></LI>&nbsp;
                      <span>Rescheduled</span> */}
                      <LI attrLI={{ className: "bg-danger" }}></LI>&nbsp;{" "}
                      <span>Cancel</span>
                    </UL>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DragAndDropCalendar
                  localizer={localizer}
                  // events={classList}
                  events={classList.filter(
                    (x) =>
                      !(
                        new Date(x.date) > new Date() &&
                        x.isPaid === false &&
                        x.tuitionStatus === "CLOSED"
                      )
                  )}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500, margin: "50px" }}
                  onSelectEvent={(item) => handleSelectEvent(item)}
                  onSelectSlot={(item) => handleSelectSlot(item)}
                  selectable
                  // onEventDrop={moveEvent}
                  showMultiDayTimes
                  eventPropGetter={eventPropGetter}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CalenderViewContain;

/*

{ 
  _id: '', 
  tuition: '', 
  startDate: '',
  endDate: '', 
  repeat: {
    type: 'weekly',  // daily
    occur: 1, // every 1 weeks/days
    days: {}
  }, 
  timeTable: {
    "sat": {
      "start": "10:00AM",
      "end": "12:00PM"
    },
    "sun": {
      "start": "09:00AM",
      "end": "10:00AM"
    }
  },
  reSchedules: [
    {
      date: '',
      startTime: '',
      endTime: '',
      reason: '',
    }
  ],
  completedClass: [
    {
      date: '',
      startTime: '',
      endTime: '',
      isTutorConfirmed: bool,
      isStudentConfirmed: bool,
      isPaid: bool,
      reviews: []
    }
  ], 
  payment: [
    {
      date: '',
    	
    }
  ]
} 

*/
