import React, { Fragment, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import CheckContext from "../_helper/customizer";
import Footer from "./Footer/index";
import Header from "./Header";
import Loader from "./Loader";
import SideBarLayout from "./SideBar-Layout";
import TapTop from "./TapTop/index";
const Layout = ({ children, classNames, ...rest }) => {
  const { sidebar_types, settings, toggleIcon, setToggleIcon, defaultClass, setDefaultClass } = useContext(CheckContext);
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const sidebar_types1 = localStorage.getItem("sidebar_types") || sidebar_types;
  window.addEventListener("resize", () => {
    if (window.innerWidth - 440 <= 575) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }

    if (window.innerWidth <= 992) {
      setDefaultClass(true);
    } else setDefaultClass(false);
  });
  const navigate = useNavigate();

  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));

  if (!auth0_profile) {
    // toastMessage("Your session has been timeout!", "warn");
    navigate(`${process.env.PUBLIC_URL}/login-account`);
  }

  return (
    <Fragment>
      <Loader />
      <TapTop />
      <div className={`page-wrapper ${!defaultClass ? sidebar_types1 : "compact-wrapper"} ${settings1}`} id="pageWrapper">
        <div className={`page-header ${toggleIcon ? "close_icon" : ""}`}>
          <Header />
        </div>
        <div className="page-body-wrapper">
          <div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`}>
            <div>
              <SideBarLayout />
            </div>
          </div>
          <div className="page-body">
            <div>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* <Themecustomizer /> */}
    </Fragment>
  );
};
export default Layout;
