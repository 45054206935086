import React, { Fragment, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import ClassListView from "./ClassListView";

const ClassListViewContain = () => {
  const { classList, isCompleteClassList, setIsCompleteClassList } = useContext(
    ClassSchedulerContext
  );
  const [VerticalTab, setVerticalTab] = useState(
    isCompleteClassList ? "4" : "1"
  );
  const { memberType } = useSelector((state) => state.menu) || {};

  useEffect(() => {
    setIsCompleteClassList(false);
  }, []);

  return (
    <Fragment>
      <Container fluid={true} className="">
        <Row>
          <Col md={4}></Col>
          <Col md="8" className="classListView_btn">
            <Nav
              className="nav nav-pills "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "1" ? "active" : ""}
                  onClick={() => setVerticalTab("1")}
                >
                  <i className="fa fa-table-list"></i>
                  {"Upcoming "}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "2" ? "active" : ""}
                  onClick={() => setVerticalTab("2")}
                >
                  Paid
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "4" ? "active" : ""}
                  onClick={() => setVerticalTab("4")}
                >
                  {"Completed"}
                </NavLink>
              </NavItem>{" "}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "3" ? "active" : ""}
                  onClick={() => setVerticalTab("3")}
                >
                  Unpaid
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "5" ? "active" : ""}
                  onClick={() => setVerticalTab("5")}
                >
                  Cancelled
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" xl="12 box-col-12">
            <TabContent activeTab={VerticalTab}>
              <TabPane tabId="1">
                <ClassListView
                  classList={[
                    ...classList
                      .filter(
                        (x) =>
                          new Date(x.start) >= new Date() &&
                          x.classStatus !== "cancelled" &&
                          x.tuitionStatus !== "CLOSED"
                      )
                      .sort(function (a, b) {
                        return new Date(a.start) - new Date(b.start);
                      }),
                  ]}
                  title="Upcoming classes"
                  memberType={memberType}
                  type="upcoming"
                />
              </TabPane>
              <TabPane tabId="2">
                <ClassListView
                  classList={classList.filter((x) => x.isPaid)}
                  memberType={memberType}
                  title="Paid Class"
                />
              </TabPane>
              <TabPane tabId="3">
                <ClassListView
                  classList={[
                    ...classList.filter(
                      (x) => !x.isPaid && x.tuitionStatus !== "CLOSED"
                    ),
                  ]}
                  memberType={memberType}
                  title="Unpaid Class"
                  type="unpaid"
                />
              </TabPane>
              <TabPane tabId="4">
                <ClassListView
                  classList={classList.filter(
                    (x) =>
                      x.isPaid &&
                      // new Date(x.start) <= new Date() &&
                      new Date(x.end) <= new Date() && //from now on class complete status will be available after class
                      typeof x.cancel !== "object"
                  )}
                  memberType={memberType}
                  title="Completed Class"
                  type="complete"
                />
              </TabPane>
              <TabPane tabId="5">
                <ClassListView
                  classList={classList.filter(
                    (x) => x.classStatus === "cancelled"
                  )}
                  memberType={memberType}
                  title="Cancelled Class"
                  type="cancelled"
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        {/* {memberType === "tutor" && <p>*20% fee will be added to TuteAir</p>} */}
      </Container>
    </Fragment>
  );
};
export default ClassListViewContain;
