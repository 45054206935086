import { createSlice } from "@reduxjs/toolkit";
import { MENUITEMS2 } from "../../../Layout/SideBar-Layout/Menu";

const initialState = {
    memberMenus: [],
    memberType: ""
};

const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        memberMenus: (state, action) => {
            switch (action.payload.memberType) {
                case "tutor":
                    var tutorMenus =  MENUITEMS2?.filter((a) => a.userType === "0" || a.userType === "1");
                    const result = tutorMenus?.map(({ id, menutitle }) => ({ id, menutitle }))
                     state.memberMenus = result;
                    state.memberType = action.payload.memberType;
                    break;
                case "student":
                    var studentMenus =  MENUITEMS2?.filter((a) => a.userType === "0" || a.userType === "2");
                    const result2 =  studentMenus?.map(({ id, menutitle }) => ({ id, menutitle }))
                    state.memberMenus = result2;
                    state.memberType = action.payload.memberType;
                    break;
                default:
                    state.memberMenus = [];
                    break;
            }
        },
        clearMemberMenus: (state) => {
            state.memberMenus = [];
            state.memberType = "";
        },
    },
});

export const { memberMenus, clearMemberMenus } = menuSlice.actions;
export default menuSlice.reducer;
