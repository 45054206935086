import React, { useEffect, useState } from "react";
import Rating from "react-rating";

const StudentRating = (props) => {
  const [behaviour, setBehaviour] = useState(0);
  const [intelligence, setIntelligence] = useState(0);
  const [iq, setIQ] = useState(0);
  useEffect(() => {
    if (props.rating) {
      setBehaviour(props.rating.behaviour);
      setIntelligence(props.rating.intelligence);
      setIQ(props.rating.iq);
    }
  }, []);

  return (
    <table className="table table-borderless" style={{ width: "280px" }}>
      <tr>
        <td className="py-0">
          <span>Behaviour </span>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={behaviour}
            emptySymbol="fa fa-star-o fa-1x"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setBehaviour(rate)}
          ></Rating>
          <span>{behaviour}</span>
        </td>
      </tr>
      <tr>
        <td className="py-0">
          <span>Intelligence </span>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={intelligence}
            emptySymbol="fa fa-star-o fa-1x text-default"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setIntelligence(rate)}
          ></Rating>
          <span>{intelligence}</span>
        </td>
      </tr>
      <tr>
        <td className="py-0">
          <label>IQ </label>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={iq}
            emptySymbol="fa fa-star-o fa-1x"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setIQ(rate)}
          ></Rating>
          <span className="">{iq}</span>
        </td>
      </tr>
    </table>
  );
};

export default StudentRating;
