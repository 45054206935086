import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { Navigate } from "react-router-dom";

const PaypalPaymentModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  const createOrder = (data, actions) => {
    const classPaidItem = props?.classPaidList;

    const purchase_units = classPaidItem?.map((item) => {
      return {
        // reference_id: item._id,
        reference_id: item.tuitionId,
        description: item.subjectName, //item.subjects
        amount: {
          currency_code: "USD",
          value: item?.payableAmount,
        },
      };
    });

    return actions.order
      .create({
        purchase_units: purchase_units,
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (details) {
      const { payer } = details;
      // console.log(details.purchase_units);

      var res = await axiosRequest({
        url: "/payment/paypal-data-submit",
        method: "post",
        data: {
          classes: details.purchase_units,
        },
      });
      props.toggler();
      //setSuccess(true);
      if (res.status == 200) {
        toast.success("Payment successfully completed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          window.open(`${process.env.PUBLIC_URL}/class-list`, "_self");
          //<Navigate to={`${process.env.PUBLIC_URL}/student-class-schedule`} />
        }, 3000);
      }
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
    }
    if (ErrorMessage) {
      alert("Something went wrong");
    }
  }, [success, ErrorMessage]);

  const clientID = `${process.env.PAYPAL_CLIENT_ID}`;
  //console.log(clientID)

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "ATajz5BZ_221NzHvZZ3etIzK9CmjcZ44YxoVakyrDVgtg7sPt555MEqi5abpg5XSf1U7cS-PNwkWuiL3",
      }}
    >
      <Fragment>
        <Modal
          isOpen={props.isOpen}
          toggle={props.toggler}
          centered
          className="modal-md"
          backdrop={"static"}
        >
          <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
          <ModalBody className={"pb-0 " + props.bodyClass}>
            <Col sm="12" xl="12 box-col-12">
              <Card className="mb-0">
                <CardBody className="megaoptions-border-space-sm">
                  <Row>
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
              Close
            </Btn>
          </ModalFooter>
        </Modal>
      </Fragment>
    </PayPalScriptProvider>
  );
};

export default PaypalPaymentModal;
