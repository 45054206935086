import Multiselect from "multiselect-react-dropdown";
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import { Col, Label } from "reactstrap";
import { interestSubjects } from "../../../redux/features/member/memberSlice";
import FilterContext from "../../../_helper/Filter";
import "../../AccountNew/Tutor/InterestPricing/styles.css";
import {
  getSubCategoryList1,
  getSubCategoryList2,
  getSubjectList,
} from "../../AccountNew/Tutor/InterestPricing/subjectInterestCommon";
import axios from "axios";

let { options, optionsOld, height } = getSubjectList();

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options?.indexOf(value) * height;
    const ITEM_SIZE = 55;
    return (
      <List
        height={maxHeight}
        itemCount={children?.length}
        itemSize={ITEM_SIZE}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default function SubjectsFilter({ isReset }) {
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tutionTypeList, setTutionTypeList] = useState([]);
  const dispatch = useDispatch();
  const currentMemberType = localStorage.getItem("swMember");

  const [selectedOptions, setSubjectSelectedOptions] = useState([]);

  const [selectedTutionTypeOptions, setTutionTypeSelectedOptions] = useState(
    []
  );

  const { subjectCategoryList, subjectSubCategoryList } =
    useSelector((state) => state.member) || {};

  //subjects post request values
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const multiselectRef = useRef();

  const {
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    setSubject_category_filter,
    setSubject_subcategory_filter,
    moonlight,
  } = useContext(FilterContext);

  useEffect(() => {
    if (isReset) {
      setCategory("");
      setSubCategory("");
      setSubject_category_filter("");
      setSubject_subcategory_filter("");
      setSubject([]);
      setTutionType([]);
      setSubjectSelectedOptions([]);
      setTutionTypeSelectedOptions([]);
      multiselectRef?.current?.resetSelectedValues();
    }
  }, [isReset]);

  // get all inital values
  const fetchInitialValue = async () => {
    setTutionTypeList([
      "Online",
      "Teachers Place",
      "Students Place",
      "Public Place",
    ]);
  };

  const handleSubjects = async (sub) => {
    if (!sub || sub?.length === 0) {
      setSubject([]);
      setSubjectSelectedOptions([]);
      return;
    }
    setSubject(sub);
    setSubjectSelectedOptions(sub);
    options = options?.filter((x) => x.tag === sub[0]?.tag);
    if (sub[0]?.tag.includes("/")) {
      setCategory({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      setSubject_category_filter({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      var subCatList = await getSubCategoryList2(
        sub[0],
        subjectSubCategoryList
      );

      setSubCategoryList(subCatList);
      setSubCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
      setSubject_subcategory_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
    } else {
      if (!selectedOptions) {
        alert(1);
      }
      setCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
      setSubject_category_filter({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
    }
  };
  const handleCategories = async (item) => {
    setSubjectSelectedOptions([]);
    setSubject([]);
    setCategory(item);
    setSubCategory([]);
    setSubject_category_filter(item);
    setSubject_subcategory_filter({});
    if (!item) {
      setSubCategoryList([]);
      setSubjectSelectedOptions([]);
      setSubject([]);
      setSubject_category_filter({});
      options = optionsOld;
      return;
    }
    options = optionsOld?.filter((x) => x.tag.split("/")[0] === item?.value);

    var subCatList = await getSubCategoryList1(item, subjectSubCategoryList);
    if (subCatList) setSubCategoryList(subCatList);
  };

  const handleSubCategory = async (item) => {
    setSubjectSelectedOptions([]);
    setSubject([]);
    setSubCategory(item);
    setSubject_subcategory_filter(item);
    if (!item) {
      setSubject_subcategory_filter({});
    }
    options = optionsOld.filter((x) => x.tag === item.value);
  };

  useEffect(() => {
    if (!subjectCategoryList) {
      dispatch(
        interestSubjects({
          type: "CATEGORY",
        })
      );
    }
  }, [subjectCategoryList, dispatch]);

  useEffect(() => {
    fetchInitialValue();
    options = optionsOld;
  }, []);

  function mySortFunction(list) {
    var sortedList = list
      ?.slice()
      ?.sort((a, b) => a.label.localeCompare(b.label));
    return sortedList;
  }

  // var categoryList = mySortFunction(subjectCategoryList);
  // var subcategoryList = mySortFunction(subCategoryList);
  // var subjectList = mySortFunction(options);

  // new subject codes
  const [sortedsubjectList, setSortedsubjectList] = useState();

  const [updatedSubjects, setUpdatedSubjects] = useState();
  useEffect(() => {
    fetchSubjects();
  }, []);
  const fetchSubjects = async () => {
    var user = JSON.parse(localStorage.getItem("tuteAirUser"));
    const currentUserToken = user?.accessToken;
    if (!currentUserToken) {
      return;
    }
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL_DEV}/newMember/getAllSubjects`,
      {
        headers: {
          Authorization: `Bearer ${currentUserToken}`,
        },
      }
    );

    if (res && res?.data) {
      setUpdatedSubjects(res?.data);
    }
  };
  useEffect(() => {
    let options = [];
    if (updatedSubjects && updatedSubjects?.length > 0) {
      updatedSubjects?.map((item, i) => {
        options.push({
          value: item.name,
          //label: item.name, //"[ " + item.tag + " ] " +
          label: item.name,
          tag: item.tag,
          id: item.id,
        });
      });
    }

    setSortedsubjectList(mySortFunction(options));
  }, [updatedSubjects]);

  return (
    <Fragment>
      <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
        <h4 className="f_title">
          <span>Subject</span>
        </h4>
        {/* <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Category
          </Label>
          <Col sm="9">
            <Select
              className="darkMode"
              options={categoryList}
              placeholder="Select Category"
              onChange={(item) => handleCategories(item)}
              value={category}
              isClearable={true}
            />
          </Col>
        </div> */}
        {/* <div
          className={`row mb-1 ${subCategoryList?.length > 0 ? "" : "d-none"}`}
        >
          <Label className={`col-sm-3 col-form-label pt-0`} htmlFor="">
            Sub-Category
          </Label>
          <Col sm="9">
            <Select
              className="darkMode"
              options={subcategoryList}
              placeholder="Select Sub Category"
              onChange={(item) => handleSubCategory(item)}
              value={subCategory}
              isClearable={true}
            />
          </Col>
        </div> */}
        <div className="row mb-3 mt-2">
          <Label className="col-sm-3 col-form-label py-0" htmlFor="">
            Subjects :
          </Label>
          <Col sm="12">
            <Select
              isMulti
              // components={{ MenuList }}
              options={sortedsubjectList}
              className="darkMode"
              isClearable={true}
              placeholder="Select Subjects"
              isSearchable={true}
              isDisabled={false}
              isLoading={false}
              closeMenuOnSelect={false}
              filterOption={createFilter({ ignoreAccents: false })}
              onChange={(item) => handleSubjects(item)}
              value={selectedOptions}
            />
          </Col>
        </div>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Tuition Place
          </Label>
          <Col sm="9">
            <Multiselect
              className="darkMode bg-white"
              isObject={false}
              options={tutionTypeList}
              showCheckbox
              onRemove={(e) => {
                setTutionType(e);
              }}
              onSelect={(e) => {
                setTutionType(e);
              }}
              selectedValues={selectedTutionTypeOptions}
              ref={multiselectRef}
            />
          </Col>
        </div>
        {/* {currentMemberType != "tutor" && (
          <hr
            color="#337AB7"
            size="5"
            width="92%"
            style={{ position: "absolute", marginTop: "15px" }}
          />
        )} */}
      </div>
    </Fragment>
  );
}
