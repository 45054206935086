import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const PopUpMessage = (props) => {
  const [toastId, setToastId] = useState(null);
  const totalIncompleteClass = props.classList.length;

  // Show the toast notification when the component is mounted
  useEffect(() => {
    if (totalIncompleteClass > 0) {
      const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Disable autoClose initially
      };
      // Show the toast and store the toastId
      const id = toast.warning(
        `${totalIncompleteClass} classes have been completed, but the class completion status has not been updated`,
        toastOptions
      );

      setToastId(id);

      // Clean up the toast when the component unmounts
      return () => {
        toast.dismiss(id);
      };
    }
  }, [totalIncompleteClass]);

  // Update the toast notification when totalIncompleteClass changes
  useEffect(() => {
    if (totalIncompleteClass && totalIncompleteClass > 0 && toastId) {
      // Update the toast notification with autoClose disabled
      toast.update(toastId, {
        autoClose: false,
      });
    } else {
      // Update the toast notification with autoClose enabled
      toast.update(toastId, {
        autoClose: 3000, // Set autoClose to 3000 milliseconds (3 seconds)
      });
    }
  }, [totalIncompleteClass, toastId]);

  return <div></div>;
};

export default PopUpMessage;
