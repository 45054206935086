import React, { Fragment } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import FindStudentsContainer from "../../Component/FindStudents";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const FindStudents = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="Bookmarks" />
      <FindStudentsContainer />
    </Fragment>
  );
};
export default FindStudents;
