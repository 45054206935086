import { axiosRequest } from "../../../redux/utils/axios-utils";

export const updateTutorNewSchedule = async (tutorId, tutorNewSchedule) => {
  let res = await axiosRequest({
    url: "/newMember/saveTutorSchedules",
    method: "post",
    data: {
      tutorId: tutorId,
      schedules: tutorNewSchedule,
    },
  });
};
