import moment from "moment";
import { getResendEmailResponse } from "../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import SweetAlert from "sweetalert2";

export const filterByTutorName = async (filteredTutors, name) => {
  var temp_tutors = [];

  var nameSplit = name.split(" ");

  const emaptyStringRemove = nameSplit.filter((str) => str !== "");

  for (var i = 0; i < filteredTutors.length; i++) {
    if (
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[0]?.toLowerCase()) ||
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[-1]?.toLowerCase()) ||
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    }
  }
  return temp_tutors;
};

export const filterByAge = async (filteredTutors, age) => {
  var temp_tutors = [];

  for (var i = 0; i < filteredTutors.length; i++) {
    const diffTime = Math.abs(new Date() - new Date(filteredTutors[i].doB));

    var ageDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

    if (ageDur >= age[0] && ageDur <= age[1]) {
      temp_tutors.push(filteredTutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByLanguage = async (tutors, languageArray) => {
  let temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    if (languageArray.every((lan) => tutors[i].languages?.includes(lan))) {
      temp_tutors.push(tutors[i]);
    }
  }
  return temp_tutors;
};

export const filterByWorkExperience = (filteredTutors, wexpFilter) => {
  var temp_tutors = [];

  for (var i = 0; i < filteredTutors.length; i++) {
    var expYearSum = 0;
    for (var j = 0; j < filteredTutors[i].workExperiences.length; j++) {
      if (wexpFilter.org) {
        if (
          filteredTutors[i].workExperiences[j].organization
            ?.toLowerCase()
            .includes(wexpFilter.org.toLowerCase()) > 0
        ) {
          var currentExp = filteredTutors[i].workExperiences[j];
          const diffTime = Math.abs(
            new Date() - new Date(currentExp.startDate)
          );
          var expDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
          if (wexpFilter.expYear == "4+" && expDur >= 4) {
            temp_tutors.push(filteredTutors[i]);
            break;
          } else if (wexpFilter.expYear == "2-4" && expDur >= 2) {
            temp_tutors.push(filteredTutors[i]);
            break;
          } else if (wexpFilter.expYear == "1-2" && expDur >= 1) {
            temp_tutors.push(filteredTutors[i]);
            break;
          } else if (wexpFilter.expYear == "<1") {
            temp_tutors.push(filteredTutors[i]);
            break;
          }
        }
      } else {
        var currentExp = filteredTutors[i].workExperiences[j];
        var diffTime = 0;
        if (currentExp.running) {
          diffTime = Math.abs(new Date() - new Date(currentExp.startDate));
        } else {
          diffTime = Math.abs(
            new Date(currentExp.endDate) - new Date(currentExp.startDate)
          );
        }
        var expDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

        expYearSum += expDur;
      }
    }

    if (!wexpFilter.org) {
      if (wexpFilter.expYear == "4+" && expYearSum > 4) {
        temp_tutors.push(filteredTutors[i]);
      } else if (
        wexpFilter.expYear == "3-4" &&
        expYearSum >= 3 &&
        expYearSum <= 4
      ) {
        temp_tutors.push(filteredTutors[i]);
      } else if (
        wexpFilter.expYear == "1-2" &&
        expYearSum >= 1 &&
        expYearSum <= 2
      ) {
        temp_tutors.push(filteredTutors[i]);
      } else if (wexpFilter.expYear == "<1" && expYearSum < 1) {
        temp_tutors.push(filteredTutors[i]);
      }
    }
  }

  return temp_tutors;
};

export const filterTutorByCourse = async (filteredTutors, course) => {
  var temp_tutors = [];
  for (var i = 0; i < filteredTutors.length; i++) {
    const userString = JSON.stringify(filteredTutors[i]);
    if (userString.indexOf(course) >= 0) {
      temp_tutors.push(filteredTutors[i]);
    }
  }
  return temp_tutors;
};

export const filterTutorByDegree = async (filteredTutors, degree) => {
  var temp_tutors = [];
  for (var i = 0; i < filteredTutors.length; i++) {
    const userString = JSON.stringify(filteredTutors[i]);
    if (userString.indexOf(degree) >= 0) {
      temp_tutors.push(filteredTutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByInstitute = async (filteredTutors, institute) => {
  var temp_tutors = [];
  for (var i = 0; i < filteredTutors.length; i++) {
    const userString = JSON.stringify(filteredTutors[i]);
    if (userString.toLowerCase().indexOf(institute.toLowerCase()) >= 0) {
      temp_tutors.push(filteredTutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByGrade = async (filteredTutors, grade) => {
  var temp_tutors = [];

  for (var i = 0; i < filteredTutors.length; i++) {
    for (var j = 0; j < filteredTutors[i].educations.length; j++) {
      if (
        parseInt(filteredTutors[i].educations[j].marks) >= grade[0] &&
        parseInt(filteredTutors[i].educations[j].marks) <= grade[1]
      ) {
        temp_tutors.push(filteredTutors[i]);
        break;
      }
    }
  }

  return temp_tutors;
};

export const filterByRating = async (
  filteredTutors,
  ratingsTutors,
  ratings
) => {
  var temp_tutors = [];
  filteredTutors?.map((item) => {
    for (var i = 0; i < ratingsTutors.length; i++) {
      const rating = parseFloat(ratingsTutors[i]?.rating);

      if (
        !isNaN(rating) &&
        rating >= parseFloat(ratings[0]) &&
        rating <= parseFloat(ratings[1]) &&
        !temp_tutors.includes(item)
        // Exclude items outside the range [1, 5]
      ) {
        if (ratingsTutors[i].tutorId === item.userId) {
          temp_tutors.push(item);
        }
      }
    }
  });

  return temp_tutors;
};

export const filterByEducation = async (filteredTutors, educationFilter) => {
  if (educationFilter.course) {
    filteredTutors = await filterTutorByCourse(
      filteredTutors,
      educationFilter.course
    );
  }
  if (educationFilter.degree) {
    filteredTutors = await filterTutorByDegree(
      filteredTutors,
      educationFilter.degree
    );
  }
  if (educationFilter.institute) {
    filteredTutors = await filterByInstitute(
      filteredTutors,
      educationFilter.institute
    );
  }
  if (educationFilter.grade) {
    filteredTutors = await filterByGrade(filteredTutors, educationFilter.grade);
  }
  if (educationFilter.passingYear) {
    filteredTutors = filteredTutors.filter((tutor) =>
      Object.values(tutor).indexOf(educationFilter.passingYear)
    );
  }

  return filteredTutors;
};

export const filterByPrice = async (filteredTutors, priceFilter, currency1) => {
  var temp_tutors = [];
  filteredTutors.map((tutor) => {
    tutor?.interestAndPricing.map((pricing) => {
      if (priceFilter.min && priceFilter.max) {
        if (
          pricing?.amount?.initialPrice >= parseFloat(priceFilter.min) &&
          pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)
        ) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
      } else if (priceFilter.min) {
        if (pricing?.amount?.initialPrice >= parseFloat(priceFilter.min)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      } else if (priceFilter.max) {
        if (pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      }
    });
  });
  return temp_tutors;
};

export const filterBySchedule = async (tutors, scheduleFilter) => {
  var temp_tutors = [];

  for (var i = 0; i < tutors.length; i++) {
    for (var j = 0; j < tutors[i].schedules.length; j++) {
      const scheduleObject = tutors[i].schedules[j];
      if (
        (scheduleObject.sat == true && scheduleFilter.sat == true) ||
        (scheduleObject.sun == true && scheduleFilter.sun == true) ||
        (scheduleObject.mon == true && scheduleFilter.mon == true) ||
        (scheduleObject.tue == true && scheduleFilter.tue == true) ||
        (scheduleObject.wed == true && scheduleFilter.wed == true) ||
        (scheduleObject.thu == true && scheduleFilter.thu == true) ||
        (scheduleObject.fri == true && scheduleFilter.fri == true)
      ) {
        if (
          checkInsideLimitRange(
            scheduleObject.from,
            scheduleFilter.from,
            scheduleObject.to,
            scheduleFilter.to
          )
        ) {
          temp_tutors.push(tutors[i]);
          break;
        }
      }
    }
  }

  return temp_tutors;
};

export const checkInsideLimitRange = (
  userFrom1,
  filterFrom1,
  userTo1,
  filterTo1
) => {
  var format = "hh:mm:ss";
  const userFrom = moment(userFrom1.toString().slice(11, 19), format);
  const filterFrom = moment(filterFrom1.toString().slice(16, 24), format);
  const userTo = moment(userTo1.toString().slice(11, 19), format);
  const filterTo = moment(filterTo1.toString().slice(16, 24), format);

  if (
    filterFrom1.toString().slice(16, 24) == "00:00:00" &&
    filterTo1.toString().slice(16, 24) == "23:59:00"
  ) {
    return true;
  } else {
    //var userFromH = parseInt(userFrom1.toString().slice(11, 13)) * 60 + parseInt(userFrom1.toString().slice(14, 16))
    //console.log(parseInt(userFrom1.toString().slice(11, 13)) * 60, parseInt(userFrom1.toString().slice(14, 16)))
    //console.log(filterFrom.isBetween(userFrom, userTo))
    if (
      filterFrom.isBetween(userFrom, userTo) ||
      filterTo.isBetween(userFrom, userTo)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const filterBySubjectCategory = async (tutors, categoryObject) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
      for (
        var l = 0;
        l < tutors[i].interestAndPricing[j].subjects.length;
        l++
      ) {
        if (
          tutors[i].interestAndPricing[j].subjects[l].tag.indexOf(
            categoryObject.value
          ) !== -1
        ) {
          tutor_taken = true;
          temp_tutors.push(tutors[i]);
          break;
        }
      }
      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterBySubject = async (tutors, subjectsArray) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
      for (var k = 0; k < subjectsArray.length; k++) {
        for (
          var l = 0;
          l < tutors[i].interestAndPricing[j].subjects.length;
          l++
        ) {
          if (
            tutors[i]?.interestAndPricing[j]?.subjects[l]?.name
              ?.replace(/\s+/g, "")
              ?.toLowerCase() ===
            subjectsArray[k]?.label?.replace(/\s+/g, "")?.toLowerCase()
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        }
        if (tutor_taken) break;
      }
      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterByTuitionType = async (tutors, tutionType) => {
  return tutors.filter((tutor) =>
    tutionType.some((item) =>
      tutor.interestAndPricing.filter((i) => i.tutionTypes?.indexOf(item) >= 0)
        .length
        ? true
        : false
    )
  );
};

export const fnResendEmail = async ({
  setResendEnabled,
  setIsEmailVerified,
  setCheckEmailVerified,
}) => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  var response = await getResendEmailResponse(_tuteair?._id);
  setResendEnabled(false);
  if (response?.message === "verified") {
    localStorage.setItem(
      "_tuteair_newTutor",
      JSON.stringify({
        ..._tuteair,
        emailVerified: true,
      })
    );
    setIsEmailVerified(true);
    setCheckEmailVerified(true);
    SweetAlert.fire({
      title: `Your email are already verified.`,
      text: "Thank you for being with TuteAir. Complete your profile to find your desired tuitions.",
    });
  } else if (response?.message === "success") {
    SweetAlert.fire({
      title: `Email sent to <u>${_tuteair?.email}<u/>`,
      text: "N.B: If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder",
    });
  }
};

// export const getTopTutors = async () => {
//   var res = await axiosRequest({
//     url: "/users/public/topTutors",
//     method: "get",
//   });
//   if (res.data) {
//     return res.data;
//   }
// };
