import React from "react";
import { Row, Col, Input, Label } from "reactstrap";

const RadioField = ({ handleFunction, value }) => {
  const radioButtonList = [
    { id: 1, value: "male", label: "Male" },
    { id: 2, value: "female", label: "Female" },
    { id: 3, value: "other", label: "Others" }
  ];

  return (
    <div
    className="d-flex justify-content-between"
      style={{ fontSize: "13px", width: "90%"}}
    >
      {radioButtonList.map((button) => (
          <div className="radio radio-primary" key={button.id}>
            <form autoComplete="off">
              <Input
                id={`radioinline1${button.id}`}
                type="radio"
                name={`radio2${button.id}`}
                value={button.value}
                checked={button.value === value}
                onChange={handleFunction}
              />
              <Label className="mb-0" for={`radioinline1${button.id}`}>
                <span className="digits"> {button.label}</span>
              </Label>
            </form>
          </div>
      ))}
    </div>
  );
};

export default RadioField;
