import { apiSlice } from "../api/apiSlice";

export const calendarApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClassEvents: builder.query({
      query: (userId) => `/tuition/all-classes/${userId}`,
    }),
    editCompletedClass: builder.mutation({
      query: (data) => ({
        url: "/tuition/completed-class/",
        method: "PUT",
        body: data.data,
      }),
    }),
    saveReviewRating: builder.mutation({
      query: (data) => ({
        url: "/review/save-review",
        method: "POST",
        body: data.data,
      }),
    }),
    getAllReviewRating: builder.query({
      query: (userId) => `/review/all/${userId}`,
    }),
    getReviewRatingByTuitionId: builder.query({
      query: ({ userId, tuitionId, classId }) =>
        `/review/single/${userId}/${tuitionId}/${classId}`,
    }),
  }),
});

export const {
  useGetAllClassEventsQuery,
  useEditCompletedClassMutation,
  useSaveReviewRatingMutation,
  useGetAllReviewRatingQuery,
  useGetReviewRatingByTuitionIdQuery,
} = calendarApi;
