import React, { useContext, useEffect, useState } from "react";
import { Image } from "../../AbstractElements";
// import logo from "../../assets/images/logo/logo_v2.png";
// import logo1 from "../../assets/images/logo/logo_v2_dark.png";
import { Link } from "react-router-dom";
import CheckContext from "../../_helper/customizer/index";
import logo1 from "../../assets/img/tuteair-logo-dark.svg";
import logo from "../../assets/img/tuteair-logo.svg";
import logo2 from "../../assets/img/logo-education.svg";

const SidebarIcon = () => {
  const { toggleSidebar, setToggleIcon } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    // console.log({ toggleSidebarIcon: toggle });
    setToggle(!toggle);
    toggleSidebar(toggle);
    if (window.innerWidth <= 580 && !toggle) {
      setToggleIcon(!toggle); // added for mobile view double click issue
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 580) {
      setToggleIcon(true); // added for mobile view double click issue
    }
  }, []);
  return (
    <div className="logo-wrapper p-0">
      <a className="d-flex justify-content-center gap-2 align-items-center" href={`${process.env.REACT_APP_NEXT_ROUTE}/`}>
        <img style={{ width: '50px', height: '50px' }} src={logo2} />
        <h1 className="text-black">TuteAir</h1>
        {/* <Image
          attrImage={{
            className: "img-fluid for-light h-100",
            src: `${logo2}`,
            alt: "",
          }}
        /> */}
        {/* <Image
          attrImage={{
            className: "img-fluid for-dark h-100",
            src: `${logo1}`,
            alt: "",
          }}
        /> */}
      </a>
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
    </div >
  );
};
export default SidebarIcon;
