import React from "react";
import { Star } from "react-feather";

const ClassRating = ({ rating }) => {
  return (
    <div className="d-flex  mb-3">
      <Star
        size={18}
        className={`mr-2 ${rating >= 1 ? "text-warning" : "text-dark"}`}
      />
      <Star
        size={18}
        className={`mr-2 ${rating >= 2 ? "text-warning" : "text-dark"}`}
      />
      <Star
        size={18}
        className={`mr-2 ${rating >= 3 ? "text-warning" : "text-dark"}`}
      />
      <Star
        size={18}
        className={`mr-2 ${rating >= 4 ? "text-warning" : "text-dark"}`}
      />
      <Star
        size={18}
        className={`mr-2 ${rating >= 5 ? "text-warning" : "text-dark"}`}
      />
    </div>
  );
};

export default ClassRating;
