import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Label, Input } from "reactstrap";
import stripeImage from "../../../../assets/images/logo/stripe.png";
import paypalImage from "../../../../assets/images/logo/paypal.png";
import bankImage from "../../../../assets/images/logo/bank.png";
import otherImage from "../../../../assets/images/logo/other-payment.png";
import { Form } from 'react-bootstrap';

const CommonMethods = ({
  handleFunction,
  method,
  email,
  setEmail,
  confirmEmail,
  setConfirmEmail,
  phone,
  setPhone,
  accountName,
  setAccountName,
  accountNumber,
  setAccountNumber,
  bankName,
  setBankName,
  branchName,
  setBranchName,
  routingNumber,
  setRoutingNumber,
  swiftCode,
  setSwiftCode,
  currentMethod,
  otherPaymentMethod,
  setOtherPaymentMethod,
  mobileNumber,
  setMobileNumber,
  referenceNumber,
  setReferenceNumber,
  nidNumber,
  setNidNumber,
  mobileNumberOrId,
  setMobileNumberOrId,
  paymentReqLink,
  setPaymentReqLink,
  countryValidity,
  countryOptions,
  saved,
  setSaved,
  error
}) => {

  const commonMethodList = [
    { id: 2, value: "paypal", label: "Paypal", imageSrc: paypalImage },
    { id: 3, value: "bank", label: "Bank Information", imageSrc: bankImage },
  ];

  //for 7 specified countries for payout
  if (countryValidity) {
    commonMethodList.push({ id: 4, value: "others", label: "Other Payout Methods", imageSrc: otherImage })
  }
  // const commonMethodList = [
  //   { id: 1, value: "stripe", label: "Stripe", imageSrc: stripeImage },
  //   { id: 2, value: "paypal", label: "Paypal", imageSrc: paypalImage },
  //   { id: 3, value: "bank", label: "Bank Information", imageSrc: bankImage}
  // ];
  // console.log(otherPaymentMethod);

  return (
    <Col sm="12" className='p-t-25'>
      <div
        className="d-flex justify-content-around p-l-15"
        style={{ fontSize: "13px" }}
      >
        {commonMethodList.map((button) => (
          <div className="radio radio-primary" key={button.id}>
            <Col md="12" className="d-flex align-items-center">
              <Input
                id={`radioinline1${button.id}`}
                type="radio"
                name={`radio2${button.id}`}
                value={button.value}
                checked={button.value === method}
                onChange={handleFunction}
                className="bg-secondary"
                disabled={saved}
              />
              <Label className="mb-0" for={`radioinline1${button.id}`}>
                <div style={{ width: "100px", height: "100px", backgroundColor: "lightgray" }} className="d-flex align-items-center justify-content-center px-3 rounded rounded-4" >
                  <img src={button.imageSrc} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="loading" />
                </div>
                <p className="text-center"> {button.label}</p>
              </Label>
            </Col>
          </div>
        ))}
      </div>
      <Row className='m-t-25'>
        {
          (saved) && (
            <div className="d-flex flex-column justify-content-center text-center">
              <Row>
                <h4>{`Your Current Payout Method Is ${currentMethod === "paypal" ? "Paypal" : currentMethod === "bank" ? "Bank Transfer" : otherPaymentMethod}`}</h4>
              </Row>
              <Row className="m-t-15">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="border-0 d-flex align-items-center"
                    color="warning"
                    onClick={() => {
                      setSaved(false);
                      setOtherPaymentMethod("");
                    }}
                  >
                    Change Method
                  </Button>
                </Col>
              </Row>
            </div>
          )
        }
        {
          // (method === "stripe" || method === "paypal") && (
          !saved && method === "paypal" && (
            <div>
              <h4>{`Paypal Account Information`}</h4>
              <h6>{`Write details connected with your paypal account`}</h6>
              {/* <h4>{`${method === "stripe" ? 'Stripe' : 'Paypal'} Account Information`}</h4>
              <h6>{`Write email connected with your ${method === "stripe" ? 'stripe' : 'paypal'} account`}</h6> */}
              <form autoComplete='off'>
                <Row className='m-t-20'>
                  <Col sm="2">
                    <Label className="mb-0" for="account-name">
                      <span className="digits">Name</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="account-name"
                      type="text"
                      placeholder="Paypal Account User Name"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-20'>
                  <Col sm="2">
                    <Label className="mb-0" for="account-email">
                      <span className="digits">Email</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="account-email"
                      type="email"
                      placeholder="Paypal Account Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-20'>
                  <Col sm="2">
                    <Label className="mb-0" for="confirm-email">
                      <span className="digits">Re-type Email</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="confirm-email"
                      type="email"
                      placeholder="Re-type Email"
                      value={confirmEmail}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-20'>
                  <Col sm="2">
                    <Label className="mb-0" for="phone">
                      <span className="digits">Phone No.</span>
                    </Label>
                  </Col>
                  <Col sm="6">

                    <Input
                      id="phone"
                      type="text"
                      placeholder="Paypal Account Phone No."
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Col>
                </Row>
              </form>
              {/* {
                error && (
                  <Row className='m-t-5 txt-danger'>
                    <Col sm="2">
                    </Col>
                    <Col sm="6">
                      <p>Email doesn't match</p>
                    </Col>
                  </Row>
                )
              } */}
            </div>
          )
        }
        {
          !saved && method === "bank" && (
            <div>
              <h4>{`Bank Account Information`}</h4>
              <h6 className='m-b-20'>{`Please verify all information before writing`}</h6>
              <form autoComplete='off'>
                <Row>
                  <Col sm="4">
                    <Label className="mb-0" for="name">
                      <span className="digits">Account Holder Name</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="name"
                      type="text"
                      placeholder="Account Holder Name"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-10'>
                  <Col sm="4">
                    <Label className="mb-0" for="account-number">
                      <span className="digits">Account Number</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="account-number"
                      type="text"
                      placeholder="Bank Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-10'>
                  <Col sm="4">
                    <Label className="mb-0" for="bank-name">
                      <span className="digits">Bank Name</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="bank-name"
                      type="text"
                      placeholder="Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-10'>
                  <Col sm="4">
                    <Label className="mb-0" for="routing-number">
                      <span className="digits">Bank Routing Number</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="routing-number"
                      type="text"
                      placeholder="Bank Routing Number"
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-10'>
                  <Col sm="4">
                    <Label className="mb-0" for="bank-branch">
                      <span className="digits">Branch</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Input
                      id="bank-branch"
                      type="text"
                      placeholder="Branch Name"
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='m-t-10'>
                  <Col sm="4">
                    <Label className="mb-0" for="swift-code">
                      <span className="digits">SWIFT/BIC Code</span>
                    </Label>
                  </Col>

                  <Col sm="6">
                    <Input
                      id="swift-code"
                      name='swift'
                      type="text"
                      placeholder="Bank SWIFT/BIC Code"
                      value={swiftCode}
                      onChange={(e) => setSwiftCode(e.target.value)}
                      autoComplete='new-password'
                    />
                  </Col>
                </Row>
              </form>
            </div>
          )
        }
        {
          !saved && method === "others" && countryValidity && (
            <div>
              <h4>{`Other Payment Receiving Option`}</h4>
              <h6 className='m-b-20'>{`Please give information associated with your payment receiving account`}</h6>
              <form autoComplete='off'>
                <Row>
                  <Col sm="4">
                    <Label className="mb-0" for="other-payment-option">
                      <span className="digits">Select Other Payment Option</span>
                    </Label>
                  </Col>
                  <Col sm="6">
                    <Form.Select size='lg' onChange={(e) => setOtherPaymentMethod(e.target.value)}>
                      {countryOptions}
                    </Form.Select>
                  </Col>
                </Row>
                {otherPaymentMethod !== "" &&
                  (<Row className='m-t-10'>
                    <Col sm="4">
                      <Label className="mb-0" for="name">
                        <span className="digits">Account Name</span>
                      </Label>
                    </Col>
                    <Col sm="6">
                      <Input
                        id="name"
                        type="text"
                        placeholder="Account Name"
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                      />
                    </Col>
                  </Row>)
                }
                {
                  (otherPaymentMethod !== "" && otherPaymentMethod !== "Khalti" && otherPaymentMethod !== "eSewa") &&
                  <Row className='m-t-10'>
                    <Col sm="4">
                      <Label className="mb-0" for="mobile-number">
                        <span className="digits">Mobile Number</span>
                      </Label>
                    </Col>
                    <Col sm="6">
                      <Input
                        id="mobile-number"
                        type="text"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                      />
                    </Col>
                  </Row>
                }
                {
                  (otherPaymentMethod === "Khalti" || otherPaymentMethod === "eSewa") &&
                  <Row className='m-t-10'>
                    <Col sm="4">
                      <Label className="mb-0" for="mobile-number-or-id">
                        <span className="digits">Mobile Number / {otherPaymentMethod} ID</span>
                      </Label>
                    </Col>
                    <Col sm="6">
                      <Input
                        id="mobile-number-or-id"
                        type="text"
                        placeholder={`Mobile Number / ${otherPaymentMethod} ID`}
                        value={mobileNumberOrId}
                        onChange={(e) => setMobileNumberOrId(e.target.value)}
                      />
                    </Col>
                  </Row>
                }
                {
                  (otherPaymentMethod === "Bkash" || otherPaymentMethod === "Nagad" || otherPaymentMethod === "Rocket") &&
                  <Row className='m-t-10'>
                    <Col sm="4">
                      <Label className="mb-0" for="reference-number">
                        <span className="digits">Reference</span>
                      </Label>
                    </Col>
                    <Col sm="6">
                      <Input
                        id="reference-number"
                        type="text"
                        placeholder="Reference"
                        value={referenceNumber}
                        onChange={(e) => setReferenceNumber(e.target.value)}
                      />
                    </Col>
                  </Row>
                }
              </form>
            </div>
          )
        }
      </Row>
    </Col>
  )
}

export default CommonMethods