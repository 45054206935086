import { AddNotification } from "../../../../Services/Notification.Service";


export const sendNotificationWithMessage = async (notify_title, sender, receiver, selectedConversation, editConversation, message) => {
    const notifyObj = {
      receiver: receiver,
      title: notify_title,
      description: "",
      context: process.env.PUBLIC_URL + "/calendar",
      isPublic: false,
    };
   await AddNotification(notifyObj);
    editConversation({
      sender: sender,
      id: selectedConversation?.conversationId,
      data: {
        message: message,
      },
    });
  };

  export const extendMessage = (date) => {
    const prevDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const prevDay = new Date(date).toLocaleDateString("en-US", {
      weekday: "long",
    });
    return `${prevDate}, ${prevDay}`;
  };