import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, TabContent, TabPane, Table } from "reactstrap";

import { useSelector } from "react-redux";
import ClassSchedulerContext from "../../_helper/ClassScheduler";
import { axiosRequest } from "../../redux/utils/axios-utils";
import SummaryList from "./SummaryList";

const PaymentSummeryContain = () => {
  const [VerticleTab, setVerticleTab] = useState("1");
  const [summeryList, setSummeryList] = useState([]);
  const { classList } = useContext(ClassSchedulerContext);
  const { memberType } = useSelector((state) => state.menu) || {};

  useEffect(() => {
    getPaymentSummery();
  }, []);

  const getPaymentSummery = async () => {
    var res = await axiosRequest({
      url: "/payment/payment-summary/",
      method: "get",
    });
    if (res) {
      setSummeryList(res.data);
    }
  };

  return (
    <>
      <Container fluid={true} className="">
        <Row
        // style={{
        //   height: "85px",
        //   position: "relative",
        //   top: "-45px",
        // }}
        >
          {/* <Col
            md="6"
            className="text-right"
            style={{
              position: "relative",
              bottom: "-40px",
            }}
          >
            <Nav
              className="nav nav-pills "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {true && (
                <>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "1" ? "active" : ""}
                      onClick={() => setVerticleTab("1")}
                    >
                      <i class="fa fa-table-list"></i>
                      All Payment Summary
                    </NavLink>
                  </NavItem>

                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "2" ? "active" : ""}
                      onClick={() => setVerticleTab("2")}
                    >
                      Paid
                    </NavLink>
                  </NavItem> 
                </>
              )}
            </Nav>
          </Col> */}
          <Col md={12} style={{ alignItems: "right" }}>
            <Table>
              <tr>
                <td
                  className="h4"
                  colSpan={6}
                  style={{
                    width: "100%",
                  }}
                ></td>
                <td
                  colSpan={6}
                  style={{
                    width: "100%",
                  }}
                >
                  <tr>
                    <td
                    // colSpan={6}
                    >
                      <strong>Credit</strong>
                    </td>
                    <td>:</td>
                    <td>
                      <span>
                        $
                        {summeryList?.credit
                          ? (summeryList?.credit).toFixed(2)
                          : 0}
                      </span>
                    </td>
                  </tr>
                  {memberType !== "student" && (
                    <>
                      <tr>
                        <td>
                          <strong>Monthend Payable</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <span>
                            ${parseFloat(summeryList.payableAmount).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>:</td>
                    <td>
                      <strong>
                        $
                        {parseFloat(
                          summeryList.credit + summeryList.payableAmount
                        ).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                </td>
              </tr>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="12" xl="12 box-col-12">
            <TabContent activeTab={VerticleTab}>
              <TabPane tabId="1">
                <SummaryList
                  summeryList={summeryList}
                  title="All"
                  memberType={memberType}
                />
              </TabPane>
              <TabPane tabId="2">
                <SummaryList
                  summeryList={summeryList}
                  memberType={memberType}
                  title="Paid"
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentSummeryContain;
