import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seen: false
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    getUpdatedSeenMessage: (state, action) => {
      switch (action.payload.type) {
        case "SEEN_ALL":
          state.seen = action.payload.seen
          break;
        default:
          break;
      }
    },
  },
});

export const { getUpdatedSeenMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
