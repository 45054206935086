import React, { useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import Header from "../Header/Header";
import HelpTab from "./HelpTab";

const HelpContain = () => {
  const { moonlight, setMoonlight } = useContext(FilterContext);

  useEffect(() => {
    document.title = "Get Help | TuteAir - Learn & Teach Globally";
  }, []);

  //--- setting page theme to light mode ---
  useEffect(() => {
    // console.log(moonlight);
    // console.log(localStorage.getItem('layout_version'));
    setMoonlight(false);
    document.body.className = "light";
    localStorage.setItem("layout_version", "light");
  }, []);

  return (
    <>
      <div className="" style={{ background: "#737a8a00" }}>
        {/* #f174171f */}
        {/* <Header />  */}
        <section className="searchPage container px-0">
          <Row className="px-0 search_heading">
            <Col md={8} className="offset-2">
              {/* <h1 className="text-center my-0 mb-2 ta-student">Hello. We are here to help</h1>
              <p className="text-center"></p> */}
            </Col>
          </Row>
        </section>
        <section id="help-text">
          <Row className="px-0">
            <HelpTab></HelpTab>
          </Row>
        </section>
      </div>
    </>
  );
};

export default HelpContain;
