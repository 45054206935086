import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import moment from 'moment';

function Example({
  title,
  classes
}) {
  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
      <Accordion open={open} toggle={toggle}>
        <AccordionItem style={{ border: 0, backgroundColor: 'azure'}}>
          <AccordionHeader targetId="1">{title}</AccordionHeader>
          <AccordionBody accordionId="1">
          {classes.map((cl, i) => (
            <p key={i}>
              {/* <b className="px-2">Class Time:</b> */}
              {moment(new Date(cl.classDate)).format(
                "LL"
              )}{" "}
              {moment(new Date(cl.startTime)).format("LT")}{" "}
              -{" "}{moment(new Date(cl.endTime)).format("LT")}
            </p>
        ))}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
  );
}

export default Example;