import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { toastNotifiy } from "../../../../Services/AuthVerify";

export const validateInterestLearn = ({ subjects, classes }) => {
  if (!subjects || subjects.length === 0) {
    toastNotifiy("Subjects Field Required", "warn");
    return false;
  } else if (!classes) {
    toastNotifiy("Degree Field Required", "warn");
    return false;
  } else {
    return true;
  }
};

export const saveInterestLearn = async ({ subjects, classes }) => {
  var data = {
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
  };
  if (window.confirm("Are you sure to save this information?")) {
    var res = await axiosRequest({
      url: "/newMember/saveInterestToLearn",
      method: "post",
      data: data,
    });
    return res;
  }
};

export const getInterestLearn = async () => {
  var res = await axiosRequest({
    url: "/newMember/membersInfo",
    method: "get",
  });
  if (res.data) {
    return {
      id: res.data._id,
      interestLearn: res.data.interestToLearn,
    };
  }
};

export const deleteSingleInterestLearn = async (id) => {
  var res = await axiosRequest({
    url: "/newMember/deleteInterestToLearn/" + id,
    method: "delete",
  });
  if (res) {
    return res;
  }
};

export const editInterestLearn = async ({ subjects, classes, id }) => {
  var data = {
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
  };
  if (window.confirm("Are you sure to save this information?")) {
    var res = await axiosRequest({
      url: "/newMember/updateInterestToLearn/" + id,
      method: "put",
      data: data,
    });
    return res;
  }
};
