import axios from "axios";
import React, { useState, useEffect } from "react";
import { AllTuitionRequestGetApi } from "../../api";
import { authHeader } from "../../Services/AuthVerify";
import Context from "./index";

const TuitionRequestProvider = (props) => {

    const [tuitionRequestList, setTuitionRequestList] = useState([]);
    const [tuitionRequestToShow, setTuitionRequestToShow] = useState([]);

    const GetAllTuitionRequest = async () => {
        await axios
            .get(AllTuitionRequestGetApi, {
                headers: {
                    Authorization: authHeader(),
                },
            })
            .then((res) => {
                setTuitionRequestList(res.data);
                setTuitionRequestToShow(res.data);
            });
    };

    return (
        <Context.Provider
            value={{
                ...props,
                tuitionRequestList,
                setTuitionRequestList,
                GetAllTuitionRequest,
                tuitionRequestToShow,
                setTuitionRequestToShow
            }}
        >
            {props.children}
        </Context.Provider>
    );
};
export default TuitionRequestProvider;
