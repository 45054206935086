import React, { Fragment } from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Edit } from "react-feather";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H2 } from "../../../AbstractElements";
import { useGetMemberInfoByIdQuery } from "../../../redux/features/member/memberApi";
import InputField from "./AccountComponents/InputField";
import { LanguagesApi } from "../../../api";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import { useUpdateMemberBasicInfoMutation } from "../../../redux/features/member/memberApi";
import { useSearchParams } from "react-router-dom";

const PersonalInfromation = ({ header }) => {
  const { user } = useSelector((state) => state.auth) || {};
  let { data: memberInfo } = useGetMemberInfoByIdQuery(user);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [accountType, setAccountType] = useState({});
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [accountTypeDb, setAccountTypeDb] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [userLanguageList, setUserLanguageList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [saveEnable, setSaveEnable] = useState(false);
  const [bgColor1, setBgColor1] = useState("");
  const [bgColor2, setBgColor2] = useState("");
  let [updateMemberBasicInfo] = useUpdateMemberBasicInfoMutation();

  useEffect(() => {
    if (memberInfo) {
      loadData();
    }
  }, [memberInfo])

  const loadData = () => {
    let { contactNo, name, doB, email, gender, languages, memberType } = memberInfo;
    setPhone(contactNo?.number);
    setFullName(name?.firstName + " " + name?.lastName);
    setFirstName(name?.firstName);
    setLastName(name?.lastName);
    setEmail(email);
    setBirthDate(doB);
    setGender(gender);
    fetchLanguages();
    let tempList = [...languages];
    setUserLanguageList(tempList);
    setAccountType(memberType);
    if (memberType === "tutor") {
      setAccountTypeList(["Tutor"]);
      setAccountType({
        onlyTutor: true,
        onlyStudent: false
      });
      setBgColor1("bg-primary");
    }
    else if (memberType === "student") {
      setAccountTypeList(["Student"])
      setAccountType({
        onlyTutor: false,
        onlyStudent: true
      });
      setBgColor2("bg-primary");
    }
    else {
      setAccountTypeList(["Tutor", "Student"])
      setAccountType({
        onlyTutor: true,
        onlyStudent: true
      });
      // setIsTutorChecked(true);
      setBgColor1("bg-primary");
      // setIsStudentChecked(true);
      setBgColor2("bg-primary");
    }
  }

  const fetchLanguages = async () => {
    const res = await axios.get(LanguagesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.data) {
      let tempList = [];
      for (let i = 0; i < res.data.length; i++) {
        tempList.push(res.data[i].name);
      }
      tempList.sort();
      setLanguageList(tempList);
    }
  };

  const handlePhoneChange = (e) => {
    setSaveEnable(true);
    const phoneRegex = /^[\d+]+$/; // regular expression to match only numbers and + sign
    const phoneValue = e.target.value;
    if (phoneRegex.test(phoneValue)) {
      setPhone(phoneValue);
      setIsValid(true);
    } else {
      setPhone(phoneValue);
      setIsValid(false);
    }
  };

  const handleGenderChange = (e) => {
    setSaveEnable(true);
    setGender(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setSaveEnable(true);
    setUserLanguageList(e);
  };

  const handleAccountType = (e) => {
    setSaveEnable(true);
    const { value, checked } = e.target;
    if (value === "tutor") {
      setAccountType({
        ...accountType, onlyTutor: checked
      })
      !checked && setBgColor1("");
      checked && setBgColor1("bg-primary");
    }
    if (value === "student") {
      setAccountType({
        ...accountType, onlyStudent: checked
      })
      !checked && setBgColor2("");
      checked && setBgColor2("bg-primary");
    }
    // validateAccountType();
  };

  const validateAccountType = async () => {
    if (accountType.onlyTutor && accountType.onlyStudent) {
      setAccountTypeDb("both");
      setAccountTypeList(["Tutor", "Student"])
    }
    else if (accountType.onlyTutor) {
      setAccountTypeDb("tutor");
      setAccountTypeList(["Tutor"]);
    }
    else if (accountType.onlyStudent) {
      setAccountTypeDb("student");
      setAccountTypeList(["Student"]);
    }
    return true;
  }

  const validateFields = () => {
    if (!phone) {
      setSaveEnable(false);
      toastNotifiy("Phone number can't be empty", "warn");
      return false;
    }
    if (userLanguageList.length < 1) {
      setSaveEnable(false);
      toastNotifiy("Language can't be empty", "warn");
      return false;
    }
    if (!gender) {
      setSaveEnable(false);
      toastNotifiy("Gender can't be empty", "warn");
      return false;
    }
    if (!accountType.onlyTutor && !accountType.onlyStudent) {
      setSaveEnable(false);
      toastNotifiy("Account type can't be empty", "warn");
      return false;
    }
    return true;
  }

  const handleSaveChanges = async () => {
    const fieldsValid = validateFields();
    if (fieldsValid) {
      setEdit(false);
      let updatedAccountType = "";
      if (accountType.onlyTutor && accountType.onlyStudent) {
        updatedAccountType = "both";
      }
      else if (accountType.onlyTutor) {
        updatedAccountType = "tutor";
      }
      else if (accountType.onlyStudent) {
        updatedAccountType = "student";
      }
      updateMemberBasicInfo({
        user: user,
        contactNo: phone,
        gender: gender,
        languages: userLanguageList,
        memberType: updatedAccountType,
        firstName: firstName,
        lastName: lastName
      });
      validateAccountType();
    } else {
      return;
    }
    setSaveEnable(false);
  }

  const handleBackButton = () => {
    loadData();
    setEdit(false);
  }

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryEdit = searchParams.get('edit');
    if (queryEdit === 'true') {
      setEdit(true);
    }
  }, [searchParams]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <H2 attrH2={{ className: "txt-primary" }}>{header}</H2>
          {
            !edit && (
              <Button
                className="p-0 bg-none txt-primary border-0 d-flex align-items-center"
                color="transparent"
                onClick={() => setEdit(true)}
              >
                <Edit size={15} />
                <span className="m-l-5">Edit</span>
              </Button>
            )
          }
        </CardHeader>
        {memberInfo ?
          <CardBody>
            <Row style={{ height: "50vh" }}>
              <InputField label="Full Name" selectedValue={fullName}></InputField>
              <InputField label="Birth Date"
                selectedValue={new Date(birthDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}>
              </InputField>
              <InputField label="Email" selectedValue={email}></InputField>
              <InputField
                label="Phone No."
                selectedValue={phone}
                isValid={isValid}
                inputType="input"
                edit={edit}
                handleFunction={handlePhoneChange}
              ></InputField>
              <InputField
                label="Gender"
                selectedValue={gender}
                inputType="radio"
                edit={edit}
                handleFunction={handleGenderChange}
              ></InputField>
              <InputField
                label="Languages"
                selectedValue={userLanguageList}
                languageList={languageList && languageList}
                inputType="multi"
                edit={edit}
                handleFunction={handleLanguageChange}
              ></InputField>
              <InputField
                label="Account Type"
                accountType={accountType}
                accountTypeList={accountTypeList}
                bgColors={{ bgColor1, bgColor2 }}
                inputType="checkbox"
                edit={edit}
                handleFunction={handleAccountType}
              ></InputField>
            </Row>
            {
              edit && (
                <Row className="m-t-15">
                  <Col className="d-flex justify-content-start">
                    <Button
                      className="border-0 d-flex align-items-center"
                      color="dark"
                      onClick={handleBackButton}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="border-0 d-flex align-items-center"
                      color="success"
                      onClick={handleSaveChanges}
                      disabled={!saveEnable || !isValid}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              )
            }
          </CardBody>
          :
          <div className="d-flex align-items-center justify-content-center">
            <p>Loading <i className="fa fa-spinner"></i></p>
          </div>
        }
      </Card>
    </Fragment>
  );
};

export default PersonalInfromation;