import subjects from "../../../../api/subjects.json";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { toastNotifiy } from "../../../../Services/AuthVerify";

export const validateInterestPricing = ({
  classes,
  subjects,
  currency,
  amount,
  tutionTypes,
}) => {
  if (!subjects || subjects.length === 0) {
    toastNotifiy("Subjects Field Required", "warn");
    return false;
  }

  // else if (!classes) {
  //   toastNotifiy("Degree Field Required", "warn");
  //   return false;
  //   // } else if (!currency) {
  //   //   toastNotifiy("Currency Field Required", "warn");
  //   //   return false;
  // }
  // else if (!amount) {
  //   toastNotifiy("Amount Field Required", "warn");
  //   return false;
  // }
  else if (parseFloat(amount) < 0.5) {
    toastNotifiy("Please add valid amount", "warn");
    return false;
  } else if (!tutionTypes) {
    toastNotifiy("Tution Type Field Required", "warn");
    return false;
  } else {
    return true;
  }
};

export const saveInterestPricing = async ({
  subjects,
  specialization,
  classes,
  // currency,
  amount,
  isNegotiable,
  tutionTypes,
}) => {
  var data = {
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
    specializeSubjects: specialization,
    // currency: {
    //   code: currency.code,
    //   id: currency.value,
    //   countryId: currency.countryId,
    //   name: currency.name,
    // },
    tutionTypes: tutionTypes,
    amount: {
      initialPrice: parseFloat(amount),
      confirmedPrice: 0,
      isNegotiable: isNegotiable,
    },
  };
  if (window.confirm("Are you sure to save this information?")) {
    var res = await axiosRequest({
      url: "/newMember/saveInterestToLearn",
      method: "post",
      data: data,
    });
    return res;
  }
};

export const getInterestPricing = async () => {
  var res = await axiosRequest({
    url: "/newMember/membersInfo",
    method: "get",
  });
  if (res.data) {
    return {
      id: res.data._id,
      interestPricing: res.data.interestAndPricing,
    };
  }
};

export const deleteSingleInterestPricing = async (id) => {
  var res = await axiosRequest({
    url: "/newMember/deleteInterestToLearn/" + id,
    method: "delete",
  });
  if (res) {
    return res;
  }
};

export const editInterestPricing = async ({
  subjects,
  specialization,
  classes,
  // currency,
  amount,
  isNegotiable,
  tutionTypes,
  id,
}) => {
  var data = {
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
    specializeSubjects: specialization,
    // currency: {
    //   code: currency.code,
    //   id: currency.value,
    //   countryId: currency.countryId,
    //   name: currency.name,
    // },
    tutionTypes: tutionTypes,
    amount: {
      initialPrice: parseFloat(amount),
      confirmedPrice: 0,
      isNegotiable: isNegotiable,
    },
  };

  if (window.confirm("Are you sure to update this information?")) {
    var res = await axiosRequest({
      url: "/newMember/updateInterestToLearn/" + id,
      method: "put",
      data: data,
    });
    return res;
  }
};

export const getSubCategoryList2 = async (sub, subjectSubCategoryList) => {
  const subCatList = subjectSubCategoryList?.filter(
    (x) => x.split("/")[0] === sub?.tag.split("/")[0]
  );

  if (subCatList) {
    let subCategoryObjList = [];
    subCatList?.map((item, index) => {
      subCategoryObjList.push({
        value: item.split("/")[0] + "/" + item.split("/")[1],
        label: item.split("/")[1],
        isDisabled: false,
      });
    });

    subCategoryObjList = subCategoryObjList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    return subCategoryObjList;
  }
};

export const getSetEditInterestSubjectData = async (
  subjectSubCategoryList,
  tag,
  selectedtSubjects1,
  spSubjects,
  options,
  optionsOld
) => {
  const subCatList = subjectSubCategoryList?.filter(
    (x) => x.split("/")[0] === tag.split("/")[0]
  );
  if (subCatList) {
    let subCategoryObjList = [];
    subCatList?.map((item, index) => {
      subCategoryObjList.push({
        value: item.split("/")[0] + "/" + item.split("/")[1],
        label: item.split("/")[1],
        isDisabled: false,
      });
    });
    // setSubCategoryList(subCategoryObjList);

    options = optionsOld?.filter((x) => x.tag === tag);
    var selectedSubjects = [];
    var selectedSpecializeSubjects = [];

    selectedtSubjects1?.map((item, index) => {
      selectedSubjects.push({
        value: item.name,
        label: "[ " + item.tag + " ] " + item.name,
        tag: item.tag,
        id: item.id,
      });
    });
    // setSubjectSelectedOptions(subj3);

    spSubjects?.map((item, index) => {
      selectedSpecializeSubjects.push({
        value: item.name,
        label: "[ " + item.tag + " ] " + item.name,
        tag: item.tag,
        id: item.id,
      });
    });
    // setSubjectSpecialSelectedOptions(spSubjects1);
    return {
      subCategoryObjList2: subCategoryObjList,
      selectedSubjects,
      selectedSpecializeSubjects,
    };
  }
};

export const getSubCategoryList1 = async (item, subjectSubCategoryList) => {
  const subCatList = subjectSubCategoryList?.filter(
    (x) => x.split("/")[0] === item.value
  );
  if (subCatList) {
    let subCategoryObjList = [];
    subCatList?.map((item, index) => {
      if (item.split("/").length > 1) {
        subCategoryObjList.push({
          value: item.split("/")[0] + "/" + item.split("/")[1],
          label: item.split("/")[1],
          isDisabled: false,
        });
      }
    });
    return subCategoryObjList;
  }
};

export const getSubjectList = () => {
  let options = [];
  let optionsOld = [];
  const calculateHeight = `${window.innerWidth > 550 ? 35 : 55}`;
  const height = Number(calculateHeight);

  subjects?.map((item, i) => {
    options.push({
      value: item.name,
      label: item.name,
      tag: item.tag,
      id: item.id,
    });
  });
  // options = options?.slice().sort((a, b) => a.label.localeCompare(b.label));
  optionsOld = options;
  return {
    options,
    optionsOld,
    height,
  };
};
