import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    confirmedTutorTuitionList: undefined
};

const confirmedTuitionSlice = createSlice(
{
    name: "confirmedTuition",
    initialState,
    reducers: {
        confirmedTutorTuitions: (state, action) => {
            state.confirmedTutorTuitionList = action.payload.confirmedTutorTuitionList;
        },
    },
}
);

export const {confirmedTutorTuitions} = confirmedTuitionSlice.actions;
export default confirmedTuitionSlice.reducer;
