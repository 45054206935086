import React, { Fragment, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { RRule } from "rrule";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import moment from "moment";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import { ArrowRight, X } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Badges, Btn, P } from "../../../../AbstractElements";
import {
  useEditConversationMutation,
  useGetConversationsQuery,
} from "../../../../redux/features/chat/conversations/conversationsApi";
import { useGetRescheduledClassesByTutorIdQuery } from "../../../../redux/features/tuition/tuitionApi";
import { toastNotifiy } from "../../../../Services/AuthVerify";
import { AddNotification } from "../../../../Services/Notification.Service";
import "../../../../style/custom-css/PersonalInfo.css";
import TuitionPlace from "./TuitionComponents/TuitionPlace";
import { TuitionDate } from "./TuitionComponents/TuitionDate";
import {
  handleTuitionScheduleString,
  handleTuitionDateString,
  handleTuitionPlaceString,
  getRescheduleContent,
  getDateWithoutTime,
  handleExcludeRescheduleString,
  isTimeBetweenUpdated,
} from "./tuitionCommonFns";
import SweetAlert from "sweetalert2";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import { updateTutorNewSchedule } from "../../../Common/scheduleUpdate/UpdateTutorNewSchedule";

const AllTuitionsModalStudent = ({
  title,
  isOpen,
  toggler,
  subjectList,
  conversationId,
  selectedStudent,
  receiverId,
  tutorCurrency,
  selectedTutorName,
  allTuitions,
  screenWidth,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setmodalOpen] = useState(isOpen);

  // const { selectedUser } = useSelector((state) => state.conversations) || {};
  let { data: conversations, isError, error } = useGetConversationsQuery(user);
  const { memberType } = useSelector((state) => state.menu) || {};

  // post
  const [selectedTuition, setSelectedTuition] = useState("");
  const [tuitionPlace, setTuitionPlace] = useState("");
  const [budget, setBudget] = useState("");
  const [currency, setCurrency] = useState("");
  const [tuitionStartDate, setStartDate] = useState(new Date());
  const [tuitionEndDate, setTuitionEndDate] = useState(new Date());
  const { user: loggedInUser } = useSelector((state) => state.auth) || {};
  const navigate = useNavigate();
  const params = useParams();

  const [myTuitions, setMyTuitions] = useState([]);
  const [selectedTuitionSchedule, setSelectedTuitionSchedule] = useState([]);
  const [selectedTuitionInfo, setSelectedTuitionInfo] = useState({});
  const [timeTableHistoryInfo, setTimeTableHistoryInfo] = useState("");
  const [tuitionStatus, setTuitionStatus] = useState("OPEN");
  const [content, setContent] = useState("main");
  const [declineMessage, setDeclineMessage] = useState("");
  const [declineMessageError, setDeclineMessageError] = useState(true);
  const [hasSubject, setHasSubject] = useState(false);
  const [overLappedDays, setOverLappedDays] = useState([]);
  const [singleClassDays, setSingleClassDays] = useState([]);

  const [
    editConversation,
    { isSuccess: isEditConversationSuccess, error: err2 },
  ] = useEditConversationMutation();

  const {
    data: rescheduledClasses,
    isLoading,
    refetch,
  } = useGetRescheduledClassesByTutorIdQuery(receiverId);

  const { getAllScheduleEvents, scheduleEventList } = useContext(
    ClassSchedulerContext
  );

  //validate budget

  const intitalFetch = () => {
    var arrList = [];
    var myTuitions = [...allTuitions];
    myTuitions?.map((item, index) => {
      var subjects = "";
      let tag = "";
      if (item.tuitionRequest?.subjects) {
        item.tuitionRequest?.subjects?.map((item2, index2) => {
          var comma =
            item.tuitionRequest?.subjects?.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.label?.toString().trim() + comma;
          tag = item2?.tag;
        });
      } else {
        item.interestedSubjects?.subjects?.map((item2, index2) => {
          var comma =
            item.interestedSubjects?.subjects?.length - 1 === index2
              ? ""
              : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
          tag = item2?.tag;
        });
      }
      arrList.push({
        info: item,
        value: item._id,
        label: subjects + " >> " + tag,
        isDisabled: false,
        type: item.sender,
        status: item.status,
      });
    });
    setMyTuitions(arrList);
  };

  useEffect(() => {
    intitalFetch();
    getAllScheduleEvents();
  }, []);

  useEffect(() => {
    if (selectedTuition) {
      const tuitionInfo = selectedTuition?.info;
      setSelectedTuitionInfo(tuitionInfo);
      setTuitionStatus(tuitionInfo?.status);
      setBudget(tuitionInfo?.confirmedTuitionFee);
      setCurrency(tuitionInfo?.currency?.name);
      setTuitionPlace(tuitionInfo?.tuitionPlace);
      if (tuitionInfo?.timeTableHistory) {
        let history = tuitionInfo?.timeTableHistory;
        let tuitionLength = history.length - 1;
        let currentTuition = history[tuitionLength];
        setTimeTableHistoryInfo(currentTuition);
        handleStartDate(new Date(currentTuition?.classStart));
        handleEndDate(new Date(currentTuition?.classEnd));
        // setSelectedTuitionSchedule(currentTuition?.timeTable);
        setSelectedTuitionSchedule(updateDateInTimeTable(currentTuition?.timeTable, currentTuition?.classStart));
        // if(tuitionInfo?.status === "OPEN" || tuitionInfo?.status === "DECLINED") {
        //   const overlappedDays =  verifyStudentEventList(currentTuition?.timeTable, new Date(currentTuition?.classStart), new Date(currentTuition?.classEnd));
        //   console.log("overlappedDays", overlappedDays);
        //   setOverLappedDays(overlappedDays);
        // }
        // else {
        //   setOverLappedDays([]);
        // }
      } else {
        handleStartDate(new Date());
        handleEndDate(new Date());
      }
    }
  }, [selectedTuition]);
  //above

  useEffect(() => {
    if (timeTableHistoryInfo && scheduleEventList?.length > 0) {
      if (tuitionStatus === "OPEN" || tuitionStatus === "DECLINED") {
        const overlappedDays = verifyStudentEventList(
          timeTableHistoryInfo?.timeTable,
          new Date(timeTableHistoryInfo?.classStart),
          new Date(timeTableHistoryInfo?.classEnd)
        );
        // console.log("overlappedDays", overlappedDays);
        setOverLappedDays(overlappedDays);
      } else {
        setOverLappedDays([]);
      }
    }
  }, [timeTableHistoryInfo, scheduleEventList]);

  useEffect(() => {
    fnSetInitialField();
  }, []);

  const fnSetInitialField = async () => {
    setLoading(true);
    var tuitionList = [];
    if (subjectList) {
      subjectList?.map((item, index) => {
        var subjects = "";
        item.subjects.map((item2, index2) => {
          var comma = item.subjects.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
        });
        tuitionList.push({
          value: item.id,
          label: subjects + " ", //+ item.amount,
          item: item,
        });
      });
    }
    setLoading(false);
  };

  const handlePreferredPlace = (e) => {
    setTuitionPlace(e.target.value);
  };

  const handleEndDate = (date) => {
    setTuitionEndDate(new Date(date));
  };

  const getAllSchedulesByRRule = ({
    startDate,
    endDate,
    repeat,
    timeTable,
  }) => {
    // console.log("startDate", startDate, "endDate", endDate);
    var byWeeks = getByWeekDays(timeTable);
    const startYear = new Date(startDate).getFullYear();
    const startMonth = new Date(startDate).getMonth();
    const startDateTemp = new Date(startDate).getDate();
    const startHour = new Date(startDate).getHours();
    const startMinutes = new Date(startDate).getMinutes();
    const startSeconds = new Date(startDate).getSeconds();

    const endYear = new Date(endDate).getFullYear();
    const endMonth = new Date(endDate).getMonth();
    const endDateTemp = new Date(endDate).getDate();
    const endHour = new Date(endDate).getHours();
    const endMinutes = new Date(endDate).getMinutes();
    const endSeconds = new Date(endDate).getSeconds();

    const rule = new RRule({
      freq:
        repeat?.type === "weekly"
          ? RRule.WEEKLY
          : repeat?.type === "daily"
            ? RRule.DAILY
            : repeat?.type === "monthly"
              ? RRule.MONTHLY
              : RRule.YEARLY,
      interval: repeat?.interval,
      byweekday: repeat?.type === "weekly" ? byWeeks : [],
      dtstart: new Date(
        Date.UTC(
          startYear,
          startMonth,
          startDateTemp,
          startHour,
          startMinutes,
          startSeconds
        )
      ),
      until: new Date(
        Date.UTC(
          endYear,
          endMonth,
          endDateTemp,
          endHour,
          endMinutes,
          endSeconds
        )
      ),
    });
    var dates = rule.all();
    return dates;
  };

  const getByWeekDays = (timeTable) => {
    var weekDays = [];
    timeTable?.map((item) => {
      if (item.dayName === "sat") {
        weekDays.push(RRule.SA);
      } else if (item.dayName === "sun") {
        weekDays.push(RRule.SU);
      } else if (item.dayName === "mon") {
        weekDays.push(RRule.MO);
      } else if (item.dayName === "tue") {
        weekDays.push(RRule.TU);
      } else if (item.dayName === "wed") {
        weekDays.push(RRule.WE);
      } else if (item.dayName === "thu") {
        weekDays.push(RRule.TH);
      } else if (item.dayName === "fri") {
        weekDays.push(RRule.FR);
      }
    }); // if (timeTable.hasOwnProperty("sat")) weekDays.push(RRule.SA);
    return weekDays;
  };

  function getWeeklySchedules(date, timeTable) {
    var weekDay = moment(date).format("dddd");
    var startEnd = null;
    timeTable?.map((x) => {
      if (x.dayName.toUpperCase() === weekDay.substring(0, 3).toUpperCase()) {
        startEnd = formatStartEndTime(date, x);
      }
      return startEnd;
    });
    return startEnd;
  }

  const studentTuitionConfirmation = async (eventList) => {
    const freeClassCount = Math.floor(eventList.length / 2);
    let res = await axiosRequest({
      url: "/tuition/confirm-timetable-status",
      method: "put",
      data: {
        tuitionId: selectedTuitionInfo?._id,
        timeTableId: timeTableHistoryInfo?._id,
        studentConfirmation: {
          status: "confirmed",
          date: new Date(),
        },
        classSchedules: eventList,
        freeClassCount: freeClassCount,
      },
    });

    if (res.status === 200) {
      SweetAlert.fire(
        "Tuition has been confirmed successfully!",
        "",
        "success"
      );
      const notifyObj = {
        receiver: receiverId,
        title: "Your tuition has been confirmed",
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/calendar",
        isPublic: false,
      };
      AddNotification(notifyObj);
      setLoading(false);
      const tuitionLabel = selectedTuition.label;
      const sliced = tuitionLabel.substring(0, tuitionLabel.lastIndexOf(" "));
      const tuitionStartDateString = handleTuitionDateString(
        new Date(timeTableHistoryInfo.classStart)
      );
      const tuitionEndDateString = handleTuitionDateString(
        new Date(timeTableHistoryInfo.classEnd)
      );
      const tuitionScheduleString = handleTuitionScheduleString(
        selectedTuitionSchedule
      );
      const tuitionPlaceString = handleTuitionPlaceString(tuitionPlace);
      editConversation({
        sender: loggedInUser,
        id: conversationId,
        data: {
          message: `Tuition has been confirmed for "${sliced}" with a fee of ${budget} USD. The location for the tuition is ${tuitionPlaceString}, and the tuition will run from ${tuitionStartDateString} to ${tuitionEndDateString}. The schedule for the tuition will be on ${tuitionScheduleString} Please click on the notification to get the tuition details.`,
        },
      });
      toastNotifiy("Tuition Confirmed", "success");
      navigate(`${process.env.PUBLIC_URL}/class-list`);
    }
  };

  function formatStartEndTime(date, time) {
    var dt1 = moment(date).format("YYYY-MM-DD");
    var _start = moment(time?.start).format("LT");
    var _end = moment(time?.end).format("LT");
    var start = moment(`${dt1} ${_start}`, "YYYY-MM-DD HH:mm aa").format();
    var end = moment(`${dt1} ${_end}`, "YYYY-MM-DD HH:mm aa").format();
    return { start, end };
  }

  const handleTuitionConfirmation = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectedTuitionInfo) {
      if (rescheduledClasses) {
        var rescheduleContent = await getRescheduleContent(
          rescheduledClasses,
          tuitionStartDate,
          tuitionEndDate,
          selectedTuitionSchedule
        );
        var { text, stringList, dateList } = rescheduleContent;
      }
      // console.log('single', singleClassDays);
      let studentSingleDayEventsString = "";
      if (singleClassDays?.length > 0) {
        const formattedClassDates = singleClassDays?.map((classDate) => {
          const classDay =
            new Date(classDate).toLocaleDateString("en-US", {
              weekday: "long",
            }) +
            " " +
            new Date(classDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          return classDay;
        });
        // console.log("formattedClassDates", formattedClassDates);
        studentSingleDayEventsString =
          handleExcludeRescheduleString(formattedClassDates);
      }
      // console.log("studentSingleDayEventsString", studentSingleDayEventsString);
      const finalSingledayList = [
        ...(dateList ?? []),
        ...(singleClassDays ?? []),
      ];
      // console.log("finalSingledayList", finalSingledayList);
      const eventString = singleClassDays?.length > 1 ? "events" : "event";
      const studentArticle = singleClassDays?.length === 1 ? "a" : "";
      const studentWarningText =
        singleClassDays?.length > 0
          ? `You have ${studentArticle} scheduled ${eventString} at the selected time on <p><b>${studentSingleDayEventsString}</b>.</p>`
          : "";
      const classString = stringList?.length > 1 ? "classes" : "class";
      const dayString = stringList?.length > 1 ? "days" : "day";
      const article = stringList?.length === 1 ? "a" : "";
      const tutorWarningText =
        stringList?.length > 0
          ? `Your tutor has ${article} rescheduled ${classString} at the selected time on <p><b>${text}</b>.</p>
      So, no ${classString} will be created for this tuition on the mentioned ${dayString}.`
          : "";
      const warningText = `${studentWarningText} ${tutorWarningText}`;
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
        title: "Are you sure to confirm the tuition?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
        html: warningText,
        width: "500px",
      }).then(async (result) => {
        if (result.value) {
          // create tuition classes and save into DB
          await createClasses(finalSingledayList);
        } else {
        }
      });
    }
  };

  const verifyStudentEventList = (timeTable, classStart, classEnd) => {
    let filteredScheduleDays = [];
    let singleDayClasses = [];
    // get the event dates falling between classStart and classEnd
    const eventsBetweenRange = scheduleEventList.filter((event) => {
      const { date, tuitionStatus, classStatus } = event;
      const isDateInRange =
        new Date(date).setHours(0, 0, 0, 0) >=
        new Date(classStart).setHours(0, 0, 0, 0) &&
        new Date(date).setHours(0, 0, 0, 0) <=
        new Date(classEnd).setHours(0, 0, 0, 0);
      if (
        isDateInRange &&
        tuitionStatus !== "CLOSED" &&
        classStatus !== "cancelled"
      )
        return event;
    });
    // console.log("eventsBetweenRange", eventsBetweenRange.sort((a, b) => new Date(a?.date) - new Date(b?.date)));

    timeTable.forEach((time) => {
      const { dayName, start: timeTableStart, end: timeTableEnd } = time;
      const filteredEvents = eventsBetweenRange?.filter((event) => {
        const { date, start, end, isFreeClass, isRescheduled } = event;
        // Check if the dayName matches
        const isDayNameMatch =
          dayName ===
          new Date(date)
            .toLocaleString("en-US", { weekday: "short" })
            .toLowerCase();
        if (isDayNameMatch) {
          const eventStart = moment(new Date(start)).format("HH:mm");
          const eventEnd = moment(new Date(end)).format("HH:mm");
          const selectedStart = moment(new Date(timeTableStart)).format(
            "HH:mm"
          );
          const selectedEnd = moment(new Date(timeTableEnd)).format("HH:mm");
          const isStartTimeInRange = isTimeBetweenUpdated(
            eventStart,
            eventEnd,
            selectedStart,
            "start"
          );
          const isEndTimeInRange = isTimeBetweenUpdated(
            eventStart,
            eventEnd,
            selectedEnd,
            "end"
          );
          // console.log("c", new Date(start), isStartTimeInRange, isEndTimeInRange);
          // add to the single class day list if the class is rescheduled or free and time falls between start-end and
          if (
            (isStartTimeInRange || isEndTimeInRange) &&
            (isFreeClass || isRescheduled)
          )
            singleDayClasses.push(new Date(date));
          // add to the filtered list if the time falls between start and end
          if (
            (isStartTimeInRange || isEndTimeInRange) &&
            !isFreeClass &&
            !isRescheduled
          )
            return event;
        }
      });
      // console.log('filteredEvents', filteredEvents.sort((a, b) => new Date(a.start) - new Date(b.start)));
      const uniqueDayNames = [
        ...new Set(
          filteredEvents.map((event) =>
            event.date
              .toLocaleString("en-US", { weekday: "short" })
              .toLowerCase()
          )
        ),
      ];
      // console.log("uniqueDayNames:", uniqueDayNames);

      filteredScheduleDays.push(...uniqueDayNames);
    });
    // console.log("singleDayClasses", singleDayClasses?.sort((a, b) => new Date(a?.date) - new Date(b?.date)));
    setSingleClassDays(singleDayClasses);
    return filteredScheduleDays;
  };

  function updateDateInTimeTable(timeTable, newDateStr) {
    // Check if newDateStr is a valid ISO 8601 string
    // if (!/^\d{4}-\d{2}-\d{2}$/.test(newDateStr)) {
    //   throw new Error("newDateStr must be a valid ISO 8601 date string (YYYY-MM-DD)");
    // }

    const newDate = new Date(newDateStr); // Parse ISO string into Date object

    const newDateString = newDate.toISOString().slice(0, 10); // Extract YYYY-MM-DD part

    return timeTable.map(item => ({
      ...item,
      start: newDateString + 'T' + item.start.slice(11),
      end: newDateString + 'T' + item.end.slice(11),
    }));
  }

  const createClasses = async (dateList = []) => {
    const { timeTable, classStart, classEnd } = timeTableHistoryInfo;
    const updatedTimeTable = updateDateInTimeTable(timeTable, classStart)
    const dayStartTime = new Date(new Date().setHours(0, 0, 0, 0));
    const dayEndTime = new Date(new Date().setHours(23, 59, 59, 0));
    const startMoment = moment(new Date(classStart));
    const startTimeMoment = moment(dayStartTime).utcOffset(
      startMoment.utcOffset()
    );
    // setting the time of the start date at 12 AM to resolve tuition not confirm due to time issue
    startMoment.set({
      hour: startTimeMoment.hour(),
      minute: startTimeMoment.minute(),
      second: startTimeMoment.second(),
      millisecond: startTimeMoment.millisecond(),
    });
    const endMoment = moment(new Date(classEnd));
    const endTimeMoment = moment(dayEndTime).utcOffset(endMoment.utcOffset());
    // setting the time of the end date at 11:59 PM to resolve tuition not confirm due to time issue
    endMoment.set({
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
      millisecond: endTimeMoment.millisecond(),
    });

    const updatedStartDate = startMoment.toISOString();
    const updatedEndDate = endMoment.toISOString();
    // console.log("s", new Date(updatedStartDate));
    // console.log("e", new Date(updatedEndDate));

    let obj = {
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      repeat: { type: "weekly", interval: 1 },
      timeTable: updatedTimeTable,
    };
    let allSchedules = getAllSchedulesByRRule(obj);
    console.warn("allSchedules", allSchedules);
    let filteredSchedules = [];
    if (dateList.length > 0) {
      filteredSchedules = allSchedules?.filter((schedule) => {
        return !dateList?.some((date) => {
          const scheduleDate = new Date(schedule.getTime());
          const dateOnly = new Date(getDateWithoutTime(date));
          const scheduleDateOnly = new Date(getDateWithoutTime(scheduleDate));
          return dateOnly.getTime() === scheduleDateOnly.getTime();
        });
      });
    } else {
      filteredSchedules = [...allSchedules];
    }
    let eventList = [];
    filteredSchedules?.map((date) => {
      var weekDays = getWeeklySchedules(new Date(date), updatedTimeTable);
      eventList.push({
        date: new Date(weekDays.start),
        startTime: new Date(weekDays.start),
        endTime: new Date(weekDays.end),
        isPaid: false,
      });
    });
    console.warn("eventList", eventList);
    if (eventList?.length > 300) {
      toastNotifiy(
        "Sorry, more than 300 classes cannot be created! You can extend the tuition later.",
        "warn"
      );
    } else if (eventList?.length === 0) {
      toastNotifiy("No class has been created!", "warn");
    } else {
      studentTuitionConfirmation(eventList);
    }
  };

  const handleDecline = () => {
    setContent("decline");
  };

  const handleSubjects = (item) => {
    if (!item) {
      setSelectedTuition([]);
      return;
    }
    setSelectedTuition(item);
  };

  useEffect(() => {
    if (selectedTuition?.length === 0) {
      setHasSubject(false);
    } else {
      setHasSubject(true);
    }
  }, [selectedTuition]);

  const getDetails = (tuition, memberType) => {
    const {
      _id: tuitionId,
      student: studentId,
      tutor: tutorId,
      interestedSubjects,
      timeTableHistory,
    } = tuition;

    const subjectName = interestedSubjects?.subjects
      .map((subject) => subject.name)
      .join(" - ");
    const selectedConversation =
      memberType === "tutor"
        ? conversations?.find((c) => c.receiverId === studentId)
        : conversations?.find((c) => c.receiverId === tutorId);
    const receiverId = memberType === "tutor" ? studentId : tutorId;
    const tuitionSchedule =
      timeTableHistory[timeTableHistory.length - 1]?.timeTable;
    const details = {
      tuitionId: tuitionId,
      conversationId: selectedConversation?.conversationId,
      subjectName: subjectName,
      receiverId: receiverId,
      tutorId: tutorId,
      tuitionSchedule: tuitionSchedule,
    };
    return details;
  };

  const sendDecline = async () => {
    if (declineMessageError) {
      toastNotifiy("Please write a message", "warn");
      return;
    }

    const {
      tuitionId,

      tuitionSchedule,
    } = getDetails(selectedTuitionInfo, "student");

    let res = await axiosRequest({
      url: "/tuition/update-tuition-status",
      method: "put",
      data: {
        tuitionId: selectedTuitionInfo?._id,
        status: "DECLINED",
        user: user,
      },
    });
    // updateTuitionStatus({
    //   tuitionId: selectedTuitionInfo?._id,
    //   status: "DECLINED",
    //   user: user
    // })
    if (res.status === 201) {
      removeTuitionFromSchedule(
        tuitionId,
        selectedTuitionInfo?.tutorId,
        tuitionSchedule
      );

      const tuitionLabel = selectedTuition.label;
      const sliced = tuitionLabel.substring(0, tuitionLabel.lastIndexOf(" "));
      editConversation({
        sender: loggedInUser,
        id: conversationId,
        data: {
          message: `[${sliced}] This tuition is declined. Reason: ${declineMessage}.`,
        },
      });
      const notifyObj = {
        receiver: receiverId,
        title: `Your tuition for [${sliced}] has been declined`,
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/inbox",
        isPublic: false,
      };
      AddNotification(notifyObj);
      toastNotifiy("Sent message successfully", "success");
      setContent("main");
      navigate(`${process.env.PUBLIC_URL}/inbox/`);
      toggler();
    }
  };

  const removeTuitionFromSchedule = async (
    tuitionId,
    tutorId,
    tuitionSchedule
  ) => {
    let res = await axiosRequest({
      url: "/newMember/getMemberSchedule/" + tutorId,
      method: "get",
    });
    if (res?.data) {
      const { tutorSchedules } = res?.data;
      let tutorOldSchedule = [...tutorSchedules];

      tutorOldSchedule?.map((tutor) => {
        tuitionSchedule?.map((tuition) => {
          if (tutor.dayName === tuition.dayName) {
            tutor.timeTable.map((time) => {
              if (time.tuitionId === tuitionId) {
                time.isBooked = false;
                time.tuitionId = "";
              }
            });
          }
        });
      });
      updateTutorNewSchedule(tutorId, tutorOldSchedule);
    }
  };

  const handleText = (e) => {
    if (e.target.value.length > 0) {
      setDeclineMessageError(false);
      setDeclineMessage(e.target.value);
    }
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleStartDate = (date) => {
    setStartDate(new Date(date));
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    setWidth(windowSize.innerWidth);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  return (
    <Fragment>
      <Modal
        isOpen={modalOpen}
        // isOpen={props.isOpen}
        toggle={toggler}
        // toggle={props.toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-lg"
        backdrop={"static"}
      >
        {/* <ModalHeader style={{ placeContent: "center" }} toggle={handleClose}> */}
        <ModalHeader style={{ placeContent: "center" }}>
          <div>{title}</div>
          <button
            onClick={toggler}
            style={{ backgroundColor: "white" }}
            className="rounded border-0 text-danger"
          >
            <X></X>
          </button>
        </ModalHeader>
        {/* <ModalBody className={"pb-0 " + props.bodyClass}> */}
        {content === "main" && (
          <>
            <ModalBody className={"pb-0 "}>
              <Col sm="12" xl="12 box-col-12">
                <Card className="mb-0">
                  <CardBody className="megaoptions-border-space-sm py-1">
                    <Row>
                      <Col sm="12" xl="12">
                        <Row className="mb-3">
                          <Col md={2}>
                            <Label className="col-form-label" htmlFor="">
                              Tuition
                            </Label>
                          </Col>
                          <Col md={8}>
                            <Select
                              styles={{
                                // #67E0A3 #EF2D56 #0090C1 #38AECC
                                option: (
                                  baseStyles,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => ({
                                  ...baseStyles,
                                  color: isFocused ? "#6f6f6f" : "black",
                                  cursor: "pointer",
                                }),
                              }}
                              options={myTuitions}
                              getOptionLabel={(e) => (
                                <div className="d-flex align-items-center">
                                  <Badges
                                    attrBadge={{
                                      color:
                                        e.status === "CONFIRMED"
                                          ? "success"
                                          : e.status === "OPEN"
                                            ? "info"
                                            : e.status === "DECLINED"
                                              ? "warning"
                                              : e.status === "CLOSE_REQUEST"
                                                ? "danger"
                                                : "light-1",
                                    }}
                                  >
                                    {e.status}
                                  </Badges>
                                  <span className="m-l-5">{e.label}</span>
                                </div>
                              )}
                              placeholder="Select Subject"
                              className="js-example-basic-single col-sm-12 darkMode"
                              // onChange={(item) => setSelectedTuition(item)}
                              onChange={(item) => handleSubjects(item)}
                              value={selectedTuition}
                              isClearable={true}
                              bsSize="lg"
                            />
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                          <Col md={2}>
                            <Label
                              className="col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Tuition Fee
                            </Label>
                          </Col>
                          <Col md={4} sm={6}>
                            <div
                              style={{ display: "flex", placeItems: "center" }}
                            >
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="Enter Your Fee"
                                min="0"
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                style={{ fontSize: "13px" }}
                                value={hasSubject && budget}
                                bsSize="lg"
                                disabled
                              />
                              <div>
                                <span className="px-2">/Class</span>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={4}
                            sm={6}
                            className={`py-1 ${currency !== null ? "" : "d-none"
                              }`}
                          >
                            <div
                              className={`"border"`}
                              style={{
                                borderRadius: "5px",
                                background: "#e6e6e659",
                                lineHeight: "13px",
                                paddingTop: "3px",
                              }}
                            >
                              <span>USD</span>
                              <p className="mb-0" style={{ fontSize: "10px" }}>
                                United States Dollar
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                          <Col md={2}>
                            <Label
                              className="col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Location
                            </Label>
                          </Col>
                          <Col sm="8">
                            <TuitionPlace
                              handlePreferredPlace={handlePreferredPlace}
                              disabled={true}
                              tuitionPlace={tuitionPlace}
                            ></TuitionPlace>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <TuitionDate
                            tuitionStartDate={tuitionStartDate}
                            handleStartDate={handleStartDate}
                            tuitionEndDate={tuitionEndDate}
                            handleEndDate={handleEndDate}
                            today={new Date()}
                            tomorrow={new Date()}
                            disabled={true}
                          ></TuitionDate>
                        </Row>
                        {hasSubject && (
                          <div className="form-group row mb-3">
                            <Row>
                              <Col>Schedule</Col>
                            </Row>
                          </div>
                        )}
                        <div className="mb-3">
                          <Row>
                            <Col lg={2}></Col>
                            <Col lg={8} sm={12} className="px-4">
                              {hasSubject &&
                                selectedTuitionSchedule?.map(
                                  (item, index) => (
                                    <Row
                                      className={`weekly_schdule_box bg-success`}
                                      key={index}
                                    >
                                      <div className="align-items-center d-flex">
                                        <Col sm={2} className="m-r-5">
                                          <Label for="exampleEmail2">
                                            <b> {item.dayName.toUpperCase()}</b>
                                          </Label>
                                        </Col>
                                        <Col
                                          className="m-r-5"
                                          xl="3"
                                          sm="3"
                                          lg="3"
                                        >
                                          <span
                                            style={{
                                              padding: "5px 15px",
                                              color: "#403d39",
                                            }}
                                            className="bg-light rounded"
                                          >
                                            {new Date(
                                              item.start
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </span>
                                        </Col>
                                        <Col className="m-r-5" sm={1}>
                                          <Label
                                            for="exampleEmail2"
                                            className="text-center"
                                          >
                                            <ArrowRight />
                                          </Label>
                                        </Col>
                                        <Col
                                          xl="3"
                                          sm="3"
                                          lg="3"
                                          className="text-end m-r-5"
                                        >
                                          <span
                                            style={{
                                              padding: "5px 15px",
                                              color: "#403d39",
                                            }}
                                            className="bg-light rounded"
                                          >
                                            {new Date(
                                              item.end
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </span>
                                        </Col>
                                        <Col sm={2}>
                                          <Label for="exampleEmail2">
                                            <b> {`(${item.duration})`}</b>
                                          </Label>
                                        </Col>
                                      </div>
                                    </Row>
                                  )
                                  // )
                                )}
                            </Col>
                          </Row>
                          {overLappedDays?.length > 0 && (
                            <Row>
                              <Col lg={2}></Col>
                              <Col
                                lg={8}
                                sm={12}
                                style={{ backgroundColor: "#FFF4CD" }}
                                className="rounded "
                              >
                                <div className="my-2 text-center txt-warning">
                                  <P>
                                    {`You have scheduled tuition classes at selected time on `}
                                    {overLappedDays?.map((day, index) => {
                                      return (
                                        <span key={day}>
                                          {`${day.toUpperCase()}
                                              ${index ===
                                              overLappedDays?.length - 1
                                              ? "."
                                              : ", "
                                            }`}
                                        </span>
                                      );
                                    })}
                                  </P>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                      <Col sm="12" xl="12">
                        <div className="row mb-2"></div>
                        <div className="row mb-2"></div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </ModalBody>
            {/* {(selectedTuition || selectedTuition.length !== 0) && tuitionStatus !== "CONFIRMED" && ( */}
            {hasSubject &&
              tuitionStatus !== "CONFIRMED" &&
              tuitionStatus !== "CLOSE_REQUEST" && (
                <ModalFooter className="bg-light">
                  <Col>
                    <Row className="justify-content-center align-items-center">
                      <Col
                        sm="4"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Btn
                          attrBtn={{
                            color: "danger",
                            className: "m-r-10",
                            onClick: (e) => handleDecline(e),
                          }}
                        >
                          {"Decline"}
                        </Btn>
                        <Btn
                          attrBtn={{
                            color: "success",
                            onClick: (e) => handleTuitionConfirmation(e),
                            disabled: overLappedDays?.length > 0,
                          }}
                        >
                          {"Confirm"}
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </ModalFooter>
              )}
          </>
        )}
        {content === "decline" && (
          <>
            <ModalBody>
              <Col>
                <Row className="justify-content-center align-items-center m-t-10">
                  <Col
                    sm="5"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Send a message to the tutor for declining the tuition..."
                      rows="8"
                      onChange={(e) => handleText(e)}
                      // style={{backgroundColor: "#b9b8f4"}}
                      style={{ backgroundColor: "#dbfeb8" }}
                    />
                  </Col>
                </Row>
              </Col>
            </ModalBody>
            <ModalFooter className="bg-light">
              <Col>
                <Row className="justify-content-center align-items-center">
                  <Col
                    sm="4"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button
                      className="m-r-10"
                      color="warning"
                      onClick={() => setContent("main")}
                    >
                      Back
                    </Button>
                    <Button color="success" onClick={sendDecline}>
                      Send
                    </Button>
                  </Col>
                </Row>
              </Col>
            </ModalFooter>
          </>
        )}
      </Modal>
    </Fragment>
  );
};

export default AllTuitionsModalStudent;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
