import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const HelpAccountSettings = () => {
  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  return (
    <div>
      <p className="f-16">
        In your Dashboard
        <ul className="f-16">
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              1
            </span>
            Click on your name or profile picture
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              2
            </span>
            Click on <span className="">Account Setting</span>
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              3
            </span>
            Click on
            <Button
              style={{ padding: "4px 6px", border: "none", outline: "none" }}
              color=" "
              disabled={_tuteair ? false : true}
            >
              {_tuteair ? (
                <Link to={`${process.env.PUBLIC_URL}/settings`} target="_blank">
                  <span className="txt-primary">Edit</span>
                </Link>
              ) : (
                <span className="">Edit</span>
              )}
            </Button>
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              4
            </span>
            You can edit your phone no
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              5
            </span>
            You can change your gender
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              6
            </span>
            You can update your language
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              7
            </span>
            Check or uncheck to change account type
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              8
            </span>
            Click on <span className="">Save Changes</span>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default HelpAccountSettings;
