import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AboutMe } from "../AboutMe";
import ProfileTabs from "../ProfileTabs";
import MemberPersonalInfo from "../Tutor/BasicInfo/TutorPersonalInfo";
import MemberEducation from "../Tutor/Education/TutorEduInfo";
import StudentPricing from "./InterestLearn/StudentPricing";
import { Button } from "reactstrap";

const StudentAccountContain = () => {
  const tabList = [
    { id: "1", name: "About Me", icon: "icofont-user-alt-7", component: <AboutMe /> },
    // { id: "1", name: "About Me", icon: "icofont-user-alt-7", component: <MemberPersonalInfo /> },
    { id: "2", name: "Interested Subjects", icon: "icofont-notebook", component: <StudentPricing /> }
  ];

  const { memberType } = useSelector((state) => state.menu) || {};
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/tutor-account`);
    }
  }, [memberType, navigate]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryTab = searchParams.get('tab');
    if (queryTab) {
      setActiveTab(queryTab);
    }
  }, [searchParams]);

  return (
    <Fragment>
      <ProfileTabs tabList={tabList} activeTab={activeTab} toggleTab={toggleTab} setActiveTab={setActiveTab}></ProfileTabs>
    </Fragment>
  );
};

export default StudentAccountContain;
