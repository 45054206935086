import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const HelpCreateTuition = () => {
  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  return (
    <div>
      <ul className="f-16">
        <li>
          <span className="px-1 border rounded-circle b-info txt-info">1</span>{" "}
          Click on
          <Button
            style={{ padding: "4px 6px", border: "none", outline: "none" }}
            color=" "
            disabled={_tuteair ? false : true}
          >
            {_tuteair ? (
              <Link to={`${process.env.PUBLIC_URL}/inbox`} target="_blank">
                <span className="txt-primary">Chat</span>
              </Link>
            ) : (
              <span className="">Chat</span>
            )}
          </Button>
          from your dashboard
        </li>
        <li>
          <span className="px-1 border rounded-circle b-info txt-info">2</span>{" "}
          Select a conversation
        </li>
        <li>
          <span className="px-1 border rounded-circle b-info txt-info">3</span>{" "}
          Click on <span className="">Add Tuition</span>
        </li>
        <li>
          <span className="px-1 border rounded-circle b-info txt-info">4</span>{" "}
          Fill the form
        </li>
        <li>
          <span className="px-1 border rounded-circle b-info txt-info">5</span>{" "}
          Click on <span className="">Send Tuition Request</span>
        </li>
      </ul>
    </div>
  );
};

export default HelpCreateTuition;
