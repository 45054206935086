import React, { useEffect, useState, useContext } from "react";
import useAuthFromNext from "../../redux/hooks/useAuthFromNext";
import { Navigate, useLocation } from "react-router-dom";

const DashboardRedirection = () => {
  const success = useAuthFromNext();
  const [loading, setLoading] = useState(true);
  const [routeLocation, setRouteLocation] = useState("/dashboard");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get("profile");
  useEffect(() => {
    const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
    if (success) {
      if (_tuteair?._id) {
        if (
          _tuteair?.emailVerified === false ||
          (_tuteair?.currentStep <= 1 && !queryParamValue)
        ) {
          setRouteLocation("/become-tutor");
        } else if (queryParamValue) {
          setRouteLocation(`/profile/${queryParamValue}`);
          if (_tuteair?.accountType !== "tutor") {
            localStorage.setItem("swMember", "student");
          }
        }

        setLoading(false);
      }
    }
  }, [success]);

  if (loading) {
    return <></>;
  }

  return <Navigate to={routeLocation} replace={true} />;
};

export default DashboardRedirection;
