import moment from "moment";
import React from "react";

export const TutorScheduleDisplay = ({
  item,
  handleAvailableTime,
  availableSlots,
  bookedSlots,
}) => {
  return (
    <div>
      <div className="d-flex flex-wrap">
        <div>
          <p className="m-r-5">Available: </p>
        </div>
        {availableSlots.map((t, index) => (
          <button
            key={index}
            style={{
              backgroundColor: "transparent",
            }}
            onClick={() => handleAvailableTime(item, t.start, t.end)}
            className="border-0 m-b-5 p-0 rounded rounded-5 m-r-5"
          >
            <p
              className=" px-2 rounded rounded-5 f-10"
              style={{
                backgroundColor: "#affc41",
              }}
            >
              {moment(t.start).format("LT")}-{moment(t.end).format("LT")}
            </p>
          </button>
        ))}
      </div>
      {
        bookedSlots.length > 0 && (
          <div className="d-flex flex-wrap align-items-center">
            <div>
              <p className="m-r-5">Booked: </p>
            </div>
            {bookedSlots?.map((t, index) => (
              <div className="m-r-5" key={index}>
                <p
                  className="text-dark px-2 rounded rounded-5 m-b-5 f-10"
                  style={{
                    backgroundColor: "#ced4da",
                  }}
                >
                  {moment(t.start).format("LT")}-{moment(t.end).format("LT")}
                </p>
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};
