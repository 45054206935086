import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useGetConversationsQuery } from "../../../redux/features/chat/conversations/conversationsApi";
import { chattingInfo } from "../../../redux/features/chat/conversations/conversationsSlice";
import Blank from "./Blank";
import ChatBody from "./inbox/ChatBody";
import MyChatStatus from "./inbox/ChatStatus";

const Conversation = () => {
  const { user } = useSelector((state) => state.auth) || {};
  const { topConversation } = useSelector((state) => state.conversations) || {};
  const [topConversationHolder, setTopConversationHolder] = useState('');
  // console.log('from conversation', Object.keys(defaultChatPath).length);

  let {
    data: conversations,
    isLoading,
    isError,
    error,
  } = useGetConversationsQuery(user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    //  var descConversations = [...conversations];

    // var _top = ...conversations?.sort(function (a, b) {
    //   return new Date(b.updatedTime) - new Date(a.updatedTime);
    // });
    // var _topConversation = [_top?.length - 1];
    // console.log(_topConversation);

    if (
      !isLoading &&
      !isError &&
      conversations?.length > 0 &&
      topConversation
    ) {
      var _topConv = conversations?.find(
        (x) => x.conversationId === topConversation?.conversationId
      );

      if (_topConv) {
        dispatch(
          chattingInfo({
            type: "SELECTED",
            selectedUser: {
              conversationId: _topConv?.conversationId,
              recevierId: _topConv?.users?.find((x) => x !== user),
            },
          })
        );
        // console.log(_topConv);
        setTopConversationHolder(`${_topConv?.conversationId}`)
        //   navigate(`${process.env.PUBLIC_URL}/inbox/${_topConv?.conversationId}`);
      }
    }
    //--- for resetting selected chat ---
    return () => {
      dispatch(
        chattingInfo({
          type: "SELECTED",
          selectedUser: undefined,
        })
      );
    }
  }, [topConversation]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="people-list" id="people-list">
                      {/* <SearchChatList /> */}
                      <MyChatStatus /> {/* Sidebar */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                {/* <Blank/> */}
                {
                  topConversationHolder &&
                  <ChatBody id={topConversationHolder} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Conversation;
