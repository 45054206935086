import React, { Fragment, useEffect } from "react";
import { useContext } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FilterContext from "../../../_helper/Filter";
import { useWindowWidth } from "../../Chat/NewChat/inbox/useWindowWidth";
import tuteair from "../assets/img/tuteair-v2.png";
import "./become-tutor.css";
import LoginNew from "./ChildComponent/LoginNew";
import SlickSlider from "./ChildComponent/SlickSlider";

const BecomeTutorLogin = () => {
  const { setMoonlight } = useContext(FilterContext);
  const width = useWindowWidth();

  //--- setting page theme to light mode ---
  useEffect(() => {
    // console.log(moonlight);
    // console.log(localStorage.getItem('layout_version'));
    setMoonlight(false);
    document.body.className = "light";
    localStorage.setItem("layout_version", "light");
  }, []);

  return (
    <div></div>
  );

  // return (
  //   <Fragment>
  //     <div className="py-0 py-md-5" style={{ background: "#737a8a00" }}>
  //       <Navbar
  //         collapseOnSelect
  //         expand="lg"
  //         className="bg_navbar mb-5"
  //         variant="light"
  //         fixed="top"
  //       // style={{ background: "#faebd7" }}
  //       >
  //         <Container>
  //           <Link to={`${process.env.PUBLIC_URL}/home`}>
  //             <Navbar.Brand className="tuteairBrand px-3">
  //               <img src={tuteair} height="35" className="" alt="TuteAir" />
  //             </Navbar.Brand>
  //           </Link>
  //           {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
  //           <Navbar.Collapse
  //             id="responsive-navbar-nav "
  //             className="tuteair_navbar"
  //           >
  //             <Nav className="me-auto"></Nav>
  //           </Navbar.Collapse>
  //         </Container>
  //       </Navbar>
  //       <section className={`${width < 576 ? "" : "container"}`}>
  //         <Row xs="1" md="2" className="m-0">
  //           <Col xs={{ order: 2 }} md={{ order: 1 }}
  //             className="p-0 carousal-page mt-5 mt-md-0"
  //             style={{ placeSelf: "center" }}
  //           >
  //             <SlickSlider
  //               slides={1}
  //               title="Responsive Example"
  //               rtl={false}
  //               lazyLoad={true}
  //             />
  //           </Col>
  //           <Col xs={{ order: 1 }} md={{ order: 2 }}
  //             className="p-0">
  //             <div className="login-card1 become_tutor_card1">
  //               <LoginNew />
  //             </div>
  //           </Col>
  //         </Row>
  //         <Row></Row>
  //       </section>
  //     </div>
  //   </Fragment>
  // );
};

export default BecomeTutorLogin;
