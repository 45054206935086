import ListView from "./ListView/ListView";
import React, { Fragment, useEffect, useState } from "react";
import { H6, H4, Image, LI, UL } from "../../AbstractElements";
import { Input, Label, Media } from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShowStudentsOnMap from "./MapVIew/ShowStudentsOnMap";

const FindStudentsContainer = () => {
  const [isListView, setIsListView] = useState(false);

  const switchViewToggle = (isListView) => {
    setIsListView(!isListView);
  };

  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "student") {
      navigate(`${process.env.PUBLIC_URL}/find-tutors`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <UL>
        <div className="d-flex justify-content-left align-items-center ps-3">
          <div>
            <li className={"text-center switch-outline"}>
              <H6>LIST VIEW</H6>
            </li>
          </div>
          <div className="mx-2">
            <li className="ms-2">
              <Media body className={"text-center switch-outline media-body"}>
                <Label className="switch p-0 m-0">
                  <Input
                    type="checkbox"
                    checked={isListView}
                    onChange={(e) => switchViewToggle(isListView)}
                  />
                  <span className="switch-state bg-primary"></span>
                </Label>
              </Media>
            </li>
          </div>
          <div>
            <li className={"text-center switch-outline"}>
              <H6>MAP VIEW</H6>
            </li>
          </div>
        </div>
      </UL>
      {!isListView ? <ListView /> : <ShowStudentsOnMap />}
    </Fragment>
  );
};

export default FindStudentsContainer;
