import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { H5, Ribbon } from "../../AbstractElements";

export const SingleMyTuition = ({
  tuition,
  schedules,
  handleTutorExtend,
  handleExtendButton,
  handleCancel,
  handleCancelCloseTuition,
  handleClose,
}) => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const [showExtendButtons, setShowExtendButtons] = useState(false);
  const handleButtonClick = () => {
    if (tuition?.status === "CLOSE_REQUEST")
      if (
        memberType === "tutor" &&
        tuition?.tuitionCloseStatus?.isCloseByTutor === true
      ) {
        handleCancelCloseTuition(tuition, memberType);
      } else if (
        memberType === "student" &&
        tuition?.tuitionCloseStatus?.isCloseByStudent === true
      ) {
        handleCancelCloseTuition(tuition, memberType);
      } else {
        handleClose(tuition, memberType);
      }
    else if (tuition?.status === "CONFIRMED") {
      handleClose(tuition, memberType);
    } else {
      handleCancel(tuition, memberType);
    }
  };

  return (
    <Col className="d-flex" xs="12" sm="6" lg="4" key={tuition?._id}>
      <Card
        style={{
          // backgroundColor: tuition?.status === 'OPEN' ? '#6362e71a'
          background:
            tuition?.status === "OPEN"
              ? "linear-gradient(180deg, rgba(145,144,255,0.18539915966386555) 0%, rgba(0,212,255,0) 100%)"
              : // : tuition?.status === 'CONFIRMED' ? '#e8fccf'
              tuition?.status === "CONFIRMED"
                ? "linear-gradient(180deg, rgba(232,252,207,0.8128501400560224) 0%, rgba(0,212,255,0) 100%)"
                : // : tuition?.status === 'DECLINED' ? '#fcd29f99'
                tuition?.status === "DECLINED"
                  ? "linear-gradient(180deg, rgba(251,155,38,0.11817226890756305) 0%, rgba(0,212,255,0) 100%)"
                  : "",
          color:
            tuition?.status === "CANCELED" || tuition?.status === "CLOSED"
              ? "#bcb8b1"
              : "",
        }}
        className="ribbon-wrapper-right w-100 shadow shadow-showcase"
      >
        <CardBody>
          <Ribbon
            attrRibbons={{
              className: `ribbon ribbon-clip-right ribbon-right
          ${tuition?.status === "OPEN"
                  ? "ribbon-primary"
                  : tuition?.status === "CONFIRMED"
                    ? "ribbon-success"
                    : tuition?.status === "DECLINED"
                      ? "ribbon-warning"
                      : tuition?.status === "CLOSE_REQUEST"
                        ? "ribbon-secondary"
                        : tuition?.status === "CANCELED"
                          ? "ribbon-light"
                          : tuition?.status === "CLOSED"
                            ? "ribbon-light"
                            : "ribbon-primary"
                }`,
              style: {
                color:
                  tuition?.status === "CANCELED" || tuition?.status === "CLOSED"
                    ? "#bcb8b1"
                    : "",
              },
            }}
          >
            {tuition?.status}
          </Ribbon>
          {/* <Col className='d-flex justify-content-end'>
          <span className={`badge
          ${tuition?.status === 'OPEN' ? 'badge-primary'
          :tuition?.status === 'CONFIRMED' ? 'badge-success'
          :tuition?.status === 'DECLINED' ? 'badge-warning'
          : 'badge-primary'}`}>{tuition?.status}</span>
        </Col> */}
          <div className="d-flex m-t-5">
            {tuition.tuitionRequest?.subjects?.length > 0
              ? tuition.tuitionRequest?.subjects?.map((item, index) => (
                <span key={index}>
                  {" "}
                  {item.label}
                  {index !== tuition.tuitionRequest?.subjects.length - 1
                    ? ", "
                    : ""}{" "}
                </span>
              ))
              : tuition.interestedSubjects?.subjects?.map((item, index) => (
                <H5 key={index}>
                  {" "}
                  {item.name}{" "}
                  {index !== tuition?.interestedSubjects?.subjects.length - 1
                    ? ", "
                    : ""}{" "}
                </H5>
              ))}
          </div>
          <Row>
            <Col xs="6">
              <div>
                <i
                  style={{
                    color:
                      tuition?.status === "CANCELED" ||
                        tuition?.status === "CLOSED" ||
                        "DECLINED"
                        ? "#bcb8b1"
                        : "rgba(99, 98, 231, 0.80)",
                  }}
                  className="fa fa-money"
                ></i>
                <span className="m-l-10">
                  {tuition?.confirmedTuitionFee} USD
                  <span className="f-10">/class</span>{" "}
                </span>
              </div>
              <div>
                <i
                  style={{
                    color:
                      tuition?.status === "CANCELED" ||
                        tuition?.status === "CLOSED" ||
                        "DECLINED"
                        ? "#bcb8b1"
                        : "rgba(99, 98, 231, 0.80)",
                  }}
                  className="icofont icofont-student-alt"
                ></i>
                <span className="m-l-10">
                  {memberType === "student"
                    ? tuition.tutor?.firstName + " " + tuition.tutor?.lastName
                    : tuition.student?.firstName +
                    " " +
                    tuition.student?.lastName}
                  {tuition.isStudentDeactivated ? " (Account Deactivated)" : ""}
                  {tuition.isStudentDeleted ? "(Account Deleted)" : ""}
                </span>
              </div>
            </Col>

            <Col xs="6" className="d-flex flex-column">
              <div className="d-flex justify-content-end">
                <span>Tuition Ends</span>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <i
                  style={{
                    color:
                      tuition?.status === "CANCELED" ||
                        tuition?.status === "CLOSED" ||
                        "DECLINED"
                        ? "#bcb8b1"
                        : "rgba(99, 98, 231, 0.80)",
                  }}
                  className="fa fa-calendar"
                ></i>
                <span className="m-l-5">
                  {tuition?.timeTableHistory.filter((x) => x.isActive)?.length >
                    0 ? (
                    <>
                      {moment(
                        new Date(
                          tuition?.timeTableHistory.filter((x) => x.isActive)[
                            tuition?.timeTableHistory?.filter((x) => x.isActive)
                              ?.length - 1
                          ]?.classEnd
                        )
                      ).format("LL")}
                    </>
                  ) : (
                    <>
                      {moment(
                        new Date(
                          tuition?.timeTableHistory[
                            tuition?.timeTableHistory?.length - 1
                          ]?.classEnd
                        )
                      ).format("LL")}
                    </>
                  )}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row className="col-12 m-t-10">
            <Col xs="12" className="m-b-5">
              <span>
                <strong>Tuition Schedule</strong>{" "}
              </span>
            </Col>
            <Col className="d-flex flex-wrap">
              {schedules?.map((schedule, index) => (
                <div
                  key={index}
                  className={`m-r-10 px-2 m-b-5 rounded-3
                ${tuition?.status === "OPEN"
                      ? "txt-primary"
                      : tuition?.status === "CANCELED"
                        ? "bg-light"
                        : tuition?.status === "CLOSE_REQUEST"
                          ? "bg-secondary"
                          : tuition?.status === "CLOSED"
                            ? "bg-light"
                            : "ribbon-primary"
                    }
                `}
                  style={{
                    color:
                      tuition?.status === "CANCELED" ||
                        tuition?.status === "CLOSED"
                        ? "#bcb8b1"
                        : tuition?.status === "CONFIRMED"
                          ? "green"
                          : tuition?.status === "DECLINED"
                            ? "#fb5607"
                            : "",
                    backgroundColor:
                      tuition?.status === "CONFIRMED"
                        ? "#7dc00666"
                        : tuition?.status === "OPEN"
                          ? "#6362e74d"
                          : tuition?.status === "DECLINED"
                            ? "#ff88194d"
                            : "",
                  }}
                >
                  <span className="f-12">
                    {schedule.dayName?.charAt(0).toUpperCase() +
                      schedule.dayName?.slice(1)}{" "}
                    {new Date(schedule.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    -
                    {new Date(schedule.end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              ))}
            </Col>
          </Row>
        </CardBody>

        <CardFooter
          className="border-0 bg-none"
          style={{ background: "transparent" }}
        >
          <Row className="m-t-10" style={{ alignItems: "center" }}>
            <Col className="" md={3}>
              {tuition?.status !== "CANCELED" &&
                tuition?.status !== "DECLINED" &&
                tuition?.status !== "CLOSED" && (
                  <button
                    className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 text-danger"
                    style={{ backgroundColor: "#ffccd5" }}
                    onClick={handleButtonClick}
                  >
                    <span className="f-w-600">
                      {tuition?.status === "CLOSE_REQUEST"
                        ? (memberType === "tutor" &&
                          tuition?.tuitionCloseStatus?.isCloseByTutor) ||
                          (memberType === "student" &&
                            tuition?.tuitionCloseStatus.isCloseByStudent)
                          ? "Cancel"
                          : "Close"
                        : tuition?.status === "CONFIRMED"
                          ? "Close"
                          : "Cancel"}
                    </span>
                  </button>
                )}
            </Col>
            {tuition?.status === "CONFIRMED" && memberType === "student" && (
              <Col className="" md={9} style={{ textAlign: "-webkit-right" }}>
                {tuition.isExtendRequest ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <span>Extend Request (Pending)</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <i
                        style={{
                          color:
                            tuition?.status === "CANCELED" ||
                              tuition?.status === "CLOSED"
                              ? "#bcb8b1"
                              : "rgba(99, 98, 231, 0.80)",
                        }}
                        className="fa fa-calendar"
                      ></i>
                      <span className="m-l-5">
                        {moment(
                          new Date(
                            tuition?.timeTableHistory.filter(
                              (x) => !x.isActive
                            )[
                              tuition?.timeTableHistory.filter(
                                (x) => !x.isActive
                              ).length - 1
                            ].classEnd
                          )
                        ).format("LL")}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 txt-warning"
                      style={{ backgroundColor: "#FFF4CD" }}
                      disabled={tuition?.isExtendRequest}
                      onClick={(e) => handleExtendButton(tuition._id)}
                    >
                      Extend
                    </button>
                  </>
                )}
              </Col>
            )}
            {tuition?.isExtendRequest &&
              memberType === "tutor" &&
              !showExtendButtons && (
                <Col className="d-flex justify-content-end">
                  <button
                    className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 txt-warning"
                    style={{ backgroundColor: "#FFF4CD", float: "right" }}
                    onClick={(e) => setShowExtendButtons(true)}
                  >
                    Extend Tuition
                  </button>
                </Col>
              )}
            {tuition?.isExtendRequest &&
              memberType === "tutor" &&
              showExtendButtons && (
                <Col className="d-flex justify-content-end">
                  <button
                    className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 txt-warning m-r-5"
                    style={{ backgroundColor: "#FFF4CD" }}
                    onClick={(e) => setShowExtendButtons(false)}
                  >
                    Later
                  </button>
                  <button
                    className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 txt-danger m-r-5 f-w-600"
                    style={{ backgroundColor: "#FFF4CD" }}
                    onClick={(e) => handleTutorExtend(tuition, false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="border-0 d-flex align-items-center py-2 px-3 rounded rounded-3 txt-success f-w-600"
                    style={{ backgroundColor: "#FFF4CD" }}
                    onClick={(e) => handleTutorExtend(tuition, true)}
                  >
                    Confirm
                  </button>
                </Col>
              )}
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};
