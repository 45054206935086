import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import RequestTuitionContain from "../../Component/RequestTuition";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const RequestTuition = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="RequestTuition" title="Request Tuition" />
      <RequestTuitionContain />
    </Fragment>
  );
};

export default RequestTuition;
