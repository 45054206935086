import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { H4, H5, P } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
const ListOfImageDesc = ({ users, setPhotoIndex, photoIndex }) => {
  var descUsers = [...users];
  descUsers.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });
  const description =
    "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.";
  return (
    <Fragment>
      {descUsers?.length > 0 && (
        <Col sm="12 pt-2">
          <Card>
            <CardHeader className="pb-0">
              <H5>{"TuteAir Member List"}</H5>
            </CardHeader>
            <CardBody>
              <div className="my-gallery row gallery-with-description max-height-100">
                {descUsers &&
                  descUsers?.map((item, i) => {
                    return (
                      <figure className="col-xl-3 col-sm-6" key={i}>
                        <img
                          src={
                            item.profilePicture === "" ||
                            item.profilePicture === " "
                              ? man
                              : ProfilePictureRootURL + item.profilePicture
                          }
                          onClick={(e) =>
                            setPhotoIndex({
                              ...photoIndex,
                              index: 1,
                              userId: item._id,
                              name: item.firstName + " " + item.lastName,
                              isOpen: true,
                            })
                          }
                          className="img-thumbnail"
                          style={{ height: "250px", width: "auto" }}
                          alt=""
                        />
                        {/* <Image
                            attrImage={{
                              src:
                                item.profilePicture === "" ||
                                item.profilePicture === " "
                                  ? man
                                  : ProfilePictureRootURL + item.profilePicture,
                              alt: "Gallery",
                              className: "img-thumbnail w-100",
                              //   onClick: () =>
                              //     setPhotoIndex({
                              //       ...photoIndex,
                              //       index: 1,
                              //       isOpen: true,
                              //     }),
                            }}                          />
*/}
                        <div className="caption mb-0">
                          <H4>{item.firstName + " " + item.lastName}</H4>
                          <P>{description}</P>
                        </div>
                      </figure>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};

export default ListOfImageDesc;
