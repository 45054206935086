import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useGetMemberInfoByIdQuery } from "../../../redux/features/member/memberApi";
import "./AccountSettings.css";
import Address from "./Address";
import ChangePassword from "./ChangePassword";
import ChangeProfilePicture from "./ChangeProfilePicture";
import Education from "./Education";
import EmailNotification from "./EmailNotification";
import GeneralProfile from "./GeneralProfile";
import HideAccount from "./HideAccount";
import MyProfile from "./myProfile";
import PaymentInformation from "./PaymentInformation";
import PersonalInfromation from "./PersonalInfromation";
import SecurityAndPrivacySettings from "./SecurityAndPrivacySettings";
import { useSearchParams } from "react-router-dom";

const AccountSettingContain = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};
  const { user } = useSelector((state) => state.auth) || {};
  const { memberType } = useSelector((state) => state.menu) || {};
  let { data: memberInfo } = useGetMemberInfoByIdQuery(user);

  const tabList = memberType === "tutor" ?

    [
      { id: "1", route: "personal", name: "Personal Information", icon: "icofont-user-alt-7", component: <PersonalInfromation header="Personal Information" /> },
      { id: "2", route: "address", name: "Address", icon: "icofont-location-pin", component: <Address header="Address" /> },
      { id: "3", route: "education", name: "Education Information", icon: "icofont-hat", component: <Education header="Education Information" /> },
      { id: "4", route: "payment", name: "Payment Receiving Information", icon: "icofont-bank", component: <PaymentInformation header="Payment Receiving Information" /> },
      { id: "5", route: "picture", name: "Change Profile Picture", icon: "icofont-image", component: <ChangeProfilePicture header="Change Profile Picture" /> },
      { id: "6", route: "password", name: "Change Password", icon: "icofont-ui-password", component: <ChangePassword /> },
    ] : [
      { id: "1", route: "personal", name: "Personal Information", icon: "icofont-user-alt-7", component: <PersonalInfromation header="Personal Information" /> },
      { id: "2", route: "address", name: "Address", icon: "icofont-location-pin", component: <Address header="Address" /> },
      { id: "3", route: "education", name: "Education Information", icon: "icofont-hat", component: <Education header="Education Information" /> },
      { id: "5", route: "picture", name: "Change Profile Picture", icon: "icofont-image", component: <ChangeProfilePicture header="Change Profile Picture" /> },
      { id: "6", route: "password", name: "Change Password", icon: "icofont-ui-password", component: <ChangePassword /> },
    ];

  const [VerticleTab, setVerticleTab] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryTab = searchParams.get('tab');
    if (queryTab) {
      setVerticleTab(queryTab);
    }
  }, [searchParams]);

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row>
          <Col sm="4">
            <Card>
              {/* <Card style={{ height: "65vh" }}> */}
              <CardTitle>
                <h3 className="p-4 pb-0">Settings</h3>
              </CardTitle>
              <CardBody className="megaoptions-border-space-sm">
                <Nav className="nav flex-column nav-pills justify-content-start">
                  {
                    tabList.map((tab) => (
                      <NavItem key={tab.id}>
                        <NavLink
                          href={`#${tab.route}`}
                          className={VerticleTab === tab.id ? "active" : ""}
                          onClick={() => setVerticleTab(tab.id)}
                        >
                          <Col className="d-flex justify-content-start align-items-center">
                            <i className={`icofont ${tab.icon} f-18`}></i>
                            <span>{tab.name}</span>
                          </Col>
                        </NavLink>
                      </NavItem>
                    ))
                  }
                  {/* <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "1" ? "active" : ""}
                      onClick={() => setVerticleTab("1")}
                    >
                      <i className="icofont icofont-teacher"></i>
                      {fName + " " + lName}
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "2" ? "active" : ""}
                      onClick={() => setVerticleTab("2")}
                    >
                      <i className="icofont icofont-ui-password"></i>
                      {"Change Password"}
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "3" ? "active" : ""}
                      onClick={() => setVerticleTab("3")}
                    >
                      <i className="icofont icofont-email"></i>
                      {"Email Notification"}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "4" ? "active" : ""}
                      onClick={() => setVerticleTab("4")}
                    >
                      <i className="icofont icofont-ssl-security"></i>
                      {"Security & Privacy"}
                    </NavLink>
                  </NavItem> */}

                  {/* It's not important in the current phase. It will be completed in the
                    next phase. */}

                  {/* <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "5" ? "active" : ""}
                      onClick={() => setVerticleTab("5")}
                    >
                      <i className="icofont icofont-zipped"></i>
                      {"Hide & Visualize"}
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </CardBody>
            </Card>
          </Col>

          <Col sm="8" md="8" xl="8 box-col-8">
            <TabContent activeTab={VerticleTab}>
              {
                tabList.map(tab => (
                  <TabPane className="fade show" key={tab.id} tabId={tab.id}>
                    {tab.component}
                  </TabPane>
                ))
              }
              {/* <TabPane className="fade show" tabId="1">
                <GeneralProfile />
              </TabPane>
              <TabPane tabId="2">
                <ChangePassword />
              </TabPane>
              <TabPane tabId="3">
                <EmailNotification />
              </TabPane>
              <TabPane tabId="4">
                <SecurityAndPrivacySettings />
              </TabPane>
              <TabPane tabId="5">
                <HideAccount />
              </TabPane> */}
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default AccountSettingContain;
