import React from "react";
import { Card } from "reactstrap";

export default function Rating() {
  return (
    <div>
      <Card body className="d-none">
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr className="h4">
                <td colSpan={"2"}>Reference Rated</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-1">Professionalism</td>
                <td className="text-warning py-1">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </td>
              </tr>
              <tr>
                <td className="py-1">Trustworthy</td>
                <td className="text-warning py-1">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </td>
              </tr>
              <tr>
                <td className="py-1">Reliability</td>
                <td className="text-warning py-1">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
