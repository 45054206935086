import {
  AdminUsersSvg,
  BlogSvg,
  CalanderSvg,
  ChatSvg,
  HomeSvg,
  JobsearchSvg,
  LearningSvg,
  SupportTicketSvg,
  TaskSvg,
  TodoSvg,
  UsersComponentSvg,
} from "../../Data/svgIcons";
/*
0: both
1: tutor
2: student
*/
const ALLMENU = [
  {
    id: 1,
    menutitle: "DashBoard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: HomeSvg,
        title: "DashBoard",
        type: "link",
      },
    ],
    userType: "0",
  },

  {
    id: 2,
    menutitle: "FindTutors",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/find-tutors`,
        icon: JobsearchSvg,
        title: "Find Tutors",
        type: "link",
      },
    ],
    userType: "2",
  },
  {
    id: 3,
    menutitle: "FindStudents",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/find-students`,
        icon: JobsearchSvg,
        title: "Find Students",
        type: "link",
      },
    ],
    userType: "1",
  },
  {
    id: 4,
    menutitle: "RequestTuition",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/request-tuition`, //tute-board`,
        icon: BlogSvg,
        title: "Request for Tutor",
        type: "link",
      },
    ],
    userType: "2", // hide for phase 1- will userType "0"
  },
  {
    id: 5,
    menutitle: "Member List",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/member-list`,
        icon: UsersComponentSvg,
        title: "All Member",
        type: "link",
      },
    ],
    userType: "4",
  },
  {
    id: 6,
    menutitle: "Chat",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/inbox`,
        icon: ChatSvg,
        title: "Chat",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 7,
    menutitle: "TutorNewAccount",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/tutor-account`,
        icon: AdminUsersSvg,
        title: "Tutor Account",
        type: "link",
      },
    ],
    userType: "1",
  },
  {
    id: 8,
    menutitle: "StudentNewAccount",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/student-account`,
        icon: AdminUsersSvg,
        title: "Student Account",
        type: "link",
      },
    ],
    userType: "2",
  },
  // {
  //   id: 6,
  //   menutitle: "TutorAccount",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/tutor-account`,
  //       icon: AdminUsersSvg,
  //       title: "My Account",
  //       type: "link",
  //     },
  //   ],
  //   userType: "1",
  // },
  // {
  //   id: 7,
  //   menutitle: "StudentAccount",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/student-account`,
  //       icon: AdminUsersSvg,
  //       title: "My Account",
  //       type: "link",
  //     },
  //   ],
  //   userType: "2",
  // },
  {
    id: 9,
    menutitle: "ViewProfileTutor",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/profile/me`,
        icon: TaskSvg,
        title: "View Profile Tutor",
        type: "link",
      },
    ],
    userType: "4",
  },
  {
    id: 10,
    menutitle: "MyTuitions",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/my-tuitions`,
        icon: LearningSvg,
        title: "My Tuitions",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 11,
    menutitle: "StudentTuitions",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/payment-summary`,
        icon: LearningSvg,
        title: "Payment Summary",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 12,
    menutitle: "ViewProfileStudent",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/student-profile/me`,
        icon: SupportTicketSvg,
        title: "View Profile Student",
        type: "link",
      },
    ],
    userType: "4",
  },
  {
    id: 13,
    menutitle: "OnDemandTuitionRequest",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/tutors-request`,
        icon: JobsearchSvg,
        title: "Tuition Request",
        type: "link",
      },
    ],
    userType: "1",
  },
  // {
  //   id: 14,
  //   menutitle: "tutorClassSchedule",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/tutor-class-schedule`,
  //       icon: CalanderSvg,
  //       title: "Class Schedule",
  //       type: "link",
  //     },
  //   ],
  //   userType: "1",
  // },
  // {
  //   id: 15,
  //   menutitle: "studentClassSchedule",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/student-class-schedule`,
  //       icon: CalanderSvg,
  //       title: "Class Schedule",
  //       type: "link",
  //     },
  //   ],
  //   userType: "2",
  // },
  {
    id: 16,
    menutitle: "Calender",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/calendar`,
        icon: CalanderSvg,
        title: "Calendar",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 17,
    menutitle: "ClassList",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/class-list`,
        icon: TodoSvg,
        title: "Class List",
        type: "link",
      },
    ],
    userType: "2",
  },
];

export const MENUITEMS2 = ALLMENU;
