import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  AlertCircle,
  ArrowRight,
  MinusSquare,
  PlusSquare,
} from "react-feather";
import { toast } from "react-toastify";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { ScheduleSaveNewApi } from "../../../../api/index";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import { addWeekdayListArray } from "../../../Chat/NewChat/inbox/tutorSchedule";
import "../InterestPricing/styles.css";
// import HelpModalBtn from "../../../LandingPage/BecomeTutor/ChildComponent/HelpModalBtn";
// import HelpModal from "../../../LandingPage/BecomeTutor/ChildComponent/HelpModal";
// import TutorScheduleGuide from "../../../UserGuidelines/TutorScheduleGuide";
const TutorSchedule = ({ setNext, newTutor, jumpToStep }) => {
  const previousWeekDays = addWeekdayListArray();
  const [weekdayList, setWeekdayList] = useState([]);
  const [selectedDayList, setSelectedDayList] = useState([]);
  const [timeError, setTimeError] = useState(false);
  const [saved, setSaved] = useState(true);
  const [timeErrorText, setTimeErrorText] = useState("");
  const [bottomButton, setBottomButton] = useState(0);

  function updateDateInTimeTable(timeTable, newDateStr) {
    // Check if newDateStr is a valid ISO 8601 string
    // if (!/^\d{4}-\d{2}-\d{2}$/.test(newDateStr)) {
    //   throw new Error("newDateStr must be a valid ISO 8601 date string (YYYY-MM-DD)");
    // }

    const newDate = new Date(newDateStr); // Parse ISO string into Date object

    const newDateString = newDate.toISOString().slice(0, 10); // Extract YYYY-MM-DD part

    return timeTable.map(item => ({
      ...item,
      start: newDateString + 'T' + item.start.slice(11),
      end: newDateString + 'T' + item.end.slice(11),
    }));
  }

  // get the tutor schedule from DB and sort timetable of each day
  const getTutorSchedule = async () => {
    var res = await axiosRequest({
      url: "/newMember/getTutorSchedule",
      method: "get",
    });

    var data = res?.data.tutorSchedules;
    let currentDate = new Date();
    currentDate = currentDate.toISOString();
    data = data.map((weekItems) => {
      const updatedTimeTable = updateDateInTimeTable(weekItems?.timeTable, currentDate);
      return { dayName: weekItems?.dayName, timeTable: updatedTimeTable };
    });
    if (data.length > 0) {
      let weekDays = [...previousWeekDays];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < weekDays.length; j++) {
          if (data[i].dayName === weekDays[j].dayName) {
            weekDays[j].timeTable = data[i].timeTable
              .map((time) => {
                return { ...time, isSlotError: false };
              })
              .sort((a, b) => {
                const startTimeA = new Date(
                  `1970/01/01 ${moment(a.start).format("HH:mm")}`
                );
                const startTimeB = new Date(
                  `1970/01/01 ${moment(b.start).format("HH:mm")}`
                );
                return startTimeA - startTimeB;
              });
            weekDays[j].isChecked = true;
          }
        }
      }
      setWeekdayList(weekDays);
    } else {
      setWeekdayList(previousWeekDays);
    }
  };

  useEffect(() => {
    let weekDaysTemp = [...previousWeekDays];
    setWeekdayList(weekDaysTemp);
    getTutorSchedule();
  }, []);

  const handleWeeklyDayChange = (e) => {
    setSaved(false);
    newTutor !== undefined && setNext(false);
    const { value, checked } = e.target;
    let currentSelectedDay = [...selectedDayList];
    let hasBooked = false;

    !checked &&
      currentSelectedDay.map((day) => {
        if (day.dayName === value) {
          hasBooked = day.timeTable.some((t) => t.isBooked === true);
          if (hasBooked) {
            errorPrint("You cannot unselect booked day");
            return;
          }
        }
      });
    !hasBooked && changeWeeklyDay(value, checked);
  };

  const changeWeeklyDay = (value, checked) => {
    let prevList = [...weekdayList];
    prevList.map((day) => {
      if (day.dayName === value) {
        day.isChecked = checked;
        if (day.timeTable.length === 0) {
          day.timeTable.push({
            start: new Date("2022-11-01T02:00:00.000Z"),
            end: new Date("2022-11-01T03:00:00.000Z"),
            // start: new Date("01-Nov-2022 08:00 AM"),
            // end: new Date("01-Nov-2022 09:00 AM"),
            isBooked: false,
            isSlotError: false,
          });
        }
        if (!checked) {
          day.timeTable.length = 0;
        }
      }
    });
    setWeekdayList(prevList);
  };

  const handleAddNewTiming = (item) => {
    setSaved(false);
    newTutor !== undefined && setNext(false);
    if (!timeError) {
      let _prevList = [...weekdayList];
      _prevList.map((i) => {
        if (i.dayName === item.dayName) {
          const index = i.timeTable.length - 1;
          const sTime = new Date(i.timeTable[index].end);
          const tempEndTime = new Date(sTime.getTime() + 1 * 60 * 60 * 1000);
          i.timeTable.push({
            start: new Date(sTime),
            end: new Date(tempEndTime),
            isBooked: false,
          });
          // i.timeTable.push({
          //   start: new Date("2022-11-01T02:00:00.000Z"),
          //   end: new Date("2022-11-01T03:00:00.000Z"),
          //   isBooked: false,
          // });
          // const index = i.timeTable.length - 1;
          // const sTime = i.timeTable[index].start;
          handleStartTimeChange(sTime, item, index + 1);
        }
      });
      setWeekdayList(_prevList);
    } else {
      errorPrint("Please change the invalid time first");
    }
  };

  const handleDeleteTiming = (item, ind) => {
    let _prevList = [...weekdayList];
    _prevList.map((i) => {
      if (i.dayName === item.dayName) {
        item.isError = false;
        const deletedTimetable = i?.timeTable.filter(
          (t, index) => index === ind
        );
        const otherSlots = i?.timeTable.filter((t, index) => index !== ind);
        const duplicate = validateTimeSlot(
          deletedTimetable[0]?.start,
          deletedTimetable[0]?.end,
          otherSlots
        );
        const errorTime = i?.timeTable.filter((t) => t.isSlotError);
        const errorFreeSlot = otherSlots.filter((t) => !t.isSlotError);
        const errorDuplicate = validateTimeSlot(
          errorTime[0]?.start,
          errorTime[0]?.end,
          errorFreeSlot
        );
        // if the deleted time has duplicate, remove the red error
        if (!errorDuplicate) {
          setTimeError(false);
          i.timeTable = i?.timeTable
            .filter((t, index) => index !== ind)
            .map((t) => {
              if (t.isSlotError) {
                t.isSlotError = false;
              }
              return t;
            });
        }
        // if the deleted time has no duplicate & it is not the error, just remove the item
        else if (!duplicate && !deletedTimetable[0]?.isSlotError) {
          i.timeTable = i?.timeTable.filter((t, index) => index !== ind);
        }

        // if the deleted time has no duplicate & it is the error, remove the item & the red error
        else {
          setTimeError(false);
          i.timeTable = i?.timeTable.filter((t, index) => index !== ind);
        }
        i.timeTable.length === 0 && (i.isChecked = false);
        setSaved(false);
        newTutor !== undefined && setNext(false);
      }
    });
    setWeekdayList(_prevList);
  };

  const errorPrint = (text) => {
    toastNotifiy(text, "danger");
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setBottomButton(scrollPosition);
  };

  const addNewSchedule = async () => {
    if (!newTutor && selectedDayList.length < 1) {
      toast.warn("Please add at least one schedule", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (window.confirm("Are you sure to save this information?")) {
      const res = await axios.post(
        ScheduleSaveNewApi,
        {
          schedules: selectedDayList,
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (res.data) {
        toastNotifiy("Schedule Updated Successfully", "success");
        newTutor !== undefined && setNext(true);
        setSaved(true);
      } else {
        alert("something went wrong!");
      }
    }
  };

  let setValues = (type, timeTable, index, endTime, error, startTime) => {
    timeTable[index].start = startTime;
    timeTable[index].end = endTime;
    timeTable[index].isSlotError = error;
    setTimeError(error);
  };

  // changes and validates start time
  const handleStartTimeChange = (sTime, item, index) => {
    const tempEndTime = new Date(sTime.getTime() + 1 * 60 * 60 * 1000);
    let _prevList = [...weekdayList];
    _prevList.map((p) => {
      if (p.dayName === item.dayName) {
        const validRange = smallEndTimeValidation(sTime, tempEndTime);
        // if the written end time is smaller than start time then show error and end the process
        if (!validRange) {
          setValues("start", p.timeTable, index, tempEndTime, true, sTime);
          setTimeErrorText(`Please select valid time`);
          return;
        }
        if (p.timeTable.length > 1) {
          let otherTimes = p.timeTable.filter((t, i) => index !== i);
          const duplicate = validateTimeSlot(sTime, tempEndTime, otherTimes);
          if (duplicate) {
            setValues("start", p.timeTable, index, tempEndTime, true, sTime);
            setTimeErrorText(`New schedule overlaps old schedules`);
          } else {
            // if there's no duplicate, set the current index schedule error free
            setValues("start", p.timeTable, index, tempEndTime, false, sTime);
            // set all other index schedule error free
            p.timeTable = p?.timeTable.map((t) => {
              if (t.isSlotError) {
                t.isSlotError = false;
              }
              return t;
            });
            setTimeErrorText(``);
          }
          return;
        }
        setValues("start", p.timeTable, index, tempEndTime, false, sTime);
        setTimeErrorText(``);
      }
    });
    setWeekdayList(_prevList);
  };

  const isTimeBetween = function (startTime, endTime, selectedTime, type) {
    let start = moment(startTime, "H:mm");
    let end = moment(endTime, "H:mm");
    let selected = moment(selectedTime, "H:mm");

    if (end < start) {
      if (type === "start") {
        return selected >= start && selected > end;
      }
      if (type === "end") {
        return selected <= end && selected < start;
      }

      // return (
      //   (selected >= start || selected < end)
      //   // (selected >= start && selected <= moment("23:59:59", "h:mm:ss")) ||
      //   // (selected >= moment("0:00:00", "h:mm:ss") && selected < end)
      // );
    }

    if (type === "start") return selected >= start && selected < end;
    if (type === "end") return selected <= end && selected > start;
  };

  const validateTimeSlot = (selectedStart, selectedEnd, freeSlots) => {
    var isValidTime = false;
    for (let i = 0; i < freeSlots.length; i++) {
      var start1 = moment(new Date(freeSlots[i].start)).format("HH:mm");
      var end1 = moment(new Date(freeSlots[i].end)).format("HH:mm");
      var selected1 = moment(selectedStart).format("HH:mm");
      var selected2 = moment(selectedEnd).format("HH:mm");
      var isStartTimeValid = isTimeBetween(start1, end1, selected1, "start");
      var isEndTimeValid = isTimeBetween(start1, end1, selected2, "end");
      var isOldStartTimeValid = isTimeBetween(
        selected1,
        selected2,
        start1,
        "start"
      );
      var isOldEndTimeValid = isTimeBetween(selected1, selected2, end1, "end");

      if (isStartTimeValid || isEndTimeValid) {
        isValidTime = true;
        break;
      } else if (isOldStartTimeValid || isOldEndTimeValid) {
        isValidTime = true;
        break;
      } else if (
        (!isStartTimeValid && !isEndTimeValid && !isOldStartTimeValid) ||
        !isOldEndTimeValid
      ) {
        isValidTime = false;
      }
    }
    return isValidTime;
  };

  // validates if the end time is greater than start time or not
  const smallEndTimeValidation = (startTime, endTime) => {
    const startHour = new Date(startTime).getHours();
    const endHour = new Date(endTime).getHours();
    const endMinutes = new Date(endTime).getMinutes();
    if (startHour <= endHour || (endHour === 0 && endMinutes === 0)) {
      return true;
    } else {
      return false;
    }
  };

  // changes and validates end time
  const handleEndTimeChange = (eTime, item, index) => {
    let _prevList = [...weekdayList];
    _prevList.map((p) => {
      if (p.dayName === item.dayName) {
        const validRange = smallEndTimeValidation(
          item?.timeTable[index]?.start,
          eTime
        );
        // if the written end time is smaller than start time then show error and end the process
        if (!validRange) {
          setValues(
            "end",
            p.timeTable,
            index,
            eTime,
            true,
            item?.timeTable[index]?.start
          );
          setTimeErrorText(`Please select valid time`);
          return;
        }
        // if the written end time & start time is equal then show error and end the process
        if (
          moment(item?.timeTable[index]?.start).format("HH:mm") ===
          moment(eTime).format("HH:mm")
        ) {
          setValues(
            "end",
            p.timeTable,
            index,
            eTime,
            true,
            item?.timeTable[index]?.start
          );
          setTimeErrorText(`Start and end time can't be similar`);
          return;
        }

        if (p.timeTable.length > 1) {
          let otherTimes = p.timeTable.filter((t, i) => index !== i);
          const duplicate = validateTimeSlot(
            item?.timeTable[index]?.start,
            eTime,
            otherTimes
          );

          if (duplicate) {
            setValues(
              "end",
              p.timeTable,
              index,
              eTime,
              true,
              item?.timeTable[index]?.start
            );
            setTimeErrorText(`New schedule overlaps old schedules`);
          } else {
            setValues(
              "end",
              p.timeTable,
              index,
              eTime,
              false,
              item?.timeTable[index]?.start
            );
            p.timeTable = p?.timeTable.map((t) => {
              if (t.isSlotError) {
                t.isSlotError = false;
              }
              return t;
            });
            setTimeErrorText("");
          }
          return;
        }

        setValues(
          "end",
          p.timeTable,
          index,
          eTime,
          false,
          item?.timeTable[index]?.start
        );
        setTimeErrorText("");
      }
    });
    setWeekdayList(_prevList);
  };

  // whenever schedule changes, selected list is updated by removing extra fields
  useEffect(() => {
    var selectedList = [];
    weekdayList.map((day) => {
      if (day.isChecked) {
        const cleanedTimeTable = day.timeTable.map((item) => {
          const { isSlotError, ...cleanItem } = item;
          return cleanItem;
        });
        selectedList.push({
          dayName: day.dayName,
          timeTable: cleanedTimeTable,
        });
      }
    });
    setSelectedDayList(selectedList);
  }, [weekdayList]);

  return (
    <Fragment>
      {/* {bottomButton < 200 && ( */}
      <Row
        style={{ paddingLeft: "5px" }}
        className="d-flex align-items-center justify-content-between bg-light"
      >
        <Col sm={9} className="d-flex align-items-center text-primary">
          <p className="Plus">
            {/* <AlertCircle size={18} className="m-r-5 icon"></AlertCircle> */}
            {/* <HelpModalBtn showIconAlways={true} /> */}
            We suggest you to add schedule with smaller slots according to your
            tuition timings.
          </p>
        </Col>
        {/* {bottomButton < 200 && ( */}
        {!newTutor && (
          <Col className="d-flex justify-content-end m-b-5">
            <Button
              color="success"
              disabled={saved}
              onClick={
                timeError === false
                  ? addNewSchedule
                  : () => errorPrint("Invalid time cannot be saved")
              }
            >
              Save Changes
            </Button>
          </Col>
        )}
        {/* )} */}
      </Row>
      <Row className="p-t-10">
        <Col
          sm="12"
          className="bg-warning d-flex justify-content-between p-2 rounded m-t-10"
        >
          {weekdayList?.map((day, index) => (
            <div key={index}>
              <Input
                type="checkbox"
                className="m-r-5"
                onChange={(e) => handleWeeklyDayChange(e)}
                name={day.dayName}
                value={day.dayName}
                checked={day.isChecked}
              />
              <Label check>{day.dayName?.toUpperCase()}</Label>
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="px-4 m-t-5">
          {weekdayList?.map(
            (item, dayIndex) =>
              item.isChecked && (
                <Row
                  key={dayIndex}
                  className="m-b-5 d-flex justify-content-center"
                >
                  {item?.timeTable.map((time, index) => (
                    <div key={index}>
                      <Row
                        className={`d-flex align-items-center weekly_schdule_box m-b-5
                      ${time?.isSlotError === true ? "bg-danger" : ""}`}
                      >
                        <Col lg="3" md="3">
                          {index === 0 ? (
                            <div className="d-flex align-items-center">
                              <Label sm={3}>
                                <b> {item.dayName.toUpperCase()}</b>
                              </Label>
                              <a
                                className="m-l-5"
                                onClick={() => handleAddNewTiming(item)}
                              >
                                <PlusSquare height={25} width={25} />
                              </a>
                            </div>
                          ) : (
                            <Label sm={2}></Label>
                          )}
                        </Col>
                        <Col lg="9" md="8">
                          <div className="d-flex align-items-center justify-content-around">
                            <Col xl="3" sm="3" lg="3">
                              <DatePicker
                                className="form-control datetimepicker-input digits"
                                selected={
                                  new Date(item?.timeTable[index].start)
                                }
                                onChange={(sTime) => {
                                  handleStartTimeChange(sTime, item, index);
                                  setSaved(false);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Start"
                                timeFormat="h:mm aa"
                                dateFormat="h:mm aa"
                                disabled={time.isBooked}
                              />
                            </Col>
                            <Label
                              for="exampleEmail2"
                              className="text-center"
                              sm={1}
                            >
                              <ArrowRight />
                            </Label>
                            <Col xl="3" sm="3" lg="3">
                              <DatePicker
                                className="form-control datetimepicker-input digits"
                                selected={new Date(item?.timeTable[index].end)}
                                onChange={(eTime) => {
                                  handleEndTimeChange(eTime, item, index);
                                  setSaved(false);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Start"
                                timeFormat="h:mm aa"
                                dateFormat="h:mm aa"
                                minTime={
                                  new Date(item?.timeTable[index]?.start)
                                }
                                maxTime={new Date("2022-11-01T17:59:00.000Z")}
                                // maxTime={new Date("01-Nov-2022 11:45 PM")}
                                disabled={time.isBooked}
                              />
                            </Col>
                            <Col lg="2">
                              <button
                                disabled={time?.isBooked}
                                style={{ backgroundColor: "transparent" }}
                                className="border-0 p-0"
                                onClick={() => handleDeleteTiming(item, index)}
                              >
                                <MinusSquare
                                  style={{ color: "#b1a7a6" }}
                                  className={`${time?.isSlotError
                                    ? "text-white"
                                    : time?.isBooked
                                      ? ""
                                      : "text-danger"
                                    }`}
                                  height={25}
                                  width={25}
                                />
                              </button>
                            </Col>
                          </div>
                          {time?.isSlotError === true && (
                            <Row
                              className="text-center"
                              style={{ height: "30px" }}
                            >
                              <Col sm={{ size: 7, offset: 1 }}>
                                {timeErrorText}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Row>
              )
          )}
        </Col>
      </Row>
      {/* {bottomButton > 200 && ( */}
      {newTutor && (
        <div className="m-b-10 d-flex justify-content-end">
          <Button
            color="success"
            disabled={saved}
            onClick={
              timeError === false
                ? addNewSchedule
                : () => errorPrint("Invalid time cannot be saved")
            }
          >
            Save Changes
          </Button>
        </div>
      )}
      {/* )} */}
      {/* <HelpModal heading="Create Schedule" body={<TutorScheduleGuide />} /> */}
    </Fragment>
  );
};
export default TutorSchedule;
