import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { Btn } from "../../AbstractElements";

const ProfileTabs = ({ tabList, activeTab, toggleTab, setActiveTab }) => {
  const { memberType } = useSelector((state) => state.menu) || {};

  const handlePrevious = () => {
    if (activeTab > 1) {
      setActiveTab((parseInt(activeTab) - 1).toString());
    }
  };

  const handleNext = () => {
    if (activeTab < tabList.length) {
      setActiveTab((parseInt(activeTab) + 1).toString());
    }
  };

  const navigate = useNavigate();

  const submitFun = () => {
    //window.location.reload();
    navigate(`${process.env.PUBLIC_URL}/profile/me`);
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader className="pb-0">
            {/* <H5>Pearls Step States</H5><span>A pearls step states with different class<code>.done</code><code>.currunt</code><code>.error</code><code>.disabled</code></span> */}
          </CardHeader>
          <CardBody>
            <Row>
              <Col className="d-flex align-items-center justify-content-around">
                {/* hidden divs are used for better UI */}
                <div className="u-pearl col-3" hidden>
                  <div className="u-pearl-icon"></div>
                </div>
                {
                  tabList.map((tab) => (
                    <div key={tab.id} className={`u-pearl ${memberType === "tutor" ? "col-3" : "col-6"} ${activeTab === tab.id ? "done" : "current"}`}>
                      <button className="u-pearl-icon" onClick={() => toggleTab(tab.id)}>
                        <i className={`icofont ${tab.icon}`}></i>
                      </button>
                    </div>
                  ))
                }
                <div className="u-pearl current col-3" hidden>
                  <div className="u-pearl-icon"></div>
                </div>
              </Col>
            </Row>

            <TabContent activeTab={activeTab}>
              {
                tabList.map((tab) => (
                  <TabPane className="m-t-20 " key={tab.id} tabId={tab.id}>
                    {
                      tab.name !== "About Me" && (
                        <p className="text-center f-22 txt-primary"><strong>{tab.name}</strong></p>
                      )
                    }
                    {tab.component}
                  </TabPane>
                ))
              }
            </TabContent>
            <div className="w-100 d-flex justify-content-between mt-5">
              <Button
                color="primary"
                onClick={handlePrevious}
                disabled={activeTab === "1"}
              >
                Previous
              </Button>
              {
                activeTab == tabList.length ?
                  <Btn
                    attrBtn={{
                      color: "warning",
                      className: "pull-right",
                      onClick: submitFun,
                    }}
                  >
                    {"Finish"}
                  </Btn>
                  :

                  <Button
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
              }
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProfileTabs;
