import React from "react";
import Slider from "react-slick";
import { Card, CardBody, CardHeader } from "reactstrap";
import Image from "../../../../CommonElements/Media";

export const SlickSlider = ({
  slides,
  rtl,
  title,
  lazyLoad,
  autoplay,
  focusOnSelect,
}) => {
  const CarouselsData = [
    {
      id: 1,
      img: require("../../assets/img/slider/1.png"),
    },
    {
      id: 2,
      img: require("../../assets/img/slider/2.png"),
    },
    // {
    //   id: 3,
    //   img: require("../../assets/img/slider/1.png"),
    // },
  ];

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    arrows: false,
    slidesToShow: slides,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 6000,
    centerPadding: "10px",
    centerMode: false,
    rtl: rtl,
    focusOnSelect: focusOnSelect,
    lazyLoad: lazyLoad,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Card>
      <CardHeader className="pb-0"></CardHeader>
      <CardBody>
        <Slider {...settings}>
          {CarouselsData.map((item) => (
            <div className="item" key={item.id}>
              <Image
                attrImage={{
                  src: `${item.img}`,
                  alt: "",
                  className: "img-fluid",
                }}
              />
            </div>
          ))}
        </Slider>
      </CardBody>
    </Card>
  );
};

export default SlickSlider;
