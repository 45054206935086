import moment from "moment";

export const getTuitionOptions = (allTuitions) => {
  const arrList = [];
  const myTuitions = [...allTuitions];

  myTuitions?.map((item) => {
    let subjects = "";
    let tag = "";

    if (item.tuitionRequest?.subjects) {
      item.tuitionRequest.subjects.map((subject, index) => {
        const comma = item.tuitionRequest.subjects.length - 1 === index ? "" : " - ";
        subjects += subject.label?.toString().trim() + comma;
        tag = subject.tag;
      });
    } else if (item.interestedSubjects?.subjects) {
      item.interestedSubjects.subjects.map((subject, index) => {
        const comma = item.interestedSubjects.subjects.length - 1 === index ? "" : " - ";
        subjects += subject.name?.toString().trim() + comma;
        tag = subject.tag;
      });
    }

    arrList.push({
      info: item,
      studentName: item?.student?.firstName + ' ' + item?.student?.lastName,
      value: item._id,
      label: subjects + " >> " + tag,
      status: item.status,
    });
  });

  return arrList;
};

export const validateTimeFromStudentScheduleList = (scheduleEventList, startDate, selectedStart, selectedEnd) => {
  const dt1 = moment(startDate).format("YYYY-MM-DD");
  const selected1 = moment(selectedStart).format("HH:mm");
  const selected2 = moment(selectedEnd).format("HH:mm");

  const filteredList = scheduleEventList.filter((item) => {
    const dt2 = moment(item.date).format("YYYY-MM-DD");

    if (dt1 !== dt2) {
      return false;
    }

    const start1 = moment(item.start).format("HH:mm");
    const end1 = moment(item.end).format("HH:mm");

    const isStartTime = isTimeExistsBetween(start1, end1, selected1, "start");
    const isEndTime = isTimeExistsBetween(start1, end1, selected2, "end");
    // console.log("isStartTime", isStartTime, start1);
    // console.log("isEndTime", isEndTime, end1);
    return isStartTime && isEndTime;
  });

  return filteredList;
};

const isTimeExistsBetween = function (startTime, endTime, selectedTime, type) {
  let start = moment(startTime, "H:mm");
  let end = moment(endTime, "H:mm");
  let selected = moment(selectedTime, "H:mm");

  if (end < start) {
    return (selected >= start && selected <= moment("23:59:59", "h:mm:ss")) || (selected >= moment("0:00:00", "h:mm:ss") && selected < end);
  }

  if (type === "start") return selected >= start && selected < end;
  if (type === "end") return selected <= end && selected > start;
};