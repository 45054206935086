import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { axiosRequest } from "../../../redux/utils/axios-utils";

import { Filter, List, MapPin, X } from "react-feather";
import { useSelector } from "react-redux";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import MemberInformationContext from "../../../_helper/MemberInformation";
import TutorsContext from "../../../_helper/Tutors";
import MapViewContain from "../MapView";
import BasicFilter from "./BasicFilter";
import FilterOptionsContain from "./FilterOptions";
import QuickSearch from "./QuickSearch";
import SubjectsFilter from "./SubjectsFilter";
import TutorCardContain from "./TutorCard";
import TutorLocationFilter from "./TutorLocationFilter";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
// import EducationFilter from "./EducationFilter";
import ScheduleFilter from "./ScheduleFilter";
import EducationFilter from "./EducationFilter";
import WorkExperienceFilter from "./WorkExperienceFilter";
import TuitionFeesFilter from "./TuitionFeesFilter";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";
import { useWindowWidth } from "../../Chat/NewChat/inbox/useWindowWidth";
// import HelpModalBtn from "../../LandingPage/BecomeTutor/ChildComponent/HelpModalBtn";
// import HelpModal from "../../LandingPage/BecomeTutor/ChildComponent/HelpModal";
// import FIndTutorsGuide from "../../UserGuidelines/FIndTutorsGuide";

import {
  filterByTutorName,
  filterByAge,
  filterByRating,
  filterByEducation,
  filterByLanguage,
  filterByPrice,
  filterBySchedule,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
  filterByWorkExperience,
} from "../../../Services/Filter.Service";
import RatingFilter from "./RatingFilter";

export default function ListView() {
  const {
    name,
    setName,
    age,
    isRatingChange,
    rating,
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    scheduleFilter,
    setScheduleFilter,
    hasTime,
    setHasTime,
    educationFilter,
    setEducationFilter,
    workExperiencesFilter,
    setWorkExperiencesFilter,
    priceFilter,
    setPriceFilter,
  } = useContext(FilterContext);

  const { currency1, setCurrency } = useContext(MemberInformationContext);

  const {
    tutorList: tutors,
    setTutorList,
    tutorWithRatings,
    ratingList,
    getallReviewsAndRatings,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const width = useWindowWidth();
  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSearched, setIsSearched] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [ratings, setRating] = useState([]);
  const [tutorWithRatingsList, setTutorWithRatings] = useState([]);

  useEffect(() => {
    if (tutors.length < 1) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  });

  const memoizedGetallReviewsAndRatings = useMemo(
    () => getallReviewsAndRatings,
    []
  );

  useEffect(() => {
    memoizedGetallReviewsAndRatings();
  }, [memoizedGetallReviewsAndRatings]);

  const ratigsnWithTutorList = useMemo(() => {
    const updatedRatings = [];
    tutors?.forEach((item) => {
      let totalRating = 0;
      let noOfClasses = 0;

      ratingList?.forEach((item2) => {
        if (item.userId === item2.tutorId) {
          if (item2?.feedback?.fromStudent?.rating !== undefined) {
            totalRating += item2?.feedback?.fromStudent?.rating;
            noOfClasses += 1;
          }
        }
      });

      if (totalRating > 0) {
        updatedRatings.push({
          noOfClasses,
          rating: totalRating / noOfClasses,
          tutorId: item.userId,
        });
      }
    });
    setTutorWithRatings(updatedRatings);

    return updatedRatings;
  }, [tutors, ratingList]);

  const allFilteredTutors = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "student" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const showTutors = allFilteredTutors?.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredTutors?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const checkInsideLimitRange = (
    userFrom1,
    filterFrom1,
    userTo1,
    filterTo1
  ) => {
    var format = "hh:mm:ss";
    const userFrom = moment(userFrom1.toString().slice(11, 19), format);
    const filterFrom = moment(filterFrom1.toString().slice(16, 24), format);
    const userTo = moment(userTo1.toString().slice(11, 19), format);
    const filterTo = moment(filterTo1.toString().slice(16, 24), format);

    if (
      filterFrom1.toString().slice(16, 24) == "00:00:00" &&
      filterTo1.toString().slice(16, 24) == "23:59:00"
    ) {
      return true;
    } else {
      //var userFromH = parseInt(userFrom1.toString().slice(11, 13)) * 60 + parseInt(userFrom1.toString().slice(14, 16))
      //console.log(parseInt(userFrom1.toString().slice(11, 13)) * 60, parseInt(userFrom1.toString().slice(14, 16)))
      //console.log(filterFrom.isBetween(userFrom, userTo))
      if (
        filterFrom.isBetween(userFrom, userTo) ||
        filterTo.isBetween(userFrom, userTo)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const filterBySchedule = async (tutors, scheduleFilter) => {
    let filteredTutors = [];
    let tempTutors = tutors.filter((tutor) => tutor?.tutorSchedules.length > 0);
    scheduleFilter.map((filterday) => {
      tempTutors.map((tutor) => {
        tutor.tutorSchedules.map((schedule) => {
          if (
            filterday?.dayName === schedule?.dayName &&
            !hasTime &&
            !filteredTutors.includes(tutor)
          ) {
            filteredTutors.push(tutor);
          } else if (
            filterday?.dayName === schedule?.dayName &&
            hasTime &&
            !filteredTutors.includes(tutor)
          ) {
            const isMatched = handleIncludeTime(
              filterday,
              schedule,
              tutor.name.firstName
            );
            isMatched && filteredTutors.push(tutor);
          }
        });
      });
    });
    return filteredTutors;
  };

  const handleIncludeTime = (filterday, schedule, name) => {
    let match = false;
    schedule?.timeTable
      .filter((time) => !time.isBooked)
      .map((time) => {
        // Convert tutorStart and tutorEnd to local time
        const tutorStart = new Date(time.start).toLocaleTimeString("en-US", {
          hour12: false,
        });
        const tutorEnd = new Date(time.end).toLocaleTimeString("en-US", {
          hour12: false,
        });

        // Check if filterFrom and filterTo fall between tutorStart and tutorEnd
        const filterFrom = new Date(filterday.timeTable.from);
        const filterTo = new Date(filterday.timeTable.to);

        const filterFromTime = new Date(
          filterFrom.getFullYear(),
          filterFrom.getMonth(),
          filterFrom.getDate(),
          filterFrom.getHours(),
          filterFrom.getMinutes(),
          filterFrom.getSeconds()
        ).toLocaleTimeString("en-US", { hour12: false });
        const filterToTime = new Date(
          filterTo.getFullYear(),
          filterTo.getMonth(),
          filterTo.getDate(),
          filterTo.getHours(),
          filterTo.getMinutes(),
          filterTo.getSeconds()
        ).toLocaleTimeString("en-US", { hour12: false });

        if (
          moment.utc(time.start).local().format("HH:mm") ===
            moment(filterday.timeTable.from).format("HH:mm") &&
          moment.utc(time.end).local().format("HH:mm") ===
            moment(filterday.timeTable.to).format("HH:mm")
        ) {
          match = true;
        } else if (filterFromTime >= tutorStart && filterToTime <= tutorEnd) {
          match = true;
        }
      });
    return match;
  };

  const fetchFilteredTutors = async () => {
    setIsLoading(true);
    var filteredTutors = [...tutors];
    var ratingsTutors = [...ratigsnWithTutorList];

    if (filters.name) {
      filteredTutors = await filterByTutorName(filteredTutors, filters.name);
    }

    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor?.gender?.toLowerCase() == filters.gender?.toLowerCase()
      );
    }

    if (filters.age) {
      filteredTutors = await filterByAge(filteredTutors, filters.age);
    }

    if (isRatingChange && filters.rating) {
      filteredTutors = await filterByRating(
        filteredTutors,
        ratingsTutors,
        filters.rating
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) =>
          tutor.media.picture.length > 0 ||
          tutor?.media?.profilePicture?.imageInfo?.secure_url?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    if (filters.scheduleFilter) {
      filteredTutors = await filterBySchedule(
        filteredTutors,
        filters.scheduleFilter
      );
    }
    if (filters.educationFilter) {
      filteredTutors = await filterByEducation(
        filteredTutors,
        filters.educationFilter
      );
    }
    if (filters.workExperiencesFilter) {
      filteredTutors = await filterByWorkExperience(
        filteredTutors,
        filters.workExperiencesFilter
      );
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }

    // filtering ends here
    setTutorsToShow(filteredTutors);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    GetAllTutors();
    setIsLoading(false);
  }, []);

  const updateFilter = (a) => {
    // setIsLoading(true);
    if (a === 1) setIsSearched(!isSearched);
    setIsReset(false);

    const addedFilter = {};

    if (name != "") {
      addedFilter.name = name;
    }

    if (age.length != 0) {
      addedFilter.age = age;
    }
    if (isRatingChange && rating.length != 0) {
      addedFilter.rating = rating;
    }

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(scheduleFilter).length) {
      addedFilter.scheduleFilter = scheduleFilter;
    }
    if (Object.keys(educationFilter).length) {
      addedFilter.educationFilter = educationFilter;
    }
    if (Object.keys(workExperiencesFilter).length) {
      addedFilter.workExperiencesFilter = workExperiencesFilter;
    }
    if (Object.keys(priceFilter).length) {
      addedFilter.priceFilter = priceFilter;
    }

    setFilters(addedFilter);
  };

  const handleSearch = () => {
    setIsSearched(!isSearched);
  };

  const [isListView, setIsListView] = useState(true);
  const [isMapViewView, setIsMapView] = useState(false);

  const handleListView = (e) => {
    e.preventDefault();
    setIsListView(true);
    setIsMapView(false);
  };
  const handleMapView = (e) => {
    e.preventDefault();
    setIsMapView(true);
    setIsListView(false);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTutors();
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  return (
    <Fragment>
      <Container fluid={true} className="pt-2">
        <div className={`row form-inline my-3 mx-0 search_tutor pt-4 `}>
          <QuickSearch isReset={isReset} />
          <div className={`${width > 768 ? "col-xxl-2 d-flex mb-3" : ""}`}>
            <div className="col-md-1 d-flex  search_btn_public">
              <button
                className="btn btn-outline-info"
                style={{ minHeight: "38px" }}
                onClick={(e) => updateFilter(1)}
              >
                Search
              </button>
            </div>

            <button
              className="btn btn-sm btn-outline-warning px-2 d-none"
              style={{
                marginLeft: "50px",
                width: "120px",
                textAlign: "-webkit-match-parent",
                fontSize: "14px",
              }}
            >
              <Filter height={17} />
              <span style={{ position: "absolute", marginTop: "0px" }}>
                Filter More
              </span>
            </button>
          </div>
        </div>

        <Row className="mb-0 py-2">
          <Col md={8} className={`${width < 768 ? "d-none" : "d-block"}`}>
            <Col md="12 search_title" className="mt-2">
              <h4>Your Search Results</h4>
            </Col>
            <Col sm="8">
              {isLoading ? null : (
                <p className="pb-1">
                  About {allFilteredTutors?.length} tutors found (0.60 seconds)
                </p>
              )}
            </Col>
          </Col>
          <Col
            md={4}
            className={`px-3 text-nowrap ${
              width < 768
                ? "d-flex justify-content-between align-items-center"
                : ""
            }`}
            style={{ textAlign: "-webkit-right" }}
          >
            {/* style={{ textAlign: "-webkit-right" }} */}
            {/* <div
              className={`square-product-setting d-inline-block tabView d-none`}
              style={{ paddingRight: "20px" }}
            >
              <button
                className="btn btn-sm btn-outline-warning"
                onClick={handleSearch}
              >
                Show Quick Search
              </button>
            </div> */}

            <div
              className={`${
                width > 768
                  ? "d-block justify-content-between align-items-center"
                  : "d-flex justify-content-between align-items-center"
              }`}
            >
              <div>
                <div className={`square-product-setting d-inline-block`}>
                  <a
                    className={`icon-grid m-0 list-layout-view  ${
                      isListView ? "icon_visited" : ""
                    }`}
                    onClick={(e) => handleListView(e)}
                    href="#javascript"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="List View"
                  >
                    <List />
                  </a>
                </div>
                <div className={`mx-2 square-product-setting d-inline-block`}>
                  <a
                    className={`icon-grid grid-layout-view  ${
                      isMapViewView ? "icon_visited" : ""
                    }`}
                    onClick={(e) => handleMapView(e)}
                    href="#javascript"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Map View"
                  >
                    <MapPin />
                  </a>
                </div>
                {/* <HelpModalBtn showIconAlways={true} /> */}
              </div>
            </div>
            <div className={`${width < 768 ? "d-block" : "d-none"}`}>
              More Filter Below
            </div>
          </Col>
        </Row>
        <Row className="d-none">
          <Col sm="12">
            Filtered By:{" "}
            <span className="px-2">
              <span className="text-primary">
                Bangladesh
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Dhaka
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Physics
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">Clear All</span>
            </span>
          </Col>
        </Row>

        <Row className="mb-5 tutorViewResponsive">
          <Col className="mb-5 tutorViewResponsive">
            <Col>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <PulseLoader
                    color="blue"
                    isLoading={isLoading}
                    size={25}
                    aria-label="isLoading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <span>
                  {isListView && (
                    <TutorCardContain
                      tutors={showTutors}
                      allFilteredTutors={allFilteredTutors}
                      tutorWithRatings={tutorWithRatingsList}
                    />
                  )}
                  {isMapViewView && (
                    <MapViewContain tutorWithRatings={tutorWithRatingsList} />
                  )}
                </span>
              )}
            </Col>
            <Col md={8} className={`${width > 768 ? "d-none" : "d-block"}`}>
              <Col md="12 search_title" className="mt-2">
                <h4>Your Search Results</h4>
              </Col>
              <Col sm="8">
                {isLoading ? null : (
                  <p className="pb-1">
                    About {allFilteredTutors?.length} tutors found (0.60
                    seconds)
                  </p>
                )}
              </Col>
            </Col>
          </Col>

          <Col xl="4" className="filterListResponsive mt-0">
            {/* <div>
              <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
            </div> */}
            <div>
              <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                <AccordionItem>
                  <AccordionHeader targetId="1">Basic</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <BasicFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Subjects</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <SubjectsFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Tutor's Location
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <TutorLocationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Tutor Ratings</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <RatingFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="8">Schedule</AccordionHeader>
                  <AccordionBody accordionId="8">
                    <ScheduleFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-3 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              {parseFloat(priceFilter.min) > parseFloat(priceFilter.max) ? (
                <Button
                  disabled
                  color="primary"
                  className="mb-3 "
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {isListView && allFilteredTutors?.length !== 0 && (
              <div className="mt-5 mb-5 pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  //disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            )}
          </Col>
        </Row>
        {/* <HelpModal heading="Find Tutors" body={<FIndTutorsGuide />} /> */}
      </Container>
    </Fragment>
  );
}
