import SetUsersLocation from "../Component/FindTutors/SetUsersLocation";
import NewStudentProfileContain from "../Component/Profile/Student";
import NewTutorProfileContain from "../Component/Profile/Tutor";
import AccountSettings from "../Pages/Account/AccountSettings";
//import StudentAccount from "../Pages/Account/StudentAccount";
//import TutorAccount from "../Pages/Account/TutorAccount";
import AllUsers from "../Pages/AllUsers";
import ConversationContain from "../Pages/Chat/ChatApp/Conversation";
import InboxContain from "../Pages/Chat/ChatApp/Inbox";
import ClassList from "../Pages/ClassList";
import Default from "../Pages/DashBoard/Default/Default";
import FAQ from "../Pages/FAQ";
import FindStudents from "../Pages/FindStudents";
import FindTutors from "../Pages/FindTutors";
import StudentNewAccount from "../Pages/NewAccount/StudentAccount";
import TutorNewAccount from "../Pages/NewAccount/TutorAccount";
import MyTuition from "../Pages/NewMyTuitions";
import NewStudentTuitions from "../Pages/NewMyTuitions/Student";
import NewTutorTuitions from "../Pages/NewMyTuitions/Tutor";
import OnDemandTuitionRequest from "../Pages/OnDemandTuitionRequest";
import PaymentSummary from "../Pages/PaymentSummary";
import RequestTuition from "../Pages/RequestTuition";
import CalendarContainNew from "../Pages/Schedule/Calendar";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Default />,
  },
  { path: `${process.env.PUBLIC_URL}/find-tutors`, Component: <FindTutors /> },
  {
    path: `${process.env.PUBLIC_URL}/find-students`,
    Component: <FindStudents />,
  },
  {
    path: `${process.env.PUBLIC_URL}/set-location`,
    Component: <SetUsersLocation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/request-tuition`,
    Component: <RequestTuition />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tutor-account`,
    Component: <TutorNewAccount />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/tutor-new-account`,
  //   Component: <TutorNewAccount />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/student-new-account`,
  //   Component: <StudentNewAccount />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/student-account`,
    Component: <StudentNewAccount />,
  },
  {
    path: `${process.env.PUBLIC_URL}/profile/:id`,
    Component: <NewTutorProfileContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tutor-tuitions`,
    Component: <NewTutorTuitions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/student-tuitions`,
    Component: <NewStudentTuitions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/student-profile/:id`,
    Component: <NewStudentProfileContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/member-list`,
    Component: <AllUsers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/inbox`,
    Component: <ConversationContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/inbox/:id`,
    Component: <InboxContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings`,
    Component: <AccountSettings />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/settings/personalInfo`,
  //   Component: <PersonalAccountSettings />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/settings/security-privacy`,
  //   Component: <SecurityAndPrivacySettings />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/tutors-request`,
    Component: <OnDemandTuitionRequest />,
  },
  {
    path: `${process.env.PUBLIC_URL}/faq/`,
    Component: <FAQ />,
  },
  {
    path: `${process.env.PUBLIC_URL}/calendar/`,
    Component: <CalendarContainNew />,
  },
  {
    path: `${process.env.PUBLIC_URL}/class-list`,
    Component: <ClassList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-tuitions`,
    Component: <MyTuition />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payment-summary`,
    Component: <PaymentSummary />,
  },
];
