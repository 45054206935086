import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const HelpCreateAccount = () => {
  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  return (
    <div>
      <p className="f-16">
        If you haven't created an account yet,
        <Button
          style={{ padding: "4px 6px", border: "none", outline: "none" }}
          color=" "
          disabled={_tuteair ? true : false}
        >
          {_tuteair ? (
            <span>Create</span>
          ) : (
            <Link to={`${process.env.PUBLIC_URL}/register-v2`} target="_blank">
              <span> Create</span>
            </Link>
          )}
        </Button>
        one right away following the steps below:
        <ul className="f-16">
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              1
            </span>{" "}
            Sign in with your google account
          </li>
          <li className="text-center">or</li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              2
            </span>{" "}
            Fill out your name, email and password
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              3
            </span>{" "}
            Choose your account type
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              4
            </span>{" "}
            Check the box
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              5
            </span>{" "}
            Click on
            <Button
              style={{ padding: "4px 6px", border: "none", outline: "none" }}
              color=" "
              disabled={_tuteair ? true : false}
            >
              {_tuteair ? (
                <span>Create Account</span>
              ) : (
                <Link
                  to={`${process.env.PUBLIC_URL}/register-v2`}
                  target="_blank"
                >
                  <span className="txt-primary">Create Account</span>
                </Link>
              )}
            </Button>
            to create your account successfully
          </li>
          <br />
          <li style={{ textAlign: "justify" }}>
            <i class="fa fa-hand-o-right txt-info m-r-5"></i>
            <span>
              After account creation, check your email inbox for the
              confirmation email. If you can’t find it there, please check the
              spam email. You need to click on the link sent in the email to
              become our verified member to facilitate with all the services.
            </span>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default HelpCreateAccount;
