import moment from "moment";

export const filterByTutorName = async (filteredTutors, name) => {
  var temp_tutors = [];

  var nameSplit = name.split(" ");

  const emaptyStringRemove = nameSplit.filter((str) => str !== "");

  for (var i = 0; i < filteredTutors.length; i++) {
    if (
      filteredTutors[i]?.student?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[0]?.toLowerCase()) ||
      filteredTutors[i]?.student?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.student?.name.firstName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.student?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.student?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.student?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[-1]?.toLowerCase()) ||
      filteredTutors[i]?.student?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    }
  }
  return temp_tutors;
};

export const filterByAge = async (filteredTutors, age) => {
  console.log("filteredTutors", filteredTutors);
  var temp_tutors = [];
  console.log("s", temp_tutors);

  for (var i = 0; i < filteredTutors.length; i++) {
    const diffTime = Math.abs(
      new Date() - new Date(filteredTutors[i]?.student?.doB)
    );

    var ageDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
    console.log("year", ageDur);

    if (ageDur >= age[0] && ageDur <= age[1]) {
      temp_tutors.push(filteredTutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByPrice = async (filteredTutors, priceFilter) => {
  var temp_tutors = [];
  filteredTutors.map((item) => {
    item?.student?.interestToLearn.map((pricing) => {
      if (priceFilter.min && priceFilter.max) {
        if (
          pricing?.amount?.initialPrice >= parseFloat(priceFilter.min) &&
          pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)
        ) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(item._id)) {
            temp_tutors.push(item);
            return;
          }
        }
      } else if (priceFilter.min) {
        if (pricing?.amount?.initialPrice >= parseFloat(priceFilter.min)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(item._id)) {
            temp_tutors.push(item);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      } else if (priceFilter.max) {
        if (pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(item._id)) {
            temp_tutors.push(item);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      }
    });
  });
  return temp_tutors;
};

export const filterByLanguage = async (tutors, languageArray) => {
  let temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    for (var j = 0; j < languageArray.length; j++) {
      if (tutors[i].languages.includes(languageArray[j])) {
        temp_tutors.push(tutors[i]);
        break;
      }
    }
  }
  return temp_tutors;
};

export const filterByTuitionType = async (tutors, tuitionTypeArray) => {
  let temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    for (var j = 0; j < tuitionTypeArray.length; j++) {
      if (tutors[i].tuitionTypes.includes(tuitionTypeArray[j])) {
        temp_tutors.push(tutors[i]);
        break;
      }
    }
  }
  return temp_tutors;
};

export const filterBySubject = async (tutors, subjectsArray) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].subjects.length; j++) {
      for (var k = 0; k < subjectsArray.length; k++) {
        if (
          tutors[i]?.subjects[j]?.name?.replace(/\s+/g, "")?.toLowerCase() ===
          subjectsArray[k]?.label?.replace(/\s+/g, "")?.toLowerCase()
        ) {
          tutor_taken = true;
          temp_tutors.push(tutors[i]);
          break;
        }

        if (tutor_taken) break;
      }

      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterBySubjectCategory = async (tutors, categoryObject) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].subjects.length; j++) {
      if (tutors[i].subjects[j].tag.indexOf(categoryObject.value) !== -1) {
        tutor_taken = true;
        temp_tutors.push(tutors[i]);
        break;
      }

      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterTutorByCourse = async (tutors, course) => {
  console.log("courseF", tutors);
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    console.log("filter course", tutors[i]);
    const userString = JSON.stringify(tutors[i]);
    if (userString.indexOf(course) >= 0) {
      temp_tutors.push(tutors[i]);
    }
  }
  return temp_tutors;
};

export const filterTutorByDegree = async (tutors, degree) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    const userString = JSON.stringify(tutors[i]);
    if (userString.indexOf(degree) >= 0) {
      temp_tutors.push(tutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByInstitute = async (tutors, institute) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    const userString = JSON.stringify(tutors[i]);
    if (userString.toLowerCase().indexOf(institute.toLowerCase()) >= 0) {
      temp_tutors.push(tutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByEducation = async (filteredTutors, educationFilter) => {
  if (educationFilter.course) {
    filteredTutors = await filterTutorByCourse(
      filteredTutors,
      educationFilter.course
    );
  }
  if (educationFilter.degree) {
    filteredTutors = await filterTutorByDegree(
      filteredTutors,
      educationFilter.degree
    );
  }
  if (educationFilter.institute) {
    filteredTutors = await filterByInstitute(
      filteredTutors,
      educationFilter.institute
    );
  }
  // if (educationFilter.grade) {
  //   filteredTutors = await filterByGrade(filteredTutors, educationFilter.grade);
  // }
  if (educationFilter.passingYear) {
    filteredTutors = filteredTutors.filter((tutor) =>
      Object.values(tutor).indexOf(educationFilter.passingYear)
    );
  }

  return filteredTutors;
};

export const filterByWorkExperience = (tutors, wexpFilter) => {
  var temp_tutors = [];

  console.log("temp", temp_tutors);

  console.log("wF", tutors);
  console.log("wex", wexpFilter.expYear);

  for (var i = 0; i < tutors.length; i++) {
    console.log("filteredTutors[i]", tutors[i]);
    if (wexpFilter?.org) {
      if (
        tutors[i]?.workExperience?.org
          ?.toLowerCase()
          .includes(wexpFilter.org.toLowerCase()) > 0
      ) {
        if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
          temp_tutors.push(tutors[i]);
          break;
        } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
          temp_tutors.push(tutors[i]);
          break;
        } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
          temp_tutors.push(tutors[i]);
          break;
        } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
          temp_tutors.push(tutors[i]);
          break;
        }
      }
    } else {
      // var currentExp = filteredTutors[i].workExperiences;
      // var diffTime = 0;
      // if (currentExp?.running) {
      //   diffTime = Math.abs(new Date() - new Date(currentExp.startDate));
      // } else {
      //   diffTime = Math.abs(
      //     new Date(currentExp?.endDate) - new Date(currentExp.startDate)
      //   );
      // }
      // var expDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
      // expYearSum += expDur;
    }

    if (!wexpFilter.org) {
      if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
        temp_tutors.push(tutors[i]);
      } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
        temp_tutors.push(tutors[i]);
      } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
        temp_tutors.push(tutors[i]);
      } else if (wexpFilter.expYear == tutors[i]?.workExperience?.expYear) {
        temp_tutors.push(tutors[i]);
      }
    }
  }
  return temp_tutors;
};
