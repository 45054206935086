export const filterByTutorName = async (filteredTutors, name) => {
  var temp_tutors = [];

  var nameSplit = name.split(" ");

  const emaptyStringRemove = nameSplit.filter((str) => str !== "");

  for (var i = 0; i < filteredTutors.length; i++) {
    if (
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[0]?.toLowerCase()) ||
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.name.firstName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[1]?.toLowerCase()) ||
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    } else if (
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(emaptyStringRemove[-1]?.toLowerCase()) ||
      filteredTutors[i]?.name.lastName
        .toLowerCase()
        .includes(name?.trim().toLowerCase())
    ) {
      temp_tutors.push(filteredTutors[i]);
    }
  }
  return temp_tutors;
};

export const filterByAge = async (filteredTutors, age) => {
  var temp_tutors = [];
  console.log("s", temp_tutors);

  for (var i = 0; i < filteredTutors.length; i++) {
    const diffTime = Math.abs(new Date() - new Date(filteredTutors[i].doB));

    var ageDur = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

    if (ageDur >= age[0] && ageDur <= age[1]) {
      temp_tutors.push(filteredTutors[i]);
    }
  }

  return temp_tutors;
};

export const filterByLanguage = async (tutors, languageArray) => {
  let temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    if (languageArray.every((lan) => tutors[i].languages?.includes(lan))) {
      temp_tutors.push(tutors[i]);
    }
  }
  return temp_tutors;
};

export const filterByTuitionType = async (tutors, tutionType) => {
  return tutors.filter((tutor) =>
    tutionType.some((item) =>
      tutor.interestToLearn.filter((i) => i.tutionTypes?.indexOf(item) >= 0)
        .length
        ? true
        : false
    )
  );
};

export const filterBySubject = async (tutors, subjectsArray) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].interestToLearn.length; j++) {
      for (var k = 0; k < subjectsArray.length; k++) {
        for (var l = 0; l < tutors[i].interestToLearn[j].subjects.length; l++) {
          if (
            tutors[i]?.interestToLearn[j]?.subjects[l]?.name
              ?.replace(/\s+/g, "")
              ?.toLowerCase() ===
            subjectsArray[k]?.label?.replace(/\s+/g, "")?.toLowerCase()
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        }
        if (tutor_taken) break;
      }
      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterBySubjectCategory = async (tutors, categoryObject) => {
  var temp_tutors = [];
  for (var i = 0; i < tutors.length; i++) {
    var tutor_taken = false;
    for (var j = 0; j < tutors[i].interestToLearn.length; j++) {
      for (var l = 0; l < tutors[i].interestToLearn[j].subjects.length; l++) {
        if (
          tutors[i].interestToLearn[j].subjects[l].tag.indexOf(
            categoryObject.value
          ) !== -1
        ) {
          tutor_taken = true;
          temp_tutors.push(tutors[i]);
          break;
        }
      }
      if (tutor_taken) break;
    }
  }
  return temp_tutors;
};

export const filterByPrice = async (filteredTutors, priceFilter, currency1) => {
  var temp_tutors = [];
  filteredTutors.map((tutor) => {
    tutor?.interestToLearn.map((pricing) => {
      if (priceFilter.min && priceFilter.max) {
        if (
          pricing?.amount?.initialPrice >= parseFloat(priceFilter.min) &&
          pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)
        ) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
      } else if (priceFilter.min) {
        if (pricing?.amount?.initialPrice >= parseFloat(priceFilter.min)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      } else if (priceFilter.max) {
        if (pricing?.amount?.initialPrice <= parseFloat(priceFilter.max)) {
          var exitItem = temp_tutors.map((item) => item._id);
          if (!exitItem.includes(tutor._id)) {
            temp_tutors.push(tutor);
            return;
          }
        }
        // } else {
        //   temp_tutors.push(tutor);
        //   return;
        // }
      }
    });
  });
  return temp_tutors;
};
