//worked till now
import React, { Fragment } from "react";
import { Modal } from "reactstrap";
import InterestAddEditContent from "./InterestAddEdit";
import InterestViewContent from "./InterestView";

const InterestModal = (props) => {
  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop={"static"}
      >
        {props.view && (
          <InterestViewContent
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            data={props.data}
          />
        )}
        {!props.view && (
          <InterestAddEditContent
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            edit={props.edit}
            data={props.data}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default InterestModal;
