import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import NewStudentTuitionsContain from "../../../Component/NewMyTuitions/Student";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const NewStudentTuitions = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="MyTuitions" title="" />
      <NewStudentTuitionsContain />
    </Fragment>
  );
};
export default NewStudentTuitions;
