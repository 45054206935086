// import Blank from "./Blank";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { unseenConversations } from "../../../../redux/features/chat/conversations/conversationsSlice";
import { useGetMessagesQuery, useUpdateMessageStatusMutation } from "../../../../redux/features/chat/messages/messagesApi";
import { getUpdatedSeenMessage } from "../../../../redux/features/chat/messages/messagesSlice";
import Error from "../Error";
import ChatHead from "./ChatHead";
import Messages from "./Messages";
import SendChat from "./SendChat";

export default function ChatBody(props) {
  let { id } = useParams();
  if (id === undefined) {
    id = props?.id;
  }
  const { user } = useSelector((state) => state.auth) || {};
  const { unseensData } = useSelector((state) => state.conversations) || {};
  const globalSocket = useSelector((state) => state.conversations.globalSocket) || null;
  const seenMessage = useSelector((state) => state.messages.seen);
  let [updateMessageStatus] = useUpdateMessageStatusMutation();

  const { data: currentConversation, isLoading, isError, error, refetch } = useGetMessagesQuery(id, user);

  useEffect(() => {
    if (currentConversation) {
      const { messages, conversationId } = currentConversation;
      const { sender, messageId, seenBy } = messages[messages.length - 1];
      // console.log("seenBy", seenBy, user);
      const userSeen = seenBy.find((seen) => seen === user);
      // console.log(messageId, userSeen);
      // when the last message sender is not the loggedIn user
      if (sender !== user && !userSeen) {
        globalSocket.emit("seen-message", { sender, messageId, conversationId, receiverId: user });
        const data = {
          messageId: messageId,
          receiverId: user,
        };
        updateMessageStatus(data);
        //refetch();
      }
      // when the last message sender is the loggedIn user
      else {
        //refetch();
        if (seenMessage) {
          // console.log("sender view", messages[messages.length - 1]);
          dispatch(
            getUpdatedSeenMessage({
              type: "SEEN_ALL",
              seen: false,
            })
          );
        }
      }
    }
  }, [currentConversation, seenMessage]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && unseensData?.length > 0) {
      var updatedUnseens = unseensData?.filter((x) => x.isSeen === false && x.conversationId !== id && x.updatedBy !== user);
      dispatch(
        unseenConversations({
          type: "ALL",
          unseensData: updatedUnseens,
        })
      );
    }
  }, []);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (!isLoading && isError) {
    content = (
      <div>
        <Error message={error?.data} />
      </div>
    );
  } else if (!isLoading && !isError && currentConversation?.messages?.length === 0) {
    content = <div>No messages found!</div>;
  } else if (!isLoading && !isError && currentConversation?.messages?.length > 0) {
    content = (
      <>
        <ChatHead conversationId={id} />
        <Messages messages={currentConversation.messages} />
        <SendChat conversationId={id} />
      </>
    );
  }

  return (
    <Row className="chat-box">
      <Col className="chat-right-aside">
        <div className="chat">{content}</div>
      </Col>
    </Row>
  );
}
