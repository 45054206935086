import React, { useState, useEffect } from "react";
import { MapPin } from "react-feather";
import { ProfilePictureRootURL } from "../../api";
import man from "../../assets/images/avtar/man.png";
import "../../style/custom-css/ProfilePopup.css";
import Experience from "../Profile/Tutor/Experience";
import Qualification from "../Profile/Tutor/Qualification";
import { Btn, H4, Image, P } from "../../AbstractElements";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardText, CardTitle } from "reactstrap";
import { Star } from "react-feather";

function ProfilePopup(props) {
  //check user loggedin or not
  const [visible, setVisible] = useState(false);
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  useEffect(() => {
    if (_tuteair?._id && _tuteair?.accessToken) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  return (
    <div className="container">
      <div className="" id="" style={{ height: "15%" }}>
        <div className="align-items-center ">
          <img
            src={`${
              props.tutorInfo?.media?.profilePicture?.imageInfo?.secure_url
                ? props.tutorInfo?.media?.profilePicture?.imageInfo?.secure_url
                : props.tutorInfo?.media?.picture
                ? ` ${ProfilePictureRootURL}${props.tutorInfo?.media?.picture}`
                : man
            }`}
            alt=""
            style={{ width: 100, height: 100, borderRadius: "50%" }}
            className=""
          />
          {/* Alternate but not rounded properly */}
          {/* <Image
                attrImage={{
                  className: "img-100 img-fluid m-r-20 rounded-circle",
                  src: `${
                    tutor.media.picture
                      ? ` ${ProfilePictureRootURL}${tutor.media.picture}`
                      : man
                  }`,
                  alt: "",
                }}
              /> */}
          <div className="mt-2">
            <h4
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <span id="printname">{props.tutorInfo?.name?.firstName} </span>
              <span id="printlast" className="ms-1">
                {props.tutorInfo?.name?.lastName}
              </span>
              <span className="ms-2">
                {props?.tutorWithRatings?.map(
                  (item2) =>
                    item2.tutorId === props?.tutorInfo?.userId && (
                      <p
                        style={{
                          fontSize: "17px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {(item2?.rating).toFixed(2)}
                        <span
                          style={{
                            fontSize: "22px",
                            marginLeft: "4px",
                          }}
                        >
                          <Star size={18} className="text-warning" />
                        </span>
                      </p>
                    )
                )}
              </span>
            </h4>
            <P style={{ lineHeight: "0" }}>
              {props.tutorInfo?.about?.tutorBio}
            </P>
          </div>
        </div>

        <div className="">
          {props?.tutorInfo?.educations?.length !== 0 ? (
            <>
              {props?.tutorInfo?.educations?.map((item, index) => (
                <div key={index} className="py-1 mb-2">
                  <CardText className="text-primary mb-0">
                    <i className="fa fa-graduation-cap"></i>{" "}
                    <span className="ms-1">
                      {item.degree.label} in {item.course.label}
                    </span>
                  </CardText>
                  <div>
                    <p className="mb-0"> {item.institute}</p>
                    <p className="mb-0">
                      {" "}
                      Result {item.marks}% (Score in percentage)
                    </p>
                    <p className="mb-0"> Year of {item.passingYear}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        {/* <div>
            {props.tutorInfo?.workExperiences?.length !== 0 ? (
              <Experience data={props.tutorInfo.workExperiences} />
            ) : (
              ""
            )}
          </div>  */}
        {!visible && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              Please
              <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                <span className="text-primary"> Sign in </span>
              </Link>
              or
              <Link to={`${process.env.PUBLIC_URL}/register-v2`}>
                <span className="text-primary"> Register </span>
              </Link>
              To Get Onboard With Tutors
            </p>
          </div>
        )}

        {visible && (
          <div className="py-1">
            <button
              className="btn btn-sm btn-primary py-0 px-2"
              onClick={() => {
                if (props.visitStudentProfile === "studentProfile") {
                  window.open(
                    `/student-profile/${props?.tutorInfo.userId}`,
                    "_blank"
                  );
                } else {
                  window.open(`/profile/${props?.tutorInfo.userId}`, "_blank");
                }
              }}
            >
              View Profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilePopup;
