import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import FAQContent from "../../Component/FAQ";
import useLogoutSession from "../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const FAQ = () => {
  useLogoutSession();
  return (
    <Fragment>
      <FAQContent />
    </Fragment>
  );
};
export default FAQ;
