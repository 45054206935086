import React, { Fragment } from "react";
import { Col, Form, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../../../AbstractElements";
import InterestToLearn from "./InterestLearn";

const StudentPricing = () => {
  const navigate = useNavigate();

  const submitFun = () => {
    //window.location.reload();
    navigate(`${process.env.PUBLIC_URL}/student-profile/me`);
  };
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Form className="needs-validation" noValidate>
            {/* <TutorInterestAndPricing /> */}
            <InterestToLearn />
          </Form>
        </Col>
      </Row>
      {/* <Btn
        attrBtn={{
          color: "warning",
          className: "pull-right",
          onClick: submitFun,
        }}
      >
        {"Finish"}
      </Btn> */}
    </Fragment>
  );
};
export default StudentPricing;
