export default function ChatItem({
  name,
  avatar,
  lastMessage,
  updatedBy,
  lastTime,
  isSeen,
  active
}) {
  return (
    <div className={""} to="/">
      <img
        className="rounded-circle user-image conversation_img"
        src={avatar}
        alt={name}
      />
      <div className={`status-circle ${active ? 'online' : 'inactive'}`}></div>
      <div className="about ">
        <div className="name">{name}</div>
        <div className="status" style={{ postition: "fixed" }}>
          <i className=""></i>
          <div>
            <span className="">
              {lastMessage.substring(0, 10)}
              {lastMessage.length > 10 ? "..." : ""}
            </span>
            <span style={{ fontSize: "10px" }}> {lastTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
