import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import HelpAccountSettings from "./HelpAccountSettings";
import HelpConfirm from "./HelpConfirm";
import HelpContact from "./HelpContact";
import HelpCreateAccount from "./HelpCreateAccount";
import {
  createAccountSnaps,
  searchStudentsSnaps,
  searchTutorSnaps,
  contactStudentsSnaps,
  contactTutorSnaps,
  confirmTuitionSnaps,
  createTuitionSnaps,
  accountSettingsTutorSnaps,
  accountSettingsStudentSnaps,
} from "./HelpList";
import HelpSearch from "./HelpSearch";
import HelpSlider from "./HelpSlider";
import {
  CheckCircle,
  Send,
  UserCheck,
  UserPlus,
  Settings,
  Search,
} from "react-feather";
import HelpCreateTuition from "./HelpCreateTuition";

const HelpContentTab = ({ acType, oppositeUser }) => {
  const [LeftLineTab, setLeftLineTab] = useState("1");
  const tabList = [
    {
      id: 1,
      title: "Become a TuteAir user",
      // icon: `<UserCheck/>`
      icon: "fa fa-user",
    },
    {
      id: 2,
      title: `Search ${oppositeUser}`,
      // icon: `<Search/>`
      icon: "icofont icofont-user-search",
    },
    {
      id: 3,
      title: `Contact ${oppositeUser}`,
      // icon: `<Send/>`
      icon: "fa fa-paper-plane",
    },
    {
      id: 4,
      title: acType === "Tutor" ? "Create New Tuition" : "Confirm Tuition",
      // icon: `<CheckCircle/>`
      icon: "icofont icofont-check-circled",
    },
    {
      id: 5,
      title: "Update Account Settings",
      // icon: `<Settings/>`
      icon: "icofont icofont-ui-settings",
    },
  ];
  return (
    <Col sm="12">
      <Card>
        <CardHeader className="pb-0"></CardHeader>
        <CardBody>
          <Row>
            <Col sm="3" className="tabs-responsive-side">
              <Nav className="flex-column nav-pills border-tab nav-left">
                {tabList?.map((tab) => (
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={LeftLineTab === `${tab.id}` ? "active" : ""}
                      onClick={() => setLeftLineTab(`${tab.id}`)}
                    >
                      <i className={`${tab.icon}`}></i>
                      {`${tab.title}`}
                    </NavLink>
                    {/* {tab.icon}{`${tab.title}`}</NavLink> */}
                  </NavItem>
                ))}
                {/* <NavItem>
                  <NavLink href="#javascript" className={LeftLineTab === '1' ? 'active' : ''} onClick={() => setLeftLineTab('1')}><i className='fa fa-user'></i> Become a TuteAir user</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink href="#javascript" className={LeftLineTab === '2' ? 'active' : ''} onClick={() => setLeftLineTab('2')}><i className='icofont icofont-ui-settings'></i> Update Account Settings</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#javascript" className={LeftLineTab === '3' ? 'active' : ''} onClick={() => setLeftLineTab('3')}><i className='icofont icofont-user-search'></i> Search Students</NavLink> 
                </NavItem>
                <NavItem>
                  <NavLink href="#javascript" className={LeftLineTab === '4' ? 'active' : ''} onClick={() => setLeftLineTab('4')}><i className='fa fa-paper-plane'></i> Contact Students</NavLink>
                </NavItem> */}
              </Nav>
            </Col>
            <Col sm="9">
              <TabContent activeTab={LeftLineTab}>
                <TabPane className="fade show" tabId="1">
                <Row>
                <Col sm="12">
                  <HelpSlider
                    slides={1}
                    rtl={false}
                    lazyLoad={true}
                    helpList={createAccountSnaps}
                  ></HelpSlider>
                  <HelpCreateAccount></HelpCreateAccount>
                  </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <HelpSlider
                    slides={1}
                    rtl={false}
                    lazyLoad={true}
                    helpList={acType === "Tutor" ? searchStudentsSnaps : searchTutorSnaps}
                  ></HelpSlider>
                  <HelpSearch
                    oppositeUser={oppositeUser}
                    category={acType === "Tutor" ? "three" : "six"}
                    categories={acType === "Tutor" ? "and Locations" : ", Location, Price, Experience and Education"}
                  ></HelpSearch>
                </TabPane>
                <TabPane tabId="3">
                  <HelpSlider
                    slides={1}
                    rtl={false}
                    lazyLoad={true}
                    helpList={
                      acType === "Tutor"
                        ? contactStudentsSnaps
                        : contactTutorSnaps
                    }
                    type={acType}
                  ></HelpSlider>
                  <HelpContact oppositeUser={oppositeUser}></HelpContact>
                </TabPane>
                <TabPane tabId="4">
                  <HelpSlider
                    slides={1}
                    rtl={false}
                    lazyLoad={true}
                    helpList={
                      acType === "Tutor"
                        ? createTuitionSnaps
                        : confirmTuitionSnaps
                    }
                  ></HelpSlider>
                  {acType === "Tutor" ? (
                    <HelpCreateTuition></HelpCreateTuition>
                  ) : (
                    <HelpConfirm></HelpConfirm>
                  )}
                </TabPane>
                <TabPane tabId="5">
                  <HelpSlider
                    slides={1}
                    rtl={false}
                    lazyLoad={true}
                    helpList={
                      acType === "Tutor"
                        ? accountSettingsTutorSnaps
                        : accountSettingsStudentSnaps
                    }
                  ></HelpSlider>
                  <HelpAccountSettings></HelpAccountSettings>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col> 
  )
}

export default HelpContentTab