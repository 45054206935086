import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { MoreHorizontal } from "react-feather";
import { Card, CardBody, CardHeader, Col, Media, Table } from "reactstrap";
import { H5, LI, UL } from "../../../AbstractElements";
import MemberInformationContext from "../../../_helper/MemberInformation";

const ProfileView = () => {
  const {
    tutorViewCount,
    studentViewCount,
    getMemberInformation
  } = useContext(MemberInformationContext)

  const { memberType } = useSelector((state) => state.menu) || {};

  useEffect(() => {
    getMemberInformation();
  }, [])

  return (
    <Fragment>
      <Col xl="6" md="6" className="dash-31 dash-xl-50 box-col-12">
        <Card className="recent-activity">
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: "mb-0" }}>
                  {" "}
                  {memberType === "tutor"
                    ? "Profile view from students"
                    : "Profile view from tutors"}{" "}
                </H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="pt-0" style={{ height: "50vh" }}>
            <div className="table-responsive-sm">
              <div className="table-border-horizontal">
                <Table className="table-bordernone">
                  <tbody>
                    <tr>
                      <td className="text-center py-3">Today</td>
                      <td className="text-center py-3">
                        {memberType === "tutor"
                          ? studentViewCount
                          : tutorViewCount}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center py-3">This Week</td>
                      <td className="text-center py-3">
                        {memberType === "tutor"
                          ? studentViewCount
                          : tutorViewCount}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center py-3">This Month</td>
                      <td className="text-center py-3">
                        {memberType === "tutor"
                          ? studentViewCount
                          : tutorViewCount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProfileView;
