import React, { useContext, useState } from "react";
import { Edit3, Mail } from "react-feather";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Button, Card, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { Badges } from "../../../../AbstractElements";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import ClassReviewModal from "../../ClassReview/ClassReviewModal";
import ReviewViewModal from "../../ClassReview/ReviewViewModal";
import "./_classListView.css";

export default function ClassListView(props) {
  const { getAllScheduleEvents } = useContext(ClassSchedulerContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setViewModal(!viewModal);

  const handleReviewViewClick = async (item) => {
    setViewModal(true);
    setSelectedClass(item);
  };

  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const showClassList = props?.classList.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(props?.classList.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [popover, setPopover] = useState(false);
  const Toggle = () => setPopover(!popover);

  const fnIsCompletedClass = (item) => {
    const result =
      (((item?.classStatus === "completed" ||
        item?.classStatus === "complete") &&
        item?.completionStatus?.isCompleteByStudent &&
        item?.completionStatus?.isCompleteByTutor) ||
        (typeof item?.completionStatus === "object" &&
          props?.memberType === "student" &&
          item?.completionStatus?.isCompleteByStudent) ||
        (typeof item?.completionStatus === "object" &&
          props?.memberType === "tutor" &&
          item?.completionStatus?.isCompleteByTutor)) &&
      true;
    return result;
  };

  const checkReviewGivenByMe = (item, memberType) => {
    const feedbackByMember =
      memberType === "student"
        ? item.feedback?.bystudent
        : item.feedback?.bytutor;

    return Boolean(feedbackByMember);
  };

  const checkReviewFromOtherSide = (item, memberType) => {
    const feedbackByMember =
      memberType === "student"
        ? item.feedback?.bytutor
        : item.feedback?.bystudent;
    return Boolean(feedbackByMember);
  };

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <td className="h4" colSpan={5}>
                  {props?.dashboard === "dashboard" &&
                    showClassList?.length === 0
                    ? " "
                    : props?.dashboard === "dashboard" &&
                      showClassList?.length > 0
                      ? props.dashboardTitle
                      : props.title}
                </td>
                <td className="" style={{ textAlignLast: "right" }}>
                  {props?.memberType === "student" &&
                    (props.type === "upcoming" || props.type === "unpaid") &&
                    showClassList?.filter((x) => !x.isPaid)?.length > 0 && (
                      <>
                        <Link to={`${process.env.PUBLIC_URL}/class-list`}>
                          <span
                            href="#class-list"
                            className=""
                            id="add-new"
                            style={{ textDecoration: "underline" }}
                          >
                            <i data-feather="arrow-right"></i> Pay Unpaid Class
                          </span>
                        </Link>
                        <Popover
                          placement="left"
                          isOpen={popover}
                          target="add-new"
                          toggle={Toggle}
                          trigger="hover"
                        >
                          <PopoverHeader>Payment</PopoverHeader>
                          <PopoverBody>
                            Click here to pay for any unpaid classes.
                          </PopoverBody>
                        </Popover>
                      </>
                    )}
                </td>
              </tr>
              <tr style={{ background: "#dbdbdb2b" }}>
                <th style={{ width: "4%" }} scope="col"></th>
                <th>Title</th>
                <th className="text-nowrap">
                  {props?.memberType === "student"
                    ? "Tutor Name"
                    : "Student Name"}
                </th>
                <th style={{ width: "15%" }}>
                  Amount
                  <sub className="f-w-500" style={{ fontSize: "10px" }}>
                    /class
                  </sub>
                </th>
                <th style={{ width: "25%" }} className="text-center">
                  Schedule
                </th>
                {/* <th style={{ width: "25%"}} className="text-center">Time</th> */}
                <th className="text-center">Status</th>
                {props.type === "complete" && <th>Review</th>}
              </tr>
            </thead>

            <tbody>
              {showClassList?.length === 0 && (
                <>
                  <tr className="px-0">
                    {props?.dashboard === "dashboard" ? (
                      <td colSpan={6} className="text-center">
                        Currently, there are no classes with incomplete status
                        updates
                      </td>
                    ) : (
                      <td colSpan={6} className="text-center">
                        There is currently no {props?.title?.toLowerCase()}.
                      </td>
                    )}
                  </tr>
                </>
              )}
              {showClassList?.map((item, index) => (
                <tr className="px-0" key={index}>
                  <td scope="row">{index + 1}</td>
                  <td>{item?.title}</td>
                  <td>
                    {props?.memberType === "student"
                      ? item?.tutorName
                      : item?.studentName}
                  </td>
                  <td>
                    <span>
                      USD {item?.isFreeClass ? "0" : item?.confirmedTuitionFee}
                    </span>
                    {/* <span>{item?.currency ? " / " + item?.currency : ""}</span> */}
                  </td>
                  <td className="scheduleMobileView">
                    <span>
                      {new Date(item?.start).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}{" "}
                      {new Date(item?.start)
                        .toLocaleDateString("en-US", { weekday: "long" })
                        .slice(0, 3)}
                      :{/* {new Date(item?.start).toDateString()} : */}
                    </span>
                    <span className="text-nowrap ms-1">
                      {new Date(item?.start).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      -
                      {new Date(item?.end).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </td>

                  <td
                    className={`text-center ${item.isPaid ? "text-success" : "text-danger"
                      }`}
                  >
                    {props?.type === "complete" ? (
                      <>
                        {fnIsCompletedClass(item) ? (
                          <span className="text-primary">Completed</span>
                        ) : (
                          <Button
                            type="button"
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedClass(item);
                              setModal(true);
                              setIsEdit(false);
                            }}
                            disabled={fnIsCompletedClass(item)}
                          >
                            Complete
                          </Button>
                        )}
                      </>
                    ) : props?.type === "cancelled" ? (
                      <>
                        <span>Cancelled</span>
                      </>
                    ) : item?.isFreeClass ? (
                      <Badges attrBadge={{ color: "info", pill: true }}>
                        Free
                      </Badges>
                    ) : item.isPaid ? (
                      <Badges attrBadge={{ color: "success", pill: true }}>
                        Paid
                      </Badges>
                    ) : (
                      // "Paid"
                      <Badges attrBadge={{ color: "danger", pill: true }}>
                        Unpaid
                      </Badges>
                      // "Unpaid"
                    )}
                  </td>
                  {props.type === "complete" && (
                    <td>
                      <span
                        title={`${checkReviewFromOtherSide(item, props.memberType)
                            ? `See your ${props.memberType === "student"
                              ? "tutor"
                              : "student"
                            } feedback`
                            : "Feedback are not received!"
                          }`}
                      >
                        <Mail
                          size={20}
                          className={`mr-2 ${checkReviewFromOtherSide(item, props.memberType)
                              ? "text-primary"
                              : ""
                            } review-icon`}
                          onClick={() => handleReviewViewClick(item)}
                        />
                      </span>
                      <span className="px-2" title="Leave Feedback">
                        <Edit3
                          size={20}
                          className={`mr-2 ${checkReviewGivenByMe(item, props.memberType)
                              ? "text-primary"
                              : ""
                            } review-icon`}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedClass(item);
                            setModal(true);
                            setIsEdit(
                              checkReviewGivenByMe(item, props.memberType) ||
                              fnIsCompletedClass(item)
                            );
                          }}
                        />
                      </span>
                    </td>

                    /*
                      Tutor or student can’t see each other rating and reviews until they both have provided already or until the two weeks from the class end time.
                      After 2 weeks of class end time, if a tutor or student miss to provide reviews/rating, there will be no opportunity to provide any.
                      Review/rating will not go public until both party provide or until 2 weeks from class end time.
                    */
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      {modal && (
        <ClassReviewModal
          isOpen={modal}
          toggle={toggle}
          isEdit={isEdit}
          item={selectedClass}
          memberType={props?.memberType}
        />
      )}
      {viewModal &&
        checkReviewFromOtherSide(selectedClass, props.memberType) && (
          /* checkReviewGivenByMe(selectedClass, props.memberType) &&  */
          <ReviewViewModal
            isOpen={viewModal}
            toggle={toggle2}
            item={selectedClass}
            isReviewedByMe={checkReviewGivenByMe(
              selectedClass,
              props.memberType
            )}
          />
        )}
      {props?.dashboard === "dashboard" ? (
        ""
      ) : showClassList?.length !== 0 ? (
        <div className="mt-5 mb-5 pagination">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            //disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
//
