import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { CardImg, CardText, CardTitle, Col } from "reactstrap";
import { P } from "../../AbstractElements";
import { ProfilePictureRootURL } from "../../api";
import allowLocation from "../../assets/images/avtar/allowLocation.png";
import man from "../../assets/images/avtar/man.png";
import NoTutorImage from "../../assets/images/login/2.jpg";
import "../../style/custom-css/FindTutor.css";
import FilterContext from "../../_helper/Filter";
import PersonalAddressContext from "../../_helper/PersonalAddress";
import TutorsContext from "../../_helper/Tutors";
import FindTutorsGeocoder from "./FindTutorsGeocoder";
import ProfilePopup from "./ProfilePopup";
import RequestTuitionModal from "../RequestTuition/Student/RequestTuitionAddEdit";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "./Layers";
import MapRef from "react-map-gl";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapViewContain = ({ tutorWithRatings }) => {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const allFilteredTutors = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "student" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );

  const { user: userId } = useSelector((state) => state.auth) || {};

  const { latitude, longitude, setLatitude, setLongitude } = useContext(
    PersonalAddressContext
  );

  const [tutorsToShowGeoJSON, setTutorsToShowGeoJSON] = useState({});
  const [currentZoom, setCurrentZoom] = useState(0);

  const [filters, setFilters] = useState({});
  //const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [tutorList, setTutorList] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const mapRef = useRef(MapRef);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");

  useEffect(() => {
    const setInitialLocation = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    };
    setInitialLocation();
  }, []);
  const openRequestTuitionModal = () => {
    setTitle("Add Request for Tuition");
    setListViewData({
      gender: gender,
      profilePicture: profilePicExist,
      language: languages,
      location: location,
      tutionType: tutionType,
      subject: subject,
      subjectCategory: subject_category_filter,
      subjectSubCategory: subject_subcategory_filter,
    });
    toggle();
    setModal(true);
  };

  useEffect(() => {
    var geojson = {
      type: "FeatureCollection",
      features: [],
    };

    for (var i = 0; i < allFilteredTutors.length; i++) {
      geojson.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            allFilteredTutors[i].address.longitude,
            allFilteredTutors[i].address.latitude,
          ],
        },
        //"properties": allFilteredTutors[i]
      });
    }

    setTutorsToShowGeoJSON(geojson);
  }, [tutorsToShow]);

  const onClick = (event) => {
    const feature = event.features[0];
    const clusterId = feature?.properties?.cluster_id;
    const mapboxSource = mapRef.current.getSource("earthquakes");

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current.easeTo({
        center: feature?.geometry?.coordinates,
        zoom,
        duration: 500,
      });
    });
  };

  const handleZoom = (e) => {
    setCurrentZoom(e.viewState.zoom);
  };

  return (
    <Fragment>
      <Col xl="8" style={{ width: "100%" }}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <PulseLoader
              color="blue"
              loading={loading}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <span>
            <span>
              {latitude != 0 && longitude != 0 && (
                <Map
                  mapboxAccessToken={
                    "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ"
                  }
                  style={{
                    width: "100%",
                    height: "80vh",
                    borderRadius: "15px",
                    border: "2px solid red",
                  }}
                  initialViewState={{
                    longitude: longitude,
                    latitude: latitude,
                    zoom: 7,
                  }}
                  mapStyle="mapbox://styles/moniruzzaman-18/cl7zwo94p000014qsaoizctns"
                  onClick={onClick}
                  interactiveLayerIds={[clusterLayer.id]}
                  ref={mapRef}
                  onZoom={(e) => handleZoom(e)}
                >
                  {currentZoom > 11 &&
                    allFilteredTutors.map((item, key) =>
                      item.address?.latitude && item.address?.longitude ? (
                        <Marker
                          key={key}
                          longitude={item.address.longitude}
                          latitude={item.address.latitude}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedTutor(item);
                            }}
                          >
                            {" "}
                            <img
                              src={
                                item?.media?.profilePicture?.imageInfo
                                  ?.secure_url
                                  ? item?.media?.profilePicture?.imageInfo
                                      ?.secure_url
                                  : item.media.picture
                                  ? ` ${
                                      ProfilePictureRootURL + item.media.picture
                                    }`
                                  : man
                              }
                              height={32}
                              width={32}
                              alt={"tuteair-member"}
                              style={{
                                borderRadius: "50%",
                                border: "1.5px solid red",
                              }}
                            />
                          </span>
                        </Marker>
                      ) : null
                    )}

                  {selectedTutor ? (
                    <Popup
                      latitude={selectedTutor.address.latitude}
                      longitude={selectedTutor.address.longitude}
                      onClose={() => {
                        setSelectedTutor(null);
                      }}
                    >
                      <ProfilePopup
                        tutorInfo={selectedTutor}
                        tutorWithRatings={tutorWithRatings}
                      />
                    </Popup>
                  ) : null}
                  {currentZoom <= 11 && (
                    <Source
                      id="earthquakes"
                      type="geojson"
                      //data="https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
                      data={tutorsToShowGeoJSON}
                      cluster={true}
                      clusterMaxZoom={14}
                      clusterRadius={50}
                    >
                      <Layer {...clusterLayer} />
                      <Layer {...clusterCountLayer} />
                      <Layer {...unclusteredPointLayer} />
                    </Source>
                  )}

                  <NavigationControl position="bottom-right" />
                  <FindTutorsGeocoder />
                  <FullscreenControl />
                  <GeolocateControl />
                </Map>
              )}
              {latitude == 0 && longitude == 0 && (
                <>
                  <P>
                    Please allow your location to see tutor's location on map
                    view.
                  </P>
                  <P>
                    You can allow location by clicking on location icon in your
                    browser's address bar. <br></br>
                    <img src={allowLocation} alt=""></img>
                  </P>
                </>
              )}
            </span>
          </span>
        )}
      </Col>
    </Fragment>
  );
};

export default MapViewContain;
