export const tutorSchedule = [
  {
    dayName: "mon",
    isChecked: false,
    timeTable: [],
  },
  {
    dayName: "tue",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
  {
    dayName: "wed",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
  {
    dayName: "thu",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
  {
    dayName: "fri",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
  {
    dayName: "sat",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
  {
    dayName: "sun",
    isChecked: false,
    timeTable: [
      // {
      //   start: new Date("01-Nov-2022 10:00 AM"),
      //   end: new Date("01-Nov-2022 12:00 PM"),
      // },
      // {
      //   start: new Date("01-Nov-2022 04:00 PM"),
      //   end: new Date("01-Nov-2022 06:00 PM"),
      // },
    ],
  },
];

// tutor profile schedule
export const addWeekdayListArray = () => {
  return [
    {
      dayName: "mon",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "tue",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "wed",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "thu",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "fri",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "sat",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
    {
      dayName: "sun",
      isChecked: false,
      isError: false,
      timeTable: [],
    },
  ];
};

// filter schedule
export const filterAddWeekdayList = () => {
  return [
    {
      dayName: "mon",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "tue",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "wed",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "thu",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "fri",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "sat",
      isChecked: false,
      timeTable: {},
    },
    {
      dayName: "sun",
      isChecked: false,
      timeTable: {},
    },
  ];
};