import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  Source,
  Layer,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  CardTitle,
  CardBody,
  CardImg,
  CardText,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { FilteredTutorsApi, ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import allowLocation from "../../../assets/images/avtar/allowLocation.png";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import FindTutorsGeocoder from "../../FindTutors/FindTutorsGeocoder";
// import BasicFilter from "./ListView/BasicFilter";
// import SubjectsFilter from "./ListView/SubjectsFilter";
// import TutorLocationFilter from "./ListView/TutorLocationFilter";
import TuitionFeesFilter from "../../FindTutors/ListView/TuitionFeesFilter";
import ProfilePopup from "../../FindTutors/ProfilePopup";
import PulseLoader from "react-spinners/PulseLoader";
import { P } from "../../../AbstractElements";
import TutorsContext from "../../../_helper/Tutors";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import StudentLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";

import {
  filterByTutorName,
  filterByAge,
  filterByLanguage,
  filterByPrice,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
} from "../CommonFilter.jsx";

import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "../../FindTutors/Layers";
import MapRef from "react-map-gl";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function ShowStudentsOnMap() {
  const {
    name,
    age,
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    priceFilter,
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);
  const { user: userId } = useSelector((state) => state.auth) || {};

  const allFilteredStudents = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "tutor" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );

  const { latitude, longitude, setLatitude, setLongitude } = useContext(
    PersonalAddressContext
  );

  const [filters, setFilters] = useState({});
  //const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  //const [tutorList, setTutorList] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [tutorsToShowGeoJSON, setTutorsToShowGeoJSON] = useState({});
  const [currentZoom, setCurrentZoom] = useState(0);
  const mapRef = useRef(MapRef);

  useEffect(() => {
    const setInitialLocation = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    };
    setInitialLocation();
  }, []);

  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.name) {
      filteredTutors = await filterByTutorName(filteredTutors, filters.name);
    }

    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.age) {
      filteredTutors = await filterByAge(filteredTutors, filters.age);
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) =>
          tutor.media.picture.length > 0 ||
          tutor?.media?.profilePicture?.imageInfo?.secure_url?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }
    // filtering ends here

    setTutorsToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  const updateFilter = () => {
    const addedFilter = {};
    setIsReset(false);

    if (name != " ") {
      addedFilter.name = name;
    }

    if (age.length != 0) {
      addedFilter.age = age;
    }
    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(priceFilter).length) {
      addedFilter.priceFilter = priceFilter;
    }

    setFilters(addedFilter);
  };

  useEffect(() => {
    var geojson = {
      type: "FeatureCollection",
      features: [],
    };

    for (var i = 0; i < allFilteredStudents.length; i++) {
      geojson.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            allFilteredStudents[i].address.longitude,
            allFilteredStudents[i].address.latitude,
          ],
        },
        //"properties": allFilteredStudents[i]
      });
    }

    setTutorsToShowGeoJSON(geojson);
  }, [tutorsToShow]);

  const onClick = (event) => {
    const feature = event.features[0];
    const clusterId = feature?.properties?.cluster_id;
    const mapboxSource = mapRef.current.getSource("earthquakes");

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current.easeTo({
        center: feature?.geometry?.coordinates,
        zoom,
        duration: 500,
      });
    });
  };
  const handleZoom = (e) => {
    setCurrentZoom(e.viewState.zoom);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTutors();
  };

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8}>
            {loading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {allFilteredStudents?.length} matched student's based on your
                search criteria
              </CardTitle>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>
                {latitude != 0 && longitude != 0 && (
                  <Map
                    mapboxAccessToken={
                      "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ"
                    }
                    style={{
                      width: "100%",
                      height: "80vh",
                      borderRadius: "15px",
                      border: "2px solid red",
                    }}
                    initialViewState={{
                      longitude: longitude,
                      latitude: latitude,
                      zoom: 7,
                    }}
                    mapStyle="mapbox://styles/moniruzzaman-18/cl7zwo94p000014qsaoizctns"
                    onClick={onClick}
                    interactiveLayerIds={[clusterLayer.id]}
                    ref={mapRef}
                    onZoom={(e) => handleZoom(e)}
                  >
                    {currentZoom > 11 &&
                      allFilteredStudents.map(
                        (item, key) => (
                          // item.address?.latitude && item.address?.longitude ? (
                          <Marker
                            key={key}
                            longitude={item.address.longitude}
                            latitude={item.address.latitude}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedTutor(item);
                              }}
                            >
                              {" "}
                              <img
                                src={
                                  item?.media?.profilePicture?.imageInfo
                                    ?.secure_url
                                    ? item?.media?.profilePicture?.imageInfo
                                        ?.secure_url
                                    : item.media.picture
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.media.picture
                                      }`
                                    : man
                                }
                                height={32}
                                width={32}
                                alt={"tuteair-member"}
                                style={{
                                  borderRadius: "50%",
                                  border: "1.5px solid red",
                                }}
                              />
                            </span>
                          </Marker>
                        )
                        // ) : null
                      )}

                    {selectedTutor ? (
                      <Popup
                        latitude={selectedTutor.address.latitude}
                        longitude={selectedTutor.address.longitude}
                        onClose={() => {
                          setSelectedTutor(null);
                        }}
                      >
                        <ProfilePopup tutorInfo={selectedTutor} />
                      </Popup>
                    ) : null}
                    {currentZoom <= 11 && (
                      <Source
                        id="earthquakes"
                        type="geojson"
                        //data="https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
                        data={tutorsToShowGeoJSON}
                        cluster={true}
                        clusterMaxZoom={14}
                        clusterRadius={50}
                      >
                        <Layer {...clusterLayer} />
                        <Layer {...clusterCountLayer} />
                        <Layer {...unclusteredPointLayer} />
                      </Source>
                    )}

                    <NavigationControl position="bottom-right" />
                    <FindTutorsGeocoder />
                    <FullscreenControl />
                    <GeolocateControl />
                  </Map>
                )}
                {latitude == 0 && longitude == 0 && (
                  <>
                    <P>
                      Please allow your location to see tutor's location on map
                      view.
                    </P>
                    <P>
                      You can allow location by clicking on location icon in
                      your browser's address bar. <br></br>
                      <img src={allowLocation}></img>
                    </P>
                  </>
                )}
              </span>
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">Basic</AccordionHeader>
                <AccordionBody accordionId="1">
                  <BasicFilter setFilters={setFilters} isReset={isReset} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">Subjects</AccordionHeader>
                <AccordionBody accordionId="2">
                  <SubjectsFilter setFilters={setFilters} isReset={isReset} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">Tutor's Location</AccordionHeader>
                <AccordionBody accordionId="3">
                  <StudentLocationFilter
                    setFilters={setFilters}
                    isReset={isReset}
                  />
                </AccordionBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionHeader targetId="4">Student's Price</AccordionHeader>
                <AccordionBody accordionId="4">
                  <TuitionFeesFilter
                    setFilters={setFilters}
                    isReset={isReset}
                  />
                </AccordionBody>
              </AccordionItem>

              {/* <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Schedule</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <ScheduleFilter />
                  </AccordionBody>
                </AccordionItem> */}
            </UncontrolledAccordion>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              {parseFloat(priceFilter.min) > parseFloat(priceFilter.max) ? (
                <Button
                  disabled
                  color="primary"
                  className="mb-3 "
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}

export default ShowStudentsOnMap;
