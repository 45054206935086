import React, { useState } from "react";
import ShowMoreText from "react-show-more-text";
import { Card, CardText, CardTitle } from "reactstrap";

export default function About(props) {
  const [isShow, setIsShowless] = useState(true);
  function toggleIsShow() {
    setIsShowless(!isShow);
  }

  return (
    <div>
      <Card body className="b-t-light border-5">
        <CardTitle className="text-primary m-b-10" tag="h4"> About {props.fullname}</CardTitle>
        <ShowMoreText
          lines={3}
          more="Show more"
          less="Show less"
          className="content-css"
          anchorClass="my-anchor-css-class"
          onClick={toggleIsShow}
          expanded={false}
        >
          <CardText>{props.data?.about?.tutorBio}</CardText>
        </ShowMoreText>
      </Card>
    </div>
  );
}
