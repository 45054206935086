import React from "react";
import { useState, useEffect } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import Select, { createFilter } from "react-select";
import { useContext } from "react";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";

export default function WorkExperienceFilter({ isReset }) {
  const { workExpYearList, workExpYear, setWorkExpYear } =
    useContext(TutorsContext);
  const [org, setOrg] = useState("");

  const { workExperiencesFilter, setWorkExperiencesFilter, moonlight } =
    useContext(FilterContext);

  const fnsetWorkExpYear = (item) => {
    if (!item || item.length == 0) {
      setWorkExpYear(0);
      setWorkExperiencesFilter({ org: org, expYear: "<1" });
      return;
    }
    setWorkExpYear(item);
    setWorkExperiencesFilter((prev) => ({ ...prev, expYear: item.value }));
  };

  const fnsetOrg = (value) => {
    setOrg(value);
    if (value == "") {
      setWorkExperiencesFilter({ expYear: workExpYear.value });
    } else {
      setWorkExperiencesFilter((prev) => ({ ...prev, org: value }));
    }
  };

  const initializeWorkExpFilter = () => {
    setWorkExperiencesFilter({});
    setOrg("");
    setWorkExpYear(0);
  };

  useEffect(() => {
    if (isReset) {
      initializeWorkExpFilter();
    }
  }, [isReset]);

  return (
    <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
      <h4 className="f_title">
        <span>Work Experience</span>
      </h4>
      <Row>
        <Col>
          <Label className="col-form-label" htmlFor="">
            Duration in years
          </Label>
          <Select
            options={workExpYearList}
            placeholder="Select Years"
            className="js-example-basic-single col-sm-12 darkMode"
            isClearable={true}
            onChange={(item) => fnsetWorkExpYear(item)}
            value={workExpYear}
          />
        </Col>

        <Col md={7}>
          <Label className="col-form-label" htmlFor="">
            Prefered Organisation
          </Label>

          <Input
            type="text"
            name="text"
            className="form-control px-2 py-7"
            placeholder="Organisation Name"
            value={org}
            onChange={(e) => fnsetOrg(e.target.value)}
          ></Input>
        </Col>
      </Row>
    </div>
  );
}
