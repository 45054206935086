import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import { useContext } from "react";
import FilterContext from "../../../_helper/Filter";
import { filterAddWeekdayList } from "../../Chat/NewChat/inbox/tutorSchedule";
import { CheckSquare, Info } from "react-feather";


export default function ScheduleFilter({ isReset }) {
  const { scheduleFilter, setScheduleFilter, moonlight, hasTime, setHasTime } =
    useContext(FilterContext);
  const dayList = filterAddWeekdayList();
  const [weekdayList, setWeekdayList] = useState([]);
  const [fromTime, setFromTime] = useState(() => {
    const now = new Date();
    now.setHours(8, 0, 0, 0);
    return now;
  });
  const [toTime, setToTime] = useState(() => {
    const now = new Date();
    now.setHours(9, 0, 0, 0);
    return now;
  });

  useEffect(() => {
    let weekDaysTemp = [...dayList];
    setWeekdayList(weekDaysTemp);
    setHasTime(false);
  }, []);

  useEffect(() => {
    if (isReset) {
      initializeScheduleFilter();
    }
  }, [isReset]);

  // make schedules empty
  const initializeScheduleFilter = () => {
    setScheduleFilter([]);
    const updatedWeekdayList = weekdayList.map((weekday) => ({
      ...weekday,
      isChecked: false,
      timeTable: {}
    }));
    setWeekdayList(updatedWeekdayList);
  };

  // checks or unchecks day
  const handleWeeklyDayChange = (e) => {
    const { value, checked } = e.target;
    let prevList = [...weekdayList];
    let newFilterList = [...scheduleFilter];
    prevList.map((day) => {
      if (day.dayName === value) {
        day.isChecked = checked;
        checked && newFilterList.push(day);
        if (!checked) {
          day.timeTable.length = 0;
        }
      }
    });
    setWeekdayList(prevList);
    handleSelectedDayList(prevList);
  };

  // adds selected days to the schedule context
  const handleSelectedDayList = (prevList) => {
    let selectedList = [];
    if (prevList?.length > 0) {
      prevList.map((day) => {
        day.isChecked && selectedList.push(day);
      });
      selectedList.length === 0 && setHasTime(false);
      setScheduleFilter(selectedList);
    }
  };

  // sets from & to time and combines with days
  const handleSelectedTimeSlot = (time, type) => {
    if (type === "from") {
      const tempEndTime = new Date(time.getTime() + 1 * 60 * 60 * 1000);
      setFromTime(new Date(time));
      setToTime(tempEndTime);
      handleIncludeTime(time, tempEndTime);
    } else {
      setToTime(new Date(time));
      handleIncludeTime(fromTime, time);
    }
  };

  // combines from & to with days
  const handleIncludeTime = (fromTime, toTime) => {
    const tempSchedule = [...scheduleFilter];
    tempSchedule.map((schedule) => {
      schedule.timeTable.from = fromTime;
      schedule.timeTable.to = toTime;
    });
    setScheduleFilter(tempSchedule);
  };

  // when select time enables, default from and to are validated
  const handleHasTime = () => {
    if(scheduleFilter.length > 0) {
      setHasTime(true);
      handleSelectedTimeSlot(fromTime, "from");
      handleSelectedTimeSlot(toTime, "to");
    }
  }

  // capitalize word
  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className={moonlight ? "filter_card_t_dark" : "filter_card_t"}>
      <h4 className="f_title m-b-20">
        <span>Schedule</span>
      </h4>
      <Row className="col-12 d-flex align-items-center">
        {
          scheduleFilter.length > 0 && (
            <>
            <Col xs="1">
              {
                !hasTime ? (
                  <Button
                    className="p-0 bg-none txt-dark border-0 f-16"
                    color="transparent"
                    onClick={handleHasTime}
                  >
                    <i className="fa fa-square-o" ></i>
                  </Button>
                ) : (
                  <Button
                    className="p-0 bg-none txt-primary border-0 f-16"
                    color="transparent"
                    onClick={() => setHasTime(false)}
                  >
                    <CheckSquare size={16} />
                  </Button>
                )
              }
            </Col>
            <Col>
              <span className="f-12">
                {
                  hasTime ? "Unselect " : "Select "
                }
                Time
              </span>
            </Col>
            </>
          )
        }
      </Row>
      <Row className="col-12 d-flex align-items-center justify-content-center">
        <Label className="text-center" xs={2} sm={2}>
          <span>From</span>
        </Label>
        <Col xs="4">
          <DatePicker
            className="form-control datetimepicker-input digits"
            selected={new Date(fromTime)}
            onChange={(fTime) => {
              handleSelectedTimeSlot(fTime, "from");
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="From"
            timeFormat="h:mm aa"
            dateFormat="h:mm aa"
            disabled={scheduleFilter.length === 0 || !hasTime}
          />
        </Col>
        <Label className="text-center" xs="1">
          <span>To</span>
        </Label>
        <Col xs="4">
          <DatePicker
            className="form-control datetimepicker-input digits"
            selected={new Date(toTime)}
            onChange={(tTime) => {
              handleSelectedTimeSlot(tTime, "to");
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="To"
            timeFormat="h:mm aa"
            dateFormat="h:mm aa"
            minTime={new Date(fromTime)}
            maxTime={new Date("2022-11-01T17:59:00.000Z")}
            disabled={scheduleFilter.length === 0 || !hasTime}
          />
        </Col>
        <Col xs="1"></Col>
        <Col
          sm="12"
          xl="12"
          className="bg-light text-dark d-flex justify-content-between p-2 rounded m-t-10"
        >
          {weekdayList?.map((day, index) => (
            <div key={index} className="d-flex flex-column align-items-center check-box-primary">
              <Input
                type="checkbox"
                onChange={(e) => handleWeeklyDayChange(e)}
                name={day.dayName}
                value={day.dayName}
                checked={day.isChecked}
              />
              <Label className={`${day.isChecked ? "txt-primary" : ""} m-t-5 f-w-500`} check>
                {capitalizeFirstLetter(day?.dayName)}
              </Label>
            </div>
          ))}
        </Col>
        <Col sm="12" xl="12">
          <div className="row mb-2"></div>
        </Col>
      </Row>
      <Row className="col-12 d-flex align-items-center justify-content-end m-t-10 txt-primary">
      {
        scheduleFilter.length === 0 && (
          <>
          <Col xs="1">
            <Info className="d-flex" size={14}></Info>
          </Col>
          <Col className="p-l-0">
            <span className="f-12">Day selection enables time selection</span>
          </Col>
          </>
        )
      }
      </Row>
    </div>
  );
}
