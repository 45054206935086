import React, { Fragment, useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5, LI, P, UL } from "../../AbstractElements";
import FilterContext from "../../_helper/Filter";

const TermsConditionContains = () => {
  const { setMoonlight } = useContext(FilterContext);

  //--- setting page theme to light mode ---
  useEffect(() => {
    // console.log(moonlight);
    // console.log(localStorage.getItem('layout_version'));
    setMoonlight(false);
    document.body.className = "light";
    localStorage.setItem("layout_version", "light");
  }, []);
  return (
    <Fragment>
      <Container fluid={false}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h2 style={{ textAlign: "center", padding: "8px 0px" }}>
                  Terms and Conditions
                </h2>
              </CardHeader>
              <CardBody>
                {/* <h3>TuteAir’s Terms & Conditions </h3> */}

                <P>
                  Before using the information and services offered by Tuteair,
                  please read the following Terms and Conditions and confirm
                  that you accept them.
                </P>

                <P>
                  Your acceptance of and adherence to these Terms is a
                  requirement for access to and use of the Service. The "Terms
                  of Use" extend to all visitors, users, and other individuals
                  who use or use the Service as well as any and all applicable
                  laws, rules, or regulations.
                </P>

                <P>
                  You consent to be legally bound by these Terms by accessing or
                  using the Service. If you disagree with any portion of the
                  terms, you are not permitted to use the Service, and the
                  Company may use any information it learns about you in
                  accordance with the Privacy Policy.
                </P>

                <h3>Service Type </h3>
                <p>
                  The totally individualized learning platform offered by
                  TuteAir enables tutors and parents to influence their
                  students' academic performance and develop students who are
                  more prepared to compete both locally and internationally.
                </p>
                <p>
                  To accomplish this, we offer the resources, information, and
                  people necessary to aid each student's learning. Teachers and
                  schools can identify each student's learning gaps and close
                  them more effectively, in our opinion if they have access to
                  more real-time data and feedback on the learning process. We
                  make an effort to be teacher-led, parent-first, and supportive
                  of the educational process.
                </p>
                <h3>Amends The Policy </h3>
                <p>
                  These Terms and Conditions may be modified from time to time
                  by Tuteair, and any such revisions shall be effective and
                  binding upon you immediately upon posting to the website.
                </p>
                <h3>Conditions of Use </h3>
                <p>
                  You consent to refrain from doing any of the following when
                  using the TuteAir website:
                </p>
                <UL>
                  <LI>
                    Copy, reformat, reuse, transmit, communicate, adapt,
                    distribute, sell, modify, store in a retrieval system,
                    publish, or use in any other way any of the content on this
                    website (including without limitation any underlying source
                    code or other data).
                  </LI>
                  <LI>
                    Submit any false or misleading information; submit anything
                    that is threatening, defamatory, offensive, vulgar, or
                    indecent.
                  </LI>
                  <LI>
                    Use this website in a way that could harass, annoy, or
                    disrupt any third party, including a third party who might
                    receive messages as a result of your use of this website.
                  </LI>
                  <LI>
                    Making use of any TuteAir content for profit-making
                    endeavors, launching a business, promoting a product, or
                    making an unrelated offer.
                  </LI>
                  <LI>
                    Get in touch with instructors or students who advertise on
                    this website to get them to re-post their services on a
                    rival website.
                  </LI>
                  <LI>
                    Data mine, scrape or conduct automated searches on this
                    website or any information included on it.
                  </LI>
                  <LI>
                    Interfere with, or attempt to interfere with, the proper
                    functioning of this website, including, for example, by
                    placing excessive traffic demands on it.
                  </LI>
                  <LI>
                    Submit Any Material of Any Kind That Contains a virus or
                    Other Destructive Component.
                  </LI>
                  <LI>
                    Try To Gain Unauthorized Access to Any Part of This Website.
                  </LI>
                </UL>
                <br />
                <p>
                  TuteAir retains the right to abide by any valid instruction
                  from law enforcement officials in any jurisdiction, including
                  requests for the identity of the posters of any materials that
                  violate the law, as well as for the disclosure of other
                  information.
                </p>
                <h3>Membership of TuteAir </h3>
                <p>
                  You need to enter your email address and password in order to
                  register with Tuteair. You are solely responsible for
                  preserving the confidentiality of your email and password. You
                  must tell TuteAir right away if your email or password is used
                  without your permission. Any approved or unauthorized acts
                  made with your email address and password are your
                  responsibility.
                </p>
                <p>
                  TuteAir retains the right to reject or remove content that we
                  deem to be inappropriate or in violation of the aforementioned
                  rules. Furthermore, we hold the right to refuse to register a
                  tutor or student and to impose temporary or permanent usage
                  restrictions on how someone uses the Site.
                </p>
                <h3>Member Responsibilities </h3>
                <p>
                  Members are prohibited from using the TuteAir website for any
                  illegitimate, illegal, or forbidden activities. These actions
                  include disseminating unsolicited email or spam, distributing
                  viruses and worms, or publishing anything that is false,
                  slanderous, indecent, obscene, or misleading.
                </p>
                <UL>
                  <LI>
                    Members are prohibited from using a false name or a name
                    they are not authorized to use, impersonating another person
                    or organization
                  </LI>
                  <LI>
                    Members are prohibited from posting information to the
                    TuteAir website that contains intellectual property or
                    copyright that is or could be the property of another
                    individual or organization.
                  </LI>
                  <LI>
                    Members consent to utilizing the TuteAir website to manage
                    their subscription charges. A member's subscription may be
                    canceled at any time, but any previously paid costs are not
                    refundable.
                  </LI>
                  <LI>
                    The authority to approve or reject subscription requests is
                    reserved by Tuteair. Members are not permitted to assign or
                    transfer their subscription to another around, nor any other
                    Plan.
                  </LI>
                  <LI>
                    On the date of the subscription renewal, the member gives
                    TuteAir permission to charge his credit or debit card for
                    subscription fees.
                  </LI>
                </UL>
                <br />
                <h3>Intangible Assets </h3>
                <p>
                  must obtain Tuteair's permission before using any of the
                  website's code, branding, or intellectual property. TuteAir is
                  the sole owner of all copyright, trademarks, and other
                  intellectual property rights in and to, and in connection
                  with, the TuteAir Website (including any material provided by
                  Members). Without the express permission of TuteAir, it is
                  forbidden to copy, distribute, publicly display, or create any
                  derivative work from the TuteAir Website or any of the content
                  that can be found there. View our Copyright Notice, which is a
                  component of these Terms and Conditions, for more information.
                </p>
                <p>
                  You agree to the following by posting any material contained
                  on the TuteAir website:
                </p>
                <UL>
                  <LI>
                    Giving TuteAir a non-exclusive, royalty-free, perpetual
                    license to copy, modify, distribute, publicly perform, and
                    create derivative works from the relevant material, as well
                    as giving up your right to claim authorship of the relevant
                    material, constitutes your representation that you have all
                    necessary rights to do so. duties or limitations of any
                    type, such as those relating to intellectual property or
                    licensing.
                  </LI>
                  <LI>
                    You acknowledge and agree that the Company does not give you
                    any property rights in any of its products or services in
                    exchange for the Company incorporating your feedback into
                    such products or services.
                  </LI>
                </UL>
                <br />

                <h3>A User's Account </h3>
                <p>
                  You are required to give us true, comprehensive, and
                  up-to-date information whenever you set up an account with us.
                  Your account on our Service may be immediately terminated if
                  you violate the Terms by failing to do so. Whether your
                  password is with our Provider or a third-party service, you
                  are responsible for keeping your password secure and for any
                  activities or acts carried out using it.
                </p>
                <UL>
                  <LI>
                    You consent to keep your password a secret from others. Upon
                    learning of any security breach or illegal use of your
                    account, you are required to tell us right away. You are not
                    permitted to use as your username a name that belongs to
                    someone else, a name that is not your own or that is not
                    legally yours to use, a name or trademark that is protected
                    by the rights of someone else without your consent, or a
                    name that is otherwise vulgar, insulting, or obscene.
                  </LI>
                  <LI>Website links to other sites </LI>
                  <LI>
                    Links to third-party websites or services that are not
                    within Tuteair's control or ownership may appear on our
                    service
                  </LI>
                  <LI>
                    TuteAir disclaims all liability and ownership for any
                    third-party websites or services, including their content,
                    privacy policies, and practices. You further understand and
                    accept that TuteAir is not responsible or liable, directly
                    or indirectly, for any loss or damage that may result from
                    the use of or reliance on any such material, products, or
                    services made available on or through any such websites or
                    services.
                  </LI>
                  <LI>
                    The terms and privacy policies of any third-party websites
                    or services that you access should be carefully examined.
                  </LI>
                </UL>
                <br />

                <h3>Financial Terms</h3>
                <UL>
                  <LI>
                    You must pay advertising fees in the amounts and ways
                    specified on the website. Fees could change at any time
                    without prior notification. At our discretion, fees may be
                    refunded, however, this is not typically done when an
                    advertiser just changes their mind.
                  </LI>
                  <LI>
                    Advertising fees cannot be assigned, sold, or transferred.
                  </LI>
                </UL>
                <br />

                <h3>Accountability Limitation</h3>
                <p>
                  You understand and accept that this website and its contents
                  are given "as is" without any explicit or implied warranties
                  of any kind, save as required by law, including, but not
                  limited to, the International Consumer Law and the Consumer
                  Guarantees. Your listings on the website and any repercussions
                  that may arise from them are strictly your responsibility.
                </p>
                <h3>Termination Terms</h3>
                <p>
                  For any reason, including without limitation if you breach the
                  Terms, we reserve the right to immediately suspend or
                  terminate your account without warning or liability. Your
                  ability to use the Service will end immediately upon
                  termination. You can stop using the Service altogether if you
                  want to close your account.
                </p>

                <h3>Refusal of Warranty</h3>
                <p>
                  TuteAir continuously maintains and reviews the quality of the
                  material on this page. Although we disclaim any responsibility
                  resulting from the use of or reliance on this Site, we do not
                  guarantee that the information made accessible through this
                  service is accurate, current, or comprehensive.
                </p>
                <p>
                  TuteAir is purely an advertising platform. We do not provide
                  any promises or guarantees as to the authenticity or sincerity
                  of any information submitted to us by the tutors and students;
                  we merely operate as a point of communication between them.
                  Prior to beginning any tutoring session, it is the obligation
                  of the tutor and the student to have an understanding of the
                  conditions and viability of their transaction. However, it is
                  the student's responsibility to verify the instructor's
                  suitability and appropriateness directly with the tutor before
                  hiring the tutor. TuteAir disclaims all warranties and
                  representations with regard to any specific teacher.
                </p>
                <p>
                  The real exchange between tutors and students does not involve
                  Tuteair. We, therefore, have no control over the standard,
                  security, or legality of the services being advertised, the
                  veracity or correctness of the listings, or the capability of
                  either party to complete a transaction. The claimed identity
                  of each user cannot and is not confirmed by Tuteair. The data
                  that other users submit and make available on our website is
                  not within our control.
                </p>
                <h3>Disputation </h3>
                <p>
                  The actual exchange between teachers and students does not
                  take place through Tuteair. As a result, if you have a
                  disagreement with one or more users of this service, you agree
                  to release TuteAir from any and all claims, demands, and
                  damages (actual and consequential), known and unknown,
                  suspected and unsuspected, revealed and undisclosed, resulting
                  from or in any way related to such disagreements.
                </p>

                <h3>Declaration of Copyright</h3>
                <p>
                  Tuteair.com has the sole ownership of this website and all of
                  its contents, which are protected by copyright and other laws.
                </p>
                <p>
                  All users of this website are subject to the terms and
                  conditions of use, which may be updated occasionally without
                  previous notice. Without Tuteair's prior written consent, you
                  are not allowed to change, duplicate, print, archive,
                  distribute, transmit, display, perform, or reproduce any
                  information, material, software, products, or services from
                  this website. You may also not make derivative works from,
                  transfer, or sell such information, material, software,
                  products, or services. Subject to the aforementioned, you are
                  permitted to read and print this website's material for use
                  that is solely private, individual, and non-commercial. You
                  agree to accept, abide by, and be bound by these terms and
                  conditions by using this website.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TermsConditionContains;
