import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({pageCount, changePage, pageNumber}) => {
  return (
    <div className="mt-5 mb-5 pagination">
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        activeClassName={"paginationActive"}
        forcePage={pageNumber}
      />
    </div>
  )
}

export default Pagination