import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  return _tuteair?._id &&
    _tuteair?.emailVerified &&
    (_tuteair?.currentStep >= 1 || _tuteair?.currentStepStudent >= 1) ? (
    <Outlet />
  ) : (
    <Navigate exact to={`${process.env.PUBLIC_URL}/home`} />
  );
};

export default PrivateRoute;
