import axios from "axios";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { ForgotPasswordURL } from "../../../api";
import { Resend, Send, SignIn } from "../../../Constant";

const ForgetPwd = () => {
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResent, setLoadingResent] = useState(false);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user_data = {
      email: email,
    };
    var response = await axios.post(ForgotPasswordURL, user_data);
    if (response.data) {
      setEmailStatus(true);
      setLoading(false);
    }
  };

  const handleResendEmail = async (e) => {
    e.preventDefault();
    setLoadingResent(true);
    const user_data = {
      email: email,
    };
    console.log(user_data);
    var response = await axios.post(ForgotPasswordURL, user_data);
    if (response.data) {
      setLoadingResent(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Fragment>
      <section>
        <Container className="p-0" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-main">
                <div className="login-card1">
                  <Form className="theme-form login-form">
                    <H4 attrH4={{ className: "mb-3" }}>Reset Your Password</H4>
                    {!emailStatus ? (
                      <>
                        <FormGroup className="form-group">
                          <Label>Enter Your Email Address</Label>
                          <Row>
                            <Col xs="12" sm="12">
                              <Input
                                onChange={handleEmailChange}
                                placeholder="user@gmail.com"
                                className="form-control"
                                type="email"
                                value={email}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Btn
                            attrBtn={{
                              className: "btn-block",
                              color: "primary",
                              type: "submit",
                              onClick: handleEmailSubmit,
                            }}
                          >
                            {loading ? "Sending..." : Send}
                          </Btn>
                        </FormGroup>
                      </>
                    ) : (
                      <FormGroup>
                        {" "}
                        <span className="reset-password-link">
                          An reset email has been sent. Check your email
                        </span>
                      </FormGroup>
                    )}

                    <FormGroup>
                      {" "}
                      <span className="reset-password-link">
                        If don't receive Email?  
                        <a
                          className="btn-link text-danger"
                          href="#resend"
                          onClick={handleResendEmail}
                        >
                          {loadingResent ? "Sending..." : Resend}
                        </a>
                      </span>
                    </FormGroup>

                    {/* <P>
                      Already have a password?
                      <Link to={`${process.env.REACT_APP_NEXT_ROUTE}/login`}>
                         <a className="ms-2" href="#javascritp"> 
                        {SignIn}
                         </a> 
                      </Link>
                    </P> */}
                    <P>
                      Already have a password?{" "}
                      <Link
                        to={`${process.env.REACT_APP_NEXT_ROUTE}/login`}
                        className="ms-2"
                      >
                        {SignIn}
                      </Link>
                    </P>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
