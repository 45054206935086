import React, { Fragment, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import MemberInformationContext from "../../../_helper/MemberInformation";
import { useWindowWidth } from "../../Chat/NewChat/inbox/useWindowWidth";
import "./dashboard.css";
import ProfileView from "./ProfileView";
import RecentlyJoined from "./RecentlyJoined";
import UpdateClassStatus from "./UpdateClassStatus";

const DefaultContain = () => {
  var userInfo = JSON.parse(localStorage.getItem("tuteAirUser"));
  const { getMemberInformation } = useContext(MemberInformationContext);
  const width = useWindowWidth();
  useEffect(() => {
    getMemberInformation();
  }, []);

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row>
          <Col xs="12" className="dash_img">
            <div
              className="dashboard-bg"
            // style={{
            //   backgroundImage: `url(${require("../../../assets/images/knowledgebase/bg_1.jpg")})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   display: "block",
            // }}
            >
              <div className="dashboardHeading">
                <div>
                  <H3>Hello {`${userInfo?.name}`}</H3>
                  <h4>Welcome to TuteAir</h4>
                </div>
              </div>
            </div>
            <UpdateClassStatus />
          </Col>
        </Row>
        <Row>
          <RecentlyJoined />
          {/* <StudenRequestPost val={[]} /> */}
          {/* <RecentActivitys /> */}
          <ProfileView />
        </Row>
      </Container>
    </Fragment>
  );
};
export default DefaultContain;
