import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ExternalLink, MapPin } from "react-feather";
import { Link } from "react-router-dom";
import { CardImg, CardText, CardTitle, Col, Media, Row } from "reactstrap";
import { Image, LI, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import { toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import RequestTuitionModal from "../../RequestTuition/Student/RequestTuitionAddEdit";
import SingleTutorModal from "../Hero-Section/SingleTutorModal";
import { Star } from "react-feather";
import { getEmailVerifiedOrNot } from "../BecomeTutor/fnBecomeTutor";
import { toast } from "react-toastify";
import { getResendEmailResponse } from "../BecomeTutor/fnBecomeTutor";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import SweetAlert from "sweetalert2";
import { fnResendEmail } from "../../../Services/Filter.Service";

const TutorListView = ({ tutors, allFilteredTutors, tutorWithRatings }) => {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const { setIsEmailVerified, isEmailVerified } =
    useContext(BecomeStudentContext);

  const [requestTuitionModal, setRequestTuitionModal] = useState(false);
  const requestTuitionToggle = () =>
    setRequestTuitionModal(!requestTuitionModal);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [resendEnabled, setResendEnabled] = useState(false);

  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");
  const [checkEmailVerified, setCheckEmailVerified] = useState(false);

  const isValidateForm = () => {
    if (subject?.length === 0) {
      toastNotifiy("Please Fillup the Subject Field!", "warn");
      return false;
    } else {
      return true;
    }
  };

  const openRequestTuitionModal = () => {
    if (isValidateForm()) {
      setTitle("Add Request for Tuition");
      setListViewData({
        gender: gender,
        profilePicture: profilePicExist,
        language: languages,
        location: location,
        tutionType: tutionType,
        subject: subject,
        subjectCategory: subject_category_filter,
        subjectSubCategory: subject_subcategory_filter,
      });
      requestTuitionToggle();
      setRequestTuitionModal(true);
    }
  };

  //check user loggedin or not
  const [visible, setVisible] = useState(false);
  const [tutor, setTutor] = useState({});
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  useEffect(() => {
    if (_tuteair?._id && _tuteair?.accessToken) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    // Define an async function inside useEffect since we cannot use `await` directly in useEffect
    const fetchData = async () => {
      try {
        // Call the getEmailVerifiedOrNot function and store the result in the variable `data`
        const data = await getEmailVerifiedOrNot();

        // Now you can use the `data` variable containing the res.data here
        setIsEmailVerified(data?.emailVerified);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (_tuteair?.emailVerified === true) {
      setCheckEmailVerified(true);
    }
    // Call the async function to fetch data
    fetchData();
  }, [isEmailVerified]);

  const handleEmailNotVerified = async (e) => {
    e.preventDefault();
    var htmlText = "";
    htmlText +=
      "<p>If you do not see the email in your inbox in a few minutes, please check your junk mail folder and add  <q>support@tuteair.com</q> to your White List or Safe Sender List.</p>";
    htmlText +=
      "<p>We make every effort to ensure that these emails are delivered.</p>";
    htmlText += `<p style=' font-size: 18px; color: #CA529A'>If you still don’t receive an email, then please click on <b style='  color:  #6e7881'>Resend Email</b></p>`;

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Your Email Not Verified, Please Verify Your Email",
      // titleText: "Second Title",
      html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Resend Email",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        handleResendEmail();
      }
    });
  };

  const handleResendEmail = async () => {
    // var response = await getResendEmailResponse(_tuteair?._id);
    // setResendEnabled(false);
    // if (response?.message === "verified") {
    //   setIsEmailVerified(true);
    //   SweetAlert.fire({
    //     title: `Your email are already verified.`,
    //     text: "Thank you for being with TuteAir. Complete your profile to find your desired tuitions.",
    //   });
    // } else if (response?.message === "success") {
    //   SweetAlert.fire({
    //     title: `Email sent to <u>${_tuteair?.email}<u/>`,
    //     text: "N.B: If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder",
    //   });
    // }
    await fnResendEmail({
      setResendEnabled,
      setIsEmailVerified,
      setCheckEmailVerified,
    });
  };

  // single tutor modal
  const handleSingleTutorModal = (e, item) => {
    e.preventDefault();
    setTutor(item);
    toggle();
  };
  const toggleCallback = useCallback((toggle) => {
    setModal(toggle);
  }, []);

  return (
    <Fragment>
      <Col xl="8" style={{ width: "100%" }}>
        <Row className="search-page">
          <div
            className="search-links tab-pane fade show active"
            id="all-links"
            role="tabpanel"
            aria-labelledby="all-link"
          >
            <Row>
              <Col xl="12 box-col-12 search-banner">
                {tutors &&
                  tutors?.map((item, k) => {
                    return (
                      <div
                        className="info-block p-3 mt-0 mb-2 single_tutor"
                        key={k}
                      >
                        <Media>
                          <Link
                            to={
                              visible && checkEmailVerified
                                ? `${process.env.PUBLIC_URL}/profile/${item.userId}`
                                : ``
                            }
                            onClick={
                              !visible
                                ? (e) => handleSingleTutorModal(e, item)
                                : visible && !checkEmailVerified
                                ? (e) => handleEmailNotVerified(e, item)
                                : ""
                            }
                          >
                            <Image
                              attrImage={{
                                className: "img-40 img-fluid m-r-20",
                                src: `${
                                  item.media?.picture
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.media?.picture
                                      }`
                                    : man
                                }`,
                                alt: "",
                              }}
                            />
                          </Link>
                          <Media body>
                            <h6
                              className="tutor_name"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                // to={`/profile/${item.userId}`}
                                to={
                                  visible && checkEmailVerified
                                    ? `${process.env.PUBLIC_URL}/profile/${item.userId}`
                                    : ``
                                }
                                onClick={
                                  !visible
                                    ? (e) => handleSingleTutorModal(e, item)
                                    : visible && !checkEmailVerified
                                    ? (e) => handleEmailNotVerified(e, item)
                                    : ""
                                }
                                data-toggle="modal"
                                data-target="#printModal"
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                {item.name?.firstName +
                                  " " +
                                  item.name?.lastName}
                                <span className="ms-4">
                                  {tutorWithRatings?.map(
                                    (item2) =>
                                      item2.tutorId === item.userId && (
                                        <p
                                          style={{
                                            fontSize: "17px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "22px",
                                            }}
                                          >
                                            <Star
                                              size={18}
                                              className="text-warning"
                                            />
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              marginLeft: "4px",
                                            }}
                                          >
                                            {(item2?.rating).toFixed(2)}
                                          </span>
                                        </p>
                                      )
                                  )}
                                </span>
                              </Link>
                              <span
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Open in new tab"
                                className="new_tab_icon"
                              >
                                <Link
                                  to={`/profile/${item.userId}`}
                                  target="_blank"
                                >
                                  <ExternalLink height={13} />
                                </Link>
                              </span>
                            </h6>
                            {item.interestAndPricing?.map((item, i) =>
                              [
                                ...new Set(
                                  item.subjects?.map((sub) => sub.tag)
                                ),
                              ]?.map((subject, k) => (
                                <span
                                  key={k}
                                  className="badge badge-light-primary py-1"
                                >
                                  {subject}
                                </span>
                              ))
                            )}
                            <a href="#javascript">{}</a>
                            <p className="" style={{ textAlign: "justify" }}>
                              {item.about?.tutorBio?.length > 165 ? (
                                <span>
                                  {item.about?.tutorBio
                                    .substring(0, 164)
                                    .trim()}
                                  ...
                                </span>
                              ) : (
                                <span>{item.about?.tutorBio}</span>
                              )}
                            </p>
                            <div className="star-ratings">
                              <UL
                                attrUL={{
                                  className: "simple-list search-info flex-row",
                                }}
                              >
                                <LI>
                                  <span>
                                    <MapPin height={"12px"} />
                                  </span>
                                  <span>
                                    {item.address?.postalCode}{" "}
                                    {item.address?.city} {item.address?.country}
                                  </span>
                                </LI>
                                <LI>
                                  Joined {moment(item.createdAt).fromNow()}
                                </LI>
                                {/* <LI>{"item.vote"}</LI> */}
                                {/* <LI>
                                  {item.interestAndPricing?.map((item, i) =>
                                    [
                                      ...new Set(
                                        item.subjects?.map((sub) => sub.tag)
                                      ),
                                    ]?.map((subject, k) => (
                                      <span
                                        key={k}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {subject}
                                      </span>
                                    ))
                                  )}
                                </LI> */}
                              </UL>
                            </div>
                          </Media>
                        </Media>
                      </div>
                    );
                  })}
              </Col>
              {tutors?.length == 0 && allFilteredTutors?.length == 0 && (
                <Col xl="12 box-col-12 search-banner">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <CardTitle className="h4">
                      Tired of not getting desired tutor?
                    </CardTitle>
                    <CardText className="h5">
                      Post your Tutor requirement Details
                    </CardText>
                    <div onClick={openRequestTuitionModal}>
                      <CardText className="h4 mb-2 text-primary clickHere">
                        Click Here
                      </CardText>
                    </div>
                    {requestTuitionModal && (
                      <RequestTuitionModal
                        title={title}
                        isOpen={requestTuitionModal}
                        toggler={requestTuitionToggle}
                        //edit={edit}
                        //view={view}
                        data={listViewData}
                      />
                    )}
                    <CardImg
                      style={{
                        width: "100%",
                        height: "55vh",
                      }}
                      alt="Card image cap"
                      src={NoTutorImage}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
          {modal && tutor && (
            <SingleTutorModal
              toggleCallback={toggleCallback}
              modal={modal}
              tutor={tutor}
              setTutor={setTutor}
            />
          )}
        </Row>
      </Col>
    </Fragment>
  );
};
export default TutorListView;
