import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProfilePictureRootURL } from "../../../../api";
import man from "../../../../assets/images/avtar/man.png";
import nouser from "../../../../assets/images/avtar/nouser.png";
import { apiSlice } from "../../../../redux/features/api/apiSlice";
import { useGetConversationsQuery } from "../../../../redux/features/chat/conversations/conversationsApi";
import {
  chattingInfo,
  unseenConversations,
} from "../../../../redux/features/chat/conversations/conversationsSlice";
import { confirmedTutorTuitions } from "../../../../redux/features/tuition/confirmedTuitionSlice";
import { tutorTuitions } from "../../../../redux/features/tuition/tuitionSlice";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import Error from "../Error";
import ChatItem from "./ChatItem";

export default function MyChatStatus() {
  const { user } = useSelector((state) => state.auth) || {};
  const { selectedUser, hideMessage, hideConversation } =
    useSelector((state) => state.conversations) || {};
  const activeUsers = useSelector((state) => state.conversations.activeUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    getTutorTuitions();
    getConfirmedTutorTuitions();
  }, []);

  const getTutorTuitions = async () => {
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/tutor",
      method: "get",
    });
    if (res) {
      dispatch(
        tutorTuitions({
          tutorTuitionList: res.data,
        })
      );
    }
  };

  const getConfirmedTutorTuitions = async () => {
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/student",
      method: "get",
    });
    if (res) {
      dispatch(
        confirmedTutorTuitions({
          confirmedTutorTuitionList: res.data,
        })
      );
    }
  };

  let {
    data: conversations,
    isLoading,
    isError,
    error,
  } = useGetConversationsQuery(user);

  // decide what to render
  let content = null;
  var descConversations = [];
  if (isLoading) {
    content = <li className="m-2 text-center">Loading...</li>;
  } else if (!isLoading && isError) {
    content = (
      <li className="m-2 text-center">
        <Error message={error?.data} />
      </li>
    );
  } else if (!isLoading && !isError && conversations?.length === 0) {
    content = <li className="m-2 text-center">No conversations found!</li>;
  } else if (!isLoading && !isError && conversations?.length > 0) {
    descConversations = [...conversations];
    descConversations.sort(function (a, b) {
      return new Date(b.updatedTime) - new Date(a.updatedTime);
    });

    const filterDescConversations = descConversations?.filter(
      (item) => item?.receiverId !== user
    );

    content = filterDescConversations.map((conversation, k) => {
      const {
        conversationId,
        name,
        thumb,
        message,
        updatedBy,
        updatedTime,
        isSeen,
        users,
        isAccountDeactive,
        isAccountDelete,
        receiverId,
      } = conversation;
      return selectedUser?.conversationId === conversationId ? (
        <li
          key={conversationId}
          className={` ${
            isAccountDeactive || isAccountDelete ? "deactivated" : ""
          } ${
            conversationId === selectedUser?.conversationId
              ? "chat_selected"
              : ""
          } clearfix ${!isSeen && user !== updatedBy ? "unseem-msg" : ""}`}
          onClick={(e) => {
            updateSeenConversation(conversationId, isSeen, updatedBy, users);
          }}
        >
          <ChatItem
            name={isAccountDeactive || isAccountDelete ? "TuteAir User" : name}
            avatar={
              isAccountDeactive || isAccountDelete
                ? nouser
                : !thumb || thumb === " "
                ? man
                : thumb?.profilePicture?.imageInfo?.secure_url
                ? thumb?.profilePicture?.imageInfo?.secure_url
                : ProfilePictureRootURL + thumb?.picture
            }
            lastMessage={message}
            updatedBy={updatedBy}
            lastTime={moment(updatedTime).fromNow()}
            isSeen={isSeen}
            active={
              activeUsers?.some(
                (activeUser) =>
                  activeUser.userId === users.filter((uId) => uId !== user)[0]
              )
                ? true
                : false
            }
          />
        </li>
      ) : (
        <Link to={`/inbox/${conversationId}`} key={k}>
          <li
            key={conversationId}
            className={` ${
              isAccountDeactive || isAccountDelete ? "deactivated" : ""
            } ${
              conversationId === selectedUser?.conversationId
                ? "chat_selected"
                : ""
            } clearfix ${!isSeen && user !== updatedBy ? "unseem-msg" : ""}`}
            onClick={(e) => {
              updateSeenConversation(conversationId, isSeen, updatedBy, users);
            }}
          >
            <ChatItem
              name={
                isAccountDeactive || isAccountDelete ? "TuteAir User" : name
              }
              avatar={
                isAccountDeactive || isAccountDelete
                  ? nouser
                  : !thumb || thumb === " "
                  ? man
                  : thumb?.profilePicture?.imageInfo?.secure_url
                  ? thumb?.profilePicture?.imageInfo?.secure_url
                  : ProfilePictureRootURL + thumb?.picture
              }
              lastMessage={message}
              updatedBy={updatedBy}
              lastTime={moment(updatedTime).fromNow()}
              isSeen={isSeen}
              // active={activeUsers?.some(user => user.userId === receiverId) ? true : false} //receiverId is inconsistent first time after creating new account
              active={
                activeUsers?.some(
                  (activeUser) =>
                    activeUser.userId === users.filter((uId) => uId !== user)[0]
                )
                  ? true
                  : false
              }
            />
          </li>
        </Link>
      );
    });
  }
  useEffect(() => {
    if (descConversations[0]?.conversationId) {
      dispatch(
        chattingInfo({
          type: "TOP",
          topConversation: descConversations[0],
        })
      );
      // dispatch(
      //   chattingInfo({
      //     type: "SELECTED",
      //     selectedUser: {
      //       conversationId: descConversations[0]?.conversationId,
      //       recevierId: descConversations[0]?.users.find((x) => x !== user),
      //     },
      //   })
      // );
    }
  }, []);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    setWidth(windowSize.innerWidth);

    if (windowSize.innerWidth <= 500 && !selectedUser) {
      dispatch(
        chattingInfo({
          type: "MOBILE",
          showConversation: "",
          showMessage: "d-none",
        })
      );
    } else if (windowSize.innerWidth >= 500) {
      dispatch(
        chattingInfo({
          type: "MOBILE",
          showConversation: "",
          showMessage: "",
        })
      );
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const updateSeenConversation = async (convId, isSeen, updatedBy, users) => {
    if (width <= 500) {
      dispatch(
        chattingInfo({
          type: "MOBILE",
          showConversation: "d-none",
          showMessage: "",
        })
      );
    } else {
      if (selectedUser?.conversationId === convId) return;
    }

    dispatch(
      chattingInfo({
        type: "SELECTED",
        selectedUser: {
          conversationId: convId,
          recevierId: users?.find((x) => x !== user),
        },
      })
    );

    if (!isSeen && user !== updatedBy) {
      dispatch(
        apiSlice.util.updateQueryData("getConversations", user, (draft) => {
          const draftConversation = draft.find(
            (c) => c.conversationId == convId
          );
          draftConversation.isSeen = true;
        })
      );
      var res = await axiosRequest({
        url: "/chat/newConversation/seen/" + convId,
        method: "get",
      });
      if (res.data?.message === "success") {
        var updatedUnseens = conversations?.filter(
          (x) =>
            x.isSeen === false &&
            x.conversationId !== convId &&
            x.updatedBy !== user
        );
        dispatch(
          unseenConversations({
            type: "ALL",
            unseensData: updatedUnseens,
          })
        );
      }
    } else {
    }
  };

  return (
    <div>
      {/* <SearchChatList /> */}
      <ul className={`simple-list list digits custom-scrollbar`}>{content}</ul>
    </div>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
