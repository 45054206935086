import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ExtendTuition from "../Schedule/Calendar/Extend/ExtendTuition";

const ExtendTuitionModal = (props) => {
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [title, setTitle] = useState("Calender");
  const [confirm, setConfirm] = useState(false);
  const [eventData, setEventDate] = useState(null);
  const [lessThan30D, setLessThan30D] = useState(false);

  const handleExtended = (e, date) => {
    e.preventDefault();
    if (moment(props?.data?.date).format("L") >= moment(new Date()).format("L")) {
      setIsExtended(true);
      setIsRescheduled(false);
      setIsCancelled(false);
      setTitle("Extend Tuition Time");
    }
  };

  useEffect(() => {
    var subjectName = "";
    props.data?.interestedSubjects?.subjects?.map((x) => {
      subjectName += " " + x.name;
    });

    setEventDate({
      ...props.data,
      tuitionId: props.data._id,
      tutorName: props.data?.tutor?.firstName + " " + props.data?.tutor?.lastName,
      title: subjectName,
      tutorId: props.data?.tutor?._id,
      studentId: props.data?.student?._id,
    });
  }, []);

  return (
    <>
      <Modal isOpen={true} toggle={props.toggle} className="schedule_modal" backdrop={"static"} animation={false}>
        <ModalHeader className="calender_title" toggle={props.toggle} style={{ color: (isCancelled || confirm) && "red" }}>
          Tuition Details
        </ModalHeader>
        <ModalBody className={"py-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  {isExtended ? (
                    <>
                      <ExtendTuition
                        setIsCancelled={setIsCancelled}
                        setIsRescheduled={setIsRescheduled}
                        setIsExtended={setIsExtended}
                        setTitle={setTitle}
                        event={eventData}
                        toggle={props.toggle}
                      />
                    </>
                  ) : (
                    <>
                      <Col sm="12" xl="12">
                        <div className="table-responsive-sm">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th>Tuition name</th>
                                <td>{eventData?.title}</td>
                                <td className="text_right"></td>
                              </tr>
                              <tr>
                                <th>{props.data?.memberType === "student" ? "Tutor" : "Student"}</th>
                                <td>
                                  {props.data?.memberType === "student"
                                    ? props.data?.tutor?.firstName + " " + props.data?.tutor?.lastName
                                    : props.data?.student?.firstName + " " + props.data?.student?.lastName}
                                </td>
                                <td className="text_right"></td>
                              </tr>
                              <tr>
                                <th>Start Date</th>
                                <td>{moment(new Date(props.data?.tuitionStartEnd?.classStart)).format("LL")}</td>
                                <td className="text_right"></td>
                              </tr>

                              <tr>
                                <th>Tuition Expire on</th>
                                <td>
                                  {props?.data?.diffDays > 0 ? (
                                    <>
                                      {props?.data?.diffDays} days ({moment(new Date(props.data?.tuitionStartEnd.classEnd)).format("LL")})
                                    </>
                                  ) : (
                                    <>{moment(new Date(props.data?.tuitionStartEnd.classEnd)).format("LL")}</>
                                  )}
                                </td>
                                <td className="text_right"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Row style={{ display: !lessThan30D && "none" }}>
                        <Col md={12} className="text-center py-2" style={{ color: "red", fontWeight: 900 }}>
                          Sorry, Tuition can only be extended if it expires in less than 30 days!
                        </Col>
                      </Row>
                      <Col md={12} className="cal_footer_center">
                        <Row>
                          <Col md={12}>
                            <button
                              className="btn btn-sm btn-secondary cal_btn"
                              onClick={(e) => {
                                if (props?.data?.diffDays >= 30) {
                                  return setLessThan30D(true);
                                } else {
                                  return handleExtended(e, props?.data?.date);
                                }
                              }}
                              disabled={false}
                            >
                              Extend
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter className=""></ModalFooter>
      </Modal>
    </>
  );
};

export default ExtendTuitionModal;
