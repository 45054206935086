import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import Questionss from "./Questions";

const FAQContent = () => {
  return (
    <Fragment>
      <Container fluid={true} className="pt-2">
        <div className="faq-wrap py-0 my-0">
          <Row className=" py-0 my-0">
            <Questionss />
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default FAQContent;
