import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ArrowRight } from "react-feather";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { toastNotifiy } from "../../../../Services/AuthVerify";
import { AddNotification } from "../../../../Services/Notification.Service";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import { useEditConversationMutation } from "../../../../redux/features/chat/conversations/conversationsApi";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { useSelector } from "react-redux";
import {
  findFreeSlots,
  formatStartEndTime,
  timeSlotByWeekDay,
  validateTimeSlot,
  validateTimeSlot2,
  validateTimeSlotFromScheduleList,
} from "./fnRescheduling";

const SingleOccurence = ({
  setIsCancelled,
  setIsRescheduled,
  setTitle,
  event,
  toggle,
  selectedConversation,
  tutorTimeTable = event.tutorTimeTable,
  rescheduleDates,
}) => {
  const [from, setFrom] = useState(new Date("01-Nov-2022 08:00 AM"));
  const [to, setTo] = useState(new Date("01-Nov-2022 11:00 AM"));
  const [startDate, setStartDate] = useState(new Date(event?.date));
  const [availableDates, setAvailableDates] = useState(new Date());
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [timeSlot, setTimeSlot] = useState({});
  const [reason, setReason] = useState("");
  const { memberType } = useSelector((state) => state.menu) || {};
  const {
    getAllSchedulesByRRule,
    scheduleEventList,
    setScheduleEventList,
    filterClassList,
  } = useContext(ClassSchedulerContext);
  const [
    editConversation,
    { isSuccess: isEditConversationSuccess, error: err2 },
  ] = useEditConversationMutation();

  useEffect(() => {
    // console.log({
    //   event: event,
    //   tutorTimeTable: tutorTimeTable,
    // });
    fnValidationTimeSlot();
  }, []);

  // get all schedule dates of tutor:ok
  // get all already rescheduled dates of tutor
  // filter only available dates with time slot

  const fnValidationTimeSlot = () => {
    var timeTable = [];
    tutorTimeTable.map((c) => {
      timeTable.push({
        dayName: c.dayName,
      });
    });
    var today = new Date();
    let tomorrow = new Date();
    const sixMonthAfter = new Date(
      today.getFullYear(),
      today.getMonth() + 6,
      today.getDate()
    );
    let rescheduleUntil = event?.tuitionStatus === "FREE" ? sixMonthAfter : new Date(event?.tuitionStartEnd?.classEnd);
    tomorrow.setDate(today.getDate() + 1);
    //next30Days.setDate(today.getDate() + 30);

    // getting all dates of tutor are available // only days
    var allSchedulesOfTutor = getAllSchedulesByRRule({
      startDate: tomorrow,
      endDate: rescheduleUntil,
      repeat: {
        type: "weekly",
        interval: 1,
      },
      timeTable: timeTable,
    });

    // tutor availability from tomorrow
    var _av = allSchedulesOfTutor?.filter((x) => moment(x).format("L") > moment(tomorrow).format("L"));

    // if we keep only date from selected event date
    // var _av = allSchedulesOfTutor?.filter(
    //   (x) => moment(x).format("L") > moment(event.date).format("L")
    // );

    // console.log("allSchedulesOfTutor", allSchedulesOfTutor, _av);

    // var newTimeTable = [];
    // for (let j = 0; j < _av.length; j++) {
    //   const date = _av[j];
    //   //getting timeSlot - each day wise
    //   var timeSlots = timeSlotByWeekDay(date, tutorTimeTable);
    //   for (let i = 0; i < timeSlots?.timeTable?.length; i++) {
    //     const element = timeSlots?.timeTable[i];
    //     if (!element.isBooked) {
    //       var isExist = isAvailableOnSpecificDate(
    //         element.start,
    //         element.end,
    //         date
    //       );
    //       if (!isExist) {
    //         newTimeTable.push(date);
    //         break;
    //       }
    //     }
    //   }
    // }
    const newTimeTable = _av.filter((date) => {
      const timeSlots = timeSlotByWeekDay(date, tutorTimeTable);
      return timeSlots?.timeTable.some((element) => {
        if (!element.isBooked) {
          return !isAvailableOnSpecificDate(element.start, element.end, date);
        }
        return false;
      });
    });
    var _newFilteredAvailableDates = newTimeTable.filter((date, i, self) => self.findIndex((d) => d.getTime() === date.getTime()) === i);

    setAvailableDates(_newFilteredAvailableDates);

    // next day is selected when enter first time to the component
    var nextDaySelected = allSchedulesOfTutor?.filter((x) => moment(x).format("L") > moment(event?.date).format("L"))[0];
    setStartDate(nextDaySelected);
    if (tutorTimeTable) handleRescheduleDate(nextDaySelected);
  };

  const handleRescheduleDate = (date) => {
    // get all rescheduled dates of selected date from datepicker
    const filteredArray = rescheduleDates?.filter((item) => !item.hasChanged && new Date(item.date).toDateString() === new Date(date).toDateString());
    // const filteredArray = rescheduleDates?.filter((item) => new Date(item.date).toDateString() === new Date(date).toDateString());
    // console.log({ filteredArray, tutorTimeTable, date });
    var timeSlots = timeSlotByWeekDay(date, tutorTimeTable);

    setStartDate(date ?? new Date());
    var newTimeTable = [];

    for (let i = 0; i < timeSlots?.timeTable?.length; i++) {
      const element = timeSlots?.timeTable[i];
      // checking student calendar he is booked or not with someone on this day.
      var isExist = isAvailableOnSpecificDate(element.start, element.end, date);
      if (!isExist) {
        //
        newTimeTable.push(element);
      }
    }

    var availableTime = newTimeTable?.filter((x) => !x.isBooked);

    var _availableFreeSlots = filteredArray?.length > 0 ? checkSchedule(availableTime, filteredArray, date) : availableTime;

    setAvailableTimeSlots(_availableFreeSlots);

    const firstAvailableTime = availableTime?.[0];

    const isValid = validateTimeSlot2(new Date(firstAvailableTime?.start), getEndTime(firstAvailableTime?.start), _availableFreeSlots);

    setTimeSlot({
      oldDate: event.start,
      start: new Date(firstAvailableTime?.start ?? Date.now()),
      end: new Date(getEndTime(firstAvailableTime?.start) ?? Date.now()),
      isValid: !!isValid,
    });

    // if (availableTime?.length > 0) {
    //   var endTime = getEndTime(availableTime[0].start);
    //   var isValid = validateTimeSlot2(
    //     new Date(availableTime[0].start),
    //     endTime,
    //     _availableFreeSlots
    //   );
    //   setTimeSlot({
    //     oldDate: event.start,
    //     start: new Date(availableTime[0].start),
    //     end: endTime,
    //     isValid: isValid,
    //   });
    // } else {
    //   setTimeSlot({
    //     oldDate: event.start,
    //     start: new Date(),
    //     end: new Date(),
    //     isValid: false,
    //   });
    // }
  };

  function modifyAvailableTime(availableSchedule, date) {
    const givenDate = new Date(date);
    const updatedSchedules = availableSchedule?.map((schedule) => {
      const startDate = new Date(schedule.start);
      startDate.setFullYear(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
      const updatedStart = startDate.toISOString();

      const endDate = new Date(schedule.end);
      endDate.setFullYear(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
      const updatedEnd = endDate.toISOString();

      return { start: updatedStart, end: updatedEnd };
    });
    return updatedSchedules;
  }

  function checkSchedule(schedule, bookedSchedule, date) {
    var freeList = [];
    schedule = modifyAvailableTime(schedule, date);
    for (let i = 0; i < schedule?.length; i++) {
      const sch = schedule[i];
      var freeSlots = findFreeSlots(sch, bookedSchedule);
      var concatenateList = freeList.concat(freeSlots);
      freeList = concatenateList;
    }
    return freeList;
  }

  const isAvailableOnSpecificDate = (start, end, date) => {
    var startEnd = formatStartEndTime(date, { start, end });
    var _start = new Date(startEnd?.start);
    var _end = new Date(startEnd?.end);
    var dateExist = scheduleEventList.filter(
      (x) => x.start.getTime() === _start.getTime() && x.end.getTime() === _end.getTime()
      //   || x.classStatus === "cancelled"
    );
    return dateExist.length > 0;
  };

  const getEndTime = (time) => {
    var timeDiff = event.end - event.start;
    var minutes = moment.duration(timeDiff).minutes();
    var hours = moment.duration(timeDiff).hours();

    var endTime = new Date(time);
    endTime.setHours(endTime.getHours() + hours);
    endTime.setMinutes(endTime.getMinutes() + minutes);
    return endTime;
  };

  const handleSelectedTimeSlot = (time, type, _ts) => {
    if (_ts) {
      var endTime = getEndTime(time);
      setTimeSlot({
        start: time,
        end: endTime,
      });
      if (type === "start") {
        var isValid = validateTimeSlot(
          time,
          endTime,
          tutorTimeTable,
          startDate
        );

        // if valid then check student is booked with other tutor on this time
        if (isValid) {
          var bookedEvent = validateTimeSlotFromScheduleList(scheduleEventList, startDate, time, endTime);
          // console.log(bookedEvent);
          if (bookedEvent && bookedEvent?.length > 0) {
            {
              memberType != "tutor"
                ? toastNotifiy(
                    "You have another tutor scheduled for this particular time slot!!  ",
                    "warn"
                  )
                : toastNotifiy(
                    "This student have another tutor scheduled for this particular time slot!!  ",
                    "warn"
                  );
            }

            isValid = false;
          }
        }

        // get date from scheduleEventList

        // if (isValid) {
        //   var isExists = isAvailableOnSpecificDate(
        //     time,
        //     endTime,
        //     startDate
        //   );
        //   isValid = !isExists;
        // }

        setTimeSlot({
          oldDate: event.start,
          start: time,
          end: endTime,
          isValid: isValid,
        });
      } else {
      }
    }
  };

  const handleRescheduleUpdate = async (e) => {
    e.preventDefault();

    var htmlContent = `
    <p>Your class of <strong>${moment(event?.date).format("LL")}</strong>
    will be rescheduled to 
    <strong>
      ${moment(startDate).format("LL")}
    </strong> 
    </p>
    <h5 class='pt-1'>Are you sure to reschedule your class?</h5>`;

    if (timeSlot?.isValid) {
      SweetAlert.fire({
        title: `Reschedule Class`,
        html: htmlContent,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "YES, Reschedule",
        cancelButtonText: "Not now",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          await fnReschedule();
        } else {
          // SweetAlert.fire({
          //   icon: "warning",
          //   text: "Nothing Changes!",
          // });
        }
      });
    } else {
      SweetAlert.fire({
        icon: "danger",
        text: "Invaild schedule time!",
      });
    }
  };

  const fnReschedule = async () => {
    var dt1 = moment(startDate).format("YYYY-MM-DD");
    var _start = moment(timeSlot?.start).format("LT");
    var _end = moment(timeSlot?.end).format("LT");
    var start = moment(`${dt1} ${_start}`, "YYYY-MM-DD HH:mm aa").format();
    var end = moment(`${dt1} ${_end}`, "YYYY-MM-DD HH:mm aa").format();
    // var oldRescheduled = event?.oldRescheduled;
    // oldRescheduled.push(new Date(event.date));
    const data = {
      tuitionId: event.tuitionId,
      tutorId: event.tutorId,
      classId: event.classId,
      startTime: start,
      endTime: end,
      date: start,
      isPaid: event.isPaid,
      // reschedule_reason: reason,
      // oldRescheduled: oldRescheduled,
      reschedule_infos: event?.reschedule_infos,
      rescheduleBy: event.studentId, // new added:05 Apr
      isFreeClass: false,
      classInfo: {
        reason: reason,
        classId: event.classId,
        oldDate: new Date(event.date),
        oldStartTime: event?.start ? new Date(event?.start) : "",
        oldEndTime: event?.end ? new Date(event?.end) : "",
      },
    };
    var res = await axiosRequest({
      url: "/tuition/rescheduled-class/",
      method: "put",
      data: data,
    });
    if (res.status === 201) {
      var prevList = [...scheduleEventList];

      for (let i = 0; i < prevList.length; i++) {
        const element = prevList[i];
        if (element.tuitionId === event.tuitionId && element.classId === event.classId) {
          var reschedule_infos = event?.reschedule_infos;
          reschedule_infos.push(res?.data.reschedule_info_id);
          element.classStatus = "rescheduled";
          // element.oldRescheduled = [event.date];
          element.start = new Date(start);
          element.end = new Date(end);
          element.date = new Date(start);
          element.isPaid = event.isPaid;
          element.isRescheduled = true;
          element.reschedule_infos = reschedule_infos;
          //  element.reschedule_reason = reason;

          break;
        }
      }
      SweetAlert.fire({
        icon: "success",
        text: "Your class rescheduled to " + moment(startDate).format("LL"),
      });
      filterClassList(event?.memberType, event?.userId, prevList);
      sendNotificationMessage(start);
      toggle();
    }
  };

  const sendNotificationMessage = (newDate) => {
    const { studentId, tutorId, userId, title, date, memberType } = event;
    const notifyObj = {
      receiver: memberType === "tutor" ? studentId : tutorId,
      title: "Your tuition has been rescheduled",
      description: "Someone Wants to contact with you",
      context: process.env.PUBLIC_URL + "/calendar",
      isPublic: false,
    };
    AddNotification(notifyObj);
    const oldScheduleMessage = scheduleMessage(date);
    const rescheduledMessage = scheduleMessage(new Date(newDate));
    editConversation({
      sender: userId,
      id: selectedConversation?.conversationId,
      data: {
        message: `
        Your tuition for ${title} has been rescheduled.
        Previous: ${oldScheduleMessage}
        Rescheduled: ${rescheduledMessage}
        `,
      },
    });
  };

  const scheduleMessage = (date) => {
    const prevDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const prevDay = new Date(date).toLocaleDateString("en-US", {
      weekday: "long",
    });
    const prevTime = new Date(date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${prevDate}, ${prevDay} at ${prevTime}`;
  };

  return (
    <>
      <Form className="pt-3">
        <Row>
          <Label for="exampleEmail" size="lg" sm={2}>
            Date
          </Label>
          <Col xl="4" sm="4" lg="4">
            <DatePicker
              className="form-control datetimepicker-input digits"
              selected={startDate}
              onChange={(date) => handleRescheduleDate(date)}
              dateFormat="MMM dd, yyyy"
              includeDates={availableDates}
            />
          </Col>
        </Row>

        <Row className="py-2">
          <Col md={2}>{availableTimeSlots?.filter((x) => !x.isBooked)?.length > 0 && <div className="py-1">Available Slots </div>}</Col>
          <Col md={6}>
            {availableTimeSlots?.map((t, k) => (
              <>
                {t.isBooked ? (
                  <strong className="d-none" key={k}>
                    {/* <del>
                        {moment(new Date(t.start)).format("LT")}-
                        {moment(new Date(t.end)).format("LT")} (Not Available)
                      </del> */}
                  </strong>
                ) : (
                  <div className="py-1" key={k}>
                    <strong>
                      {moment(new Date(t.start)).format("LT")}-{moment(new Date(t.end)).format("LT")}
                    </strong>
                  </div>
                )}
              </>
            ))}
          </Col>
        </Row>

        <div className="tutor_timeslots">
          <>
            {availableTimeSlots?.filter((x) => !x.isBooked)?.length > 0 ? (
              <>
                <Row className={`${!timeSlot?.hasOwnProperty("isValid") ? "" : timeSlot.isValid ? "bg_valid" : "bg_invalid"}`}>
                  <Col md={2}></Col>
                  <Col xl="4" sm="4" lg="4">
                    <DatePicker
                      className="form-control datetimepicker-input digits"
                      selected={timeSlot?.start}
                      onChange={(sTime) => {
                        handleSelectedTimeSlot(sTime, "start", timeSlot);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Start"
                      timeFormat="h:mm aa"
                      dateFormat="h:mm aa"
                      // includeTimes={() => {
                      //   return [new Date("5:30 pm"), new Date("8:30 pm")];
                      // }}
                    />
                  </Col>
                  <Label for="exampleEmail2" className="text-center" sm={1}>
                    <ArrowRight />
                  </Label>
                  <Col xl="4" sm="4" lg="4">
                    <DatePicker
                      className="form-control datetimepicker-input digits"
                      selected={timeSlot?.end}
                      onChange={(t) => {
                        handleSelectedTimeSlot(t, "end", timeSlot);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Start"
                      timeFormat="h:mm aa"
                      dateFormat="h:mm aa"
                      readOnly
                    />
                  </Col>
                </Row>
                {!timeSlot?.isValid && (
                  <>
                    <Row>
                      <Col md={12} className="text-center text_red">
                        <small>
                          <b> N.B:</b> Tutor is not available or booked with someone else in your selected timeslot.
                        </small>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                <Row>
                  <Col md={12}>
                    <h4 className="text_red text-center">There is no available time slot on this day</h4>
                  </Col>
                </Row>
              </>
            )}
          </>
        </div>
        <FormGroup row>
          <Label for="exampleEmail2" sm={2}>
            Reason
          </Label>
          <Col xl="9" sm="9" lg="9">
            <Input
              id="exampleText"
              name="text"
              value={reason}
              disabled={!timeSlot?.isValid}
              onChange={(e) => setReason(e.target.value)}
              type="textarea"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={12} className="text-center">
            <button
              onClick={(e) => {
                setIsCancelled(false);
                setIsRescheduled(false);
                setTitle("Calendar");
              }}
              className="btn btn-info mx-2"
            >
              Back
            </button>
            <button
              className="btn btn-success"
              disabled={!timeSlot?.isValid}
              // onClick={sendNotificationMessage}
              onClick={(e) => {
                handleRescheduleUpdate(e);
              }}
            >
              Reschedule
            </button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default SingleOccurence;
