import axios from "axios";
import React, { useEffect, useState } from "react";
import { MemberAllInfosGetApi, ProfilePictureRootURL } from "../../api";
import { axiosRequest } from "../../redux/utils/axios-utils";
import { authHeader } from "../../Services/AuthVerify";
import Context from "./index";

const MemberInformationProvider = (props) => {
  const [about, setAbout] = useState({
    tutorBio: " ",
    studentBio: " ",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [media, setmedia] = useState({});
  const [dateOfBirth, setdateOfBirth] = useState(new Date("01/01/2000"));
  const [fullname, setFullName] = useState({});
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [languages, setLanguages] = useState([]);
  const [currency1, setCurrency] = useState({});
  const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);
  const [memberType, setMemberType] = useState("");
  const [address, setAddress] = useState(null);
  const [educations, setEducations] = useState([]);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [interestAndPricing, setInterestAndPricing] = useState([]);
  const [interestToLearn, setInterestToLearn] = useState([]);

  const [profileUrl, setProfileUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [documentID, setDocumentID] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [tutorViewCount, setTutorViewCount] = useState(0);
  const [studentViewCount, setStudentViewCount] = useState(0);
  const [experienceData, setExpericendData] = useState([]);

  const setMembersAllInfo = (data) => {
    //console.log(data.educations)
    setAbout(data?.about);
    setmedia(data?.media);
    setdateOfBirth(new Date(data?.doB == "" ? "01/01/2000" : data?.doB));
    setFullName(data?.name?.firstName + " " + data?.name?.lastName);
    setGender(data?.gender);
    setEmail(data?.email);
    setPhone(data?.contactNo?.number);
    setLanguages(data?.languages);
    setCurrency(data?.currency);
    setMemberType(data?.memberType);
    setAddress(data?.address);
    setEducations(data?.educations);
    setWorkExperiences(data?.workExperiences);
    setExpericendData(data?.workExperiences);
    setSchedules(data?.schedules);
    setInterestAndPricing(data?.interestAndPricing);
    setInterestToLearn(data?.interestToLearn);
    setStudentViewCount(data?.studentViewCount ? data?.studentViewCount : 0);
    setTutorViewCount(data?.tutorViewCount ? data?.tutorViewCount : 0);

    const selectedlanguages = [];
    for (var i = 0; i < data?.languages.length; i++) {
      selectedlanguages.push(data?.languages[i]);
    }
    setLanguages(selectedlanguages?.map((value) => value));
    setLanguageSelectedOptions(selectedlanguages);
    if (
      data?.media?.picture ||
      data?.media?.profilePicture?.imageInfo?.secure_url
    ) {
      const profUrl =
        data?.media?.profilePicture?.imageInfo?.secure_url ||
        ProfilePictureRootURL + data?.media?.picture;
      setProfileUrl(profUrl);
      setSelectedFile([]);
      setDocumentID(data.media.picture);
    }
  };

  const getMemberInformation = async () => {
    await axios
      .get(MemberAllInfosGetApi, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        if (res.data) setMembersAllInfo(res.data);
      });
  };

  const getDegreeAndCoursesList = async () => {
    var res = await axiosRequest({
      url: "/member/educational-info/getAllDegreeCourseAndInstitutes",
      method: "get",
    });
    if (res.data) {
      setCourseList(res.data.courses);
      setDegreeList(res.data.degrees);
    }
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("tuteAirUser"));
    if (user !== null && (courseList?.length === 0 || degreeList?.length === 0))
      getDegreeAndCoursesList();
  }, []);

  const clearMemberInfoContext = () => {
    setAbout({});
    setmedia({});
    setdateOfBirth(new Date("01/01/2000"));
    setFullName("");
    setGender("");
    setEmail("");
    setPhone("");
    setLanguages([]);
    setMemberType("");
    setAddress({});
    setEducations([]);
    setWorkExperiences([]);
    setSchedules([]);
    setInterestAndPricing([]);
    setInterestToLearn([]);
    setLanguageSelectedOptions([]);
    setProfileUrl("");
    setDocumentID("");
  };

  return (
    <Context.Provider
      value={{
        ...props,
        isVisible,
        setIsVisible,
        address,
        setAddress,
        about,
        setAbout,
        media,
        setmedia,
        dateOfBirth,
        setdateOfBirth,
        fullname,
        setFullName,
        gender,
        setGender,
        email,
        setEmail,
        phone,
        setPhone,
        languages,
        setLanguages,
        currency1,
        setCurrency,
        memberType,
        setMemberType,
        educations,
        setEducations,
        workExperiences,
        setWorkExperiences,
        schedules,
        setSchedules,
        interestAndPricing,
        setInterestAndPricing,
        interestToLearn,
        setInterestToLearn,
        getMemberInformation,
        selectedLanguageOptions,
        setLanguageSelectedOptions,
        profileUrl,
        selectedFile,
        documentID,
        profileUrl,
        setProfileUrl,
        setSelectedFile,
        setDocumentID,
        courseList,
        degreeList,
        setCourseList,
        setDegreeList,
        getDegreeAndCoursesList,
        tutorViewCount,
        studentViewCount,
        clearMemberInfoContext,
        experienceData,
        setExpericendData,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default MemberInformationProvider;
