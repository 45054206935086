import React, { Fragment } from "react";
import { Modal } from "reactstrap";
import RequestTuitionAddEditContent from "./RequestTuitionAddEdit";
import RequestTuitionViewContent from "./RequestTuitionView";

const RequestTuitionModal = (props) => {
  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop={"static"}
      >
        {props.view && (
          <RequestTuitionViewContent
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            data={props.data}
          />
        )}
        {!props.view && (
          <RequestTuitionAddEditContent
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            edit={props.edit}
            data={props.data}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default RequestTuitionModal;
