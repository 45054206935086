import React, { useState } from "react";
import Context from "./index";

const FilterProvider = (props) => {
  const [name, setName] = useState(" ");
  const [age, setAge] = useState([]);
  const [rating, setRating] = useState([]);
  const [gender, setGender] = useState("0");
  const [languages, setLanguages] = useState([]);
  const [profilePicExist, setProfilePicExist] = useState(false);
  const [location, setLocation] = useState({});
  const [fullCountry, setFullCountry] = useState(null);
  const [tutionType, setTutionType] = useState([]);
  const [subject, setSubject] = useState([]);
  const [subject_category_filter, setSubject_category_filter] = useState({});
  const [subject_subcategory_filter, setSubject_subcategory_filter] = useState(
    {}
  );
  const [scheduleFilter, setScheduleFilter] = useState([]);
  const [hasTime, setHasTime] = useState(false);
  // const [scheduleFilter, setScheduleFilter] = useState({});
  const [educationFilter, setEducationFilter] = useState({});
  const [workExperiencesFilter, setWorkExperiencesFilter] = useState({});
  const [priceFilter, setPriceFilter] = useState({});
  const [myFilter, setMyFilter] = useState({});
  const [moonlight, setMoonlight] = useState(false);
  const [themeReminder, setThemeReminder] = useState(false);
  //--- setting account type while registering for the first time ---
  const [accountType, setAccountType] = useState("tutor");
  const [isTutor, setIsTutor] = useState(false);
  const [isRatingChange, setIsRatingChange] = useState(false);

  // const setAddressData = (address) => {

  // }

  // useEffect(() => {
  //     const getPreviousLocation = async () => {
  //         await axios.get(TutorPersonalInfoGetApi, {
  //             headers: {
  //                 Authorization: authHeader(),
  //             }
  //         }).then((res) => {
  //             setAddressData(res.data[0].address);
  //         });
  //     }

  //     var isMember = JSON.parse(localStorage.getItem("isMemberExists"));
  //     if (isMember) getPreviousLocation();
  // }, [])
  return (
    <Context.Provider
      value={{
        ...props,
        name,
        setName,
        age,
        setAge,
        rating,
        setRating,
        gender,
        setGender,
        languages,
        setLanguages,
        profilePicExist,
        setProfilePicExist,
        location,
        setLocation,
        tutionType,
        setTutionType,
        subject,
        setSubject,
        subject_category_filter,
        setSubject_category_filter,
        subject_subcategory_filter,
        setSubject_subcategory_filter,
        scheduleFilter,
        setScheduleFilter,
        hasTime,
        setHasTime,
        educationFilter,
        setEducationFilter,
        workExperiencesFilter,
        setWorkExperiencesFilter,
        priceFilter,
        setPriceFilter,
        fullCountry,
        setFullCountry,
        myFilter,
        setMyFilter,
        moonlight,
        setMoonlight,
        themeReminder,
        setThemeReminder,
        accountType,
        setAccountType,
        isTutor,
        setIsTutor,
        isRatingChange,
        setIsRatingChange,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default FilterProvider;
