import { apiSlice } from "../api/apiSlice";
import { userLoggedIn } from "../auth/authSlice";
import { memberMenus } from "../menus/menuSlice";

export const membersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLatestTutors: builder.query({
      query: () => `/newMember/latest-tutors`,
    }),
    getLatestStudents: builder.query({
      query: () => `/newMember/latest-students`,
    }),
    getMemberInfoById: builder.query({
      query: (userId) => `/newMember/member-info/${userId}`,
    }),
    updateMemberBio: builder.mutation({
      query: ({user, about}) => ({
        url: `/newMember/update-bio`,
        method: 'PATCH',
        body: { user, about },
      }),
      async onQueryStarted({ user, about }, { dispatch, queryFulfilled }) {
        try {
          const { data: updateMemberBio } = await queryFulfilled; //returns profile with updated about
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('getMemberInfoById', user, (draft) => {
              Object.assign(draft, updateMemberBio)
            })
          )
        } catch {}
      }
    }),
    updateMemberBasicInfo: builder.mutation({
      query: ({user, contactNo, gender, languages, memberType, firstName, lastName}) => ({
        url: `/newMember/update-basic-info`,
        method: 'PATCH',
        body: { user, contactNo, gender, languages, memberType },
      }),
      async onQueryStarted({ user, memberType, firstName,  lastName}, { dispatch, queryFulfilled }) {
        try {
          const { data: updateMemberBasicInfo } = await queryFulfilled; //returns profile with updated data

          const patchResult = dispatch(
            apiSlice.util.updateQueryData('getMemberInfoById', user, (draft) => {
              Object.assign(draft, updateMemberBasicInfo)
            })
          )
          
          const _tuteair = JSON.parse(
            localStorage.getItem("_tuteair_newTutor")
          );
          const tuteAirUser = JSON.parse(localStorage.getItem("tuteAirUser"));

          if (memberType === "both") {
            localStorage.setItem("swMember", "tutor");
            dispatch(memberMenus({ memberType: "tutor" }));
          } else {
            localStorage.setItem("swMember", memberType);
            dispatch(memberMenus({ memberType: memberType }));
          }

          dispatch(
            userLoggedIn({
              accessToken: _tuteair.accessToken,
              user: _tuteair._id,
              accountType: memberType,
              firstName: firstName,
              lastName: lastName,
            })
          );

          localStorage.setItem(
            "tuteAirUser",
            JSON.stringify({
              ...tuteAirUser,
              accountType: memberType,
            })
          );

          localStorage.setItem(
            "_tuteair_newTutor",
            JSON.stringify({
              ..._tuteair,
              accountType: memberType,
            })
          );

        } catch {}
      }
    }),
    updateMemberAddress: builder.mutation({
      query: ({user, address}) => ({
        url: `/newMember/update-address`,
        method: 'PATCH',
        body: { user, address },
      }),
      async onQueryStarted({ user }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedMemberInfo } = await queryFulfilled; //returns profile with updated address
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('getMemberInfoById', user, (draft) => {
              const draftInfo = draft.find(
                (member) => member._id === updatedMemberInfo._id
              );
              draftInfo.address = updatedMemberInfo.address;
            })
          )
        } catch {}
      }
    }),
  }),
});

export const { useGetLatestStudentsQuery, useGetLatestTutorsQuery, useGetMemberInfoByIdQuery, useUpdateMemberBioMutation, useUpdateMemberBasicInfoMutation, useUpdateMemberAddressMutation } =
  membersApi;
