import React from "react";

const HelpConfirm = () => {
  return (
    <div>
      <p className="f-16">
        You will get a notification for a tution request. In your Dashboard
        <ul className="f-16">
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              1
            </span>{" "}
            Click on the <span className="">Notification Icon</span>
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              2
            </span>{" "}
            Click on the notification link
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              3
            </span>{" "}
            Click on <span className="">Tuitions</span>
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              4
            </span>{" "}
            Select an open tuition
          </li>
          <li>
            <span className="px-1 border rounded-circle b-info txt-info">
              5
            </span>{" "}
            Click on <span className="">Confirm</span>
          </li>
          <li className="m-t-10" style={{ textAlign: "justify" }}>
            <i class="fa fa-hand-o-right txt-info m-r-5"></i>
            <span>
              If you want any changes in the selected tuition, you can decline
              it with a message stating your reason to the tutor.
            </span>
          </li>
        </ul>
      </p>
    </div>
  );
};

export default HelpConfirm;
