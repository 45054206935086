import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useWindowWidth } from "./useWindowWidth";
import {
  updateTutorProfile,
  validateTuitionData,
  getTutorSchedule,
  getDuration,
  validateTimeSlot,
  handleTuitionScheduleString,
  handleTuitionDateString,
  handleTuitionPlaceString,
  getRescheduleContent
} from "./tuitionCommonFns";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import { ArrowRight, X } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Badges, Btn } from "../../../../AbstractElements";
import { useEditConversationMutation } from "../../../../redux/features/chat/conversations/conversationsApi";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { toastNotifiy } from "../../../../Services/AuthVerify";
import { AddNotification } from "../../../../Services/Notification.Service";
import "../../../../style/custom-css/PersonalInfo.css";
import TuitionModalHeader from "./TuitionComponents/TuitionModalHeader";
import { TutorScheduleDisplay } from "./TuitionComponents/TutorScheduleDisplay";
import TuitionPlace from "./TuitionComponents/TuitionPlace";
import { TuitionDate } from "./TuitionComponents/TuitionDate";
import { useGetRescheduledClassesByTutorIdQuery } from "../../../../redux/features/tuition/tuitionApi";
import SweetAlert from "sweetalert2";

const AllTuitionsModal = ({
  title,
  isOpen,
  toggler,
  subjectList,
  conversationId,
  selectedStudent,
  receiverId,
  tutorCurrency,
  selectedTutorName,
  allTuitions,
}) => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  let nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    today.getDate()
  );

  const width = useWindowWidth();
  const [loading, setLoading] = useState(false);
  const [tuitions, setTuitions] = useState([]);
  const [modalOpen, setmodalOpen] = useState(isOpen);
  const [tuitionsData, setTuitionsData] = useState([]);
  // post
  const [selectedTuition, setSelectedTuition] = useState("");
  const [tuitionPlace, setTuitionPlace] = useState("");
  const [budget, setBudget] = useState("");
  const [currency, setCurrency] = useState("");
  const [message, setMessage] = useState("");
  const [tuitionStartDate, setStartDate] = useState(today);
  const [tuitionEndDate, setTuitionEndDate] = useState(nextMonth);
  const [weekdayList, setWeekdayList] = useState([]);
  const [selectedDayList, setSelectedDayList] = useState([]);
  const [oldSelectedDayList, setOldSelectedDayList] = useState([]);
  const [tutorOldSchedule, setTutorOldSchedule] = useState([]);
  const [myTuitions, setMyTuitions] = useState([]);
  const [tuitionStatus, setTuitionStatus] = useState("OPEN");
  const [selectedTuitionInfo, setSelectedTuitionInfo] = useState({});
  const [selectedTuitionId, setSelectedTuitionId] = useState({});
  const [timeTableHistoryInfo, setTimeTableHistoryInfo] = useState({});
  const [timeValidation, setTimeValidation] = useState(true);
  const navigate = useNavigate();

  const { user: loggedInUser } = useSelector((state) => state.auth) || {};
  const { selectedUser, hideMessage, hideConversation } =
    useSelector((state) => state.conversations) || {};

  const [
    editConversation,
    { isSuccess: isEditConversationSuccess, error: err2 },
  ] = useEditConversationMutation();

  const { data: rescheduledClasses, isLoading, refetch } = useGetRescheduledClassesByTutorIdQuery(
    loggedInUser, { skipToken: true }
  );

  //validate budget
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValidBudget, setIsValidBudget] = useState(false);
  const [budgetErrorMessage, setBudgetErrorMessage] = useState(
    "Budget cannot be negative"
  );

  const intitalFetch = () => {
    var arrList = [];
    var myTuitions = [...allTuitions];
    myTuitions?.map((item, index) => {
      var subjects = "";
      let tag = "";
      if (item.tuitionRequest?.subjects) {
        item.tuitionRequest?.subjects?.map((item2, index2) => {
          var comma =
            item.tuitionRequest?.subjects?.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.label?.toString().trim() + comma;
          tag = item2?.tag;
        });
      } else {
        item.interestedSubjects?.subjects?.map((item2, index2) => {
          var comma =
            item.interestedSubjects?.subjects?.length - 1 === index2
              ? ""
              : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
          tag = item2?.tag;
        });
      }
      // const tuitionStatus = item.status;
      arrList.push({
        info: item,
        value: item._id,
        label: subjects + " >> " + tag,
        isDisabled: false,
        type: item.sender,
        status: item.status
      });
    });
    setMyTuitions(arrList);
  };

  useEffect(() => {
    intitalFetch();
  }, []);

  useEffect(() => {
    if (selectedTuition) {
      const tuitionInfo = selectedTuition?.info;
      setSelectedTuitionInfo(tuitionInfo);
      setSelectedTuitionId(tuitionInfo?._id);
      setBudget(tuitionInfo?.confirmedTuitionFee);
      setCurrency(tuitionInfo?.currency?.name);
      setTuitionStatus(tuitionInfo?.status);
      setTuitionPlace(tuitionInfo?.tuitionPlace);
      if (tuitionInfo?.timeTableHistory) {
        let history = tuitionInfo?.timeTableHistory;
        let tuitionLength = history.length - 1;
        var currentTuition = history[tuitionLength];
        setTimeTableHistoryInfo(currentTuition);
        setSelectedDayList(currentTuition?.timeTable);
        setOldSelectedDayList(currentTuition?.timeTable);
        handleStartDate(new Date(currentTuition?.classStart));
        handleEndDate(new Date(currentTuition?.classEnd));
        setWeekdayList(tutorOldSchedule);
      } else {
        handleStartDate(new Date());
        handleEndDate(new Date());
      }
      showTutionSchedule(selectedTuitionId, currentTuition?.timeTable);
      setTimeValidation(true);
    }
  }, [selectedTuition]);

  const showTutionSchedule = (selectedTuitionId, currentSchedule) => {
    let temp = [...weekdayList];
    temp.map((day) => {
      day.isChecked = false;
    });
    for (let i = 0; i < currentSchedule.length; i++) {
      temp.map((day) => {
        if (day.dayName === currentSchedule[i].dayName) {
          day.isChecked = true;
          day.initialStart = new Date(currentSchedule[i]?.start);
          day.initialEnd = new Date(currentSchedule[i]?.end);
          day.timeDiff = currentSchedule[i]?.duration;
          day.isTimeSlotValid = true;
        }
      });
    }
    setWeekdayList(temp);
  };

  useEffect(() => {
    fnSetInitialField();
    getTutorSchedule(setTutorOldSchedule, setWeekdayList);
    return () => {
      setTuitions([]);
    };
  }, []);

  const fnIsValidBudget = (Number) => {
    if (Number >= 0) return true;
    else return false;
  };
  useEffect(() => {
    if (!fnIsValidBudget(budget)) {
      setIsValidBudget(false);
      setShowErrorMessage(true);
    } else {
      setIsValidBudget(false);
      setShowErrorMessage(false);
    }
  }, [budget]);

  const updateTuitionSchedule = async () => {
    var res = await axiosRequest({
      // update tuition data
      url: "/tuition/update-timetable",
      method: "put",
      data: {
        tuitionId: selectedTuitionInfo?._id,
        timeTableId: timeTableHistoryInfo?._id,
        confirmedTuitionFee: budget,
        tuitionPlace: tuitionPlace,
        classStart: tuitionStartDate,
        classEnd: tuitionEndDate,
        repeat: {
          type: "weekly",
          interval: 1,
        },
        timeTable: selectedDayList,
      },
    });
    // check success > update tutor profile schedule > notify student > navigate
    if (res?.status === 200) {
      const tuitionId = selectedTuitionInfo?._id;
      const updatedOldSchedule = removeExisting(selectedDayList, tuitionId);
      // setTutorOldSchedule(updatedOldSchedule);
      updateTutorProfile(tuitionId, selectedDayList, updatedOldSchedule);
      const notifyObj = {
        receiver: receiverId,
        title: "Your tuition information has been updated",
        description: "Someone Wants to contact with you",
        context: process.env.PUBLIC_URL + "/inbox",
        isPublic: false,
      };
      AddNotification(notifyObj);
      setLoading(false);
      const tuitionStartDateString = handleTuitionDateString(new Date(tuitionStartDate));
      const tuitionEndDateString = handleTuitionDateString(new Date(tuitionEndDate));
      const tuitionScheduleString = handleTuitionScheduleString(selectedDayList);
      const tuitionPlaceString = handleTuitionPlaceString(tuitionPlace);
      editConversation({
        sender: loggedInUser,
        id: conversationId,
        data: {
          message: `Your tuition for "${selectedTuition.label}" has been updated. The updated details are as follows- [Tuition Fee]: ${budget} USD, [Location]: ${tuitionPlaceString}, [Start Date]: ${tuitionStartDateString}, [End Date]: ${tuitionEndDateString}, [Schedule]: ${tuitionScheduleString} Please click on the notification to get the updated tuition.`,
        },
      });
      toastNotifiy("Tuition has been updated", "success");
      navigate(`${process.env.PUBLIC_URL}/inbox/`);
      toggler();
    }
  };

  const fnSetInitialField = async () => {
    setLoading(true);
    var tuitionList = [];
    if (subjectList) {
      setTuitionsData(subjectList);
      subjectList?.map((item, index) => {
        var subjects = "";
        item.subjects.map((item2, index2) => {
          var comma = item.subjects.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
        });
        tuitionList.push({
          value: item.id,
          label: subjects + " ", //+ item.amount,
          item: item,
        });
      });
    }
    setTuitions(tuitionList);
    setLoading(false);
  };

  const handlePreferredPlace = (e) => {
    setTuitionPlace(e.target.value);
  };
  const handleStartDate = (date) => {
    setStartDate(new Date(date));
    const oneMonthAfter = new Date(
      date.getFullYear(),
      date.getMonth() + 2,
      date.getDate()
    );
    setTuitionEndDate(oneMonthAfter);
  };

  const handleEndDate = (date) => {
    setTuitionEndDate(new Date(date));
  };

  const handleSubjects = (item) => {
    if (!item) {
      setSelectedTuition([]);
      return;
    }
    setSelectedTuition(item);
  };

  const handleNotify = () => {
    toastNotifiy(`You can't update tuition schedule`, "warn");
  };

  const handleWeeklyDayChange = (e) => {
    // when check on [sat,sun,...] update existing weekDayList
    if (timeValidation) {
      const { value, checked } = e.target;
      var _prevList = [...weekdayList];
      _prevList.map((day) => {
        var freeSlots = day.timeTable?.filter((t) => !t.isBooked);
        if (day.dayName === value) {
          if (freeSlots.length > 0) {
            day.isChecked = checked;
            day.initialStart = new Date(freeSlots[0]?.start);
            day.initialEnd = new Date(freeSlots[0]?.end);
            day.isTimeSlotValid = validateTimeSlot(
              new Date(freeSlots[0]?.start),
              new Date(freeSlots[0]?.end),
              freeSlots
            );
            setTimeValidation(day.isTimeSlotValid);
            let start = moment(day.initialStart).format("HH:mm A");
            let end = moment(day.initialEnd).format("HH:mm A");
            if (start.slice(6, 8) === "PM" && end.slice(6, 8) === "AM") {
              const nextDay = day.initialEnd.setDate(
                day.initialEnd.getDate() + 1
              );
              getDuration(day.initialStart, nextDay, day);
            } else {
              getDuration(day.initialStart, day.initialEnd, day);
            }
          } else if (
            freeSlots.length === 0 &&
            day.isChecked === true &&
            (tuitionStatus === "OPEN" || tuitionStatus === "DECLINED")
          ) {
            day.isChecked = checked;
            day?.timeTable.map((time, index) => {
              if (time.tuitionId === selectedTuitionInfo?._id) {
                time.isBooked = false;
                time.tuitionId = "";
              }
            });
          } else {
            toastNotifiy(
              `No free slots on ${day.dayName.toUpperCase()}`,
              "warn"
            );
          }
        }
      });
      setWeekdayList(_prevList);
      handleSelectedList(_prevList); // intially checkbox selection
    } else {
      toastNotifiy("Please choose valid timeslot first", "warn");
    }
  };

  const handleSelectedTimeSlot = (time, dayName, type, changeEnd = false) => {
    var _prevList = [...weekdayList];
    _prevList.map((day) => {
      if (day.dayName === dayName) {
        var freeSlots = day.timeTable?.filter(
          (t) => t.tuitionId === selectedTuitionId || !t.isBooked
        );
        if (type === "start") {
          day.initialStart = time;
          const tempEndTime = new Date(time.getTime() + 1 * 60 * 60 * 1000);
          changeEnd && handleSelectedTimeSlot(new Date(tempEndTime), dayName, "end");
        } else {
          day.initialEnd = time;
        }

        day.isTimeSlotValid = validateTimeSlot(
          day.initialStart,
          day.initialEnd,
          freeSlots
        );
        setTimeValidation(day.isTimeSlotValid);

        let start = moment(day.initialStart).format("HH:mm A");
        let end = moment(day.initialEnd).format("HH:mm A");
        if (start.slice(6, 8) === "PM" && end.slice(6, 8) === "AM") {
          const nextDay = day.initialEnd.setDate(day.initialEnd.getDate() + 1);
          getDuration(day.initialStart, nextDay, day);
        } else {
          getDuration(day.initialStart, day.initialEnd, day);
        }
      }
    });
    setWeekdayList(_prevList);

    // when select a time slot, creating new List for storing in DB
    handleSelectedList(_prevList);
  };

  const handleSelectedList = (_prevList) => {
    let _selectedList = [];
    if (_prevList?.length > 0) {
      _prevList.map((day) => {
        if (day.isChecked) {
          if (day.isTimeSlotValid) {
            _selectedList.push({
              dayName: day.dayName,
              start: day.initialStart,
              end: day.initialEnd,
              duration: day.timeDiff,
            });
          }
        }
      });
      setSelectedDayList(_selectedList);
    }
  };

  const removeExisting = (_selectedList, tuitionId) => {
    var _prevWeekDayList = [...tutorOldSchedule];
    var _prevSelectedList = [...oldSelectedDayList];
    _prevWeekDayList?.map((w) => {
      _prevSelectedList?.map((s) => {
        if (s.dayName === w.dayName) {
          w.timeTable.map((time) => {
            if (time.tuitionId === tuitionId) {
              time.isBooked = false;
              time.tuitionId = "";
            }
          });
        }
      });
    });
    return _prevWeekDayList;
  };

  // --- change on save change button click ---
  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    // validating to check no field is empty
    const validateDate = validateTuitionData(
      selectedTuition,
      budget,
      tuitionPlace,
      selectedDayList,
      timeValidation
    );
    // executes if data is validated
    if (validateDate) {
      if (rescheduledClasses) {
        var rescheduleContent = await getRescheduleContent(rescheduledClasses, tuitionStartDate, tuitionEndDate, selectedDayList);
        var { text, stringList } = rescheduleContent;
      }
      const classString = stringList?.length > 1 ? "classes" : "class";
      const dayString = stringList?.length > 1 ? "days" : "day";
      const article = stringList?.length === 1 ? "a" : "";
      const warningText = stringList?.length > 0 ? `You have ${article} rescheduled ${classString} on <p><b>${text}</b>.</p>
      So, no ${classString} will be created for this tuition on the mentioned ${dayString}.` : "";
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
        title: "Are you sure to save the changes?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
        html: warningText,
        width: "500px"
      }).then(async (result) => {
        if (result.value) {
          // update the tuition details when clicked on yes
          await updateTuitionSchedule();
          SweetAlert.fire("Tuition details have been updated successfully!", "", "success");
        } else {
        }
      });
    }
  };

  const handleAvailableTime = (item, start, end) => {
    handleSelectedTimeSlot(new Date(start), item.dayName, "start");
    handleSelectedTimeSlot(new Date(end), item.dayName, "end");
  };

  return (
    <Fragment>
      <Modal
        isOpen={modalOpen}
        toggle={toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-lg"
        backdrop={"static"}
      >
        <TuitionModalHeader
          title={title}
          toggler={toggler}
        ></TuitionModalHeader>
        <ModalBody className={"pb-0 "}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm py-1">
                <Row>
                  <Col sm="12" xl="12">
                    <Row className="mb-3">
                      <Col md={2}>
                        <Label className="col-form-label" htmlFor="">
                          Tuition
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          styles={{
                            //
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              color: isFocused ? "#6f6f6f" : "black",
                              cursor: "pointer",
                            }),
                          }}
                          options={myTuitions}
                          getOptionLabel={(e) => (
                            <div className="d-flex align-items-center">
                              <Badges
                                attrBadge={{
                                  color:
                                    e.status === "CONFIRMED"
                                      ? "success"
                                      : e.status === "OPEN"
                                        ? "info"
                                        : e.status === "DECLINED"
                                          ? "warning"
                                          : e.status === "CLOSE_REQUEST"
                                            ? "danger"
                                            : "light-1",
                                }}
                              >
                                {e.status}
                              </Badges>
                              <span className="m-l-5">{e.label}</span>
                            </div>
                          )}
                          placeholder="Select Subject"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setSelectedTuition(item)}
                          // onChange={(item) => handleSubjects(item)}
                          value={selectedTuition}
                          isClearable={true}
                          bsSize="lg"
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col md={2}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Tuition Fee
                        </Label>
                      </Col>
                      <Col lg={4} md={5}>
                        <div style={{ display: "flex", placeItems: "center" }}>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Fee"
                            style={{ fontSize: "13px" }}
                            value={budget}
                            onChange={(e) => {
                              e.target.value
                                ?.replace(/[^\d.]/g, "")
                                ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] >=
                                0 &&
                                e.target.value
                                  ?.replace(/[^\d.]/g, "")
                                  ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] <=
                                1000000 &&
                                setBudget(
                                  e.target.value
                                    ?.replace(/[^\d.]/g, "")
                                    ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
                                );
                            }}
                            bsSize="lg"
                            disabled={
                              tuitionStatus === "CONFIRMED" ||
                              tuitionStatus === "CLOSE_REQUEST"
                            }
                          />
                          <div>
                            <span className="px-2">/Class</span>
                          </div>
                        </div>
                        {showErrorMessage && !isValidBudget ? (
                          <div className="text-danger">
                            {budgetErrorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col
                        lg={2}
                        md={4}
                        className={`py-1 ${currency !== null ? "" : "d-none"}`}
                      >
                        <div
                          className={`"border"`}
                          style={{
                            borderRadius: "5px",
                            background: "#e6e6e659",
                            lineHeight: "13px",
                            paddingTop: "3px",
                          }}
                        >
                          <span>USD</span>
                          <p className="mb-0" style={{ fontSize: "10px" }}>
                            United States Dollar
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={2} md={2}></Col>
                      {/* <Col lg={4} md={6}>
                        {
                          parseFloat(budget) < 0.5 ? 
                          <p className="mb-0 font-warning" style={{ fontSize: "10px" }}>
                            Minimum tuition fee is 0.5$
                          </p>
                          :
                          <p className="mb-0" style={{ fontSize: "10px" }}>
                            *20% of the income will go to TuteAir
                          </p>
                        }
                      </Col> */}
                    </Row>
                    <Row className="align-items-center mb-3">
                      <Col md={2}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Location
                        </Label>
                      </Col>
                      <Col sm="8">
                        <TuitionPlace
                          handlePreferredPlace={handlePreferredPlace}
                          disabled={
                            tuitionStatus === "CONFIRMED" ||
                            tuitionStatus === "CLOSE_REQUEST"
                          }
                          tuitionPlace={tuitionPlace}
                        ></TuitionPlace>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <TuitionDate
                        tuitionStartDate={tuitionStartDate}
                        handleStartDate={handleStartDate}
                        tuitionEndDate={tuitionEndDate}
                        handleEndDate={handleEndDate}
                        today={today}
                        tomorrow={tomorrow}
                        disabled={
                          tuitionStatus === "CONFIRMED" ||
                          tuitionStatus === "CLOSE_REQUEST"
                        }
                      ></TuitionDate>
                    </Row>
                    <div className="form-group row mb-3">
                      <Col lg={2}>Schedule</Col>
                      <Col lg={10}>
                        <div
                          className="btn-group btn-group-toggle"
                          style={{ zIndex: "0" }}
                        >
                          {weekdayList?.length > 0 ? (
                            weekdayList.map((item, id) => (
                              <label
                                htmlFor={`${id}-${item.dayName}`}
                                key={id}
                                style={{ padding: width <= 500 ? "5px" : "10px 15px" }}
                                className={`btn btn-warning ${item.isChecked ? "active" : ""
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  id={`${id}-${item.dayName}`}
                                  name={item.dayName}
                                  className="btn btn-sm m-r-5"
                                  value={item.dayName}
                                  checked={item.isChecked}
                                  onChange={
                                    tuitionStatus === "CONFIRMED" ||
                                      tuitionStatus === "CLOSE_REQUEST"
                                      ? handleNotify
                                      : (e) => {
                                        handleWeeklyDayChange(e);
                                      }
                                  }
                                  readOnly={
                                    tuitionStatus === "CONFIRMED" ||
                                    tuitionStatus === "CLOSE_REQUEST"
                                  }
                                />
                                {item.dayName?.toLocaleUpperCase()}
                              </label>
                            ))
                          ) : (
                            <>
                              <h5>No Schedule created at your profile yet!</h5>
                            </>
                          )}
                        </div>
                        <div>
                          <Link
                            to={`${process.env.PUBLIC_URL}/tutor-account`}
                            state={{ to: "3" }}
                          >
                            Click Here To Update Your Schedule
                          </Link>
                        </div>
                      </Col>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col sm={2}></Col>
                        <Col lg={8} className="px-4">
                          {weekdayList.map(
                            (item, index) =>
                              item.isChecked && (
                                <Row
                                  key={index}
                                  className={` weekly_schdule_box ${!item?.hasOwnProperty("isTimeSlotValid")
                                    ? ""
                                    : item.isTimeSlotValid
                                      ? "bg-success"
                                      : "bg-danger"
                                    }`}
                                >
                                  <div className="align-items-center d-flex">
                                    <Label
                                      className="m-r-5"
                                      for="exampleEmail2"
                                      sm={2}
                                    >
                                      <b> {item.dayName.toUpperCase()}</b>
                                    </Label>
                                    <Col xl="3" sm="3" lg="3">
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={new Date(item.initialStart)}
                                        onChange={(sTime) => {
                                          handleSelectedTimeSlot(
                                            sTime,
                                            item.dayName,
                                            "start",
                                            true
                                          );
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Start"
                                        timeFormat="h:mm aa"
                                        dateFormat="h:mm aa"
                                        disabled={
                                          tuitionStatus === "CONFIRMED" ||
                                          tuitionStatus === "CLOSE_REQUEST"
                                        }
                                      />
                                    </Col>
                                    <Label
                                      for="exampleEmail2"
                                      className="text-center"
                                      sm={1}
                                    >
                                      <ArrowRight />
                                    </Label>
                                    <Col xl="3" sm="3" lg="3">
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={new Date(item.initialEnd)}
                                        onChange={(eTime) => {
                                          handleSelectedTimeSlot(
                                            eTime,
                                            item.dayName,
                                            "end"
                                          );
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Start"
                                        timeFormat="h:mm aa"
                                        dateFormat="h:mm aa"
                                        minTime={new Date(item?.initialStart)}
                                        maxTime={
                                          new Date("2022-11-01T17:59:00.000Z")
                                        }
                                        disabled={
                                          tuitionStatus === "CONFIRMED" ||
                                          tuitionStatus === "CLOSE_REQUEST"
                                        }
                                      />
                                    </Col>
                                    <Label
                                      className="text-center m-l-5"
                                      for="exampleEmail2"
                                      sm={2}
                                    >
                                      <b> {`(${item.timeDiff})`}</b>
                                    </Label>
                                  </div>
                                  <Col md={12}>
                                    <Row className="py-2">
                                      <Col md={2}></Col>
                                      <Col md={9}>
                                        {tuitionStatus !== "CONFIRMED" ||
                                          (tuitionStatus ===
                                            "CLOSE_REQUEST" && (
                                              <TutorScheduleDisplay
                                                item={item}
                                                handleAvailableTime={
                                                  handleAvailableTime
                                                }
                                                availableSlots={item?.timeTable
                                                  .filter(
                                                    (time) =>
                                                      !time.isBooked ||
                                                      time.tuitionId ===
                                                      selectedTuitionId
                                                  )
                                                  .sort((a, b) => {
                                                    const startTimeA = new Date(
                                                      `1970/01/01 ${moment(
                                                        a.start
                                                      ).format("HH:mm")}`
                                                    );
                                                    const startTimeB = new Date(
                                                      `1970/01/01 ${moment(
                                                        b.start
                                                      ).format("HH:mm")}`
                                                    );
                                                    return (
                                                      startTimeA - startTimeB
                                                    );
                                                  })}
                                                bookedSlots={item?.timeTable
                                                  .filter(
                                                    (time) =>
                                                      time.isBooked &&
                                                      time.tuitionId !==
                                                      selectedTuitionId
                                                  )
                                                  .sort((a, b) => {
                                                    const startTimeA = new Date(
                                                      `1970/01/01 ${moment(
                                                        a.start
                                                      ).format("HH:mm")}`
                                                    );
                                                    const startTimeB = new Date(
                                                      `1970/01/01 ${moment(
                                                        b.start
                                                      ).format("HH:mm")}`
                                                    );
                                                    return (
                                                      startTimeA - startTimeB
                                                    );
                                                  })}
                                              ></TutorScheduleDisplay>
                                            ))}
                                      </Col>
                                    </Row>

                                    {!item?.hasOwnProperty(
                                      "isTimeSlotValid"
                                    ) ? (
                                      ""
                                    ) : item.isTimeSlotValid ? (
                                      ""
                                    ) : (
                                      <>
                                        <Row className="py-2">
                                          <Col md={2}></Col>
                                          <Col
                                            md={10}
                                            style={{
                                              background: "#cd4701",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            Please Select a time slot from your
                                            available schedule.
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              )
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          {tuitionStatus !== "CONFIRMED" ||
            (tuitionStatus === "CLOSE_REQUEST" && (
              <Btn
                attrBtn={{
                  color: "success",
                  // disabled: loading,
                  onClick: (e) => handleSaveChanges(e),
                }}
              >
                {/* {loading ? "LOADING..." : "Save changes"} */}
                {"Save Changes"}
              </Btn>
            ))}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AllTuitionsModal;
