import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "../_profile.css";

function ShareProfileModal({ open, userId }) {
  const profileUrl = `${process.env.REACT_APP_DASHBOARD}/profile/${userId}`;

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div
      className="share-modal"
      style={{
        display: open ? "block" : "none",
        width: "-webkit-fill-available",
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
        }}
      >
        <div className="icon-container ms-3 ">
          <div className="social-icons mt-5 mb-3">
            <div className="icon-row mb-4">
              <div
                className="icon-container"
                data-tip={copied ? "Copied!" : "Copy to Clipboard"}
              >
                <CopyToClipboard text={profileUrl} onCopy={handleCopy}>
                  <FontAwesomeIcon icon={faCopy} className="icon" />
                </CopyToClipboard>
              </div>
              <div className="icon-container" data-tip="Share on Facebook">
                <FacebookShareButton url={profileUrl}>
                  <FacebookIcon size={40} round className="icon" />
                </FacebookShareButton>
              </div>
              <div className="icon-container" data-tip="Share on WhatsApp">
                <WhatsappShareButton url={profileUrl}>
                  <WhatsappIcon size={40} round className="icon" />
                </WhatsappShareButton>
              </div>
            </div>
            <div className="icon-row">
              <div className="icon-container" data-tip="Share via Email">
                <EmailShareButton url={profileUrl}>
                  <EmailIcon size={40} round className="icon" />
                </EmailShareButton>
              </div>
              <div className="icon-container" data-tip="Share on LinkedIn">
                <LinkedinShareButton url={profileUrl}>
                  <LinkedinIcon size={40} round className="icon" />
                </LinkedinShareButton>
              </div>
              <div className="icon-container" data-tip="Share on Twitter">
                <TwitterShareButton url={profileUrl}>
                  <TwitterIcon size={40} round className="icon" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareProfileModal;
