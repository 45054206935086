import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ReviewModal from "../../Review/ReviewModal";

const NewStudentTuitionsContain = () => {
  const [isAddReview, setIsAddReview] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [singleTuition, setSingleTuition] = useState({});
  const [title, setTitle] = useState("");
  const [tuitionStatus, setTuitionStatus] = useState("ALL");
  const [stTuitions, setStTuitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, photo } = useSelector((state) => state.auth) || {};

  useEffect(() => {
    getStTuitions();
    return () => {
      setStTuitions([]);
    };
  }, []);

  const getStTuitions = async () => {
    setIsLoading(true);
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/student",
      method: "get",
    });
    if (res.data) {
      setStTuitions(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleTuitionsAddReview = (e, tuitionId) => {
    e.preventDefault();
    const singleTuition = stTuitions?.find((t) => t.tuitionId === tuitionId);
    setSingleTuition(singleTuition);
    setTitle("Give a review");
    setIsAddReview(true);
    setModal(true);
  };

  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/tutor-tuitions`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h6>Tuitions of Student</h6>
                <span style={{ fontSize: "14px" }}>
                  {"To give review on specific tuitions status must be closed, click on "}{" "}
                  <strong> {"Review"} </strong>{" "}
                  {"& give opinion about your tutor"}
                </span>
                {modal && (
                  <ReviewModal
                    title={title}
                    isOpen={modal}
                    toggler={toggle}
                    isAddReview={isAddReview}
                    isStudent={true}
                    isTutor={false}
                    data={singleTuition}
                  />
                )}
              </CardHeader>
              <div className="table-responsive-sm">
                <Table hover className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }} scope="col">
                        {"#"}
                      </th>
                      <th
                        style={{ width: "25%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        {"Tuition Subjects"}
                      </th>
                      <th
                        style={{ width: "15%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        {"Tutor Name"}
                      </th>
                      <th
                        style={{ width: "15%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        {"Tuition Fee"}
                      </th>
                      <th
                        style={{ width: "18%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        <div className="row align-items-center">
                          <div className="col-md-4 px-0">{"Status"}</div>
                          <div className="col-md-8">
                            <Input
                              style={{ height: "35px" }}
                              type="select"
                              name="select"
                              className="form-control"
                              value={tuitionStatus}
                              onChange={(e) => setTuitionStatus(e.target.value)}
                            >
                              <option value={""} disabled>
                                Select Status
                              </option>
                              <option value={"ALL"}>ALL</option>
                              <option value={"OPEN"}>Open</option>
                              <option value={"CONFIRMED"}>Confirmed</option>
                              <option value={"CANCELED"}>Canceled</option>
                              <option value={"CLOSED"}>Closed</option>
                            </Input>
                          </div>
                        </div>
                      </th>
                      <th style={{ width: "10%" }} scope="col">
                        {""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr className="text-center">
                        <td colSpan={"6"} rowSpan="3">
                          Loading...
                        </td>
                      </tr>
                    )}
                    {tuitionStatus === "ALL" &&
                      stTuitions?.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">
                            <span className="badge bg-light">{i + 1}</span>
                          </th>
                          <td>
                            {item.tuitionRequest?.subjects?.length > 0
                              ? item.tuitionRequest?.subjects?.map(
                                  (item, index) => (
                                    <span
                                      className="badge bg-light"
                                      style={{ margin: "0px 3px 2px 0px" }}
                                      key={index}
                                    >
                                      {item.label}
                                    </span>
                                  )
                                )
                              : item.interestedSubjects?.subjects?.map(
                                  (item, index) => (
                                    <span
                                      className="badge bg-light"
                                      style={{ margin: "0px 0px 0px 0px" }}
                                      key={index}
                                    >
                                      {item.name}
                                    </span>
                                  )
                                )}
                          </td>
                          <td>
                            <span className="badge bg-light">
                              {item.tutorName}
                            </span>
                            {item.isTutorDeactivated
                              ? " (Account Deactivated)"
                              : ""}
                            {item.isTutorDeleted ? " (Account Deleted)" : ""}
                          </td>
                          <td>
                            <span
                              className="badge bg-light"
                              style={{ margin: "0px 0px 0px 10px" }}
                            >
                              {item.confirmedTuitionFee}
                            </span>
                          </td>
                          <td className="p-3">
                            <span className="badge badge-light-dark">
                              {item.status}
                            </span>
                          </td>
                          <td className="px-1">
                            {item.status === "CLOSED" &&
                              !(
                                item.isTutorDeactivated || item.isTutorDeleted
                              ) && (
                                <button
                                  className="btn px-1 py-0 btn-sm btn-warning"
                                  onClick={(e) =>
                                    handleTuitionsAddReview(e, item.tuitionId)
                                  }
                                >
                                  <span>
                                    {" "}
                                    <i className="fa fa-comment"></i> Review
                                  </span>
                                </button>
                              )}
                          </td>
                        </tr>
                      ))}
                    {stTuitions?.map((item, i) =>
                      item.status === tuitionStatus ? (
                        <tr key={i}>
                          <th scope="row">
                            <span className="badge bg-light">{i + 1}</span>
                          </th>
                          <td>
                            <div>
                              {item.interestedSubjects?.subjects?.map(
                                (item, index) => (
                                  <span
                                    className="badge bg-light"
                                    style={{ margin: "0px 3px 2px 0px" }}
                                    key={index}
                                  >
                                    {item.name}
                                  </span>
                                )
                              )}
                            </div>
                          </td>
                          <td>
                            <span className="badge bg-light">
                              {item.studentName}
                            </span>

                            {item.isTutorDeactivated
                              ? " (Account Deactivated)"
                              : ""}
                            {item.isTutorDeleted ? " (Account Deleted)" : ""}
                          </td>
                          <span
                            className="badge bg-light"
                            style={{ margin: "0px 0px 0px 10px" }}
                          >
                            {item.confirmedTuitionFee}
                          </span>
                          <td className="p-3">
                            <span className="badge badge-light-dark">
                              {item.status}
                            </span>
                          </td>
                          <td className="px-1">
                            {item.status === "CLOSED" &&
                              !(
                                item.isTutorDeactivated || item.isTutorDeleted
                              ) && (
                                <button
                                  className="btn px-1 py-0 btn-sm btn-warning"
                                  onClick={(e) =>
                                    handleTuitionsAddReview(e, item.tuitionId)
                                  }
                                >
                                  <span>
                                    {" "}
                                    <i className="fa fa-comment"></i> Review
                                  </span>
                                </button>
                              )}
                          </td>
                        </tr>
                      ) : null
                    )}
                    {stTuitions?.length === 0 && !isLoading && (
                      <tr className="text-center">
                        <td colSpan={"6"} rowSpan="3">
                          No Tuitions Found.Change Status Or Request Tutor Via
                          Chatbox.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewStudentTuitionsContain;
