import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Clock, MapPin } from "react-feather";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  UncontrolledAccordion,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  CardFooter,
} from "reactstrap";

import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import PulseLoader from "react-spinners/PulseLoader";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import noRequestTuitionImage from "../../../assets/images/request-tuition/requestTuitionNoData.avif";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TuitionRequestContext from "../../../_helper/TuitionRequest";
import ContactRequestModal from "../../Chat/NewChat/ContactRequestModal";
import FilterOptionsContain from "./FilterOptions";
import {
  filterByTutorName,
  filterByAge,
  filterByLanguage,
  filterByPrice,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
  filterByEducation,
  filterByWorkExperience,
} from "../CommonFilter.jsx";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import TuitionFeesFilter from "../../FindTutors/ListView/TuitionFeesFilter";
import TutorLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";
import EducationFilter from "../../FindTutors/ListView/EducationFilter";
import WorkExperienceFilter from "../../FindTutors/ListView/WorkExperienceFilter";

export default function ListView() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const initilindex = { index: 0, isOpen: false, toggler: toggle };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const {
    name,
    age,
    gender,
    languages,
    profilePicExist,
    location,
    tutionType,
    subject,
    subject_category_filter,
    subject_subcategory_filter,
    priceFilter,
    educationFilter,
    workExperiencesFilter,
  } = useContext(FilterContext);

  const {
    tuitionRequestList: tutors,
    setTuitionRequestList,
    GetAllTuitionRequest,
    tuitionRequestToShow,
    setTuitionRequestToShow,
  } = useContext(TuitionRequestContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShowless] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredStudents = tuitionRequestToShow?.filter(
    (x) =>
      x.studentId &&
      x.studentId !== userId &&
      x.memberType !== "tutor" &&
      !x.student.isAccountDeactive &&
      !x.student.isAccountDelete &&
      !x.student.isAccountHide
  );
  const showTuitionRequestStudents = allFilteredStudents.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredStudents.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  function toggleIsShow() {
    setIsShowless(!isShow);
  }

  const fetchFilteredTutors = async () => {
    // setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here

    if (filters.name) {
      filteredTutors = await filterByTutorName(filteredTutors, filters.name);
    }

    if (filters.age) {
      filteredTutors = await filterByAge(filteredTutors, filters.age);
    }

    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor?.gender?.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.student?.media?.picture?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.location?.country?.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.location?.country?.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.location?.city?.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.location?.country?.toLowerCase() ==
                filters.location.country?.toLowerCase() &&
              tutor.location?.postalCode?.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }

    if (filters.educationFilter) {
      filteredTutors = await filterByEducation(
        filteredTutors,
        filters.educationFilter
      );
    }
    if (filters.workExperiencesFilter) {
      filteredTutors = await filterByWorkExperience(
        filteredTutors,
        filters.workExperiencesFilter
      );
    }

    // filtering ends here
    setTuitionRequestToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    GetAllTuitionRequest();
    setLoading(false);
  }, []);

  const updateFilter = () => {
    setIsReset(false);

    const addedFilter = {};

    if (name != " ") {
      addedFilter.name = name;
    }

    if (age.length != 0) {
      addedFilter.age = age;
    }

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(priceFilter).length) {
      addedFilter.priceFilter = priceFilter;
    }
    if (Object.keys(educationFilter).length) {
      addedFilter.educationFilter = educationFilter;
    }
    if (Object.keys(workExperiencesFilter).length) {
      addedFilter.workExperiencesFilter = workExperiencesFilter;
    }
    setFilters(addedFilter);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTuitionRequest();
  };

  const handleStartConversationModal = (e, tuition) => {
    e.preventDefault();
    setPhotoIndex({
      ...photoIndex,
      isOpen: true,
      userId: tuition.studentId,
      name:
        tuition.student?.name?.firstName +
        " " +
        tuition.student?.name?.lastName,
      tuitions: tuition,
    });
    setModal(true);
  };
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8}>
            {loading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {allFilteredStudents?.length} matched tuition request list based
                on your search criteria
              </CardTitle>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>
                {showTuitionRequestStudents?.map((tutor, key) => (
                  <Card className="mb-2" key={key}>
                    <CardBody className="justify-content-between">
                      <div className="row">
                        <div className="d-flex align-items-center col-md-9">
                          <CardImg
                            style={{
                              width: "120px",
                              height: "120px",
                              // cursor: "pointer",
                            }}
                            className="rounded-circle me-4"
                            src={
                              tutor.student?.media
                                ? ` ${
                                    ProfilePictureRootURL +
                                    tutor.student?.media?.picture
                                  }`
                                : man
                            }
                            alt={tutor.student?.name?.firstName}
                          />
                          <div>
                            <CardTitle
                              tag="h3"
                              className="cardBox-2"
                              // onClick={() =>
                              //   window.open(
                              //     `/student-profile/${tutor.studentId}`,
                              //     "_blank"
                              //   )
                              // }
                            >
                              <div
                                style={{
                                  // cursor: "pointer",
                                  color: "blueviolet",
                                }}
                              >
                                <span className="darkModeTutorName">
                                  {tutor.student?.name?.firstName}{" "}
                                  {tutor.student?.name?.lastName}
                                </span>
                              </div>
                            </CardTitle>

                            <CardText className="mb-0 color-info">
                              {tutor?.subjects?.length > 0 ? (
                                <span
                                  style={{
                                    margin: "0px 3px 2px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  Subjects:{" "}
                                  {tutor.subjects?.map((item, index) => (
                                    <span
                                      className="badge badge-light-primary py-1"
                                      style={{
                                        margin: "0px 3px 2px 0px",
                                        fontSize: "12.5px",
                                      }}
                                      key={index}
                                    >
                                      {item?.name}
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                ""
                              )}
                            </CardText>
                            {/* {tutor?.languages?.length > 0 ? (
                              <span
                                style={{
                                  margin: "0px 3px 2px 3px",
                                  fontSize: "12.5px",
                                }}
                              >
                                Preferred Language:{" "}
                                {tutor.languages?.map((item, index) => (
                                  <span
                                    className="badge badge-light-primary py-1"
                                    style={{
                                      margin: "0px 3px 2px 0px",
                                      fontSize: "12.5px",
                                    }}
                                    key={index}
                                  >
                                    {item}
                                  </span>
                                ))}
                              </span>
                            ) : (
                              ""
                            )} */}

                            <CardText className="mb-0">
                              {tutor?.tuitionTypes?.length > 0 ? (
                                <span
                                  style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  Tuition Place:{" "}
                                  {tutor.tuitionTypes?.map((item, index) => (
                                    <span
                                      className="badge badge-light-primary py-1"
                                      style={{
                                        margin: "0px 3px 2px 0px",
                                        fontSize: "12.5px",
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                ""
                              )}
                            </CardText>
                            <CardText className="mb-0">
                              {tutor?.gender && tutor?.gender !== "0" ? (
                                <span
                                  style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  Gender:{" "}
                                  <span
                                    style={{
                                      margin: "0px 3px 2px 0px",
                                      fontSize: "12.5px",
                                    }}
                                    className="badge badge-light-primary py-1"
                                  >
                                    {tutor.gender}
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </CardText>
                            {/* <CardText>
                              {tutor?.location ? (
                                <span
                                  style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  Preferred Location:{" "}
                                  <span
                                    style={{
                                      margin: "0px 3px 2px 0px",
                                      fontSize: "12.5px",
                                    }}
                                    className="badge badge-light-primary py-1"
                                  >
                                    {tutor.location?.country}{" "}
                                    {tutor.location?.city}{" "}
                                    {tutor.location?.postalCode}
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </CardText> */}

                            <CardText>
                              {tutor?.languages?.length > 0 ? (
                                <span
                                  style={{
                                    margin: "0px 3px 2px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  Preferred Language :
                                  {tutor.languages?.map((item, index) => (
                                    <span
                                      className="badge badge-light-primary py-1"
                                      style={{
                                        margin: "0px 3px 0px 0px",
                                        fontSize: "12.5px",
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                ""
                              )}

                              <span>
                                <br />
                                {tutor?.education ? (
                                  <span>
                                    <span
                                      style={{
                                        margin: "0px 3px 0px 3px",
                                        fontSize: "12.5px",
                                      }}
                                    >
                                      Education
                                      <br />
                                      <span
                                        style={{
                                          margin: "0px 3px 2px 0px",
                                          fontSize: "12.5px",
                                        }}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {tutor?.education.degreeName}
                                      </span>
                                      <span
                                        style={{
                                          margin: "0px 3px 0px 3px",
                                          fontSize: "12.5px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            margin: "0px 3px 2px 0px",
                                            fontSize: "12.5px",
                                          }}
                                          className="badge badge-light-primary py-1"
                                        >
                                          {tutor?.education.courseName}
                                        </span>
                                      </span>
                                      <span
                                        style={{
                                          margin: "0px 3px 0px 3px",
                                          fontSize: "12.5px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            margin: "0px 3px 2px 0px",
                                            fontSize: "12.5px",
                                          }}
                                          className="badge badge-light-primary py-1"
                                        >
                                          {tutor?.education.institute}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <br />
                                {tutor?.workExperience ? (
                                  <span>
                                    <span
                                      style={{
                                        margin: "0px 3px 0px 3px",
                                        fontSize: "12.5px",
                                      }}
                                    >
                                      Work Experience
                                      <br />
                                      <span
                                        style={{
                                          margin: "0px 3px 2px 0px",
                                          fontSize: "12.5px",
                                        }}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {tutor?.workExperience.expYear}
                                      </span>
                                      <span
                                        style={{
                                          margin: "0px 3px 0px 3px",
                                          fontSize: "12.5px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            margin: "0px 3px 2px 0px",
                                            fontSize: "12.5px",
                                          }}
                                          className="badge badge-light-primary py-1"
                                        >
                                          {tutor?.workExperience.org}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </CardText>

                            <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="my-anchor-css-class"
                              onClick={toggleIsShow}
                              expanded={false}
                            >
                              <span>
                                {tutor?.requestMessage ? (
                                  <span>
                                    Message: <span>{tutor.requestMessage}</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </ShowMoreText>
                          </div>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ textAlignLast: "right" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-warning text-white ms-1 p-2"
                            disabled={false}
                            onClick={(e) =>
                              handleStartConversationModal(e, tutor)
                            }
                          >
                            Contact Student
                          </button>
                        </div>

                        <CardFooter
                          className="d-flex justify-content-around"
                          // style={{ textAlignLast: "right" }}
                        >
                          <div>
                            {tutor?.location ? (
                              <span>
                                <span>
                                  <MapPin height={"12px"} />
                                </span>
                                <span
                                  style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}
                                >
                                  <span
                                    style={{
                                      margin: "0px 3px 2px 0px",
                                      fontSize: "12.5px",
                                    }}
                                    // className="badge badge-light-primary py-1"
                                  >
                                    {tutor.location?.country}{" "}
                                    {tutor.location?.city}{" "}
                                    {tutor.location?.postalCode}
                                  </span>
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            <Clock
                              style={{
                                height: "17px",
                                verticalAlign: "text-top",
                              }}
                            />
                            <span style={{ verticalAlign: "top" }}>
                              Post Time {moment(tutor.createdAt).fromNow()}
                            </span>
                          </div>
                        </CardFooter>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </span>
            )}
            {showTuitionRequestStudents.length == 0 ? (
              <span className="mt-3">
                <h1
                  className="m-0"
                  style={{
                    fontFamily: "Fredoka One, cursive",
                    fontSize: "42px",
                    fontWeight: "bolder",
                    letterSpacing: "1px",
                    color: "#ff686b",
                    textAlign: "center",
                  }}
                >
                  No List Found
                </h1>
                <CardImg
                  style={{
                    width: "100%",
                    height: "55vh",
                  }}
                  alt="Card image cap"
                  src={noRequestTuitionImage}
                />
              </span>
            ) : (
              ""
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            {/* <div>
              <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
            </div> */}

            <div>
              <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                <AccordionItem>
                  <AccordionHeader targetId="1">Basic</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <BasicFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Subjects</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <SubjectsFilter setFilters={setFilters} isReset={isReset} />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Tutor's Location
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <TutorLocationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter
                      setFilters={setFilters}
                      isReset={isReset}
                    />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>
              {parseFloat(priceFilter.min) > parseFloat(priceFilter.max) ? (
                <Button
                  disabled
                  color="primary"
                  className="mb-3 "
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={(e) => updateFilter(0)}
                >
                  Apply Filter
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {allFilteredStudents?.length !== 0 && (
          <div className="mt-5 mb-5 pagination">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              //disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </Col>
      {modal && (
        <ContactRequestModal photoIndex={photoIndex} setPhotoIndex={callback} />
      )}
    </Fragment>
  );
}
