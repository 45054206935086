import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { axiosRequest } from "../../../redux/utils/axios-utils";

import AddReplyContain from "../AddReply";
import AddReviewContain from "../AddReview";

const AddReviewStudentContainer = (props) => {
  const [tutorReview, setTutorReview] = useState([]);
  const [myReview, setMyReview] = useState([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const [ratings, setRatings] = useState({});

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const { user: fromUserId } = useSelector((state) => state.auth);

  const getReviews = async () => {
    var res = await axiosRequest({
      url: "/newTuition/getReviews/" + props.data.tuitionId,
      method: "get",
    });
    if (res.data) {
      setRatings(res.data[0].ratings);
      var tutorReview1 = res.data[0]?.reviews?.filter(
        (t) =>
          (t.fromUserId === props.data.tutorId && t.parentReviewId === null) ||
          (t.fromUserId === fromUserId && t.parentReviewId !== null)
      );

      var myReview1 = res.data[0]?.reviews?.filter(
        (t) =>
          (t.fromUserId === fromUserId && t.parentReviewId === null) ||
          (t.fromUserId === props.data.tutorId && t.parentReviewId !== null)
      );
      setTutorReview(tutorReview1);
      setMyReview(myReview1);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <Fragment>
      <ModalHeader toggle={props.toggler}>
        {props.title} to {props.data.tutorName}
      </ModalHeader>
      <ModalBody className={"pb-0 " + props.bodyClass}>
        <Col sm="12" xl="12 box-col-12">
          <Card className="mb-0">
            <CardBody className="megaoptions-border-space-sm py-1">
              <AddReplyContain
                data={tutorReview}
                name={props.data.tutorName}
                stThumb={props.data.studentThumb}
                trThumb={props.data.tutorThumb}
                fromUserId={fromUserId}
                tutorId={props.data.tutorId}
                tuitionId={props.data.tuitionId}
                isTutor={false}
                isStudent={true}
                ratings={ratings}
              />
              <AddReviewContain
                data={myReview}
                name={props.data.tutorName}
                stThumb={props.data.studentThumb}
                trThumb={props.data.tutorThumb}
                fromUserId={fromUserId}
                tutorId={props.data.tutorId}
                tuitionId={props.data.tuitionId}
                isTutor={false}
                isStudent={true}
                ratings={ratings}
              />
            </CardBody>
          </Card>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>Close</Btn>
      </ModalFooter>
    </Fragment>
  );
};
export default AddReviewStudentContainer;
