import React, { Fragment, useState } from "react";
import { Edit } from "react-feather";
import { Button } from "reactstrap";
import FeedbackModal from "./FeedbackModal";
import "./sidebar-layout.css";

const SidebarSection = () => {
  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("Feedback Hub");

  const openFeedbackModal = () => {
    setModal(true);
  };

  return (
    <Fragment>
      <div className="sidebar-img-section sidebar_image">
        <div className="sidebar-img-content bg-default">
          {/* <Image
            // className="mobileViewResImg"
            attrImage={{
              className: "img-fluid mobileViewResImg",
              src: `${sidebar}`,
              alt: "",
            }}
          /> */}
          <h4 className="mb-1 mobileViewResTitle"></h4>
          {/* <Link to={"#"} className="txt">
            We appreciate your valuable suggestions or feedback.
          </Link> */}
          {/* <p className="m-0 mobileViewResSubTitle"> We appreciate your valuable suggestions or feedback.</p> */}
          <Button className="btn btn-secondary w-75 mt-1 mobileViewResBtn" onClick={openFeedbackModal}>
            Give Feedback{" "}
            <span>
              <Edit size={16} />
            </span>
          </Button>

          {modal && <FeedbackModal title={title} isOpen={modal} toggler={toggle} />}
        </div>
      </div>
    </Fragment>
  );
};
export default SidebarSection;
