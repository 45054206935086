import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CalenderViewContain from "./CalenderView";
import ClassListViewContain from "./ClassListView";
import PopUpMessage from "../PopUpMessage";
import ClassSchedulerContext from "../../../_helper/ClassScheduler";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// import CompletedClass from "./CompletedClass/CompletedClass";
// import PaidClass from "./PaidClasses/PaidClasses";
// import UpComingClass from "./UpcomingClasses/UpcomingClasses";
// import { axiosRequest } from "../../../redux/utils/axios-utils";

const CalenderContain = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [VerticalTab, setVerticalTab] = useState("1");
  const { classList } = useContext(ClassSchedulerContext);
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("calendarList");

  // Get the tab from the URL parameter if it exists
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get("tab");
    if (tabFromUrl && (tabFromUrl === "1" || tabFromUrl === "2")) {
      setVerticalTab(tabFromUrl);
    }
  }, [location]);

  const handleTabChange = (tab) => {
    setVerticalTab(tab);
    navigate(`/calendar?tab=${tab}`);
  };

  useEffect(() => {
    setShowPopup(true);
  }, []);

  // Memoize the PopUpMessage component to prevent unnecessary re-renders
  const memoizedPopUpMessage = useMemo(
    () => (
      <PopUpMessage
        classList={classList?.filter(
          (x) =>
            x.isPaid &&
            (memberType === "tutor"
              ? !x.completionStatus.isCompleteByTutor
              : !x.completionStatus.isCompleteByStudent) &&
            // new Date(x.start) <= new Date() &&
            new Date(x.end) <= new Date() && //from now on class complete status will be available after class
            typeof x.cancel !== "object"
        )}
        memberType={memberType}
      />
    ),
    [classList, memberType]
  );

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        {showPopup && memoizedPopUpMessage}
        <Row style={{ alignItems: "center" }}>
          <Col sm="8">
            <Nav className="nav  nav-pills">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "1" ? "active" : ""}
                  onClick={() => handleTabChange("1")}
                >
                  <i className="icofont icofont-ui-calendar"></i>
                  {"Calendar"}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={VerticalTab === "2" ? "active" : ""}
                  onClick={() => handleTabChange("2")}
                >
                  <i className="icofont icofont-listing-number"></i>
                  {"Class List View"}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" xl="12 box-col-12" className="px-0">
            <TabContent activeTab={VerticalTab}>
              <TabPane tabId="1">
                <CalenderViewContain />
              </TabPane>
              <TabPane tabId="2">
                <ClassListViewContain />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CalenderContain;
