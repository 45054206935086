import React, { Fragment } from "react";
import AccountSettingContain from "../../../Component/Account/AccountSettings";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const AccountSettings = () => {
  useLogoutSession();
  return (
    <Fragment>
      {/* <Breadcrumbs parent="AccountSettings" title="Account Settings" /> */}
      {/* <TutorAccountContain /> */}
      <AccountSettingContain />
    </Fragment>
  );
};

export default AccountSettings;
