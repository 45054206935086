import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import NewTutorTuitionsContain from "../../../Component/NewMyTuitions/Tutor";
import useLogoutSession from "../../../_helper/LogoutAfterSessionExpiration/useLogoutSession";

const NewTutorTuitions = () => {
  useLogoutSession();
  return (
    <Fragment>
      <Breadcrumbs parent="MyTuitions" title="" />
      <NewTutorTuitionsContain />
    </Fragment>
  );
};

export default NewTutorTuitions;
