import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { MoreHorizontal } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { H5, LI, P, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { Today } from "../../../Constant";
import {
  useGetLatestStudentsQuery,
  useGetLatestTutorsQuery,
} from "../../../redux/features/member/memberApi";

const RecentlyJoined = (props) => {
  const { memberType } = useSelector((state) => state.menu) || {};
  var userInfo = JSON.parse(localStorage.getItem("tuteAirUser"));
  const [memberList, setMemberList] = useState([]);
  let { data: students } = useGetLatestStudentsQuery();
  let { data: tutors } = useGetLatestTutorsQuery();

  useEffect(() => {
    // getting latest 6 tutors to show in dashboard
    var filterTutors = tutors?.filter((item) => item?.userId != userInfo?.id);
    var filterStudents = students?.filter(
      (item) => item?.userId != userInfo?.id
    );
    if (memberType === "student") {
      if (filterTutors?.length > 5) {
        setMemberList(filterTutors.slice(0, 6));
      } else {
        setMemberList(filterTutors);
      }
    } else {
      if (filterStudents?.length > 5) {
        setMemberList(filterStudents.slice(0, 6));
      } else {
        setMemberList(filterStudents);
      }
    }
  }, [tutors, students, memberType]);

  return (
    <Fragment>
      <Col xl="6" md="6" className={"col-md-6"}>
        <Card className="news-update">
          <CardHeader className="card-no-border">
            <div className="header-top">
              <H5 attrH5={{ className: "m-0" }}>
                Recently Joined
                {/* { memberType === "student"?"Tutor":"Student"} */}
              </H5>{" "}
              <div className="icon-box onhover-dropdown">
                <MoreHorizontal />
                <div className="icon-box-show onhover-show-div d-none">
                  <UL>
                    <LI>
                      <a href="#javacsript">{Today}</a>
                    </LI>
                    <LI>
                      <a href="#javacsript"> Yesterday</a>
                    </LI>
                  </UL>
                </div>
              </div>
            </div>
            <h6>{memberType === "student" ? "Tutor" : "Student"}</h6>
          </CardHeader>
          <CardBody className="pt-3">
            <div className="table-responsive-sm">
              <Table borderless>
                <tbody>
                  {memberList?.map((item, k) => {
                    return (
                      <tr key={k}>
                        <td>
                          <div className="d-flex justify-content-between">
                            <div className="profile-media media">
                              <img
                                src={
                                  item?.media?.profilePicture?.imageInfo
                                    ?.secure_url
                                    ? item?.media?.profilePicture?.imageInfo
                                        ?.secure_url
                                    : item.media.picture
                                    ? ProfilePictureRootURL + item.media.picture
                                    : man
                                }
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "20px",
                                  minWidth: "40px",
                                }}
                                className=""
                                alt=""
                              />
                              <div className="px-2 text-start">
                                <Link
                                  to={
                                    memberType === "student"
                                      ? `${process.env.PUBLIC_URL}/profile/${item.userId}`
                                      : `${process.env.PUBLIC_URL}/student-profile/${item.userId}`
                                  }
                                >
                                  <H5>
                                    {item.name?.firstName +
                                      " " +
                                      item.name?.lastName}{" "}
                                  </H5>
                                </Link>
                                <P>
                                  {memberType === "student" && (
                                    <>
                                      {item.about?.tutorBio?.length > 165 ? (
                                        <span>
                                          {item.about?.tutorBio
                                            .substring(0, 164)
                                            .trim()}
                                          ...
                                        </span>
                                      ) : (
                                        <span>{item.about?.tutorBio}</span>
                                      )}
                                    </>
                                  )}
                                  {memberType === "tutor" && (
                                    <>
                                      {item.about?.studentBio?.length > 165 ? (
                                        <span>
                                          {item.about?.studentBio
                                            .substring(0, 164)
                                            .trim()}
                                          ...
                                        </span>
                                      ) : (
                                        <span>{item.about?.studentBio}</span>
                                      )}
                                    </>
                                  )}
                                </P>
                              </div>
                            </div>
                            <div>
                              <span className="badge badge-light-theme-light font-theme-light">
                                Joined {moment(item.createdAt).fromNow()}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="text-center py-2" colSpan={2}>
                      <Link
                        to={
                          memberType === "student"
                            ? `${process.env.PUBLIC_URL}/find-tutors`
                            : `${process.env.PUBLIC_URL}/find-students`
                        }
                      >
                        Find More
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default RecentlyJoined;
