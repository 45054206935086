import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { toastNotifiy as toastNotify } from "../../../../Services/AuthVerify";
import ClassSchedulerContext from "../../../../_helper/ClassScheduler";
import { useEditConversationMutation, useGetConversationsQuery } from "../../../../redux/features/chat/conversations/conversationsApi";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import { extendMessage, sendNotificationWithMessage } from "./extendTuitionFns";

const ExtendTuition = ({ setIsCancelled, setIsRescheduled, setIsExtended, setTitle, event, toggle, tutorTimeTable = event.tutorTimeTable }) => {
  var activeTimeTableHistory = event?.timeTableHistory.filter((x) => x.isActive);
  const { user } = useSelector((state) => state.auth) || {};

  let { data: conversations } = useGetConversationsQuery(user);

  const selectedConversation = conversations?.find((c) => c.receiverId === event?.tutorId);

  const { getTuitionList, getAllScheduleEvents, setScheduleEventList, filterClassList } = useContext(ClassSchedulerContext);

  const [editConversation, { isSuccess: isEditConversationSuccess, error: err2 }] = useEditConversationMutation();

  const [extendDate, setExtendDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [currentTuition, setCurrentTuition] = useState(null);

  useEffect(() => {
    setTitle("Extend Agreement");
    var dt = new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1]?.classEnd);
    dt.setMonth(dt.getMonth() + 3);
    setExtendDate(dt);
    var min = new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1]?.classEnd);
    min.setDate(dt.getDate());
    setMinDate(min);
    console.log(event);

    setCurrentTuition({
      tuitionStart: new Date(event?.timeTableHistory[0]?.classStart),
      tuitionEnd: new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1]?.classEnd),
    });
  }, []);

  const handleExtendAgreement = () => {
    var htmlContent = `Your current tuition agreement extended to <strong> ${moment(extendDate).format(
      "LL"
    )} </strong> <h5 class='pt-1'>Are you sure to send extended request to tutor?</h5>`;

    SweetAlert.fire({
      title: "Extended Agreement",
      html: htmlContent,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "YES, Send Request",
      cancelButtonText: "Not now",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await fnExtendAgreementToDB();
      }
    });
  };

  const fnExtendAgreementToDB = async () => {
    var dt = new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1]?.classEnd);
    var newStartDate = new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1]?.classEnd);
    newStartDate.setDate(dt.getDate() + 1);
    // let obj = {
    //   startDate: newStartDate,
    //   endDate: extendDate,
    //   repeat: { type: "weekly", interval: 1 },
    //   timeTable: event?.timeTableHistory[event?.timeTableHistory.length - 1]?.timeTable,
    // };
    // let allSchedules = getAllSchedulesByRRule(obj);
    // let extEventList = [];
    // allSchedules?.map((date) => {
    //   var weekDays = getWeeklySchedules(new Date(date), event?.timeTableHistory[event?.timeTableHistory.length - 1]?.timeTable);
    //   extEventList.push({
    //     date: new Date(weekDays.start),
    //     startTime: new Date(weekDays.start),
    //     endTime: new Date(weekDays.end),
    //     isPaid: false,
    //   });
    // });

    // console.log(event.tuitionId, newStartDate, extendDate, event?.timeTableHistory[event?.timeTableHistory.length - 1]?.timeTable);
    if (event.tuitionId) {
      var res = await axiosRequest({
        url: "/tuition/extend-timetable/",
        method: "put",
        data: {
          tuitionId: event.tuitionId,
          classStart: newStartDate.toISOString(),
          classEnd: extendDate.toISOString(),
          repeat: {
            type: "weekly",
            interval: 1,
          },
          timeTable: activeTimeTableHistory[activeTimeTableHistory.length - 1]?.timeTable,
        },
      });

      if (res.status === 200) {
        // getAllScheduleEvents();
        getTuitionList();
        const { title } = event;
        const extendedMessage = extendMessage(new Date(extendDate));
        var message = `A tuition extend request sent for ${title} until ${extendedMessage}. Please confirm extended tuition request.`;
        var notify_title = "A tuition extension request received";
        var sender = event.studentId;
        var receiver = event.tutorId;
        sendNotificationWithMessage(notify_title, sender, receiver, selectedConversation, editConversation, message);
        toastNotify("A tuition extension request has been sent", "success");
        toggle();
      }
    }
  };

  return (
    <>
      <div className="ext_tuition">
        <Row>
          <h3 className="text-center">
            <span style={{ borderBottom: "1px solid #47327c" }}>{event?.title}</span>
          </h3>

          <h3 className="text-center">
            <span>
              <strong>Tuition Fee: </strong> {event?.confirmedTuitionFee} {event?.currency ? "/" + event?.currency : ""}
            </span>
          </h3>
        </Row>
        <Row className="row-align-center">
          <Label for="exampleEmail" size="lg" sm={2}>
            Tutor name
          </Label>
          <Col xl="10" sm="10" lg="10">
            <input type="text" disabled className="form-control" value={event.tutorName} />
          </Col>
        </Row>
        <Row className="row-align-center">
          <Label for="exampleEmail" size="lg" sm={2}>
            Tuition Start
          </Label>
          <Col xl="4" sm="4" lg="4">
            <DatePicker
              className="form-control datetimepicker-input digits"
              selected={new Date(event?.timeTableHistory[0].classStart)}
              disabled
              dateFormat="MMM dd, yyyy"
              includeDates={[]}
            />
          </Col>
          <Label for="exampleEmail" size="lg" sm={2}>
            Tuition End
          </Label>
          <Col xl="4" sm="4" lg="4">
            <DatePicker
              className="form-control datetimepicker-input digits"
              selected={new Date(activeTimeTableHistory[activeTimeTableHistory.length - 1].classEnd)}
              disabled
              dateFormat="MMM dd, yyyy"
              includeDates={[]}
            />
          </Col>
        </Row>
        <Row className="ext_tuition_time">
          <Label for="exampleEmail" size="lg" sm={2}>
            Tuition Time
          </Label>
          <Col md={4}>
            {event?.timeTableHistory[0].timeTable?.map((item) => (
              <>
                <Row className="pb-1">
                  <Col md={4}>
                    <input className="form-control" disabled type="text" value={item.dayName.toUpperCase()} />
                  </Col>
                  <Col md={8}>
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      value={moment(new Date(item.start)).format("LT") + " - " + moment(new Date(item.end)).format("LT")}
                    />
                  </Col>
                </Row>
              </>
            ))}
          </Col>
          <Label for="exampleEmail" size="lg" sm={2}>
            Location
          </Label>
          <Col md={4}>
            <input type="text" disabled className="form-control" value={event.tuitionPlace} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5>
              <strong className="" style={{ paddingRight: "5px" }}>
                N.B:
              </strong>
              <i>
                Your current tuition agreement with <strong> {event.tutorName} </strong> is expiring on{" "}
                <strong>
                  {" "}
                  <strong> {moment(new Date(event?.timeTableHistory[event?.timeTableHistory.length - 1].classEnd)).format("LL")} </strong>{" "}
                </strong>
                . You may like to extend before <strong> {event.tutorName} </strong> get engaged with some other students
              </i>
            </h5>
          </Col>
        </Row>
      </div>
      <Row className="row-align-center py-2">
        <Label for="exampleEmail" className="px-0" size="lg" sm={2}>
          New End Date
        </Label>
        <Col xl="4" sm="4" lg="4">
          <DatePicker
            className="form-control datetimepicker-input digits"
            selected={extendDate}
            minDate={minDate}
            dateFormat="MMM dd, yyyy"
            onChange={(e) => setExtendDate(e)}
          />
        </Col>
      </Row>

      <FormGroup row>
        <Col md={12} className="text-center">
          <button
            onClick={(e) => {
              setIsCancelled(false);
              setIsRescheduled(false);
              setIsExtended(false);
              setTitle("Calendar");
            }}
            className="btn btn-info mx-2"
          >
            Back
          </button>
          <button
            className="btn btn-success"
            disabled={!(extendDate > new Date(event?.timeTableHistory[event?.timeTableHistory.length - 1]?.classEnd))}
            onClick={(e) => {
              handleExtendAgreement(e);
            }}
          >
            Extend Agreement
          </button>
        </Col>
      </FormGroup>
    </>
  );
};

export default ExtendTuition;
