import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Col, Label, Row } from "reactstrap";
import { AddNotification } from "../../../src/Services/Notification.Service";
import { ProfilePictureRootURL } from "../../api";
import { useAddReplyMutation } from "../../redux/features/review/reviewsApi";
import { toastMessage } from "../../redux/utils/tuteairCommon";
import StudentRating from "./StudentRating";
import TutorRating from "./TutorRating";
const AddReplyContain = (props) => {
  const [addReply, { isSuccess, isLoading, mutationResult }] =
    useAddReplyMutation();
  const [stReview, setStReview] = useState({});
  const [myReply, setMyReply] = useState({});
  const [replyText, setReplyText] = useState("");
  const dispatch = useDispatch();

  // const { updatedReplys } = useSelector((state) => state.reviews) || {};

  useEffect(() => {
    if (props.data.length > 0) {
      var stReview1 = props.data?.filter(
        (r) => r.toUserId === props.fromUserId && r.parentReviewId === null
      )[0];
      setStReview(stReview1);
      var myReply = props.data?.filter(
        (r) => r.fromUserId === props.fromUserId && r.parentReviewId !== null
      )[0];
      setMyReply(myReply);
    }
  }, [props.data]);
  const handleReply = (e) => {
    e.preventDefault();
    var toUserId = "";
    if (props.isStudent) toUserId = props.tutorId;
    else if (props.isTutor) toUserId = props.studentId;

    addReply({
      data: {
        fromUserId: props.fromUserId,
        toUserId: toUserId,
        tuitionId: props.tuitionId,
        reviewText: replyText,
        parentReviewId: stReview.reviewId,
      },
    });

    const notifyObj = {
      receiver: toUserId,
      title: "You have got a new reply",
      description: "Someone replied to your review",
      context: process.env.PUBLIC_URL + "/student-tuitions",
      isPublic: false,
    };

    AddNotification(notifyObj);
  };

  useEffect(() => {
    if (isSuccess) {
      setMyReply({
        reviewId: "Ok",
        createdAt: new Date(),
        reviewText: replyText,
      });
      toastMessage("Successfully given review", "success");
      setReplyText("");
    }
  }, [isSuccess]);

  return (
    <Fragment>
      {stReview?.reviewId && (
        <Row
          style={{
            border: "1px solid #8080804d",
            borderRadius: "5px",
            padding: "15px",
            background: "#d3ebff52",
          }}
          className="my-2"
        >
          <Col md="12" className="py-2 row">
            <Col md="1" className="text-start">
              <img
                src={
                  ProfilePictureRootURL +
                  (props.isStudent ? props.trThumb : props.stThumb)
                }
                alt=""
                className="rounded-circle media"
                style={{ borderRadius: "50px", height: "60px" }}
              />
            </Col>
            <Col md="11">
              <div>
                <div className="row">
                  <div className="col-md-8">
                    <Label className="text-bold mb-0">
                      <strong>{props.name} Review</strong>{" "}
                      <label className="py-0 ">
                        {stReview?.reviewId && (
                          <small className="px-0">
                            {moment(stReview.createdAt).format(
                              "Do MMMM YYYY, h:mm a"
                            )}
                            {/* {moment(studentReview.createdAt).fromNow()} */}
                          </small>
                        )}
                      </label>
                    </Label>
                    {!props.isStudent && (
                      <TutorRating rating={props.ratings?.tutorRating} />
                    )}
                    {props.isStudent && (
                      <StudentRating rating={props.ratings?.studentRating} />
                    )}
                  </div>
                  <div className="col-md-4 text-end">
                    <button
                      disabled={myReply?.reviewId || isLoading ? true : false}
                      className={
                        myReply?.reviewId
                          ? "btn px-2 py-1 mb-1 btn-warning text-white d-none" // d-none new add
                          : "btn px-2 py-1 mb-1 btn-success d-none"
                      }
                      onClick={(e) =>
                        !myReply?.reviewId ? handleReply(e) : ""
                      }
                    >
                      <span>
                        <i className="fa fa-reply"></i>
                        {myReply?.reviewId ? " Replied" : " Reply"}
                      </span>
                    </button>
                  </div>
                </div>
                <p>
                  {stReview?.reviewId && <span>{stReview.reviewText}</span>}
                </p>
              </div>
            </Col>
          </Col>
          <Col className="offset-md-1 col-md-11 border-top py-2">
            <div>
              <div className="row">
                <div className="px-5 col-md-12">
                  <Label className="border-bottom">Your Response </Label>
                  <Label className="px-2">
                    {myReply?.reviewId && (
                      <small>
                        {moment(myReply.createdAt).format(
                          "Do MMMM YYYY, h:mm a"
                        )}
                      </small>
                    )}{" "}
                  </Label>
                  {myReply?.reviewId && (
                    <p>
                      <span>{myReply.reviewText}</span>
                    </p>
                  )}

                  {!myReply?.reviewId && (
                    <div>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        rows="3"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                      ></textarea>
                    </div>
                  )}
                  <div className="pt-1" style={{ textAlign: "-webkit-right" }}>
                    <div className="col-md-4 text-end">
                      <button
                        disabled={myReply?.reviewId || isLoading ? true : false}
                        className={
                          myReply?.reviewId
                            ? "btn px-2 py-1 mb-1 btn-primary d-none"
                            : "btn px-2 py-1 mb-1 btn-success"
                        }
                        onClick={(e) =>
                          !myReply?.reviewId ? handleReply(e) : ""
                        }
                      >
                        <span>
                          <i className="fa fa-reply"></i>
                          {myReply?.reviewId ? " Replied" : " Reply"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-end d-none">
                  <button className="btn px-1 py-0 btn-primary">
                    <span>
                      <i className="fa fa-reply"></i> Reply
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default AddReplyContain;
