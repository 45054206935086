import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Briefcase, Calendar, User } from "react-feather";
import ProfileTabs from "../ProfileTabs";
import TutorPersonalInfo from "./BasicInfo/TutorPersonalInfo";
import TutorPricing from "./InterestPricing/TutorPricing";
import TutorSchedule from "./Schedule/TutorSchedule";
import TutorExperience from "./WorkExperience/TutorExperience";
import { AboutMe } from "../AboutMe";

const TutorTabs = () => {
  const tabList = [
    { id: "1", name: "About Me", icon: "icofont-user-alt-7", component: <AboutMe /> },
    // { id: "1", name: "About Me", icon: "icofont-user-alt-7", component: <TutorPersonalInfo /> },
    { id: "2", name: "Working Experience", icon: "icofont-briefcase", component: <TutorExperience /> },
    { id: "3", name: "Schedule", icon: "icofont-ui-calendar", component: <TutorSchedule /> },
    { id: "4", name: "Tuition Subjects", icon: "icofont-notebook", component: <TutorPricing /> },
  ];
  const { memberType } = useSelector((state) => state.menu) || {};
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const location = useLocation();
  const { to } = location.state || {};

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (to && activeTab !== to) { // add a check to avoid infinite loop
      toggleTab(to);
      navigate(`${process.env.PUBLIC_URL}/tutor-account`, { state: {} });
    }
  }, [])

  useEffect(() => {
    if (memberType === "student") {
      navigate(`${process.env.PUBLIC_URL}/student-account`);
    }
  }, [memberType, navigate]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryTab = searchParams.get('tab');
    if (queryTab) {
      setActiveTab(queryTab);
    }
  }, [searchParams]);

  return (
    <Fragment>
      <ProfileTabs tabList={tabList} activeTab={activeTab} toggleTab={toggleTab} setActiveTab={setActiveTab} ></ProfileTabs>
    </Fragment>
  );
};
export default TutorTabs;
