export const sortedWorkExperiences = (_workExperiences) => {
  if (!_workExperiences) return [];
  return _workExperiences?.sort((a, b) => {
    if (a.running && !b.running) {
      return -1; // a comes first if it's running and b is not
    } else if (!a.running && b.running) {
      return 1; // b comes first if it's running and a is not
    } else {
      // Both are running or both are not running, sort by endDate
      const aEndDate = new Date(a.endDate).getTime();
      const bEndDate = new Date(b.endDate).getTime();

      if (aEndDate === bEndDate) {
        // If both have the same endDate, sort by startDate
        const aStartDate = new Date(a.startDate).getTime();
        const bStartDate = new Date(b.startDate).getTime();
        return bStartDate - aStartDate; // sort by startDate in descending order
      } else {
        // Sort by endDate in descending order
        return bEndDate - aEndDate;
      }
    }
  });
};
