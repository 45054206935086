import React, { Fragment, useEffect } from "react";
import { useContext } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H2, H5, LI, P, UL } from "../../AbstractElements";
import FilterContext from "../../_helper/Filter";

const PrivacyContain = () => {
  const {setMoonlight}=useContext(FilterContext);

 //--- setting page theme to light mode ---
 useEffect(()=>{
  // console.log(moonlight);
  // console.log(localStorage.getItem('layout_version'));
  setMoonlight(false);
  document.body.className = "light";
  localStorage.setItem("layout_version", "light");
},[]);

  return (
    <Fragment>
      <Container fluid={false}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Privacy Policy for TuteAir</H5>
              </CardHeader>
              <CardBody>
                <h3>TuteAir's Privacy Statement</h3>

                <P>
                  We place a high priority on protecting the privacy of our
                  visitors. This privacy policy document describes the
                  categories of personal information that{" "}
                  <a href="www.tuteair.com">www.tuteair.com </a>
                  receives and collects, as well as how it will be kept safe.
                  Please don't hesitate to get in touch with us if you have any
                  more queries or need details regarding our privacy policy
                </P>

                <h3>Acceptance of This Policy </h3>

                <P>
                  You agree without reservation that using the Platform and its
                  Services constitutes yours. (i) acceptance of this Privacy
                  Policy and (ii) authorization to TuteAir using, collecting,
                  processing, and/or disclosing your Personal Information in the
                  manner and for the purposes specified in this Privacy Policy.
                  You acknowledge that this Privacy Policy is a part of the
                  Agreement of your use of the Platform and the other Services.
                  This Privacy Policy and the Terms of Use apply to your use of
                  the Platform and your visit.
                </P>

                <h3>Information Type </h3>

                <P>
                  Any information that we can use to identify a specific person
                  is considered "personal information." For instance, this
                  includes information like your name, phone number, email
                  address, etc. that enables you to be recognized as a specific
                  individual. It excludes any personal data that has been
                  encrypted, made anonymous, or merged with publicly available
                  but non-public data.
                </P>

                <h3>Personal data we obtain from users of our services </h3>
                <br />

                <UL>
                  <LI>
                    Sign in: Your email address and username are retained in our
                    databases once you register with TuteAir. Additionally, in
                    order to register for our service, we must have a minimum of
                    the following information:{" "}
                  </LI>
                  <LI>Your full name </LI>
                  <LI>Your phone number </LI>
                  <LI>Your email address </LI>
                  <LI>Your physical address including postcode and town</LI>
                  <LI>Your Date of Birth </LI>
                  <LI>Profile Picture </LI>
                  <LI>Passport/Driving License/National Identification</LI>
                </UL>
                <br />

                <p>
                  The following additional details are optional and can be added
                  to a student account:{" "}
                </p>
                <UL>
                  <LI>Subjects and grade level you would want to be taught</LI>
                  <LI>
                    General information about the support you need and your
                    availability
                  </LI>
                  <LI>
                    Grades are now being received, as well as the exam board
                  </LI>
                  <LI>Details of your payment</LI>
                </UL>
                <br />

                <p>
                  We also keep the additional personal data listed below for a
                  tutor account:{" "}
                </p>
                <UL>
                  <LI>
                    The subjects the tutor teaches, along with their hourly
                    rates{" "}
                  </LI>
                  <LI>
                    The gender, availability, credentials, and experience of the
                    teachers
                  </LI>
                  <LI>
                    Personal statements, backgrounds, expertise, native tongues
                  </LI>
                  <LI>About bank information </LI>
                </UL>
                <br />

                <h3>How your information may be used</h3>

                <P>
                  We communicate with you directly or through one of our
                  partners for a variety of reasons, such as customer support,
                  to send you updates and other information about the website,
                  and for marketing and promotional purposes. We also send you
                  emails. We provide, operate, and manage our website. We
                  improve, customize, and expand it. We understand and analyze
                  how you use it. And take all precautions to stop fraud.
                </P>

                <h3>The Specifics of Your Public Disclosure</h3>

                <P>
                  You give TuteAir your permission to use any Personal
                  Information or other content you submit or make available
                  through such sites for marketing and promotional purposes,
                  including but not limited to incorporation into our marketing
                  materials, without any further notice or reimbursement, by
                  connecting with TuteAir through our social media pages or
                  accounts, or by posting content using TuteAir-created hashtags
                  or other identifiers. When you fill out forms on our website
                  to sign up for newsletters or other TuteAir communications,
                  TuteAir may also gather and process any Personal Information
                  you choose to provide.
                </P>

                <h3>Accepting Cookies </h3>

                <P>
                  When users check in to our user’s portal, we apply cookies to
                  store their user session. The TuteAir website also makes use
                  of cookies or other similar technology to monitor user
                  behavior on the site and compile demographic data about our
                  user base as a whole. <br /> Cookies are tiny text files that
                  a website or user's device stores on it in order to track
                  usage trends and store preferences (such as remembering your
                  password for the quick sign-on). Although TuteAir does not use
                  cookies to keep personal information about its users, we do
                  use them to monitor how our site is being used by all
                  visitors. Internet Protocol (IP) addresses, browser type,
                  Internet Service Provider (ISP), referring/exit sites, and the
                  files are some of the tracking data.
                </P>

                <h3>Regional Context </h3>

                <P>
                  The General Data Protection Regulation (GDPR), sometimes known
                  as the "GDPR," is a set of rules that protects natural persons
                  with regard to the processing of their personal data and the
                  free movement of that data. For individuals based locally or
                  globally, all processing of personal information is carried
                  out in line with these rights and regulations. TuteAir will
                  treat the personal information of such individuals in
                  compliance with the GDPR.
                </P>
                <P>
                  According to the GDPR, TuteAir processes personal information
                  as both a "Processor" and a "Controller," however in cases
                  where you voluntarily provided personal information for the
                  purpose of setting up a user account on one of the services or
                  online platforms that TuteAir occasionally offers, you will be
                  the Controller and TuteAir will be the Mainframe.
                </P>

                <P>
                  Note that tuteair.com has no access to or control over these
                  cookies that are used by third-party advertisers.
                </P>

                <h3>Policy Concerning Privacy of Advertising Partners</h3>

                <P>
                  The privacy policies of tuteair.com's advertising partners are
                  listed on this list, which you can review. The advertisements
                  and links that appear on tuteair.com are served by third-party
                  ad servers or ad networks using technologies like cookies,
                  JavaScript, or Web Beacons, which are transmitted directly to
                  users' browsers. Your IP address is automatically transmitted
                  to them when this happens. These tools are used to assess the
                  success of their marketing initiatives and/or to tailor the
                  advertising content you see on websites you visit. Note that
                  tuteair.com has no access to or control over these cookies
                  that are used by third-party advertisers.
                </P>
                <h3>Peripheral Links</h3>

                <P>
                  We may provide links to external websites, or websites not
                  affiliated with TuteAir, on some of the pages on TuteAir
                  (third-party websites). These external websites' content is
                  not the responsibility of TuteAir. Although we are selective
                  about the websites we link to, we cannot control their content
                  or privacy practices, thus some may include offensive
                  material. If you're worried, please study their policies.
                </P>
                <P>
                  There is a well-known logo and menu system on every page of
                  TuteAir; if you arrived here by clicking a link to an external
                  website, the layout is probably present on all pages.
                </P>

                <h3>CCPA Privacy Rights  </h3>

                <P>
                  California consumers have the following rights under the CCPA,
                  among others: Demand that businesses who collect their
                  personal data disclose the categories and particular pieces of
                  data they have amassed on them. Submit a request to have any
                  customer information that has been gathered about them deleted
                  by the business. Ensure that a company selling customer data
                  does not sell the customer's data again. In the event that you
                  submit a request, we have one month to answer. Contact us if
                  you want to make use of any of these rights.
                </P>

                <h3>Data Protection Rights under GDPR</h3>

                <P>
                  We want to make sure that you are completely informed of all
                  of your rights in relation to data protection. The following
                  are rights that each user has:
                </P>

                <UL>
                  <LI>
                    Accessibility - You are allowed to ask for copies of your
                    personal data. For this service, we might assess a modest
                    price to you.{" "}
                  </LI>
                  <LI>
                    The right to rectification - You have the right to ask us to
                    change any information you feel is incorrect. Additionally,
                    you have the right to ask that we complete any information
                    that you feel is lacking.{" "}
                  </LI>
                  <LI>
                    The option to have your personal data erased is available to
                    you under specific circumstances.{" "}
                  </LI>
                  <LI>
                    The right to request a restriction on processing - In
                    certain circumstances, you have the right to ask that we
                    stop using your personal data for specified purposes.{" "}
                  </LI>
                  <LI>
                    The right to object to processing - In some circumstances,
                    you have the right to object to the way we are using your
                    personal information.{" "}
                  </LI>
                </UL>
                <br />

                <h3>Client Activities Records </h3>
                <P>
                  We may look up an individual's IP address on our server and
                  determine which ISP is providing it by looking through the
                  regular log files that are kept there. We are able to
                  determine the date and time that you accessed each page on
                  TuteAir thanks to our logs. Other common data found in these
                  log files includes a "user agent" that identifies the internet
                  browser and operating system you were using. This log data is
                  mostly used to analyze user behavior across TuteAir and
                  determine which sites are popular.{" "}
                </P>
                <P>
                  We are unable to gather any personally identifying information
                  from this data on its own. The user can be tracked, though, if
                  they register for our service (through the IP address).
                  Although TuteAir is the only user of this information, if a
                  user repeatedly abuses us, we may disclose their information
                  to the police or other third parties.{" "}
                </P>
                <h3>Information Sharing Practices </h3>
                <p>
                  If you work as a tutor, we will give a student access to your
                  contact information (complete name, address, phone number, and
                  email address) if you have given permission for them to do so
                  or if you have arranged a booking with them. You can revoke
                  your consent at any moment by ending a conversation with a
                  student.{" "}
                </p>
                <p>
                  TuteAir exclusively works with a very small number of
                  carefully chosen organizations since we only employ
                  third-party solutions when it is absolutely necessary for our
                  business. As much functionality as possible is kept in-house
                  since, in our opinion, it is considerably safer for the data
                  of our customers
                </p>
                <p>
                  The following businesses are ones we currently partner with:{" "}
                </p>
                <UL>
                  <LI>
                    PayPal / PayPal Holdings Inc (USA) - Online payment provider
                  </LI>
                  <LI>
                    Stripe / Stripe Payments Europe - Online payment provider
                  </LI>
                </UL>
                <br />

                <h3>Transaction Terminal</h3>
                <p>
                  Payment processing for our service is handled by a third-party
                  payment provider. By default, we don't store our users'
                  financial information; only after their consent do, we safely
                  store it for upcoming purchases. We process payments using
                  Stripe/PayPal, whose privacy policy governs how they utilize
                  your personal information.
                </p>
                <h3>Information Involving Children </h3>
                <p>
                  Adding protection for kids using the internet is another one
                  of our priorities. We urge parents and guardians to keep an
                  eye on, engage in, monitor, and/or direct their children's
                  internet behavior.
                </p>
                <p>
                  Children under the age of 13 cannot knowingly provide
                  www.tuteair.com with any Personal Identifiable Information. We
                  highly encourage you to notify us immediately if you believe
                  that your child may have supplied this kind of information on
                  our website. We will make every attempt to promptly erase such
                  information from our databases.
                </p>
                <h3>Communicating TuteAir </h3>
                <p>
                  By utilizing our website, www.tuteair.com, you can get in
                  touch with us if you have any questions or issues about our
                  privacy statement. We will be pleased to respond to your
                  inquiries. If our policy changes, we'll make the necessary
                  updates to this page.
                </p>
                <h3>Our Portal Address </h3>
                <p>
                  <a
                    href="https://www.tuteair.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.tuteair.com
                  </a>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PrivacyContain;
