import React, { Fragment } from "react";
import { toast, ToastContainer } from "react-toastify";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import BecomeStudentProvider from "./_helper/BecomeStudent/BecomeStudentProvider";
import BecomeTutorProvider from "./_helper/BecomeTutor/BecomeTutorProvider";
import ClassSchedulerProvider from "./_helper/ClassScheduler/ClassSchedulerProvider";
import {
  default as CustomizerProvider,
  default as CustomProvider
} from "./_helper/customizer/CustomizerProvider";
import FilterProvider from "./_helper/Filter/FilterProvider";
import MemberInformationProvider from "./_helper/MemberInformation/MemberInformationProvider";
import PersonalAddressProvider from "./_helper/PersonalAddress/PersonalAddressProvider";
import TuitionRequestProvider from "./_helper/TuitionRequest/TuitionRequestProvider";
import TutorsProvider from "./_helper/Tutors/TutorsProvider";
import "./App.css";
import useAuthCheck from "./redux/hooks/useAuthCheck";
import Routers from "./Routes";
import FbMessengerChatbot from "./Component/FbMessengerChatbot";

toast.configure();

function App() {
  const authChecked = useAuthCheck();
  return !authChecked ? (
    <div>
      {
        //"Checking authentication...."
      }
    </div>
  ) : (
    <Fragment>
      <CustomizerProvider>
        <CustomProvider>
          <BecomeTutorProvider>
            <BecomeStudentProvider>
              <FilterProvider>
                <MemberInformationProvider>
                  <PersonalAddressProvider>
                    <TuitionRequestProvider>
                      <TutorsProvider>
                        <ClassSchedulerProvider>
                          <AnimationThemeProvider>
                            {/* <FilterProvider></FilterProvider> */}
                            <Routers />
                          </AnimationThemeProvider>
                        </ClassSchedulerProvider>
                      </TutorsProvider>
                    </TuitionRequestProvider>
                  </PersonalAddressProvider>
                </MemberInformationProvider>
              </FilterProvider>
            </BecomeStudentProvider>
          </BecomeTutorProvider>
        </CustomProvider>
      </CustomizerProvider>
      <ToastContainer />
      <FbMessengerChatbot />
    </Fragment>
  );
}
export default App;
